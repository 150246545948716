<script setup lang="ts">
import ToastData = App.Base.Data.ToastData;
import ToastType = App.Base.Enums.ToastType;

import Alert, { AlertColor } from '@/components/alert/Alert.vue';

import Check from '@/icons/line/check.svg';
import InfoCircle from '@/icons/line/info-circle.svg';
import TimesCircle from '@/icons/line/times-circle.svg';

const hybridlyToasts = useProperty<ToastData[]>('toasts');
const toasts = ref<ToastData[]>([...hybridlyToasts.value]);

const shownToasts = new Set<string>();

watch(
  () => hybridlyToasts.value,
  (newHybridlyToasts) => {
    toasts.value = [...toasts.value, ...newHybridlyToasts].filter(
      (toast) => !shownToasts.has(toast.id)
    );

    toasts.value.forEach((toast) => shownToasts.add(toast.id));
  }
);

function getToastIcon(type: ToastType) {
  return (
    {
      success: Check,
      info: InfoCircle,
      danger: TimesCircle
    }[type] ?? null
  );
}

function getToastColor(type: ToastType): AlertColor {
  const colorMap: Record<ToastType, AlertColor> = {
    success: 'success',
    info: 'secondary',
    danger: 'danger'
  };
  return colorMap[type];
}

function handleButtonClick(toast: ToastData) {
  if (toast.buttonLink) {
    router.get(toast.buttonLink);
  }

  removeToast(toast.id);
}

function handleClose(id: string) {
  removeToast(id);
}

function removeToast(id: string) {
  toasts.value = toasts.value.filter((toast) => toast.id !== id);
}
</script>

<template>
  <TransitionGroup
    ariaLive="assertive"
    moveClass="transition-toast duration-500"
    enterActiveClass="transition-toast duration-500"
    leaveActiveClass="transition-toast duration-500 absolute inset-x-0 bottom-0"
    enterFromClass="opacity-0"
    leaveToClass="opacity-0"
    tag="ul"
    class="relative flex flex-col gap-6"
  >
    <li v-for="(toast, index) in toasts" :key="toast.id">
      <Alert
        :color="getToastColor(toast.type)"
        :description="toast.message"
        :duration="toast.timeout"
        :icon="getToastIcon(toast.type)"
        :title="toast.title"
        :style="{ zIndex: index + 1 }"
        :primaryButtonLabel="toast.buttonLabel"
        @onPrimaryClick="handleButtonClick(toast)"
        @onClose="handleClose(toast.id)"
      />
    </li>
  </TransitionGroup>
</template>
