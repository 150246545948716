<script setup lang="ts">
import type { DialogProps } from '@/components/dialog/Dialog.vue';
import Dialog from '@/components/dialog/Dialog.vue';
import ExclamationCircle from '@/icons/line/exclamation-circle.svg';

type DangerDialogProps = Omit<DialogProps, 'icon' | 'variant' | 'contentClass'>;

defineProps<DangerDialogProps>();

const emit = defineEmits<{
  onCancel: [void];
  onClose: [void];
  onConfirm: [void];
}>();

function onCancelButtonClick() {
  emit('onCancel');
}

function onOverlayClick() {
  emit('onClose');
}

function onConfirmButtonClick() {
  emit('onConfirm');
}
</script>

<template>
  <Dialog
    :cancelButtonLabel="cancelButtonLabel"
    :confirmButtonLabel="confirmButtonLabel"
    :description="description"
    :icon="ExclamationCircle"
    :isLoading="isLoading"
    :isOpen="isOpen"
    :message="message"
    :title="title"
    variant="danger"
    @onCancel="onCancelButtonClick"
    @onClose="onOverlayClick"
    @onConfirm="onConfirmButtonClick"
  >
    <slot />
    <template v-if="$slots.body" #body>
      <slot name="body" />
    </template>
    <template v-if="$slots.footer" #footer>
      <slot name="footer" />
    </template>
  </Dialog>
</template>
