<script setup lang="ts">
import {
  TooltipArrow,
  TooltipContent,
  TooltipPortal,
  TooltipProvider,
  TooltipRoot,
  TooltipTrigger
} from 'radix-vue';
import IconInfoCircle from '@/icons/line/info-circle.svg';
import { ref, computed } from 'vue';

export type TooltipSide = 'right' | 'left' | 'bottom' | 'top';

const isTouchDevice = ref(false);
const isTouchOpen = ref(false);

const computedIsOpen = computed(() => (isTouchDevice.value ? isTouchOpen.value : undefined));

const props = withDefaults(
  defineProps<{
    asChild?: boolean;
    isDisabled?: boolean;
    isTouchTooltipDisabled?: boolean;
    showArrow?: boolean;
    side?: TooltipSide;
    sideOffset?: number;
    variant?: 'solid' | 'soft';
    triggerClasses?: string;
    delayDuration?: number;
  }>(),
  {
    delayDuration: 1200,
    isDisabled: false,
    showArrow: false,
    side: 'right',
    sideOffset: 10,
    variant: 'solid'
  }
);

defineOptions({
  inheritAttrs: false
});

const handleTouchStart = (event: TouchEvent) => {
  if (!props.isTouchTooltipDisabled) {
    isTouchDevice.value = true;
    event.preventDefault();
    isTouchOpen.value = true;
  }
};

const handleTouchEnd = () => {
  setTimeout(() => {
    isTouchOpen.value = false;
  }, 3000);
};
</script>

<template>
  <TooltipProvider :delayDuration>
    <TooltipRoot :open="computedIsOpen">
      <TooltipTrigger
        as="div"
        :class="triggerClasses"
        :asChild="asChild"
        v-bind="$attrs"
        @touchstart="handleTouchStart"
        @touchend="handleTouchEnd"
      >
        <slot name="trigger" v-bind="$attrs">
          <IconInfoCircle class="h-4 w-4 text-slate-500" />
        </slot>
      </TooltipTrigger>
      <TooltipPortal v-if="!isDisabled">
        <TooltipContent
          asChild
          :side="side"
          class="isolate w-full max-w-md whitespace-normal rounded px-2 py-0.5 text-sm text-white shadow-md data-[state=instant-open]:data-[side=bottom]:animate-slide-up-and-fade data-[state=instant-open]:data-[side=left]:animate-slide-right-and-fade data-[state=instant-open]:data-[side=right]:animate-slide-left-and-fade data-[state=instant-open]:data-[side=top]:animate-slide-down-and-fade"
          :class="{
            'bg-slate-900': variant === 'solid',
            'bg-slate-50': variant === 'soft'
          }"
          :sideOffset="sideOffset"
        >
          <div>
            <slot />
            <TooltipArrow
              v-if="showArrow"
              :class="{
                'fill-current text-slate-900': variant === 'solid',
                'fill-current text-slate-50': variant === 'soft'
              }"
              size="8"
            />
          </div>
        </TooltipContent>
      </TooltipPortal>
    </TooltipRoot>
  </TooltipProvider>
</template>
