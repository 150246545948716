<script setup lang="ts">
type TodoData = App.Configuration.Data.TodoData;

import { ButtonColor, ButtonVariant } from '@/hooks/useButtonClasses';

import Button from '@/components/button/Button.vue';
import TodoContent from '@/components/todos/TodoContent.vue';
import TodosPopover from '@/components/todos/TodosPopover.vue';

import CheckSquare from '@/icons/line/check-square.svg';

const hybridlyTodos = useProperty<TodoData[]>('todos');

defineProps<{
  isSmallScreen: boolean;
}>();

const isTodosOpen = ref(false);

const todoList = computed(() => hybridlyTodos.value.filter((todo) => !todo.is_complete));
const doneList = computed(() => hybridlyTodos.value.filter((todo) => todo.is_complete));

function toggleTodos() {
  isTodosOpen.value = !isTodosOpen.value;
}
</script>

<template>
  <div>
    <TodosPopover v-if="!isSmallScreen">
      <TodoContent
        v-if="!isSmallScreen || (isSmallScreen && isTodosOpen)"
        :doneList="doneList"
        :todoList="todoList"
      />
    </TodosPopover>
    <div class="block" v-if="isSmallScreen">
      <Button
        class="w-full justify-start"
        :iconLeft="CheckSquare"
        :color="ButtonColor.slate"
        :variant="ButtonVariant.invisible"
        @click="toggleTodos"
        >Todos</Button
      >
      <Teleport to="body">
        <div v-if="isSmallScreen && isTodosOpen" class="fixed inset-0 z-50 bg-white">
          <TodoContent :doneList="doneList" :todoList="todoList" @onClose="toggleTodos" />
        </div>
      </Teleport>
    </div>
  </div>
</template>
