<script setup lang="ts">
type EnumData = App.Base.Data.EnumData;

import type { Selectable } from '@/components/selectBox/selectBox';

import CenteredContent from '@/components/content/CenteredContent.vue';
import StudentScholarshipForm from '@/components/students/studentActions/StudentScholarshipForm.vue';

const props = defineProps<{
  returnUrl?: string;
  student: App.Students.Data.StudentData;
  statuses: EnumData[];
  types: EnumData[];
}>();

const form = useForm({
  method: 'POST',
  url: route('students.student-scholarships.store', { student: props.student.id }),
  fields: {
    type: null as Selectable<number> | null,
    institute_id: null as Selectable<number> | null,
    scholarship_name: null as Selectable<string> | null,
    amount: null,
    years_renewable: null,
    status: null as Selectable<number> | null,
    is_assisted: false
  },
  transform: (fields) => ({
    ...fields,
    type: fields.type?.value,
    institute_id: fields.institute_id?.value,
    scholarship_name: fields.scholarship_name?.label,
    status: fields.status?.value
  })
});
</script>


					<script lang="ts">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <CenteredContent>
    <StudentScholarshipForm :form :returnUrl :student :statuses :types />
  </CenteredContent>
</template>
