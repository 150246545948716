<script setup lang="ts" generic="T extends Record<string, unknown>">
import { ref, computed } from 'vue';
import type { TableColumn } from '@/components/table/Table';

import { useDialogHelpers } from '@/hooks/useDialogHelpers';

import Dialog from '@/components/dialog/Dialog.vue';
import IconButton from '@/components/button/IconButton.vue';
import SwitchInput from '@/components/switchInput/SwitchInput.vue';
import Indicator from '@/components/button/Indicator.vue';

import Columns from '@/icons/line/columns.svg';
import AlignLeft from '@/icons/line/align-left.svg';
import Calendar from '@/icons/line/calendar.svg';
import CheckCircle from '@/icons/line/check-circle.svg';
import CheckSquare from '@/icons/line/check-square.svg';
import Envelope from '@/icons/line/envelope.svg';
import Paragraph from '@/icons/line/paragraph.svg';
import Phone from '@/icons/line/phone.svg';
import SliderH from '@/icons/line/slider-h.svg';
import Text from '@/icons/line/text.svg';
import ToggleOn from '@/icons/line/toggle-on.svg';

const props = defineProps<{
  columnOptions: (TableColumn<T> & { block_type: string })[];
  selectedOptions: string[];
}>();

const emits = defineEmits(['updateColumns']);

const { isDialogOpen, toggleDialog } = useDialogHelpers();

const selectedState = ref(new Set(props.selectedOptions));

const columnsWithSelection = computed(() =>
  props.columnOptions.map((column) => ({
    ...column,
    selected: selectedState.value.has(column.key)
  }))
);

function onColumnCheckedChange(columnKey: string, checked: boolean) {
  if (checked) {
    selectedState.value.add(columnKey);
  } else {
    selectedState.value.delete(columnKey);
  }
}

function handleColumnsSubmit() {
  const selectedColumns = Array.from(selectedState.value);
  emits('updateColumns', selectedColumns);
  toggleDialog();
}

function getBlockTypeIcon(value: string) {
  return {
    select: CheckCircle,
    multiselect: CheckSquare,
    text: Text,
    textarea: AlignLeft,
    instruction: Paragraph,
    range: SliderH,
    boolean: ToggleOn,
    date: Calendar,
    email: Envelope,
    phone: Phone
  }[value];
}
</script>

<template>
  <Indicator
    :label="columnOptions.length - selectedOptions.length"
    :show="selectedOptions.length !== columnOptions.length"
  >
    <IconButton
      :icon="Columns"
      ariaLabel="Customize columns"
      size="sm"
      variant="soft"
      :tooltipProps="{ side: 'top' }"
      @click="toggleDialog"
    />
  </Indicator>

  <Dialog
    @onClose="toggleDialog"
    @onConfirm="handleColumnsSubmit"
    @onCancel="toggleDialog"
    confirmButtonLabel="Apply"
    :isOpen="isDialogOpen"
    title="Customize columns"
  >
    <div class="w-full">
      <div
        v-for="column in columnsWithSelection"
        :key="column.key"
        class="flex items-start gap-x-3 py-2"
      >
        <div class="flex grow items-start gap-x-2">
          <div
            class="flex size-5 shrink-0 items-center justify-center rounded border border-slate-200 bg-slate-100"
          >
            <component :is="getBlockTypeIcon(column.block_type)" class="size-3.5 text-zinc-600" />
          </div>
          <label for="column.key" class="grow text-sm leading-5 text-slate-900">{{
            column.label
          }}</label>
        </div>
        <SwitchInput
          :checked="column.selected"
          :id="column.key"
          :name="column.key"
          @update:checked="(checked) => onColumnCheckedChange(column.key, checked)"
        />
      </div>
    </div>
  </Dialog>
</template>
