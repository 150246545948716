<script setup lang="ts">
import { useResizeObserver } from '@vueuse/core';

import IconButton from '@/components/button/IconButton.vue';
import EmptyCard from '@/components/emptyCard/EmptyCard.vue';
import PageSection from '@/components/pageSection/PageSection.vue';
import PageSectionHeaderButton from '@/components/pageSection/PageSectionHeaderButton.vue';
import StudentInteractionCard from '@/components/students/StudentInteractionCard.vue';
import StudentInteractionCounts from '@/components/students/StudentInteractionCounts.vue';

import AngleLeft from '@/icons/line/angle-left.svg';
import AngleRight from '@/icons/line/angle-right.svg';

const props = defineProps<{
  interactionCounts: {
    '1_on_1': number;
    group: number;
    parent: number;
    other: number;
  };
  interactions?: App.Students.Data.InteractionData[] | null;
  student: App.Students.Data.StudentData;
}>();

const scrollContainer = ref<HTMLDivElement | null>(null);
const isAtStart = ref(true);
const isAtEnd = ref(false);
const cardWidth = ref(0);
const gapWidth = 8;

function calculateCardWidth() {
  if (scrollContainer.value) {
    const firstCard = scrollContainer.value.querySelector('.student-interaction-card');
    if (firstCard) {
      cardWidth.value = firstCard instanceof HTMLElement ? firstCard.offsetWidth + gapWidth : 0;
    }
  }
}

function scrollLeft() {
  if (scrollContainer.value) {
    scrollContainer.value.scrollBy({ left: -cardWidth.value, behavior: 'smooth' });
    setTimeout(checkScroll, 300); // Check scroll after scrolling
  }
}

function scrollRight() {
  if (scrollContainer.value) {
    scrollContainer.value.scrollBy({ left: cardWidth.value, behavior: 'smooth' });
    setTimeout(checkScroll, 300); // Check scroll after scrolling
  }
}

function checkScroll() {
  if (scrollContainer.value) {
    const { scrollLeft, scrollWidth, clientWidth } = scrollContainer.value;
    isAtStart.value = scrollLeft === 0;
    isAtEnd.value = scrollLeft + clientWidth >= scrollWidth;
  }
}

useResizeObserver(scrollContainer, () => {
  calculateCardWidth();
  checkScroll();
});

watch(
  () => props.interactions,
  () => {
    nextTick(() => {
      calculateCardWidth();
      checkScroll();
    });
  }
);
</script>

<template>
  <PageSection title="Interactions Overview" :paddedContent="false">
    <template #header>
      <div class="flex items-center gap-x-3">
        <StudentInteractionCounts
          class="hidden xl:flex"
          v-if="interactions"
          :counts="interactionCounts"
        />
        <div class="flex items-center gap-x-1">
          <IconButton
            :icon="AngleLeft"
            :isDisabled="isAtStart"
            ariaLabel="Scroll left"
            size="xs"
            variant="outlined"
            @click="scrollLeft"
          />
          <IconButton
            :icon="AngleRight"
            :isDisabled="isAtEnd"
            ariaLabel="Scroll right"
            size="xs"
            variant="outlined"
            @click="scrollRight"
          />
        </div>
        <PageSectionHeaderButton
          :href="route('students.interactions.index', { student: student.id })"
          label="See details"
        />
      </div>
    </template>
    <div ref="scrollContainer" class="flex items-stretch gap-x-2 overflow-x-auto">
      <template v-if="interactions">
        <StudentInteractionCard
          v-for="interaction in interactions"
          :interaction="interaction"
          :key="interaction.id"
          class="student-interaction-card"
        />
      </template>
      <EmptyCard
        v-else
        class="grow"
        :actionHref="route('students.interactions.create', { student: student.id })"
        actionLabel="New interaction"
        description="Add a new interaction by clicking the button below"
        isDashed
        isRounded
        title="No interaction were added"
      />
    </div>
  </PageSection>
</template>
