import axios from 'axios';

type KpiReportResult = Record<string, App.Reports.Data.KpiMetricResultData>;

type KpiReportParams = {
  school_id: number;
  grade: number;
  report_id: string;
  columns: {
    key: string;
  }[];
};

export async function runReport(params: KpiReportParams): Promise<KpiReportResult> {
  return axios.post(route('api.custom-reports.kpis'), params).then((res) => res.data);
}
