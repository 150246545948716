<script setup lang="ts">
import { ButtonColor, ButtonSize, ButtonVariant } from '@/hooks/useButtonClasses';

import Button from '@/components/button/Button.vue';
import DangerDialog from '@/components/dialog/DangerDialog.vue';

import Trash from '@/icons/line/trash.svg';

const props = defineProps<{
  recordType: string;
  route: string;
  modalMessage?: string;
}>();

const [isDialogOpen, toggleDialogOpen] = useToggle(false);

const deleteForm = useForm({
  method: 'DELETE',
  url: props.route,
  fields: {}
});
</script>

<template>
  <div class="flex items-center gap-x-2">
    <div class="flex grow flex-col">
      <h4 class="text-sm font-medium leading-5 text-slate-700">Delete {{ recordType }}</h4>
      <p class="text-sm font-normal leading-5 text-slate-500">
        This will delete the {{ recordType }}. This action cannot be undone.
      </p>
    </div>
    <div class="grow-0">
      <Button
        :color="ButtonColor.danger"
        :iconLeft="Trash"
        :size="ButtonSize.sm"
        :variant="ButtonVariant.solid"
        @click="toggleDialogOpen"
        >Delete {{ recordType }}</Button
      >
    </div>
  </div>
  <DangerDialog
    :isLoading="deleteForm.processing"
    :isOpen="isDialogOpen"
    :message="
      modalMessage ??
      `Are you sure you want to delete this ${recordType}? This action is permanent and cannot be reverted.`
    "
    :title="`Delete ${recordType}`"
    confirmButtonLabel="Delete"
    @onCancel="toggleDialogOpen"
    @onClose="toggleDialogOpen"
    @onConfirm="() => deleteForm.submit()"
  />
</template>
