type DragAndDropContext<T> = {
  dragAndDropItems: Ref<T[]>;
  applyDrag: (arr: T[], dragResult: unknown) => T[];
  applyDragForMultipleItems: (arr: T[], dragResult: unknown) => T[];
  onDrop: (dropResult: unknown) => void;
};

export function useDragAndDrop<T>(initialItems: T[] = []): DragAndDropContext<T> {
  const dragAndDropItems = ref([...initialItems]) as Ref<T[]>;

  watch(
    () => initialItems,
    (newBlocks) => {
      dragAndDropItems.value = [...newBlocks];
    }
  );

  function applyDragForMultipleItems(arr, dragResult) {
    const { removedIndex, addedIndex, payload } = dragResult;
    if (removedIndex === null && addedIndex === null) {
      return arr;
    }

    const result = [...arr];
    const itemsToMove = Array.isArray(payload) ? payload : [arr[addedIndex]];

    // Remove items from their original positions
    itemsToMove.forEach((item) => {
      const index = result.indexOf(item);
      if (index !== -1) {
        result.splice(index, 1);
      }
    });

    // Insert items at the new position
    if (addedIndex !== null) {
      result.splice(addedIndex, 0, ...itemsToMove);
    }

    return result;
  }

  function applyDrag(arr, dragResult) {
    const { removedIndex, addedIndex } = dragResult;

    if (removedIndex === null || addedIndex === null) {
      return arr;
    }

    const result = [...arr];
    const [itemToMove] = result.splice(removedIndex, 1);
    result.splice(addedIndex, 0, itemToMove);

    return result;
  }

  function onDrop(dropResult) {
    dragAndDropItems.value = applyDrag(dragAndDropItems.value, dropResult);
  }

  return {
    dragAndDropItems,
    applyDrag,
    applyDragForMultipleItems,
    onDrop
  };
}
