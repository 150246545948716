<script setup lang="ts">
type StudentData = App.Students.Data.StudentData;
type StudentParentData = App.Students.Data.StudentParentData;

import { Container, Draggable } from 'vue-dndrop';

import { useDragAndDrop } from '@/hooks/useDragAndDrop';
import CenteredContent from '@/components/content/CenteredContent.vue';
import EmptyCard from '@/components/emptyCard/EmptyCard.vue';
import StudentFormWrapper from '@/components/students/StudentFormWrapper.vue';
import StudentParentCard from '@/components/studentParent/StudentParentCard.vue';
import { ButtonColor, ButtonVariant } from '@/hooks/useButtonClasses';
import Button from '@/components/button/Button.vue';

const props = defineProps<{
  parents: StudentParentData[];
  student: StudentData;
  returnUrl: string;
}>();

const { dragAndDropItems, onDrop, applyDrag } = useDragAndDrop<StudentParentData>(props.parents);

watch(
  () => props.parents,
  async (newParents) => {
    dragAndDropItems.value = [...newParents];
  }
);

function handleParentOrderChange(event: unknown) {
  onDrop(event);

  const newOrderedParents = applyDrag(props.parents, event);

  useForm({
    method: 'PUT',
    url: route('students.parents-guardians.update', {
      student: props.student.id
    }),
    updateInitials: true,
    fields: {
      parent_ids: newOrderedParents.map((parent) => parent.id)
    },
    only: ['parents']
  }).submit();
}
</script>


					<script lang="ts">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <CenteredContent>
    <StudentFormWrapper
      isEditing
      :isDirty="false"
      :isProcessing="false"
      :returnUrl="props.returnUrl"
      :studentId="student.id"
      :hideControls="true"
    >
      <div class="flex flex-col gap-4">
        <h2 class="text-xs font-bold uppercase leading-3 text-slate-400">Parents</h2>
        <template v-if="dragAndDropItems.length">
          <Container
            ref="containerRef"
            @drop="handleParentOrderChange"
            lockAxis="y"
            dragHandleSelector=".parent-card-handle"
            :animationDuration="400"
            class="flex flex-1 flex-col overflow-y-auto"
          >
            <Draggable v-for="parent in dragAndDropItems" :key="parent.id" class="shrink-0">
              <StudentParentCard :parent="parent" :student="student" />
            </Draggable>
          </Container>
        </template>
        <div v-else class="px-4 py-6">
          <EmptyCard
            isDashed
            isRounded
            title="No parents yet"
            size="md"
            variant="invisible"
            description="Add a parent or guardian to the student"
            actionLabel="Add parent/guardian"
            :isLoading="false"
            :actionHref="route('students.parents-guardians.create', { student: props.student.id })"
          />
        </div>
        <div class="flex justify-center" v-if="dragAndDropItems.length">
          <Button
            :color="ButtonColor.primary"
            :href="route('students.parents-guardians.create', { student: props.student.id })"
            :variant="ButtonVariant.solid"
            >Add a parent/guardian</Button
          >
        </div>
      </div>
    </StudentFormWrapper>
  </CenteredContent>
</template>
