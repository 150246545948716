import { debounce } from 'lodash';

import { search } from '@/api/SurveyApi';

import { buildSelectableSurvey } from '@/utils/buildSelectable';

import { Selectable } from '@/components/selectBox/selectBox';

export function useSurveysSearch(searchFilters?: Record<string, unknown>) {
  const searchQuery = ref('');
  const isLoading = ref(false);
  const surveyOptions = ref<Selectable<number>[]>([]);

  const searchSurveys = debounce(async (query: string) => {
    const response = await search({
      filters: {
        ...searchFilters,
        search: query
      }
    });
    surveyOptions.value = response.map((survey) =>
      buildSelectableSurvey(survey)
    ) as Selectable<number>[];

    isLoading.value = false;
  }, 300);

  function onQueryChange(query: string): void {
    isLoading.value = true;
    searchQuery.value = query;

    searchSurveys(query);
  }

  return {
    surveyOptions,
    isLoading,
    onQueryChange
  };
}
