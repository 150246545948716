import { debounce } from 'lodash';

import { search } from '@/api/StudentGroupApi';

import { buildSelectableGroup } from '@/utils/buildSelectable';

import { Selectable } from '@/components/selectBox/selectBox';

export function useStudentGroupsSearch(searchFilters?: Record<string, unknown>) {
  const searchQuery = ref('');
  const isLoading = ref(false);
  const studentGroupOptions = ref<Selectable<number>[]>([]);

  const searchStudentGroups = debounce(async (query: string) => {
    const response = await search({
      filters: {
        ...searchFilters,
        search: query
      }
    });
    studentGroupOptions.value = response.map((studentGroup) =>
      buildSelectableGroup(studentGroup)
    ) as Selectable<number>[];

    isLoading.value = false;
  }, 300);

  function onQueryChange(query: string): void {
    isLoading.value = true;
    searchQuery.value = query;

    searchStudentGroups(query);
  }

  return {
    studentGroupOptions,
    isLoading,
    onQueryChange
  };
}
