<script setup lang="ts">
import type { AppliedFilters } from '@/components/distiller/types';

import StepFooter from '@/components/stepContainer/StepFooter.vue';
import Fieldset from '@/components/fieldset/Fieldset.vue';
import StepContainer from '@/components/stepContainer/StepContainer.vue';
import CreateCustomReport from './CreateCustomReport.vue';
import ReportColumnOrderForm from '@/domains/reports/components/form/ReportColumnOrderForm.vue';

const props = defineProps<{
  customReport: App.Reports.Data.CustomReportData;
  columns: string[];
  filters: AppliedFilters;
  columnFilters: AppliedFilters;
  reportTemplate?: App.Reports.Data.ReportTemplateData;
}>();

const nextForm = useForm({
  url: route('custom-reports.details.create', { customReport: props.customReport.id }),
  key: `custom-reports.order-columns.next.${props.customReport.id}`,
  fields: {
    columns: props.columns ?? [],
    filters: props.filters ?? {},
    column_filters: props.columnFilters ?? {},
    report_template_id: props.reportTemplate?.id ?? null
  }
});

const prevForm = useForm({
  url: route('custom-reports.columns.create', { customReport: props.customReport.id }),
  key: `custom-reports.order-columns.prev.${props.customReport.id}`,
  fields: {
    columns: props.columns ?? [],
    filters: props.filters,
    column_filters: props.columnFilters,
    report_template_id: props.reportTemplate?.id ?? null
  }
});
</script>


					<script lang="ts">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <CreateCustomReport :currentStep="2" :customReport="customReport">
    <StepContainer title="Order columns">
      <div class="mx-auto w-full max-w-lg space-y-5">
        <div class="space-y-5">
          <Fieldset
            title="Order the selected columns"
            description="Use the handles on the right to drag and drop columns to set the order that they'll appear in the report. You can hold the `Shift` key to select a range of columns."
          >
            <ReportColumnOrderForm :form="nextForm" :customReport="customReport" />
          </Fieldset>

          <StepFooter
            @previous="prevForm.submit()"
            @next="nextForm.submit()"
            :isLoading="nextForm.processing"
          />
        </div>
      </div>
    </StepContainer>
  </CreateCustomReport>
</template>
