<script setup lang="ts">
import StudentData = App.Students.Data.StudentData;
import StudentParentData = App.Students.Data.StudentParentData;

import Badge from '@/components/badge/Badge.vue';

import GraduationCapIcon from '@/icons/line/graduation-cap.svg';
import UserIcon from '@/icons/line/user.svg';

const props = defineProps<{
  isStudent: boolean;
  recipient: App.Students.Data.StudentData | App.Students.Data.StudentParentData;
}>();

const studentId = computed(() => getStudentId(props.recipient));

/**
 * @TODO: this only gets the first student id,
 * we need to handle multiple students.
 */
function getStudentId(data: StudentParentData | StudentData | null) {
  if (!data) {
    return null;
  }

  return 'students' in data ? data.students[0].id : data.id;
}
</script>

<template>
  <Badge
    size="sm"
    variant="soft"
    :href="!isStudent && studentId ? route('students.show', { student: studentId }) : undefined"
    :iconLeft="isStudent ? GraduationCapIcon : UserIcon"
    :label="
      isStudent
        ? 'Student'
        : recipient && 'students' in recipient && recipient.students.length
          ? `Parent/Guardian of ${recipient.students[0]?.name}`
          : 'Parent/Guardian'
    "
  />
</template>
