<script setup lang="ts" generic="T extends SelectableValue">
import FormComboboxInput, {
  FormComboboxProps
} from '@/components/formComboboxInput/FormComboboxInput.vue';
import { Selectable, SelectableValue } from '@/components/selectBox/selectBox';
import { useScholarshipsSearch } from '@/hooks/search/useScholarshipsSearch';

const props = defineProps<Omit<FormComboboxProps<number>, 'options'>>();

defineEmits<{
  'update:modelValue': [Selectable<number> | null];
}>();

const { scholarshipOptions, isLoading, onQueryChange } = useScholarshipsSearch(props.searchFilters);
</script>

<template>
  <FormComboboxInput
    v-bind="$props"
    :isLoading="isLoading"
    :options="scholarshipOptions"
    :onFilter="onQueryChange"
    :modelValue="modelValue as Selectable<number>"
    showQueryAsOption
    @update:modelValue="(value) => $emit('update:modelValue', value as Selectable<number>)"
    @open="() => onQueryChange('')"
  />
</template>
