<script setup lang="ts">
import type { AppliedFilters } from '@/components/distiller/types';

import AdviserInteractionsReportFiltersForm from '@/domains/reports/components/form/AdviserInteractionsReportFiltersForm.vue';
import CreateCustomReport from './CreateCustomReport.vue';
import Fieldset from '@/components/fieldset/Fieldset.vue';
import StepContainer from '@/components/stepContainer/StepContainer.vue';
import StepFooter from '@/components/stepContainer/StepFooter.vue';

const props = defineProps<{
  customReport: App.Reports.Data.CustomReportData;
  globalDistiller: App.Search.Data.DistillerData;
  filters: AppliedFilters;
  columnFilters: AppliedFilters;
  schoolYearStartDate: string;
  reportTemplate?: App.Reports.Data.ReportTemplateData;
}>();

const nextForm = useForm({
  url: route('custom-reports.details.create', { customReport: props.customReport.id }),
  key: `custom-reports.filters.next.${props.customReport.id}`,
  fields: {
    filters: props.filters ?? {
      program_id: null,
      user_ids: [],
      start_date: props.schoolYearStartDate,
      end_date: null
    },
    column_filters: props.columnFilters,
    report_template_id: props.reportTemplate?.id ?? null
  }
});
</script>


					<script lang="ts">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <CreateCustomReport :currentStep="0" :customReport="customReport">
    <StepContainer title="Create New Adviser Interactions Report">
      <div class="mx-auto w-full max-w-xl space-y-10">
        <Fieldset title="Report filters">
          <AdviserInteractionsReportFiltersForm :form="nextForm" />
        </Fieldset>

        <StepFooter
          :isLoading="nextForm.processing"
          previousButtonLabel="Cancel"
          nextButtonLabel="Next"
          @next="nextForm.submit()"
          @previous="router.get(route('report-templates.index'))"
        />
      </div>
    </StepContainer>
  </CreateCustomReport>
</template>
