<script setup lang="ts">
import { Slot as RadixSlot } from 'radix-vue';
import { useFormField } from '@/components/formField/useFormField';
import FormError from '@/components/formError/FormError.vue';
import FormHelperText from '@/components/formHelperText/FormHelperText.vue';
import Label from '@/components/label/Label.vue';
import { FormFieldProps, FormFieldSlots } from '@/components/formField/FormField';
import VerticalDivider from '@/components/verticalDivider/VerticalDivider.vue';

const props = withDefaults(
  defineProps<
    FormFieldProps & {
      isFieldset?: boolean;
    }
  >(),
  {
    inheritId: true,
    hasError: false
  }
);

defineSlots<
  FormFieldSlots & {
    default?: (props: { id: string }) => unknown;
  }
>();

const { id } = useFormField({ props });
</script>

<template>
  <component :is="isFieldset ? 'fieldset' : 'div'" class="w-full min-w-0 space-y-1.5">
    <div>
      <Label
        :as="isFieldset ? 'legend' : 'label'"
        class="block leading-4"
        :class="{ 'opacity-50': isDisabled }"
        :for="id"
        :hasError="hasError"
      >
        <slot name="label">
          <span class="flex items-center gap-x-1.5">
            {{ label }}

            <template v-if="isOptional">
              <VerticalDivider size="sm" />
              <span class="font-normal italic text-slate-500">Optional</span>
            </template>

            <slot name="info"></slot>
          </span>
        </slot>
      </Label>
    </div>

    <RadixSlot :id="inheritId ? id : undefined">
      <slot :id="id" />
    </RadixSlot>

    <slot name="helperText" />
    <FormHelperText v-if="helperText && !error" :class="{ 'opacity-50': isDisabled }">
      {{ helperText }}
    </FormHelperText>

    <slot name="error" />
    <FormError v-if="error">{{ error }}</FormError>
  </component>
</template>
