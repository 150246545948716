<script setup lang="ts">
import DataHookData = App.Sms.Data.DataHookData;

import ComposeMessageInput from '@/components/sms/ComposeMessageInput.vue';
import PageHeader from '@/components/pageHeader/PageHeader.vue';
import RecipientErrors from '@/domains/sms/components/RecipientErrors.vue';
import RecipientListDialog from '@/domains/sms/components/RecipientListDialog.vue';

const props = defineProps<{
  hasSmsEnabled: boolean;
  recipients: App.Sms.Data.SmsRecipientData[];
  smsRecipientTypeOptions: App.Base.Data.EnumData[];
  dataHooks: DataHookData[];
}>();

const recipientErrors = ref<string[] | null>(null);

const form = useForm<{
  body: string;
  recipient_type: App.Base.Data.EnumData;
  recipients: App.Sms.Data.SmsRecipientData[];
  scheduled_send_date: string | null;
  attachment_ids: App.Sms.Data.SmsAttachmentData[];
  ignore_recipients: App.Sms.Data.SmsRecipientData[];
}>({
  method: 'POST',
  url: route('sms.outbound.store'),
  fields: {
    recipients: props.recipients ?? [],
    recipient_type: props.smsRecipientTypeOptions[0],
    body: '',
    scheduled_send_date: null,
    attachment_ids: [],
    ignore_recipients: []
  },
  transform: (data) => ({
    ...data,
    attachment_ids: data.attachment_ids.map(({ id }) => id),
    recipient_type: data.recipient_type.value,
    recipients: data.recipients.map((recipient) => ({
      recipient_id: recipient.recipient_id,
      recipient_type: recipient.recipient_type,
      phone_number: recipient.phone_number
    }))
  })
});

function handleRecipientsUpdate(recipients: App.Sms.Data.SmsRecipientData[]) {
  form.fields.recipients = recipients;
}

function handleSendWithErrors() {
  form.fields.ignore_recipients = form.errors['recipient-errors'].map((error) => error.recipient);
  form.submit();
}
</script>


					<script lang="ts">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <div class="flex h-full flex-col md:flex-row">
    <div class="divide-y-slate-200 flex h-full flex-1 flex-col divide-y border-r border-slate-200">
      <PageHeader :padded="false" class="p-3" title="Compose Message" />

      <div v-if="hasSmsEnabled" class="bg-dot-pattern flex flex-1 flex-col justify-end">
        <ComposeMessageInput
          v-model="form.fields.body"
          v-model:attachments="form.fields.attachment_ids"
          v-model:scheduledSendDate="form.fields.scheduled_send_date"
          wrapperClass="border-t bg-white pb-3 pl-2"
          :error="form.errors.body"
          :hasError="recipientErrors !== null"
          :isLoading="form.processing"
          @send="form.submit"
        />
      </div>
    </div>

    <div class="hidden w-full max-w-sm flex-col md:flex">
      <RecipientListDialog
        :smsRecipientTypeOptions
        :recipients="form.fields.recipients"
        :errors="form.errors"
        @update:recipients="handleRecipientsUpdate"
        v-model:recipientType="form.fields.recipient_type"
      />
    </div>

    <RecipientErrors :errors="form.errors" @sendWithErrors="handleSendWithErrors" />
  </div>
</template>
