<script setup lang="ts">
import { HybridlyFormData } from '@/@types/global';
import Fieldset from '@/components/fieldset/Fieldset.vue';
import FormMultiProgramInput from '@/components/formComboboxInput/FormMultiProgramInput.vue';
import FormMultiUserInput from '@/components/formComboboxInput/FormMultiUserInput.vue';
import FormRadioInput from '@/components/formRadioInput/FormRadioInput.vue';
import FormRows from '@/components/formRows/FormRows.vue';
import FormSwitchInput from '@/components/formSwitchInput/FormSwitchInput.vue';
import { Selectable } from '@/components/selectBox/selectBox';
import { useAuth } from '@/hooks/useAuth';

defineProps<{
  customReport: App.Reports.Data.CustomReportData;
  form: HybridlyFormData<{
    share_template: boolean;
    is_system_template: boolean;
    program_ids: Selectable<number>[];
    user_ids: Selectable<number>[];
  }>;
}>();

const { hasPermission } = useAuth();
</script>

<template>
  <Fieldset title="Share report template">
    <FormSwitchInput
      label="Share this report template with other programs or users"
      helperText="This will allow selected users to use this template. They will not be able to edit the template."
      name="share_template"
      v-model:checked="form.fields.share_template"
    />

    <FormRows v-if="form.fields.share_template">
      <FormRadioInput
        v-if="hasPermission('create-system-report')"
        :value="form.fields.is_system_template ? 'true' : 'false'"
        @update:modelValue="(value) => (form.fields.is_system_template = value === 'true')"
        label="Is this a system template?"
        name="is_system_template"
        ariaLabel="Is this a system template?"
        :radioInputs="[
          {
            value: 'true',
            label: 'Yes, this is a system template',
            helperText: 'This template will be accessible to all users as a system template.'
          },
          {
            value: 'false',
            label: 'No, this will be shared with specific users/programs',
            helperText:
              'This template will only be accessible to users/programs you specifically share it with.'
          }
        ]"
      />

      <template v-if="form.fields.is_system_template === false">
        <FormMultiProgramInput
          :error="form.errors.program_ids"
          :modelValue="form.fields.program_ids"
          label="Programs"
          @update:modelValue="(value) => (form.fields.program_ids = value)"
        />
        <FormMultiUserInput
          label="Users"
          :error="form.errors.user_ids"
          :modelValue="form.fields.user_ids"
          @update:modelValue="(value) => (form.fields.user_ids = value)"
        />
      </template>
    </FormRows>
  </Fieldset>
</template>
