<script setup lang="ts">
type Block = App.Surveys.Data.SurveyBlockData;
type EnumData = App.Base.Data.EnumData;
type Survey = App.Surveys.Data.SurveyData;
type Choices = App.Surveys.Data.SurveyChoiceData[];

import type { HybridlyFormData } from '@/@types/global';
import type { Selectable } from '@/components/selectBox/selectBox';

import debounce from 'lodash/debounce';
import { Container, Draggable } from 'vue-dndrop';

import { ButtonColor, ButtonVariant } from '@/hooks/useButtonClasses';
import { useDragAndDrop } from '@/hooks/useDragAndDrop';

import { buildSelectableSurveyBlock } from '@/utils/buildSelectable';

import Alert from '@/components/alert/Alert.vue';
import Button from '@/components/button/Button.vue';
import EmptyCard from '@/components/emptyCard/EmptyCard.vue';
import Fieldset from '@/components/fieldset/Fieldset.vue';
import FormError from '@/components/formError/FormError.vue';
import FormListboxInput from '@/components/formListboxInput/FormListboxInput.vue';
import FormSwitchInput from '@/components/formSwitchInput/FormSwitchInput.vue';
import FormTextareaInput from '@/components/formTextareaInput/FormTextareaInput.vue';
import FormTextInput from '@/components/formTextInput/FormTextInput.vue';
import IconButton from '@/components/button/IconButton.vue';
import SurveyBlockPanelBody from '@/components/surveys/surveys/SurveyBlockPanelBody.vue';
import SurveyBlockPanelHeader from '@/components/surveys/surveys/SurveyBlockPanelHeader.vue';
import TextInput from '@/components/textInput/TextInput.vue';

import ElipsisDoubleVAlt from '@/icons/line/elipsis-double-v-alt.svg';
import InfoCircle from '@/icons/line/info-circle.svg';
import Plus from '@/icons/line/plus.svg';
import TimesCircle from '@/icons/line/times-circle.svg';

const props = defineProps<{
  block: Block;
  blockChoiceType: EnumData[];
  blockTypes: EnumData[];
  blockMatchColumns: EnumData[];
  choicesFromBlockOptions: Block[];
  form: HybridlyFormData<{
    block_text: string;
    block_type: Selectable<string>;
    choice_type: Selectable<string>;
    choices: Choices;
    choices_from_survey_block_id: number;
    selections_min_count: number;
    selections_max_count: number;
    placeholder: string;
    match_column: Selectable<string>;
    is_skippable: boolean;
    view_in_summary: boolean;
    allow_nullable: boolean;
  }>;
  referencedBlocks: Block[];
  selectedBlock?: number;
  survey: Survey;
}>();

const { dragAndDropItems, onDrop } = useDragAndDrop<App.Surveys.Data.SurveyChoiceData>(
  props.form.fields.choices
);

const isSelectingChoicesFromAnotherBlock = ref(!!props.block.choices_from_survey_block);

const computedBlockChoiceType = computed(() => [
  ...props.blockChoiceType,
  {
    value: null,
    label: 'Custom',
    description: null,
    header: null
  }
]);

const computedChoicesFromBlockOptions = computed<Selectable<number>[]>(() => {
  return props.choicesFromBlockOptions.map((block) =>
    buildSelectableSurveyBlock(block)
  ) as Selectable<number>[];
});

const debouncedSubmit = debounce(() => {
  props.form.submit();
}, 300);

function handleFormUpdate(
  key: string,
  value:
    | string
    | number
    | boolean
    | Selectable<string>
    | Selectable<number>
    | Selectable<string>[]
    | Selectable<number>[]
    | null
) {
  props.form.fields[key] = value;
  debouncedSubmit();
}

function handleFormChoicesUpdate(index: number, value: string | number | boolean | null) {
  props.form.fields.choices[index].choice_text = value;
  debouncedSubmit();
}

function handleBlockTypeUpdate(blockType) {
  if (blockType.value === props.form.initial.block_type.value) {
    props.form.reset();
    return;
  }

  props.form.fields.choice_type = null;
  props.form.fields.choices = [];
  props.form.fields.selections_min_count = null;
  props.form.fields.selections_max_count = null;
  props.form.fields.placeholder = null;
  props.form.fields.match_column = null;
  props.form.fields.is_skippable = false;
  props.form.fields.view_in_summary = false;
  props.form.fields.allow_nullable = false;

  props.form.fields.block_type = blockType;

  if (blockType.value === 'email') {
    props.form.fields.match_column = props.blockMatchColumns.filter(
      (matchColumn) => matchColumn.value === 'email'
    )[0];
  }

  if (blockType.value !== 'select' || blockType.value !== 'multiselect') {
    props.form.submit();
  }
}

function handleChoiceTypeUpdate(choiceType) {
  props.form.fields.choice_type = choiceType;

  if (
    props.form.fields.block_type.value === props.form.initial.block_type.value &&
    choiceType.value === props.form.initial.choice_type.value
  ) {
    props.form.resetFields('choices');
    props.form.submit();
    return;
  }

  props.form.fields.choices = [];

  if (choiceType.value !== null) {
    props.form.fields.choices_from_survey_block_id = null;
    props.form.submit();
  }
}

function handleChoicesFromSurveyBlockTypeToggle(value) {
  isSelectingChoicesFromAnotherBlock.value = value;

  if (value) {
    props.form.fields.choices = [];
    props.form.fields.choices_from_survey_block_id =
      props.form.initial.choices_from_survey_block_id;
    return;
  }

  props.form.fields.choices_from_survey_block_id = null;
  props.form.fields.choices = props.form.initial.choices;
}

function handleAddChoiceOption() {
  dragAndDropItems.value = [
    ...dragAndDropItems.value,
    {
      choice_text: ''
    } as unknown as App.Surveys.Data.SurveyChoiceData
  ];

  props.form.fields.choices = dragAndDropItems.value;
}

function handleChoiceOrderChange(event) {
  onDrop(event);
  useForm({
    method: 'PUT',
    preserveState: false,
    url: route('surveys.blocks.settings.choices.update', {
      survey: props.survey.id,
      block: props.block.id,
      choice: props.block.choices?.[event.removedIndex].id
    }),
    fields: {
      order: event.addedIndex
    },
    hooks: {
      success() {
        router.reload({ only: ['survey'] });
      }
    }
  }).submit();
}

function handleRemoveChoiceOption(index: number) {
  dragAndDropItems.value = dragAndDropItems.value.filter((_, i) => i !== index);
  props.form.fields.choices = dragAndDropItems.value;
  props.form.submit();
}

watch(
  () => props.block,
  () => {
    isSelectingChoicesFromAnotherBlock.value = !!props.block.choices_from_survey_block;
  },
  { deep: true }
);
</script>

<template>
  <SurveyBlockPanelBody>
    <template #header>
      <SurveyBlockPanelHeader
        :block="block"
        :href="
          route('surveys.blocks.show', {
            survey: survey.id,
            block: block.id
          })
        "
        :referencedBlocks="referencedBlocks"
        :survey="survey"
        title="Question Settings"
      >
        <div class="flex items-start gap-x-2 px-4 py-6">
          <label class="flex h-8 items-center text-sm font-bold text-slate-800"
            >{{ block.order + 1 }}.</label
          >
          <div class="flex grow flex-col gap-y-1.5">
            <TextInput
              :hasError="!!form.errors.block_text"
              :modelValue="form.fields.block_text"
              class="grow"
              placeholder="What is this question?"
              @update:modelValue="handleFormUpdate('block_text', $event)"
            />
            <FormError v-if="form.errors.block_text">{{ form.errors.block_text }}</FormError>
          </div>
        </div>
      </SurveyBlockPanelHeader>
    </template>
    <template v-if="block.block_text">
      <Fieldset title="Question settings">
        <FormListboxInput
          :error="form.errors.block_type"
          :modelValue="form.fields.block_type"
          :options="blockTypes"
          label="Type"
          placeholder="Select question type"
          @update:modelValue="handleBlockTypeUpdate"
        />
        <template
          v-if="
            form.fields.block_type?.value === 'text' || form.fields.block_type?.value === 'textarea'
          "
        >
          <FormTextInput
            :error="form.errors.placeholder"
            :modelValue="form.fields.placeholder"
            isOptional
            label="Text Field Placeholder"
            name="placeholder"
            @update:modelValue="handleFormUpdate('placeholder', $event)"
          />
        </template>
        <template
          v-if="
            form.fields.block_type?.value === 'text' ||
            form.fields.block_type?.value === 'email' ||
            form.fields.block_type?.value === 'date'
          "
        >
          <FormListboxInput
            :error="form.errors.match_column"
            :modelValue="form.fields.match_column"
            :options="blockMatchColumns"
            helperText="Column to match this block against when searching for a student"
            isOptional
            label="Match to"
            placeholder="Select column to match to"
            @update:modelValue="handleFormUpdate('match_column', $event)"
          />
        </template>
        <template v-if="form.fields.block_type?.value === 'instruction'">
          <FormTextareaInput
            :error="form.errors.instructional_text"
            :modelValue="form.fields.instructional_text"
            name="instructional_text"
            @update:modelValue="handleFormUpdate('instructional_text', $event)"
          />
        </template>
        <template
          v-if="
            form.fields.block_type?.value === 'select' ||
            form.fields.block_type?.value === 'multiselect'
          "
        >
          <FormListboxInput
            :error="form.errors.choice_type"
            :modelValue="form.fields.choice_type"
            :options="computedBlockChoiceType"
            label="Answer sources"
            placeholder="Select column to match to"
            @update:modelValue="handleChoiceTypeUpdate"
          />
          <div class="flex flex-col gap-y-1.5">
            <div
              v-if="form.fields.block_type?.value === 'multiselect'"
              class="flex items-center gap-x-2"
            >
              <FormTextInput
                :modelValue="form.fields.selections_min_count"
                isOptional
                label="Min choices"
                name="selections_min_count"
                @update:modelValue="handleFormUpdate('selections_min_count', $event)"
              />
              <FormTextInput
                :modelValue="form.fields.selections_max_count"
                isOptional
                label="Max choices"
                name="selections_max_count"
                @update:modelValue="handleFormUpdate('selections_max_count', $event)"
              />
            </div>
            <FormError v-if="form.errors.selections_min_count">{{
              form.errors.selections_min_count
            }}</FormError>
            <FormError v-if="form.errors.selections_max_count">{{
              form.errors.selections_max_count
            }}</FormError>
          </div>
        </template>
      </Fieldset>
      <template
        v-if="
          (form.fields.block_type?.value === 'select' ||
            form.fields.block_type?.value === 'multiselect') &&
          form.fields.choice_type?.value === null
        "
      >
        <Fieldset title="Options">
          <FormSwitchInput
            v-if="computedChoicesFromBlockOptions.length"
            label="Use options from another block"
            name="isSelectingChoicesFromAnotherBlock"
            :checked="isSelectingChoicesFromAnotherBlock"
            @update:checked="handleChoicesFromSurveyBlockTypeToggle"
          />
          <FormListboxInput
            v-if="isSelectingChoicesFromAnotherBlock"
            :error="form.errors.choices_from_survey_block_id"
            :modelValue="form.fields.choices_from_survey_block_id"
            :options="computedChoicesFromBlockOptions"
            label="Select block"
            @update:modelValue="handleFormUpdate('choices_from_survey_block_id', $event)"
          />
          <div v-if="!isSelectingChoicesFromAnotherBlock" class="flex flex-col gap-y-3.5">
            <div class="flex flex-col gap-y-1.5">
              <Container
                v-if="form.fields.choices.length"
                @drop="handleChoiceOrderChange"
                dragHandleSelector=".block-card-handle"
                class="flex flex-col gap-y-1.5"
              >
                <Draggable
                  v-for="(choice, index) in dragAndDropItems"
                  :key="choice.id"
                  class="flex shrink-0 flex-col gap-y-1.5"
                >
                  <div class="flex items-center gap-x-2">
                    <TextInput
                      :modelValue="choice.choice_text"
                      class="grow"
                      @update:modelValue="handleFormChoicesUpdate(index, $event)"
                    />
                    <div class="flex items-center gap-x-1">
                      <IconButton
                        :icon="ElipsisDoubleVAlt"
                        ariaLabel="Drag and drop choice"
                        class="block-card-handle !cursor-grab"
                        size="sm"
                        variant="invisible"
                      />
                      <IconButton
                        :icon="TimesCircle"
                        ariaLabel="Remove choice"
                        size="sm"
                        variant="invisible"
                        @click="handleRemoveChoiceOption(index)"
                      />
                    </div>
                    <FormError v-if="form.errors.choices?.[index]">{{
                      form.errors.choices?.[index].choice_text
                    }}</FormError>
                  </div>
                </Draggable>
              </Container>
              <EmptyCard
                v-else
                class="grow"
                description="Add a choice by clicking the button below"
                isDashed
                isRounded
                size="md"
                title="No choices added"
                variant="invisible"
              />
              <FormError v-if="form.errors.choices && !form.fields.choices.length">{{
                form.errors.choices
              }}</FormError>
            </div>
            <div>
              <Button
                :iconLeft="Plus"
                :color="ButtonColor.slate"
                :variant="ButtonVariant.outlined"
                @click="handleAddChoiceOption"
                >Add an option</Button
              >
            </div>
          </div>
        </Fieldset>
      </template>
      <template v-if="form.fields.block_type?.value === 'boolean'">
        <Fieldset title="Options">
          <FormSwitchInput
            label='Allow "I Don&apos;t know" response'
            name="allow_nullable"
            :checked="form.fields.allow_nullable"
            @update:checked="handleFormUpdate('allow_nullable', $event)"
          />
        </Fieldset>
      </template>
      <Fieldset v-if="form.fields.block_type?.value !== 'instruction'" title="Other">
        <FormSwitchInput
          label="This question can be skipped"
          name="is_skippable"
          :checked="form.fields.is_skippable"
          @update:checked="handleFormUpdate('is_skippable', $event)"
        />
        <FormSwitchInput
          label="Show this block in the responses table"
          name="view_in_summary"
          :checked="form.fields.view_in_summary"
          @update:checked="handleFormUpdate('view_in_summary', $event)"
        />
      </Fieldset>
    </template>
    <template v-else>
      <Alert
        :icon="InfoCircle"
        alignment="vertical"
        title="Block settings will be available once a you have selected a title for this question."
        variant="soft"
        color="secondary"
        hideClose
      />
    </template>
  </SurveyBlockPanelBody>
</template>
