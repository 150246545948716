<script setup lang="ts">
import { AvailableFilter } from '@/components/distiller/types';
import { Selectable } from '@/components/selectBox/selectBox';
import ComboboxInput from '@/components/selectBox/combobox/ComboboxInput.vue';

type FilterOperator = App.Search.Enums.FilterOperator;

const props = defineProps<{
  path: string;
  parentPath?: string;
  isDisabled?: boolean;
  isReadonly?: boolean;
  filter?: AvailableFilter;
  availableFilters: AvailableFilter[];
}>();

const emit = defineEmits<{
  replaceCurrentFilter: [newPath: string, operator: FilterOperator, filterType?: string];
}>();

const operatorsLength = computed(() => props.filter?.operators?.length ?? 0);

const selectedFilter = computed(() => {
  if (props.filter) {
    return {
      label: [props.filter.title, props.filter.description].filter(Boolean).join(' - '),
      value: props.filter.key
    };
  }
  return null;
});

const selectableFilters = computed<Selectable<string>[]>(() => {
  const availableFilterOptions = props.availableFilters.map(
    (filter: AvailableFilter): Selectable<string> => ({
      label: filter.title,
      value: filter.key,
      description: filter.description
    })
  );

  if (selectedFilter.value) {
    return [selectedFilter.value, ...availableFilterOptions];
  }

  return availableFilterOptions;
});

function handleUpdateFilterKey(selectedFilter: Selectable<string> | null) {
  if (!selectedFilter) {
    return;
  }

  const filter = props.availableFilters.find((f) => f.key === selectedFilter.value);
  if (!filter) {
    return;
  }

  const operator: FilterOperator | undefined = filter.operators?.[0]?.value;
  if (!operator) {
    return;
  }

  const newPath = props.parentPath
    ? `${props.parentPath}.${selectedFilter.value}`
    : selectedFilter.value;

  emit('replaceCurrentFilter', newPath, operator, filter.type);
}
</script>

<template>
  <div class="shrink-0" :class="[operatorsLength > 1 ? 'w-52' : 'w-72']">
    <ComboboxInput
      placeholder="Select a filter..."
      :options="selectableFilters.filter((opt) => opt.value !== selectedFilter?.value)"
      :modelValue="selectedFilter"
      :isDisabled="isDisabled || isReadonly"
      :isReadonly="isReadonly"
      @update:modelValue="handleUpdateFilterKey"
    />
  </div>
</template>
