<script setup lang="ts">
import { format, isPast, startOfDay } from 'date-fns';
import VueDatePicker from '@vuepic/vue-datepicker';

import { ButtonColor, ButtonSize, ButtonVariant } from '@/hooks/useButtonClasses';

import Button from '@/components/button/Button.vue';
import CheckboxInput from '@/components/checkboxInput/CheckboxInput.vue';

import EditAlt from '@/icons/line/edit-alt.svg';
import Save from '@/icons/line/save.svg';

import '@vuepic/vue-datepicker/dist/main.css';

interface Props {
  todo: App.Configuration.Data.TodoData;
}

const props = defineProps<Props>();

const shouldPreventInteractOutside = inject('shouldPreventInteractOutside') as Ref<boolean>;

const isEditing = ref(false);
const todoTextarea = ref<HTMLTextAreaElement | null>(null);

const isOverdue = computed(() => {
  if (props.todo.is_complete) {
    return false;
  }
  return isPast(startOfDay(new Date(props.todo.due_date)));
});

const form = useForm<
  Omit<App.Configuration.Data.TodoData, 'id' | 'created_at' | 'updated_at' | 'authorization'>
>({
  method: 'PUT',
  spoof: false,
  url: route('todos.update', { toDo: props.todo.id }),
  updateInitials: true,
  fields: {
    is_complete: props.todo.is_complete,
    text: props.todo.text,
    due_date: props.todo.due_date ?? new Date()
  },
  hooks: {
    success() {
      toggleEditing();
      router.reload({ only: ['todos'] });
    }
  }
});

function handleCheckChange(checked: boolean) {
  form.fields.is_complete = checked;
  form.submit();
}

function togglePreventInteractOutside() {
  shouldPreventInteractOutside.value = !shouldPreventInteractOutside.value;
}

function resizeTextarea() {
  if (todoTextarea.value) {
    todoTextarea.value.style.height = '1px';
    todoTextarea.value.style.height = todoTextarea.value.scrollHeight + 'px';
  }
}

function toggleEditing() {
  isEditing.value = !isEditing.value;
  if (isEditing.value) {
    nextTick(() => {
      resizeTextarea();
    });
    nextTick(() => {
      todoTextarea.value?.focus();
    });
  }
}

watch(
  () => form.fields.text,
  () => {
    nextTick(resizeTextarea);
  }
);

onMounted(() => {
  nextTick(resizeTextarea);
});
</script>

<template>
  <div
    class="flex flex-col items-stretch overflow-hidden rounded-lg border border-slate-200 bg-white transition-colors duration-150 ease-in-out focus-within:border-slate-400"
  >
    <div class="flex flex-row items-start gap-2 p-2">
      <CheckboxInput :checked="form.fields.is_complete" @update:checked="handleCheckChange" />
      <textarea
        v-if="isEditing"
        class="min-h-0 w-full resize-none border-0 p-0 text-[16px] leading-5 text-slate-700 focus:outline-none focus:ring-0 md:text-sm"
        :disabled="todo.is_complete || form.processing"
        name="text"
        ref="todoTextarea"
        v-model="form.fields.text"
        @keydown.enter.exact.prevent="form.submit"
        @input="resizeTextarea"
      />
      <p
        v-else
        class="whitespace-pre-wrap text-sm leading-5 text-slate-700"
        :class="{ 'line-through': todo.is_complete }"
      >
        {{ todo.text }}
      </p>
    </div>
    <div
      class="border-t border-slate-200 px-2 py-1"
      :class="{
        'bg-amber-100': isOverdue,
        'bg-zinc-50': !isOverdue
      }"
    >
      <div class="flex items-center justify-between gap-x-1" @click.stop>
        <div class="flex items-center gap-x-1 text-sm text-slate-500">
          <p class="leading-5">Due:</p>
          <VueDatePicker
            v-if="isEditing"
            :clearable="false"
            :disabled="todo.is_complete"
            :enableTimePicker="false"
            :required="true"
            :teleport="true"
            format="MMM dd, yyyy"
            position="right"
            v-model="form.fields.due_date"
            @closed="togglePreventInteractOutside"
            @open="togglePreventInteractOutside"
          >
            <template #dp-input="{ value }">
              <input
                :disabled="todo.is_complete"
                :value="value"
                class="flex-inline cursor-pointer rounded-md border border-slate-200 bg-white px-1 py-0 text-2xs text-slate-500 focus:ring-0"
                name="due_date"
                readonly
              />
            </template>
          </VueDatePicker>
          <p v-else class="font-semibold leading-5">
            {{ format(new Date(todo.due_date), 'MMM dd, yyyy') }}
          </p>
        </div>
        <div v-if="isEditing && !todo.is_complete" class="flex items-center gap-x-1">
          <Button
            :color="ButtonColor.slate"
            :size="ButtonSize.xs"
            :variant="ButtonVariant.invisible"
            @click="toggleEditing"
          >
            Cancel
          </Button>
          <Button
            :color="ButtonColor.primary"
            :disabled="form.processing || form.fields.text.trim().length === 0"
            :iconLeft="Save"
            :isLoading="form.processing"
            :size="ButtonSize.xs"
            :variant="ButtonVariant.solid"
            @click="form.submit"
          >
            Save
          </Button>
        </div>
        <Button
          v-if="!isEditing && !todo.is_complete"
          :color="ButtonColor.primary"
          :iconLeft="EditAlt"
          :size="ButtonSize.xs"
          :variant="ButtonVariant.soft"
          @click="toggleEditing"
          >Edit</Button
        >
      </div>
    </div>
  </div>
</template>
