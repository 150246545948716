<script setup lang="ts">
import { toRaw } from 'vue';
import { RouterLink } from 'hybridly/vue';
import Tooltip from '@/components/tooltip/Tooltip.vue';
import Badge from '@/components/badge/Badge.vue';
import { BadgeColor, BadgeShape, BadgeSize, BadgeVariant } from '@/hooks/useBadgeClasses';

const props = withDefaults(
  defineProps<{
    label: string;
    href: string;
    icon: Component;
    isCollapsed: boolean;
    badgeCount?: number;
    isActive?: boolean;
  }>(),
  {
    isCollapsed: false,
    isActive: false,
    badgeCount: 0
  }
);

const rawIcon = toRaw(props.icon);

const iconClasses = computed(() => ({
  'w-5 h-5': props.isCollapsed,
  'w-4 h-4': !props.isCollapsed,
  'opacity-70': !props.isActive
}));

const containerClasses = computed(() => ({
  'rounded-md px-2.5 py-2 hover:bg-slate-200 text-secondary-900': true,
  'w-[39px] h-[36px]': props.isCollapsed,
  'bg-slate-200 font-semibold': props.isActive
}));
</script>

<template>
  <RouterLink :href="href" class="m-1 block -outline-offset-1">
    <template v-if="isCollapsed">
      <Tooltip side="right" :showArrow="false" :sideOffset="8">
        <template v-slot:trigger>
          <span :class="containerClasses" class="flex items-center justify-center">
            <component :is="rawIcon" :class="iconClasses" class="text-secondary-900" />
          </span>
        </template>
        {{ label }}
      </Tooltip>
    </template>
    <template v-else>
      <span :class="containerClasses" class="flex items-center gap-2.5">
        <component :is="rawIcon" :class="iconClasses" class="text-secondary-900" />
        <span class="min-w-0 flex-1">
          {{ label }}
        </span>
        <Badge
          v-if="badgeCount > 0"
          class="pointer-events-none"
          :color="BadgeColor.primary"
          :size="BadgeSize.sm"
          :variant="BadgeVariant.solid"
          :shape="BadgeShape.pill"
          :label="badgeCount"
        />
      </span>
    </template>
  </RouterLink>
</template>
