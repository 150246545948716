<script setup lang="ts">
import FormError from '@/components/formError/FormError.vue';
import TextInput from '@/components/textInput/TextInput.vue';

defineProps<{
  block: App.Surveys.Data.SurveyBlockData;
  errors?: Record<string, string>;
  value?: string;
}>();

const emit = defineEmits<{
  'update:modelValue': [value: boolean];
}>();

function handleValueUpdate(value) {
  emit('update:modelValue', value);
}
</script>

<template>
  <div class="flex w-full flex-col gap-1.5">
    <TextInput
      :modelValue="value"
      :name="`${block.id}`"
      :placeholder="block.placeholder ?? 'e.g. fullname@mail.com'"
      @update:modelValue="(value) => handleValueUpdate(value)"
    />
    <FormError v-if="errors?.value">{{ errors.value }}</FormError>
  </div>
</template>
