<script setup lang="ts">
import type { ShowDataImportProps } from '@/domains/bulkinator/views/data-imports/ShowDataImport.vue';

import Badge from '@/components/badge/Badge.vue';
import DefinitionList from '@/components/definitionList/DefinitionList.vue';
import DefinitionListItem from '@/components/definitionList/DefinitionListItem.vue';
import InformationCollapsible from '@/components/collapsible/InformationCollapsible.vue';
import Slideout from '@/components/slideout/Slideout.vue';
import Button from '@/components/button/Button.vue';

import Calendar from '@/icons/line/calendar.svg';
import CheckDouble from '@/icons/line/check-double.svg';
import Clock from '@/icons/line/clock.svg';
import DownloadAlt from '@/icons/line/download-alt.svg';
import ExclamationCircle from '@/icons/line/exclamation-circle.svg';
import ElipsisCircle from '@/icons/line/elipsis-circle.svg';
import Import from '@/icons/line/import.svg';
import Label from '@/icons/line/label.svg';
import ListUi from '@/icons/line/list-ui.svg';
import SortAmountDown from '@/icons/line/sort-amount-down.svg';
import Stopwatch from '@/icons/line/stopwatch.svg';
import User from '@/icons/line/user.svg';
import BadgeBooleanList from '@/components/badge/BadgeBooleanList.vue';
import DataImportResult from './DataImportResult.vue';

defineProps<ShowDataImportProps>();

const { show, close } = useDialog();
</script>

<template>
  <Slideout title="Data Import Information" :isOpen="show" @onClose="close" :icon="Import">
    <InformationCollapsible title="Data Import Information">
      <DefinitionList>
        <DefinitionListItem :description="dataImport.user?.name" :icon="User" title="User">
          <Badge :label="dataImport.user?.name" variant="soft" size="md" />
        </DefinitionListItem>
        <DefinitionListItem
          :description="dataImport.data_import_type?.name ?? 'No data import type'"
          :icon="Label"
          title="Data Input Type"
        />
        <DefinitionListItem
          :description="formatDate(dataImport.created_at) || 'No create date'"
          :icon="Calendar"
          title="Created on"
        />
        <DefinitionListItem
          :description="dataImport.run_time || 'No run time'"
          :icon="Stopwatch"
          title="Run Time"
        />
        <DefinitionListItem
          :description="formatTime(dataImport.started_at) || 'Not started'"
          :icon="Clock"
          title="Started at"
        />
        <DefinitionListItem
          :description="formatTime(dataImport.finished_at) || 'Not finished'"
          :icon="Clock"
          title="Finished at"
        />
        <DefinitionListItem
          :description="dataImport.row_count || 'No rows'"
          :icon="ListUi"
          title="Rows"
        />
        <DefinitionListItem
          :description="dataImport.rows_processed_count || 'No rows processed'"
          :icon="SortAmountDown"
          title="Rows Processed"
        />
        <DefinitionListItem
          :description="dataImport.success_results_count || 'No successful rows'"
          :icon="CheckDouble"
          title="Successful Rows"
        />
        <DefinitionListItem
          :description="dataImport.error_results_count || 'No issues'"
          :icon="ExclamationCircle"
          title="Issues"
        />
        <DefinitionListItem v-if="dataImport.file_url" :icon="DownloadAlt" title="File">
          <Button
            :href="dataImport.file_url"
            target="_blank"
            isExternalLink
            size="sm"
            variant="outlined"
            color="secondary"
          >
            Download file used for import
          </Button>
        </DefinitionListItem>
        <DefinitionListItem :icon="ElipsisCircle" title="Other">
          <BadgeBooleanList
            :items="{
              Successful: !!dataImport.is_successful,
              'Dry Run': !!dataImport.is_dry_run
            }"
          />
        </DefinitionListItem>
      </DefinitionList>
    </InformationCollapsible>
    <InformationCollapsible title="Errors" v-if="dataImport.error_results?.length">
      <div>
        <DataImportResult
          v-for="error in dataImport.error_results"
          :key="error.id"
          :result="error"
        />
      </div>
    </InformationCollapsible>
  </Slideout>
</template>
