<script setup lang="ts">
import { Selectable } from '@/components/selectBox/selectBox';
import { SelectableState } from '@/utils/states';
import { SelectableCountry } from '@/utils/countries';

type EnumData = App.Base.Data.EnumData;

import CenteredContent from '@/components/content/CenteredContent.vue';
import InstituteForm from '@/components/institutes/InstituteForm.vue';

defineProps<{
  programLengths: Array<EnumData>;
  selectivities: Array<EnumData>;
  returnUrl?: string;
}>();

const form = useForm({
  method: 'POST',
  url: route('institutes.store'),
  fields: {
    name: '',
    program_length: '',
    selectivity: null as Selectable<string> | null,
    hbcu: false,
    css_required: false,
    address: '',
    city: '',
    state: null as SelectableState | null,
    country: null as SelectableCountry | null,
    zip_code: '',
    waiver_fee: '',
    ipeds: null
  },
  transform: (fields) => ({
    ...fields,
    selectivity: fields.selectivity?.value,
    state: fields.state?.value,
    state_abbreviation: fields.state?.state_abbreviation,
    country: fields.country?.value,
    country_abbreviation: fields.country?.country_abbreviation
  })
});
</script>


					<script lang="ts">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <CenteredContent>
    <InstituteForm
      :programLengths="programLengths"
      :selectivities="selectivities"
      :form="form"
      :returnUrl="returnUrl"
    />
  </CenteredContent>
</template>
