<script setup lang="ts">
import { RouterLink } from 'hybridly/vue';

import { ButtonColor, ButtonSize, ButtonVariant } from '@/hooks/useButtonClasses';
import { useAuth } from '@/hooks/useAuth';

import Button from '@/components/button/Button.vue';
import Divider from '@/components/divider/Divider.vue';
import IconButton from '@/components/button/IconButton.vue';

import AlignLeft from '@/icons/line/align-left.svg';
import Calendar from '@/icons/line/calendar.svg';
import CheckCircle from '@/icons/line/check-circle.svg';
import CheckSquare from '@/icons/line/check-square.svg';
import Cog from '@/icons/line/cog.svg';
import ElipsisDoubleVAlt from '@/icons/line/elipsis-double-v-alt.svg';
import Envelope from '@/icons/line/envelope.svg';
import Paragraph from '@/icons/line/paragraph.svg';
import Phone from '@/icons/line/phone.svg';
import PlusCircle from '@/icons/line/plus-circle.svg';
import SliderH from '@/icons/line/slider-h.svg';
import Text from '@/icons/line/text.svg';
import ToggleOn from '@/icons/line/toggle-on.svg';

const props = defineProps<{
  block: App.Surveys.Data.SurveyBlockData;
  selected?: boolean;
  survey: App.Surveys.Data.SurveyData;
}>();

const emit = defineEmits<{
  onAddQuestionClick: [value: number];
}>();

const addingQuestion = ref(false);

const { hasPermission } = useAuth();

function getBlockTypeIcon(value) {
  return {
    select: CheckCircle,
    multiselect: CheckSquare,
    text: Text,
    textarea: AlignLeft,
    instruction: Paragraph,
    range: SliderH,
    boolean: ToggleOn,
    date: Calendar,
    email: Envelope,
    phone: Phone
  }[value];
}

function handleAddQuestionClick() {
  addingQuestion.value = true;
  emit('onAddQuestionClick', props.block.id);
}
</script>

<template>
  <div class="relative flex overflow-hidden rounded-lg border border-slate-200 bg-white">
    <div
      v-if="hasPermission('update-survey')"
      @click.prevent
      class="block-card-handle flex cursor-grab items-center justify-center border-r border-slate-200 px-2 text-slate-500 hover:bg-slate-50/50 hover:text-slate-700 active:cursor-grabbing"
    >
      <ElipsisDoubleVAlt class="h-5 w-5" />
    </div>
    <RouterLink
      class="flex grow items-center justify-between gap-x-2 px-3 py-4 transition duration-150 ease-in-out hover:cursor-pointer"
      :class="{
        'border-slate-500 shadow-md': selected,
        'border-slate-200': !selected,
        'px-2': hasPermission('update-survey'),
        'px-4': !hasPermission('update-survey')
      }"
      :href="
        route('surveys.blocks.show', {
          survey: survey.id,
          block: block.id
        })
      "
      :options="{ preserveState: true, preserveScroll: true, only: ['block'] }"
    >
      <div class="flex grow flex-col gap-y-0.5 overflow-hidden text-sm font-medium">
        <h3 class="flex gap-x-2 text-slate-900">
          <span class="font-bold">{{ block.order + 1 }}.</span>
          <span class="truncate">{{ block.block_text }}</span>
        </h3>
        <div class="flex gap-x-1 text-slate-500">
          <div class="size-5 rounded bg-slate-100 p-[0.1875rem]">
            <component :is="getBlockTypeIcon(block.block_type.value)" />
          </div>
          <span>{{ block.block_type.label }}</span>
        </div>
      </div>
      <IconButton
        v-if="hasPermission('update-survey')"
        :href="
          route('surveys.blocks.settings.show', {
            survey: survey.id,
            block: block.id
          })
        "
        :icon="Cog"
        ariaLabel="Edit block settings"
        size="sm"
        variant="invisible"
        @click.stop
      />
    </RouterLink>
    <Divider v-if="selected && hasPermission('update-survey')">
      <Button
        :color="ButtonColor.slate"
        :iconLeft="PlusCircle"
        :isDisabled="addingQuestion"
        :isLoading="addingQuestion"
        :size="ButtonSize.sm"
        :variant="ButtonVariant.invisible"
        @click="handleAddQuestionClick"
      >
        Add a question
      </Button>
    </Divider>
  </div>
</template>
