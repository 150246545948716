<script setup lang="ts">
import FormTextInput from '@/components/formTextInput/FormTextInput.vue';

defineProps<{
  customField: App.Configuration.Data.ProgramStudentFieldData;
  error?: string;
  modelValue?: string | number | null;
}>();

const emit = defineEmits<{
  'update:modelValue': [value: string | number | null];
}>();

function onValueChange(event: string | number | null): void {
  emit('update:modelValue', event);
}
</script>

<template>
  <FormTextInput
    :error
    :label="customField.field_label"
    :modelValue="modelValue"
    :name="customField.field_key"
    @update:modelValue="onValueChange"
  />
</template>
