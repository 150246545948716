<script setup lang="ts">
import Dialog from '@/components/dialog/Dialog.vue';

const emit = defineEmits<{
  (event: 'sendWithErrors'): void;
}>();

const props = defineProps<{
  errors: Record<string, unknown>;
}>();

const recipientErrors = ref<App.Sms.Data.SmsRecipientValidationData[] | null>(null);

watch(
  () => props.errors['recipient-errors'],
  (value) => {
    recipientErrors.value = value as App.Sms.Data.SmsRecipientValidationData[] | null;
  }
);

function handleSendWithErrors() {
  emit('sendWithErrors');
  recipientErrors.value = null;
}
</script>

<template>
  <Dialog
    v-if="recipientErrors"
    isOpen
    title="Recipient Errors"
    confirmButtonLabel="Send to all valid recipients"
    cancelButtonLabel="Cancel"
    contentClass="max-w-xl w-full"
    @onCancel="recipientErrors = null"
    @onClose="recipientErrors = null"
    @onConfirm="handleSendWithErrors"
  >
    <div class="space-y-4">
      <h3 class="text-base font-semibold">The following recipients have errors</h3>
      <ul class="list-disc space-y-2 pl-4 text-sm">
        <li v-for="error in recipientErrors" :key="error.error">
          {{ error.error }}
        </li>
      </ul>
    </div>
  </Dialog>
</template>
