<script setup lang="ts" generic="T extends SelectableValue">
import type { Selectable, SelectableValue } from '@/components/selectBox/selectBox';

import { useStudentGroupsSearch } from '@/hooks/search/useStudentGroupsSearch';

import FormMultiComboboxInput, {
  FormMultiComboboxProps
} from '@/components/formComboboxInput/FormMultiComboboxInput.vue';

const props = defineProps<Omit<FormMultiComboboxProps<number>, 'options' | 'searchable'>>();

defineEmits<{
  'update:modelValue': [Selectable<number>[]];
}>();

const { studentGroupOptions, isLoading, onQueryChange } = useStudentGroupsSearch(
  props.searchFilters
);
</script>

<template>
  <FormMultiComboboxInput
    v-bind="$props"
    :isLoading="isLoading"
    :options="studentGroupOptions"
    :onFilter="onQueryChange"
    @update:modelValue="(value) => $emit('update:modelValue', value)"
    @open="() => onQueryChange('')"
  />
</template>
