import { getSmsUnreadCount } from '@/api/ThreadApi';
import { onUserEvent } from '@/hooks/useWebsockets';

export function useUnreadSmsCount(): ComputedRef<number> {
  const count = ref<number>(0);

  onUserEvent('sms-unread-count-updated', refreshCount);

  onMounted(refreshCount);

  async function refreshCount() {
    count.value = await getSmsUnreadCount().then((data) => data.unread_sms_count);
  }

  return computed(() => count.value);
}
