<script setup lang="ts">
type CustomTestData = App.Configuration.Data.CustomTestData;
type EnumData = App.Base.Data.EnumData;
type GroupedInteractionData = App.Students.Data.GroupedInteractionData;

import type { Selectable } from '@/components/selectBox/selectBox';

import { ButtonColor, ButtonVariant } from '@/hooks/useButtonClasses';

import Button from '@/components/button/Button.vue';
import CenteredContent from '@/components/content/CenteredContent.vue';
import DangerDialog from '@/components/dialog/DangerDialog.vue';
import Fieldset from '@/components/fieldset/Fieldset.vue';
import Form from '@/components/form/Form.vue';
import FormDelete from '@/components/formDelete/FormDelete.vue';
import FormFooter from '@/components/formFooter/FormFooter.vue';
import FormHeader from '@/components/formHeader/FormHeader.vue';
import FormMultiStudentInput from '@/components/formComboboxInput/FormMultiStudentInput.vue';
import StudentInteractionForm from '@/components/students/studentActions/StudentInteractionForm.vue';

const props = defineProps<{
  customTests: CustomTestData[];
  interaction: GroupedInteractionData;
  mediums: EnumData[];
  meetingLengths: EnumData[];
  testTypes: EnumData[];
  topics: EnumData[];
  types: EnumData[];
}>();

const isCancelDialogOpen = ref(false);

const { onBackForward } = useBackForward();

// TODO: Fix intecepting the back button
onBackForward(() => {
  router.abort();
});

const form = useForm({
  method: 'PUT',
  url: route('interactions.update', { interaction: props.interaction.id }),
  fields: {
    student_ids:
      props.interaction?.students?.map((student) => buildSelectableStudent(student)) ||
      ([] as Selectable<number>[]),
    notes: props.interaction?.notes || '',
    topics: props.topics.filter(
      (topic) => props.interaction.topics?.map((t) => t.value).includes(topic.value)
    ),
    application_assisted_institute_ids: (
      props.interaction.application_assisted_applications || []
    ).map((application) => buildSelectableInstitute(application.institute)) as
      | Selectable<number>[]
      | null,
    award_letter_review_institute_ids: (
      props.interaction.award_letter_review_applications || []
    ).map((application) => buildSelectableInstitute(application.institute)) as
      | Selectable<number>[]
      | null,
    scholarship_names: props.interaction?.student_scholarships?.map((studentScholarship) =>
      buildSelectableScholarship(studentScholarship.scholarship)
    ) as Selectable<number>[] | null,
    tests: props.interaction.tests?.map((test) => buildTest(test)) ?? [],
    types: props.interaction.types as Selectable<string>[] | null,
    institute_id: buildSelectableInstitute(
      props.interaction.institute
    ) as Selectable<number> | null,
    medium: props.interaction.medium as Selectable<string> | null,
    meeting_length: props.interaction.meeting_length as Selectable<string> | null,
    date: props.interaction.date || ''
  },
  transform: (fields) => ({
    ...fields,
    student_ids: fields.student_ids?.map((student) => student.value) ?? null,
    topics: fields.topics?.map((type) => type.value) ?? [],
    application_assisted_institute_ids:
      fields.application_assisted_institute_ids?.map((institute) =>
        institute ? institute.value : null
      ) ?? null,
    award_letter_review_institute_ids:
      fields.award_letter_review_institute_ids?.map((institute) =>
        institute ? institute.value : null
      ) ?? null,
    scholarship_names: fields.scholarship_names?.map((scholarship) => scholarship.label) ?? null,
    tests:
      fields.tests && fields.tests.length > 0
        ? fields.tests.map((testItem) => ({
            type:
              testItem.type?.value === 'custom-test' && testItem.test?.value
                ? testItem.test?.value
                : testItem.type?.value,
            date: testItem.date,
            test: testItem.test?.value
          }))
        : null,
    types: fields.types?.map((type) => type.value) ?? null,
    institute_id: fields.institute_id?.value,
    medium: fields.medium?.value,
    meeting_length: fields.meeting_length?.value
  })
});

function buildTest(test) {
  const isCustomTest = !props.testTypes.find((type) => type.value === test.type);

  return {
    type: isCustomTest
      ? { value: 'custom-test', label: 'Custom Test' }
      : props.testTypes.find((testType) => testType.value === test.type),
    date: test.date,
    test: isCustomTest
      ? buildSelectableCustomTest(
          props.customTests.find((customTest) => customTest.name === test.type)
        )
      : null
  };
}

function handleToggleCancelDialog() {
  if (form.isDirty) {
    isCancelDialogOpen.value = !isCancelDialogOpen.value;
    return;
  }
  handleRedirect();
}

function handleRedirect() {
  router.navigate({ url: route('interactions.index') });
}
</script>


					<script lang="ts">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <CenteredContent>
    <Form @submit.prevent="form.submit">
      <template #header>
        <FormHeader
          canClose
          title="Edit a Interaction"
          description="Use the form below to edit the interaction."
          @onClose="handleToggleCancelDialog"
        />
      </template>
      <Fieldset title="Selected students">
        <FormMultiStudentInput
          :error="form.errors.student_ids as unknown as string"
          :modelValue="form.fields.student_ids as Selectable<number>[]"
          label="Students"
          placeholder="Select student"
          @update:modelValue="(value) => (form.fields.student_ids = value)"
        />
      </Fieldset>
      <StudentInteractionForm
        :testTypes="testTypes"
        :form="form"
        :mediums="mediums"
        :meetingLengths="meetingLengths"
        :topics="topics"
        :types="types"
      />
      <template #footer>
        <FormFooter>
          <Button
            type="submit"
            :color="ButtonColor.primary"
            :isDisabled="form.processing || !form.isDirty"
            :isLoading="form.processing"
            :variant="ButtonVariant.solid"
            >Confirm</Button
          >
          <Button
            @click="handleToggleCancelDialog"
            :color="ButtonColor.slate"
            :variant="ButtonVariant.outlined"
            type="button"
            >Back to interactions</Button
          >
        </FormFooter>
      </template>
      <template #delete>
        <FormDelete
          :route="route('interactions.delete', { interaction: interaction.id })"
          recordType="interaction"
          modalMessage="Are you sure you want to delete this interaction? This will remove the interaction for all associated students. This action is permanent and cannot be reverted."
        />
      </template>
    </Form>
  </CenteredContent>
  <DangerDialog
    title="Cancel editing interaction"
    message="Are you sure you want to cancel editing this interaction? All changes will be lost."
    :isOpen="isCancelDialogOpen"
    cancelButtonLabel="Stay on this page"
    confirmButtonLabel="Go back to interactions"
    @onCancel="handleToggleCancelDialog"
    @onClose="handleToggleCancelDialog"
    @onConfirm="handleRedirect"
  />
</template>
