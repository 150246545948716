<script setup lang="ts">
import type { ShowStudentScholarshipProps } from '@/domains/students/views/ShowStudentScholarship.vue';

import { useDialogHelpers } from '@/hooks/useDialogHelpers';

import Badge from '@/components/badge/Badge.vue';
import DangerDialog from '@/components/dialog/DangerDialog.vue';
import DefinitionList from '@/components/definitionList/DefinitionList.vue';
import DefinitionListItem from '@/components/definitionList/DefinitionListItem.vue';
import IconButton from '@/components/button/IconButton.vue';
import InformationCollapsible from '@/components/collapsible/InformationCollapsible.vue';
import Slideout from '@/components/slideout/Slideout.vue';

import TrashIcon from '@/icons/line/trash.svg';
import EditAlt from '@/icons/line/edit-alt.svg';
import InfoCircle from '@/icons/line/info-circle.svg';
import GraduationCap from '@/icons/line/graduation-cap.svg';

const props = defineProps<ShowStudentScholarshipProps>();

const { show, close } = useDialog();
const { isDialogOpen, toggleDialog } = useDialogHelpers();

const form = useForm({
  method: 'DELETE',
  spoof: false,
  url: route('student-scholarships.delete', { studentScholarship: props.studentScholarship.id }),
  fields: {},
  hooks: {
    success() {
      close();
    }
  }
});

async function handleStudentScholarshipDelete() {
  await form.submit();
  toggleDialog();
}
</script>

<template>
  <Slideout
    :title="studentScholarship.scholarship?.name ?? 'Scholarship Application'"
    :isOpen="show"
    @onClose="close"
    :icon="GraduationCap"
  >
    <template #badge>
      <Badge
        :label="studentScholarship.status?.label ?? 'No Status'"
        variant="soft"
        shape="rounded"
      />
    </template>
    <template #actions>
      <IconButton
        v-if="studentScholarship.authorization.update"
        :href="
          route('students.student-scholarships.edit', {
            student: student.id,
            studentScholarship: studentScholarship.id
          })
        "
        :icon="EditAlt"
        ariaLabel="Edit scholarship application"
        size="sm"
        variant="soft"
      />
      <IconButton
        v-if="studentScholarship.authorization.delete"
        color="danger"
        :icon="TrashIcon"
        ariaLabel="Move scholarship application to trash"
        size="sm"
        variant="soft"
        @click="toggleDialog"
      />
    </template>
    <InformationCollapsible title="General information">
      <DefinitionList>
        <DefinitionListItem
          :icon="InfoCircle"
          :description="studentScholarship.scholarship?.name ?? 'No scholarship name'"
          title="Name"
        />
        <DefinitionListItem title="Status">
          <Badge :label="studentScholarship.status.label" variant="soft" size="md" />
        </DefinitionListItem>
        <DefinitionListItem
          :description="
            studentScholarship.amount ? `$${studentScholarship.amount}.00` : 'No amount'
          "
          title="Amount"
        />
        <DefinitionListItem
          :description="studentScholarship.years_renewable"
          title="Years renewable"
        />
        <DefinitionListItem
          :description="
            studentScholarship.amount
              ? `$${studentScholarship.amount * studentScholarship.years_renewable}.00`
              : 'No total amount'
          "
          title="Total amount"
        />
      </DefinitionList>
    </InformationCollapsible>
  </Slideout>
  <DangerDialog
    title="Delete Scholarship Application"
    message="Are you sure you want to delete this scholarship application? This action is permanent and cannot be reverted."
    confirmButtonLabel="Delete"
    :isOpen="isDialogOpen"
    :isLoading="form.processing"
    @onConfirm="handleStudentScholarshipDelete"
    @onCancel="toggleDialog"
    @onClose="toggleDialog"
  />
</template>
