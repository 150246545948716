<script setup lang="ts">
import Dialog from '@/components/dialog/Dialog.vue';
import Slideout from '@/components/slideout/Slideout.vue';
import StudentParentSingleComboBoxInput from '@/components/studentParent/StudentParentSingleComboBoxInput.vue';
import Fieldset from '@/components/fieldset/Fieldset.vue';
import Divider from '@/components/divider/Divider.vue';
import Button from '@/components/button/Button.vue';
import FormTextInput from '@/components/formTextInput/FormTextInput.vue';
import FormError from '@/components/formError/FormError.vue';
import ExclamationCircle from '@/icons/line/exclamation-circle.svg';
import IconUsersAlt from '@/icons/line/users-alt.svg';
import IconUser from '@/icons/line/user.svg';
import IconEnvelope from '@/icons/line/envelope.svg';
import IconPhone from '@/icons/line/phone.svg';
import IconGraduationCap from '@/icons/line/graduation-cap.svg';
import Badge from '@/components/badge/Badge.vue';

import { Selectable } from '@/components/selectBox/selectBox';

import DefinitionList from '@/components/definitionList/DefinitionList.vue';
import DefinitionListItem from '@/components/definitionList/DefinitionListItem.vue';

const props = defineProps<{
  student: App.Students.Data.StudentData;
  existingParent: App.Students.Data.StudentParentData | null;
  isOpen: boolean;
}>();

const emit = defineEmits<{
  cancel: [void];
  close: [void];
  confirm: [void];
}>();

const addExistingParentConfirmationDialogOpen = ref(false);

const addExistingParentForm = useForm({
  method: 'POST',
  url: route('students.parents-guardians.attach', { student: props.student.id }),
  fields: {
    parent_id: null as Selectable<number> | null,
    confirm_parent: null as boolean | null,
    parent_associated_with_different_school: null as boolean | null
  },
  transform: (fields) => ({
    ...fields,
    parent_id: fields.parent_id?.value
  }),
  hooks: {
    error: (error) => {
      if (error.parent_associated_with_different_school) {
        addExistingParentConfirmationDialogOpen.value = true;
      }
    }
  }
});

const addMatchedExistingParentDialogOpen = ref(false);
const matchedParentWithEmailOrPhoneNumberDialogTitle = ref('');

const addNewParentForm = useForm({
  method: 'POST',
  url: route('students.parents-guardians.create', {
    student: props.student.id
  }),
  fields: {
    first_name: '',
    last_name: '',
    email: '',
    cell_phone: '',
    normalized_cell_phone: null as string | null,
    existing_parent_with_email: null as boolean | null,
    existing_parent_with_phone: null as boolean | null,
    already_attached: null as boolean | null
  },
  hooks: {
    error: (error) => {
      if (
        error.existing_parent_with_email ||
        (error.existing_parent_with_phone && props.existingParent)
      ) {
        const email = error.existing_parent_with_email ? 'email' : '';
        const phone = error.existing_parent_with_phone ? 'phone number' : '';
        const conjunction = email && phone ? 'and' : '';
        matchedParentWithEmailOrPhoneNumberDialogTitle.value = `A parent/guardian with this ${email} ${conjunction} ${phone} already exists`;
        addMatchedExistingParentDialogOpen.value = true;
      }
    }
  }
});

function handleAddMatchedExistingParent() {
  addExistingParentForm.clearErrors();
  addExistingParentForm.fields['confirm_parent'] = true;
  addExistingParentForm.fields['parent_id'] = props.existingParent
    ? {
        label: '',
        value: props.existingParent.id
      }
    : null;
  addExistingParentForm.submit();
  addMatchedExistingParentDialogOpen.value = false;
}

function handleConfirmAddExistingParent() {
  addExistingParentForm.clearErrors();
  addExistingParentForm.fields['confirm_parent'] = true;
  addExistingParentForm.submit();
  addExistingParentConfirmationDialogOpen.value = false;
}

function handleClose() {
  addExistingParentForm.resetFields();
  addNewParentForm.resetFields();
  emit('close');
}
</script>

<template>
  <Slideout
    title="Add a Parent/Guardian"
    :icon="IconUsersAlt"
    :isOpen="isOpen"
    @onClose="handleClose"
  >
    <form @submit.prevent="addExistingParentForm.submit">
      <Fieldset title="Add an existing parent/guardian">
        <StudentParentSingleComboBoxInput
          v-model="addExistingParentForm.fields.parent_id"
          @update:modelValue="addExistingParentForm.clearErrors()"
          :hasError="addExistingParentForm.errors.parent_id"
          class="flex-1"
          placeholder="Select a parent/guardian"
        />
        <FormError
          v-if="addExistingParentForm.errors.parent_id"
          :error="addExistingParentForm.errors.parent_id"
        />
        <div class="flex justify-end">
          <Button
            @click="addExistingParentForm.submit()"
            :disabled="
              !addExistingParentForm.fields.parent_id ||
              addExistingParentForm.processing ||
              addExistingParentForm.hasErrors
            "
            :isLoading="addExistingParentForm.processing"
            >Add parent/guardian</Button
          >
        </div>
      </Fieldset>
    </form>
    <Divider label="or" class="my-8" />
    <form @submit.prevent="addNewParentForm.submit">
      <Fieldset
        title="Add a new parent/guardian"
        :disabled="addExistingParentConfirmationDialogOpen"
      >
        <div class="flex flex-col gap-y-2">
          <FormTextInput
            :modelValue="addNewParentForm.fields.first_name"
            label="First name"
            name="first_name"
            :error="addNewParentForm.errors.first_name"
            @update:modelValue="(value) => (addNewParentForm.fields.first_name = value)"
          />
          <FormTextInput
            :modelValue="addNewParentForm.fields.last_name"
            label="Last name"
            name="last_name"
            :error="addNewParentForm.errors.last_name"
            @update:modelValue="(value) => (addNewParentForm.fields.last_name = value)"
          />
          <FormTextInput
            :modelValue="addNewParentForm.fields.email"
            label="Email"
            name="email"
            :error="addNewParentForm.errors.email"
            @update:modelValue="(value) => (addNewParentForm.fields.email = value)"
          />
          <FormTextInput
            :modelValue="addNewParentForm.fields.cell_phone"
            label="Phone"
            name="cell_phone"
            :error="
              addNewParentForm.errors.cell_phone || addNewParentForm.errors.normalized_cell_phone
            "
            @update:modelValue="(value) => (addNewParentForm.fields.cell_phone = value)"
          />
        </div>
        <div class="flex justify-end">
          <Button
            type="submit"
            :isLoading="addNewParentForm.processing"
            @click="addNewParentForm.submit"
            :isDisabled="
              !addNewParentForm.fields.first_name &&
              !addNewParentForm.fields.last_name &&
              !addNewParentForm.fields.email &&
              !addNewParentForm.fields.cell_phone
            "
          >
            Create parent/guardian
          </Button>
        </div>
      </Fieldset>
    </form>
  </Slideout>
  <Dialog
    title="Are you sure you want to add this parent/guardian to this student?"
    :isOpen="addExistingParentConfirmationDialogOpen"
    @onClose="addExistingParentConfirmationDialogOpen = false"
    @onCancel="addExistingParentConfirmationDialogOpen = false"
    @onConfirm="handleConfirmAddExistingParent"
    confirmButtonLabel="Add parent/guardian"
    :icon="ExclamationCircle"
    :isLoading="addExistingParentForm.processing"
    :message="addExistingParentForm.errors['parent_associated_with_different_school']"
  />
  <Dialog
    :title="matchedParentWithEmailOrPhoneNumberDialogTitle"
    :isOpen="addMatchedExistingParentDialogOpen"
    @onClose="addMatchedExistingParentDialogOpen = false"
    @onCancel="addMatchedExistingParentDialogOpen = false"
    @onConfirm="handleAddMatchedExistingParent"
    confirmButtonLabel="Attach parent/guardian"
  >
    <template #message>
      <div class="">
        <h2 class="px-3 py-1.5 text-left text-xs font-bold uppercase text-slate-500">
          Parent/Guardian Information
        </h2>
        <DefinitionList class="px-3 pt-4">
          <DefinitionListItem
            title="First Name"
            :icon="IconUser"
            :description="existingParent?.first_name ?? '--'"
          />
          <DefinitionListItem
            title="Last Name"
            :icon="IconUser"
            :description="existingParent?.last_name ?? '--'"
          />
          <DefinitionListItem title="Email" :icon="IconEnvelope">
            <div
              class="py-1 text-sm leading-4 text-slate-900"
              :class="{ 'font-semibold': addNewParentForm.errors.existing_parent_with_email }"
            >
              {{ existingParent?.email ?? '--' }}
            </div>
          </DefinitionListItem>
          <DefinitionListItem title="Phone" :icon="IconPhone">
            <div
              class="py-1 text-sm leading-4 text-slate-900"
              :class="{ 'font-semibold': addNewParentForm.errors.existing_parent_with_phone }"
            >
              {{ existingParent?.cell_phone ?? '--' }}
            </div>
          </DefinitionListItem>
          <DefinitionListItem
            :icon="IconGraduationCap"
            title="Students"
            :description="!existingParent?.students?.length ? 'No students' : undefined"
          >
            <div v-if="existingParent?.students?.length" class="flex flex-wrap gap-1">
              <Badge
                v-for="student in existingParent.students"
                :key="student.id"
                :iconLeft="IconGraduationCap"
                :label="student.name"
                :href="route('students.show', { student: student.id })"
                variant="outlined"
                shape="rounded"
              />
            </div>
          </DefinitionListItem>
        </DefinitionList>
      </div>
      <p class="mt-8">Would you like to attach this parent/guardian to {{ props.student.name }}?</p>
    </template>
  </Dialog>
</template>
