<script setup lang="ts">
import GridCard from './GridCard.vue';
import DateTime from '@/components/dateTime/DateTime.vue';
import { search } from '@/api/SchoolApi';
import { ref, watch, onMounted } from 'vue';

interface Props {
  schoolId: number | undefined;
}

const props = defineProps<Props>();
const school = ref<App.Configuration.Data.SchoolData | null>(null);

async function fetchSchool() {
  try {
    const schools = await search({
      filters: {
        id: props.schoolId
      }
    });
    school.value = schools[0] || null;
  } catch (error) {
    console.error('Error fetching school:', error);
  }
}

onMounted(fetchSchool);
watch(() => props.schoolId, fetchSchool);
</script>

<template>
  <GridCard
    v-if="school"
    title="DoE FAFSA Total"
    :subtitle="school?.name"
    class="col-span-6 lg:col-span-3"
  >
    <figure
      v-if="school?.fafsa_doe_count && school?.fafsa_doe_update_date"
      class="flex flex-col gap-4 px-6 py-5"
    >
      <div class="flex items-baseline gap-2">
        <span class="text-2xl font-bold">{{ school?.fafsa_doe_count }}</span>
        <span class="text-sm font-medium leading-4 text-slate-600"> as of </span>
        <DateTime
          v-if="school?.fafsa_doe_update_date"
          format="date"
          :date="school?.fafsa_doe_update_date"
        />
      </div>
      <figcaption class="text-sm font-medium leading-4 text-slate-600">
        The count above shows the number of students who have a completed FAFSA on file with the US
        US Department of Education. Remember that more students may have submitted their FAFSA, but
        the forms have not been processed yet. The DOE does not count FAFSA submissions for
        undocumented students or students aged 19+.
      </figcaption>
    </figure>
    <div v-else class="px-6 py-5">
      <p class="text-sm font-medium leading-4 text-slate-600">
        There is no DoE data for this school.
      </p>
    </div>
  </GridCard>
</template>
