<script setup lang="ts">
import CalendarStop from '@/icons/line/calendar-stop.svg';
</script>


					<script lang="ts">
					import __hybridly_layout_0 from '~/resources/domains/surveys/layouts/TwoColumns.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <div class="flex flex-col items-center gap-y-4 pt-8 text-center">
    <div class="flex size-13 items-center justify-center rounded-full bg-slate-700">
      <CalendarStop class="size-9 fill-white" />
    </div>
    <h2 class="text-2xl font-bold leading-7 text-slate-800">Survey Closed</h2>
    <p class="text-sm leading-5 text-slate-500">
      This survey is now closed and is no longer accepting submissions.<br />
      You can now close this page.
    </p>
  </div>
</template>
