<script setup lang="ts">
import MenuItem from '@/components/menu/MenuItem.vue';
import GridIcon from '@/icons/line/grid.svg';

const props = defineProps<{
  uploadSession: App.Bulkinator.Data.UploadSessionData;
}>();

const form = useForm({
  url: route('upload-sessions.export', { uploadSession: props.uploadSession.id }),
  method: 'GET',
  fields: {}
});
</script>

<template>
  <MenuItem
    label="Export current session as Excel"
    :iconLeft="GridIcon"
    @click="form.submit()"
    :isLoading="form.processing"
  />
</template>
