import pluralize from 'pluralize';
import GraduationCapIcon from '@/icons/line/graduation-cap.svg';
import UsersAltIcon from '@/icons/line/users-alt.svg';
import StudentGroupsIcon from '@/icons/line/student-groups.svg';
import PhoneIcon from '@/icons/line/phone.svg';
import BriefcaseIcon from '@/icons/line/briefcase.svg';

export function getRecipientTypeLabel(recipientType: string) {
  return (
    {
      student: 'Student',
      student_parent: 'Parent/Guardian',
      student_group: 'Student Group',
      caseload: 'Caseload',
      phone_number: 'Direct Phone Number'
    }[recipientType] ?? 'Unknown'
  );
}

export function getRecipientTypeIcon(recipientType: string) {
  return {
    student: GraduationCapIcon,
    student_parent: UsersAltIcon,
    student_group: StudentGroupsIcon,
    caseload: BriefcaseIcon,
    phone_number: PhoneIcon
  }[recipientType];
}

export function pluralizeRecipientTypeLabel(recipientType: string) {
  return pluralize(getRecipientTypeLabel(recipientType), 2);
}
