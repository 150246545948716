<script setup lang="ts">
import GlobalSearch from '@/components/header/GlobalSearch.vue';
import NotificationBell from '@/components/header/NotificationBell.vue';
import Todos from '@/components/todos/Todos.vue';
import BugReport from '@/components/header/BugReport.vue';
import Canny from '@/components/header/Canny.vue';
import IconButton from '@/components/button/IconButton.vue';

import EllipsisV from '@/icons/line/ellipsis-v.svg';
import Times from '@/icons/line/times.svg';

const [isMobileButtonsOpen, toggleMobileButtonsOpen] = useToggle(false);

defineProps<{
  isSmallScreen: boolean;
}>();
</script>

<template>
  <div class="flex flex-shrink-0 items-start justify-end gap-1.5">
    <template v-if="!isSmallScreen">
      <GlobalSearch />
      <NotificationBell />
      <Todos :isSmallScreen="isSmallScreen" />
      <BugReport />
      <Canny />
    </template>
    <template v-else>
      <GlobalSearch />
      <IconButton
        v-if="isMobileButtonsOpen"
        :icon="Times"
        ariaLabel="Close mobile menu"
        variant="soft"
        @click="() => toggleMobileButtonsOpen()"
      />
      <IconButton
        v-else
        :icon="EllipsisV"
        ariaLabel="Open mobile menu"
        variant="soft"
        @click="() => toggleMobileButtonsOpen()"
      />
      <div
        v-if="isMobileButtonsOpen"
        class="absolute inset-x-0 top-[57px] flex w-screen flex-col items-stretch gap-1.5 rounded-b-lg bg-white p-1.5 shadow-md"
      >
        <NotificationBell :isSmallScreen="isSmallScreen" />
        <Todos :isSmallScreen="isSmallScreen" />
        <BugReport :isSmallScreen="isSmallScreen" />
        <Canny :isSmallScreen="isSmallScreen" />
      </div>
    </template>
  </div>
</template>
