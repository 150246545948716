<script setup lang="ts">
import type { Selectable } from '@/components/selectBox/selectBox';

import CenteredContent from '@/components/content/CenteredContent.vue';
import Fieldset from '@/components/fieldset/Fieldset.vue';
import EditReportTemplateLayout from '@/domains/reports/components/EditReportTemplateLayout.vue';
import EditReportTemplateFiltersForm from '@/domains/reports/components/form/EditReportTemplateFiltersForm.vue';

type ReportTemplateData = App.Reports.Data.ReportTemplateData;

defineProps<{
  template: ReportTemplateData;
  globalDistiller: App.Search.Data.DistillerData;
  grades: Selectable<number>[];
  smsGroups: Selectable<string>[];
  campaignOptions: Selectable<string>[];
  shouldShowGlobalFilters: boolean;
}>();
</script>


					<script lang="ts">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <EditReportTemplateLayout :template="template">
    <template #default="{ form }">
      <CenteredContent :padded="false" size="md">
        <div class="space-y-10">
          <Fieldset title="Report filters">
            <EditReportTemplateFiltersForm
              :campaignOptions="campaignOptions"
              :customReport="template.report_type"
              :form="form"
              :globalDistiller="globalDistiller"
              :grades="grades"
              :smsGroups="smsGroups"
              :shouldShowGlobalFilters="shouldShowGlobalFilters"
            />
          </Fieldset>
        </div>
      </CenteredContent>
    </template>
  </EditReportTemplateLayout>
</template>
