<script setup lang="ts">
import { PopoverContent, PopoverPortal, PopoverRoot, PopoverTrigger } from 'radix-vue';

import { ButtonColor } from '@/hooks/useButtonClasses';

import IconButton from '@/components/button/IconButton.vue';

import CheckSquare from '@/icons/line/check-square.svg';

const shouldPreventInteractOutside = ref(false);

provide('shouldPreventInteractOutside', shouldPreventInteractOutside);

function handleInteractOutside(event: Event) {
  if (shouldPreventInteractOutside.value) {
    event.preventDefault();
  }
}
</script>

<template>
  <PopoverRoot>
    <PopoverTrigger asChild>
      <IconButton
        :icon="CheckSquare"
        ariaLabel="Open todos"
        size="md"
        variant="soft"
        :color="ButtonColor.slate"
      />
    </PopoverTrigger>
    <PopoverPortal>
      <PopoverContent
        align="end"
        side="bottom"
        :sideOffset="5"
        class="flex h-full max-h-[calc(100vh-3rem)] w-screen flex-col items-stretch overflow-hidden rounded-lg border border-slate-200 bg-white shadow-md will-change-[transform,opacity] data-[state=open]:data-[side=bottom]:animate-slide-down-and-fade md:h-[600px] md:max-h-[calc(100vh-2rem)] md:w-[480px]"
        @interactOutside.stop="handleInteractOutside"
      >
        <slot />
      </PopoverContent>
    </PopoverPortal>
  </PopoverRoot>
</template>
