import axios from 'axios';
import AutomatedActionTypeData = App.Sms.Data.AutomatedCampaigns.AutomatedActionTypeData;
import EnumData = App.Base.Data.EnumData;
import AutomatedActionType = App.Sms.Enums.AutomatedActionType;

export async function index(): Promise<AutomatedActionTypeData[]> {
  return axios.get(route('api.sms.action-types.index')).then((res) => res.data);
}

export async function indexKeyOptions(actionType: AutomatedActionType): Promise<EnumData[]> {
  return axios
    .get(route('api.sms.action-types.key-options.index', { actionType }))
    .then((res) => res.data);
}
