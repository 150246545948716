<script setup lang="ts">
import { toRaw } from 'vue';

const props = withDefaults(
  defineProps<{
    icon: Component;
    color?: 'primary' | 'secondary' | 'slate' | 'success' | 'warning' | 'danger' | 'info';
    iconClasses?: string;
    size?: string;
    isCircle?: boolean;
  }>(),
  {
    size: 'w-4 h-4',
    color: 'slate',
    isCircle: false
  }
);

const styleClasses = computed(() => {
  return {
    primary: 'bg-primary-100 text-primary-800',
    secondary: 'bg-secondary-100 text-secondary-800',
    slate: 'bg-slate-100 text-slate-700',
    success: 'bg-green-100 text-green-800',
    warning: 'bg-amber-100 text-amber-800',
    danger: 'bg-red-100 text-red-800',
    info: 'bg-sky-100 text-sky-800'
  }[props.color];
});

const rawIcon = toRaw(props.icon);
</script>

<template>
  <div
    :class="[
      styleClasses,
      {
        'rounded-full p-1': isCircle,
        'rounded-lg p-2': !isCircle
      }
    ]"
  >
    <component :is="rawIcon" :class="[iconClasses, size]" />
  </div>
</template>
