<script setup lang="ts">
import { ButtonColor, ButtonSize, ButtonVariant } from '@/hooks/useButtonClasses';

import Badge from '@/components/badge/Badge.vue';
import Button from '@/components/button/Button.vue';
import Copy from '@/components/copy/Copy.vue';
import CopyButton from '@/components/button/CopyButton.vue';
import MetricCardContainer from '@/components/metricCard/MetricCardContainer.vue';
import PageHeader from '@/components/pageHeader/PageHeader.vue';
import ShowSurveyPageNav from '@/components/surveys/surveys/ShowSurveyPageNav.vue';
import VerticalDivider from '@/components/verticalDivider/VerticalDivider.vue';

import Eye from '@/icons/line/eye.svg';

defineProps<{
  survey: App.Surveys.Data.SurveyData;
  title: string;
  tableTitle?: string;
}>();
</script>


					<script lang="ts">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <div class="divide-y-slate-200 flex h-full flex-col divide-y">
    <div class="flex items-center gap-2.5 px-4 pb-2 pt-2.5">
      <div class="min-w-0 flex-1 shrink">
        <div class="flex min-w-0 flex-grow flex-col gap-2.5 lg:flex-row lg:items-center">
          <div class="flex min-w-0 items-center gap-2.5">
            <h1 class="truncate text-base font-semibold leading-6">{{ title }}</h1>
            <Badge :label="survey.status.label" shape="rounded" size="md" variant="soft" />
          </div>
          <div class="mr-auto flex items-center gap-x-2.5">
            <template v-if="survey.status.value === 'active'">
              <Copy
                v-if="survey.url"
                :text="survey.url"
                class="hidden max-w-xs md:flex"
                label="Copy URL"
                variant="input"
              />
              <CopyButton
                v-if="survey.url"
                :color="ButtonColor.slate"
                :size="ButtonSize.sm"
                :text="survey.url"
                :variant="ButtonVariant.soft"
                class="md:hidden"
                label=""
              />
            </template>
            <template v-else>
              <VerticalDivider />
              <Button
                :color="ButtonColor.slate"
                :iconLeft="Eye"
                :size="ButtonSize.sm"
                :variant="ButtonVariant.outlined"
                >Preview</Button
              >
            </template>
          </div>
        </div>
      </div>
      <div class="hidden xl:flex">
        <ShowSurveyPageNav :survey="survey" />
      </div>
    </div>
    <div class="block shrink-0 overflow-x-auto px-4 py-0.5 xl:hidden">
      <ShowSurveyPageNav :survey="survey" />
    </div>
    <div
      class="divide-y-slate-200 flex grow flex-col divide-y overflow-y-auto"
      v-if="$slots.table && tableTitle"
    >
      <PageHeader :title="tableTitle" headingAs="h2">
        <template #rightButtons>
          <slot name="tableRightButtons" />
        </template>
      </PageHeader>
      <div class="divide-y-slate-200 flex grow flex-col divide-y">
        <slot name="table" />
      </div>
    </div>
    <MetricCardContainer v-if="$slots.metricCards">
      <slot name="metricCards" />
    </MetricCardContainer>
    <div class="overflow-y-auto" v-if="$slots.fullWidth">
      <slot name="fullWidth" />
    </div>
    <div v-if="$slots.leftColumn || $slots.default" class="grow overflow-hidden">
      <div class="divide-x-slate-200 flex h-full divide-x overflow-hidden">
        <div class="flex w-full max-w-115">
          <slot name="leftColumn" />
        </div>
        <div class="flex flex-1 grow flex-col gap-y-2 overflow-y-auto">
          <div class="relative grow overflow-y-auto bg-slate-50 px-4 pb-8 pt-16 lg:px-10 lg:pt-52">
            <slot />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
