<script setup lang="ts" generic="T extends SelectableValue">
import type { Selectable, SelectableValue } from '@/components/selectBox/selectBox';

import { useStudentGroupsSearch } from '@/hooks/search/useStudentGroupsSearch';

import FormComboboxInput, {
  FormComboboxProps
} from '@/components/formComboboxInput/FormComboboxInput.vue';

const props = defineProps<Omit<FormComboboxProps<number>, 'options'>>();

defineEmits<{
  'update:modelValue': [Selectable<number> | null];
}>();

const { studentGroupOptions, isLoading, onQueryChange } = useStudentGroupsSearch(
  props.searchFilters
);
</script>

<template>
  <FormComboboxInput
    v-bind="$props"
    :isLoading="isLoading"
    :options="studentGroupOptions"
    :onFilter="onQueryChange"
    :modelValue="modelValue as Selectable<number>"
    @update:modelValue="(value) => $emit('update:modelValue', value as Selectable<number>)"
    @open="() => onQueryChange('')"
  />
</template>
