<script setup lang="tsx">
import StarFilledIcon from '@/icons/line/star-filled.svg';
import StarIcon from '@/icons/line/star.svg';
import IconButton from '@/components/button/IconButton.vue';

const props = defineProps<{
  template: App.Reports.Data.ReportTemplateData;
  isFavorite: boolean;
}>();

const form = useForm({
  url: route('report-templates.favorite.toggle', { reportTemplate: props.template.id }),
  fields: {},
  only: ['favoriteTemplateIds'],
  preserveState: true,
  preserveScroll: true
});

const iconComponent = computed(() => (props.isFavorite ? StarFilledIcon : StarIcon));

const tooltipContent = computed(() => {
  if (props.isFavorite) {
    return 'Remove this template from your favorites.';
  }
  return 'Mark this template as one of your favorites.';
});

function handleFavoriteToggle() {
  form.submit();
}
</script>

<template>
  <IconButton
    :icon="iconComponent"
    :tooltipProps="{ side: 'top', showArrow: true }"
    :ariaLabel="tooltipContent"
    variant="invisible"
    color="warning"
    size="sm"
    :isLoading="form.processing"
    @click="handleFavoriteToggle"
  />
</template>
