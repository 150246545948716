<script setup lang="tsx" generic="T extends Record<string, unknown>">
import { ref, computed } from 'vue';
import type { HybridlyRow, TableColumn } from '@/components/table/Table';

import { useSortableRows } from '@/components/table/useSortableRows';
import { useTableRowClick } from '@/hooks/useTableRowClick';

import DownloadSubmissionsButton from '@/domains/surveys/components/DownloadSubmissionsButton.vue';
import EmptyState from '@/components/emptyState/ThreadsEmptyState.vue';
import FilterColumnsDialog from '@/components/surveys/surveys/FilterColumnsDialog.vue';
import Pagination from '@/components/pagination/Pagination.vue';
import ShowSurveyPageLayout from '@/components/surveys/surveys/ShowSurveyPageLayout.vue';
import Table from '@/components/table/Table.vue';
import TableBadgesCell from '@/components/table/TableBadgesCell.vue';
import TableBooleanCell from '@/components/table/TableBooleanCell.vue';
import TableDateCell from '@/components/table/TableDateCell.vue';
import TableDateTimeCell from '@/components/table/TableDateTimeCell.vue';
import TableIconCell from '@/components/table/TableIconCell.vue';
import TableWrappedTextCell from '@/components/table/TableWrappedTextCell.vue';

import CheckCircle from '@/icons/line/check-circle.svg';
import QuestionCircle from '@/icons/line/question-circle.svg';

type SurveySessionRow = HybridlyRow<App.Surveys.Data.SurveySessionData>;

const TextareaBlockCell = ({ column, row }) => (
  <TableWrappedTextCell text={row.value(column.key)} />
);
const BooleanBlockCell = ({ column, row }) =>
  row.value(column.key)?.length && (
    <TableBooleanCell
      labelNull="I don't know"
      boolean={
        {
          Yes: true,
          No: false,
          "I don't know": null
        }[row.value(column.key)] ?? null
      }
      nullable={true}
    />
  );
const DateBlockCell = ({ column, row }) => <TableDateCell date={row.value(column.key)} />;
const RangeBlockCell = ({ column, row }) => (
  <TableBadgesCell badgesToDisplay={1} items={row.value(column.key) ?? []} />
);
const SelectBlockCell = ({ column, row }) => (
  <TableBadgesCell badgesToDisplay={1} items={row.value(column.key) ?? []} />
);
const MultiselectBlockCell = ({ column, row }) => (
  <TableBadgesCell badgesToDisplay={2} items={row.value(column.key) ?? []} />
);

const { handleRowClick } = useTableRowClick();

const stickyStartColumns = 1;
const stickyEndColumns = 1;

const props = defineProps<{
  survey: App.Surveys.Data.SurveyData;
  sessions: {
    columns: (TableColumn<T> & { block_type: string })[];
    records: Paginator<App.Surveys.Data.SurveySessionData>;
  };
}>();

const columnOptions = computed(() => {
  return props.sessions.columns.filter((_, index) => {
    const startIndex = stickyStartColumns - 1;
    const endIndex = props.sessions.columns.length - stickyEndColumns;
    return index > startIndex && index < endIndex;
  });
});

const selectedColumns = ref(columnOptions.value.map((column) => column.key));

const columns = computed(() =>
  transformColumnsArray([
    {
      key: 'created_at',
      label: 'Date',
      cellRender: ({ row }) => <TableDateTimeCell date={row.created_at as string} />,
      isSortable: true,
      width: '120px'
    },
    ...props.sessions.columns.filter((option) => selectedColumns.value.includes(option.key)),
    {
      key: 'match_status',
      cellRender: ({ row }) => (
        <TableIconCell
          icon={row.match_status ? CheckCircle : QuestionCircle}
          iconColor={row.match_status ? 'success' : 'warning'}
          iconTooltipLabel={row.match_status ? 'Matched student' : 'No matched student'}
        />
      ),
      width: '44px'
    }
  ])
) as unknown as TableColumn<Record<string, unknown>>[];

const rows = computed(() => transformRecordsArray(props.sessions.records.data));

const { sortedRows, currentSort, handleSort } = useSortableRows(rows, '-created_at');

const blockTypeToComponent = (blockType?: string) => {
  switch (blockType) {
    case 'textarea':
      return TextareaBlockCell;
    case 'boolean':
      return BooleanBlockCell;
    case 'date':
      return DateBlockCell;
    case 'range':
      return RangeBlockCell;
    case 'select':
      return SelectBlockCell;
    case 'multiselect':
      return MultiselectBlockCell;
    default:
      return null;
  }
};

const generateColumnsObject = ({ key, label, cellRender, isSortable, width }: TableColumn<T>) => ({
  key,
  label,
  ...(cellRender && { cellRender }),
  ...(isSortable && { isSortable }),
  ...(width && { width })
});

function transformColumnsArray(
  columns: (TableColumn<T> & { block_type?: string })[]
): TableColumn<Record<string, unknown>>[] {
  return columns.map((column) => {
    const cellRender = blockTypeToComponent(column.block_type);

    return generateColumnsObject({
      cellRender: cellRender || undefined,
      isSortable: false,
      ...column
    });
  }) as unknown as TableColumn<Record<string, unknown>>[];
}

function transformRecordsArray(
  records: App.Surveys.Data.SurveySessionData[]
): Record<string, unknown>[] {
  return records.map((record) => {
    return {
      ...record,
      value: (column: string) => record[column]
    };
  });
}

function handleSurveySessionRowClick(event: MouseEvent, row: SurveySessionRow) {
  const url = route('surveys.sessions.show', {
    survey: props.survey.id,
    session: row.id
  });
  handleRowClick(url, event);
}

function updateColumns(newColumns: string[]) {
  selectedColumns.value = newColumns;
}
</script>


					<script lang="tsx">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <ShowSurveyPageLayout :survey="survey" :title="survey.name" tableTitle="Surveys Responses">
    <template #tableRightButtons>
      <DownloadSubmissionsButton :survey="survey" />
      <FilterColumnsDialog
        :columnOptions="columnOptions"
        :selectedOptions="selectedColumns"
        @updateColumns="updateColumns"
      />
    </template>
    <template #table>
      <Table
        :columns="columns"
        :currentSort="currentSort"
        :getRowId="(row) => row.id as number"
        :rows="sortedRows"
        :stickyStartColumns="stickyStartColumns"
        :stickyEndColumns="stickyEndColumns"
        clickableRows
        @clickRow="($event, row) => handleSurveySessionRowClick($event, row as SurveySessionRow)"
        @sort="handleSort"
      >
        <template #emptyState>
          <EmptyState
            inline
            title="No tests found"
            description="Create a new test by clicking the button below"
            actionLabel="Add a test"
            actionHref="#"
          />
        </template>
      </Table>
      <Pagination :pagination="props.sessions.records" class="mt-auto" />
    </template>
  </ShowSurveyPageLayout>
</template>
