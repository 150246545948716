<script setup lang="ts">
import TrashIcon from '@/icons/line/trash.svg';
import ListboxInput from '@/components/selectBox/listbox/ListboxInput.vue';
import IconButton from '@/components/button/IconButton.vue';
import { Selectable, SelectableValue } from '@/components/selectBox/selectBox';
import RelationFilterInput from '@/components/distiller/RelationFilterInput.vue';
import { ButtonColor } from '@/hooks/useButtonClasses';
import { FilterConditionData, OutboundMessageFilterData } from './types';
import ComboboxInput from '@/components/selectBox/combobox/ComboboxInput.vue';
import FormError from '@/components/formError/FormError.vue';
import MultiComboboxInput from '@/components/selectBox/combobox/MultiComboboxInput.vue';
import FormHelperText from '@/components/formHelperText/FormHelperText.vue';

type FilterConditionType = App.Sms.Enums.FilterConditionType;
type FilterConditionValue = App.Sms.Enums.FilterConditionValue;

const props = defineProps<{
  filter: OutboundMessageFilterData;
  filterConditions: FilterConditionData[];
  filterConditionTypes: Selectable<FilterConditionType>[];
  isPaused: boolean;
  errors?: Record<string, string>;
}>();

const emit = defineEmits<{
  remove: [];
  filterChange: [filter: OutboundMessageFilterData];
}>();

const errorsToDisplay = computed(() => {
  return Object.values(props.errors ?? {})
    .map((errors) => {
      if (typeof errors === 'string') {
        return [errors];
      }

      return Object.values(errors);
    })
    .flat();
});

const selectedFilterCondition = computed(() => findConditionType(props.filter.filter_type?.value));

const helpText = computed(() => {
  if (!selectedFilterCondition.value) {
    return null;
  }

  if (selectedFilterCondition.value.uses_filter_relations) {
    const verb = props.filter.value?.value === 'include' ? 'Includes' : 'Excludes';

    return `${verb} students that ${selectedFilterCondition.value.help_text}`;
  }

  const verb = props.filter.value?.value === 'yes' ? 'are' : 'are not';

  return `Includes students that ${verb} marked as ${selectedFilterCondition.value.type.label}.`;
});

function findConditionType(filterType: FilterConditionType | undefined) {
  return props.filterConditions.find((condition) => condition.type.value === filterType);
}

function handleFilterChange(changes: Partial<OutboundMessageFilterData>): void {
  emit('filterChange', {
    ...props.filter,
    ...changes
  });
}

function handleTypeChange(nextType: Selectable<FilterConditionType>): void {
  const selectedConditionType = findConditionType(nextType.value);

  handleFilterChange({
    filter_type: nextType,
    value: selectedConditionType?.values[0],
    filter_relations: selectedConditionType?.uses_filter_relations ? [] : null
  });
}

function handleValueChange(value: Selectable<FilterConditionValue> | null): void {
  handleFilterChange({
    value
  });
}

function handleRelationChange(selected: Selectable<SelectableValue>[]): void {
  handleFilterChange({
    filter_relations: selected
  });
}
</script>

<template>
  <li class="space-y-2 rounded-xl bg-slate-50 p-2">
    <div class="flex items-center gap-2">
      <div class="w-52">
        <ComboboxInput
          :isDisabled="!isPaused"
          :options="filterConditionTypes"
          :modelValue="filter.filter_type"
          :hasError="!!errors?.filter_type"
          @update:modelValue="(selected) => selected && handleTypeChange(selected)"
        />
      </div>
      <div class="min-w-36">
        <ListboxInput
          v-if="selectedFilterCondition"
          :isDisabled="!isPaused"
          :hasError="!!errors?.value"
          :options="selectedFilterCondition?.values ?? []"
          :modelValue="filter.value"
          @update:modelValue="(selected) => handleValueChange(selected)"
        />
      </div>
      <div class="w-full min-w-0 flex-1">
        <RelationFilterInput
          v-if="selectedFilterCondition?.search_entity"
          :hasError="!!errors?.filter_relations"
          :isDisabled="!isPaused"
          :filter="{ searchEntity: selectedFilterCondition?.search_entity }"
          :modelValue="filter.filter_relations as unknown as Selectable<SelectableValue>[]"
          @update:modelValue="(selected) => handleRelationChange(selected)"
        />
        <MultiComboboxInput
          v-if="selectedFilterCondition?.enum_options"
          :hasError="!!errors?.filter_relations"
          :isDisabled="!isPaused"
          :options="selectedFilterCondition?.enum_options"
          :modelValue="filter.filter_relations as unknown as Selectable<SelectableValue>[]"
          @update:modelValue="(selected) => handleRelationChange(selected)"
        />
      </div>
      <IconButton
        v-if="isPaused"
        :color="ButtonColor.danger"
        :icon="TrashIcon"
        ariaLabel="Remove filter"
        size="sm"
        variant="invisible"
        @click="$emit('remove')"
      />
    </div>

    <FormHelperText v-if="helpText">{{ helpText }}</FormHelperText>

    <FormError v-if="errorsToDisplay.length">{{ errorsToDisplay.join(', ') }}</FormError>
  </li>
</template>
