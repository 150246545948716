<script setup lang="ts">
import type { ShowGoalProps } from '@/domains/configuration/views/goals/ShowGoal.vue';

import { ButtonColor } from '@/hooks/useButtonClasses';
import { useAuth } from '@/hooks/useAuth';
import { useDialogHelpers } from '@/hooks/useDialogHelpers';

import DangerDialog from '@/components/dialog/DangerDialog.vue';
import DefinitionList from '@/components/definitionList/DefinitionList.vue';
import DefinitionListItem from '@/components/definitionList/DefinitionListItem.vue';
import DefinitionListItemBadgeWrapper from '@/components/definitionList/DefinitionListItemBadgeWrapper.vue';
import IconButton from '@/components/button/IconButton.vue';
import InformationCollapsible from '@/components/collapsible/InformationCollapsible.vue';
import Slideout from '@/components/slideout/Slideout.vue';

import Building from '@/icons/line/building.svg';
import Bullseye from '@/icons/line/bullseye.svg';
import EditAlt from '@/icons/line/edit-alt.svg';
import GraduationCap from '@/icons/line/graduation-cap.svg';
import Label from '@/icons/line/label.svg';
import Percentage from '@/icons/line/percentage.svg';
import TrashIcon from '@/icons/line/trash.svg';

const props = defineProps<ShowGoalProps>();

const { show, close } = useDialog();
const { isDialogOpen, toggleDialog } = useDialogHelpers();

const { hasPermission } = useAuth();
const form = useForm({
  method: 'DELETE',
  spoof: false,
  url: route('goals.delete', { goal: props.goal.id }),
  fields: {},
  hooks: {
    success() {
      close();
    }
  }
});

async function handleGoalDelete() {
  await form.submit();
  toggleDialog();
}
</script>

<template>
  <Slideout title="Goal Information" :isOpen="show" @onClose="close" :icon="Bullseye">
    <template #actions>
      <IconButton
        v-if="goal.authorization.update"
        :href="route('goals.edit', { goal: goal.id })"
        :icon="EditAlt"
        ariaLabel="Edit goal"
        size="sm"
        variant="soft"
      />
      <IconButton
        v-if="goal.authorization.delete"
        :color="ButtonColor.danger"
        :icon="TrashIcon"
        ariaLabel="Move goal to trash"
        size="sm"
        variant="soft"
        @click="toggleDialog"
      />
    </template>
    <InformationCollapsible title="Goal Information">
      <DefinitionList>
        <DefinitionListItem
          :description="goal.goal_type?.label ?? 'No goal type'"
          :icon="Label"
          title="Goal Type"
        />
        <DefinitionListItem
          :description="goal.grade.label ?? 'No grade'"
          :icon="GraduationCap"
          title="Grade"
        />
        <DefinitionListItem
          :description="goal.goal_percentage ? `${goal.goal_percentage}%` : 'No goal percentage'"
          :icon="Percentage"
          title="Goal"
        />
        <DefinitionListItem
          v-if="hasPermission('read-school')"
          :description="!goal.schools?.length ? 'No schools' : undefined"
          :icon="Building"
          title="Schools"
        >
          <DefinitionListItemBadgeWrapper
            v-if="goal.schools?.length"
            :badgeItems="
              goal.schools?.map((school) => ({
                id: school?.id,
                label: school?.name
              })) ?? []
            "
            :buttonRoute="route('schools.index')"
            buttonLabel="Go to schools"
          />
        </DefinitionListItem>
      </DefinitionList>
    </InformationCollapsible>
  </Slideout>
  <DangerDialog
    title="Move Goal to Trash"
    message="Are you sure you want to move this goal to the trash?"
    confirmButtonLabel="Move to trash"
    :isOpen="isDialogOpen"
    :isLoading="form.processing"
    @onConfirm="handleGoalDelete"
    @onCancel="toggleDialog"
    @onClose="toggleDialog"
  />
</template>
