<script setup lang="ts">
import { CheckboxIndicator, CheckboxRoot } from 'radix-vue';

import Check from '@/icons/line/check.svg';
import Minus from '@/icons/line/minus.svg';

export type CheckedValue = boolean | 'indeterminate';

export type CheckboxInputProps = {
  checked: CheckedValue;
  hasError?: boolean;
  isDisabled?: boolean;
  name?: string;
};

const props = defineProps<CheckboxInputProps>();

const emit = defineEmits<{
  'update:checked': [checked: boolean];
}>();

function onCheckedChange(checked: boolean) {
  emit('update:checked', props.checked === 'indeterminate' ? true : checked);
}
</script>

<template>
  <CheckboxRoot
    @update:checked="onCheckedChange"
    :checked="checked"
    :disabled="isDisabled"
    :name="name"
    class="group flex h-5 w-5 shrink-0 appearance-none items-center justify-center rounded border bg-white outline-none transition-control duration-150 ease-in-out data-[state=checked]:border-secondary-900 data-[state=indeterminate]:border-secondary-900 data-[state=checked]:bg-secondary-900 data-[state=indeterminate]:bg-secondary-900 data-[state=checked]:text-white data-[state=indeterminate]:text-white data-[state=unchecked]:text-secondary-900"
    :class="{
      'disabled:cursor-not-allowed disabled:opacity-50': isDisabled,
      'hover:data-[state=unchecked]:bg-secondary-50': !isDisabled,
      'border-slate-300 hover:data-[disabled]:data-[state=checked]:ring-0 hover:data-[state=checked]:ring hover:data-[state=indeterminate]:ring hover:data-[state=unchecked]:ring hover:data-[state=checked]:ring-secondary-900/40 hover:data-[state=indeterminate]:ring-secondary-900 hover:data-[state=unchecked]:ring-secondary-900/40':
        !hasError && !isDisabled,
      'disabled:hover:ring-0  hover:data-[disabled]:data-[state=unchecked]:ring-0':
        !hasError && isDisabled,
      'hover:ring hover:ring-red-600/10 data-[state=checked]:border-red-500 data-[state=unchecked]:border-red-500':
        hasError
    }"
  >
    <CheckboxIndicator
      forceMount
      class="checkbox flex h-full w-full items-center justify-center rounded opacity-0 transition-opacity duration-150 ease-in-out data-[state=checked]:opacity-100 data-[state=indeterminate]:opacity-100"
      :class="{
        'group-hover:data-[state=unchecked]:opacity-100': !isDisabled,
        'group-hover:data-[disabled]:data-[state=unchecked]:opacity-0': isDisabled
      }"
    >
      <Minus v-if="checked === 'indeterminate'" class="h-5 w-5" />
      <Check v-else class="h-5 w-5" />
    </CheckboxIndicator>
  </CheckboxRoot>
</template>
