<script setup lang="tsx" generic="T extends Record<string, unknown>">
import TableHeaderCell from '@/components/table/TableHeaderCell.vue';
import TableHeaderCellSortable from '@/components/table/TableHeaderCellSortable.vue';
import TableRow from '@/components/table/TableRow.vue';
import { useStickyColumns } from '@/components/table/useStickyColumns';
import { TableColumn } from './Table';
import { RecordIdentifier } from 'hybridly/vue';
import ListRow from './ListRow.vue';

const props = withDefaults(
  defineProps<{
    columns: TableColumn<T>[];
    rows: T[];
    getRowId: (row: T) => RecordIdentifier;
    selectedRowIds?: RecordIdentifier[];
    clickableRows?: boolean;
    stickyStartColumns?: number;
    stickyEndColumns?: number;
    currentSort?: string | null;
    bordered?: boolean;
    view?: 'table' | 'list';
    showHeaderWhenEmpty?: boolean;
  }>(),
  {
    bordered: false,
    view: 'table',
    showHeaderWhenEmpty: false
  }
);

const emit = defineEmits<{
  clickRow: [event: MouseEvent, row: T];
  clickStickyCell: [event: Event, row: T];
  sort: [columnKey: string | null];
}>();

defineSlots<{
  selectionBanner?: void;
  emptyState?: void;
}>();

const { rows, stickyStartColumns, stickyEndColumns } = toRefs(props);

const computedTableStickColumnStyles = computed(() => {
  const styles = {};
  if (stickyStartColumns.value) {
    styles['--sticky-column-width'] = stickyStartColumnsWidth.value + 'px';
  }
  if (stickyEndColumns.value) {
    styles['--sticky-end-column-width'] = stickyEndColumnsWidth.value + 'px';
  }
  return styles;
});

const {
  tableRef,
  tableHeaderRef,
  stickyColumnLeftValues,
  stickyEndColumnRightValues,
  stickyStartColumnsWidth,
  stickyEndColumnsWidth
} = useStickyColumns(stickyStartColumns, stickyEndColumns);

const isListView = computed(() => props.view === 'list');

function handleRowClick(event: MouseEvent, row: T) {
  if (props.clickableRows) {
    emit('clickRow', event, row);
  }
}
</script>

<template>
  <div
    class="relative flex flex-1 flex-col overflow-x-auto"
    :class="{
      'table-wrapper-start': !isListView && stickyStartColumns,
      'table-wrapper-end': !isListView && stickyEndColumns
    }"
    :style="computedTableStickColumnStyles"
  >
    <div class="relative flex-1 overflow-y-auto" :class="{ 'overflow-x-hidden': isListView }">
      <table
        v-if="rows?.length || showHeaderWhenEmpty"
        class="w-full border-separate"
        cellpadding="0"
        cellspacing="0"
        ref="tableRef"
      >
        <thead
          :class="['sticky top-0 z-20 bg-slate-50', { 'sr-only': isListView }]"
          :hidden="isListView"
          ref="tableHeaderRef"
        >
          <tr class="h-12">
            <template v-for="(column, columnIndex) in columns" :key="column.key">
              <th
                class="border-b border-slate-200 text-left text-2xs font-bold uppercase leading-5 text-slate-500"
                :class="{
                  'lg:sticky lg:left-0 lg:z-10 lg:bg-slate-50':
                    stickyStartColumns && columnIndex < stickyStartColumns,
                  'sm:sticky sm:right-0 sm:z-10 sm:bg-slate-50':
                    stickyEndColumns && columnIndex === columns.length - stickyEndColumns,
                  'before:hidden lg:relative lg:before:absolute lg:before:inset-y-0 lg:before:right-0 lg:before:block lg:before:w-px lg:before:bg-slate-200':
                    columnIndex + 1 === stickyStartColumns,
                  'before:hidden sm:relative sm:before:absolute sm:before:inset-y-0 sm:before:left-0 sm:before:block sm:before:w-px sm:before:bg-slate-200':
                    stickyEndColumns ? columnIndex === columns.length - stickyEndColumns : false
                }"
                :style="{
                  left: stickyColumnLeftValues
                    ? `${stickyColumnLeftValues[columnIndex]}px`
                    : undefined,
                  right: stickyEndColumnRightValues ? `${stickyEndColumnRightValues}px` : undefined,
                  width: column.width,
                  minWidth: column.minWidth,
                  maxWidth: column.maxWidth
                }"
              >
                <TableHeaderCellSortable
                  v-if="column.isSortable"
                  :column="column"
                  :currentSort="currentSort"
                  @sort="(columnKey) => $emit('sort', columnKey)"
                />
                <TableHeaderCell
                  v-else
                  :column="column"
                  @sort="(sortBy) => $emit('sort', sortBy)"
                />
              </th>
            </template>
          </tr>
        </thead>

        <slot name="selectionBanner" />

        <tbody v-if="rows?.length > 0">
          <component
            v-for="row in rows"
            :is="isListView ? ListRow : TableRow"
            :key="getRowId(row)"
            :row="row"
            :columns="columns"
            :selected="selectedRowIds?.includes(getRowId(row))"
            :clickableRows="clickableRows"
            :stickyStartColumns="stickyStartColumns"
            :stickyEndColumns="stickyEndColumns"
            :stickyColumnLeftValues="stickyColumnLeftValues"
            :stickyEndColumnRightValues="stickyEndColumnRightValues"
            :bordered="bordered"
            @click="$event => handleRowClick($event as unknown as MouseEvent, row)"
            @clickStickyCell="$emit('clickStickyCell', $event, row)"
          />
        </tbody>
      </table>
      <template v-if="rows?.length === 0">
        <slot name="emptyState" />
      </template>
    </div>
  </div>
</template>

<style scoped>
.table-wrapper-start::before {
  @apply absolute left-0 top-0 z-0 hidden h-full border-r border-slate-200 bg-slate-50 lg:block;
  content: '';
  width: var(--sticky-column-width);
}
.table-wrapper-end::after {
  @apply absolute right-0 top-0 hidden h-full border-l border-slate-200 bg-slate-50 sm:block;
  content: '';
  width: var(--sticky-end-column-width);
  z-index: -1;
}
</style>
