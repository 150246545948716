<script setup lang="ts">
export type TextareaInputProps = {
  hasError?: boolean;
  isDisabled?: boolean;
  id?: string;
  modelValue?: string | number | null;
  name: string;
  placeholder?: string;
  variant?: 'default' | 'filled';
};

withDefaults(defineProps<TextareaInputProps>(), {
  variant: 'default'
});

const emit = defineEmits<{
  'update:modelValue': [value: string | number];
}>();

function onValueChange(event: Event) {
  emit('update:modelValue', (event.target as HTMLInputElement).value ?? '');
}
</script>

<template>
  <div class="relative flex grow items-center" :class="{ 'opacity-50': isDisabled }">
    <textarea
      v-bind="$attrs"
      class="min-h-36 w-full rounded-lg px-3 py-1.5 text-[16px] text-slate-900 transition-input duration-150 ease-in-out focus:ring-3 md:text-sm"
      :class="{
        'cursor-not-allowed': isDisabled,
        'cursor-text': !isDisabled,
        'border-slate-200 bg-white hover:border-slate-700 focus:border-slate-700 focus:ring-slate-700/40 disabled:hover:border-slate-200':
          variant === 'default' && !hasError,
        'border-transparent bg-slate-100 hover:bg-slate-200 focus:border-transparent focus:bg-slate-200 focus:ring-slate-300/50 disabled:hover:bg-slate-100':
          variant === 'filled' && !hasError,
        'border-red-500 bg-white focus:border-red-500 focus:ring-red-500/40':
          variant === 'default' && hasError,
        'border-transparent bg-red-100 focus:border-transparent focus:bg-red-200 focus:ring-red-600/10':
          variant === 'filled' && hasError
      }"
      :disabled="isDisabled"
      :id="id"
      :name="name"
      :placeholder="placeholder"
      :value="modelValue ?? ''"
      @input="onValueChange"
    />
  </div>
</template>
