<script setup lang="ts">
import { StepStatus } from './GuidedStep';
import GuidedStep, { Step } from './GuidedStep.vue';

const props = withDefaults(
  defineProps<{
    currentStep: number;
    steps: Step[];
    direction?: 'horizontal' | 'vertical';
    showStepNumber?: boolean;
    showBadge?: boolean;
  }>(),
  {
    direction: 'vertical',
    showStepNumber: true,
    showBadge: true
  }
);

function getStepStatus(index: number): StepStatus {
  if (props.currentStep === index) {
    return StepStatus.InProgress;
  }
  if (props.currentStep > index) {
    return StepStatus.Complete;
  }
  return StepStatus.Pending;
}
</script>

<template>
  <div
    class="flex w-full"
    :class="{
      'flex-row': direction === 'horizontal',
      'flex-col': direction === 'vertical'
    }"
  >
    <GuidedStep
      v-for="(step, index) in steps"
      :key="index"
      :isFirst="index === 0"
      :isLast="steps.length === index + 1"
      :label="step.label"
      :description="step.description"
      :status="getStepStatus(index)"
      :direction="direction"
      :showStepNumber="showStepNumber"
      :showBadge="showBadge"
      :step="index + 1"
    />
  </div>
</template>
