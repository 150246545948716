<script setup lang="ts" generic="T extends SelectableValue">
import type { Selectable, SelectableValue } from '@/components/selectBox/selectBox';

import { useCustomTestsSearch } from '@/hooks/search/useCustomTestsSearch';

import FormComboboxInput, {
  FormComboboxProps
} from '@/components/formComboboxInput/FormComboboxInput.vue';

type Props = Omit<FormComboboxProps<number>, 'options'> & {
  programIds?: number[];
};

const props = defineProps<Props>();

defineEmits<{
  'update:modelValue': [Selectable<string> | null];
}>();

const { customTestOptions, isLoading, onQueryChange, updateSearchFilters } = useCustomTestsSearch(
  props.searchFilters
);

watch(
  () => props.programIds,
  (programIds) => {
    updateSearchFilters({ program_ids: programIds });
  }
);
</script>

<template>
  <FormComboboxInput
    v-bind="$props"
    :isLoading="isLoading"
    :options="customTestOptions"
    :onFilter="onQueryChange"
    :modelValue="modelValue as unknown as Selectable<string>"
    @update:modelValue="(value) => $emit('update:modelValue', value as Selectable<string>)"
    @open="() => onQueryChange('')"
  />
</template>
