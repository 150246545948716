<script setup lang="ts">
import Dialog from '@/components/dialog/Dialog.vue';

const sessionLifetimeMs = useProperty<number>('sessionLifetimeMs');
const serverTime = useProperty<number>('serverTime');
const user = useProperty<App.Auth.Data.UserData>('security.user');

const sessionExpiresAt = ref(Date.now() + sessionLifetimeMs.value);
const secondsUntilExpiry = ref(sessionLifetimeMs.value / 1000);
const showDialog = ref(false);
const intervalTimer = ref<NodeJS.Timeout | null>(null);
const isExtendingSession = ref(false);
const isExpired = ref(false);

const logoutForm = useForm({
  fields: {},
  method: 'POST',
  url: route('logout.store')
});

const message = computed(() => {
  return `Your session will expire in ${secondsUntilExpiry.value} seconds due to inactivity.`;
});

watch(
  () => serverTime.value,
  () => {
    sessionExpiresAt.value = Date.now() + sessionLifetimeMs.value;
  }
);

onMounted(() => {
  intervalTimer.value = setInterval(() => {
    if (!user.value) {
      clearIntervalTime();
      return;
    }

    if (sessionExpiresAt.value <= Date.now()) {
      clearIntervalTime();
      isExpired.value = true;
      return;
    }

    secondsUntilExpiry.value = Math.round((sessionExpiresAt.value - Date.now()) / 1000);

    const minutesToWarnAt = 2;
    showDialog.value = secondsUntilExpiry.value < minutesToWarnAt * 60;
  }, 1000);
});

function clearIntervalTime() {
  if (intervalTimer.value) {
    clearInterval(intervalTimer.value);
  }
}

async function extendSession() {
  isExtendingSession.value = true;
  await router.reload();
  isExtendingSession.value = false;
}

function handleSessionExpiredConfirm() {
  router.reload();
  isExpired.value = false;
}
</script>

<template>
  <Dialog
    :isOpen="!isExpired && showDialog"
    title="Session Expiring Soon"
    :message="message"
    confirmButtonLabel="Continue using GRACE"
    cancelButtonLabel="Logout"
    :isLoading="isExtendingSession"
    @onCancel="logoutForm.submit"
    @onConfirm="extendSession"
  />
  <Dialog
    :isOpen="isExpired"
    title="Session Expired"
    message="Your session has expired due to inactivity. Please refresh the page to continue using GRACE."
    confirmButtonLabel="Refresh page"
    :cancelButtonLabel="null"
    @onConfirm="handleSessionExpiredConfirm"
  />
</template>
