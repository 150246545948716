<script setup lang="ts">
import DateInput from '@/components/dateInput/DateInput.vue';

const props = defineProps<{
  modelValue: (string | null)[] | null;
  isDisabled?: boolean;
  isReadonly?: boolean;
}>();

const emit = defineEmits<{
  'update:modelValue': [(string | null)[] | null];
}>();

const fromValue = computed(() => {
  return props.modelValue?.[0] ?? null;
});
const toValue = computed(() => {
  return props.modelValue?.[1] ?? null;
});
const minDate = computed(() => {
  if (!fromValue.value) {
    return undefined;
  }

  return `${fromValue.value}T12:00:00.000Z`;
});
const maxDate = computed(() => {
  if (!toValue.value) {
    return undefined;
  }

  return `${toValue.value}T12:00:00.000Z`;
});

function handleFromValueChange(value: string | null) {
  value = value ?? '';
  emit('update:modelValue', [value, toValue.value]);
}
function handleToValueChange(value: string | null) {
  value = value ?? '';
  emit('update:modelValue', [fromValue.value, value]);
}
</script>

<template>
  <div class="flex items-center gap-2">
    <DateInput
      class="w-full"
      name="value"
      placeholder="From..."
      :isDisabled="isDisabled"
      :isReadonly="isReadonly"
      :maxDate="maxDate"
      :modelValue="fromValue"
      @update:modelValue="handleFromValueChange"
    />
    <span class="text-sm opacity-50">to</span>
    <DateInput
      class="w-full"
      name="value"
      placeholder="To..."
      :isDisabled="isDisabled"
      :isReadonly="isReadonly"
      :minDate="minDate"
      :modelValue="toValue"
      @update:modelValue="handleToValueChange"
    />
  </div>
</template>
