<script setup lang="ts">
import { isEqual } from 'lodash';
import pluralize from 'pluralize';
import IconButton from '@/components/button/IconButton.vue';
import InfoCircle from '@/icons/line/info-circle.svg';
import Dialog from '@/components/dialog/Dialog.vue';
import EnumData = App.Base.Data.EnumData;
import ListboxInput from '@/components/selectBox/listbox/ListboxInput.vue';
import RecipientSelector from './RecipientSelector.vue';

const props = defineProps<{
  recipients: App.Sms.Data.SmsRecipientData[];
  errors: Record<string, unknown>;
  readonly?: boolean;
  smsRecipientTypeOptions?: EnumData[];
}>();

const recipientType = defineModel<EnumData | null>('recipientType');

const emit = defineEmits<{
  'update:recipients': [App.Sms.Data.SmsRecipientData[]];
}>();

const duplicateError = ref<string | null>(null);
const isDialogOpen = ref(false);

const numberOfRecipientsLabel = computed(() => {
  return `${props.recipients.length} ${pluralize('recipient', props.recipients.length)}`;
});

function handleAddRecipient(recipient: App.Sms.Data.SmsRecipientData) {
  const existingRecipient = props.recipients.find((existingRecipient) => {
    return isEqual(recipient, existingRecipient);
  });

  if (existingRecipient) {
    duplicateError.value = 'Recipient already added.';
    return;
  }
  emit('update:recipients', [...props.recipients, recipient]);
}

function handleRemoveRecipient(recipient: App.Sms.Data.SmsRecipientData) {
  emit(
    'update:recipients',
    props.recipients.filter((existingRecipient) => {
      return !isEqual(recipient, existingRecipient);
    })
  );
}
</script>


					<script lang="ts">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <div class="flex h-full flex-col divide-y divide-slate-200">
    <div class="flex h-14 items-center justify-between px-4 py-3.5">
      <div>
        <h3 class="text-sm font-semibold leading-5">Recipient List</h3>
        <p class="text-2xs font-medium text-slate-600">{{ numberOfRecipientsLabel }}</p>
      </div>
      <div>
        <IconButton
          :icon="InfoCircle"
          ariaLabel="Incomplete Recipient List"
          variant="invisible"
          color="secondary"
          @click="isDialogOpen = true"
        />
        <Dialog
          title="Recipient Information"
          cancelButtonLabel="Close"
          :icon="InfoCircle"
          :confirmButtonLabel="''"
          :isOpen="isDialogOpen"
          @onClose="isDialogOpen = false"
          @onCancel="isDialogOpen = false"
          @close="isDialogOpen = false"
        >
          <div class="space-y-2 text-base">
            <p class="font-bold">Important note about recipients</p>
            <p>
              If a recipient is unable to receive SMS messages for any reason (invalid/missing phone
              numbers, has opted out of SMS, etc), then the system will skip sending them the
              message.
            </p>
          </div>
        </Dialog>
      </div>
    </div>

    <div v-if="$slots.append" class="px-4 py-3.5">
      <slot name="append" />
    </div>

    <div
      v-if="!readonly && smsRecipientTypeOptions"
      class="flex items-center gap-2 whitespace-nowrap px-4 py-3.5"
    >
      <p class="text-sm text-slate-500">Send to:</p>
      <ListboxInput
        :disabled="readonly ?? false"
        v-model="recipientType"
        :options="smsRecipientTypeOptions"
        :error="errors.recipient_type"
      />
    </div>

    <RecipientSelector
      :recipients="recipients"
      :errors="errors"
      :readonly="readonly"
      class="py-3.5"
      @selectRecipient="handleAddRecipient"
      @removeRecipient="handleRemoveRecipient"
    />
  </div>
</template>
