<script setup lang="ts">
import { FormFieldSlots, type FormFieldProps } from '@/components/formField/FormField';
import type { DateTimeInputProps } from '@/components/dateTimeInput/DateTimeInput.vue';

import { useFormField } from '@/components/formField/useFormField';
import DateTimeInput from '@/components/dateTimeInput/DateTimeInput.vue';
import FormField from '@/components/formField/FormField.vue';

type FormDateTimeInputProps = Omit<DateTimeInputProps, 'hasError'> & FormFieldProps;

const props = defineProps<FormDateTimeInputProps>();

defineSlots<FormFieldSlots>();

const emit = defineEmits<{
  'update:modelValue': [value: string | null];
}>();

function onValueChange(event: string | null): void {
  emit('update:modelValue', event);
}

const { formFieldProps, slots } = useFormField({ props });
</script>

<template>
  <FormField v-bind="formFieldProps">
    <DateTimeInput
      :hasError="hasError"
      :isDisabled="isDisabled"
      :modelValue="modelValue"
      :name="name"
      :placeholder="placeholder"
      :variant="variant"
      :timezone="timezone"
      @update:modelValue="onValueChange"
    />
    <template v-for="slot of slots" #[slot]>
      <slot :name="slot" />
    </template>
  </FormField>
</template>
