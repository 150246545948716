<script setup lang="ts">
import type { AppliedFilter, AvailableFilter } from '@/components/distiller/types';
import type { Selectable, SelectableValue } from '@/components/selectBox/selectBox';

import CaseloadMultiComboBoxInput from '@/components/caseloads/CaseloadMultiComboBoxInput.vue';
import InstituteMultiComboBoxInput from '@/components/institutes/InstituteMultiComboBoxInput.vue';
import ProgramMultiComboBoxInput from '@/components/programs/ProgramMultiComboBoxInput.vue';
import RegionMultiComboBoxInput from '@/components/regions/RegionMultiComboBoxInput.vue';
import SchoolMultiComboBoxInput from '@/components/schools/SchoolMultiComboBoxInput.vue';
import StudentGroupMultiComboBoxInput from '@/components/studentGroups/StudentGroupMultiComboBoxInput.vue';
import StudentMultiComboBoxInput from '@/components/students/StudentMultiComboBoxInput.vue';
import StudentParentMultiComboBoxInput from '@/components/studentParent/StudentParentMultiComboBoxInput.vue';
import SurveyMultiComboBoxInput from '@/components/surveys/surveys/SurveyMultiComboBoxInput.vue';
import UserMultiComboBoxInput from '@/components/user/UserMultiComboBoxInput.vue';
import { useFilters } from '@/hooks/useFilters';

const props = withDefaults(
  defineProps<{
    filter: Pick<AvailableFilter, 'searchEntity'>;
    modelValue: Selectable<SelectableValue>[];
    hasError?: boolean;
    isDisabled?: boolean;
    isReadonly?: boolean;
    multiple?: boolean;
  }>(),
  {
    multiple: true
  }
);

defineEmits(['update:modelValue']);

const { getAppliedFilter } = useFilters();

const components = {
  institute: InstituteMultiComboBoxInput,
  region: RegionMultiComboBoxInput,
  program: ProgramMultiComboBoxInput,
  school: SchoolMultiComboBoxInput,
  student: StudentMultiComboBoxInput,
  student_group: StudentGroupMultiComboBoxInput,
  student_parent: StudentParentMultiComboBoxInput,
  survey: SurveyMultiComboBoxInput,
  user: UserMultiComboBoxInput,
  caseload: CaseloadMultiComboBoxInput
};

const extraSearchComponentProps = computed(() => {
  if (props.filter.searchEntity === 'school') {
    const programFilterValue = getAppliedFilter('program') as AppliedFilter;

    if (programFilterValue?.operator === 'in' && programFilterValue?.value instanceof Array) {
      return {
        programIds: programFilterValue.value.map(
          (value) => (value as unknown as Selectable<number>)?.value
        )
      };
    }
  }

  return null;
});
</script>

<template>
  <div>
    <component
      v-if="filter.searchEntity"
      :is="components[filter.searchEntity]"
      :multiple="multiple"
      :hasError="hasError"
      :isDisabled="isDisabled"
      :isReadonly="isReadonly"
      :modelValue="modelValue as unknown as Selectable<number>[]"
      v-bind="extraSearchComponentProps"
      placeholder="Select an option..."
      @update:modelValue="$emit('update:modelValue', $event)"
    />
  </div>
</template>
