<script setup lang="ts">
import CacLogo from '@/images/cac-logo.svg';
import { usePageMeta } from '@/hooks/usePageMeta';

usePageMeta();
</script>

<template>
  <div>
    <header class="flex h-[52px] w-full items-center border-b border-slate-300 px-5">
      <CacLogo class="h-8" />
    </header>
    <main class="m-auto flex w-full max-w-108 flex-col items-center justify-center px-4 pt-24">
      <slot />
    </main>
  </div>
</template>
