<script setup lang="ts" generic="T extends Record<string, unknown>">
import PaddedCellContent from '@/components/table/PaddedCellContent.vue';
import Tooltip from '@/components/tooltip/Tooltip.vue';

export type TableTruncatedTextCellProps = {
  text?: string;
  textLength?: number;
};

const props = withDefaults(defineProps<TableTruncatedTextCellProps>(), {
  textLength: 80
});

const truncatedText = computed(() => {
  if (props.text && isTruncated.value) {
    const slicedText = props.text.slice(0, props.textLength);
    return slicedText.trimEnd() + '...';
  }
  return props.text;
});

const isTruncated = computed(() => {
  return (props.text?.length ?? 0) > props.textLength;
});
</script>

<template>
  <PaddedCellContent>
    <Tooltip v-if="isTruncated" side="top" showArrow>
      <template #trigger>
        <div
          class="w-80 whitespace-normal"
          :class="{
            'cursor-default underline decoration-slate-400 decoration-dotted underline-offset-4':
              isTruncated
          }"
        >
          {{ truncatedText }}
        </div>
      </template>
      <div class="w-80 whitespace-normal">{{ text }}</div>
    </Tooltip>
    <div v-else class="w-fit min-w-40 whitespace-normal">{{ text }}</div>
  </PaddedCellContent>
</template>
