<script setup lang="ts">
import { get } from 'lodash';
import { useFilters } from '@/hooks/useFilters';

import FilterInput from '@/components/distiller/FilterInput.vue';
import Button from '@/components/button/Button.vue';
import { CombinedAppliedFilter } from '@/components/distiller/types';

import { ButtonVariant, ButtonSize, ButtonColor } from '@/hooks/useButtonClasses';

import Plus from '@/icons/line/plus.svg';
import FilterTreeIndicator from './FilterTreeIndicator.vue';

const props = withDefaults(
  defineProps<{
    title?: string;
    distiller: App.Search.Data.DistillerData;
    path?: string;
    gapClass?: string;
    rootDistiller?: boolean;
    addNewFilterOpen?: boolean;
    showOutOfFiltersMessage?: boolean;
    isDisabled?: boolean;
    showDistillerTypeAddButton?: boolean;
  }>(),
  {
    gapClass: 'gap-4',
    rootDistiller: true,
    addNewFilterOpen: false,
    isDisabled: false,
    showOutOfFiltersMessage: true
  }
);

const addingNewFilter = defineModel<boolean>('addingNewFilter');

// Sync the prop value on mount
onMounted(() => {
  if (addingNewFilter.value === undefined) {
    addingNewFilter.value = props.addNewFilterOpen;
  }
});

const { getAppliedFilter, removeFilter, hasAppliedFilter } = useFilters();

const appliedFiltersForDistiller = computed(() => getAppliedFilter(props.path));

const hasAppliedFilterForDistiller = computed(() => hasAppliedFilter(props.path));

const displayAddFilterButton = computed(() => {
  return !(addingNewFilter.value || !hasAppliedFilterForDistiller.value);
});

const availableFilters = computed(() => {
  return (
    props.distiller.filters?.filter((filter) => !appliedFiltersForDistiller.value[filter.key]) ?? []
  );
});

const combinedAppliedFilters = computed<CombinedAppliedFilter[]>(() => {
  const appliedFilterKeys = Object.keys(appliedFiltersForDistiller.value || {});

  return appliedFilterKeys.reduce((carry: CombinedAppliedFilter[], key: string) => {
    const filter = props.distiller.filters?.find((filter) => filter.key === key);

    if (!filter) {
      return carry;
    }

    carry.push({
      filter,
      appliedFilter: get(appliedFiltersForDistiller.value, key)
    });

    return carry;
  }, []);
});

const distillerLabel = computed(() => {
  const label = props.distiller.name?.label;
  return label ? `${label.toLowerCase()} ` : '';
});

function buildPath(key: string) {
  if (props.path) {
    return `${props.path}.${key}`;
  }

  return key;
}
</script>

<template>
  <fieldset class="flex w-full min-w-0 flex-col items-start" :disabled="isDisabled">
    <div
      v-for="({ filter }, index) in combinedAppliedFilters"
      :key="filter.key"
      class="flex w-full min-w-0 flex-row"
      :class="{ 'mt-3': index > 0 || (index === 0 && !rootDistiller) }"
    >
      <FilterInput
        class="w-full flex-1"
        :parentPath="path"
        :filter="filter"
        :availableFilters="availableFilters"
        :isNested="!rootDistiller"
        :isFirstNode="index === 0"
        :isDisabled="isDisabled"
        @removeFilter="removeFilter(buildPath(filter.key))"
      />
    </div>

    <div
      class="flex w-full min-w-0 grow-0 flex-row"
      :class="{
        'mt-3': (combinedAppliedFilters.length > 0 || !rootDistiller) && availableFilters.length > 0
      }"
    >
      <FilterTreeIndicator
        v-if="!rootDistiller"
        horizontal
        lastNode
        :forAddButton="displayAddFilterButton"
      />

      <template v-if="!isDisabled">
        <div
          v-if="availableFilters.length === 0 && showOutOfFiltersMessage"
          class="mt-2 flex h-9 items-center pl-2 text-sm text-slate-500"
        >
          <p>Out of filters to add</p>
        </div>

        <template v-else-if="availableFilters.length > 0">
          <div
            v-if="displayAddFilterButton"
            class="flex w-full items-center justify-between gap-x-2"
          >
            <Button
              :variant="ButtonVariant.soft"
              :size="ButtonSize.md"
              :iconLeft="Plus"
              :color="ButtonColor.slate"
              @click="addingNewFilter = true"
            >
              {{
                rootDistiller
                  ? showDistillerTypeAddButton
                    ? `Add a ${distillerLabel}filter`
                    : 'Add a filter'
                  : `Add a nested ${title} filter`
              }}
            </Button>
            <slot name="recordCountLabel" />
          </div>

          <div v-else class="flex w-full flex-col">
            <FilterInput
              class="w-full"
              :availableFilters="availableFilters"
              :parentPath="path"
              :showTrashIcon="!!hasAppliedFilter"
              @filterAdded="addingNewFilter = false"
              @removeFilter="addingNewFilter = false"
            />
            <div
              v-if="rootDistiller && $slots.recordCountLabel"
              class="ml-auto mt-3 flex h-8 items-center"
            >
              <slot name="recordCountLabel" />
            </div>
          </div>
        </template>
      </template>
    </div>
  </fieldset>
</template>
