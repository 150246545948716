<script setup lang="ts">
import { ButtonColor, ButtonVariant } from '@/hooks/useButtonClasses';

import Button from '@/components/button/Button.vue';
import IconButton from '@/components/button/IconButton.vue';

import FileCheck from '@/icons/line/file-check.svg';
import FileError from '@/icons/line/file-error.svg';
import Trash from '@/icons/line/trash.svg';

type Props = { state: 'success' | 'loading' | 'error'; accept?: string[]; error?: string };
const props = defineProps<Props>();
const model = defineModel<File>();
const fileDialog = useFileDialog();

function cancel() {
  fileDialog.reset();
  model.value = undefined;
}

function replace() {
  fileDialog.open({ accept: props.accept?.join(',') });
  fileDialog.onChange((files) => {
    model.value = files ? files[0] : undefined;
  });
}

function trash() {
  fileDialog.reset();
  model.value = undefined;
}

function readableFileSize(fileSize: number) {
  const sizeInKb = fileSize / 1024;

  if (sizeInKb > 1024) {
    return `${(sizeInKb / 1024).toFixed(2)} mb`;
  } else {
    return `${sizeInKb.toFixed(2)} kb`;
  }
}

const message = computed(() => {
  if (props.state === 'loading') {
    return 'Uploading file';
  }
  if (props.state === 'success') {
    return `File format is correct | ${readableFileSize(model.value?.size ?? 0)}`;
  }
  if (props.state === 'error') {
    return props.error ?? 'File format is incorrect';
  }
  return null;
});

const icon = computed(() => {
  if (props.state === 'loading') {
    return null;
  }
  if (props.state === 'success') {
    return FileCheck;
  }

  if (props.state === 'error') {
    return FileError;
  }

  return null;
});
</script>

<template>
  <div
    v-if="modelValue"
    class="flex flex-row items-center justify-between rounded-lg border border-slate-200"
  >
    <div
      :class="[
        'flex flex-row rounded-l-lg border-l-4',
        {
          'border-slate-600': state === 'loading',
          'border-green-600': state === 'success',
          'border-red-700': state === 'error'
        }
      ]"
    >
      <div class="flex h-16 w-18 items-center justify-center border-r border-slate-200 bg-slate-50">
        <component v-if="icon" :is="icon" class="h-10 w-10 text-slate-700" />
      </div>
      <div class="flex flex-col justify-center px-3">
        <p class="text-base font-semibold leading-5 text-slate-900">{{ modelValue.name }}</p>
        <p
          v-if="message"
          :class="[
            'text-sm font-medium leading-4',
            {
              'text-slate-500': state === 'loading',
              'text-green-700': state === 'success',
              'text-red-700': state === 'error'
            }
          ]"
        >
          {{ message }}
        </p>
      </div>
    </div>

    <div v-if="state === 'loading'" class="flex flex-row items-center px-3">
      <Button :color="ButtonColor.slate" :variant="ButtonVariant.invisible" @click="cancel"
        >Cancel</Button
      >
    </div>
    <div v-else class="flex flex-row items-center px-3">
      <Button :color="ButtonColor.slate" :variant="ButtonVariant.invisible" @click="replace"
        >Replace</Button
      >
      <IconButton ariaLabel="Delete" variant="invisible" :icon="Trash" @click="trash"
        >Delete</IconButton
      >
    </div>
  </div>
</template>
