<script setup lang="tsx">
import type { CustomCells, HybridlyRow } from '@/components/table/Table';

import { ButtonColor, ButtonSize, ButtonVariant } from '@/hooks/useButtonClasses';
import { useAuth } from '@/hooks/useAuth';
import { useFilters } from '@/hooks/useFilters';
import { useHybridlyTable } from '@/hooks/useHybridlyTable';
import { useTableRowClick } from '@/hooks/useTableRowClick';
import Button from '@/components/button/Button.vue';
import EmptyState from '@/components/emptyState/EmptyState.vue';
import IndexPageLayout from '@/components/pageLayout/IndexPageLayout.vue';
import TableBadgesCell from '@/components/table/TableBadgesCell.vue';
import TableBooleanCell from '@/components/table/TableBooleanCell.vue';
import TabNavDivider from '@/components/tabNav/TabNavDivider.vue';
import TabNavItem from '@/components/tabNav/TabNavItem.vue';
import { AppliedFilters } from '@/components/distiller/types';
import BulkTrashButton from '@/components/table/BulkTrashButton.vue';
import BulkRestoreButton from '@/components/table/BulkRestoreButton.vue';

import FilesLandscapeAlt from '@/icons/line/files-landscapes-alt.svg';
import LayerGroup from '@/icons/line/layer-group.svg';
import MapPin from '@/icons/line/map-pin.svg';
import Plus from '@/icons/line/plus.svg';
import UserCircle from '@/icons/line/user-circle.svg';
import TableTruncatedTextCell from '@/components/table/TableTruncatedTextCell.vue';

type SchoolRow = HybridlyRow<App.Configuration.Data.ProgramData>;

const props = defineProps<{
  schools: Table<App.Configuration.Data.SchoolData>;
  appliedFilters: AppliedFilters;
  distiller: App.Search.Data.DistillerData;
  trashActive: boolean;
  counts: {
    active_count: number;
    trashed_count: number;
  };
}>();

const { hasPermission } = useAuth();

const { table, tableSelectionState } = useHybridlyTable<App.Configuration.Data.SchoolData>(
  props,
  'schools'
);
const { handleRowClick } = useTableRowClick();
const { encodeFilters } = useFilters();

const encodedFilters = computed(() => encodeFilters(props.appliedFilters));

const customCells = {
  name: ({ row, column }) => <TableTruncatedTextCell text={row.value(column.key)} />,
  advisers: ({ row, column }) => (
    <TableBadgesCell
      badgesToDisplay={2}
      itemIcon={UserCircle}
      items={row.value(column.key).map((adviser: App.Auth.Data.UserData) => adviser.name)}
    />
  ),
  program: ({ row, column }) => (
    <TableBadgesCell
      badgesToDisplay={1}
      itemIcon={FilesLandscapeAlt}
      items={[row.value(column.key).display_name]}
    />
  ),
  region: ({ row }) => (
    <TableBadgesCell
      badgesToDisplay={1}
      itemIcon={MapPin}
      items={[row.value('program').region?.display_name]}
    />
  ),
  is_served: ({ row, column }) => <TableBooleanCell boolean={row.value(column.key)} />
} as CustomCells<App.Configuration.Data.SchoolData>;

function handleSchoolRowClick(event: MouseEvent, row: SchoolRow) {
  const url = route('schools.show', {
    school: row.key
  });
  handleRowClick(url, event);
}
</script>


					<script lang="tsx">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <IndexPageLayout
    title="Schools"
    :table="table"
    :appliedFilters="appliedFilters"
    :distiller="distiller"
    :customCells="customCells"
    :stickyStartColumns="3"
    :clickableRows="!trashActive"
    :tableSelectionState="tableSelectionState"
    @clickRow="($event, row) => handleSchoolRowClick($event, row as SchoolRow)"
  >
    <template #rightButtons>
      <template v-if="tableSelectionState.hasSelection">
        <BulkTrashButton
          v-if="!trashActive"
          recordType="Schools"
          :route="route('schools.bulk-delete')"
          :tableSelectionState="tableSelectionState"
        />

        <BulkRestoreButton
          v-if="trashActive"
          recordType="Schools"
          :route="route('schools.bulk-restore')"
          :tableSelectionState="tableSelectionState"
        />
      </template>

      <template v-else>
        <Button
          v-if="hasPermission('create-school')"
          :size="ButtonSize.sm"
          :variant="ButtonVariant.soft"
          :color="ButtonColor.slate"
          :iconLeft="LayerGroup"
          :href="route('upload-sessions.draft.index', { uploadType: 'school' })"
        >
          Bulk add
        </Button>
        <Button
          v-if="hasPermission('create-school')"
          :size="ButtonSize.sm"
          :variant="ButtonVariant.solid"
          :color="ButtonColor.primary"
          :iconLeft="Plus"
          :href="route('schools.create')"
        >
          Add a new school
        </Button>
      </template>
    </template>

    <template #tabs>
      <TabNavItem
        :badgeLabel="counts.active_count"
        :isActive="!trashActive"
        label="Active"
        variant="inline"
        :href="
          route('schools.index', {
            ...(encodedFilters ? { filters: encodedFilters } : {})
          })
        "
      />
      <TabNavDivider layout="inline" />
      <TabNavItem
        :badgeLabel="counts.trashed_count ?? 0"
        :isActive="trashActive"
        label="Trashed"
        variant="inline"
        :href="
          route('schools.index', {
            trashed: true,
            ...(encodedFilters ? { filters: encodedFilters } : {})
          })
        "
      />
    </template>

    <template #emptyState>
      <EmptyState
        v-if="trashActive"
        title="No schools were moved to the trash"
        description="Trashed schools will appear on this screen"
      />
      <EmptyState
        v-else
        title="No schools found"
        :description="
          hasPermission('create-school') ? 'Create a new school by clicking the button below' : ''
        "
        :actionLabel="hasPermission('create-school') ? 'Add a school' : ''"
        :actionHref="route('schools.create')"
      />
    </template>
  </IndexPageLayout>
</template>
