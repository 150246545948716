<script setup lang="ts">
import TabNav from '@/components/tabNav/TabNav.vue';
import TabNavItem from '@/components/tabNav/TabNavItem.vue';
import RelatedThreadData = App.Sms.Data.RelatedThreadData;
import ThreadData = App.Sms.Data.ThreadData;
import { WithLoadedRelations } from '@/@types/global';
const emit = defineEmits<{
  showAdmitHubContact: [contactId: number];
  showThread: [thread: WithLoadedRelations<ThreadData, 'recipient'>];
}>();

const props = defineProps<{
  type: 'thread' | 'admitHubContact';
  id: number;
  relatedThreads: RelatedThreadData[];
}>();

function isActive(tab: RelatedThreadData) {
  if (props.type === 'thread' && tab.thread?.id) {
    return props.id === tab.thread.id;
  }
  if (props.type === 'admitHubContact' && tab.admit_hub_contact_id) {
    return props.id === tab.admit_hub_contact_id;
  }
  return false;
}

function handleClick(tab: RelatedThreadData) {
  if (tab.thread?.id) {
    emit('showThread', tab.thread as WithLoadedRelations<ThreadData, 'recipient'>);
    return;
  }
  if (tab.admit_hub_contact_id) {
    emit('showAdmitHubContact', tab.admit_hub_contact_id);
  }
}
</script>

<template>
  <TabNav layout="inline" v-if="relatedThreads">
    <TabNavItem
      v-for="tab in relatedThreads"
      :key="tab.thread?.id ?? tab.admit_hub_contact_id ?? undefined"
      :isActive="isActive(tab)"
      @click="handleClick(tab)"
      class="!grow"
      variant="inline"
      :label="tab.label"
    />
  </TabNav>
</template>
