<script setup lang="ts">
type Block = App.Surveys.Data.SurveyBlockData;
type EnumData = App.Base.Data.EnumData;
type Survey = App.Surveys.Data.SurveyData;

import { buildSelectableSurveyBlock } from '@/utils/buildSelectable';

import ShowSurveyPageLayout from '@/components/surveys/surveys/ShowSurveyPageLayout.vue';
import SurveyBlockPreview from '@/components/surveys/surveys/SurveyBlockPreview.vue';
import SurveyBlockSettings from '@/components/surveys/surveys/SurveyBlockSettings.vue';

const props = defineProps<{
  survey: Survey;
  block: Block;
  blockChoiceType: EnumData[];
  blockTypes: EnumData[];
  blockMatchColumns: EnumData[];
  choicesFromBlockOptions: Block[];
  referencedBlocks: Block[];
}>();

const form = useForm({
  method: 'PUT',
  url: route('surveys.blocks.settings.update', {
    survey: props.survey.id,
    block: props.block.id
  }),
  updateInitials: true,
  fields: {
    block_text: props.block.block_text ?? '',
    block_type: props.block.block_type,
    choice_type: props.block.choice_type ?? {
      value: null,
      label: 'Custom',
      description: null,
      header: null
    },
    choices: !props.block.choice_type?.value ? props.block.choices : [],
    choices_from_survey_block_id:
      buildSelectableSurveyBlock(props.block.choices_from_survey_block) ?? null,
    selections_min_count: props.block.selections_min_count ?? null,
    selections_max_count: props.block.selections_max_count ?? null,
    placeholder: props.block.placeholder ?? '',
    match_column: props.block.match_column ?? null,
    is_skippable: !props.block.is_required,
    view_in_summary: props.block.view_in_summary,
    instructional_text: props.block.instructional_text ?? '',
    allow_nullable:
      props.block.block_type?.value === 'boolean' &&
      props.block.choices?.filter((choice) => !choice.choice_value).length
        ? true
        : false
  },
  transform: (fields) => ({
    ...fields,
    block_type: fields.block_type?.value ?? null,
    choice_type: fields.choice_type?.value ?? null,
    choices_from_survey_block_id: fields.choices_from_survey_block_id?.value ?? null,
    match_column: fields.match_column?.value ?? null,
    is_required: !fields.is_skippable // Note: we have to reassign due to Radix switch not accepting `!true`
  })
});
</script>


					<script lang="ts">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <ShowSurveyPageLayout :survey="survey" :title="survey.name">
    <template #leftColumn>
      <SurveyBlockSettings
        :block="block"
        :blockChoiceType="blockChoiceType"
        :blockTypes="blockTypes"
        :blockMatchColumns="blockMatchColumns"
        :choicesFromBlockOptions="choicesFromBlockOptions"
        :form="form"
        :referencedBlocks="referencedBlocks"
        :selectedBlock="block.id"
        :survey="survey"
      />
    </template>
    <SurveyBlockPreview :block="block" :survey="survey" />
  </ShowSurveyPageLayout>
</template>
