<script setup lang="ts">
import UserData = App.Auth.Data.UserData;
import TwoFactorChannelData = App.Auth.Data.TwoFactorChannelData;
import CenteredContent from '@/components/content/CenteredContent.vue';
import AccountForm from '@/components/account/AccountForm.vue';

const props = defineProps<{
  user: UserData;
  channels: TwoFactorChannelData[];
}>();

const form = useForm({
  method: 'PUT',
  url: route('account.update', { user: props.user.id }),
  updateInitials: true,
  fields: {
    first_name: props.user.first_name,
    last_name: props.user.last_name,
    password: '',
    new_password: '',
    new_password_confirmation: '',
    email: props.user.email,
    sms_email: props.user.sms_email,
    sms_phone_number: props.user.sms_phone_number,
    url: props.user.url
  }
});
</script>


					<script lang="ts">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <CenteredContent>
    <AccountForm :channels="channels" :form="form" />
  </CenteredContent>
</template>
