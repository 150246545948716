<script setup lang="ts">
import { ButtonColor, ButtonSize, ButtonVariant } from '@/hooks/useButtonClasses';

import Button from '@/components/button/Button.vue';
import AuthForm from '@/components/form/AuthForm.vue';
import FormTextInput from '@/components/formTextInput/FormTextInput.vue';
import StackedFormFooter from '@/components/formFooter/StackedFormFooter.vue';
import AuthFormWrapper from '@/components/auth/AuthFormWrapper.vue';

const form = useForm({
  method: 'POST',
  url: route('password.email'),
  fields: {
    email: ''
  }
});
</script>


					<script lang="ts">
					import __hybridly_layout_0 from '~/resources/domains/auth/layouts/two-columns-guest.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <AuthFormWrapper
    title="Reset password"
    description="Enter your email address. If an account exists, you’ll receive an email with a password reset link soon."
  >
    <AuthForm @submit.prevent="form.submit">
      <FormTextInput
        label="Email"
        name="email"
        type="email"
        placeholder="Enter your email address..."
        :modelValue="form.fields.email"
        :error="form.errors.email"
        @update:modelValue="(value) => (form.fields.email = value as string)"
      />

      <template #footer>
        <StackedFormFooter>
          <Button
            type="submit"
            :size="ButtonSize.lg"
            :color="ButtonColor.primary"
            :isDisabled="form.processing"
            :variant="ButtonVariant.solid"
            >Continue</Button
          >
          <Button
            :color="ButtonColor.slate"
            :href="route('login.show')"
            :size="ButtonSize.lg"
            :variant="ButtonVariant.invisible"
            >Back to login</Button
          >
        </StackedFormFooter>
      </template>
    </AuthForm>
  </AuthFormWrapper>
</template>
