<script setup lang="ts">
import { vIntersectionObserver } from '@vueuse/components';
import Breadcrumb from '@/components/breadcrumbs/Breadcrumb.vue';

const props = defineProps<{
  breadcrumbs: App.Base.Data.BreadcrumbData[];
}>();

const clippedBreadcrumbs = ref<{
  [key: number]: boolean;
}>(props.breadcrumbs.reduce((acc, _, index) => ({ ...acc, [index]: true }), {}));

const visibleBreadcrumbs = computed(() => {
  return props.breadcrumbs.filter((_, index) => clippedBreadcrumbs.value[index]);
});

const breadcrumbsContainer = ref<HTMLElement | null>(null);

function onBreadcrumbIntersection(index: number, entries: IntersectionObserverEntry[]) {
  entries.forEach((entry) => {
    clippedBreadcrumbs.value = {
      ...clippedBreadcrumbs.value,
      [index]: entry.intersectionRatio === 1
    };
  });
}
</script>

<template>
  <div class="relative flex h-full flex-1 flex-shrink items-center justify-start overflow-hidden">
    <nav ariaLabel="Breadcrumbs" class="w-full">
      <ol class="flex items-center">
        <li
          v-for="(breadcrumb, index) in visibleBreadcrumbs"
          :key="index"
          class="flex flex-nowrap items-center"
        >
          <div v-if="index > 0" class="flex items-center p-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              class="h-4 w-4 fill-current text-slate-500"
            >
              <path
                d="M14.83,11.29,10.59,7.05a1,1,0,0,0-1.42,0,1,1,0,0,0,0,1.41L12.71,12,9.17,15.54a1,1,0,0,0,0,1.41,1,1,0,0,0,.71.29,1,1,0,0,0,.71-.29l4.24-4.24A1,1,0,0,0,14.83,11.29Z"
              ></path>
            </svg>
          </div>
          <Breadcrumb
            :ariaCurrent="visibleBreadcrumbs.length === index + 1 ? 'page' : null"
            :isActive="visibleBreadcrumbs.length === index + 1"
            :label="breadcrumb.label"
            :url="breadcrumb.url"
          />
        </li>
      </ol>
    </nav>

    <!-- This is the hidden copy of the breadcrumbs that we use to determine if a breadcrumb is clipped -->
    <ol
      aria-hidden
      class="pointer-events-none invisible absolute left-0 top-0 flex flex-row-reverse items-center"
      ref="breadcrumbsContainer"
    >
      <li
        v-for="(breadcrumb, index) in breadcrumbs"
        :key="index"
        class="flex flex-nowrap items-center"
        v-intersection-observer="[
          (observerEntries: IntersectionObserverEntry[]) =>
            onBreadcrumbIntersection(index, observerEntries),
          { root: breadcrumbsContainer, threshold: 1 }
        ]"
      >
        <div v-if="index > 0" class="flex items-center p-1">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            class="h-4 w-4 fill-current text-slate-500"
          >
            <path
              d="M14.83,11.29,10.59,7.05a1,1,0,0,0-1.42,0,1,1,0,0,0,0,1.41L12.71,12,9.17,15.54a1,1,0,0,0,0,1.41,1,1,0,0,0,.71.29,1,1,0,0,0,.71-.29l4.24-4.24A1,1,0,0,0,14.83,11.29Z"
            ></path>
          </svg>
        </div>
        <Breadcrumb
          :ariaCurrent="visibleBreadcrumbs.length === index + 1 ? 'page' : null"
          :isActive="visibleBreadcrumbs.length === index + 1"
          :label="breadcrumb.label"
          :url="breadcrumb.url"
        />
      </li>
    </ol>
  </div>
</template>
