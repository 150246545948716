<script setup lang="ts" generic="T extends string | number">
import { PopoverContent, PopoverRoot, PopoverTrigger } from 'radix-vue';
import EllipsisH from '@/icons/line/ellipsis-h.svg';

import { Selectable } from '@/components/selectBox/selectBox';
import IconButton from '@/components/button/IconButton.vue';
import SearchInput from '@/components/searchInput/SearchInput.vue';
import { useZindex } from '@/hooks/useZindex';

type Group = {
  label: string;
  key: string;
  icon?: Component;
  deselectable?: boolean;
  options: Array<Selectable<T>>;
};

const props = defineProps<{
  groups: Array<Group>;
  hideSearch?: boolean;
}>();

defineSlots<{
  group({ group }: { group: Group }): unknown;
}>();

const searchStr = ref<string>('');

const filteredGroups = computed(() =>
  searchStr.value.trim().length
    ? props.groups.map((group) => ({
        ...group,
        options: group.options.filter((option) =>
          option.label.toLowerCase().includes(searchStr.value.toLowerCase())
        )
      }))
    : props.groups
);

const { nextIndex } = useZindex();
const zIndex = nextIndex();
</script>

<template>
  <PopoverRoot>
    <slot name="trigger">
      <PopoverTrigger
        :as="IconButton"
        size="xs"
        variant="invisible"
        :icon="EllipsisH"
        ariaLabel="Sort and re-map the column"
      />
    </slot>

    <PopoverContent
      :style="{ zIndex }"
      side="bottom"
      :sideOffset="3"
      align="end"
      class="data-[state=open]:data-[side=top]:animate-slideDownAndFade data-[state=open]:data-[side=right]:animate-slideLeftAndFade data-[state=open]:data-[side=bottom]:animate-slideUpAndFade data-[state=open]:data-[side=left]:animate-slideRightAndFade w-[260px] rounded-lg border border-zinc-200 bg-white shadow-md will-change-[transform,opacity]"
    >
      <div class="flex flex-col" style="max-height: var(--radix-popover-content-available-height)">
        <SearchInput
          v-if="!hideSearch"
          v-model="searchStr"
          inputClasses="rounded-b-none focus:ring-0 py-5"
        />
        <div class="overflow-y-auto">
          <div v-for="group in filteredGroups" :key="group.key">
            <slot name="group" :group="group"></slot>
          </div>
        </div>
      </div>
    </PopoverContent>
  </PopoverRoot>
</template>
