import { format as formatDateFns, toZonedTime, fromZonedTime } from 'date-fns-tz';
import { formatRelative as formatRelativeDateFns } from 'date-fns';

interface FormatOptions {
  timezone?: string;
  format?: string;
}

function formatWithTimezone(date: Date | string | null, format: string, timezone: string | null) {
  if (!date) {
    return null;
  }

  const zonedDate = timezone ? toZonedTime(date, timezone) : new Date(date);

  if (isNaN(zonedDate.getTime())) {
    return null;
  }

  return formatDateFns(zonedDate, format, { timeZone: timezone || undefined });
}

export function formatDate(date: Date | string | null, options?: FormatOptions) {
  const { timezone = Intl.DateTimeFormat().resolvedOptions().timeZone, format = 'MMM dd, yyyy' } =
    options || {};
  return formatWithTimezone(date, format, timezone);
}

export function formatTime(date: Date | string | null, options?: FormatOptions) {
  const { timezone = Intl.DateTimeFormat().resolvedOptions().timeZone, format = 'h:mmaaa' } =
    options || {};
  return formatWithTimezone(date, format, timezone);
}

export function formatDateTime(date: Date | string | null, options?: FormatOptions) {
  const {
    timezone = Intl.DateTimeFormat().resolvedOptions().timeZone,
    format = 'MMM dd, yyyy h:mmaaa'
  } = options || {};
  return formatWithTimezone(date, format, timezone);
}

export function formatRelative(date: string): string {
  return formatRelativeDateFns(fromZonedTime(date, 'UTC'), new Date());
}
