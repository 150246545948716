<script setup lang="ts">
type EnumData = App.Base.Data.EnumData;

import type { ShowSurveySessionProps } from '@/domains/surveys/views/surveys/ShowSurveySession.vue';

import { formatDate, formatTime } from '@/utils/date';

import Alert from '@/components/alert/Alert.vue';
import Badge from '@/components/badge/Badge.vue';
import BadgeBoolean from '@/components/badge/BadgeBoolean.vue';
import InformationCollapsible from '@/components/collapsible/InformationCollapsible.vue';
import Slideout from '@/components/slideout/Slideout.vue';
import StudentComboBoxInput from '@/components/students/StudentComboBoxInput.vue';

import AlignLeft from '@/icons/line/align-left.svg';
import Calendar from '@/icons/line/calendar.svg';
import CheckCircle from '@/icons/line/check-circle.svg';
import CheckSquare from '@/icons/line/check-square.svg';
import Envelope from '@/icons/line/envelope.svg';
import InfoCircle from '@/icons/line/info-circle.svg';
import Paragraph from '@/icons/line/paragraph.svg';
import Phone from '@/icons/line/phone.svg';
import SliderH from '@/icons/line/slider-h.svg';
import Text from '@/icons/line/text.svg';
import ToggleOn from '@/icons/line/toggle-on.svg';
import User from '@/icons/line/user.svg';

const props = defineProps<ShowSurveySessionProps>();

const { show, close } = useDialog();

const form = useForm({
  method: 'PUT',
  url: route('surveys.sessions.update', {
    survey: props.session.survey?.id,
    session: props.session.id
  }),
  fields: {
    student_id: null as EnumData | null
  },
  transform: (fields) => ({
    ...fields,
    student_id: fields.student_id?.value
  })
});

function handleStudentSelection(student: EnumData) {
  form.fields.student_id = student;
  form.submit();
}

function getBlockTypeIcon(value) {
  return {
    select: CheckCircle,
    multiselect: CheckSquare,
    text: Text,
    textarea: AlignLeft,
    instruction: Paragraph,
    range: SliderH,
    boolean: ToggleOn,
    date: Calendar,
    email: Envelope,
    phone: Phone
  }[value];
}
</script>

<template>
  <Slideout
    :icon="User"
    :isOpen="show"
    backgroundColor="slate"
    :title="session.student?.name"
    @onClose="close"
  >
    <template v-if="!session?.student" #title>
      <div class="my-2.5 flex flex-col gap-y-2">
        <Alert
          :icon="InfoCircle"
          alignment="vertical"
          title="No matching student was found. Set a student manually"
          hideClose
          variant="soft"
        />
        <StudentComboBoxInput
          :modelValue="form.fields.student_id"
          :schoolIds="session.survey?.school?.id ? [session.survey.school.id] : []"
          @update:modelValue="handleStudentSelection"
        />
      </div>
    </template>
    <template v-if="session.created_at" #subtitle>
      <p class="flex items-center gap-x-1 text-sm font-normal leading-4">
        {{ formatDate(session.created_at)
        }}<span class="text-slate-500">{{ formatTime(session.created_at) }}</span>
      </p>
      <p class="text-2xs leading-4 text-slate-500">Submitted at</p>
    </template>
    <InformationCollapsible title="Responses">
      <div class="flex flex-col gap-1.5">
        <div
          v-for="answer in session.answers"
          :key="answer.id"
          class="divide-y-slate-200 divide-y rounded-lg border border-slate-200 bg-white shadow-sm"
        >
          <div class="p-4">
            <div class="flex items-center gap-x-2 text-slate-500">
              <div class="size-5 rounded bg-slate-100 p-[0.1875rem]">
                <component :is="getBlockTypeIcon(answer.block?.block_type.value)" />
              </div>
              <p class="text-sm font-medium leading-5 text-slate-900">
                {{ answer.block?.block_text }}
              </p>
            </div>
          </div>
          <div class="p-4">
            <template
              v-if="
                answer.block?.block_type.value === 'text' ||
                answer.block?.block_type.value === 'textarea' ||
                answer.block?.block_type.value === 'date' ||
                answer.block?.block_type.value === 'phone' ||
                answer.block?.block_type.value === 'email'
              "
            >
              <p class="text-sm font-medium leading-5 text-slate-900">
                <template v-if="answer.block?.block_type.value === 'date'">{{
                  answer.value ? formatDate(answer.value) : ''
                }}</template>
                <template v-else>{{ answer.value }}</template>
              </p>
            </template>
            <template
              v-if="
                answer.block?.block_type.value === 'select' ||
                answer.block?.block_type.value === 'multiselect' ||
                answer.block?.block_type.value === 'range' ||
                answer.block?.block_type.value === 'boolean'
              "
            >
              <div class="flex flex-wrap gap-1">
                <template v-for="choice in answer.choices" :key="choice.id">
                  <Badge
                    v-if="
                      answer.block.choice_type?.value &&
                      answer.block.choice_type?.value !== 'institute'
                    "
                    :label="choice?.answer_option?.label"
                    size="md"
                    shape="rounded"
                  />
                  <Badge
                    v-if="
                      answer.block.choice_type?.value &&
                      answer.block.choice_type?.value === 'institute'
                    "
                    :label="choice.answer_option?.label"
                    size="md"
                    shape="rounded"
                  />
                  <BadgeBoolean
                    v-if="
                      answer.block?.block_type.value === 'boolean' &&
                      !answer.block.choice_type &&
                      choice.choice?.choice_text &&
                      answer.choices?.[0]?.choice?.choice_value !== undefined
                    "
                    :boolean="
                      answer.choices?.[0]?.choice?.choice_value
                        ? !!parseInt(String(answer.choices[0].choice.choice_value))
                        : null
                    "
                    labelNull="I don't know"
                    nullable
                  />
                  <Badge
                    v-if="answer.block?.block_type.value !== 'boolean' && !answer.block.choice_type"
                    :label="choice.choice?.choice_text"
                    size="md"
                    shape="rounded"
                  />
                </template>
              </div>
            </template>
          </div>
        </div>
      </div>
    </InformationCollapsible>
  </Slideout>
</template>
