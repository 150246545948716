<script setup lang="ts">
import type { HybridlyFormData } from '@/@types/global';

import { ButtonColor } from '@/hooks/useButtonClasses';

import Button from '@/components/button/Button.vue';
import Fieldset from '@/components//fieldset/Fieldset.vue';
import Form from '@/components/form/Form.vue';
import FormFooter from '@/components/formFooter/FormFooter.vue';
import FormHeader from '@/components/formHeader/FormHeader.vue';
import FormTextInput from '@/components/formTextInput/FormTextInput.vue';
import { Selectable } from '@/components/selectBox/selectBox';
import StudentGroupSingleComboBoxInput from '@/components/studentGroups/StudentGroupSingleComboBoxInput.vue';
import FormField from '@/components/formField/FormField.vue';
import FormRadioInput from '@/components/formRadioInput/FormRadioInput.vue';
import FormListboxInput from '@/components/formListboxInput/FormListboxInput.vue';
import FormDelete from '@/components/formDelete/FormDelete.vue';

defineProps<{
  campaign?: App.Sms.Data.AutomatedCampaigns.AutomatedCampaignData;
  form: HybridlyFormData<{
    name: string;
    student_group_id: Selectable<number> | null;
    is_targeting_parents: boolean;
  }>;
  isEditing?: boolean;
}>();

function handleCancel() {
  router.navigate({ url: route('sms.campaigns.index') });
}
</script>

<template>
  <Form @submit.prevent="form.submit">
    <template #header>
      <FormHeader
        canClose
        :title="`${isEditing ? 'Edit' : 'Add'} a SMS Campaign`"
        :description="
          isEditing
            ? 'Use the form below to edit the SMS campaign.'
            : 'Fill out the form below to add a new SMS campaign.'
        "
        @onClose="handleCancel"
      />
    </template>

    <Fieldset title="General information">
      <FormTextInput
        v-model="form.fields.name"
        label="Campaign name"
        name="name"
        type="text"
        :error="form.errors.name"
      />

      <FormField label="Student group" :error="form.errors.student_group_id">
        <StudentGroupSingleComboBoxInput v-model="form.fields.student_group_id" />
      </FormField>

      <FormListboxInput
        v-if="isEditing"
        label="Campaign status"
        v-model="form.fields.status"
        :options="[
          { label: 'Active', value: 'active' },
          { label: 'Paused', value: 'paused' }
        ]"
      />

      <FormRadioInput
        label="Audience target"
        name="is_targeting_parents"
        ariaLabel="Audience target"
        :value="form.fields.is_targeting_parents"
        @update:modelValue="(value) => (form.fields.is_targeting_parents = value)"
        :radioInputs="[
          {
            label: 'Students only',
            value: 'false',
            helperText: 'Campaign messages will only be sent to the students in the student group.'
          },
          {
            label: 'Parents only',
            value: 'true',
            helperText:
              'Campaign messages will only be sent to the parents of the students in the student group.'
          }
        ]"
      />
    </Fieldset>
    <template #footer>
      <FormFooter>
        <Button
          :color="ButtonColor.primary"
          :isDisabled="form.processing || !form.isDirty"
          :isLoading="form.processing"
          type="submit"
          variant="solid"
          >{{ isEditing ? 'Update' : 'Add' }} SMS Campaign</Button
        >
        <Button :color="ButtonColor.slate" type="button" variant="outlined" @click="handleCancel">
          Cancel
        </Button>
      </FormFooter>
    </template>
    <template v-if="isEditing && campaign" #delete>
      <FormDelete
        :route="route('sms.campaigns.delete', { campaign: campaign.id })"
        recordType="campaign"
      />
    </template>
  </Form>
</template>
