<script setup lang="ts">
type EnumData = App.Base.Data.EnumData;

import type { Selectable } from '@/components/selectBox/selectBox';
import type { TableSelectionState } from '@/hooks/useHybridlyTable';

import pluralize from 'pluralize';

import { getInteractionOptions } from '@/api/InteractionApi';
import { getTestOptions } from '@/api/TestApi';
import { formatDate } from '@/utils/date';

import Alert from '@/components/alert/Alert.vue';
import Dialog from '@/components/dialog/Dialog.vue';
import Fieldset from '@/components/fieldset/Fieldset.vue';
import FormMultiStudentInput from '@/components/formComboboxInput/FormMultiStudentInput.vue';
import FormStudentGroupInput from '@/components/formComboboxInput/FormStudentGroupInput.vue';
import StudentInteractionForm from '@/components/students/studentActions/StudentInteractionForm.vue';

const props = defineProps<{
  isOpen: boolean;
  selectedStudentsCount?: number;
  tableSelectionState?: TableSelectionState;
}>();

const emit = defineEmits<{
  onCancel: [void];
  onClose: [void];
}>();

const queryParameters = useQueryParameters();

const testTypes = ref<EnumData[]>([]);
const interactionTopics = ref<EnumData[]>([]);
const interactionTypes = ref<EnumData[]>([]);
const interactionMediums = ref<EnumData[]>([]);
const interactionMeetingLengths = ref<EnumData[]>([]);

onMounted(async () => {
  const interactionOptions = await getInteractionOptions();
  const testOptions = await getTestOptions();

  interactionTopics.value = interactionOptions.interactionTopics;
  interactionTypes.value = interactionOptions.interactionTypes;
  interactionMediums.value = interactionOptions.interactionMediums;
  interactionMeetingLengths.value = interactionOptions.interactionMeetingLengths;

  testTypes.value = testOptions.testTypes;
});

const form = useForm({
  method: 'POST',
  url: route('students.bulk-store.interaction'),
  fields: {
    ids: props.tableSelectionState?.selectedRowIds || ([] as Selectable<number>[]),
    filters: queryParameters.filters || null,
    all: props.tableSelectionState?.areAllRecordsSelected,
    student_group_id: null as Selectable<number> | null,
    notes: '',
    topics: [] as Selectable<string>[] | null,
    application_assisted_institute_ids: null as Selectable<number>[] | null,
    award_letter_review_institute_ids: null as Selectable<number>[] | null,
    scholarship_names: null as Selectable<string>[] | null,
    tests: [] as Array<{
      type: Selectable<string>;
      date: string;
      test?: Selectable<string>;
    }>,
    types: [] as Selectable<string>[] | null,
    institute_id: null as Selectable<number> | null,
    medium: { value: 'in-person', label: 'In Person' } as Selectable<string>,
    meeting_length: null as Selectable<string> | null,
    date: formatDate(new Date(), { format: 'yyyy-MM-dd' })
  },
  transform: (fields) => ({
    ...fields,
    ids: props.tableSelectionState?.selectedRowIds || fields.ids.map((student) => student.value),
    filters: queryParameters.filters || null,
    all: props.tableSelectionState?.areAllRecordsSelected ?? false,
    student_group_id: fields.student_group_id?.value,
    topics: fields.topics?.map((type) => type.value) ?? [],
    application_assisted_institute_ids:
      fields.application_assisted_institute_ids?.map((institute) => institute.value) ?? null,
    award_letter_review_institute_ids:
      fields.award_letter_review_institute_ids?.map((institute) => institute.value) ?? null,
    scholarship_names: fields.scholarship_names?.map((scholarship) => scholarship.label) ?? null,
    tests:
      fields.tests && fields.tests.length > 0
        ? fields.tests.map((testItem) => ({
            type:
              testItem.type?.value === 'custom-test' && testItem.test?.value
                ? testItem.test?.value
                : testItem.type?.value,
            date: testItem.date,
            test: testItem.test?.value
          }))
        : null,
    types: fields.types?.map((type) => type.value) ?? null,
    institute_id: fields.institute_id?.value,
    medium: fields.medium?.value,
    meeting_length: fields.meeting_length?.value
  }),
  hooks: {
    success() {
      props.tableSelectionState?.reset();
      emit('onClose');
    }
  }
});

function handleConfirm() {
  form.submit();
}
</script>

<template>
  <Dialog
    :isOpen="isOpen"
    :isLoading="form.processing"
    confirmButtonLabel="Add interaction"
    description="Add the same interaction for all selected students"
    title="Add Interaction"
    @onConfirm="handleConfirm"
    @onClose="emit('onClose')"
    @onCancel="emit('onCancel')"
  >
    <div class="flex w-full flex-col gap-y-8">
      <template
        v-if="
          !tableSelectionState?.selectedRowIds.length && !tableSelectionState?.areAllRecordsSelected
        "
      >
        <Fieldset title="Selected students">
          <FormMultiStudentInput
            :error="form.errors.ids as unknown as string"
            :modelValue="form.fields.ids as Selectable<number>[]"
            label="Students"
            placeholder="Select student"
            @update:modelValue="(value) => (form.fields.ids = value)"
          />
        </Fieldset>
        <Fieldset title="Selected student group">
          <FormStudentGroupInput
            :error="form.errors.student_group_id as unknown as string"
            :modelValue="form.fields.student_group_id as Selectable<number>"
            clearable
            label="Student group"
            placeholder="Select student group"
            @update:modelValue="(value) => (form.fields.student_group_id = value)"
          />
        </Fieldset>
      </template>
      <StudentInteractionForm
        :testTypes="testTypes"
        :form="form"
        :mediums="interactionMediums"
        :meetingLengths="interactionMeetingLengths"
        :topics="interactionTopics"
        :types="interactionTypes"
        class="w-full grow"
      />
      <Alert v-if="selectedStudentsCount" title="Selected Students" variant="soft">
        You are creating this interaction for
        <span class="font-bold">{{ selectedStudentsCount }}</span> selected
        {{ pluralize('student', selectedStudentsCount) }}.
      </Alert>
    </div>
  </Dialog>
</template>
