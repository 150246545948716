<script setup lang="tsx">
import { ButtonColor, ButtonSize, ButtonVariant } from '@/hooks/useButtonClasses';

import Button from '@/components/button/Button.vue';

const props = defineProps<{
  template: App.Reports.Data.ReportTemplateData;
}>();

const form = useForm({
  method: 'post',
  url: route('report-templates.generate', { reportTemplate: props.template.id }),
  fields: {},
  preserveState: true,
  preserveScroll: true
});

function handleClick() {
  form.submit();
}
</script>

<template>
  <Button
    :isLoading="form.processing"
    :variant="ButtonVariant.soft"
    :color="ButtonColor.primary"
    :size="ButtonSize.sm"
    class="flex-1"
    @click="handleClick"
    >Export data</Button
  >
</template>
