<script setup lang="ts">
import { buildSelectableUser } from '@/utils/buildSelectable';

import CenteredContent from '@/components/content/CenteredContent.vue';
import RegionForm from '@/components/regions/RegionForm.vue';

const props = defineProps<{
  region: App.Configuration.Data.RegionData;
  returnUrl?: string;
}>();

const form = useForm({
  method: 'PUT',
  url: route('regions.update', { region: props.region.id }),
  fields: {
    display_name: props.region.display_name,
    description: props.region.description,
    director_ids: (props.region.users || []).map((user) => buildSelectableUser(user))
  },
  transform: (fields) => ({
    ...fields,
    director_ids: fields.director_ids.map((item) => item?.value)
  })
});
</script>


					<script lang="ts">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <CenteredContent>
    <RegionForm isEditing :form="form" :returnUrl="returnUrl" />
  </CenteredContent>
</template>
