<script setup lang="ts">
import type { ShowProgramStudentFieldProps } from '@/domains/configuration/views/program-student-fields/ShowProgramStudentField.vue';

import { formatDate } from '@/utils/date';

import { useAuth } from '@/hooks/useAuth';
import { useDialogHelpers } from '@/hooks/useDialogHelpers';

import Badge from '@/components/badge/Badge.vue';
import BadgeBooleanList from '@/components/badge/BadgeBooleanList.vue';
import DangerDialog from '@/components/dialog/DangerDialog.vue';
import DefinitionList from '@/components/definitionList/DefinitionList.vue';
import DefinitionListItem from '@/components/definitionList/DefinitionListItem.vue';
import IconButton from '@/components/button/IconButton.vue';
import InformationCollapsible from '@/components/collapsible/InformationCollapsible.vue';
import Slideout from '@/components/slideout/Slideout.vue';

import ArrowDown from '@/icons/line/arrow-down.svg';
import ArrowUp from '@/icons/line/arrow-up.svg';
import Calendar from '@/icons/line/calendar.svg';
import CustomField from '@/icons/line/custom-field.svg';
import EditAlt from '@/icons/line/edit-alt.svg';
import ElipsisCircle from '@/icons/line/elipsis-circle.svg';
import FilesLandscapeAlt from '@/icons/line/files-landscapes-alt.svg';
import Label from '@/icons/line/label.svg';
import ListUi from '@/icons/line/list-ui.svg';
import SortAmountDown from '@/icons/line/sort-amount-down.svg';
import TrashIcon from '@/icons/line/trash.svg';

const props = defineProps<ShowProgramStudentFieldProps>();

const { show, close } = useDialog();
const { isDialogOpen, toggleDialog } = useDialogHelpers();

const { hasPermission } = useAuth();

const form = useForm({
  method: 'DELETE',
  spoof: false,
  url: route('program-student-fields.delete', {
    programStudentField: props.programStudentField.id
  }),
  fields: {},
  hooks: {
    success() {
      close();
    }
  }
});

async function handleProgramStudentFieldDelete() {
  await form.submit();
  toggleDialog();
}
</script>

<template>
  <Slideout
    :title="programStudentField.field_label ?? 'Custom Field Information'"
    :isOpen="show"
    @onClose="close"
    :icon="CustomField"
  >
    <template #actions>
      <IconButton
        v-if="programStudentField.authorization.update"
        :href="
          route('program-student-fields.edit', { programStudentField: programStudentField.id })
        "
        :icon="EditAlt"
        ariaLabel="Edit custom field"
        size="sm"
        variant="soft"
      />
      <IconButton
        v-if="programStudentField.authorization.delete"
        color="danger"
        :icon="TrashIcon"
        ariaLabel="Move custom field to trash"
        size="sm"
        variant="soft"
        @click="toggleDialog"
      />
    </template>
    <InformationCollapsible title="Custom field information">
      <DefinitionList>
        <DefinitionListItem
          :icon="Label"
          title="Type"
          :description="programStudentField.type.label"
        />
        <DefinitionListItem
          v-if="programStudentField.type.value === 'select'"
          :icon="ListUi"
          title="Options"
        >
          <ol class="ml-4 list-disc space-y-1 py-1">
            <li
              v-for="(rule, index) in programStudentField.rules?.[0]?.parameters?.options ?? []"
              :key="index"
              class="text-sm leading-5 text-slate-900"
            >
              {{ rule }}
            </li>
          </ol>
        </DefinitionListItem>
        <template v-if="programStudentField.type.value === 'number'">
          <DefinitionListItem
            :icon="ArrowDown"
            title="Min"
            :description="programStudentField?.rules?.[0]?.parameters?.min ?? 'No minimum'"
          />
          <DefinitionListItem
            :icon="ArrowUp"
            title="Max"
            :description="programStudentField?.rules?.[0]?.parameters?.max ?? 'No maximum'"
          />
        </template>
        <DefinitionListItem
          v-if="hasPermission('read-program')"
          :description="!programStudentField.program ? 'No program' : undefined"
          :icon="FilesLandscapeAlt"
          title="Program"
        >
          <Badge
            v-if="programStudentField.program"
            :label="programStudentField.program.display_name"
            variant="soft"
            size="md"
          />
        </DefinitionListItem>
        <DefinitionListItem
          :icon="SortAmountDown"
          title="Order"
          :description="programStudentField.order ?? 'No order preference'"
        />
        <DefinitionListItem :icon="ElipsisCircle" title="Other">
          <BadgeBooleanList
            :items="{
              Enabled: !!programStudentField.enabled,
              Readonly: !!programStudentField.readonly,
              'Show in results': !!programStudentField.in_results
            }"
          />
        </DefinitionListItem>
        <DefinitionListItem
          :description="
            formatDate(programStudentField.created_at, {
              format: 'MM/dd/yyyy'
            }) || 'No created date'
          "
          :icon="Calendar"
          title="Created"
        />
      </DefinitionList>
    </InformationCollapsible>
  </Slideout>
  <DangerDialog
    title="Move Custom Field to Trash"
    message="Are you sure you want to move this custom field to the trash?"
    confirmButtonLabel="Move to trash"
    :isOpen="isDialogOpen"
    :isLoading="form.processing"
    @onConfirm="handleProgramStudentFieldDelete"
    @onCancel="toggleDialog"
    @onClose="toggleDialog"
  />
</template>
