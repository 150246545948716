<script setup lang="ts">
import DangerDialog from '@/components/dialog/DangerDialog.vue';

const props = defineProps<{
  message: App.Sms.Data.AutomatedCampaigns.AutomatedOutboundMessageData;
}>();

const isDeleteDialogOpen = ref(false);

const deleteForm = useForm({
  fields: {},
  method: 'delete',
  url: route('sms.outbound-message.delete', { message: props.message })
});

function openDialog() {
  isDeleteDialogOpen.value = true;
}

function handleDeleteMessage() {
  deleteForm.submit();
}
</script>

<template>
  <slot name="default" v-bind="{ openDialog }" />

  <DangerDialog
    title="Delete message"
    message="Are you sure you want to delete this message? You will not be able to revert this action."
    confirmButtonLabel="Delete message"
    :isOpen="isDeleteDialogOpen"
    :isLoading="deleteForm.processing"
    @onCancel="isDeleteDialogOpen = false"
    @onClose="isDeleteDialogOpen = false"
    @onConfirm="handleDeleteMessage"
  />
</template>
