<script setup lang="ts">
type EnumData = App.Base.Data.EnumData;
type StudentData = App.Students.Data.StudentData;

import type { Selectable } from '@/components/selectBox/selectBox';

import CenteredContent from '@/components/content/CenteredContent.vue';
import Divider from '@/components/divider/Divider.vue';
import Fieldset from '@/components/fieldset/Fieldset.vue';
import FormDateInput from '@/components/formDateInput/FormDateInput.vue';
import FormInlineFields from '@/components/formInlineFields/FormInlineFields.vue';
import FormListboxInput from '@/components/formListboxInput/FormListboxInput.vue';
import FormTextInput from '@/components/formTextInput/FormTextInput.vue';
import StudentFormWrapper from '@/components/students/StudentFormWrapper.vue';

import Envelope from '@/icons/line/envelope.svg';
import Phone from '@/icons/line/phone.svg';

const props = defineProps<{
  student: StudentData;
  returnUrl?: string;
  genders: EnumData[];
  ethnicities: EnumData[];
}>();

const form = useForm({
  method: 'PUT',
  url: route('students.update', { student: props.student.id }),
  updateInitials: true,
  fields: {
    first_name: props.student.first_name,
    middle_name: props.student.middle_name,
    last_name: props.student.last_name,
    suffix: props.student.suffix,
    preferred_name: props.student.preferred_name,
    dob: props.student.dob,
    gender: props.student.gender as Selectable<string> | null,
    ethnicity: props.student.ethnicity as Selectable<string> | null,
    email: props.student.email,
    cell_phone: props.student.cell_phone,
    home_phone: props.student.home_phone
  },
  transform: (fields) => ({
    ...fields,
    gender: fields.gender?.value,
    ethnicity: fields.ethnicity?.value
  })
});
</script>


					<script lang="ts">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <CenteredContent>
    <StudentFormWrapper
      isEditing
      :form="form"
      :isDirty="form.isDirty"
      :isProcessing="form.processing"
      :returnUrl
      :studentId="student.id"
      @onSubmit="form.submit"
    >
      <div class="flex flex-col gap-8">
        <Fieldset title="Student information">
          <FormTextInput
            :error="form.errors.first_name"
            :modelValue="form.fields.first_name"
            label="First name"
            name="first_name"
            @update:modelValue="(value) => (form.fields.first_name = value)"
          />
          <FormTextInput
            :error="form.errors.preferred_name"
            :modelValue="form.fields.preferred_name"
            label="Preferred name (optional)"
            name="preferred_name"
            placeholder="Student's preferred name"
            @update:modelValue="(value) => (form.fields.preferred_name = value)"
          />
          <FormTextInput
            :error="form.errors.middle_name"
            :modelValue="form.fields.middle_name"
            label="Middle name"
            name="middle_name"
            @update:modelValue="(value) => (form.fields.middle_name = value)"
          />
          <FormTextInput
            :error="form.errors.last_name"
            :modelValue="form.fields.last_name"
            label="Last name"
            name="last_name"
            @update:modelValue="(value) => (form.fields.last_name = value)"
          />
          <FormTextInput
            :error="form.errors.suffix"
            :modelValue="form.fields.suffix"
            label="Suffix (optional)"
            name="suffix"
            placeholder="e.g. Jr."
            @update:modelValue="(value) => (form.fields.suffix = value)"
          />
          <FormDateInput
            :error="form.errors.dob"
            :modelValue="form.fields.dob"
            label="Date of birth"
            name="dob"
            @update:modelValue="(value) => (form.fields.dob = value)"
          />
          <FormListboxInput
            label="Gender"
            :error="form.errors.gender"
            :options="genders"
            :modelValue="form.fields.gender"
            @update:modelValue="(value) => (form.fields.gender = value)"
          />
          <FormListboxInput
            label="Ethnicity"
            :error="form.errors.ethnicity"
            :options="ethnicities"
            :modelValue="form.fields.ethnicity"
            @update:modelValue="(value) => (form.fields.ethnicity = value)"
          />
        </Fieldset>
        <Divider />
        <Fieldset title="Contact information">
          <FormTextInput
            :error="form.errors.email"
            :iconLeft="Envelope"
            :modelValue="form.fields.email"
            label="Email"
            name="email"
            @update:modelValue="(value) => (form.fields.email = value)"
          />
          <FormInlineFields>
            <FormTextInput
              :error="form.errors.home_phone"
              :iconLeft="Phone"
              :modelValue="form.fields.home_phone"
              label="Home phone"
              name="home_phone"
              @update:modelValue="(value) => (form.fields.home_phone = value)"
            />
            <FormTextInput
              :error="form.errors.cell_phone"
              :iconLeft="Phone"
              :modelValue="form.fields.cell_phone"
              label="Cell phone"
              name="cell_phone"
              @update:modelValue="(value) => (form.fields.cell_phone = value)"
            />
          </FormInlineFields>
        </Fieldset>
      </div>
    </StudentFormWrapper>
  </CenteredContent>
</template>
