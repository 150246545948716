<script setup lang="ts"></script>
<template>
  <div>
    <div
      class="invisible absolute translate-x-8 transform opacity-0 transition group-focus-within/table-row:visible group-focus-within/table-row:static group-focus-within/table-row:translate-x-0 group-focus-within/table-row:opacity-100 group-hover/table-row:visible group-hover/table-row:static group-hover/table-row:translate-x-0 group-hover/table-row:opacity-100"
    >
      <slot name="onHover"></slot>
    </div>
    <div
      class="transform transition group-focus-within/table-row:invisible group-focus-within/table-row:absolute group-focus-within/table-row:translate-x-8 group-focus-within/table-row:opacity-0 group-hover/table-row:invisible group-hover/table-row:absolute group-hover/table-row:translate-x-8 group-hover/table-row:opacity-0"
    >
      <slot></slot>
    </div>
  </div>
</template>
