<script setup lang="tsx">
import type { CustomCells, HybridlyRow } from '@/components/table/Table';

import capitalize from 'lodash/capitalize';

import { ButtonColor, ButtonSize, ButtonVariant } from '@/hooks/useButtonClasses';
import { useAlertColor } from '@/hooks/useAlertColor';
import { useHybridlyTable } from '@/hooks/useHybridlyTable';
import { useTableRowClick } from '@/hooks/useTableRowClick';

import Button from '@/components/button/Button.vue';
import EmptyState from '@/components/emptyState/EmptyState.vue';
import IndexPageLayout from '@/components/pageLayout/IndexPageLayout.vue';
import TableBadgesCell from '@/components/table/TableBadgesCell.vue';
import TabNavDivider from '@/components/tabNav/TabNavDivider.vue';
import TabNavItem from '@/components/tabNav/TabNavItem.vue';
import RichContentVue from '@/components/richTextInput/RichContent.vue';

import FilesLandscapeAlt from '@/icons/line/files-landscapes-alt.svg';
import Plus from '@/icons/line/plus.svg';
import UserCircle from '@/icons/line/user-circle.svg';
import BulkTrashButton from '@/components/table/BulkTrashButton.vue';
import BulkRestoreButton from '@/components/table/BulkRestoreButton.vue';
import TableTruncatedTextCell from '@/components/table/TableTruncatedTextCell.vue';

const props = defineProps<{
  alerts: Table<App.Configuration.Data.AlertData>;
  trashActive: boolean;
  counts: {
    active_count: number;
    trashed_count: number;
  };
}>();

type AlertRow = HybridlyRow<App.Configuration.Data.ProgramData>;

const { getAlertColor } = useAlertColor();
const { table, tableSelectionState } = useHybridlyTable<App.Configuration.Data.ProgramData>(
  props,
  'alerts'
);
const { handleRowClick } = useTableRowClick();

const customCells = {
  title: ({ row, column }) => <TableTruncatedTextCell text={row.value(column.key)} />,
  content_html: ({ row, column }) => <RichContentVue class="p-2" html={row.value(column.key)} />,
  type: ({ row, column }) => (
    <TableBadgesCell
      badgeColor={getAlertColor(row.value(column.key).value)}
      badgeShape="pill"
      badgeSize="md"
      badgesToDisplay={1}
      badgeVariant="solid"
      items={[capitalize(row.value(column.key).label)]}
    />
  ),
  users: ({ row, column }) => (
    <TableBadgesCell
      badgesToDisplay={2}
      itemIcon={UserCircle}
      items={row.value(column.key).map((user: App.Auth.Data.UserData) => user.name)}
    />
  ),
  programs: ({ row, column }) => (
    <TableBadgesCell
      badgesToDisplay={2}
      itemIcon={FilesLandscapeAlt}
      items={row
        .value(column.key)
        .map((program: App.Configuration.Data.ProgramData) => program.display_name)}
    />
  ),
  creator: ({ row, column }) => (
    <TableBadgesCell
      badgesToDisplay={1}
      itemIcon={UserCircle}
      items={[row.value(column.key).name]}
    />
  )
} as CustomCells<App.Configuration.Data.AlertData>;

function handleAlertRowClick(event: MouseEvent, row: AlertRow) {
  const url = route('alerts.show', {
    alert: row.key
  });
  handleRowClick(url, event);
}
</script>


					<script lang="tsx">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <IndexPageLayout
    title="Alerts"
    :table="table"
    :customCells="customCells"
    :stickyEndColumns="1"
    :stickyStartColumns="1"
    :clickableRows="!trashActive"
    :tableSelectionState="tableSelectionState"
    @clickRow="($event, row) => handleAlertRowClick($event, row as AlertRow)"
  >
    <template #rightButtons>
      <template v-if="tableSelectionState.hasSelection">
        <BulkTrashButton
          v-if="!trashActive"
          recordType="Alerts"
          :route="route('alerts.bulk-delete')"
          :tableSelectionState="tableSelectionState"
        />

        <BulkRestoreButton
          v-if="trashActive"
          recordType="Alerts"
          :route="route('alerts.bulk-restore')"
          :tableSelectionState="tableSelectionState"
        />
      </template>

      <template v-else>
        <Button
          :color="ButtonColor.primary"
          :variant="ButtonVariant.solid"
          :size="ButtonSize.sm"
          :iconLeft="Plus"
          :href="route('alerts.create')"
        >
          Add a alert
        </Button>
      </template>
    </template>

    <template #tabs>
      <TabNavItem
        :badgeLabel="counts.active_count"
        :isActive="!trashActive"
        label="Active"
        variant="inline"
        :href="route('alerts.index')"
      />
      <TabNavDivider layout="inline" />
      <TabNavItem
        :badgeLabel="counts.trashed_count ?? 0"
        :isActive="trashActive"
        label="Trashed"
        variant="inline"
        :href="route('alerts.index', { trashed: true })"
      />
    </template>

    <template #emptyState>
      <EmptyState
        v-if="trashActive"
        title="No alerts were moved to the trash"
        description="Trashed alerts will appear on this screen"
      />
      <EmptyState
        v-else
        title="No alerts found"
        description="Create a new alert by clicking the button below"
        actionLabel="Add an alert"
        :actionHref="route('alerts.create')"
      />
    </template>
  </IndexPageLayout>
</template>
