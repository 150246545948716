import { orderBy } from 'lodash';
import { Ref, computed, ref } from 'vue';

export function useSortableRows<T>(rows: Ref<T[]>, defaultSort: string | null = null) {
  const currentSort = ref<string | null>(defaultSort);

  function handleSort(columnKey: string | null) {
    currentSort.value = columnKey;
  }

  const sortedRows = computed(() => {
    if (!currentSort.value) {
      return rows.value;
    }

    const order = currentSort.value.startsWith('-') ? 'desc' : 'asc';
    const orderAttribute = currentSort.value.startsWith('-')
      ? currentSort.value.slice(1)
      : currentSort.value;

    // Note: this just does "dumb" sorting on the row[sortColumn] value.
    // We may need to update this in the future, if we need local sorting with more complex logic.
    return orderBy(rows.value, orderAttribute, order);
  });

  return {
    sortedRows,
    handleSort,
    currentSort
  };
}
