<script setup lang="ts">
import Thread from '@/components/sms/Thread.vue';
type ThreadData = App.Sms.Data.ThreadData;
type RelatedThreadData = App.Sms.Data.RelatedThreadData;
type AdmitHubContactData = App.AdmitHub.Data.AdmitHubContactData;
import { WithLoadedRelations } from '@/@types/global';
import { showAdmitHubContact } from '@/api/AdmitHubApi';
import AdmitHubContact from '@/components/admitHub/AdmitHubContact.vue';

const props = withDefaults(
  defineProps<{
    thread: WithLoadedRelations<ThreadData, 'recipient'>;
    hasSmsEnabled: boolean;
    showRecipientHeader?: boolean;
    relatedThreads: RelatedThreadData[];
  }>(),
  {
    showRecipientHeader: true
  }
);

const chatbotThread = ref<WithLoadedRelations<AdmitHubContactData, 'contactable'> | null>(null);
const activeThread = ref<WithLoadedRelations<ThreadData, 'recipient'>>(props.thread);

watch(
  () => props.thread,
  (newThread, oldThread) => {
    if (newThread.id !== oldThread.id) {
      activeThread.value = newThread;
      chatbotThread.value = null;
    }
    // If the updated thread is the active thread, update the active thread
    if (newThread.id === activeThread.value.id) {
      activeThread.value = newThread;
    }
  }
);

watch(
  () => props.relatedThreads,
  (relatedThreads) => {
    // If the active thread has been updated, update the active thread
    const newActiveThread = relatedThreads.find(
      (relatedThread) => relatedThread.thread?.id === activeThread.value.id
    );
    if (newActiveThread?.thread) {
      activeThread.value = newActiveThread.thread as WithLoadedRelations<ThreadData, 'recipient'>;
    }
  }
);

function handleShowThread(thread: WithLoadedRelations<ThreadData, 'recipient'>) {
  activeThread.value = thread;

  chatbotThread.value = null;
}

async function handleShowAdmitHubContact(contactId: number) {
  chatbotThread.value = await showAdmitHubContact(contactId);
}
</script>

<template>
  <Thread
    v-if="!chatbotThread"
    :key="activeThread.id"
    :thread="activeThread"
    :relatedThreads="relatedThreads"
    :hasSmsEnabled="hasSmsEnabled"
    :showRecipientHeader="showRecipientHeader"
    @showAdmitHubContact="handleShowAdmitHubContact"
    @showThread="handleShowThread"
  />
  <AdmitHubContact
    v-else
    :key="chatbotThread.id"
    :contact="chatbotThread"
    :relatedThreads="relatedThreads"
    :showRecipientHeader="showRecipientHeader"
    showNav
    @showAdmitHubContact="handleShowAdmitHubContact"
    @showThread="handleShowThread"
  />
</template>
