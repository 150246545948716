<script setup lang="ts">
type EnumData = App.Base.Data.EnumData;

import type { Selectable } from '@/components/selectBox/selectBox';
import type { HybridlyFormData } from '@/@types/global';

import { ButtonColor, ButtonVariant } from '@/hooks/useButtonClasses';

import { buildSelectableCustomTest } from '@/utils/buildSelectable';

import Button from '@/components/button/Button.vue';
import DangerDialog from '@/components/dialog/DangerDialog.vue';
import Divider from '@/components/divider/Divider.vue';
import Fieldset from '@/components/fieldset/Fieldset.vue';
import Form from '@/components/form/Form.vue';
import FormCheckboxInput from '@/components/formCheckboxInput/FormCheckboxInput.vue';
import FormDateInput from '@/components/formDateInput/FormDateInput.vue';
import FormFooter from '@/components/formFooter/FormFooter.vue';
import FormHeader from '@/components/formHeader/FormHeader.vue';
import FormListboxInput from '@/components/formListboxInput/FormListboxInput.vue';
import FormRows from '@/components/formRows/FormRows.vue';
import FormSwitchInput from '@/components/formSwitchInput/FormSwitchInput.vue';
import FormTextInput from '@/components/formTextInput/FormTextInput.vue';

const props = defineProps<{
  isEditing?: boolean;
  form: HybridlyFormData<{
    type: EnumData;
    subject: EnumData;
    test: EnumData;
    date: string;
    scores: Record<string, number>;
    is_waiver_assisted: boolean;
    is_registered: boolean;
    is_waived: boolean;
  }>;
  returnUrl?: string;
  types: EnumData[];
  scoreMap: Record<string, Record<string, { rules?: string[]; step?: number }>>;
  labelMap: Record<string, string>;
  customTests: App.Configuration.Data.CustomTestData[];
  apCourses: EnumData[];
  student: App.Students.Data.StudentData;
}>();

const isCancelDialogOpen = ref(false);

const currentCustomTest = computed(
  () => props.customTests.filter((test) => test.name === props.form.fields.test?.value)[0]
);

const shouldShowScores = computed(() => {
  const isComplete = props.form.fields.is_complete;
  const isNotCustomTest = props.form.fields.type && props.form.fields.type?.value !== 'custom-test';
  const isCustomTestWithValue =
    props.form.fields.type?.value === 'custom-test' && props.form.fields.test?.value;

  return isComplete && (isNotCustomTest || isCustomTestWithValue);
});

const isScoresSwitchDisabled = computed(() => {
  const hasNoTypeAndNotCustomTest =
    !props.form.fields.type && props.form.fields.type?.value !== 'custom-test';
  const isCustomTestWithoutValue =
    props.form.fields.type?.value === 'custom-test' && !props.form.fields.test?.value;
  const isApCourseWithoutSubject =
    props.form.fields.type?.value === 'ap-course' && !props.form.fields.subject?.value;

  return hasNoTypeAndNotCustomTest || isCustomTestWithoutValue || isApCourseWithoutSubject;
});

const customTestOptions = props.customTests.map((test) =>
  buildSelectableCustomTest(test)
) as Selectable<string>[];

const { onBackForward } = useBackForward();

// TODO: Fix intecepting the back button
onBackForward(() => {
  router.abort();
});

function handleToggleCancelDialog() {
  if (props.form.isDirty) {
    isCancelDialogOpen.value = !isCancelDialogOpen.value;
    return;
  }
  handleRedirect();
}

function handleRedirect() {
  router.navigate({
    url: props.returnUrl || route('students.tests.index', { student: props.student.id })
  });
}

function handleResetScores() {
  props.form.fields.scores = {};
}

function handleResetCustomTestScores() {
  ['score', 'score2', 'score3', 'score4', 'score5'].forEach((score) => {
    props.form.fields[score] = null;
  });
}

function handleTestTypeChange(type) {
  if (props.form.initial.type?.value === type.value) {
    props.form.reset();
  } else {
    props.form.fields.type = type;
    props.form.fields.test = null;
    props.form.fields.is_complete = false;
    props.form.errors = {};
    handleResetScores();
    handleResetCustomTestScores();
  }
}

function handleSubjectChange(subject) {
  props.form.fields.scores.subject = subject.value;
  props.form.fields.subject = subject;
}

function handleIsCompleteChange(isComplete) {
  if (props.form.initial.is_complete?.value === isComplete) {
    props.form.reset();
  } else {
    props.form.fields.is_complete = isComplete;
    handleResetScores();
    handleResetCustomTestScores();
    if (props.form.fields.subject?.value) {
      props.form.fields.scores.subject = props.form.fields.subject.value;
    }
  }
}
</script>

<template>
  <Form @submit.prevent="form.submit">
    <template #header>
      <FormHeader
        canClose
        :title="`${isEditing ? 'Edit' : 'Add'} a Exam`"
        :description="
          isEditing
            ? 'Use the form below to edit the exam.'
            : 'Fill out the form below add a new exam.'
        "
        @onClose="handleToggleCancelDialog"
      />
    </template>
    <div class="flex flex-col gap-y-8">
      <Fieldset title="Global information">
        <FormListboxInput
          label="Exam type"
          :error="form.errors.type"
          :options="types"
          :modelValue="form.fields.type"
          @update:modelValue="(value) => handleTestTypeChange(value)"
        />
        <FormListboxInput
          v-if="form.fields.type?.value === 'ap-course'"
          label="Course"
          :error="form.errors.scores?.subject"
          :options="apCourses"
          :modelValue="form.fields.subject"
          @update:modelValue="(value) => handleSubjectChange(value)"
        />
        <FormListboxInput
          v-if="form.fields.type?.value === 'custom-test'"
          label="Test"
          :error="form.errors.test"
          :options="customTestOptions"
          :modelValue="form.fields.test"
          @update:modelValue="(value) => (form.fields.test = value)"
        />
        <FormDateInput
          :error="form.errors.date"
          :modelValue="form.fields.date"
          label="Date"
          name="date"
          format="MMM dd, yyyy"
          :clearable="false"
          @update:modelValue="(value) => (form.fields.date = value)"
        />
      </Fieldset>

      <Divider />
      <Fieldset title="Scores">
        <FormSwitchInput
          :checked="form.fields.is_complete"
          :error="form.errors.is_complete"
          :isDisabled="isScoresSwitchDisabled"
          label="Add Exam Scores?"
          name="is_complete"
          helperText="If the exam is complete, you can add the scores."
          @update:checked="(value) => handleIsCompleteChange(value)"
        />
        <template v-if="shouldShowScores">
          <FormRows class="lg:w-1/2">
            <template v-if="form.fields.type.value !== 'custom-test'">
              <FormTextInput
                v-for="(values, score) in scoreMap[form.fields.type?.value]"
                :key="score"
                :error="form.errors.scores?.[score]"
                :modelValue="form.fields.scores[score]"
                :label="labelMap[score]"
                :step="values?.step"
                :name="score"
                type="number"
                @update:modelValue="(value) => (form.fields.scores[score] = value)"
              />
            </template>
            <template v-if="form.fields.type?.value === 'custom-test'">
              <FormTextInput
                v-if="currentCustomTest?.score"
                :error="form.errors?.score"
                :modelValue="form.fields.score"
                :label="currentCustomTest.score"
                name="score"
                type="number"
                @update:modelValue="(value) => (form.fields.score = value)"
              />
              <FormTextInput
                v-if="currentCustomTest?.score2"
                :error="form.errors?.score2"
                :modelValue="form.fields.score2"
                :label="currentCustomTest.score2"
                name="score2"
                type="number"
                @update:modelValue="(value) => (form.fields.score2 = value)"
              />
              <FormTextInput
                v-if="currentCustomTest?.score3"
                :error="form.errors?.score3"
                :modelValue="form.fields.score3"
                :label="currentCustomTest.score3"
                name="score3"
                type="number"
                @update:modelValue="(value) => (form.fields.score3 = value)"
              />
              <FormTextInput
                v-if="currentCustomTest?.score4"
                :error="form.errors?.score4"
                :modelValue="form.fields.score4"
                :label="currentCustomTest.score4"
                name="score4"
                type="number"
                @update:modelValue="(value) => (form.fields.score4 = value)"
              />
              <FormTextInput
                v-if="currentCustomTest?.score5"
                :error="form.errors?.score5"
                :modelValue="form.fields.score5"
                :label="currentCustomTest.score5"
                name="score5"
                type="number"
                @update:modelValue="(value) => (form.fields.score5 = value)"
              />
            </template>
          </FormRows>
        </template>
      </Fieldset>
      <Divider />
      <Fieldset title="Other information">
        <FormCheckboxInput
          :checked="form.fields.is_registered"
          :error="form.errors.is_registered"
          label="Has registered"
          name="is_registered"
          helperText="Has the student registered to take the exam?"
          @update:checked="(value) => (form.fields.is_registered = value)"
        />
        <FormCheckboxInput
          :checked="form.fields.is_waived"
          :error="form.errors.is_waived"
          label="Has waiver"
          name="is_waived"
          helperText="Has the student received a waiver?"
          @update:checked="(value) => (form.fields.is_waived = value)"
        />
        <FormCheckboxInput
          :checked="form.fields.is_waiver_assisted"
          :error="form.errors.is_waiver_assisted"
          label="Helped with waiver"
          name="is_waiver_assisted"
          helperText="Did you help the student receive a waiver?"
          @update:checked="(value) => (form.fields.is_waiver_assisted = value)"
        />
      </Fieldset>
    </div>
    <template #footer>
      <FormFooter>
        <Button
          type="submit"
          :color="ButtonColor.primary"
          :isDisabled="form.processing || !form.isDirty"
          :isLoading="form.processing"
          :variant="ButtonVariant.solid"
          >Confirm</Button
        >
        <Button
          :color="ButtonColor.slate"
          :variant="ButtonVariant.outlined"
          type="button"
          @click="handleToggleCancelDialog"
        >
          Back to exams
        </Button>
      </FormFooter>
    </template>
  </Form>
  <DangerDialog
    :title="`Cancel ${isEditing ? 'editing' : 'adding a new'} exam`"
    :message="`Are you sure you want to cancel ${isEditing ? 'editing' : 'adding'} this exam? All ${
      isEditing ? 'changes' : 'information'
    } will be lost.`"
    :isOpen="isCancelDialogOpen"
    cancelButtonLabel="Stay on this page"
    confirmButtonLabel="Go back to exams"
    @onCancel="handleToggleCancelDialog"
    @onClose="handleToggleCancelDialog"
    @onConfirm="handleRedirect"
  />
</template>
