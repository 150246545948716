<script setup lang="ts">
import DraftInteractionApprovalSlideout from '@/components/notifications/components/table/actions/DraftInteractionApprovalSlideout.vue';
import Slideout from '@/components/slideout/Slideout.vue';

export type ShowNotificationActionProps = {
  notification: App.Notifications.Data.NotificationData;
};

defineProps<ShowNotificationActionProps>();

const { show, close } = useDialog();
</script>

<template>
  <template v-if="!notification.data['action']">
    <Slideout title="Notification Action" :isOpen="show" @onClose="close">
      <p class="text-center">No action configured for this notification.</p>
    </Slideout>
  </template>
  <template v-else>
    <DraftInteractionApprovalSlideout
      v-if="notification.type === 'interaction_created'"
      :notification="notification"
      :isOpen="show"
      @onClose="close"
    />
  </template>
</template>
