<script setup lang="ts">
import IconButton from '@/components/button/IconButton.vue';
import PageHeader from '@/components/pageHeader/PageHeader.vue';
import ShowStudentPageLayout from '@/components/students/ShowStudentPageLayout.vue';
import FinancialAidDetails from '@/components/students/financialAid/FinancialAidDetails.vue';
import IndexStudentActionsTabNav, {
  IndexStudentActionsTabNavCounts
} from '@/components/students/studentActions/IndexStudentActionsTabNav.vue';

import EditAlt from '@/icons/line/edit-alt.svg';

defineProps<{
  counts: IndexStudentActionsTabNavCounts;
  student: App.Students.Data.StudentData;
  studentCssProfiles: App.Base.Data.EnumData[];
}>();
</script>


					<script lang="ts">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <ShowStudentPageLayout :student :title="student.name">
    <template #tabs>
      <IndexStudentActionsTabNav :student :counts />
    </template>
    <template #table>
      <PageHeader headingAs="h2" title="Financial Aid Application and Eligibility">
        <template #rightButtons>
          <IconButton
            ariaLabel="Edit financial aid application and eligibility"
            variant="soft"
            :icon="EditAlt"
            :href="
              route('students.financial-aid.edit', {
                student: student.id,
                financialAid: student.financial_aid?.id
              })
            "
          />
        </template>
      </PageHeader>
      <div class="border-y border-slate-200">
        <FinancialAidDetails :student :studentCssProfiles />
      </div>
    </template>
  </ShowStudentPageLayout>
</template>
