<script setup lang="ts" generic="T">
import { RouterLink } from 'hybridly/vue';

import { ButtonColor, ButtonVariant } from '@/hooks/useButtonClasses';

import Button from '@/components/button/Button.vue';

import IconArrowLeft from '@/icons/line/arrow-left.svg';
import IconArrowRight from '@/icons/line/arrow-right.svg';

type RouterLinkOptions = ExtractPropTypes<typeof RouterLink>['options'];

export type PaginationProps<T> = {
  pagination: Paginator<T>;
  compact?: boolean;
  showPageNumbers?: boolean;
  linkOptions?: RouterLinkOptions;
};

const props = withDefaults(defineProps<PaginationProps<T>>(), { showPageNumbers: true });

const links = computed(() => props.pagination.links.slice(1, -1));

const root = useTemplateRef<HTMLDivElement>('root');
const linkRefs = useTemplateRef<HTMLLIElement[]>('linkRefs');
const linkList = useTemplateRef<HTMLUListElement>('linkList');
const linksContainer = useTemplateRef<HTMLDivElement>('linksContainer');

const { width: availableWidth } = useElementSize(linksContainer);

const currentPage = computed(() => props.pagination.meta.current_page);

const linkGap = computed(() =>
  linkList.value ? parseFloat(getComputedStyle(linkList.value).gap) : 0
);

const totalPotentialWidthOfLinks = computed(() => {
  if (linkRefs.value?.length === 0) {
    return 0;
  }

  return (
    linkRefs.value?.reduce((acc, link) => {
      return acc + link.offsetWidth + linkGap.value;
    }, 0) ?? 0
  );
});

const linkWidth = computed(() => {
  return totalPotentialWidthOfLinks.value / links.value.length;
});

const maximumLinks = computed(() => {
  if (!availableWidth.value || !linkWidth.value) {
    return links.value.length;
  }

  return Math.floor(availableWidth.value / linkWidth.value);
});

const visibleLinks = computed(() => {
  if (maximumLinks.value >= links.value.length) {
    return links.value;
  }

  const minLinksToShow = Math.min(3, links.value.length);
  const actualLinksToShow = Math.max(minLinksToShow, maximumLinks.value);

  const currentIndex = currentPage.value - 1;
  const leftHalf = Math.floor((actualLinksToShow - 1) / 2);
  const rightHalf = actualLinksToShow - leftHalf - 1;

  let start = currentIndex - leftHalf;
  let end = currentIndex + rightHalf;

  if (start < 0) {
    end = Math.min(actualLinksToShow - 1, links.value.length - 1);
    start = 0;
  }

  if (end >= links.value.length) {
    start = Math.max(0, links.value.length - actualLinksToShow);
    end = links.value.length - 1;
  }

  return links.value.slice(start, end + 1);
});
</script>

<template>
  <div class="flex w-full justify-between px-4 py-3" ref="root">
    <Button
      :aria-label="compact ? 'Previous page' : null"
      :color="ButtonColor.slate"
      :disabled="!pagination.meta.prev_page_url"
      :href="pagination.meta.prev_page_url"
      :hrefOptions="{ preserveState: true, ...linkOptions }"
      :iconLeft="IconArrowLeft"
      :variant="ButtonVariant.outlined"
    >
      <span v-if="!compact">Previous</span>
    </Button>
    <div
      v-if="showPageNumbers"
      class="flex flex-1 justify-center overflow-hidden"
      ref="linksContainer"
    >
      <ul class="flex gap-1.5" ref="linkList">
        <li v-for="link in visibleLinks" :key="link.label" ref="linkRefs">
          <Component
            :is="link.url ? RouterLink : 'span'"
            :aria-hidden="link.url ? null : 'true'"
            :href="link.url"
            :options="{ preserveState: true, ...linkOptions }"
            class="flex h-8 w-8 items-center justify-center rounded-full text-sm hover:bg-secondary-100 active:bg-secondary-100"
            :class="{
              'bg-secondary-100/60 font-bold text-secondary-900': link.active,
              'text-slate-500': !link.active
            }"
          >
            {{ link.label }}
          </Component>
        </li>
      </ul>
    </div>
    <Button
      :aria-label="compact ? 'Next page' : null"
      :color="ButtonColor.slate"
      :disabled="!pagination.meta.next_page_url"
      :href="pagination.meta.next_page_url"
      :hrefOptions="{ preserveState: true, ...linkOptions }"
      :iconRight="IconArrowRight"
      :variant="ButtonVariant.outlined"
    >
      <span v-if="!compact">Next</span>
    </Button>
  </div>
</template>
