<script setup lang="ts">
type EnumData = App.Base.Data.EnumData;

import type { Selectable } from '@/components/selectBox/selectBox';

import CenteredContent from '@/components/content/CenteredContent.vue';
import StudentCreateForm from '@/components/students/StudentCreateForm.vue';

const props = defineProps<{
  returnUrl?: string;
  defaultSchool: App.Configuration.Data.SchoolData;
  grades: EnumData[];
}>();

const form = useForm({
  method: 'POST',
  url: route('students.store'),
  fields: {
    first_name: '',
    last_name: '',
    middle_name: '',
    preferred_name: '',
    grade: null as Selectable<number> | null,
    dob: null,
    school_id: buildSelectableSchool(props.defaultSchool) as Selectable<number> | null
  },
  transform: (fields) => ({
    ...fields,
    grade: fields.grade?.value,
    school_id: fields.school_id?.value
  })
});
</script>


					<script lang="ts">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <CenteredContent>
    <StudentCreateForm :form="form" :grades="grades" :returnUrl="returnUrl" />
  </CenteredContent>
</template>
