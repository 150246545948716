import type { Chart, FontSpec, TooltipModel } from 'chart.js';

interface TooltipOptions {
  anchorPosition: 'top' | 'bottom';
}

export function useChartTooltip({ anchorPosition }: TooltipOptions) {
  function getOrCreateTooltip(chart: Chart) {
    let tooltipEl = chart.canvas.parentNode?.querySelector('div');

    if (!tooltipEl) {
      tooltipEl = document.createElement('div');
      tooltipEl.style.background = 'rgba(0, 0, 0, 0.7)';
      tooltipEl.style.borderRadius = '3px';
      tooltipEl.style.color = 'white';
      tooltipEl.style.opacity = '1';
      tooltipEl.style.pointerEvents = 'none';
      tooltipEl.style.position = 'absolute';
      tooltipEl.style.transform = 'translate(-50%, 0)';
      tooltipEl.style.transition = 'all 150ms ease';
      tooltipEl.style.whiteSpace = 'nowrap';

      // Create the arrow element
      const arrowEl = document.createElement('div');
      arrowEl.style.position = 'absolute';
      arrowEl.style.width = '0';
      arrowEl.style.height = '0';
      arrowEl.style.borderLeft = '5px solid transparent';
      arrowEl.style.borderRight = '5px solid transparent';
      arrowEl.style.left = '50%';
      arrowEl.style.transform = 'translateX(-50%)';
      tooltipEl.appendChild(arrowEl);

      const table = document.createElement('table');
      table.style.margin = '0px';

      tooltipEl.appendChild(table);
      chart.canvas.parentNode?.appendChild(tooltipEl);
    }

    return tooltipEl;
  }

  function externalTooltipHandler(context: {
    chart: Chart;
    tooltip: TooltipModel<'doughnut' | 'bar'>;
  }) {
    const { chart, tooltip } = context;
    const tooltipEl = getOrCreateTooltip(chart);

    // Hide if no tooltip
    if (tooltip.opacity === 0) {
      tooltipEl.style.opacity = '0';
      return;
    }

    // Set Text
    if (tooltip.body) {
      const titleLines = tooltip.title || [];
      const bodyLines = tooltip.body.map(
        (b: TooltipModel<'doughnut' | 'bar'>['body'][number]) => b.lines
      );

      const tableHead = document.createElement('thead');

      titleLines.forEach((title: string) => {
        const tr = document.createElement('tr');

        const th = document.createElement('th');
        const text = document.createTextNode(title);

        th.style.textAlign = 'left';

        th.appendChild(text);
        tr.appendChild(th);
        tableHead.appendChild(tr);
      });

      const tableBody = document.createElement('tbody');
      bodyLines.forEach((body: string[]) => {
        const span = document.createElement('span');
        span.style.borderWidth = '1px';
        span.style.marginRight = '10px';
        span.style.height = '10px';
        span.style.width = '10px';
        span.style.display = 'inline-block';

        const tr = document.createElement('tr');
        tr.style.backgroundColor = 'inherit';

        const td = document.createElement('td');

        const text = document.createTextNode(body.join(', '));

        td.appendChild(span);
        td.appendChild(text);
        tr.appendChild(td);
        tableBody.appendChild(tr);
      });

      const tableRoot = tooltipEl.querySelector('table');

      // Remove old children
      if (tableRoot) {
        while (tableRoot.firstChild) {
          tableRoot.firstChild.remove();
        }

        // Add new children
        tableRoot.appendChild(tableHead);
        tableRoot.appendChild(tableBody);
      }
    }

    const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

    tooltipEl.style.opacity = '1';
    tooltipEl.style.left = `${positionX + tooltip.caretX}px`;

    if (anchorPosition === 'top') {
      tooltipEl.style.top = `${positionY + tooltip.caretY - tooltipEl.offsetHeight - 10}px`;

      const arrowEl = tooltipEl.querySelector('div') as HTMLElement;
      arrowEl.style.borderTop = '5px solid rgba(0, 0, 0, 0.7)';
      arrowEl.style.borderBottom = 'none';
      arrowEl.style.top = '100%';
    } else {
      tooltipEl.style.top = `${positionY + tooltip.caretY + 10}px`;

      const arrowEl = tooltipEl.querySelector('div') as HTMLElement;
      arrowEl.style.borderTop = 'none';
      arrowEl.style.borderBottom = '5px solid rgba(0, 0, 0, 0.7)';
      arrowEl.style.top = '-5px';
    }

    tooltipEl.style.fontSize = `${(tooltip.options.bodyFont as FontSpec).size}px`;
    tooltipEl.style.fontFamily = (tooltip.options.bodyFont as FontSpec).family;
    tooltipEl.style.padding = `${tooltip.options.padding}px`;
  }

  return { getOrCreateTooltip, externalTooltipHandler };
}
