<script setup lang="ts">
import { Edge, Node, Panel, Position, useVueFlow } from '@vue-flow/core';
import { VueFlow } from '@vue-flow/core';
import ArrowLeftIcon from '@/icons/line/arrow-left.svg';
import IconExclamationCircle from '@/icons/line/exclamation-circle.svg';
import Badge from '@/components/badge/Badge.vue';
import IconButton from '@/components/button/IconButton.vue';
import OutboundMessageNode from '@/components/automated-campaigns/nodes/OutboundMessageNode.vue';
import ActionNode from '@/components/automated-campaigns/nodes/ActionNode.vue';
import InboundMessageNode from '@/components/automated-campaigns/nodes/InboundMessageNode.vue';
import RootOutboundMessageNode from '@/components/automated-campaigns/nodes/RootOutboundMessageNode.vue';
import AutomatedOutboundMessageData = App.Sms.Data.AutomatedCampaigns.AutomatedOutboundMessageData;
import AutomatedNodeData = App.Sms.Data.AutomatedCampaigns.NodeTree.AutomatedNodeData;
import dagre from '@dagrejs/dagre';
import EditOutboundMessagePanel from '@/components/automated-campaigns/nodePanels/EditOutboundMessagePanel.vue';
import AutomatedNodeType = App.Sms.Enums.AutomatedNodeType;
import EditInboundMessagePanel from '@/components/automated-campaigns/nodePanels/EditInboundMessagePanel.vue';
import AutomatedInboundMessageData = App.Sms.Data.AutomatedCampaigns.AutomatedInboundMessageData;
import AutomatedActionData = App.Sms.Data.AutomatedCampaigns.AutomatedActionData;
import EditActionPanel from '@/components/automated-campaigns/nodePanels/EditActionPanel.vue';
import pluralize from 'pluralize';
import { sortBy } from 'lodash';
import { campaignNodeEditorKey } from '@/utils/keys';

export type NodeData = AutomatedNodeData['data'];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type CustomNode = Node<NodeData, any, AutomatedNodeType>;

const nodeTypes: Record<AutomatedNodeType, Component> = {
  root: markRaw(RootOutboundMessageNode),
  inbound: markRaw(InboundMessageNode),
  action: markRaw(ActionNode),
  outbound: markRaw(OutboundMessageNode)
};

const props = defineProps<{
  campaign: App.Sms.Data.AutomatedCampaigns.AutomatedCampaignData;
  nodes: CustomNode[];
  edges: Edge[];
}>();

provide(campaignNodeEditorKey, props.campaign);

const { fitView, onNodesInitialized, getSelectedNodes, findNode, addSelectedNodes } = useVueFlow();

const rootNode = computed(() => props.nodes.find((node) => node.type === 'root'));
const rootNodeData = computed(
  () => rootNode.value?.data
) as ComputedRef<App.Sms.Data.AutomatedCampaigns.AutomatedOutboundMessageData>;

const layout = computed(() => {
  const graph = new dagre.graphlib.Graph();

  graph.setGraph({});
  graph.setDefaultEdgeLabel(() => ({}));

  props.nodes.forEach((node) => {
    const height = {
      inbound: 100,
      outbound: 100,
      action: 80,
      root: 140
    }[node.data?.nodeType ?? 'outbound'];

    graph.setNode(node.id, { width: 320, height });
  });
  props.edges.forEach((edge) => graph.setEdge(edge.source, edge.target));

  dagre.layout(graph);

  return {
    nodes: props.nodes.map((node) => {
      const nodeWithPosition = graph.node(node.id);
      return {
        ...node,
        data: {
          ...node.data,
          toolbarVisible: false,
          toolbarPosition: Position.Bottom
        },
        position: {
          x: nodeWithPosition.x - nodeWithPosition.width / 2,
          y: nodeWithPosition.y - nodeWithPosition.height / 2
        }
      };
    }),
    edges: props.edges
  };
});

const nodesWithValidationErrors = computed(() => {
  return props.nodes.filter((node) => !node.data?.is_valid_node);
});
const nodeValidationMessage = computed(() => {
  return `${nodesWithValidationErrors.value.length} validation ${pluralize(
    'error',
    nodesWithValidationErrors.value.length
  )}`;
});

function handleValidationBadgeClick() {
  const node = nodesWithValidationErrors.value[0];
  if (!node) {
    return;
  }

  fitView({
    nodes: [node.id],
    duration: 1000,
    padding: 1
  });
}

watch(
  () => props.nodes,
  (updated, prev) => {
    if (updated.length && updated.length > prev.length) {
      openLatestNodePanel();
    }
  }
);

function openNodePanel(nodeId: string) {
  const node = findNode(nodeId);
  if (node) {
    addSelectedNodes([node]);
  }
}

function openLatestNodePanel() {
  nextTick(() => {
    const nodes = props.nodes as unknown as CustomNode[];
    const sortedNodes = sortBy(nodes, (node) => node.data?.created_at);
    const latestNode = sortedNodes[sortedNodes.length - 1];

    if (!latestNode) {
      return;
    }

    openNodePanel(latestNode.id);
  });
}

onNodesInitialized(() => fitView({ maxZoom: 1.5, minZoom: 0.8 }));

const selectedNodeData = computed<NodeData | undefined>(() => getSelectedNodes.value[0]?.data);

const selectedOutboundNode = computed<AutomatedOutboundMessageData | undefined>(() => {
  return ['outbound', 'root'].includes(selectedNodeData.value?.nodeType ?? '')
    ? (selectedNodeData.value as AutomatedOutboundMessageData)
    : undefined;
});

const selectedActionNode = computed<AutomatedActionData | undefined>(() => {
  return selectedNodeData.value?.nodeType === 'action'
    ? (selectedNodeData.value as AutomatedActionData)
    : undefined;
});

const selectedInboundNode = computed<AutomatedInboundMessageData | undefined>(() => {
  return selectedNodeData.value?.nodeType === 'inbound'
    ? (selectedNodeData.value as AutomatedInboundMessageData)
    : undefined;
});
</script>

<template>
  <VueFlow
    :multiSelectionKeyCode="null"
    :nodes="layout.nodes"
    :edges="layout.edges"
    snapToGrid
    :nodeTypes
    :nodesConnectable="false"
    :nodesDraggable="false"
  >
    <Panel
      position="top-left"
      class="m-4 flex max-w-fit items-center justify-between gap-16 rounded-lg border border-slate-200 bg-white px-4 py-2.5 shadow-sm"
    >
      <div class="flex items-center gap-3">
        <IconButton
          :href="route('sms.campaigns.show', { campaign: campaign.id })"
          :icon="ArrowLeftIcon"
          ariaLabel="Back to campaign"
          variant="soft"
        />
        <h1 v-if="rootNodeData" class="text-base font-bold leading-6 text-slate-800">
          {{ rootNodeData.name }}
        </h1>
      </div>

      <div class="flex gap-1">
        <Badge
          as="button"
          variant="soft"
          :label="nodeValidationMessage"
          color="warning"
          :iconLeft="IconExclamationCircle"
          @click="handleValidationBadgeClick"
        />
      </div>
    </Panel>

    <Transition
      appear
      enterActiveClass="transition ease-in-out duration-300"
      enterFromClass="translate-x-full opacity-0"
      enterToClass="translate-x-0"
      leaveActiveClass="transform transition ease-in-out duration-150"
      leaveFromClass="translate-x-0"
      leaveToClass="translate-x-full opacity-0"
    >
      <div>
        <EditOutboundMessagePanel
          v-if="selectedOutboundNode"
          :key="selectedNodeData?.id"
          :node="selectedOutboundNode"
          :isPaused="campaign.paused_at"
        />
        <EditInboundMessagePanel
          v-if="selectedInboundNode"
          :key="selectedNodeData?.id"
          :node="selectedInboundNode"
          :isPaused="campaign.paused_at"
        />
        <EditActionPanel
          v-if="selectedActionNode"
          :key="selectedNodeData?.id"
          :automatedAction="selectedActionNode"
          :isPaused="campaign.paused_at"
        />
      </div>
    </Transition>
  </VueFlow>
</template>
