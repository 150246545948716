import { debounce } from 'lodash';

import { search } from '@/api/InstituteApi';

import { buildSelectableInstitute } from '@/utils/buildSelectable';

import { Selectable } from '@/components/selectBox/selectBox';

export function useInstitutesSearch(searchFilters?: Record<string, unknown>) {
  const searchQuery = ref('');
  const isLoading = ref(false);
  const instituteOptions = ref<Selectable<number>[]>([]);

  const searchInstitutes = debounce(async (query: string) => {
    const response = await search({
      filters: {
        ...searchFilters,
        search: query
      }
    });
    instituteOptions.value = response.map((institute) =>
      buildSelectableInstitute(institute)
    ) as Selectable<number>[];

    isLoading.value = false;
  }, 300);

  function onQueryChange(query: string): void {
    isLoading.value = true;
    searchQuery.value = query;

    searchInstitutes(query);
  }

  return {
    instituteOptions,
    isLoading,
    onQueryChange
  };
}
