<script setup lang="ts">
import { Draggable } from 'vue-dndrop';
import Badge from '@/components/badge/Badge.vue';
import IconButton from '@/components/button/IconButton.vue';
import CheckboxInput, { CheckedValue } from '@/components/checkboxInput/CheckboxInput.vue';
import { DualListboxOption as DualListboxOptionType } from '@/components/selectBox/selectBox';
import { BadgeColor, BadgeShape, BadgeSize, BadgeVariant } from '@/hooks/useBadgeClasses';
import { ButtonVariant } from '@/hooks/useButtonClasses';
import { flattenNestedList } from '@/utils/lists';

import AngleRightIcon from '@/icons/line/angle-right.svg';
import { DualListboxContext } from './DualListbox.vue';

const props = defineProps<{
  valuesInList: Set<string>;
  checkedValues: Set<string>;
  option: DualListboxOptionType;
  isAvailableList: boolean;
  expandedOptions: Set<string>;
}>();

const emit = defineEmits<{
  toggleExpand: [{ expanded: boolean; values: string[] }];
}>();

const expanded = computed(() => props.expandedOptions.has(props.option.value));

const isGroupOption = computed(() => !!props.option.children?.length);

const nestedOptionChildrenValues = computed(() => {
  return flattenNestedList(props.option.children ?? []).map(
    (option: DualListboxOptionType) => option.value
  );
});

const dualListboxContext = inject<DualListboxContext>('dual-listbox');

function determineState(
  option: DualListboxOptionType,
  valuesToCheckAgainst: Set<string>
): CheckedValue {
  if (option.children?.length) {
    const childOptions = flattenNestedList(option.children);

    const checkedChildrenCount =
      childOptions.filter((childOption: DualListboxOptionType) => {
        return !!determineState(childOption, valuesToCheckAgainst);
      }).length ?? 0;

    if (checkedChildrenCount === 0) {
      return false;
    }
    if (checkedChildrenCount === childOptions.length) {
      return true;
    }

    return 'indeterminate';
  }

  return valuesToCheckAgainst.has(option.value);
}

const isChecked = computed<CheckedValue>(() => !props.isAvailableList) as ComputedRef<CheckedValue>;
const isSelected = computed<CheckedValue>(() => determineState(props.option, props.valuesInList));

const shouldDisplayOption = computed<boolean>(() => {
  if (isSelected.value === true) {
    return true;
  }
  if (isSelected.value === false) {
    return false;
  }

  return isSelected.value === 'indeterminate';
});

function handleToggle(checked: boolean) {
  if (!dualListboxContext) {
    return;
  }

  const updateMethod = checked
    ? dualListboxContext.handleMoveCheckedToSelected
    : dualListboxContext.handleMoveCheckedToAvailable;

  if (isGroupOption.value) {
    updateMethod(new Set(nestedOptionChildrenValues.value));

    return;
  }

  updateMethod(new Set([props.option.value]));
}

function handleExpandClick() {
  emit('toggleExpand', {
    expanded: !expanded.value,
    values: [props.option.value, ...nestedOptionChildrenValues.value]
  });
}
</script>

<template>
  <Draggable v-if="shouldDisplayOption">
    <button
      class="flex w-full items-center gap-2 rounded px-2 text-left leading-5 hover:bg-slate-50"
      type="button"
      @click="handleToggle(!isChecked)"
    >
      <IconButton
        :icon="AngleRightIcon"
        :variant="ButtonVariant.invisible"
        ariaLabel="Expand group"
        class="h-8 w-8"
        :class="{
          'rotate-90': expanded,
          invisible: !isGroupOption
        }"
        @click.stop="handleExpandClick"
      />
      <div class="flex flex-1 items-center gap-2">
        <CheckboxInput :checked="isChecked" @update:checked="handleToggle" />
        <p
          class="py-1 text-base leading-none"
          :class="{
            'text-slate-600': isGroupOption
          }"
        >
          {{ option.label }}
        </p>
      </div>
      <Badge
        v-if="isGroupOption"
        :variant="BadgeVariant.soft"
        :color="BadgeColor.primary"
        :size="BadgeSize.sm"
        :shape="BadgeShape.pill"
        :label="option.children?.length ?? 0"
      />
    </button>

    <div v-if="expanded && option.children?.length" class="pl-8">
      <DualListboxOption
        v-for="childOption in option.children"
        :key="childOption.value"
        :option="childOption"
        :valuesInList="valuesInList"
        :checkedValues="checkedValues"
        :isAvailableList="isAvailableList"
        :expandedOptions="expandedOptions"
        @toggleExpand="$emit('toggleExpand', $event)"
      />
    </div>
  </Draggable>
</template>
