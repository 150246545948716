<script setup lang="ts">
type StudentParentData = App.Students.Data.StudentParentData;

import Button from '@/components/button/Button.vue';
import CenteredContent from '@/components/content/CenteredContent.vue';
import Divider from '@/components/divider/Divider.vue';
import Form from '@/components/form/Form.vue';
import FormFooter from '@/components/formFooter/FormFooter.vue';
import FormHeader from '@/components/formHeader/FormHeader.vue';
import Fieldset from '@/components/fieldset/Fieldset.vue';
import FormTextInput from '@/components/formTextInput/FormTextInput.vue';
import FormMultiStudentInput from '@/components/formComboboxInput/FormMultiStudentInput.vue';
import DangerDialog from '@/components/dialog/DangerDialog.vue';
import { ButtonColor, ButtonVariant } from '@/hooks/useButtonClasses';
import Envelope from '@/icons/line/envelope.svg';
import Phone from '@/icons/line/phone.svg';

import type { Selectable } from '@/components/selectBox/selectBox';

const props = defineProps<{
  parent: StudentParentData;
  returnUrl?: string;
}>();

const isCancelDialogOpen = ref(false);

const form = useForm({
  method: 'PUT',
  url: route('parents.update', { parent: props.parent.id }),
  updateInitials: true,
  fields: {
    first_name: props.parent.first_name,
    last_name: props.parent.last_name,
    email: props.parent.email,
    cell_phone: props.parent.cell_phone,
    student_ids:
      props.parent.students?.map((student) => buildSelectableStudent(student)) ||
      ([] as Selectable<number>[])
  },
  transform: (fields) => ({
    ...fields,
    student_ids: fields.student_ids?.map((student) => student.value) ?? null
  })
});

function handleToggleCancelDialog() {
  if (form.isDirty) {
    isCancelDialogOpen.value = !isCancelDialogOpen.value;
    return;
  }
  handleRedirect();
}

function handleRedirect() {
  router.navigate({
    url: props.returnUrl || route('parents.index')
  });
}
</script>


					<script lang="ts">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <CenteredContent>
    <Form @submit.prevent="form.submit">
      <template #header>
        <FormHeader
          canClose
          title="Edit a Parent/Guardian"
          description="Use the form below to edit the parent/guardian."
          @onClose="handleToggleCancelDialog"
        />
      </template>
      <Fieldset title="Parent information">
        <FormTextInput
          :error="form.errors.first_name"
          :modelValue="form.fields.first_name"
          label="First name"
          name="first_name"
          @update:modelValue="(value) => (form.fields.first_name = value as string)"
        />
        <FormTextInput
          :error="form.errors.last_name"
          :modelValue="form.fields.last_name"
          label="Last name"
          name="last_name"
          @update:modelValue="(value) => (form.fields.last_name = value as string)"
        />

        <FormTextInput
          :error="form.errors.email"
          :iconLeft="Envelope"
          :modelValue="form.fields.email"
          label="Email"
          name="email"
          @update:modelValue="(value) => (form.fields.email = value as string)"
        />
        <FormTextInput
          :error="form.errors.cell_phone"
          :iconLeft="Phone"
          :modelValue="form.fields.cell_phone"
          label="Phone"
          name="cell_phone"
          @update:modelValue="(value) => (form.fields.cell_phone = value as string)"
        />
      </Fieldset>
      <Divider />
      <Fieldset title="Students">
        <FormMultiStudentInput
          :error="form.errors.student_ids as unknown as string"
          :modelValue="form.fields.student_ids as Selectable<number>[]"
          label="Students"
          name="student_ids"
          @update:modelValue="(value) => (form.fields.student_ids = value)"
        />
      </Fieldset>
      <template #footer>
        <FormFooter>
          <Button
            type="submit"
            :color="ButtonColor.primary"
            :isDisabled="form.processing || !form.isDirty"
            :isLoading="form.processing"
            :variant="ButtonVariant.solid"
            >Confirm</Button
          >
          <Button
            @click="handleToggleCancelDialog"
            :color="ButtonColor.slate"
            :variant="ButtonVariant.outlined"
            type="button"
            >Back to parents</Button
          >
        </FormFooter>
      </template>
    </Form>

    <DangerDialog
      :title="`Cancel editing parent/guardian`"
      :message="`Are you sure you want to cancel editing this parent/guardian? All changes will be lost.`"
      :isOpen="isCancelDialogOpen"
      cancelButtonLabel="Stay on this page"
      confirmButtonLabel="Go to back to previous page"
      @onCancel="handleToggleCancelDialog"
      @onClose="handleToggleCancelDialog"
      @onConfirm="handleRedirect"
    />
  </CenteredContent>
</template>
