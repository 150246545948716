<script setup lang="ts">
import type { AppliedFilters } from '@/components/distiller/types';
import type { HybridlyFormData } from '@/@types/global';

import { ButtonVariant, ButtonSize, ButtonColor } from '@/hooks/useButtonClasses';

import Alert from '@/components/alert/Alert.vue';
import Button from '@/components/button/Button.vue';
import Distiller from '@/components/distiller/Distiller.vue';
import FilterProvider from '@/components/distiller/FilterProvider.vue';
import FilterTemplates from '@/components/filters/filterTemplate/FilterTemplates.vue';

import ExclamationTriangle from '@/icons/line/exclamation-triangle.svg';

const props = defineProps<{
  customReport: App.Reports.Data.CustomReportData;
  form: HybridlyFormData<{ filters: AppliedFilters }>;
}>();

const appliedFiltersCount = computed(() =>
  props.form.fields.filters ? Object.keys(props.form.fields.filters).length : 0
);

const hasAppliedFilters = computed(() => appliedFiltersCount.value > 0);

function handleFilterTemplateChange(filterTemplate: App.Search.Data.FilterTemplateData | null) {
  if (!filterTemplate) {
    return;
  }

  props.form.fields.filters = filterTemplate.filters as unknown as AppliedFilters;
}

function handleClear() {
  props.form.fields.filters = {};
}
</script>

<template>
  <FilterProvider v-model="form.fields.filters">
    <div class="flex items-center justify-between">
      <h4 class="text-base font-semibold text-slate-900">
        {{ customReport.distiller?.name?.label }} record filters
      </h4>

      <div class="flex items-center gap-4 divide-x divide-gray-100">
        <FilterTemplates
          v-if="customReport.distiller"
          :title="customReport.distiller.name.label"
          :distiller="customReport.distiller"
          :appliedFilters="form.fields.filters"
          @update:filterTemplate="handleFilterTemplateChange"
        />

        <div v-if="hasAppliedFilters" class="border-l border-gray-100 pl-4">
          <Button
            :variant="ButtonVariant.invisible"
            :size="ButtonSize.sm"
            :color="ButtonColor.slate"
            @click="handleClear"
            >Clear all filters</Button
          >
        </div>
      </div>
    </div>

    <Distiller
      v-if="customReport.distiller"
      :distiller="customReport.distiller"
      showDistillerTypeAddButton
      title="Student Filters"
    />
  </FilterProvider>
  <Alert
    v-if="form.errors.filters"
    :icon="ExclamationTriangle"
    :description="formatErrors(form.errors.filters)"
    title="Filter errors"
    color="danger"
    variant="soft"
  />
</template>
