<script setup lang="ts">
import type { AppliedFilters } from '@/components/distiller/types';
import type { HybridlyFormData } from '@/@types/global';
import type { Selectable } from '@/components/selectBox/selectBox';

import ReportFiltersForm from '@/domains/reports/components/form/ReportFiltersForm.vue';

import AdviserInteractionsReportFiltersForm from './AdviserInteractionsReportFiltersForm.vue';
import SmsEngagementReportFiltersForm from './SmsEngagementReportFiltersForm.vue';
import SmsCampaignSummaryReportFiltersForm from './SmsCampaignSummaryReportFiltersForm.vue';
import SmsCampaignMessagesReportFiltersForm from './SmsCampaignMessagesReportFiltersForm.vue';
import SchoolKpiReportFiltersForm from './SchoolKpiReportFiltersForm.vue';
import ProgramKpiReportFiltersForm from './ProgramKpiReportFiltersForm.vue';
import GlobalFiltersForm from './GlobalFiltersForm.vue';

const props = defineProps<{
  campaignOptions: Selectable<string>[];
  customReport: App.Reports.Data.CustomReportData;
  form: HybridlyFormData<{ filters: AppliedFilters }>;
  globalDistiller: App.Search.Data.DistillerData;
  grades: App.Base.Data.EnumData[];
  smsGroups: App.Base.Data.EnumData[];
  shouldShowGlobalFilters: boolean;
}>();

const formComponent = computed(() => {
  switch (props.customReport.id) {
    case 'sms-engagement-report':
      return SmsEngagementReportFiltersForm;
    case 'sms-campaign-summary-report':
      return SmsCampaignSummaryReportFiltersForm;
    case 'sms-campaign-messages-report':
      return SmsCampaignMessagesReportFiltersForm;
    case 'school-kpi-report':
      return SchoolKpiReportFiltersForm;
    case 'program-kpi-report':
      return ProgramKpiReportFiltersForm;
    case 'adviser-interactions-report':
      return AdviserInteractionsReportFiltersForm;

    default:
      return ReportFiltersForm;
  }
});

if (props.customReport.is_kpi_report && !props.form.fields.filters.student_grade) {
  props.form.fields.filters = {
    student_grade: null,
    program_id: null,
    school_ids: [],
    ...props.form.fields.filters
  };
}
</script>

<template>
  <component
    :is="formComponent"
    :campaignOptions="campaignOptions"
    :customReport="customReport"
    :form="form"
    :grades="grades"
    :smsGroups="smsGroups"
  />
  <GlobalFiltersForm
    v-if="formComponent == ReportFiltersForm && shouldShowGlobalFilters"
    :form="form"
    :globalDistiller="globalDistiller"
  />
</template>
