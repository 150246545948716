<script setup lang="ts">
import Alert from '@/components/alert/Alert.vue';
import CenteredContent from '@/components/content/CenteredContent.vue';
import EditReportTemplateLayout from '@/domains/reports/components/EditReportTemplateLayout.vue';
import Fieldset from '@/components/fieldset/Fieldset.vue';
import ReportColumnsForm from '@/domains/reports/components/form/ReportColumnsForm.vue';

import ExclamationTriangle from '@/icons/line/exclamation-triangle.svg';

type ReportTemplateData = App.Reports.Data.ReportTemplateData;

defineProps<{
  template: ReportTemplateData;
}>();
</script>


					<script lang="ts">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <EditReportTemplateLayout :template="template">
    <template #default="{ form }">
      <CenteredContent :padded="false" size="md">
        <Fieldset title="Report columns">
          <ReportColumnsForm :customReport="template.report_type" :form="form" />
          <Alert
            v-if="form.errors.columns"
            :icon="ExclamationTriangle"
            :description="form.errors.columns"
            title="Filter errors"
            color="danger"
            variant="soft"
          />
        </Fieldset>
      </CenteredContent>
    </template>
  </EditReportTemplateLayout>
</template>
