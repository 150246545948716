<script setup lang="tsx">
import type { AppliedFilters } from '@/components/distiller/types';
import type { CustomCells, HybridlyRow } from '@/components/table/Table';

import { useHybridlyTable } from '@/hooks/useHybridlyTable';
import { useTableRowClick } from '@/hooks/useTableRowClick';
import { statusColors } from '@/domains/reports/reportStatusColors';

import EmptyState from '@/components/emptyState/EmptyState.vue';
import IndexPageLayout from '@/components/pageLayout/IndexPageLayout.vue';
import ReportResultDownloadCell from '@/domains/reports/components/tables/ReportResultDownloadCell.vue';
import TableBadgesCell from '@/components/table/TableBadgesCell.vue';
import TableDateTimeCell from '@/components/table/TableDateTimeCell.vue';
import TableEnumCell from '@/components/table/TableEnumCell.vue';

import UserCircle from '@/icons/line/user-circle.svg';

type ReportResultData = HybridlyRow<App.Reports.Data.ReportResultData>;

const props = defineProps<{
  appliedFilters: AppliedFilters;
  distiller: App.Search.Data.DistillerData;
  results: Table<App.Reports.Data.ReportResultData>;
}>();

const { table, tableSelectionState } = useHybridlyTable<App.Reports.Data.ReportResultData>(
  props,
  'results'
);
const { handleRowClick } = useTableRowClick();

const customCells = {
  report: ({ row }) => <TableEnumCell enumData={row.value('report')} />,
  download_url: ({ row }) => (
    <ReportResultDownloadCell
      downloadUrl={row.value('download_url')}
      fileName={row.value('file_name')}
    />
  ),
  status: ({ row }) => (
    <TableBadgesCell
      badgeColor={statusColors[row.value('status').value]}
      badgeSize="sm"
      badgeVariant="soft"
      items={[row.value('status').label]}
    />
  ),
  user: ({ row }) => (
    <TableBadgesCell badgeSize="sm" itemIcon={UserCircle} items={[row.value('user').name]} />
  ),
  started_at: ({ row }) => <TableDateTimeCell date={row.value('started_at')} />,
  finished_at: ({ row }) => <TableDateTimeCell date={row.value('finished_at')} />
} as CustomCells<App.Reports.Data.ReportResultData>;

function handleReportResultRowClick(event: MouseEvent, row: ReportResultData) {
  const url = route('report-results.show', {
    reportResult: row.value('uuid')
  });
  handleRowClick(url, event);
}
</script>


					<script lang="tsx">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <IndexPageLayout
    title="Report Results"
    :appliedFilters="appliedFilters"
    :clickableRows="true"
    :customCells="customCells"
    :distiller="distiller"
    :selectionsEnabled="false"
    :table="table"
    :tableSelectionState="tableSelectionState"
    @clickRow="($event, row) => handleReportResultRowClick($event, row as ReportResultData)"
  >
    <template #emptyState>
      <EmptyState
        title="No report results found"
        description="Generate your first report via the Reports page below."
        actionLabel="Reports"
        :actionHref="route('report-templates.index')"
      />
    </template>
  </IndexPageLayout>
</template>
