<script setup lang="tsx" generic="T extends Record<string, unknown>">
import type { TableColumn } from '@/components/table/Table';

import PaddedCellContent from '@/components/table/PaddedCellContent.vue';

const props = defineProps<{
  column: TableColumn<T>;
}>();

const headerCellContent = computed(() => {
  if (props.column.headerCellRender) {
    return props.column.headerCellRender({ column: props.column });
  }

  return <PaddedCellContent>{props.column.label || ''}</PaddedCellContent>;
});
</script>

<template>
  <component :is="headerCellContent" class="whitespace-nowrap" />
</template>
