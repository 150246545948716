<script setup lang="ts">
type UpcomingMessageData = App.Sms.Data.UpcomingMessageData;

import { ButtonColor, ButtonVariant } from '@/hooks/useButtonClasses';

import Button from '@/components/button/Button.vue';
import Pagination from '@/components/pagination/Pagination.vue';
import ScheduledMessagesEmptyState from '@/components/emptyState/ScheduledMessagesEmptyState.vue';
import SmsDropdownNavigation from '@/domains/sms/components/SmsDropdownNavigation.vue';
import UpcomingMessageCard from '@/domains/sms/components/UpcomingMessageCard.vue';
import PageHeader from '@/components/pageHeader/PageHeader.vue';

import EditIcon from '@/icons/line/edit.svg';

defineProps<{
  messages: Paginator<UpcomingMessageData>;
}>();
</script>


					<script lang="ts">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <div class="divide-y-slate-200 flex h-full flex-col divide-y">
    <PageHeader :padded="true">
      <template #title>
        <SmsDropdownNavigation />
      </template>

      <template #rightButtons>
        <Button
          :color="ButtonColor.primary"
          :href="route('sms.compose')"
          :iconLeft="EditIcon"
          :variant="ButtonVariant.solid"
          class="mr-4"
        >
          Compose
        </Button>
      </template>
    </PageHeader>

    <div
      class="flex max-h-full min-h-0 flex-1 flex-col gap-4 overflow-y-auto px-4 py-4 md:py-8 lg:px-24"
      scroll-region
    >
      <UpcomingMessageCard v-for="message in messages.data" :key="message.id" :message="message" />

      <ScheduledMessagesEmptyState
        v-if="messages.data.length === 0"
        title="Scheduled Messages"
        :description="`You have ${messages.meta.total || 'no'} scheduled messages`"
      />
    </div>

    <Pagination :maxLinks="2" compact :pagination="messages" class="overflow-x-scroll border-t" />
  </div>
</template>
