<script setup lang="ts">
import Dialog from '@/components/dialog/Dialog.vue';
import { AppliedFilters } from '@/components/distiller/types';
import FormRows from '@/components/formRows/FormRows.vue';
import { Selectable } from '@/components/selectBox/selectBox';
import FormRadioInput from '@/components/formRadioInput/FormRadioInput.vue';
import FormComboboxInput from '@/components/formComboboxInput/FormComboboxInput.vue';
import FormTextInput from '@/components/formTextInput/FormTextInput.vue';

const emit = defineEmits(['onClose', 'onCancel', 'onSuccess']);

const props = defineProps<{
  isOpen: boolean;
  subject: string | null;
  filters: AppliedFilters;
  templates: Selectable<number>[];
}>();

const form = useForm<{
  creatingNewTemplate: string;
  selectedTemplate: Selectable<number> | null;
  name: string;
  subject: string | null;
  filters: AppliedFilters;
}>({
  method: 'POST',
  url: route('filter-templates.store'),
  fields: {
    creatingNewTemplate: 'true',
    selectedTemplate: null,
    name: '',
    subject: props.subject,
    filters: props.filters
  },
  transform: (fields) => ({
    ...fields,
    filters: props.filters
  }),
  hooks: {
    success() {
      emit('onSuccess');
    }
  }
});

watch(
  () => form.fields.selectedTemplate,
  () => {
    if (form.fields.selectedTemplate) {
      form.fields.name = form.fields.selectedTemplate.label;
    }
  }
);

function handleCreatingNewTemplateChange(value: string) {
  form.fields.creatingNewTemplate = value;
  if (value === 'true') {
    form.fields.name = '';
  }
}

function handleOnConfirmClick() {
  if (form.fields.creatingNewTemplate === 'false' && form.fields.selectedTemplate) {
    form.submitWith({
      method: 'PUT',
      url: route('filter-templates.update', { filterTemplate: form.fields.selectedTemplate.value })
    });
    return;
  }

  form.submit();
}

function handleOnCloseClick() {
  form.reset();
  emit('onClose');
}

function handleOnCancelClick() {
  form.reset();
  emit('onCancel');
}
</script>

<template>
  <Dialog
    :isOpen="isOpen"
    title="Save filters as a template"
    :isLoading="form.processing"
    confirmButtonLabel="Save template"
    @onConfirm="handleOnConfirmClick"
    @onClose="handleOnCloseClick"
    @onCancel="handleOnCancelClick"
  >
    <FormRows>
      <FormRadioInput
        label="I want to..."
        name="isCreatingNewTemplate"
        ariaLabel="Choose if you are creating or updating a template"
        :value="form.fields.creatingNewTemplate"
        :radioInputs="[
          {
            label: 'Create a new template',
            value: 'true'
          },
          {
            label: 'Update an existing template',
            value: 'false'
          }
        ]"
        @update:modelValue="handleCreatingNewTemplateChange"
      />

      <FormComboboxInput
        v-if="form.fields.creatingNewTemplate === 'false'"
        v-model="form.fields.selectedTemplate"
        label="Choose a template to update"
        placeholder="Select a template to update..."
        :options="templates"
        class="w-full"
      />

      <FormTextInput
        type="text"
        name="name"
        label="Template name"
        :helperText="
          form.fields.creatingNewTemplate === 'false'
            ? 'You can rename the template here.'
            : undefined
        "
        :modelValue="form.fields.name"
        :error="form.errors.name"
        @update:modelValue="(value) => (form.fields.name = value)"
      />
    </FormRows>
  </Dialog>
</template>
