<script setup lang="ts">
import type { AppliedFilters } from '@/components/distiller/types';

import CreateCustomReport from './CreateCustomReport.vue';
import Fieldset from '@/components/fieldset/Fieldset.vue';
import SmsEngagementReportFiltersForm from '@/domains/reports/components/form/SmsEngagementReportFiltersForm.vue';
import StepContainer from '@/components/stepContainer/StepContainer.vue';
import StepFooter from '@/components/stepContainer/StepFooter.vue';

const props = defineProps<{
  studentGrades: App.Base.Data.EnumData[];
  customReport: App.Reports.Data.CustomReportData;
  globalDistiller: App.Search.Data.DistillerData;
  filters: AppliedFilters;
  columnFilters: AppliedFilters;
  reportTemplate?: App.Reports.Data.ReportTemplateData;
  schoolYearStartDate: string;
  smsGroups: App.Base.Data.EnumData[];
}>();

const nextForm = useForm({
  url: route('custom-reports.details.create', { customReport: props.customReport.id }),
  key: `custom-reports.filters.next.${props.customReport.id}`,
  fields: {
    filters: props.filters ?? {
      program_id: null,
      school_ids: [],
      student_grade: null,
      sms_group: null,
      start_date: props.schoolYearStartDate,
      end_date: null
    },
    column_filters: props.columnFilters,
    report_template_id: props.reportTemplate?.id ?? null
  }
});
</script>


					<script lang="ts">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <CreateCustomReport :currentStep="0" :customReport="customReport">
    <StepContainer title="Create New SMS Engagement Report">
      <div class="mx-auto w-full max-w-xl space-y-10">
        <Fieldset title="Report filters">
          <SmsEngagementReportFiltersForm
            :form="nextForm"
            :grades="studentGrades"
            :smsGroups="smsGroups"
          />
        </Fieldset>

        <StepFooter
          :isLoading="nextForm.processing"
          previousButtonLabel="Cancel"
          nextButtonLabel="Next"
          @next="nextForm.submit()"
          @previous="router.get(route('report-templates.index'))"
        />
      </div>
    </StepContainer>
  </CreateCustomReport>
</template>
