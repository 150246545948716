<script setup lang="ts">
import { Selectable } from '@/components/selectBox/selectBox';
import { useStudentGroupsSearch } from '@/hooks/search/useStudentGroupsSearch';
import ComboboxInput from '@/components/selectBox/combobox/ComboboxInput.vue';

const { studentGroupOptions, isLoading, onQueryChange } = useStudentGroupsSearch();

defineProps<{
  modelValue: Selectable<number> | null;
}>();

defineEmits(['update:modelValue']);
</script>

<template>
  <ComboboxInput
    :options="studentGroupOptions"
    :onFilter="onQueryChange"
    :isLoading="isLoading"
    :modelValue="modelValue"
    @update:modelValue="$emit('update:modelValue', $event)"
    @open="() => onQueryChange('')"
  />
</template>
