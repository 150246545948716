<script setup lang="ts">
import { ButtonColor, ButtonVariant } from '@/hooks/useButtonClasses';

import Button from '@/components/button/Button.vue';
import Hyperlink from '@/components/links/Hyperlink.vue';

const props = defineProps<{
  notificationId: string | number;
  id: string | number;
  message: string;
  title: string;
  link?: {
    text?: string | undefined;
    href: string;
    download?: boolean | undefined;
  } | null;
  action?: {
    text: string;
    data?: unknown;
  } | null;
}>();

const messageParts = computed(() => props.message.split('{{ link }}') ?? []);

function handleActionClick() {
  router.navigate({
    url: route('notifications.action.show', { databaseNotification: props.notificationId })
  });
}
</script>

<template>
  <p class="text-sm font-bold leading-6">{{ title }}</p>
  <p class="text-sm font-medium leading-5">
    <template v-if="link && messageParts.length === 2">
      {{ messageParts[0] }}
      <Hyperlink :href="link.href" :download="link?.download" @click.stop>
        {{ link.text }}
      </Hyperlink>
      {{ messageParts[1] }}
    </template>
    <template v-else>
      {{ message }}
    </template>
  </p>
  <div>
    <Button
      v-if="action?.text"
      class="mt-2"
      :color="ButtonColor.slate"
      :variant="ButtonVariant.soft"
      @click.prevent.stop="handleActionClick"
      >{{ action.text }}</Button
    >
  </div>
</template>
