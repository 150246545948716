<script setup lang="ts">
import type { ShowNotesProps } from '@/domains/students/views/ShowNotes.vue';

import { ButtonColor, ButtonSize, ButtonVariant } from '@/hooks/useButtonClasses';
import { useAuth } from '@/hooks/useAuth';

import Button from '@/components/button/Button.vue';
import Note from '@/components/notes/Note.vue';
import NoteCreateForm from '@/components/notes/NoteCreateForm.vue';
import Slideout from '@/components/slideout/Slideout.vue';

import Notes from '@/icons/line/notes.svg';
import Plus from '@/icons/line/plus.svg';

defineProps<ShowNotesProps>();

const { hasPermission } = useAuth();
const { show, closeLocally } = useDialog();

const isCreating = ref(false);

function toggleCreatingState() {
  isCreating.value = !isCreating.value;
}
</script>

<template>
  <Slideout title="Notes" :isOpen="show" @onClose="closeLocally" :icon="Notes">
    <template #actions>
      <Button
        v-if="hasPermission('create-note')"
        :color="ButtonColor.primary"
        :iconRight="Plus"
        :size="ButtonSize.sm"
        :variant="ButtonVariant.soft"
        @click="toggleCreatingState"
      >
        Create a new note
      </Button>
    </template>
    <div class="flex flex-col gap-2.5">
      <NoteCreateForm
        v-if="isCreating"
        :studentId="student.id"
        @onCancel="toggleCreatingState"
        @onSuccess="toggleCreatingState"
      />
      <TransitionGroup
        ariaLive="assertive"
        moveClass="transition-all duration-300"
        enterActiveClass="transition-all duration-300"
        leaveActiveClass="transition-all duration-300 absolute inset-x-0 top-0"
        enterFromClass="opacity-0"
        leaveToClass="opacity-0"
        tag="ul"
        class="flex flex-col gap-2.5"
      >
        <li v-for="note in notes" :key="note.id">
          <Note :note="note" />
        </li>
      </TransitionGroup>
    </div>
  </Slideout>
</template>
