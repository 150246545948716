<script setup lang="ts">
type EnumData = App.Base.Data.EnumData;
type AlertData = App.Configuration.Data.AlertData;

import { buildSelectableProgram, buildSelectableUser } from '@/utils/buildSelectable';

import CenteredContent from '@/components/content/CenteredContent.vue';
import AlertForm from '@/components/alerts/AlertForm.vue';

const props = defineProps<{
  alert: AlertData;
  types: EnumData[];
  returnUrl?: string;
}>();

const form = useForm({
  method: 'PUT',
  url: route('alerts.update', { alert: props.alert.id }),
  fields: {
    title: props.alert.title,
    type: props.alert.type,
    expiration_date: props.alert.expiration_date || '',
    content_json: props.alert.content_json || '',
    content_html: props.alert.content_html || '',
    program_ids: (props.alert.programs || []).map((program) => buildSelectableProgram(program)),
    user_ids: (props.alert.users || []).map((user) => buildSelectableUser(user))
  },
  transform: (fields) => ({
    ...fields,
    type: fields.type?.value,
    program_ids: fields.program_ids?.map((program) => program?.value) ?? [],
    user_ids: fields.user_ids?.map((user) => user?.value) ?? []
  })
});
</script>


					<script lang="ts">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <CenteredContent>
    <AlertForm :form="form" :types="types" isEditing :returnUrl="returnUrl" />
  </CenteredContent>
</template>
