<script setup lang="tsx">
import { AppliedFilters } from '@/components/distiller/types';
import type { CustomCells, HybridlyRow } from '@/components/table/Table';

import { ButtonColor, ButtonSize, ButtonVariant } from '@/hooks/useButtonClasses';
import { useFilters } from '@/hooks/useFilters';
import { useHybridlyTable } from '@/hooks/useHybridlyTable';
import { useTableRowClick } from '@/hooks/useTableRowClick';

import BulkRestoreButton from '@/components/table/BulkRestoreButton.vue';
import BulkTrashButton from '@/components/table/BulkTrashButton.vue';
import Button from '@/components/button/Button.vue';
import EmptyState from '@/components/emptyState/EmptyState.vue';
import IndexPageLayout from '@/components/pageLayout/IndexPageLayout.vue';
import TableBadgesCell from '@/components/table/TableBadgesCell.vue';
import TableDateCell from '@/components/table/TableDateCell.vue';
import TableDateTimeCell from '@/components/table/TableDateTimeCell.vue';
import TableDualTextCell from '@/components/table/TableDualTextCell.vue';
import TabNavDivider from '@/components/tabNav/TabNavDivider.vue';
import TabNavItem from '@/components/tabNav/TabNavItem.vue';

import Plus from '@/icons/line/plus.svg';
import UsersAlt from '@/icons/line/users-alt.svg';

type AutomatedCampaignRow = HybridlyRow<App.Sms.Data.AutomatedCampaigns.AutomatedCampaignData>;

const props = defineProps<{
  automatedCampaigns: Table<App.Sms.Data.AutomatedCampaigns.AutomatedCampaignData>;
  appliedFilters: AppliedFilters;
  distiller: App.Search.Data.DistillerData;
  trashActive: boolean;
  counts: {
    active_count: number;
    trashed_count: number;
  };
}>();

const { table, tableSelectionState } =
  useHybridlyTable<App.Sms.Data.AutomatedCampaigns.AutomatedCampaignData>(
    props,
    'automatedCampaigns'
  );
const { handleRowClick } = useTableRowClick();
const { encodeFilters } = useFilters();

const encodedFilters = computed(() => encodeFilters(props.appliedFilters));

const customCells = {
  name: ({ row, column }) => (
    <TableDualTextCell
      primaryText={row.value(column.key).name}
      secondaryText={`${row.value(column.key).message_flow_count} message flows`}
    />
  ),
  paused_at: ({ row, column }) => (
    <TableBadgesCell
      badgeColor={row.value(column.key) ? 'warning' : 'success'}
      badgesToDisplay={1}
      badgeSize="md"
      badgeVariant="soft"
      items={[row.value(column.key) ? 'Paused' : 'Activated']}
    />
  ),
  student_group: ({ row, column }) => (
    <TableBadgesCell badgesToDisplay={1} itemIcon={UsersAlt} items={[row.value(column.key).name]} />
  ),
  first_message_scheduled_date: ({ row, column }) => <TableDateCell date={row.value(column.key)} />,
  created_at: ({ row, column }) => <TableDateTimeCell date={row.value(column.key)} />
} as CustomCells<App.Sms.Data.AutomatedCampaigns.AutomatedCampaignData>;

function handleAutomatedCampaignRowClick(event: MouseEvent, row: AutomatedCampaignRow) {
  const url = route('sms.campaigns.show', {
    campaign: row.key
  });
  handleRowClick(url, event);
}
</script>


					<script lang="tsx">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <IndexPageLayout
    title="SMS Campaigns"
    :table="table"
    :appliedFilters="appliedFilters"
    :distiller="distiller"
    :customCells="customCells"
    :stickyEndColumns="1"
    :stickyStartColumns="2"
    :clickableRows="!trashActive"
    :tableSelectionState="tableSelectionState"
    @clickRow="($event, row) => handleAutomatedCampaignRowClick($event, row as AutomatedCampaignRow)"
  >
    <template #rightButtons>
      <template v-if="tableSelectionState.hasSelection">
        <BulkTrashButton
          v-if="!trashActive"
          recordType="SMS campaigns"
          :route="route('sms.campaigns.bulk-delete')"
          :tableSelectionState="tableSelectionState"
        />
        <BulkRestoreButton
          v-if="trashActive"
          recordType="SMS campaigns"
          :route="route('sms.campaigns.bulk-restore')"
          :tableSelectionState="tableSelectionState"
        />
      </template>

      <template v-else>
        <Button
          :color="ButtonColor.primary"
          :href="route('sms.campaigns.create')"
          :iconLeft="Plus"
          :size="ButtonSize.sm"
          :variant="ButtonVariant.solid"
        >
          Add a SMS campaign
        </Button>
      </template>
    </template>

    <template #tabs>
      <TabNavItem
        :badgeLabel="counts.active_count"
        :isActive="!trashActive"
        label="Active"
        variant="inline"
        :href="
          route('sms.campaigns.index', {
            ...(encodedFilters ? { filters: encodedFilters } : {})
          })
        "
      />
      <TabNavDivider layout="inline" />
      <TabNavItem
        :badgeLabel="counts.trashed_count ?? 0"
        :isActive="trashActive"
        label="Trashed"
        variant="inline"
        :href="
          route('sms.campaigns.index', {
            trashed: true,
            ...(encodedFilters ? { filters: encodedFilters } : {})
          })
        "
      />
    </template>

    <template #emptyState>
      <EmptyState
        v-if="trashActive"
        title="No SMS campaigns were moved to the trash"
        description="Trashed SMS campaigns will appear on this screen"
      />
      <EmptyState
        v-else
        title="No SMS campaigns found"
        description="Create a new SMS campaign by clicking the button below"
        actionLabel="Add a SMS campaign"
      />
    </template>
  </IndexPageLayout>
</template>
