<script setup lang="ts">
import GridCard from './GridCard.vue';
import BarChart from '@/components/dashboard/BarChart.vue';
import DoughnutChart from '@/components/dashboard/DoughnutChart.vue';
import {
  MetricApiResult,
  MetricConfig,
  convertMetricToChartDataset,
  appendRemainderDataset
} from '@/components/dashboard/metrics';
import { DashboardConfig } from '@/domains/dashboard/config';

interface Props {
  metrics: MetricApiResult;
  metricConfig: MetricConfig;
  dashboardConfig: DashboardConfig;
}

interface Slots {
  headerRight: void;
}

const sizeClasses = {
  sm: 'col-span-6 lg:col-span-2',
  md: 'col-span-6 lg:col-span-3',
  lg: 'col-span-6 lg:col-span-4'
};

const props = defineProps<Props>();
defineSlots<Slots>();

const datasets = computed(() => {
  const data = convertMetricToChartDataset(props.metrics);
  if (props.metricConfig.type === 'doughnut') {
    return appendRemainderDataset(data);
  }
  return data;
});

const classes = computed(() => {
  return [
    sizeClasses[props.metricConfig.size] ?? sizeClasses.md,
    'print:!col-span-3 print:break-inside-avoid'
  ];
});

const chartComponent = computed(() => {
  if (props.metricConfig.type === 'doughnut') {
    return DoughnutChart;
  }
  return BarChart;
});
</script>

<template>
  <GridCard :title="metricConfig.title" :subtitle="metricConfig.byline" :class="classes">
    <template #headerRight>
      <slot name="headerRight"></slot>
    </template>

    <figure>
      <component
        :is="chartComponent"
        :labels="metricConfig.kpis.map((kpi) => kpi.label)"
        :dataset="datasets"
        :dashboardConfig="dashboardConfig"
      />
    </figure>
  </GridCard>
</template>
