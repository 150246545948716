<script setup lang="ts">
type EnumData = App.Base.Data.EnumData;

import type { HybridlyFormData } from '@/@types/global';

import { ButtonColor } from '@/hooks/useButtonClasses';

import Button from '@/components/button/Button.vue';
import DangerDialog from '@/components/dialog/DangerDialog.vue';
import Fieldset from '@/components/fieldset/Fieldset.vue';
import Form from '@/components/form/Form.vue';
import FormDateInput from '@/components/formDateInput/FormDateInput.vue';
import FormFooter from '@/components/formFooter/FormFooter.vue';
import FormHeader from '@/components/formHeader/FormHeader.vue';
import FormListboxInput from '@/components/formListboxInput/FormListboxInput.vue';
import FormMultiProgramInput from '@/components/formComboboxInput/FormMultiProgramInput.vue';
import FormMultiUserInput from '@/components/formComboboxInput/FormMultiUserInput.vue';
import FormRichTextInput from '@/components/formRichTextInput/FormRichTextInput.vue';
import FormTextInput from '@/components/formTextInput/FormTextInput.vue';

const props = defineProps<{
  form: HybridlyFormData<{
    title: string;
    type: EnumData;
    content_html: string;
    content_json: string;
  }>;
  isEditing?: boolean;
  types: EnumData[];
  returnUrl?: string;
}>();

const isCancelDialogOpen = ref(false);

const { onBackForward } = useBackForward();

// TODO: Fix intecepting the back button
onBackForward(() => {
  router.abort();
});

function handleToggleCancelDialog() {
  if (props.form.isDirty) {
    isCancelDialogOpen.value = !isCancelDialogOpen.value;
    return;
  }
  handleRedirect();
}

function handleRedirect() {
  router.navigate({ url: props.returnUrl || route('alerts.index') });
}
</script>

<template>
  <Form @submit.prevent="form.submit">
    <template #header>
      <FormHeader
        canClose
        :title="`${isEditing ? 'Edit' : 'Add'} a Alert`"
        :description="
          isEditing
            ? 'Use the form below to edit the alert.'
            : 'Fill out the form below add a new alert.'
        "
        @onClose="handleToggleCancelDialog"
      />
    </template>
    <Fieldset title="Alert information">
      <FormTextInput
        v-model="form.fields.title"
        label="Title"
        name="title"
        placeholder="Title"
        :error="form.errors.title"
      />
      <FormListboxInput
        label="Alert type"
        :error="form.errors.type"
        :options="types"
        :modelValue="form.fields.type"
        @update:modelValue="(value) => (form.fields.type = value)"
      />
      <FormDateInput
        :error="form.errors.expiration_date"
        :modelValue="form.fields.expiration_date"
        label="Expiration Date"
        name="expiration_date"
        placeholder="Expiration Date"
        format="MMM dd, yyyy"
        :clearable="false"
        @update:modelValue="(value) => (form.fields.expiration_date = value)"
      />
      <FormRichTextInput
        :error="form.errors.content_json"
        :modelValue="form.fields.content_json"
        label="Alert content"
        name="content_json"
        @update:modelValue="
          (value) => {
            form.fields.content_html = value.html;
            form.fields.content_json = value.json;
          }
        "
      />
    </Fieldset>
    <Fieldset title="Who should see this alert?">
      <FormMultiProgramInput
        :error="form.errors.program_ids"
        :modelValue="form.fields.program_ids"
        label="Programs"
        @update:modelValue="(value) => (form.fields.program_ids = value)"
      />
      <FormMultiUserInput
        label="Users"
        :error="form.errors.user_ids"
        :modelValue="form.fields.user_ids"
        @update:modelValue="(value) => (form.fields.user_ids = value)"
      />
    </Fieldset>
    <template #footer>
      <FormFooter>
        <Button
          :color="ButtonColor.primary"
          :isDisabled="form.processing"
          :isLoading="form.processing"
          type="submit"
          variant="solid"
          >Confirm</Button
        >
        <Button @click="handleToggleCancelDialog" variant="outlined" type="button">
          Back to alerts
        </Button>
      </FormFooter>
    </template>
  </Form>
  <DangerDialog
    :title="`Cancel ${isEditing ? 'editing' : 'adding a new'} alert`"
    :message="`Are you sure you want to cancel ${
      isEditing ? 'editing' : 'adding'
    } this alert? All ${isEditing ? 'changes' : 'information'} will be lost.`"
    :isOpen="isCancelDialogOpen"
    cancelButtonLabel="Stay on this page"
    confirmButtonLabel="Go back to alerts"
    @onCancel="handleToggleCancelDialog"
    @onClose="handleToggleCancelDialog"
    @onConfirm="handleRedirect"
  />
</template>
