<script setup lang="ts">
type EnumData = App.Base.Data.EnumData;

import type { Selectable } from '@/components/selectBox/selectBox';

import { useToggle } from '@vueuse/core';

import { ButtonColor, ButtonVariant } from '@/hooks/useButtonClasses';
import { useAuth } from '@/hooks/useAuth';
import { useMetrics } from '@/hooks/useMetrics';
import { useSchoolsSearch } from '@/hooks/search/useSchoolsSearch';

import { metricConfigs } from '@/domains/dashboard/config';
import { NumberVisualization } from '@/components/dashboard/data';
import AlertManager from '@/components/dashboard/AlertManager.vue';
import Button from '@/components/button/Button.vue';
import ButtonGroup from '@/components/buttonGroup/ButtonGroup.vue';
import ComboboxInput from '@/components/selectBox/combobox/ComboboxInput.vue';
import DoeFafsaCard from '@/components/dashboard/DoeFafsaCard.vue';
import IconButton from '@/components/button/IconButton.vue';
import Label from '@/components/label/Label.vue';
import ListboxInput from '@/components/selectBox/listbox/ListboxInput.vue';
import Loading from '@/components/loading/Loading.vue';
import MetricCard from '@/components/dashboard/MetricCard.vue';
import PageHeader from '@/components/pageHeader/PageHeader.vue';
import PercentageIcon from '@/icons/line/percentage.svg';
import PrintIcon from '@/icons/line/print.svg';
import SigmaIcon from '@/icons/line/sigma.svg';
import SpinnerIcon from '@/icons/line/spinner.svg';
import SwitchInput from '@/components/switchInput/SwitchInput.vue';

defineProps<{
  alerts: App.Configuration.Data.AlertData[];
  grades: EnumData[];
}>();

const { user } = useAuth();
const { schoolOptions, isLoading, onQueryChange } = useSchoolsSearch();
const dashboardSettings = useStorage<{
  school: Selectable<number> | null;
  grade: Selectable<number> | null;
  showGoal: boolean;
  numberFormat: NumberVisualization;
}>(`dashboardSettings-10012024-${user.value?.id}`, {
  school: null,
  grade: { value: 12, label: 'Grade 12' },
  showGoal: false,
  numberFormat: NumberVisualization.Sum
});

const dashboardFilters = computed(() => ({
  schoolId: dashboardSettings.value.school?.value,
  grade: dashboardSettings.value.grade?.value
}));

const [isReady, setIsReady] = useToggle(!!dashboardSettings.value.school);
const {
  metrics,
  isLoading: isLoadingMetrics,
  updateFilters: updateMetricFilters
} = useMetrics(dashboardFilters.value, metricConfigs);

onMounted(() => {
  onQueryChange('');
});

function updateDashboardSettings(key: string, value: unknown) {
  dashboardSettings.value = { ...dashboardSettings.value, [key]: value };
}

function setShowGoal(checked: boolean) {
  updateDashboardSettings('showGoal', checked);
}

function setNumberVisualizationFormat(format: NumberVisualization) {
  updateDashboardSettings('numberFormat', format);
}

function onSelectSchool(opt: Selectable<number> | null) {
  updateDashboardSettings('school', opt);
  updateMetricFilters(dashboardFilters.value);
}

function onSelectGrade(opt: Selectable<number> | null) {
  updateDashboardSettings('grade', opt);
  updateMetricFilters(dashboardFilters.value);
}

function handlePrint() {
  window.print();
}

watchOnce(schoolOptions, () => {
  // On initial load, if no school is selected wait
  // for the school options to load then auto-select the first option
  if (!dashboardSettings.value.school && schoolOptions.value[0]) {
    onSelectSchool(schoolOptions.value[0]);
    setIsReady(true);
  }
});
</script>


					<script lang="ts">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <div class="divide-y-slate-200 flex h-full flex-col divide-y">
    <PageHeader title="Dashboard">
      <template #title>
        <div class="flex flex-1 items-center gap-4">
          <ComboboxInput
            class="min-w-0"
            inputClasses="border-none"
            contentWidth="max-w-sm w-full"
            :isLoading="isLoading"
            :isDisabled="!isReady"
            :options="schoolOptions"
            :modelValue="dashboardSettings.school"
            :onFilter="onQueryChange"
            placeholder="Loading..."
            @update:modelValue="onSelectSchool"
          >
            <template #trigger="triggerSlotProps">
              <p class="mr-1 truncate text-lg font-bold leading-5 text-slate-800">
                {{ triggerSlotProps.modelValue?.label ?? triggerSlotProps.placeholder }}
              </p>
            </template>
          </ComboboxInput>
          <div class="w-32">
            <ListboxInput
              :modelValue="dashboardSettings.grade"
              :options="grades"
              @update:modelValue="onSelectGrade"
            />
          </div>

          <SpinnerIcon v-if="isLoadingMetrics" class="h-6 w-6 animate-spin text-secondary-900" />
        </div>
      </template>
      <template #rightButtons>
        <label class="flex flex-row items-center gap-2">
          <SwitchInput
            name="showGoal"
            :checked="dashboardSettings.showGoal"
            @update:checked="setShowGoal"
          />
          <Label as="span">Show goals</Label>
        </label>

        <ButtonGroup :variant="ButtonVariant.outlined">
          <IconButton
            :icon="SigmaIcon"
            size="sm"
            :variant="ButtonVariant.outlined"
            @click="setNumberVisualizationFormat(NumberVisualization.Sum)"
            :checked="dashboardSettings.numberFormat === NumberVisualization.Sum"
            :isToggle="true"
            ariaLabel="View metric sums"
          />
          <IconButton
            :icon="PercentageIcon"
            size="sm"
            :variant="ButtonVariant.outlined"
            @click="setNumberVisualizationFormat(NumberVisualization.Percentage)"
            :checked="dashboardSettings.numberFormat === NumberVisualization.Percentage"
            :isToggle="true"
            ariaLabel="View metric percentages"
          />
        </ButtonGroup>

        <Button
          @click="handlePrint"
          :iconLeft="PrintIcon"
          :color="ButtonColor.slate"
          :variant="ButtonVariant.soft"
          >Print</Button
        >
      </template>
    </PageHeader>

    <AlertManager :alerts="alerts" />

    <template v-if="!isReady || (isLoadingMetrics && metrics.length === 0)">
      <section class="py-20">
        <Loading title="Loading dashboard" iconColor="slate" />
      </section>
    </template>
    <template v-else>
      <section class="grid grid-cols-6 gap-4 p-4 print:pb-72">
        <template v-for="(config, i) in metricConfigs" :key="config.title">
          <MetricCard
            v-if="metrics[i]"
            :metricConfig="config"
            :dashboardConfig="dashboardSettings"
            :metrics="metrics[i] ?? []"
          />
        </template>
        <DoeFafsaCard :schoolId="dashboardSettings.school?.value" />
      </section>
    </template>
  </div>
</template>
