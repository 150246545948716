<script setup lang="ts">
import { usePageMeta } from '@/hooks/usePageMeta';

usePageMeta();

withDefaults(
  defineProps<{
    width?: 'sm' | 'lg';
  }>(),
  {
    width: 'sm'
  }
);
</script>

<template>
  <main class="relative flex h-screen w-full overflow-hidden">
    <div class="flex w-full flex-col items-center overflow-y-auto lg:w-1/2">
      <div
        class="my-auto flex w-full flex-col px-4 py-8"
        :class="{
          'max-w-108': width === 'sm',
          'max-w-142': width === 'lg'
        }"
      >
        <slot />
      </div>
    </div>
    <div class="bg hidden h-screen w-1/2 lg:block"></div>
  </main>
</template>

<style scoped>
.bg {
  background-image: url('@/images/backgrounds/login-bg.jpg');
  background-size: cover;
  background-position: right 67% bottom 0;
}
</style>
