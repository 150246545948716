import { Selectable } from '@/components/selectBox/selectBox';

export const countries = [
  { country: 'United States of America', country_abbreviation: 'US' },
  { country: 'Afghanistan', country_abbreviation: 'AF' },
  { country: 'Åland Islands', country_abbreviation: 'AX' },
  { country: 'Albania', country_abbreviation: 'AL' },
  { country: 'Algeria', country_abbreviation: 'DZ' },
  { country: 'American Samoa', country_abbreviation: 'AS' },
  { country: 'Andorra', country_abbreviation: 'AD' },
  { country: 'Angola', country_abbreviation: 'AO' },
  { country: 'Anguilla', country_abbreviation: 'AI' },
  { country: 'Antarctica', country_abbreviation: 'AQ' },
  { country: 'Antigua and Barbuda', country_abbreviation: 'AG' },
  { country: 'Argentina', country_abbreviation: 'AR' },
  { country: 'Armenia', country_abbreviation: 'AM' },
  { country: 'Aruba', country_abbreviation: 'AW' },
  { country: 'Australia', country_abbreviation: 'AU' },
  { country: 'Austria', country_abbreviation: 'AT' },
  { country: 'Azerbaijan', country_abbreviation: 'AZ' },
  { country: 'Bahamas', country_abbreviation: 'BS' },
  { country: 'Bahrain', country_abbreviation: 'BH' },
  { country: 'Bangladesh', country_abbreviation: 'BD' },
  { country: 'Barbados', country_abbreviation: 'BB' },
  { country: 'Belarus', country_abbreviation: 'BY' },
  { country: 'Belgium', country_abbreviation: 'BE' },
  { country: 'Belize', country_abbreviation: 'BZ' },
  { country: 'Benin', country_abbreviation: 'BJ' },
  { country: 'Bermuda', country_abbreviation: 'BM' },
  { country: 'Bhutan', country_abbreviation: 'BT' },
  { country: 'Bolivia, Plurinational State of', country_abbreviation: 'BO' },
  { country: 'Bonaire, Sint Eustatius and Saba', country_abbreviation: 'BQ' },
  { country: 'Bosnia and Herzegovina', country_abbreviation: 'BA' },
  { country: 'Botswana', country_abbreviation: 'BW' },
  { country: 'Bouvet Island', country_abbreviation: 'BV' },
  { country: 'Brazil', country_abbreviation: 'BR' },
  { country: 'British Indian Ocean Territory', country_abbreviation: 'IO' },
  { country: 'Brunei Darussalam', country_abbreviation: 'BN' },
  { country: 'Bulgaria', country_abbreviation: 'BG' },
  { country: 'Burkina Faso', country_abbreviation: 'BF' },
  { country: 'Burundi', country_abbreviation: 'BI' },
  { country: 'Cabo Verde', country_abbreviation: 'CV' },
  { country: 'Cambodia', country_abbreviation: 'KH' },
  { country: 'Cameroon', country_abbreviation: 'CM' },
  { country: 'Canada', country_abbreviation: 'CA' },
  { country: 'Cayman Islands', country_abbreviation: 'KY' },
  { country: 'Central African Republic', country_abbreviation: 'CF' },
  { country: 'Chad', country_abbreviation: 'TD' },
  { country: 'Chile', country_abbreviation: 'CL' },
  { country: 'China', country_abbreviation: 'CN' },
  { country: 'Christmas Island', country_abbreviation: 'CX' },
  { country: 'Cocos (Keeling) Islands', country_abbreviation: 'CC' },
  { country: 'Colombia', country_abbreviation: 'CO' },
  { country: 'Comoros', country_abbreviation: 'KM' },
  { country: 'Congo, the Democratic Republic of', country_abbreviation: 'CD' },
  { country: 'Congo', country_abbreviation: 'CG' },
  { country: 'Cook Islands', country_abbreviation: 'CK' },
  { country: 'Costa Rica', country_abbreviation: 'CR' },
  { country: 'Croatia', country_abbreviation: 'HR' },
  { country: 'Cuba', country_abbreviation: 'CU' },
  { country: 'Curaçao', country_abbreviation: 'CW' },
  { country: 'Cyprus', country_abbreviation: 'CY' },
  { country: 'Czech Republic', country_abbreviation: 'CZ' },
  { country: 'Denmark', country_abbreviation: 'DK' },
  { country: 'Djibouti', country_abbreviation: 'DJ' },
  { country: 'Dominica', country_abbreviation: 'DM' },
  { country: 'Dominican Republic', country_abbreviation: 'DO' },
  { country: 'Ecuador', country_abbreviation: 'EC' },
  { country: 'Egypt', country_abbreviation: 'EG' },
  { country: 'El Salvador', country_abbreviation: 'SV' },
  { country: 'Equatorial Guinea', country_abbreviation: 'GQ' },
  { country: 'Eritrea', country_abbreviation: 'ER' },
  { country: 'Estonia', country_abbreviation: 'EE' },
  { country: 'Ethiopia', country_abbreviation: 'ET' },
  { country: 'Falkland Islands (Malvinas)', country_abbreviation: 'FK' },
  { country: 'Faroe Islands', country_abbreviation: 'FO' },
  { country: 'Fiji', country_abbreviation: 'FJ' },
  { country: 'Finland', country_abbreviation: 'FI' },
  { country: 'France', country_abbreviation: 'FR' },
  { country: 'French Guiana', country_abbreviation: 'GF' },
  { country: 'French Polynesia', country_abbreviation: 'PF' },
  { country: 'French Southern Territories', country_abbreviation: 'TF' },
  { country: 'Gabon', country_abbreviation: 'GA' },
  { country: 'Gambia', country_abbreviation: 'GM' },
  { country: 'Georgia', country_abbreviation: 'GE' },
  { country: 'Germany', country_abbreviation: 'DE' },
  { country: 'Ghana', country_abbreviation: 'GH' },
  { country: 'Gibraltar', country_abbreviation: 'GI' },
  { country: 'Greece', country_abbreviation: 'GR' },
  { country: 'Greenland', country_abbreviation: 'GL' },
  { country: 'Grenada', country_abbreviation: 'GD' },
  { country: 'Guadeloupe', country_abbreviation: 'GP' },
  { country: 'Guam', country_abbreviation: 'GU' },
  { country: 'Guatemala', country_abbreviation: 'GT' },
  { country: 'Guernsey', country_abbreviation: 'GG' },
  { country: 'Guinea-Bissau', country_abbreviation: 'GW' },
  { country: 'Guinea', country_abbreviation: 'GN' },
  { country: 'Guyana', country_abbreviation: 'GY' },
  { country: 'Haiti', country_abbreviation: 'HT' },
  { country: 'Heard Island and McDonalds Islands', country_abbreviation: 'HM' },
  { country: 'Holy See', country_abbreviation: 'VA' },
  { country: 'Honduras', country_abbreviation: 'HN' },
  { country: 'Hong Kong', country_abbreviation: 'HK' },
  { country: 'Hungary', country_abbreviation: 'HU' },
  { country: 'Iceland', country_abbreviation: 'IS' },
  { country: 'India', country_abbreviation: 'IN' },
  { country: 'Indonesia', country_abbreviation: 'ID' },
  { country: 'Iran, Islamic Republic of', country_abbreviation: 'IR' },
  { country: 'Iraq', country_abbreviation: 'IQ' },
  { country: 'Ireland', country_abbreviation: 'IE' },
  { country: 'Isle of Man', country_abbreviation: 'IM' },
  { country: 'Israel', country_abbreviation: 'IL' },
  { country: 'Italy', country_abbreviation: 'IT' },
  { country: 'Jamaica', country_abbreviation: 'JM' },
  { country: 'Japan', country_abbreviation: 'JP' },
  { country: 'Jersey', country_abbreviation: 'JE' },
  { country: 'Jordan', country_abbreviation: 'JO' },
  { country: 'Kazakhstan', country_abbreviation: 'KZ' },
  { country: 'Kenya', country_abbreviation: 'KE' },
  { country: 'Kiribati', country_abbreviation: 'KI' },
  { country: 'Korea, Republic of', country_abbreviation: 'KR' },
  { country: 'Kosovo', country_abbreviation: 'XK' },
  { country: 'Kuwait', country_abbreviation: 'KW' },
  { country: 'Kyrgyzstan', country_abbreviation: 'KG' },
  { country: 'Latvia', country_abbreviation: 'LV' },
  { country: 'Lebanon', country_abbreviation: 'LB' },
  { country: 'Lesotho', country_abbreviation: 'LS' },
  { country: 'Liberia', country_abbreviation: 'LR' },
  { country: 'Libya', country_abbreviation: 'LY' },
  { country: 'Liechtenstein', country_abbreviation: 'LI' },
  { country: 'Lithuania', country_abbreviation: 'LT' },
  { country: 'Luxembourg', country_abbreviation: 'LU' },
  { country: 'Macao', country_abbreviation: 'MO' },
  { country: 'Macedonia, the former Yugoslav Republic of', country_abbreviation: 'MK' },
  { country: 'Madagascar', country_abbreviation: 'MG' },
  { country: 'Malawi', country_abbreviation: 'MW' },
  { country: 'Malaysia', country_abbreviation: 'MY' },
  { country: 'Maldives', country_abbreviation: 'MV' },
  { country: 'Mali', country_abbreviation: 'ML' },
  { country: 'Malta', country_abbreviation: 'MT' },
  { country: 'Marshall Islands', country_abbreviation: 'MH' },
  { country: 'Martinique', country_abbreviation: 'MQ' },
  { country: 'Mauritania', country_abbreviation: 'MR' },
  { country: 'Mauritius', country_abbreviation: 'MU' },
  { country: 'Mayotte', country_abbreviation: 'YT' },
  { country: 'Mexico', country_abbreviation: 'MX' },
  { country: 'Micronesia, Federated States of', country_abbreviation: 'FM' },
  { country: 'Moldova, Republic of', country_abbreviation: 'MD' },
  { country: 'Monaco', country_abbreviation: 'MC' },
  { country: 'Mongolia', country_abbreviation: 'MN' },
  { country: 'Montenegro', country_abbreviation: 'ME' },
  { country: 'Montserrat', country_abbreviation: 'MS' },
  { country: 'Morocco', country_abbreviation: 'MA' },
  { country: 'Mozambique', country_abbreviation: 'MZ' },
  { country: 'Myanmar', country_abbreviation: 'MM' },
  { country: 'Namibia', country_abbreviation: 'NA' },
  { country: 'Nauru', country_abbreviation: 'NR' },
  { country: 'Nepal', country_abbreviation: 'NP' },
  { country: 'Netherlands', country_abbreviation: 'NL' },
  { country: 'New Caledonia', country_abbreviation: 'NC' },
  { country: 'New Zealand', country_abbreviation: 'NZ' },
  { country: 'Nicaragua', country_abbreviation: 'NI' },
  { country: 'Niger', country_abbreviation: 'NE' },
  { country: 'Nigeria', country_abbreviation: 'NG' },
  { country: 'Niue', country_abbreviation: 'NU' },
  { country: 'Norfolk Island', country_abbreviation: 'NF' },
  { country: 'Northern Mariana Islands', country_abbreviation: 'MP' },
  { country: 'Norway', country_abbreviation: 'NO' },
  { country: 'Oman', country_abbreviation: 'OM' },
  { country: 'Pakistan', country_abbreviation: 'PK' },
  { country: 'Palau', country_abbreviation: 'PW' },
  { country: 'Palestine, State of', country_abbreviation: 'PS' },
  { country: 'Panama', country_abbreviation: 'PA' },
  { country: 'Papua New Guinea', country_abbreviation: 'PG' },
  { country: 'Paraguay', country_abbreviation: 'PY' },
  { country: 'Peru', country_abbreviation: 'PE' },
  { country: 'Philippines', country_abbreviation: 'PH' },
  { country: 'Pitcairn', country_abbreviation: 'PN' },
  { country: 'Poland', country_abbreviation: 'PL' },
  { country: 'Portugal', country_abbreviation: 'PT' },
  { country: 'Puerto Rico', country_abbreviation: 'PR' },
  { country: 'Qatar', country_abbreviation: 'QA' },
  { country: 'Réunion', country_abbreviation: 'RE' },
  { country: 'Romania', country_abbreviation: 'RO' },
  { country: 'Russian Federation', country_abbreviation: 'RU' },
  { country: 'Rwanda', country_abbreviation: 'RW' },
  { country: 'Saint Barthélemy', country_abbreviation: 'BL' },
  { country: 'Saint Helena, Ascension and Tristan da Cunha', country_abbreviation: 'SH' },
  { country: 'Saint Kitts and Nevis', country_abbreviation: 'KN' },
  { country: 'Saint Lucia', country_abbreviation: 'LC' },
  { country: 'Saint Martin (French part)', country_abbreviation: 'MF' },
  { country: 'Saint Pierre and Miquelon', country_abbreviation: 'PM' },
  { country: 'Saint Vincent and the Grenadines', country_abbreviation: 'VC' },
  { country: 'Samoa', country_abbreviation: 'WS' },
  { country: 'San Marino', country_abbreviation: 'SM' },
  { country: 'Sao Tome and Principe', country_abbreviation: 'ST' },
  { country: 'Saudi Arabia', country_abbreviation: 'SA' },
  { country: 'Senegal', country_abbreviation: 'SN' },
  { country: 'Serbia', country_abbreviation: 'RS' },
  { country: 'Seychelles', country_abbreviation: 'SC' },
  { country: 'Sierra Leone', country_abbreviation: 'SL' },
  { country: 'Singapore', country_abbreviation: 'SG' },
  { country: 'Sint Maarten (Dutch part)', country_abbreviation: 'SX' },
  { country: 'Slovakia', country_abbreviation: 'SK' },
  { country: 'Slovenia', country_abbreviation: 'SI' },
  { country: 'Solomon Islands', country_abbreviation: 'SB' },
  { country: 'Somalia', country_abbreviation: 'SO' },
  { country: 'South Africa', country_abbreviation: 'ZA' },
  { country: 'South Georgia and the South Sandwich Islands', country_abbreviation: 'GS' },
  { country: 'South Sudan', country_abbreviation: 'SS' },
  { country: 'Spain', country_abbreviation: 'ES' },
  { country: 'Sri Lanka', country_abbreviation: 'LK' },
  { country: 'Sudan', country_abbreviation: 'SD' },
  { country: 'Suriname', country_abbreviation: 'SR' },
  { country: 'Svalbard and Jan Mayen', country_abbreviation: 'SJ' },
  { country: 'Swaziland', country_abbreviation: 'SZ' },
  { country: 'Sweden', country_abbreviation: 'SE' },
  { country: 'Switzerland', country_abbreviation: 'CH' },
  { country: 'Syrian Arab Republic', country_abbreviation: 'SY' },
  { country: 'Taiwan, Province of China', country_abbreviation: 'TW' },
  { country: 'Tajikistan', country_abbreviation: 'TJ' },
  { country: 'Tanzania, United Republic of', country_abbreviation: 'TZ' },
  { country: 'Thailand', country_abbreviation: 'TH' },
  { country: 'Timor-Leste', country_abbreviation: 'TL' },
  { country: 'Togo', country_abbreviation: 'TG' },
  { country: 'Tokelau', country_abbreviation: 'TK' },
  { country: 'Tonga', country_abbreviation: 'TO' },
  { country: 'Trinidad and Tobago', country_abbreviation: 'TT' },
  { country: 'Tunisia', country_abbreviation: 'TN' },
  { country: 'Turkey', country_abbreviation: 'TR' },
  { country: 'Turkmenistan', country_abbreviation: 'TM' },
  { country: 'Turks and Caicos Islands', country_abbreviation: 'TC' },
  { country: 'Tuvalu', country_abbreviation: 'TV' },
  { country: 'Uganda', country_abbreviation: 'UG' },
  { country: 'Ukraine', country_abbreviation: 'UA' },
  { country: 'United Arab Emirates', country_abbreviation: 'AE' },
  { country: 'United Kingdom of Great Britain and Northern Ireland', country_abbreviation: 'GB' },
  { country: 'United States Minor Outlying Islands', country_abbreviation: 'UM' },
  { country: 'Uruguay', country_abbreviation: 'UY' },
  { country: 'Uzbekistan', country_abbreviation: 'UZ' },
  { country: 'Vanuatu', country_abbreviation: 'VU' },
  { country: 'Venezuela, Bolivarian Republic of', country_abbreviation: 'VE' },
  { country: 'Viet Nam', country_abbreviation: 'VN' },
  { country: 'Virgin Islands, British', country_abbreviation: 'VG' },
  { country: 'Virgin Islands, U.S.', country_abbreviation: 'VI' },
  { country: 'Wallis and Futuna', country_abbreviation: 'WF' },
  { country: 'Western Sahara', country_abbreviation: 'EH' },
  { country: 'Yemen', country_abbreviation: 'YE' },
  { country: 'Zambia', country_abbreviation: 'ZM' },
  { country: 'Zimbabwe', country_abbreviation: 'ZW' },
  { country: "Côte d'Ivoire", country_abbreviation: 'CI' },
  { country: "Korea, Democratic People's Republic of", country_abbreviation: 'KP' },
  { country: "Lao People's Democratic Republic", country_abbreviation: 'LA' }
];

export interface SelectableCountry extends Selectable<string> {
  country_abbreviation: string;
}

export function countryOptions(): Array<SelectableCountry> {
  return [...countries.values()].map(({ country, country_abbreviation }) => ({
    label: country,
    value: country,
    country_abbreviation
  }));
}
