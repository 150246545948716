<script setup lang="tsx">
import type { CustomCells, HybridlyRow } from '@/components/table/Table';
import { AppliedFilters, AppliedFilter } from '@/components/distiller/types';

import { useHybridlyTable } from '@/hooks/useHybridlyTable';
import { useTableRowClick } from '@/hooks/useTableRowClick';

import BulkRemoveButton from '@/components/table/BulkRemoveButton.vue';
import EmptyState from '@/components/emptyState/EmptyState.vue';
import IndexPageLayout from '@/components/pageLayout/IndexPageLayout.vue';
import TableBadgesCell from '@/components/table/TableBadgesCell.vue';
import TableEnumCell from '@/components/table/TableEnumCell.vue';

import Building from '@/icons/line/building.svg';
import ManageStudentBuilder from '@/components/students/ManageStudentBuilder.vue';

type StudentRow = HybridlyRow<App.Students.Data.StudentData>;

const props = defineProps<{
  appliedFilters: AppliedFilters;
  distiller: App.Search.Data.DistillerData;
  studentGroup: App.Students.Data.StudentGroupData;
  students: Table<App.Students.Data.StudentGroupData>;
}>();

const { table, tableSelectionState } = useHybridlyTable<App.Students.Data.StudentGroupData>(
  props,
  'students'
);
const { handleRowClick } = useTableRowClick();
function transformRecordsArray(records: HybridlyRow<App.Students.Data.StudentGroupData>[]) {
  return records.map((record) => {
    const updatedActions = record.actions.map((action) => {
      async function execute() {
        await router.navigate({
          method: 'DELETE',
          url: route('student-groups.student.remove', {
            studentGroup: props.studentGroup.id,
            student: record.key
          }),
          preserveState: true
        });
      }

      return { ...action, execute };
    });

    return { ...record, actions: updatedActions };
  });
}

const customCells = {
  school: ({ row, column }) => (
    <TableBadgesCell badgesToDisplay={1} itemIcon={Building} items={[row.value(column.key).name]} />
  ),
  grade: ({ row, column }) => <TableEnumCell enumData={row.value(column.key)} />,
  status: ({ row, column }) => (
    <TableBadgesCell
      badgesToDisplay={1}
      badgeVariant="soft"
      items={[row.value(column.key).label]}
    />
  )
} as CustomCells<App.Students.Data.StudentData>;

function handleStudentRowClick(event: MouseEvent, row: StudentRow) {
  const url = route('students.profile.show', {
    student: row.key
  });
  handleRowClick(url, event);
}
</script>


					<script lang="tsx">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <IndexPageLayout
    :title="studentGroup.name"
    :appliedFilters="appliedFilters"
    :distiller="distiller"
    :scopedAppliedFilters="{
      groups: {
        operator: 'in',
        value: [{ value: studentGroup.id } as unknown]
      } as AppliedFilter
    }"
    :table="{ ...table, records: transformRecordsArray(table.records) }"
    :customCells="customCells"
    :stickyEndColumns="1"
    :stickyStartColumns="2"
    :tableSelectionState="tableSelectionState"
    clickableRows
    @clickRow="($event, row) => handleStudentRowClick($event, row as StudentRow)"
  >
    <template #rightButtons>
      <template v-if="tableSelectionState.hasSelection">
        <BulkRemoveButton
          parentType="Student Group"
          recordType="Students"
          :route="route('student-groups.student.bulk-remove', { studentGroup: studentGroup.id })"
          :tableSelectionState="tableSelectionState"
        />
      </template>

      <template v-else>
        <ManageStudentBuilder
          title="Test Title"
          :distiller="distiller"
          :studentGroup="studentGroup"
        />
      </template>
    </template>

    <template #emptyState>
      <EmptyState
        title="No students in this student group"
        description="Students in this student groups will appear on this screen"
      />
    </template>
  </IndexPageLayout>
</template>
