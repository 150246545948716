<script setup lang="ts">
import { AccordionRoot } from 'radix-vue';
import EditNodePanel from '@/components/automated-campaigns/nodePanels/EditNodePanel.vue';
import AutomatedInboundMessageType = App.Sms.Enums.AutomatedInboundMessageType;
import AutomatedInboundMessageData = App.Sms.Data.AutomatedCampaigns.AutomatedInboundMessageData;
import FormListboxInput from '@/components/formListboxInput/FormListboxInput.vue';
import FormTextInput from '@/components/formTextInput/FormTextInput.vue';
import EnumData = App.Base.Data.EnumData;
import { index } from '@/api/AutomatedInboundMessageTypeApi';
import NodePanelAccordionHeader from '@/components/slideout/slideoutAccordion/SlideoutAccordionHeader.vue';
import NodePanelAccordionContent from '@/components/slideout/slideoutAccordion/SlideoutAccordionContent.vue';
import NodePanelAccordionItem from '@/components/slideout/slideoutAccordion/SlideoutAccordionItem.vue';
import DeleteInboundMessageDialog from '@/components/automated-campaigns/dialogs/DeleteInboundMessageDialog.vue';

const props = defineProps<{
  node: AutomatedInboundMessageData;
  isPaused: boolean;
}>();

const form = useForm<{
  type?: AutomatedInboundMessageType;
  body: null | string;
}>({
  method: 'PUT',
  url: route('sms.inbound-message.update', { message: props.node.id }),
  updateInitials: true,
  fields: {
    type: props.node.type,
    body: props.node.body
  }
});

const selectedInboundMessageType = computed<EnumData | null>(() => {
  return inboundMessageTypes.value.find(({ value }) => value === form.fields.type) ?? null;
});

const inboundMessageTypes = useStorage<EnumData[]>('smsAutomatedInboundMessageTypes', []);

onMounted(async () => {
  inboundMessageTypes.value = await index();
});
</script>

<template>
  <DeleteInboundMessageDialog :message="node">
    <template #default="{ openDialog }">
      <EditNodePanel
        title="Edit Reply Option"
        :onSubmit="form.submit"
        :onDelete="() => openDialog()"
        :reset="form.reset"
        :isDirty="form.isDirty"
        :processing="form.processing"
        :isPaused
      >
        <AccordionRoot
          type="multiple"
          :defaultValue="['settings', 'compose']"
          class="flex flex-grow flex-col"
        >
          <NodePanelAccordionItem value="settings">
            <NodePanelAccordionHeader title="If" />
            <NodePanelAccordionContent>
              <FormListboxInput
                :error="form.errors.type"
                label="Reply is"
                :options="inboundMessageTypes"
                :modelValue="selectedInboundMessageType"
                @update:modelValue="(selected) => (form.fields.type = selected?.value)"
                :isDisabled="!isPaused"
              />

              <FormTextInput
                v-if="form.fields.type === 'custom'"
                :error="form.errors.body"
                :isDisabled="!isPaused"
                label="Content is"
                v-model="form.fields.body"
              />
            </NodePanelAccordionContent>
          </NodePanelAccordionItem>
        </AccordionRoot>
      </EditNodePanel>
    </template>
  </DeleteInboundMessageDialog>
</template>
