<script setup lang="ts">
import type { Selectable } from '@/components/selectBox/selectBox';

import CenteredContent from '@/components/content/CenteredContent.vue';
import StudentParentCreateForm from '@/components/studentParent/StudentParentCreateForm.vue';

defineProps<{
  returnUrl?: string;
}>();

const form = useForm({
  method: 'POST',
  url: route('parents.store'),
  fields: {
    first_name: '',
    last_name: '',
    email: '',
    cell_phone: '',
    student_ids: [] as Selectable<number>[]
  },
  transform: (fields) => ({
    ...fields,
    student_ids: fields.student_ids?.map((student) => student.value) ?? null
  })
});
</script>


					<script lang="ts">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <CenteredContent>
    <StudentParentCreateForm :form="form" :returnUrl="returnUrl" />
  </CenteredContent>
</template>
