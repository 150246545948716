<script setup lang="ts">
import MultiComboboxInput from '@/components/selectBox/combobox/MultiComboboxInput.vue';
import { useSchoolsSearch } from '@/hooks/search/useSchoolsSearch';
import { Selectable } from '@/components/selectBox/selectBox';

const props = defineProps<{
  modelValue: Selectable<number>[];
  programIds?: number[];
}>();

defineEmits(['update:modelValue']);

const { updateSearchFilters, schoolOptions, isLoading, onQueryChange } = useSchoolsSearch({
  program_ids: props.programIds
});

watch(
  () => props.programIds,
  (newProgramIds) => {
    updateSearchFilters({ program_ids: newProgramIds });
  }
);
</script>

<template>
  <MultiComboboxInput
    :selectAll="true"
    :options="schoolOptions"
    :onFilter="onQueryChange"
    :isLoading="isLoading"
    :modelValue="modelValue"
    @update:modelValue="$emit('update:modelValue', $event)"
    @open="() => onQueryChange('')"
  />
</template>
