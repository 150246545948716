<script setup lang="ts">
type EnumData = App.Base.Data.EnumData;

import { buildSelectableInstitute, buildSelectableScholarship } from '@/utils/buildSelectable';

import CenteredContent from '@/components/content/CenteredContent.vue';
import StudentScholarshipForm from '@/components/students/studentActions/StudentScholarshipForm.vue';

const props = defineProps<{
  returnUrl?: string;
  student: App.Students.Data.StudentData;
  studentScholarship: App.Students.Data.StudentScholarshipData;
  statuses: EnumData[];
  types: EnumData[];
}>();

const form = useForm({
  method: 'PUT',
  url: route('students.student-scholarships.update', {
    student: props.student.id,
    studentScholarship: props.studentScholarship.id
  }),
  fields: {
    type: props.studentScholarship.type,
    institute_id: buildSelectableInstitute(props.studentScholarship.institute),
    scholarship_name: buildSelectableScholarship(props.studentScholarship.scholarship),
    amount: props.studentScholarship.amount,
    years_renewable: props.studentScholarship.years_renewable,
    status: props.studentScholarship.status,
    is_assisted: props.studentScholarship.is_assisted
  },
  transform: (fields) => ({
    ...fields,
    type: fields.type?.value,
    institute_id: fields.institute_id?.value,
    scholarship_name: fields.scholarship_name?.label,
    status: fields.status?.value
  })
});
</script>


					<script lang="ts">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <CenteredContent>
    <StudentScholarshipForm
      :form
      :returnUrl
      :student
      :studentScholarship
      :statuses
      :types
      isEditing
    />
  </CenteredContent>
</template>
