<script setup lang="ts">
import IconButton from '@/components/button/IconButton.vue';
import DangerDialog from '@/components/dialog/DangerDialog.vue';
import TrashIcon from '@/icons/line/trash.svg';

const props = defineProps<{
  uploadSession: App.Bulkinator.Data.UploadSessionData;
}>();

const [isOpen, toggleOpen] = useToggle(false);

const form = useForm({
  url: route('upload-sessions.delete', { uploadSession: props.uploadSession.id }),
  method: 'DELETE',
  fields: {}
});
</script>

<template>
  <DangerDialog
    :isOpen="isOpen"
    title="Delete Upload Session"
    message="Are you sure you want to delete this upload session? This action is permanent and cannot be reverted."
    confirmButtonLabel="Delete"
    :isLoading="form.processing"
    @onConfirm="() => form.submit()"
    @onClose="toggleOpen"
    @onCancel="toggleOpen"
  />

  <IconButton
    :icon="TrashIcon"
    ariaLabel="Delete upload session"
    color="danger"
    variant="soft"
    size="xs"
    @click="() => toggleOpen()"
  />
</template>
