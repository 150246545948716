<script setup lang="tsx">
import CenteredContent from '@/components/content/CenteredContent.vue';
import Form from '@/components/form/Form.vue';
import FormHeader from '@/components/formHeader/FormHeader.vue';
import ReportTypeCard from '@/domains/reports/components/ReportTypeCard.vue';

const props = defineProps<{
  customReports: App.Reports.Data.CustomReportData[];
}>();

const groupedReports = computed(() => {
  return props.customReports.reduce(
    (carry, report) => {
      if (report.is_kpi_report) {
        carry.kpiReports.push(report);
      } else {
        carry.customReports.push(report);
      }

      return carry;
    },
    {
      kpiReports: [] as App.Reports.Data.CustomReportData[],
      customReports: [] as App.Reports.Data.CustomReportData[]
    }
  );
});
</script>


					<script lang="tsx">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <CenteredContent size="lg">
    <Form>
      <template #header>
        <FormHeader
          canClose
          title="Create a New Report"
          description="Select the type of report you want to create."
          @onClose="router.get(route('report-templates.index'))"
        />
      </template>

      <div class="space-y-8">
        <div class="space-y-4">
          <h3 class="text-lg font-medium">System Reports</h3>
          <div class="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
            <ReportTypeCard
              v-for="reportType in groupedReports.kpiReports"
              :key="reportType.id"
              :reportType="reportType"
              :url="reportType.url"
            />
          </div>
        </div>

        <hr class="border-t border-slate-200" />

        <div class="space-y-4">
          <h3 class="text-lg font-medium">Custom Reports</h3>
          <div class="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
            <ReportTypeCard
              v-for="reportType in groupedReports.customReports"
              :key="reportType.id"
              :reportType="reportType"
              :url="reportType.url"
            />
          </div>
        </div>
      </div>
    </Form>
  </CenteredContent>
</template>
