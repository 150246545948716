import axios from 'axios';
import { get, set } from 'lodash';

const localCache = {};

export async function getEntity(
  type: string,
  id: number | string
): Promise<App.Bulkinator.Data.UploadEntityData> {
  const cachedValue = get(localCache, `${type}.${id}`, null);
  if (cachedValue) {
    return cachedValue;
  }

  const response = axios.get(route('api.upload-entity.show', { type, id })).then((res) => res.data);
  set(localCache, `${type}.${id}`, response);
  return response;
}
