<script setup lang="ts">
type EnumData = App.Base.Data.EnumData;

import type { HybridlyFormData } from '@/@types/global';

import { ButtonColor, ButtonVariant } from '@/hooks/useButtonClasses';

import Button from '@/components/button/Button.vue';
import DangerDialog from '@/components/dialog/DangerDialog.vue';
import Fieldset from '@/components/fieldset/Fieldset.vue';
import Form from '@/components/form/Form.vue';
import FormCheckboxInput from '@/components/formCheckboxInput/FormCheckboxInput.vue';
import FormField from '@/components/formField/FormField.vue';
import FormFooter from '@/components/formFooter/FormFooter.vue';
import FormHeader from '@/components/formHeader/FormHeader.vue';
import FormListboxInput from '@/components/formListboxInput/FormListboxInput.vue';
import FormRichTextInput from '@/components/formRichTextInput/FormRichTextInput.vue';
import FormSchoolInput from '@/components/formComboboxInput/FormSchoolInput.vue';
import FormStudentInput from '@/components/formComboboxInput/FormStudentInput.vue';
import FormTextInput from '@/components/formTextInput/FormTextInput.vue';

const props = defineProps<{
  form: HybridlyFormData<{
    title: string;
    school_id: number;
    student_id: number;
    parent_id: number;
  }>;
  isEditing?: boolean;
  subjects: EnumData[];
}>();

const isCancelDialogOpen = ref(false);

const { onBackForward } = useBackForward();

// TODO: Fix intecepting the back button
onBackForward(() => {
  router.abort();
});

function handleToggleCancelDialog() {
  if (props.form.isDirty) {
    isCancelDialogOpen.value = !isCancelDialogOpen.value;
    return;
  }
  handleRedirect();
}

function handleRedirect() {
  router.navigate({ url: route('success-stories.index') });
}
</script>

<template>
  <Form @submit.prevent="form.submit">
    <template #header>
      <FormHeader
        canClose
        :title="`${isEditing ? 'Edit' : 'Add'} a Success Story`"
        :description="
          isEditing
            ? 'Use the form below to edit the success story.'
            : 'Fill out the form below add a new success story.'
        "
        @onClose="handleToggleCancelDialog"
      />
    </template>
    <Fieldset title="Success story information">
      <FormTextInput
        :error="form.errors.title"
        :modelValue="form.fields.title"
        label="Title"
        name="title"
        @update:modelValue="(value) => (form.fields.title = value)"
      />
      <FormListboxInput
        :error="form.errors.subject"
        :modelValue="form.fields.subject"
        :options="subjects"
        label="Subject"
        @update:modelValue="(value) => (form.fields.subject = value)"
      />
      <FormSchoolInput
        :error="form.errors.school_id"
        :modelValue="form.fields.school_id"
        label="Schools"
        placeholder="Select school"
        @update:modelValue="(value) => (form.fields.school_id = value)"
      />
      <FormStudentInput
        :error="form.errors.student_id"
        :modelValue="form.fields.student_id"
        label="Student"
        placeholder="Select student"
        @update:modelValue="(value) => (form.fields.student_id = value)"
      />
      <FormField label="Parent/Guardian" :error="form.errors.is_parent">
        <FormCheckboxInput
          :checked="form.fields.is_parent"
          helperText="Is this success story about the student's parent/guardian?"
          label="Display parent/guardian name"
          name="is_parent"
          @update:checked="(value) => (form.fields.is_parent = value)"
        />
      </FormField>
      <FormRichTextInput
        :error="form.errors.content_json"
        :modelValue="form.fields.content_json"
        label="Success story"
        name="content_json"
        @update:modelValue="
          (value) => {
            form.fields.content_html = value.html;
            form.fields.content_json = value.json;
          }
        "
      />
    </Fieldset>
    <template #footer>
      <FormFooter>
        <Button
          type="submit"
          :color="ButtonColor.primary"
          :isDisabled="form.processing || !form.isDirty"
          :isLoading="form.processing"
          :variant="ButtonVariant.solid"
          >Confirm</Button
        >
        <Button
          :color="ButtonColor.slate"
          :variant="ButtonVariant.outlined"
          type="button"
          @click="handleToggleCancelDialog"
        >
          Back to success stories
        </Button>
      </FormFooter>
    </template>
  </Form>
  <DangerDialog
    :title="`Cancel ${isEditing ? 'editing' : 'adding a new'} success story`"
    :message="`Are you sure you want to cancel ${
      isEditing ? 'editing' : 'adding'
    } this success story? All ${isEditing ? 'changes' : 'information'} will be lost.`"
    :isOpen="isCancelDialogOpen"
    cancelButtonLabel="Stay on this page"
    confirmButtonLabel="Go back to success stories"
    @onCancel="handleToggleCancelDialog"
    @onClose="handleToggleCancelDialog"
    @onConfirm="handleRedirect"
  />
</template>
