<script setup lang="ts">
import type { TextInputProps } from '@/components/textInput/TextInputProps';

import VueDatePicker from '@vuepic/vue-datepicker';

import TextInput from '@/components/textInput/TextInput.vue';

import Calendar from '@/icons/line/calendar.svg?component';

import '@vuepic/vue-datepicker/dist/main.css';

export type DateTimeInputProps = Omit<TextInputProps, 'type'> & {
  timezone?: string;
};

defineProps<DateTimeInputProps>();

const emit = defineEmits<{
  'update:modelValue': [value: string | null];
}>();

function onValueChange(date: Date): void {
  emit('update:modelValue', date?.toISOString() ?? null);
}
</script>

<template>
  <VueDatePicker
    :disabled="isDisabled"
    :modelValue="modelValue"
    :timezone="timezone"
    format="MMM dd, yyyy hh:mm a"
    @update:modelValue="onValueChange"
  >
    <template #dp-input="{ value }">
      <TextInput
        :hasError="hasError"
        :isDisabled="isDisabled"
        :iconLeft="Calendar"
        :id="id"
        :modelValue="value"
        :name="name"
        :placeholder="placeholder"
        :variant="variant"
      />
    </template>
    <template #action-preview>
      {{ timezone }}
    </template>
  </VueDatePicker>
</template>

<style>
:root {
  --dp-font-family: theme(fontFamily.sans);
  --dp-cell-border-radius: 100%;
}

.dp__theme_light {
  --dp-primary-color: theme('colors.zinc.900');
}

button.dp__action_button.dp__action_select {
  @apply bg-slate-900;
}
</style>
