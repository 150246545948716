<script setup lang="ts">
import type { ShowProfileProps } from '@/domains/students/views/ShowProfile.vue';

import { RouterLink } from 'hybridly/vue';

import { ButtonColor, ButtonVariant } from '@/hooks/useButtonClasses';
import { useAuth } from '@/hooks/useAuth';
import { useDialogHelpers } from '@/hooks/useDialogHelpers';

import { formatDate } from '@/utils/date';

import Badge from '@/components/badge/Badge.vue';
import Button from '@/components/button/Button.vue';
import Copy from '@/components/copy/Copy.vue';
import DangerDialog from '@/components/dialog/DangerDialog.vue';
import DefinitionList from '@/components/definitionList/DefinitionList.vue';
import DefinitionListItem from '@/components/definitionList/DefinitionListItem.vue';
import DefinitionListItemBadgeWrapper from '@/components/definitionList/DefinitionListItemBadgeWrapper.vue';
import EmptyCard from '@/components/emptyCard/EmptyCard.vue';
import getAsyncIcon from '@/components/icons/AsyncIcon';
import IconButton from '@/components/button/IconButton.vue';
import InformationCollapsible from '@/components/collapsible/InformationCollapsible.vue';
import Slideout from '@/components/slideout/Slideout.vue';
import TabNav from '@/components/tabNav/TabNav.vue';
import TabNavItem from '@/components/tabNav/TabNavItem.vue';

import Calendar from '@/icons/line/calendar.svg';
import CustomField from '@/icons/line/custom-field.svg';
import EditAlt from '@/icons/line/edit-alt.svg';
import Envelope from '@/icons/line/envelope.svg';
import PlusCircle from '@/icons/line/plus-circle.svg';
import SignAlt from '@/icons/line/sign-alt.svg';
import TagAlt from '@/icons/line/tag-alt.svg';
import TrashIcon from '@/icons/line/trash.svg';
import User from '@/icons/line/user.svg';
import UserCircle from '@/icons/line/user-circle.svg';

const props = defineProps<ShowProfileProps>();

const { show, closeLocally } = useDialog();
const { isDialogOpen, toggleDialog } = useDialogHelpers();

const { hasPermission } = useAuth();

const activeTab = ref('student');

const form = useForm({
  method: 'DELETE',
  spoof: false,
  url: route('students.archive', { student: props.student.id }),
  fields: {},
  hooks: {
    success() {
      closeLocally();
    }
  }
});

async function handleStudentArchive() {
  await form.submit();
  toggleDialog();
}

function toggleActiveTab() {
  activeTab.value === 'student' ? (activeTab.value = 'parents') : (activeTab.value = 'student');
}
</script>

<template>
  <Slideout :title="student.name" :isOpen="show" @onClose="closeLocally" :icon="User">
    <template #actions>
      <IconButton
        v-if="student.authorization.update"
        :href="route('students.edit', { student: student.id })"
        :icon="EditAlt"
        ariaLabel="Edit student"
        size="sm"
        variant="soft"
      />
      <!--
        TODO: Add back in once SMS is implemented
      -->
      <!-- <IconButton
        v-if="student.authorization.update"
        href="#"
        :icon="Comments"
        ariaLabel="View communications"
        size="sm"
        variant="soft"
      /> -->
      <IconButton
        v-if="student.authorization.delete"
        color="danger"
        :icon="TrashIcon"
        ariaLabel="Move student to trash"
        size="sm"
        variant="soft"
        @click="toggleDialog"
      />
    </template>
    <template #subNav>
      <TabNav class="!gap-0" layout="inline">
        <TabNavItem
          class="w-1/2"
          :isActive="activeTab === 'student'"
          label="Student"
          variant="inline"
          @click="toggleActiveTab"
        />
        <TabNavItem
          class="w-1/2"
          :isActive="activeTab === 'parents'"
          label="Parent/Guardian"
          variant="inline"
          @click="toggleActiveTab"
        />
      </TabNav>
    </template>
    <template v-if="activeTab === 'student'">
      <div class="-mx-4 border-b border-slate-200 p-4 pt-0">
        <InformationCollapsible title="Academic Details">
          <div class="flex flex-col gap-6">
            <RouterLink
              :href="route('students.edit.academic-details', { student: student.id })"
              class="flex flex-col items-center rounded-2xl border border-slate-200 bg-slate-50 p-4 hover:border-slate-300 hover:bg-slate-100"
            >
              <p class="text-sm font-semibold uppercase leading-5 text-slate-500">GPA</p>
              <p class="text-lg font-semibold leading-7 text-slate-900">
                {{ student.gpa ?? '--' }}
              </p>
            </RouterLink>

            <RouterLink
              :href="route('students.tests.index', { student: student.id })"
              class="flex flex-col items-center rounded-2xl border border-slate-200 bg-slate-50 p-4 hover:border-slate-300 hover:bg-slate-100"
            >
              <p class="text-sm font-semibold uppercase leading-5 text-slate-500">
                SAT Total Super Score
              </p>
              <p class="text-lg font-semibold leading-7 text-slate-900">
                {{ satSuperScores.Total }}
              </p>
            </RouterLink>

            <RouterLink
              :href="route('students.tests.index', { student: student.id })"
              class="flex flex-col items-center rounded-2xl border border-slate-200 bg-slate-50 p-4 hover:border-slate-300 hover:bg-slate-100"
            >
              <p class="text-sm font-semibold uppercase leading-5 text-slate-500">
                ACT Total Super Score
              </p>
              <p class="text-lg font-semibold leading-7 text-slate-900">
                {{ actSuperScores.Total }}
              </p>
            </RouterLink>

            <div class="flex flex-col gap-1.5">
              <p class="text-center text-2xs font-bold uppercase text-slate-500">Majors</p>
              <div
                v-for="(major, index) in majors"
                :key="index"
                class="rounded-2xl border border-slate-200 bg-slate-50"
              >
                <div v-if="major.id" class="flex flex-col justify-center p-4">
                  <div class="flex items-center gap-2">
                    <component
                      :is="major.major.icon ? getAsyncIcon(major.major.icon) : undefined"
                      class="h-4 w-4 shrink-0 grow-0 text-slate-500"
                    />
                    <p class="truncate text-base font-semibold leading-5 text-slate-900">
                      {{ major.major.label }}
                    </p>
                  </div>
                  <p class="truncate text-sm leading-5 text-slate-500">
                    {{ major.major.description ?? 'No description' }}
                  </p>
                </div>
              </div>
              <Button
                :color="ButtonColor.slate"
                :href="route('students.edit.academic-details', { student: student.id })"
                :iconLeft="PlusCircle"
                :variant="ButtonVariant.soft"
              >
                Add a major
              </Button>
            </div>
          </div>
        </InformationCollapsible>
      </div>
      <div class="-mx-4 border-b border-slate-200 p-4">
        <InformationCollapsible title="Personal Information">
          <div class="flex flex-col gap-4">
            <DefinitionList>
              <DefinitionListItem
                :description="student.email ? 'No email address' : undefined"
                :icon="Envelope"
                title="Email"
              >
                <div v-if="student.email" class="py-1">
                  <Copy :text="student.email" />
                </div>
              </DefinitionListItem>
              <DefinitionListItem
                :description="!student.cell_phone ? 'No cell phone' : undefined"
                title="Cell Phone"
              >
                <div v-if="student.cell_phone" class="py-1">
                  <Copy :text="student.cell_phone" />
                </div>
              </DefinitionListItem>
              <DefinitionListItem
                :description="!student.home_phone ? 'No home phone' : undefined"
                title="Home Phone"
              >
                <div v-if="student.home_phone" class="py-1">
                  <Copy :text="student.home_phone" />
                </div>
              </DefinitionListItem>
            </DefinitionList>
            <DefinitionList>
              <DefinitionListItem
                :description="formatDate(student.dob) || 'No date of birth'"
                :icon="UserCircle"
                title="Date of Birth"
              />
              <DefinitionListItem
                :description="student.gender?.label ?? 'No date of birth'"
                title="Gender"
              />
              <DefinitionListItem
                :description="student.ethnicity?.label ?? 'No ethnicity'"
                title="Ethnicity"
              />
              <DefinitionListItem
                :description="student.first_gen ? 'Yes' : 'No'"
                title="First gen"
              />
            </DefinitionList>
          </div>
        </InformationCollapsible>
      </div>
      <div class="-mx-4 border-b border-slate-200 p-4">
        <InformationCollapsible title="Enrollment Details">
          <div class="flex flex-col gap-4">
            <DefinitionList>
              <DefinitionListItem
                v-if="hasPermission('read-school')"
                :icon="SignAlt"
                title="School"
              >
                <Badge
                  v-if="student.school?.name"
                  :label="student.school.name"
                  size="md"
                  variant="soft"
                />
              </DefinitionListItem>
              <DefinitionListItem v-if="hasPermission('read-program')" title="Program">
                <Badge
                  v-if="student.school?.program?.display_name"
                  :label="student.school.program.display_name"
                  size="md"
                  variant="soft"
                />
              </DefinitionListItem>
              <DefinitionListItem
                :description="
                  !student.post_secondary_plans?.length ? 'No post-secondary plans' : undefined
                "
                title="Post-secondary plan"
              >
                <DefinitionListItemBadgeWrapper
                  v-if="student.post_secondary_plans?.length"
                  :badgeItems="
                    student.post_secondary_plans?.map((plan) => ({
                      id: plan?.id,
                      label: plan?.post_secondary_plan?.label
                    })) ?? []
                  "
                  :buttonRoute="route('students.edit.enrollment-details', { student: student.id })"
                  buttonLabel="Go to post-secondary plans"
                />
              </DefinitionListItem>
              <DefinitionListItem
                :description="!student.groups?.length ? 'No groups' : undefined"
                :icon="TagAlt"
                title="Groups"
              >
                <DefinitionListItemBadgeWrapper
                  v-if="student.groups?.length"
                  :badgeItems="
                    student.groups?.map((group) => ({
                      id: group?.id,
                      label: group?.name
                    })) ?? []
                  "
                  :buttonRoute="route('students.edit.enrollment-details', { student: student.id })"
                  buttonLabel="Go to student groups"
                />
              </DefinitionListItem>
              <DefinitionListItem
                :description="formatDate(student.enroll_date) || 'No enroll date'"
                :icon="Calendar"
                title="Enroll date"
              />
              <DefinitionListItem :description="student.grade?.label ?? 'No grade'" title="Grade" />
              <DefinitionListItem
                :description="student.class_of ?? 'No class of'"
                title="Class of"
              />
              <DefinitionListItem
                :description="!student.status ? 'No enrollment status' : undefined"
                title="Status"
              >
                <Badge
                  v-if="student.status"
                  :label="student.status.label"
                  size="md"
                  variant="soft"
                />
              </DefinitionListItem>
            </DefinitionList>
          </div>
        </InformationCollapsible>
      </div>
      <div class="-mx-4 p-4 pb-0">
        <InformationCollapsible title="Custom fields">
          <DefinitionList v-if="programStudentFields.length">
            <template v-for="(customField, index) in programStudentFields" :key="customField.id">
              <DefinitionListItem
                :icon="index === 0 ? CustomField : undefined"
                :title="customField.field_label"
                :description="student.meta[customField.field_key] ?? '--'"
              />
            </template>
          </DefinitionList>
          <EmptyCard
            v-else
            actionLabel="New custom field"
            :actionHref="route('program-student-fields.create')"
            isDashed
            isRounded
            size="md"
            title="No custom fields"
            description="Add a new custom field by clicking the button below"
          />
        </InformationCollapsible>
      </div>
    </template>
    <template v-if="activeTab === 'parents'">
      <div
        v-for="(parent, index) in parents"
        :key="parent.id"
        class="-mx-4 p-4"
        :class="{
          'pt-0': index === 0,
          'pb-0': index === parents.length - 1,
          'border-slate border-b': index !== parents.length - 1
        }"
      >
        <InformationCollapsible :title="`Parent/Gaurdian ${index + 1}`">
          <div class="flex flex-col gap-4">
            <DefinitionList>
              <DefinitionListItem
                :description="parent?.first_name ?? 'No first name'"
                :icon="UserCircle"
                title="First name"
              />
              <DefinitionListItem
                :description="parent?.last_name ?? 'No last name'"
                title="Last name"
              />
            </DefinitionList>
            <DefinitionList>
              <DefinitionListItem
                :description="!parent?.email ? 'No email address' : undefined"
                :icon="Envelope"
                title="Email"
              >
                <div v-if="parent?.email" class="py-1">
                  <Copy :text="parent?.email" />
                </div>
              </DefinitionListItem>
              <DefinitionListItem
                :description="!parent?.cell_phone ? 'No phone number' : undefined"
                title="Phone"
              >
                <div v-if="parent.cell_phone" class="py-1">
                  <Copy :text="parent.cell_phone" />
                </div>
              </DefinitionListItem>
            </DefinitionList>
          </div>
        </InformationCollapsible>
      </div>
    </template>
  </Slideout>
  <DangerDialog
    title="Move Student to Trash"
    message="Are you sure you want to move this student to the trash?"
    confirmButtonLabel="Move to trash"
    :isOpen="isDialogOpen"
    :isLoading="form.processing"
    @onConfirm="handleStudentArchive"
    @onCancel="toggleDialog"
    @onClose="toggleDialog"
  />
</template>
