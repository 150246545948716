<script setup lang="ts" generic="T extends Record<string, unknown>">
import type { BadgeProps } from '@/components/badge/Badge.vue';

import Badge from '@/components/badge/Badge.vue';
import PaddedCellContent from '@/components/table/PaddedCellContent.vue';

export type TableBadgesCellProps = {
  items: string[] | { label: string; href: string }[];
  badgesToDisplay?: number;
  itemIcon?: Component;
  badgeVariant?: BadgeProps['variant'];
  badgeColor?: BadgeProps['color'];
  badgeSize?: BadgeProps['size'];
  badgeShape?: BadgeProps['shape'];
};

const props = withDefaults(defineProps<TableBadgesCellProps>(), {
  badgesToDisplay: 1,
  badgeShape: 'rounded',
  badgeVariant: 'outlined'
});

const filteredItems = computed(() => props.items.filter((item) => item !== null));
const trimmedItems = computed(() => filteredItems.value.slice(0, props.badgesToDisplay));
</script>

<template>
  <PaddedCellContent>
    <div class="flex flex-wrap gap-1">
      <Badge
        v-for="item in trimmedItems"
        :iconLeft="itemIcon"
        :key="item['label'] ?? item"
        :label="item['label'] ?? item"
        :href="item['href'] ?? null"
        :shape="badgeShape"
        :size="badgeSize"
        :variant="badgeVariant"
        :color="badgeColor"
        class="truncate"
      />
      <Badge
        v-if="badgesToDisplay && items.length > badgesToDisplay"
        :shape="badgeShape"
        :size="badgeSize"
        :variant="badgeVariant"
        :color="badgeColor"
        class="truncate"
      >
        +{{ items.length - trimmedItems.length }}
      </Badge>
    </div>
  </PaddedCellContent>
</template>
