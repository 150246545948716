export function formatErrors(errors: Record<string, unknown>) {
  return Object.values(errors ?? {})
    .map((errors) => {
      if (typeof errors === 'string') {
        return [errors];
      }

      return formatErrors(errors as Record<string, unknown>);
    })
    .flat()
    .join('\n');
}
