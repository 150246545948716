<script setup lang="tsx">
import { formatDate } from '@/utils/date';
import Hyperlink from '@/components/links/Hyperlink.vue';

const props = defineProps<{
  uploadSession: App.Bulkinator.Data.UploadSessionData;
}>();

const parentSession = computed(() => props.uploadSession.parent_upload_session);
const createdAt = computed(() => formatDate(props.uploadSession.created_at));
</script>

<template>
  <p class="text-sm text-zinc-500">
    <template v-if="parentSession">
      Session automatically created from
      <Hyperlink
        :href="route('upload-sessions.show', { uploadSession: parentSession.id })"
        target="_blank"
      >
        {{ parentSession.name }}
      </Hyperlink>
      on
      <span class="font-semibold text-zinc-700">{{ createdAt }}</span>
    </template>
    <template v-else>
      Session created on <span class="font-semibold text-zinc-700">{{ createdAt }}</span>
    </template>
  </p>
</template>
