import debounce from 'lodash/debounce';
import { HybridlyTable } from '@/@types/hybridly';
import { RecordIdentifier } from 'hybridly/vue';

export type TableSelectionState = {
  hasSelection: boolean;
  selectedRowIds: RecordIdentifier[];
  arePageRecordsSelected: boolean;
  areAllRecordsSelected: boolean;
  hasRowSelected: (id: RecordIdentifier) => boolean;
  toggleRow: (id: RecordIdentifier) => void;
  toggleAllRows: () => void;
  togglePageRows: () => void;
  reset: () => void;
};

export function useHybridlyTable<
  T extends Record<string, unknown>,
  P extends 'cursor' | 'length-aware' | 'simple' = 'length-aware'
>(props: Record<string, unknown>, key: string) {
  const table = useTable(props, key) as unknown as HybridlyTable;

  const data = computed(() => props[key] as Table<T, P>);
  const filters = computed(() => {
    return data.value.refinements.filters.reduce((acc, filter) => {
      acc[filter.name] = filter;
      return acc;
    }, {});
  });
  const applyFilter = debounce((filterName, value) => {
    const filter = table.filters.find((filter) => filter.name === filterName);

    filter?.apply(value, {
      transformUrl: { query: { page: undefined } }
    });
  }, 250);

  const selectedRows = ref({
    all: false,
    only: new Set<RecordIdentifier>()
  });

  const hasSelection = computed(() => selectedRows.value.all || selectedRows.value.only.size > 0);
  const arePageRecordsSelected = computed(() =>
    table.records.every((record) => selectedRows.value.only.has(record.key))
  );
  const areAllRecordsSelected = computed(() => selectedRows.value.all);

  function hasRowSelected(id: RecordIdentifier): boolean {
    if (selectedRows.value.only.has(id)) {
      return true;
    }
    if (selectedRows.value.all) {
      return true;
    }
    return false;
  }

  function toggleRow(id: RecordIdentifier) {
    if (selectedRows.value.only.has(id)) {
      selectedRows.value.only.delete(id);
    } else {
      selectedRows.value.only.add(id);
    }
  }

  function togglePageRows() {
    if (arePageRecordsSelected.value || areAllRecordsSelected.value) {
      selectedRows.value.all = false;
      selectedRows.value.only.clear();
      return;
    }

    table.records.forEach((record) => {
      selectedRows.value.only.add(record.key);
    });
  }

  function toggleAllRows() {
    if (areAllRecordsSelected.value) {
      selectedRows.value.all = false;
      selectedRows.value.only.clear();
      return;
    }

    selectedRows.value.all = true;
    selectedRows.value.only.clear();
  }

  function reset() {
    selectedRows.value.all = false;
    selectedRows.value.only.clear();
  }

  const tableSelectionState = computed(() => ({
    toggleRow,
    togglePageRows,
    toggleAllRows,
    reset,
    hasRowSelected,
    hasSelection: hasSelection.value,
    selectedRowIds: Array.from(selectedRows.value.only),
    arePageRecordsSelected: arePageRecordsSelected.value,
    areAllRecordsSelected: areAllRecordsSelected.value
  }));

  return {
    table,
    filters,
    applyFilter,
    tableSelectionState
  };
}
