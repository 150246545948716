<script setup lang="ts">
import { buildSelectableProgram, buildSelectableUser } from '@/utils/buildSelectable';

import CenteredContent from '@/components/content/CenteredContent.vue';
import SchoolForm from '@/components/schools/SchoolForm.vue';

type SchoolData = App.Configuration.Data.SchoolData;

const props = defineProps<{
  school: SchoolData;
  returnUrl?: string;
}>();

const form = useForm({
  method: 'PUT',
  url: route('schools.update', { school: props.school.id }),
  fields: {
    name: props.school.name,
    district: props.school.district,
    program_id: buildSelectableProgram(props.school.program),
    adviser_ids: (props.school.advisers || []).map((user) => buildSelectableUser(user)),
    address: props.school.address,
    state: {
      value: props.school.state,
      label: props.school.state
    },
    city: props.school.city,
    zip_code: props.school.zip_code,
    is_served: props.school.is_served
  },
  transform: (fields) => ({
    ...fields,
    program_id: fields.program_id?.value ?? null,
    adviser_ids: fields.adviser_ids.map((adviser) => adviser?.value),
    state: fields.state?.value ?? null
  })
});
</script>


					<script lang="ts">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <CenteredContent>
    <SchoolForm isEditing :form="form" :returnUrl="returnUrl" />
  </CenteredContent>
</template>
