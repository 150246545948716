<script setup lang="ts">
import AutomatedNodeData = App.Sms.Data.AutomatedCampaigns.NodeTree.AutomatedNodeData;
import AutomatedEdgeData = App.Sms.Data.AutomatedCampaigns.NodeTree.AutomatedEdgeData;
import CampaignNodeEditor from '@/components/automated-campaigns/CampaignNodeEditor.vue';
import Alert from '@/components/alert/Alert.vue';
import ExclamationCircleIcon from '@/icons/line/exclamation-circle.svg';

defineProps<{
  campaign: App.Sms.Data.AutomatedCampaigns.AutomatedCampaignData;
  nodes: AutomatedNodeData[];
  edges: AutomatedEdgeData[];
}>();
</script>


					<script lang="ts">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <div class="flex h-full w-full flex-col">
    <div v-if="!campaign.paused_at" class="p-2">
      <Alert
        color="secondary"
        variant="soft"
        :icon="ExclamationCircleIcon"
        title="To edit the messages, the campaign must be paused."
      />
    </div>
    <div
      class="bg-dot-pattern w-full flex-1"
      :class="{
        'border-t border-zinc-200': !campaign.paused_at
      }"
    >
      <CampaignNodeEditor :campaign :nodes :edges />
    </div>
  </div>
</template>
