<script setup lang="ts">
import Dialog from '@/components/dialog/Dialog.vue';
import IconButton from '@/components/button/IconButton.vue';
import FormRows from '@/components/formRows/FormRows.vue';
import FormTextInput from '@/components/formTextInput/FormTextInput.vue';
import EditAltIcon from '@/icons/line/edit-alt.svg';

const props = defineProps<{
  uploadSession: App.Bulkinator.Data.UploadSessionData;
}>();

const [isOpen, toggleOpen] = useToggle(false);

const form = useForm({
  url: route('upload-sessions.rename', { uploadSession: props.uploadSession.id }),
  method: 'PUT',
  fields: {
    name: props.uploadSession.name
  },
  only: ['uploadSession', 'meta'],
  reset: false,
  updateInitials: false,
  hooks: {
    success() {
      toggleOpen();
    }
  }
});

function handleSaveName() {
  form.submit();
}
</script>

<template>
  <Dialog
    :isOpen="isOpen"
    title="Rename Upload Session"
    :isLoading="form.processing"
    confirmButtonLabel="Rename Upload Session"
    @onConfirm="handleSaveName"
    @onClose="toggleOpen"
    @onCancel="toggleOpen"
  >
    <FormRows>
      <FormTextInput
        label="Upload Session Name"
        name="name"
        v-model="form.fields.name"
        :error="form.errors.name"
        autofocus
      />
    </FormRows>
  </Dialog>

  <IconButton
    :icon="EditAltIcon"
    ariaLabel="Rename upload session"
    color="slate"
    variant="soft"
    size="xs"
    @click="() => toggleOpen()"
  />
</template>
