<script setup lang="ts">
import FormSwitchInput from '@/components/formSwitchInput/FormSwitchInput.vue';
import DateTime from '@/components/dateTime/DateTime.vue';
import type { Dictionary } from 'lodash';
import { groupBy } from 'lodash';
import AdmitHubContactData = App.AdmitHub.Data.AdmitHubContactData;
import AdmitHubMessageData = App.AdmitHub.Data.AdmitHubMessageData;
import { RadioGroupItem, RadioGroupRoot } from 'radix-vue';
import ThreadsEmptyState from '@/components/emptyState/ThreadsEmptyState.vue';
import AdmitHubMessage from '@/components/admitHub/AdmitHubMessage.vue';
import { ComponentPublicInstance } from 'vue';
import ExclamationIcon from '@/icons/line/exclamation-circle.svg';
import Badge from '@/components/badge/Badge.vue';
import RecipientProfileHeader from '@/components/messaging/recipientProfileHeader.vue';
import RecipientBadge from '@/components/messaging/recipientBadge.vue';
import RelatedThreadsNav from '@/components/sms/RelatedThreadsNav.vue';
import { WithLoadedRelations } from '@/@types/global';
import RelatedThreadData = App.Sms.Data.RelatedThreadData;
import ThreadData = App.Sms.Data.ThreadData;

type AdmitHubMessageComponent = InstanceType<typeof AdmitHubMessage>;

defineEmits<{
  showAdmitHubContact: [contactId: number];
  showThread: [thread: WithLoadedRelations<ThreadData, 'recipient'>];
}>();

const props = withDefaults(
  defineProps<{
    contact: WithLoadedRelations<AdmitHubContactData, 'contactable'>;
    showRecipientHeader?: boolean;
    relatedThreads?: RelatedThreadData[];
  }>(),
  {
    showNav: false,
    showRecipientHeader: true
  }
);

const onlyShowEscalations = ref<boolean>(false);
const escalationFilter = ref<'all' | 'unresolved' | 'resolved'>('all');
const unresolvedEscalationEls = ref<HTMLDivElement[]>([]);
const scrollContainerEl = ref<HTMLDivElement | null>(null);

function assignUnresolvedEscalationRefs(
  el: Element | ComponentPublicInstance,
  message: AdmitHubMessageData
) {
  if (message.escalation?.is_resolved === false) {
    el = el as AdmitHubMessageComponent;
    unresolvedEscalationEls.value.push(el.$el);
  }
}

const filteredMessages = computed(() => {
  if (onlyShowEscalations.value) {
    return (
      props.contact.messages?.filter((message) => {
        return (
          message.has_escalation &&
          {
            all: true,
            unresolved: !message.escalation?.is_resolved,
            resolved: message.escalation?.is_resolved
          }[escalationFilter.value]
        );
      }) || []
    );
  }

  return props.contact.messages || [];
});

const messagesGroupedByDate = computed<Dictionary<AdmitHubMessageData[]>>(() => {
  return groupBy(filteredMessages.value, (message: AdmitHubMessageData) => message.created);
});

function scrollToEarliestUnresolvedEscalation() {
  return unresolvedEscalationEls.value[0]?.scrollIntoView();
}

function setScrollPosition() {
  window.requestAnimationFrame(() => {
    if (unresolvedEscalationEls.value.length !== 0) {
      scrollToEarliestUnresolvedEscalation();
      return;
    }
    scrollContainerEl.value?.scrollTo({
      top: scrollContainerEl.value.scrollHeight
    });
  });
}

watch(onlyShowEscalations, () => {
  escalationFilter.value = 'all';
  setScrollPosition();
});

watch(
  () => props.contact.id,
  () => {
    onlyShowEscalations.value = false;
    unresolvedEscalationEls.value = [];
    nextTick(() => setScrollPosition());
  },
  { immediate: true, flush: 'post' }
);
</script>

<template>
  <div class="flex max-h-full min-h-0 flex-1 flex-col">
    <div>
      <RecipientProfileHeader
        v-if="contact.contactable && showRecipientHeader"
        :isStudent="contact.is_student_contact"
        :recipient="contact.contactable"
        recipientType="admithub"
      >
        <template #badge>
          <div class="mt-0.5 flex flex-wrap gap-x-2 gap-y-1">
            <RecipientBadge
              :isStudent="contact.is_student_contact"
              :recipient="contact.contactable"
            />
            <Badge
              v-if="contact.unresolved_escalation_count"
              as="button"
              variant="soft"
              :label="`${contact.unresolved_escalation_count} Unresolved`"
              size="sm"
              :iconLeft="ExclamationIcon"
              @click="scrollToEarliestUnresolvedEscalation"
            />
          </div>
        </template>
      </RecipientProfileHeader>

      <RelatedThreadsNav
        v-if="relatedThreads"
        type="admitHubContact"
        :id="contact.id"
        :relatedThreads="relatedThreads"
        @showThread="$emit('showThread', $event)"
        @showAdmitHubContact="$emit('showAdmitHubContact', $event)"
      />

      <div class="flex h-12 items-center border-b px-3 py-2">
        <FormSwitchInput
          label="Show Escalations Only"
          v-model:checked="onlyShowEscalations"
          name="escalationsOnly"
          :class="['border-zinc-200', onlyShowEscalations && 'mr-2.5 border-r pr-2.5']"
        />
        <RadioGroupRoot
          v-model="escalationFilter"
          :disabled="!onlyShowEscalations"
          :aria-hidden="!onlyShowEscalations"
          :class="[
            'divide-x-zinc-200 divide-x',
            !onlyShowEscalations && 'invisible',
            'rounded-lg border border-zinc-200'
          ]"
        >
          <RadioGroupItem
            :class="[
              escalationFilter === 'all' ? 'bg-zinc-100' : 'bg-zinc-50',
              'relative hover:z-10 hover:ring hover:ring-slate-300 focus:z-10 focus:ring focus:ring-slate-300 focus-visible:outline-none',
              'rounded-l-lg',
              'px-2 py-1 text-sm font-medium text-zinc-800'
            ]"
            value="all"
          >
            All
          </RadioGroupItem>
          <RadioGroupItem
            :class="[
              escalationFilter === 'unresolved' ? 'bg-zinc-100' : 'bg-zinc-50',
              'relative hover:z-10 hover:ring hover:ring-slate-300 focus:z-10 focus:ring focus:ring-slate-300 focus-visible:outline-none',
              'px-2 py-1 text-sm font-medium text-zinc-800'
            ]"
            value="unresolved"
          >
            Unresolved
          </RadioGroupItem>
          <RadioGroupItem
            :class="[
              escalationFilter === 'resolved' ? 'bg-zinc-100' : 'bg-zinc-50',
              'relative hover:z-10 hover:ring hover:ring-slate-300 focus:z-10 focus:ring focus:ring-slate-300 focus-visible:outline-none',
              'rounded-r-lg',
              'px-2 py-1 text-sm font-medium text-zinc-800'
            ]"
            value="resolved"
          >
            Resolved
          </RadioGroupItem>
        </RadioGroupRoot>
      </div>
    </div>
    <main
      ref="scrollContainerEl"
      class="flex max-h-full flex-col justify-between space-y-2 overflow-y-auto px-3 py-2"
    >
      <ThreadsEmptyState v-if="filteredMessages.length === 0" title="No Messages" description="" />
      <template v-for="(messages, date) in messagesGroupedByDate" :key="date">
        <!-- @TODO: fix 'as X' type escape hatches here -->
        <DateTime
          :date="date as string"
          class="pb-2 pt-4 text-center text-sm font-bold text-zinc-500"
        />
        <AdmitHubMessage
          v-for="message in messages"
          :key="message.id"
          :message="message"
          :ref="
            (el) => el && assignUnresolvedEscalationRefs(el as AdmitHubMessageComponent, message)
          "
        />
      </template>
    </main>
  </div>
</template>
