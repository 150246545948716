import { computed } from 'vue';
import { HybridlyFormData } from '@/@types/global';

export function useFormHelpers(form: HybridlyFormData<unknown>) {
  const isClean = computed(() => !form.isDirty);

  return {
    isClean
  };
}
