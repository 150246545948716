<script setup lang="tsx">
import ExportAsExcelDialog from '@/domains/bulkinator/components/ExportAsExcelDialog.vue';

defineProps<{
  uploadType: App.Bulkinator.Data.UploadTypeData;
  distiller: App.Search.Data.DistillerData;
}>();

const { show, close } = useDialog();

function handleConfirmExportData() {
  close();
}
</script>


					<script lang="tsx">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <ExportAsExcelDialog
    :isOpen="show ?? false"
    :distiller="distiller"
    :uploadType="uploadType"
    @cancel="close"
    @close="close"
    @confirm="handleConfirmExportData"
  />
</template>
