<script setup lang="ts">
import type { FormFieldProps, FormFieldSlots } from '@/components/formField/FormField';
import type { DateInputProps } from '@/components/dateInput/DateInput.vue';

import { useFormField } from '@/components/formField/useFormField';
import DateInput from '@/components/dateInput/DateInput.vue';
import FormField from '@/components/formField/FormField.vue';

type FormDateInputProps = Omit<DateInputProps, 'hasError'> & FormFieldProps;

const props = defineProps<FormDateInputProps>();
defineSlots<FormFieldSlots>();

const emit = defineEmits<{
  'update:modelValue': [value: string | null];
}>();

function onValueChange(date: string | null): void {
  emit('update:modelValue', date);
}

const { formFieldProps, slots } = useFormField({ props });
</script>

<template>
  <FormField v-bind="formFieldProps">
    <DateInput
      :autoPosition="autoPosition"
      :hasError="hasError"
      :isDisabled="isDisabled"
      :modelValue="modelValue"
      :name="name"
      :placeholder="placeholder"
      :variant="variant"
      :clearable="clearable"
      :format="format"
      @update:modelValue="onValueChange"
    />
    <template v-for="slot of slots" #[slot]>
      <slot :name="slot" />
    </template>
  </FormField>
</template>
