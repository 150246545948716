<script setup lang="ts">
import type { ShowUserProps } from '@/domains/auth/views/users/ShowUser.vue';

import { useAuth } from '@/hooks/useAuth';
import { useDialogHelpers } from '@/hooks/useDialogHelpers';

import { formatDate, formatDateTime } from '@/utils/date';

import Badge from '@/components/badge/Badge.vue';
import BadgeBoolean from '@/components/badge/BadgeBoolean.vue';
import DangerDialog from '@/components/dialog/DangerDialog.vue';
import DefinitionList from '@/components/definitionList/DefinitionList.vue';
import DefinitionListItem from '@/components/definitionList/DefinitionListItem.vue';
import DefinitionListItemBadgeWrapper from '@/components/definitionList/DefinitionListItemBadgeWrapper.vue';
import IconButton from '@/components/button/IconButton.vue';
import InformationCollapsible from '@/components/collapsible/InformationCollapsible.vue';
import Slideout from '@/components/slideout/Slideout.vue';

import Building from '@/icons/line/building.svg';
import CommentAltMessage from '@/icons/line/comment-alt-message.svg';
import EditAlt from '@/icons/line/edit-alt.svg';
import Envelope from '@/icons/line/envelope.svg';
import FilesLandscapeAlt from '@/icons/line/files-landscapes-alt.svg';
import Phone from '@/icons/line/phone.svg';
import TrashIcon from '@/icons/line/trash.svg';
import User from '@/icons/line/user.svg';
import UserCircle from '@/icons/line/user-circle.svg';
import FileCheck from '@/icons/line/file-check.svg';

const props = defineProps<ShowUserProps>();

const { isDialogOpen, toggleDialog } = useDialogHelpers();
const { show, close } = useDialog();

const { hasPermission } = useAuth();

const form = useForm({
  method: 'DELETE',
  spoof: false,
  url: route('users.delete', { user: props.user.id }),
  fields: {},
  hooks: {
    success() {
      close();
    }
  }
});

function getChannelBadgeIcon(type) {
  return {
    email: Envelope,
    sms: CommentAltMessage
  }[type];
}

async function handleUserDelete() {
  await form.submit();
  toggleDialog();
}
</script>

<template>
  <Slideout :title="user.name" :isOpen="show" @onClose="close" :icon="FilesLandscapeAlt">
    <template #actions>
      <IconButton
        v-if="user.authorization.update"
        :href="route('users.edit', { user: user.id })"
        :icon="EditAlt"
        ariaLabel="Edit user"
        size="sm"
        variant="soft"
      />
      <IconButton
        v-if="user.authorization.delete"
        color="danger"
        :icon="TrashIcon"
        ariaLabel="Move user to trash"
        size="sm"
        variant="soft"
        @click="toggleDialog"
      />
    </template>
    <div class="flex flex-col gap-4">
      <div
        class="-mx-4 p-4 pt-0"
        :class="{
          'border-b border-slate-200':
            (hasPermission('read-document-acknowledgements') &&
              user?.document_acknowledgements?.length) ||
            (channels?.length && hasPermission('read-user-two-factor-details')) ||
            (!channels?.length && !hasPermission('read-user-two-factor-details'))
        }"
      >
        <InformationCollapsible title="User Information">
          <DefinitionList v-if="user.roles?.length">
            <DefinitionListItem :icon="User" title="Role">
              <Badge variant="soft" size="md">{{ user.roles[0].display_name }}</Badge>
            </DefinitionListItem>
          </DefinitionList>
          <DefinitionList v-if="user.roles?.length && user.roles[0].name === 'adviser'">
            <DefinitionListItem
              :description="user.sms_phone_number ?? 'No SMS phone number'"
              :icon="Phone"
              title="SMS phone"
            />
          </DefinitionList>
          <DefinitionList>
            <DefinitionListItem
              :description="user.email ?? 'No email address'"
              :icon="Envelope"
              title="Email"
            />
            <DefinitionListItem
              v-if="user.roles?.length && user.roles[0].name === 'adviser'"
              :description="user.sms_email ?? 'No SMS email address'"
              title="SMS email"
            />
          </DefinitionList>
          <DefinitionList v-if="user.schools?.length && hasPermission('read-school')">
            <DefinitionListItem :icon="Building" title="Schools">
              <DefinitionListItemBadgeWrapper
                v-if="user.schools?.length"
                :badgeItems="
                  user.schools?.map((school) => ({
                    id: school?.id,
                    label: school?.name
                  })) ?? []
                "
                :buttonRoute="route('schools.index')"
                buttonLabel="Go to schools"
              />
            </DefinitionListItem>
          </DefinitionList>
          <DefinitionList v-if="user.programs?.length && hasPermission('read-program')">
            <DefinitionListItem :icon="Building" title="Programs">
              <DefinitionListItemBadgeWrapper
                v-if="user.programs?.length"
                :badgeItems="
                  user.programs?.map((program) => ({
                    id: program?.id,
                    label: program?.display_name
                  })) ?? []
                "
                :buttonRoute="route('programs.index')"
                buttonLabel="Go to programs"
              />
            </DefinitionListItem>
          </DefinitionList>
          <DefinitionList v-if="user.regions?.length && hasPermission('read-region')">
            <DefinitionListItem :icon="Building" title="Regions">
              <DefinitionListItemBadgeWrapper
                v-if="user.regions?.length"
                :badgeItems="
                  user.regions?.map((region) => ({
                    id: region?.id,
                    label: region?.display_name
                  })) ?? []
                "
                :buttonRoute="route('regions.index')"
                buttonLabel="Go to regions"
              />
            </DefinitionListItem>
          </DefinitionList>
          <DefinitionList>
            <DefinitionListItem
              :description="formatDateTime(user.last_login_at ?? null) || 'No login date'"
              :icon="UserCircle"
              title="Last Login"
            />
            <DefinitionListItem
              :description="
                formatDate(user.created_at, {
                  format: 'MM/dd/yyyy'
                }) || 'No created date'
              "
              title="Created"
            />
          </DefinitionList>
        </InformationCollapsible>
      </div>
      <div
        v-if="
          hasPermission('read-document-acknowledgements') && user?.document_acknowledgements?.length
        "
        class="-mx-4 p-4 pt-0"
        :class="{
          'border-b border-slate-200':
            (channels?.length && hasPermission('read-user-two-factor-details')) ||
            (!channels?.length && !hasPermission('read-user-two-factor-details'))
        }"
      >
        <InformationCollapsible title="Signed Documents">
          <DefinitionList>
            <DefinitionListItem
              v-for="(document, index) in user?.document_acknowledgements"
              :key="document.id"
              :icon="index === 0 ? FileCheck : undefined"
              :title="document.document"
              :description="formatDateTime(document.created_at) ?? 'No signed date'"
            />
          </DefinitionList>
        </InformationCollapsible>
      </div>
      <div class="-mx-4 p-4 pt-0">
        <InformationCollapsible
          v-if="
            (channels?.length && hasPermission('read-user-two-factor-details')) ||
            (!channels?.length && !hasPermission('read-user-two-factor-details'))
          "
          title="User 2FA Devices"
        >
          <DefinitionList>
            <DefinitionListItem
              v-if="
                (channels?.length && hasPermission('read-user-two-factor-details')) ||
                (!channels?.length && !hasPermission('read-user-two-factor-details'))
              "
              :icon="Building"
              title="2FA devices"
            >
              <div class="flex flex-col gap-y-2">
                <div
                  v-if="hasPermission('read-user-two-factor-details')"
                  class="flex flex-wrap gap-1"
                >
                  <Badge
                    v-for="channel in channels"
                    :iconLeft="getChannelBadgeIcon(channel.channelType)"
                    :label="`${channel.redactedDestination} | ${channel.channelTypeDisplayName}`"
                    :key="channel.id"
                    variant="soft"
                    size="md"
                  />
                </div>
                <div v-else class="flex flex-col items-start gap-1">
                  <BadgeBoolean
                    :boolean="hasSmsChannel"
                    labelTrue="Has SMS device"
                    labelFalse="Missing SMS device"
                  />
                  <BadgeBoolean
                    :boolean="hasEmailChannel"
                    labelTrue="Has email device"
                    labelFalse="Missing email device"
                  />
                </div>
              </div>
            </DefinitionListItem>
          </DefinitionList>
        </InformationCollapsible>
      </div>
    </div>
  </Slideout>
  <DangerDialog
    title="Move User to Trash"
    message="Are you sure you want to move this user to the trash?"
    confirmButtonLabel="Move to trash"
    :isOpen="isDialogOpen"
    :isLoading="form.processing"
    @onConfirm="handleUserDelete"
    @onCancel="toggleDialog"
    @onClose="toggleDialog"
  />
</template>
