<script setup lang="ts">
import type { HybridlyFormData } from '@/@types/global';

import { ButtonColor, ButtonVariant } from '@/hooks/useButtonClasses';

import Button from '@/components/button/Button.vue';
import DangerDialog from '@/components/dialog/DangerDialog.vue';
import Fieldset from '@/components/fieldset/Fieldset.vue';
import Form from '@/components/form/Form.vue';
import FormFooter from '@/components/formFooter/FormFooter.vue';
import FormHeader from '@/components/formHeader/FormHeader.vue';
import FormMultiUserInput from '@/components/formComboboxInput/FormMultiUserInput.vue';
import FormTextareaInput from '@/components/formTextareaInput/FormTextareaInput.vue';
import FormTextInput from '@/components/formTextInput/FormTextInput.vue';

const props = defineProps<{
  form: HybridlyFormData<{
    display_name: string | null;
    description: string | null;
    user_ids: number[];
  }>;
  isEditing?: boolean;
  returnUrl?: string;
}>();

const isCancelDialogOpen = ref(false);

const { onBackForward } = useBackForward();
// TODO: Fix intecepting the back button
onBackForward(() => {
  router.abort();
});

function handleToggleCancelDialog() {
  if (props.form.isDirty) {
    isCancelDialogOpen.value = !isCancelDialogOpen.value;
    return;
  }

  handleRedirect();
}

function handleRedirect() {
  router.navigate({ url: props.returnUrl || route('regions.index') });
}
</script>

<template>
  <Form @submit.prevent="form.submit">
    <template #header>
      <FormHeader
        canClose
        :title="`${isEditing ? 'Edit' : 'Add'} a Region`"
        :description="
          isEditing
            ? 'Use the form below to edit the region.'
            : 'Fill out the form below add a new region.'
        "
        @onClose="handleToggleCancelDialog"
      />
    </template>
    <Fieldset title="Region information">
      <FormTextInput
        type="text"
        name="name"
        label="Name"
        :modelValue="form.fields.display_name"
        :error="form.errors.display_name"
        @update:modelValue="(value) => (form.fields.display_name = value)"
      />
      <FormTextareaInput
        label="Description"
        name="description"
        :modelValue="form.fields.description"
        :error="form.errors.description"
        @update:modelValue="(value) => (form.fields.description = value)"
      />
      <FormMultiUserInput
        label="Regional Directors"
        :error="form.errors.director_ids"
        :modelValue="form.fields.director_ids"
        :searchFilters="{ role: 'regional-director' }"
        @update:modelValue="(value) => (form.fields.director_ids = value)"
      />
    </Fieldset>
    <template #footer>
      <FormFooter>
        <Button
          :color="ButtonColor.primary"
          :isDisabled="form.processing || !form.isDirty"
          :isLoading="form.processing"
          :variant="ButtonVariant.solid"
          type="submit"
          >Confirm</Button
        >
        <Button
          :color="ButtonColor.slate"
          :variant="ButtonVariant.outlined"
          type="button"
          @click="handleToggleCancelDialog"
        >
          Back to regions
        </Button>
      </FormFooter>
    </template>
  </Form>

  <DangerDialog
    :title="`Cancel ${isEditing ? 'editing' : 'adding a new'} region`"
    :message="`Are you sure you want to cancel ${
      isEditing ? 'editing' : 'adding'
    } this region? All ${isEditing ? 'changes' : 'information'} will be lost.`"
    :isOpen="isCancelDialogOpen"
    cancelButtonLabel="Stay on this page"
    confirmButtonLabel="Go back to regions"
    @onCancel="handleToggleCancelDialog"
    @onClose="handleToggleCancelDialog"
    @onConfirm="handleRedirect"
  />
</template>
