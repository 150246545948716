<script setup lang="ts">
import ProgramStudentFieldBetweenRules from '@/components/programStudentFields/ProgramStudentFieldBetweenRules.vue';
import ProgramStudentFieldInRules from '@/components/programStudentFields/ProgramStudentFieldInRules.vue';

const props = defineProps<{
  rule: App.Configuration.Data.FieldRuleData;
  errors: Record<string, string>;
  isEditing?: boolean;
}>();

const emit = defineEmits<{
  change: [key: string, value: unknown];
}>();

const rulesComponent = computed(() => {
  if (props.rule.field_rule_id === 'between:') {
    return ProgramStudentFieldBetweenRules;
  }
  if (props.rule.field_rule_id === 'in:') {
    return ProgramStudentFieldInRules;
  }
  return null;
});

function handleInputChange(parameters: unknown) {
  emit('change', props.rule.field_rule_id, parameters);
}
</script>

<template>
  <component
    v-if="rulesComponent"
    :is="rulesComponent"
    :rule="rule"
    :errors="errors"
    :isEditing="isEditing"
    @change="handleInputChange"
  />
</template>
