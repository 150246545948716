<script setup lang="ts">
import Check from '@/icons/line/check.svg';
</script>


					<script lang="ts">
					import __hybridly_layout_0 from '~/resources/domains/surveys/layouts/TwoColumns.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <div class="flex flex-col items-center gap-y-4 pt-8 text-center">
    <div class="flex size-13 items-center justify-center rounded-full bg-green-700">
      <Check class="size-9 fill-white" />
    </div>
    <h2 class="text-2xl font-bold leading-7 text-slate-800">Survey Complete</h2>
    <p class="text-sm leading-5 text-slate-500">
      Thank you for taking part in our survey.<br />
      You can now close this page.
    </p>
  </div>
</template>
