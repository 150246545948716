<script setup lang="ts" generic="T extends SelectableValue">
import type { Selectable, SelectableValue } from '@/components/selectBox/selectBox';

import { useSchoolsSearch } from '@/hooks/search/useSchoolsSearch';

import FormMultiComboboxInput, {
  FormMultiComboboxProps
} from '@/components/formComboboxInput/FormMultiComboboxInput.vue';

type Props = Omit<FormMultiComboboxProps<number>, 'options' | 'searchable'> & {
  programIds?: number[];
};

const props = defineProps<Props>();

defineEmits<{
  'update:modelValue': [Selectable<number>[]];
}>();

const { schoolOptions, isLoading, onQueryChange, updateSearchFilters } = useSchoolsSearch(
  props.searchFilters
);

watch(
  () => props.programIds,
  (programIds) => {
    updateSearchFilters({ program_ids: programIds });
  }
);
</script>

<template>
  <FormMultiComboboxInput
    v-bind="$props"
    :isLoading="isLoading"
    :options="schoolOptions"
    :onFilter="onQueryChange"
    @update:modelValue="(value) => $emit('update:modelValue', value)"
    @open="() => onQueryChange('')"
  />
</template>
