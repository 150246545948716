import { ChartjsDatasetItem } from '@/components/dashboard/data';

export interface KpiConfig {
  label: string;
  kpi: string;
}

export type MetricApiResult = App.Reports.Data.KpiMetricResultData[];

export type MetricConfig = {
  title: string;
  kpis: KpiConfig[];
  type: 'bar' | 'doughnut';
  byline?: string;
  size: 'sm' | 'md' | 'lg';
};

function isNumericValue(data: App.Reports.Data.KpiMetricResultData): boolean {
  return typeof data.distinct_students === 'number' || !isNaN(Number(data.distinct_students));
}

export function convertMetricToChartDataset(data: MetricApiResult): ChartjsDatasetItem[] {
  return data
    .filter((item) => isNumericValue(item))
    .map(({ available_students, ...d }) => ({
      ...d,
      percent: (d.percent ?? 0) as number,
      total: available_students as number,
      distinct_students: d.distinct_students as number
    }));
}

export function appendRemainderDataset(data: ChartjsDatasetItem[]): ChartjsDatasetItem[] {
  if (!data.length) {
    return data;
  }
  const firstItem = data[0];
  const remainder: ChartjsDatasetItem = {
    distinct_students: firstItem.total - firstItem.distinct_students,
    total: firstItem.total,
    percent: 1 - firstItem.percent,
    goal: null
  };
  return [firstItem, remainder];
}
