<script setup lang="ts">
import { debounce } from 'lodash';
import Distiller from '@/components/distiller/Distiller.vue';
import { AppliedFilter, AppliedFilterValue, AvailableFilter } from '@/components/distiller/types';
import { useFilters } from '@/hooks/useFilters';
import RelationFilterInput from '@/components/distiller/RelationFilterInput.vue';
import IconButton from '@/components/button/IconButton.vue';

import TrashIcon from '@/icons/line/trash.svg';
import { ButtonColor } from '@/hooks/useButtonClasses';
import FilterValueInput from './FilterValueInput.vue';
import FilterSelectInput from './FilterSelectInput.vue';
import OperatorSelectInput from './OperatorSelectInput.vue';
import FilterTreeIndicator from './FilterTreeIndicator.vue';
import { Selectable, SelectableValue } from '@/components/selectBox/selectBox';

type FilterOperator = App.Search.Enums.FilterOperator;

const props = withDefaults(
  defineProps<{
    parentPath?: string;
    filter?: AvailableFilter;
    availableFilters: AvailableFilter[];
    showTrashIcon?: boolean;
    isFirstNode?: boolean;
    isNested?: boolean;
    isDisabled?: boolean;
  }>(),
  {
    showTrashIcon: true,
    isNested: false,
    isDisabled: false
  }
);

const emit = defineEmits<{
  filterAdded: [];
  removeFilter: [];
}>();

const textInput = ref<{ inputEl: Ref<HTMLInputElement | null> | null }>({ inputEl: null });

onMounted(() => {
  if (textInput.value?.inputEl) {
    textInput.value.inputEl.focus();
  }
});

const {
  getAppliedFilter,
  addFilter,
  updateFilterOperator,
  updateFilterValue,
  removeFilter,
  hasAppliedFilter
} = useFilters();

const path = computed<string>(() => {
  if (props.filter) {
    return buildPath(props.filter.key);
  }
  if (props.parentPath) {
    return props.parentPath;
  }

  return '';
});

function buildPath(key: string): string {
  if (props.parentPath) {
    return `${props.parentPath}.${key}`;
  }

  return key;
}

const appliedFilter = computed(() => getAppliedFilter(path.value) as AppliedFilter);

const isReadonly = computed(() => appliedFilter.value?.readonly ?? false);

const hasAppliedFilterForFilterInput = computed(() => hasAppliedFilter(path.value));

const addingNewFilter = ref(
  !hasAppliedFilterForFilterInput.value &&
    ['has', 'does_not_have'].includes(appliedFilter.value?.operator ?? '')
);

const operator = computed<FilterOperator>(() => appliedFilter.value?.operator as FilterOperator);

const hasMultipleOperators = computed(() => (props.filter?.operators?.length ?? 0) > 1);

const usingSearchableRelationFilter = computed(() => {
  return (
    props.filter?.type === 'relation' && operator.value && ['in', 'not_in'].includes(operator.value)
  );
});

function replaceCurrentFilter(newPath: string, operator: FilterOperator, filterType?: string) {
  if (props.filter) {
    removeFilter(path.value);
  }

  nextTick(() => {
    let defaultValue: AppliedFilterValue = null;
    if (filterType === 'boolean') {
      defaultValue = true;
    }
    if (operator && ['in', 'not_in'].includes(operator)) {
      defaultValue = [];
    }

    addFilter(newPath, operator, defaultValue);

    // Fire this so the parent distiller knows to exit out of the "add new filter" state
    emit('filterAdded');
  });
}

const handleUpdateFilterValue = debounce((updatedValue: AppliedFilterValue) => {
  updateFilterValue(path.value, updatedValue);
}, 300);

onMounted(() => {
  if (!appliedFilter.value?.operator && props.filter) {
    updateFilterOperator(path.value, props.filter?.operators?.[0]?.value ?? null);
  }
});
</script>
<template>
  <div>
    <div class="flex w-full min-w-0 flex-row">
      <FilterTreeIndicator v-if="isNested" horizontal :firstNode="isFirstNode" />

      <div
        class="flex min-w-0 max-w-full flex-1 items-center justify-between gap-2 rounded-lg border border-slate-200 p-2"
        :class="{
          'bg-slate-100': props.filter?.type !== 'relation',
          'bg-slate-300': props.filter?.type === 'relation'
        }"
        :style="{
          '--tw-bg-opacity': Math.min(
            1,
            props.filter?.type === 'relation' ? path.split('.').length * 0.2 : 1
          )
        }"
      >
        <FilterSelectInput
          :path="path"
          :parentPath="parentPath"
          :filter="filter"
          :availableFilters="availableFilters"
          :isDisabled="isDisabled"
          :isReadonly="isReadonly"
          @replaceCurrentFilter="replaceCurrentFilter"
        />

        <div v-if="filter" class="flex w-full min-w-0 flex-1 items-center gap-2">
          <OperatorSelectInput
            v-if="hasMultipleOperators"
            :path="path"
            :parentPath="parentPath"
            :appliedFilter="appliedFilter"
            :filter="filter"
            :isDisabled="isDisabled"
            :isReadonly="isReadonly"
            @replaceCurrentFilter="replaceCurrentFilter"
          />

          <FilterValueInput
            v-if="filter"
            :path="path"
            :filter="filter"
            :appliedFilter="appliedFilter"
            :operator="operator"
            :isDisabled="isDisabled"
            :isReadonly="isReadonly"
          />
        </div>

        <IconButton
          v-if="showTrashIcon && !isDisabled && !isReadonly"
          :color="ButtonColor.slate"
          :icon="TrashIcon"
          ariaLabel="Remove filter"
          size="sm"
          variant="invisible"
          @click="$emit('removeFilter')"
        />
      </div>
    </div>

    <div v-if="filter?.relation?.filters" class="flex w-full min-w-0 flex-row bg-white">
      <FilterTreeIndicator
        vertical
        :class="{
          'ml-8': isNested
        }"
      />

      <div v-if="usingSearchableRelationFilter" class="flex w-full min-w-0 flex-row">
        <FilterTreeIndicator horizontal lastNode />

        <RelationFilterInput
          :filter="filter"
          :modelValue="appliedFilter?.value as unknown as Selectable<SelectableValue>[]"
          @update:modelValue="handleUpdateFilterValue"
          class="mt-3 w-full min-w-0 rounded-lg border border-slate-200 bg-slate-50 p-2"
        />
      </div>

      <Distiller
        v-else
        :addNewFilterOpen="addingNewFilter"
        gapClass="gap-2"
        :title="filter.title"
        :distiller="{
          name: filter.relation.name,
          filters: filter.relation.filters
        }"
        :path="path"
        :rootDistiller="false"
        :isDisabled="isDisabled"
      />
    </div>
  </div>
</template>
