<script setup lang="ts">
import { useId } from 'radix-vue';
import { RadioGroupIndicator, RadioGroupItem, RadioGroupRoot } from 'radix-vue';

interface RadioCardInput {
  icon?: Component;
  label: string;
  value: string;
}

export type RadioCardInputProps = {
  ariaLabel: string;
  hasError?: boolean;
  isDisabled?: boolean;
  name: string;
  radioInputs: RadioCardInput[];
  value?: string;
  direction?: 'horizontal' | 'vertical';
};

const props = defineProps<RadioCardInputProps>();

const computedRadioInputs = props.radioInputs.map((input) => ({
  ...input,
  id: useId()
}));

const emit = defineEmits<{
  'update:modelValue': [value: string];
}>();

function onModelValueChange(event: string) {
  emit('update:modelValue', event);
}
</script>

<template>
  <RadioGroupRoot
    class="flex flex-row gap-2.5"
    :ariaLabel="ariaLabel"
    :isDisabled="isDisabled"
    :modelValue="value"
    :forceMount="true"
    orientation="vertical"
    @update:modelValue="onModelValueChange"
  >
    <label
      v-for="radioInput in computedRadioInputs"
      :key="radioInput.id"
      class="flex w-full cursor-pointer items-center gap-2 rounded-lg border bg-white px-6 py-4 ring-secondary-900/20 transition-control duration-150 ease-in-out hover:cursor-pointer has-[[data-orientation=vertical]]:flex-col-reverse has-[[data-orientation=vertical]]:text-center has-[[data-state=checked]]:ring"
      :class="{
        'border-slate-200 hover:bg-secondary-50 has-[[data-state=checked]]:border-slate-500':
          !hasError,
        'ring-red-600/10 hover:bg-red-50': hasError,
        'data-[state=checked]:border-secondary-900 data-[state=unchecked]:border-slate-600':
          !hasError,
        'border-red-500': hasError
      }"
    >
      <RadioGroupItem
        class="h-4.5 w-4.5 shrink-0 cursor-default rounded-full border bg-white outline-none transition-control duration-150 ease-in-out hover:cursor-pointer focus-visible:ring focus-visible:ring-secondary-900/20 hover:data-[state=checked]:ring hover:data-[state=unchecked]:ring"
        :class="{
          'hover:data-[state=unchecked]:bg-secondary-50 hover:data-[state=checked]:ring-secondary-900/20 hover:data-[state=unchecked]:ring-secondary-900/20':
            !hasError,
          'hover:data-[state=unchecked]:bg-red-50 hover:data-[state=checked]:ring-red-600/10 hover:data-[state=unchecked]:ring-red-600/10':
            hasError,
          'data-[state=checked]:border-secondary-900 data-[state=unchecked]:border-slate-600':
            !hasError,
          'border-red-500': hasError
        }"
        :value="radioInput.value"
      >
        <RadioGroupIndicator
          forceMount
          class="relative flex h-full w-full items-center justify-center transition-opacity duration-150 ease-in-out after:block after:h-2.5 after:w-2.5 after:rounded-[50%] after:bg-secondary-900 after:content-[''] data-[state=checked]:opacity-100 data-[state=unchecked]:opacity-0"
        />
      </RadioGroupItem>
      <span class="text-sm leading-5 text-slate-700">{{ radioInput.label }}</span>
      <component :is="radioInput.icon" class="h-6 w-6 text-slate-500" />
    </label>
  </RadioGroupRoot>
</template>
