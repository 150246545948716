<script setup lang="ts">
import { buildSelectableSchool } from '@/utils/buildSelectable';

import CenteredContent from '@/components/content/CenteredContent.vue';
import StudentGroupForm from '@/components/studentGroups/StudentGroupForm.vue';

const props = defineProps<{
  returnUrl?: string;
  studentGroup: App.Students.Data.StudentGroupData;
}>();

const form = useForm({
  method: 'PUT',
  url: route('student-groups.update', { studentGroup: props.studentGroup.id }),
  fields: {
    name: props.studentGroup.name,
    school_id: buildSelectableSchool(props.studentGroup.school)
  },
  transform: (fields) => ({
    ...fields,
    school_id: fields.school_id?.value
  })
});
</script>


					<script lang="ts">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <CenteredContent>
    <StudentGroupForm :form :returnUrl isEditing />
  </CenteredContent>
</template>
