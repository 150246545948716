<script setup lang="ts">
import { AccordionRoot } from 'radix-vue';
import FormTextInput from '@/components/formTextInput/FormTextInput.vue';
import FormDateTimeInput from '@/components/formDateTimeInput/FormDateTimeInput.vue';
import ComposeMessageInput from '@/components/sms/ComposeMessageInput.vue';
import AutomatedOutboundMessageData = App.Sms.Data.AutomatedCampaigns.AutomatedOutboundMessageData;
import EditNodePanel from '@/components/automated-campaigns/nodePanels/EditNodePanel.vue';
import OutboundMessageFilterBuilder from '@/components/automated-campaigns/OutboundMessageFilterBuilder.vue';
import AutomatedOutboundMessageFilterData = App.Sms.Data.AutomatedCampaigns.AutomatedOutboundMessageFilterData;
import NodePanelAccordionHeader from '@/components/slideout/slideoutAccordion/SlideoutAccordionHeader.vue';
import NodePanelAccordionContent from '@/components/slideout/slideoutAccordion/SlideoutAccordionContent.vue';
import NodePanelAccordionItem from '@/components/slideout/slideoutAccordion/SlideoutAccordionItem.vue';
import DeleteOutboundMessageDialog from '@/components/automated-campaigns/dialogs/DeleteOutboundMessageDialog.vue';

const props = defineProps<{
  node: AutomatedOutboundMessageData;
  isPaused: boolean;
}>();

const form = useForm<{
  name?: string;
  scheduled_send_date: string;
  body: string;
  filters: AutomatedOutboundMessageFilterData[];
}>({
  method: 'PUT',
  url: route('sms.outbound-message.update', { message: props.node.id }),
  updateInitials: true,
  fields: {
    name: props.node.name ?? '',
    scheduled_send_date: props.node.scheduled_send_date,
    body: props.node.body ?? '',
    filters: props.node.filters ?? []
  },
  transform: (data) => ({
    ...data,
    filters: data.filters.map((filter) => ({
      filter_type: filter.filter_type?.value,
      value: filter.value?.value,
      filter_relations: filter.filter_relations?.map((relation) => relation.value) ?? null
    }))
  })
});

const userTimezone = computed(() => Intl.DateTimeFormat().resolvedOptions().timeZone);
</script>

<template>
  <DeleteOutboundMessageDialog :message="node">
    <template #default="{ openDialog }">
      <EditNodePanel
        title="Edit Outbound Message"
        style="height: calc(100% - 2rem)"
        :onSubmit="form.submit"
        :onDelete="() => openDialog()"
        :reset="form.reset"
        :isDirty="form.isDirty"
        :processing="form.processing"
        :isPaused="isPaused"
      >
        <AccordionRoot
          type="multiple"
          :defaultValue="['settings', 'compose']"
          class="flex flex-grow flex-col"
        >
          <NodePanelAccordionItem value="settings">
            <NodePanelAccordionHeader title="Message Settings" />
            <NodePanelAccordionContent>
              <FormTextInput
                :error="form.errors.name"
                :isDisabled="!isPaused"
                label="Message Name"
                v-model="form.fields.name"
                placeholder="Enter a name for this message"
                something="else"
              />

              <FormDateTimeInput
                v-if="node.nodeType === 'root'"
                :error="form.errors.scheduled_send_date"
                :isDisabled="!isPaused"
                label="Scheduled Send Date"
                v-model="form.fields.scheduled_send_date"
                placeholder="Select a date and time"
                :helperText="`The time selected is in the ${userTimezone} timezone.`"
              />

              <OutboundMessageFilterBuilder
                v-model="form.fields.filters"
                label="Filters"
                :isPaused="isPaused"
                :errors="form.errors.filters as unknown as Record<string, Record<string, string>>"
              />
            </NodePanelAccordionContent>
          </NodePanelAccordionItem>
          <NodePanelAccordionItem value="compose" class="flex flex-grow flex-col">
            <NodePanelAccordionHeader title="Message Body" />
            <NodePanelAccordionContent class="flex-grow">
              <ComposeMessageInput
                :error="form.errors.body"
                :isDisabled="!isPaused"
                v-model="form.fields.body"
                variant="campaign"
                :canUploadAttachments="false"
                :canScheduleMessage="false"
              />
            </NodePanelAccordionContent>
          </NodePanelAccordionItem>
        </AccordionRoot>
      </EditNodePanel>
    </template>
  </DeleteOutboundMessageDialog>
</template>
