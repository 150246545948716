<script setup lang="ts" generic="T extends Record<string, unknown>">
import type { TableColumn } from '@/components/table/Table';
import PaddedCellContent from '@/components/table/PaddedCellContent.vue';

import ArrowDown from '@/icons/line/arrow-down.svg';
import ArrowUp from '@/icons/line/arrow-up.svg';
import Sort from '@/icons/line/sort.svg';

const props = defineProps<{
  column: TableColumn<T>;
  currentSort?: string | null;
}>();

const emit = defineEmits<{
  sort: [columnKey: string | null];
}>();

const sortColumn = computed(() =>
  props.currentSort?.startsWith('-') ? props.currentSort.slice(1) : props.currentSort
);

const isDescending = computed(() => props.currentSort?.startsWith('-'));

const sortIcon = computed(() => {
  if (sortColumn.value !== props.column.key) {
    return Sort;
  }

  return isDescending.value ? ArrowDown : ArrowUp;
});

function handleSortChange(columnKey: string | null) {
  emit('sort', columnKey);
}

function handleClick() {
  if (!props.column.isSortable) {
    return;
  }

  if (sortColumn.value !== props.column.key) {
    handleSortChange(props.column.key);
    return;
  }

  if (isDescending.value) {
    handleSortChange(null);
    return;
  }

  handleSortChange(`-${props.currentSort}` as string);
}
</script>

<template>
  <button
    @click="handleClick"
    class="whitespace-nowrap uppercase"
    :style="[column.width ? `width: ${column.width};` : undefined]"
  >
    <PaddedCellContent>
      <div class="flex items-center gap-x-2">
        {{ column.label }}
        <component :is="sortIcon" class="h-5 w-5 text-slate-700 opacity-70" />
      </div>
    </PaddedCellContent>
  </button>
</template>
