<script setup lang="ts">
import ComboboxInput from '@/components/selectBox/combobox/ComboboxInput.vue';
import { Selectable } from '@/components/selectBox/selectBox';
import { useStudentParentsSearch } from '@/hooks/search/useStudentParentsSearch';

const { studentParentOptions, isLoading, onQueryChange } = useStudentParentsSearch();

defineProps<{
  modelValue: Selectable<number> | null;
  placeholder?: string;
  isDisabled?: boolean;
}>();

defineEmits<{
  'update:modelValue': [value: Selectable<number> | null];
}>();
</script>

<template>
  <ComboboxInput
    :options="studentParentOptions"
    :onFilter="onQueryChange"
    :isLoading="isLoading"
    :modelValue="modelValue"
    :placeholder="placeholder"
    :isDisabled="isDisabled"
    :highlightSearchTerm="true"
    @update:modelValue="$emit('update:modelValue', $event)"
    @open="() => onQueryChange('')"
  />
</template>
