<script setup lang="ts">
import Slideout from '@/components/slideout/Slideout.vue';
import EditUploadRecordHeader from '@/domains/bulkinator/components/uploadRecord/EditUploadRecordHeader.vue';
import EditUploadRecordMatchSection from '@/domains/bulkinator/components/uploadRecord/EditUploadRecordMatchSection.vue';
import EditUploadRecordForm from '@/domains/bulkinator/components/uploadRecord/EditUploadRecordForm.vue';
import EditUploadRecordRemoveButton from '@/domains/bulkinator/components/uploadRecord/EditUploadRecordRemoveButton.vue';

defineProps<{
  uploadRecord: App.Bulkinator.Data.UploadRecordData;
  uploadSession: App.Bulkinator.Data.UploadSessionData;
  uploadType: App.Bulkinator.Data.UploadTypeData;
  availableColumns: App.Bulkinator.Data.UploadColumnData[];
}>();

defineOptions({
  inheritAttrs: false
});

const { show, closeLocally } = useDialog();
</script>

<template>
  <Slideout
    title="Edit Upload Record"
    :isOpen="show"
    :isScrollable="false"
    :allowClickaway="false"
    @onClose="closeLocally"
  >
    <template #content>
      <div class="flex h-full flex-col">
        <EditUploadRecordHeader
          :uploadRecord="uploadRecord"
          :uploadType="uploadType"
          @close="closeLocally"
        />
        <EditUploadRecordMatchSection
          v-if="uploadType.is_matchable && !uploadSession.is_locked"
          :uploadRecord="uploadRecord"
          :uploadType="uploadType"
        />
        <EditUploadRecordForm
          :uploadRecord="uploadRecord"
          :uploadSession="uploadSession"
          :availableColumns="availableColumns"
        />
        <EditUploadRecordRemoveButton
          v-if="!uploadSession.is_locked"
          :uploadRecord="uploadRecord"
        />
      </div>
    </template>
  </Slideout>
</template>
