<script setup lang="ts" generic="T extends SelectableValue">
import type { Selectable, SelectableValue } from '@/components/selectBox/selectBox';

import FormListboxInput from '@/components/formListboxInput/FormListboxInput.vue';

const props = defineProps<{
  customField: App.Configuration.Data.ProgramStudentFieldData;
  error?: string;
  modelValue?: Selectable<T> | null;
}>();

const emit = defineEmits<{
  'update:modelValue': [Selectable<T> | null];
}>();

const computedFieldOptions = computed(() =>
  props.customField?.rules?.[0]?.parameters?.options
    ? props.customField.rules[0].parameters.options.map((item) => ({
        label: item,
        value: item
      }))
    : []
);

function onValueChange(value: Selectable<T> | null): void {
  emit('update:modelValue', value);
}
</script>

<template>
  <FormListboxInput
    :error
    :label="customField.field_label"
    :modelValue="modelValue"
    :options="computedFieldOptions"
    @update:modelValue="onValueChange"
  />
</template>
