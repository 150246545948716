<script setup lang="tsx">
import type { CustomCells, HybridlyRow } from '@/components/table/Table';

import { ButtonColor, ButtonSize, ButtonVariant } from '@/hooks/useButtonClasses';
import { useAuth } from '@/hooks/useAuth';
import { useFilters } from '@/hooks/useFilters';
import { useHybridlyTable } from '@/hooks/useHybridlyTable';
import { useTableRowClick } from '@/hooks/useTableRowClick';

import Button from '@/components/button/Button.vue';
import EmptyState from '@/components/emptyState/EmptyState.vue';
import IndexPageLayout from '@/components/pageLayout/IndexPageLayout.vue';
import TableBooleanCell from '@/components/table/TableBooleanCell.vue';
import TableDualTextCell from '@/components/table/TableDualTextCell.vue';
import TableMoneyCell from '@/components/table/TableMoneyCell.vue';
import TableEnumCell from '@/components/table/TableEnumCell.vue';
import TabNavDivider from '@/components/tabNav/TabNavDivider.vue';
import TabNavItem from '@/components/tabNav/TabNavItem.vue';
import TableEmptyCell from '@/components/table/TableEmptyCell.vue';
import BulkTrashButton from '@/components/table/BulkTrashButton.vue';
import BulkRestoreButton from '@/components/table/BulkRestoreButton.vue';
import { AppliedFilters } from '@/components/distiller/types';

import LayerGroup from '@/icons/line/layer-group.svg';
import Plus from '@/icons/line/plus.svg';
import TableTruncatedTextCell from '@/components/table/TableTruncatedTextCell.vue';

type InstituteRow = HybridlyRow<App.Configuration.Data.InstituteData>;

const props = defineProps<{
  institutes: Table<App.Configuration.Data.InstituteData>;
  appliedFilters: AppliedFilters;
  distiller: App.Search.Data.DistillerData;
  trashActive: boolean;
  counts: {
    active_count: number;
    trashed_count: number;
  };
}>();

const { hasAnyPermission, hasPermission } = useAuth();

const { table, tableSelectionState } = useHybridlyTable<App.Configuration.Data.InstituteData>(
  props,
  'institutes'
);
const { handleRowClick } = useTableRowClick();

const { encodeFilters } = useFilters();

const encodedFilters = computed(() => encodeFilters(props.appliedFilters));

const customCells = {
  name: ({ row, column }) => <TableTruncatedTextCell text={row.value(column.key)} />,
  hbcu: ({ row, column }) => <TableBooleanCell boolean={row.value(column.key) ?? false} />,
  program_length: ({ row, column }) => <TableEnumCell enumData={row.value(column.key)} />,
  selectivity: ({ row, column }) => <TableEnumCell enumData={row.value(column.key)} />,
  css_required: ({ row, column }) => <TableBooleanCell boolean={row.value(column.key) ?? false} />,
  address: ({ row, column }) => {
    const address = row.value(column.key);

    return address ? (
      <TableDualTextCell
        primaryText={address.split('\n')[0]}
        secondaryText={address.split('\n')[1]}
      />
    ) : (
      <TableEmptyCell />
    );
  },
  waiver_fee: ({ row, column }) => <TableMoneyCell value={row.value(column.key)} />
} as CustomCells<App.Configuration.Data.InstituteData>;

function handleInstituteRowClick(event: MouseEvent, row: InstituteRow) {
  const url = route('institutes.show', {
    institute: row.key
  });
  handleRowClick(url, event);
}
</script>


					<script lang="tsx">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <IndexPageLayout
    title="Institutions"
    :table="table"
    :appliedFilters="appliedFilters"
    :distiller="distiller"
    :customCells="customCells"
    :stickyEndColumns="1"
    :stickyStartColumns="hasAnyPermission(['create-institute', 'delete-institute']) ? 3 : 2"
    :selectionsEnabled="hasAnyPermission(['create-institute', 'delete-institute'])"
    :clickableRows="!trashActive"
    :tableSelectionState="tableSelectionState"
    @clickRow="($event, row) => handleInstituteRowClick($event, row as InstituteRow)"
  >
    <template #rightButtons v-if="hasPermission('create-institute')">
      <template v-if="tableSelectionState.hasSelection">
        <BulkTrashButton
          v-if="!trashActive"
          recordType="Institutions"
          :route="route('institutes.bulk-delete')"
          :tableSelectionState="tableSelectionState"
        />

        <BulkRestoreButton
          v-if="trashActive"
          recordType="Institutions"
          :route="route('institutes.bulk-restore')"
          :tableSelectionState="tableSelectionState"
        />
      </template>

      <template v-else>
        <Button
          v-if="hasPermission('create-institute')"
          :color="ButtonColor.slate"
          :href="route('upload-sessions.draft.index', { uploadType: 'institute' })"
          :iconLeft="LayerGroup"
          :size="ButtonSize.sm"
          :variant="ButtonVariant.soft"
        >
          Bulk add
        </Button>
        <Button
          v-if="hasPermission('create-institute')"
          :color="ButtonColor.primary"
          :href="route('institutes.create')"
          :iconLeft="Plus"
          :size="ButtonSize.sm"
          :variant="ButtonVariant.solid"
        >
          Add an institution
        </Button>
      </template>
    </template>

    <template #tabs>
      <TabNavItem
        :badgeLabel="counts.active_count"
        :isActive="!trashActive"
        label="Active"
        variant="inline"
        :href="
          route('institutes.index', {
            ...(encodedFilters ? { filters: encodedFilters } : {})
          })
        "
      />
      <template v-if="hasPermission('create-institute')">
        <TabNavDivider layout="inline" />
        <TabNavItem
          :badgeLabel="counts.trashed_count ?? 0"
          :isActive="trashActive"
          label="Trashed"
          variant="inline"
          :href="
            route('institutes.index', {
              trashed: true,
              ...(encodedFilters ? { filters: encodedFilters } : {})
            })
          "
        />
      </template>
    </template>

    <template #emptyState>
      <EmptyState
        v-if="trashActive"
        title="No institutions were moved to the trash"
        description="Trashed institutions will appear on this screen"
      />
      <EmptyState
        v-else
        title="No institutions found"
        :description="
          hasPermission('create-institute')
            ? 'Create a new institution by clicking the button below'
            : ''
        "
        :actionLabel="hasPermission('create-institute') ? 'Add an institution' : ''"
        :actionHref="route('institutes.create')"
      />
    </template>
  </IndexPageLayout>
</template>
