<script setup lang="ts">
type EnumData = App.Base.Data.EnumData;

import { buildSelectableInstitute } from '@/utils/buildSelectable';

import CenteredContent from '@/components/content/CenteredContent.vue';
import CollegeApplicationForm from '@/components/students/studentActions/CollegeApplicationForm.vue';

const props = defineProps<{
  application: App.Students.Data.ApplicationData;
  returnUrl?: string;
  statuses: EnumData[];
  student: App.Students.Data.StudentData;
}>();

const form = useForm({
  method: 'PUT',
  url: route('students.college-applications.update', {
    student: props.student.id,
    application: props.application.id
  }),
  fields: {
    institute_id: buildSelectableInstitute(props.application?.institute),
    date_submitted: props.application.date_submitted,
    status: props.application.status,
    intend_to_enroll: {
      label: props.application.intend_to_enroll ? 'Yes' : 'No',
      value: props.application.intend_to_enroll
    },
    has_waiver: {
      label: props.application.waiver ? 'Yes' : 'No',
      value: !!props.application.waiver
    },
    waiver: props.application.waiver,
    first_choice: props.application.first_choice,
    award_meeting_held: props.application.award_meeting_held,
    early_action: props.application.early_action,
    early_decision: props.application.early_decision,
    sent_hs_transcript: props.application.sent_hs_transcript,
    applied_to_honors_program: props.application.applied_to_honors_program,
    financial_aid_submitted_to_school: props.application.financial_aid_submitted_to_school
  },
  transform: (fields) => ({
    ...fields,
    institute_id: fields.institute_id?.value,
    status: fields.status?.value,
    intend_to_enroll: fields.intend_to_enroll?.value,
    has_waiver: fields.has_waiver?.value
  })
});
</script>


					<script lang="ts">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <CenteredContent>
    <CollegeApplicationForm :form :returnUrl :statuses :student isEditing />
  </CenteredContent>
</template>
