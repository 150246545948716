import { z } from 'zod';

export const BaseNotificationDataSchema = z.object({
  id: z.union([z.string(), z.number()]),
  title: z.string(),
  message: z.string(),
  link: z
    .object({
      text: z.string().optional(),
      href: z.string(),
      download: z.boolean().optional()
    })
    .nullable(),
  action: z
    .object({
      text: z.string(),
      data: z.any()
    })
    .nullable()
});

export type BaseNotificationData = z.infer<typeof BaseNotificationDataSchema>;
