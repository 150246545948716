<script setup lang="ts">
import type { ShowCustomTestProps } from '@/domains/configuration/views/custom-tests/ShowCustomTest.vue';

import { ButtonColor } from '@/hooks/useButtonClasses';
import { useAuth } from '@/hooks/useAuth';
import { useDialogHelpers } from '@/hooks/useDialogHelpers';

import { formatDateTime } from '@/utils/date';

import Badge from '@/components/badge/Badge.vue';
import Button from '@/components/button/Button.vue';
import DangerDialog from '@/components/dialog/DangerDialog.vue';
import DefinitionList from '@/components/definitionList/DefinitionList.vue';
import DefinitionListItem from '@/components/definitionList/DefinitionListItem.vue';
import IconButton from '@/components/button/IconButton.vue';
import InformationCollapsible from '@/components/collapsible/InformationCollapsible.vue';
import Slideout from '@/components/slideout/Slideout.vue';

import TrashIcon from '@/icons/line/trash.svg';
import Cell from '@/icons/line/cell.svg';
import EditAlt from '@/icons/line/edit-alt.svg';
import ExternalLinkAlt from '@/icons/line/external-link-alt.svg';
import FilesLandscapesAlt from '@/icons/line/files-landscapes-alt.svg';
import PlusCircle from '@/icons/line/plus-circle.svg';
import TachometerFastAlt from '@/icons/line/tachometer-fast-alt.svg';

const props = defineProps<ShowCustomTestProps>();

const { show, close } = useDialog();
const { isDialogOpen, toggleDialog } = useDialogHelpers();

const { hasPermission } = useAuth();

const form = useForm({
  method: 'DELETE',
  spoof: false,
  url: route('custom-tests.delete', { customTest: props.customTest.id }),
  fields: {},
  hooks: {
    success() {
      close();
    }
  }
});

async function handleCustomTestDelete() {
  await form.submit();
  toggleDialog();
}
</script>

<template>
  <Slideout
    :title="customTest.display_name"
    :isOpen="show"
    @onClose="close"
    :icon="FilesLandscapesAlt"
  >
    <template #actions>
      <IconButton
        v-if="customTest.authorization.update"
        :href="route('custom-tests.edit', { customTest: customTest.id })"
        :icon="EditAlt"
        ariaLabel="Edit custom test"
        size="sm"
        variant="soft"
      />
      <IconButton
        v-if="customTest.authorization.delete"
        color="danger"
        :icon="TrashIcon"
        ariaLabel="Move custom test to trash"
        size="sm"
        variant="soft"
        @click="toggleDialog"
      />
    </template>
    <InformationCollapsible title="Custom Test Information">
      <div class="flex flex-col gap-4">
        <DefinitionList>
          <DefinitionListItem :icon="Cell" title="Type">
            <Badge label="Custom Test" variant="soft" size="md" />
          </DefinitionListItem>
        </DefinitionList>
        <DefinitionList v-if="hasPermission('read-program')">
          <DefinitionListItem
            :description="!customTest.program ? 'No program' : undefined"
            :icon="FilesLandscapesAlt"
            title="Programs"
          >
            <div v-if="customTest.program" class="flex flex-col items-start gap-y-2">
              <Badge :label="customTest.program?.display_name" variant="soft" size="md" />
              <Button
                v-if="hasPermission('read-program')"
                :color="ButtonColor.slate"
                :href="route('programs.index')"
                :iconLeft="ExternalLinkAlt"
                size="xs"
                variant="outlined"
              >
                Go to programs list
              </Button>
            </div>
          </DefinitionListItem>
        </DefinitionList>
        <DefinitionList>
          <DefinitionListItem
            :description="customTest?.score ?? 'No score'"
            :icon="TachometerFastAlt"
            title="Score 1"
          />
          <DefinitionListItem :description="customTest?.score2 ?? 'No score'" title="Score 2" />
          <DefinitionListItem :description="customTest?.score3 ?? 'No score'" title="Score 3" />
          <DefinitionListItem :description="customTest?.score4 ?? 'No score'" title="Score 4" />
          <DefinitionListItem :description="customTest?.score5 ?? 'No score'" title="Score 5" />
        </DefinitionList>
        <DefinitionList>
          <DefinitionListItem
            :description="formatDateTime(customTest.created_at) || 'No create date'"
            :icon="PlusCircle"
            title="Created"
          />
          <DefinitionListItem
            :description="formatDateTime(customTest.updated_at) || 'No update date'"
            title="Updated"
          />
        </DefinitionList>
      </div>
    </InformationCollapsible>
  </Slideout>
  <DangerDialog
    title="Delete Custom Test"
    message="Are you sure you want to delete this custom test? This action is permanent and cannot be reverted."
    confirmButtonLabel="Delete"
    :isOpen="isDialogOpen"
    :isLoading="form.processing"
    @onConfirm="handleCustomTestDelete"
    @onCancel="toggleDialog"
    @onClose="toggleDialog"
  />
</template>
