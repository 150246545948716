<script setup lang="ts">
import { AppliedFilter, AvailableFilter } from '@/components/distiller/types';
import ListboxInput from '@/components/selectBox/listbox/ListboxInput.vue';
import { Selectable } from '@/components/selectBox/selectBox';
import { useFilters } from '@/hooks/useFilters';

type FilterOperator = App.Search.Enums.FilterOperator;

const props = defineProps<{
  path: string;
  parentPath?: string;
  appliedFilter: AppliedFilter;
  filter?: AvailableFilter;
  isDisabled?: boolean;
  isReadonly?: boolean;
}>();

const emit = defineEmits<{
  filterAdded: [];
  replaceCurrentFilter: [newPath: string, operator: FilterOperator, filterType?: string];
}>();

const { updateFilterOperator } = useFilters();

const addingNewFilter = ref(['has', 'does_not_have'].includes(props.appliedFilter?.operator ?? ''));

const operator = computed<FilterOperator>(() => props.appliedFilter?.operator as FilterOperator);

const operatorSelectable = computed(() => {
  return props.filter?.operators?.find(({ value }) => value === operator.value);
});

function handleUpdateRelationFilterOperator(updatedOperator: Selectable<FilterOperator> | null) {
  if (!updatedOperator) {
    return;
  }

  if (updatedOperator.value === 'has' || updatedOperator.value === 'does_not_have') {
    addingNewFilter.value = true;
  }

  emit('replaceCurrentFilter', props.path, updatedOperator.value, props.filter?.type);
}

function handleUpdateFilterOperator(updatedOperator: Selectable<FilterOperator> | null) {
  if (!updatedOperator) {
    return;
  }

  if (props.filter?.relation) {
    handleUpdateRelationFilterOperator(updatedOperator);
    return;
  }

  updateFilterOperator(props.path, updatedOperator?.value ?? null);
}
</script>

<template>
  <div class="w-36">
    <ListboxInput
      class="w-full"
      :modelValue="operatorSelectable"
      :options="filter?.operators || []"
      :isDisabled="!filter || isDisabled"
      :isReadonly="isReadonly"
      @update:modelValue="handleUpdateFilterOperator"
    />
  </div>
</template>
