<script setup lang="ts">
import Tooltip from '@/components/tooltip/Tooltip.vue';
import UploadRecordInput from './input/UploadRecordInput.vue';

const props = defineProps<{
  value: unknown;
  column: App.Bulkinator.Data.UploadColumnData;
  row: App.Bulkinator.Data.UploadRecordData;
  isLocked?: boolean;
}>();

const displaySuccess = ref(false);

const error = computed(() => {
  return props.row.validation_results?.[props.column.name]?.[0] ?? null;
});

const hasError = computed(() => {
  return error.value !== null;
});

function handleSuccess() {
  displaySuccess.value = true;
  setTimeout(() => {
    displaySuccess.value = false;
  }, 2000);
}
</script>

<template>
  <div
    class="relative h-full w-full p-1 transition-all duration-200"
    :class="{
      'bg-red-100': hasError,
      'bg-green-50': displaySuccess
    }"
  >
    <Tooltip side="top" showArrow :isDisabled="!hasError" triggerClasses="block h-full w-full flex">
      <template v-slot:trigger>
        <UploadRecordInput
          :value="value"
          :column="column"
          :row="row"
          :error="error"
          isInline
          :isLocked="isLocked"
          @success="handleSuccess"
        />
      </template>

      {{ error }}
    </Tooltip>
  </div>
</template>
