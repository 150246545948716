<script setup lang="ts">
import IndexThreads from '@/domains/sms/views/IndexThreads.vue';
import ThreadData = App.Sms.Data.ThreadData;
import RelatedThreadData = App.Sms.Data.RelatedThreadData;
import { WithLoadedRelations } from '@/@types/global';
import ThreadWithRelated from '@/components/sms/ThreadWithRelated.vue';
import { AppliedFilters } from '@/components/distiller/types';
import DistillerData = App.Search.Data.DistillerData;

defineProps<{
  hasSmsEnabled: boolean;
  threads: Paginator<WithLoadedRelations<ThreadData, 'recipient'>>;
  thread: WithLoadedRelations<ThreadData, 'recipient'>;
  distiller: DistillerData;
  appliedFilters: AppliedFilters;
  relatedThreads: RelatedThreadData[];
}>();
</script>


					<script lang="ts">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <IndexThreads
    :hasSmsEnabled="hasSmsEnabled"
    :threads="threads"
    :activeThreadId="thread.id"
    :distiller
    :appliedFilters
  >
    <ThreadWithRelated
      :hasSmsEnabled="hasSmsEnabled"
      :thread="thread"
      :relatedThreads="relatedThreads"
    />
  </IndexThreads>
</template>
