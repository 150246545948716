import axios from 'axios';

export type UploadSessionCountResponse = {
  new: number;
  pending: number;
  match: number;
  error: number;
};

export async function getUploadSessionCount(
  uploadSessionId: number
): Promise<UploadSessionCountResponse> {
  return axios
    .get(route('api.upload-sessions.count', { uploadSession: uploadSessionId }))
    .then((res) => res.data);
}
