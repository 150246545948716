<script setup lang="tsx">
import CreateParentGuardianSlideout from '@/components/studentParent/CreateParentGuardianSlideout.vue';

defineProps<{
  student: App.Students.Data.StudentData;
  existingParent: App.Students.Data.StudentParentData | null;
}>();

const { show, close } = useDialog();

function handleConfirmExportData() {
  close();
}
</script>


					<script lang="tsx">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <CreateParentGuardianSlideout
    :isOpen="show ?? false"
    :student="student"
    :existingParent="existingParent"
    @cancel="close"
    @close="close"
    @confirm="handleConfirmExportData"
  />
</template>
