<script setup lang="ts">
type EnumData = App.Base.Data.EnumData;
type ProgramStudentFieldData = App.Configuration.Data.ProgramStudentFieldData;

import { buildSelectableProgram } from '@/utils/buildSelectable';

import CenteredContent from '@/components/content/CenteredContent.vue';
import ProgramStudentFieldForm from '@/components/programStudentFields/ProgramStudentFieldForm.vue';

const props = defineProps<{
  programStudentField: ProgramStudentFieldData;
  returnUrl?: string;
  types: EnumData[];
}>();

const form = useForm({
  method: 'PUT',
  url: route('program-student-fields.update', {
    programStudentField: props.programStudentField.id
  }),
  fields: {
    field_label: props.programStudentField.field_label || '',
    type: props.programStudentField.type || {},
    program_id: buildSelectableProgram(props.programStudentField.program),
    enabled: props.programStudentField.enabled || false,
    in_results: props.programStudentField.in_results,
    rules: props.programStudentField.rules
  },
  transform: (fields) => ({
    ...fields,
    type: fields.type?.value,
    program_id: fields.program_id?.value ?? null
  })
});
</script>


					<script lang="ts">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <CenteredContent>
    <ProgramStudentFieldForm :form="form" :types="types" isEditing />
  </CenteredContent>
</template>
