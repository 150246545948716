<script setup lang="tsx" generic="T extends Record<string, unknown>">
import { ButtonColor, ButtonSize, ButtonVariant } from '@/hooks/useButtonClasses';

import Button from '@/components/button/Button.vue';

import DownloadAltIcon from '@/icons/line/download-alt.svg';

const props = defineProps<{
  survey: App.Surveys.Data.SurveyData;
}>();

const form = useForm({
  method: 'get',
  url: route('surveys.sessions.export', { survey: props.survey.id }),
  fields: {}
});

function downloadSubmissions() {
  form.submit();
}
</script>


					<script lang="tsx">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <Button
    :color="ButtonColor.slate"
    :iconLeft="DownloadAltIcon"
    :isLoading="form.processing"
    :size="ButtonSize.sm"
    :variant="ButtonVariant.outlined"
    @click="downloadSubmissions"
  >
    Download submissions
  </Button>
</template>
