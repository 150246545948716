import { debounce } from 'lodash';

import { buildSelectableCaseload } from '@/utils/buildSelectable';

import { search } from '@/api/CaseloadApi';

import { Selectable } from '@/components/selectBox/selectBox';

export function useCaseloadsSearch(searchFilters?: Record<string, unknown>) {
  const searchQuery = ref('');
  const isLoading = ref(false);
  const caseloadOptions = ref<Selectable<number>[]>([]);
  const localSearchFilters = ref(searchFilters);

  const searchCaseloads = debounce(async (query: string) => {
    const response = await search({
      filters: {
        ...localSearchFilters.value,
        search: query
      }
    });

    caseloadOptions.value = response.map((caseload) =>
      buildSelectableCaseload(caseload)
    ) as Selectable<number>[];

    isLoading.value = false;
  }, 300);

  function updateSearchFilters(searchFilters?: Record<string, unknown>) {
    localSearchFilters.value = searchFilters;
    searchCaseloads(searchQuery.value);
  }

  function onQueryChange(query: string): void {
    isLoading.value = true;
    searchQuery.value = query;

    searchCaseloads(query);
  }

  return {
    caseloadOptions,
    searchCaseloads,
    isLoading,
    onQueryChange,
    updateSearchFilters
  };
}
