<script setup lang="tsx">
import UploadSessionSettingsForm from '@/domains/bulkinator/components/forms/UploadSessionSettingsForm.vue';
import UploadSessionPrepareExistingDataForm from '@/domains/bulkinator/components/forms/UploadSessionPrepareExistingDataForm.vue';
import IconButton from '@/components/button/IconButton.vue';
import GuidedSteps from '@/components/guidedSteps/GuidedSteps.vue';
import GuidedStepsContainer from '@/components/guidedSteps/GuidedStepsContainer.vue';
import PageHeader from '@/components/pageHeader/PageHeader.vue';
import Times from '@/icons/line/times.svg';
import StepContainer from '@/components/stepContainer/StepContainer.vue';
import {
  defaultCreateFormValues,
  transformCreateFormValues,
  useUploadSessionFormWizard
} from '@/domains/bulkinator/components/forms/useUploadSessionFormWizard';
import { AppliedFilters } from '@/components/distiller/types';

const props = defineProps<{
  uploadType: App.Bulkinator.Data.UploadTypeData;
  defaultFilters: AppliedFilters;
  distiller: App.Search.Data.DistillerData;
  testType?: App.Base.Data.EnumData;
}>();

const { form, guidedSteps, steps, currentStep } = useUploadSessionFormWizard(
  {
    config: {
      prepare: {
        label: 'Prepare',
        description: 'Select existing data needed',
        subtitle: undefined
      },
      create: {
        label: 'Create',
        description: 'Set and create upload session',
        subtitle: 'Almost done'
      }
    },
    order: ['prepare', 'create']
  },
  {
    method: 'POST',
    url: route('upload-sessions.existing-data.store'),
    fields: {
      filters: props.defaultFilters,
      ...defaultCreateFormValues({
        uploadType: props.uploadType.name,
        testType: props.testType?.value
      })
    },
    transform: (v) => ({ ...transformCreateFormValues(props.uploadType)(v), filters: v.filters }),
    updateInitials: true
  }
);

function handleClose() {
  router.navigate({
    url: route('upload-sessions.create', { uploadType: props.uploadType.id })
  });
}
</script>


					<script lang="tsx">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <PageHeader title="Start from existing data in the system" :centered="true">
    <template #rightButtons>
      <IconButton
        :icon="Times"
        @click="handleClose"
        size="sm"
        variant="invisible"
        ariaLabel="Cancel empty session creation"
      />
    </template>
  </PageHeader>

  <GuidedStepsContainer class="border-y px-20">
    <GuidedSteps
      :steps="steps"
      :currentStep="guidedSteps.index.value"
      direction="horizontal"
      :showStepNumber="false"
      :showBadge="false"
      class="mx-auto"
    />
  </GuidedStepsContainer>

  <StepContainer :title="currentStep.description" :description="currentStep.subtitle">
    <section class="mx-auto max-w-2xl">
      <UploadSessionPrepareExistingDataForm
        v-show="guidedSteps.isCurrent('prepare')"
        :uploadType="uploadType"
        :distiller="distiller"
        :form="form"
        @submit="guidedSteps.goToNext()"
        @cancel="handleClose"
      />
      <UploadSessionSettingsForm
        v-show="guidedSteps.isCurrent('create')"
        :uploadType="uploadType"
        :form="form"
        @submit="form.submit"
        @cancel="guidedSteps.goToPrevious()"
      />
    </section>
  </StepContainer>
</template>
