import axios from 'axios';

export function previewUrlForImageFile(file: File): string {
  return URL.createObjectURL(file);
}

export function useFileUpload() {
  async function uploadFile(file: File, path: string, isPublicFile: boolean) {
    const formData = new FormData();
    formData.append('path', path);
    formData.append('is_public_file', isPublicFile ? '1' : '0');
    formData.append('file', file);

    return axios.post(route('api.files.upload'), formData).then((res) => res.data);
  }

  return {
    previewUrlForImageFile,
    uploadFile
  };
}
