export function useVariants<T extends NonNullable<string>>(variant: T) {
  function variety(...variants: NonNullable<T>[]): boolean {
    return variants.includes(variant);
  }

  function whenVariety(variants: NonNullable<T>[] | NonNullable<T>, then: unknown) {
    if (!Array.isArray(variants)) {
      variants = [variants];
    }

    if (variants.includes(variant)) {
      return then;
    }
  }

  return { variety, whenVariety };
}
