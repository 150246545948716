<script setup lang="ts">
import type { ShowSuccessStoryProps } from '@/domains/success-stories/views/ShowSuccessStory.vue';

import { formatDate } from '@/utils/date';

import { useAuth } from '@/hooks/useAuth';
import { useDialogHelpers } from '@/hooks/useDialogHelpers';

import DangerDialog from '@/components/dialog/DangerDialog.vue';
import IconButton from '@/components/button/IconButton.vue';
import InformationCollapsible from '@/components/collapsible/InformationCollapsible.vue';
import Slideout from '@/components/slideout/Slideout.vue';
import DefinitionList from '@/components/definitionList/DefinitionList.vue';
import DefinitionListItem from '@/components/definitionList/DefinitionListItem.vue';
import RichContent from '@/components/richTextInput/RichContent.vue';

import TrashIcon from '@/icons/line/trash.svg';
import AwardAlt from '@/icons/line/award-alt.svg';
import Building from '@/icons/line/building.svg';
import Calendar from '@/icons/line/calendar.svg';
import DocumentLayoutLeft from '@/icons/line/document-layout-left.svg';
import EditAlt from '@/icons/line/edit-alt.svg';
import UserCircle from '@/icons/line/user-circle.svg';
import IconGraduationCap from '@/icons/line/graduation-cap.svg';
import IconUsersAlt from '@/icons/line/users-alt.svg';

const props = defineProps<ShowSuccessStoryProps>();

const { show, close } = useDialog();
const { isDialogOpen, toggleDialog } = useDialogHelpers();

const { hasPermission } = useAuth();

const form = useForm({
  method: 'DELETE',
  spoof: false,
  url: route('success-stories.delete', { successStory: props.successStory.id }),
  fields: {},
  hooks: {
    success() {
      close();
    }
  }
});

async function handleSuccessStoryDelete() {
  await form.submit();
  toggleDialog();
}
</script>

<template>
  <Slideout
    :title="successStory.title ?? 'Success Story Information'"
    :isOpen="show"
    @onClose="close"
    :icon="AwardAlt"
  >
    <template #actions>
      <IconButton
        v-if="successStory.authorization.update"
        :href="route('success-stories.edit', { successStory: successStory.id })"
        :icon="EditAlt"
        ariaLabel="Edit success story"
        size="sm"
        variant="soft"
      />
      <IconButton
        v-if="successStory.authorization.delete"
        color="danger"
        :icon="TrashIcon"
        ariaLabel="Move success story to trash"
        size="sm"
        variant="soft"
        @click="toggleDialog"
      />
    </template>
    <InformationCollapsible title="Success Story Information">
      <DefinitionList>
        <DefinitionListItem
          :description="!successStory.content_html ? 'No content' : undefined"
          :icon="DocumentLayoutLeft"
          title="Content"
        >
          <RichContent v-if="successStory.content_html" :html="successStory.content_html" />
        </DefinitionListItem>
        <DefinitionListItem
          v-if="hasPermission('read-school')"
          :description="!successStory.school ? 'No school' : successStory.school.name"
          :icon="Building"
          title="School"
        />
        <DefinitionListItem
          :description="!successStory.student ? 'No student' : successStory.student.name"
          :icon="IconGraduationCap"
          title="Student"
        />
        <DefinitionListItem
          :description="successStory.is_parent ? 'Yes' : 'No'"
          :icon="IconUsersAlt"
          title="About student's parent/guardian"
        />
        <DefinitionListItem
          :description="successStory.user?.name ?? 'No user'"
          :icon="UserCircle"
          title="Created By"
        />
        <DefinitionListItem
          :description="
            formatDate(successStory.created_at, {
              format: 'MM/dd/yyyy'
            }) || 'No created date'
          "
          :icon="Calendar"
          title="Created"
        />
      </DefinitionList>
    </InformationCollapsible>
  </Slideout>
  <DangerDialog
    title="Move Success Story to Trash"
    message="Are you sure you want to move this success story to the trash?"
    confirmButtonLabel="Move to trash"
    :isOpen="isDialogOpen"
    :isLoading="form.processing"
    @onConfirm="handleSuccessStoryDelete"
    @onCancel="toggleDialog"
    @onClose="toggleDialog"
  />
</template>
