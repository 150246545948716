<script setup lang="ts">
import {
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuPortal,
  DropdownMenuRoot,
  DropdownMenuTrigger
} from 'radix-vue';
import MegaphoneIcon from '@/icons/line/megaphone.svg';
import CalendarIcon from '@/icons/line/calendar.svg';
import AngleDownIcon from '@/icons/line/angle-down.svg';
import CommentsIcon from '@/icons/line/comments.svg';
import SelectBoxItem from '@/components/selectBox/SelectBoxItem.vue';
import { useAuth } from '@/hooks/useAuth';

const { hasPermission } = useAuth();

function onSelectBoxItemClick(path: 'campaigns' | 'threads' | 'scheduled') {
  switch (path) {
    case 'campaigns':
      router.get(route('sms.campaigns.index'));
      break;
    case 'threads':
      router.get(route('sms.threads.index'));
      break;
    case 'scheduled':
      router.get(route('sms.scheduled-messages.index'));
      break;
  }
}

const { current } = useRoute();

const title = computed(() => {
  return (
    {
      'sms.campaigns.index': 'Campaigns',
      'sms.scheduled-messages.index': 'Scheduled',
      'sms.outbound-message-override.edit': 'Scheduled',
      'sms.outbound-message-override.create': 'Scheduled',
      'sms.threads.index': 'Threads'
    }[current.value ?? ''] ?? 'Threads'
  );
});
</script>

<template>
  <DropdownMenuRoot>
    <DropdownMenuTrigger
      :class="[
        'flex items-center space-x-1 rounded-lg px-2 py-1.5',
        'hover:bg-zinc-100',
        'focus:ring-2 focus:ring-slate-300 data-[state=open]:bg-slate-100 data-[state=open]:ring-2 data-[state=open]:ring-slate-300'
      ]"
    >
      <h2 class="truncate text-xl font-semibold leading-6 text-zinc-800">{{ title }}</h2>
      <AngleDownIcon class="h-5 w-5 text-zinc-500" />
    </DropdownMenuTrigger>

    <DropdownMenuPortal>
      <DropdownMenuContent
        align="start"
        class="mt-1 space-y-0.5 rounded-lg border border-zinc-300 bg-white px-2 py-3 text-sm text-zinc-700 shadow-md"
      >
        <SelectBoxItem
          :as="DropdownMenuItem"
          class="flex cursor-pointer items-center"
          @click="onSelectBoxItemClick('threads')"
        >
          <CommentsIcon aria-hidden="true" class="h-4 text-zinc-400" />
          Threads
        </SelectBoxItem>
        <SelectBoxItem
          :as="DropdownMenuItem"
          class="flex cursor-pointer items-center"
          @click="onSelectBoxItemClick('scheduled')"
        >
          <CalendarIcon aria-hidden="true" class="h-4 text-zinc-400" />
          Scheduled
        </SelectBoxItem>
        <SelectBoxItem
          v-if="hasPermission('read-sms-campaign')"
          :as="DropdownMenuItem"
          class="flex cursor-pointer items-center"
          @click="onSelectBoxItemClick('campaigns')"
        >
          <MegaphoneIcon aria-hidden="true" class="h-4 text-zinc-400" />
          Campaigns
        </SelectBoxItem>
      </DropdownMenuContent>
    </DropdownMenuPortal>
  </DropdownMenuRoot>
</template>
