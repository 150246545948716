<script setup lang="tsx">
import type { CustomCells, HybridlyRow } from '@/components/table/Table';

import { ButtonColor, ButtonSize, ButtonVariant } from '@/hooks/useButtonClasses';
import { useAuth } from '@/hooks/useAuth';
import { useFilters } from '@/hooks/useFilters';
import { useHybridlyTable } from '@/hooks/useHybridlyTable';
import { useTableRowClick } from '@/hooks/useTableRowClick';

import Button from '@/components/button/Button.vue';
import EmptyState from '@/components/emptyState/EmptyState.vue';
import IndexPageLayout from '@/components/pageLayout/IndexPageLayout.vue';
import TableBadgesCell from '@/components/table/TableBadgesCell.vue';
import TabNavDivider from '@/components/tabNav/TabNavDivider.vue';
import TabNavItem from '@/components/tabNav/TabNavItem.vue';
import BulkTrashButton from '@/components/table/BulkTrashButton.vue';
import BulkRestoreButton from '@/components/table/BulkRestoreButton.vue';
import { AppliedFilters } from '@/components/distiller/types';

import FilesLandscapeAlt from '@/icons/line/files-landscapes-alt.svg';
import UserCircle from '@/icons/line/user-circle.svg';
import Plus from '@/icons/line/plus.svg';
import TableTruncatedTextCell from '@/components/table/TableTruncatedTextCell.vue';

type RegionRow = HybridlyRow<App.Configuration.Data.RegionData>;

const props = defineProps<{
  regions: Table<App.Configuration.Data.RegionData>;
  appliedFilters: AppliedFilters;
  distiller: App.Search.Data.DistillerData;
  trashActive: boolean;
  counts: {
    active_count: number;
    trashed_count: number;
  };
}>();

const { hasPermission } = useAuth();

const { table, tableSelectionState } = useHybridlyTable<App.Configuration.Data.RegionData>(
  props,
  'regions'
);
const { handleRowClick } = useTableRowClick();

const { encodeFilters } = useFilters();

const encodedFilters = computed(() => encodeFilters(props.appliedFilters));

const customCells = {
  display_name: ({ row, column }) => <TableTruncatedTextCell text={row.value(column.key)} />,
  users: ({ row, column }) => (
    <TableBadgesCell
      badgesToDisplay={2}
      itemIcon={UserCircle}
      items={row.value(column.key).map((user: App.Auth.Data.UserData) => user.name)}
    />
  ),
  programs: ({ row, column }) => (
    <TableBadgesCell
      badgesToDisplay={2}
      itemIcon={FilesLandscapeAlt}
      items={row
        .value(column.key)
        .map((program: App.Configuration.Data.ProgramData) => program.display_name)}
    />
  )
} as CustomCells<App.Configuration.Data.RegionData>;

function handleRegionRowClick(event: MouseEvent, row: RegionRow) {
  const url = route('regions.show', {
    region: row.key
  });
  handleRowClick(url, event);
}
</script>


					<script lang="tsx">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <IndexPageLayout
    title="Regions"
    :table="table"
    :appliedFilters="appliedFilters"
    :distiller="distiller"
    :customCells="customCells"
    :stickyEndColumns="1"
    :stickyStartColumns="1"
    :clickableRows="!trashActive"
    :tableSelectionState="tableSelectionState"
    @clickRow="($event, row) => handleRegionRowClick($event, row as RegionRow)"
  >
    <template #rightButtons>
      <template v-if="tableSelectionState.hasSelection">
        <BulkTrashButton
          v-if="!trashActive"
          recordType="Regions"
          :route="route('regions.bulk-delete')"
          :tableSelectionState="tableSelectionState"
        />

        <BulkRestoreButton
          v-if="trashActive"
          recordType="Regions"
          :route="route('regions.bulk-restore')"
          :tableSelectionState="tableSelectionState"
        />
      </template>

      <template v-else>
        <Button
          v-if="hasPermission('create-region')"
          :color="ButtonColor.primary"
          :href="route('regions.create')"
          :iconLeft="Plus"
          :size="ButtonSize.sm"
          :variant="ButtonVariant.solid"
        >
          Add a region
        </Button>
      </template>
    </template>

    <template #tabs>
      <TabNavItem
        :badgeLabel="counts.active_count"
        :isActive="!trashActive"
        label="Active"
        variant="inline"
        :href="
          route('regions.index', {
            ...(encodedFilters ? { filters: encodedFilters } : {})
          })
        "
      />
      <TabNavDivider layout="inline" />
      <TabNavItem
        :badgeLabel="counts.trashed_count ?? 0"
        :isActive="trashActive"
        label="Trashed"
        variant="inline"
        :href="
          route('regions.index', {
            trashed: true,
            ...(encodedFilters ? { filters: encodedFilters } : {})
          })
        "
      />
    </template>

    <template #emptyState>
      <EmptyState
        v-if="trashActive"
        title="No regions were moved to the trash"
        description="Trashed regions will appear on this screen"
      />
      <EmptyState
        v-else
        title="No regions found"
        :description="
          hasPermission('create-region') ? 'Create a new region by clicking the button below' : ''
        "
        :actionLabel="hasPermission('create-region') ? 'Add a region' : ''"
        :actionHref="route('regions.create')"
      />
    </template>
  </IndexPageLayout>
</template>
