<script setup lang="ts" generic="T extends Record<string, unknown>">
import type { TableColumn } from './Table';
import PaddedCellContent from './PaddedCellContent.vue';

const props = defineProps<{
  column: TableColumn<T>;
  row: T & { value?: (key: string) => unknown };
}>();

const cellContent = computed(() => {
  if (props.column.cellRender) {
    return props.column.cellRender({ row: props.row, column: props.column });
  }

  return h(PaddedCellContent, () => {
    if (props.column.value) {
      return props.column.value({ row: props.row });
    }
    if (props.row.value) {
      return props.row.value(props.column.key);
    }
    return '';
  });
});
</script>

<template>
  <td
    class="h-px truncate text-sm leading-5 text-slate-700"
    :class="{
      'text-center': column.align === 'center',
      'text-right': column.align === 'right'
    }"
  >
    <component
      :is="cellContent"
      :style="{
        width: column.width ?? undefined,
        'min-width': column.minWidth ?? undefined,
        'max-width': column.maxWidth ?? undefined
      }"
    />
  </td>
</template>

<style scoped>
@-moz-document url-prefix() {
  td {
    @apply h-full;
  }
}
</style>
