<script setup lang="ts">
import {
  FormControlFieldProps,
  FormControlFieldSlots
} from '@/components/formControlField/FormControlField';
import type { SwitchInputProps } from '@/components/switchInput/SwitchInput.vue';

import SwitchInput from '@/components/switchInput/SwitchInput.vue';
import FormControlField from '@/components/formControlField/FormControlField.vue';
import { useFormField } from '@/components/formField/useFormField';

type FormSwitchInputProps = Omit<SwitchInputProps, 'hasError'> & FormControlFieldProps;

const props = defineProps<FormSwitchInputProps>();
defineSlots<FormControlFieldSlots>();

const emit = defineEmits<{
  'update:checked': [value: boolean];
}>();

function onCheckedChange(event: boolean) {
  emit('update:checked', event);
}

const { formFieldProps, slots } = useFormField({ props });
</script>

<template>
  <FormControlField v-bind="formFieldProps">
    <SwitchInput
      :checked="checked"
      :hasError="formFieldProps.hasError"
      :isDisabled="isDisabled"
      :name="name"
      @update:checked="onCheckedChange"
    />
    <template v-for="slot of slots" #[slot]>
      <slot :name="slot" />
    </template>
  </FormControlField>
</template>
