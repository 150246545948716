<script setup lang="ts">
type EnumData = App.Base.Data.EnumData;

import EmptyState from '@/components/emptyState/EmptyState.vue';
import IconButton from '@/components/button/IconButton.vue';
import ShowStudentPageLayout from '@/components/students/ShowStudentPageLayout.vue';
import StudentInteractionCollapsibleCard from '@/components/students/StudentInteractionCollapsibleCard.vue';
import StudentInteractionCounts from '@/components/students/StudentInteractionCounts.vue';

import Plus from '@/icons/line/plus.svg';

defineProps<{
  student: App.Students.Data.StudentData;
  types: EnumData[];
  counts: {
    '1_on_1': number;
    group: number;
    parent: number;
    other: number;
  };
}>();
</script>


					<script lang="ts">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <ShowStudentPageLayout :student="student" :title="student.name">
    <template #subNav>
      <div class="flex flex-wrap items-center gap-2.5">
        <h2 class="text-xl font-semibold leading-5">Interactions List</h2>
        <div class="order-4 grow basis-full overflow-x-auto lg:order-none lg:basis-auto">
          <StudentInteractionCounts :counts="counts" />
        </div>
        <div class="ml-auto lg:pl-3.5">
          <IconButton
            :href="route('students.interactions.create', { student: student.id })"
            :icon="Plus"
            ariaLabel="Add interaction"
            variant="soft"
          />
        </div>
      </div>
    </template>

    <div v-if="student.interactions?.length" class="flex flex-col gap-2 p-4">
      <StudentInteractionCollapsibleCard
        v-for="interaction in student.interactions"
        :interaction="interaction"
        :student="student"
        :key="interaction.id"
      />
    </div>
    <EmptyState
      v-else
      title="No interactions were added"
      description="Add a new interaction by clicking the button below"
      actionLabel="New interaction"
      :actionHref="route('students.interactions.create', { student: student.id })"
    />
  </ShowStudentPageLayout>
</template>
