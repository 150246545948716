<script setup lang="ts">
const props = defineProps<{
  src: string;
}>();

const iframeContainer = ref(null as HTMLElement | null);

onMounted(() => {
  const iframe = document.createElement('iframe');
  iframe.src = props.src;
  iframe.width = '100%';
  iframe.height = '100%';
  iframe.frameBorder = '0';
  iframe.scrolling = '0';
  iframe.style.border = 'none';
  iframe.style.background = 'white';
  iframe.style.overflow = 'hidden';
  iframe.style.height = '100%';
  iframeContainer.value?.appendChild(iframe);
});
</script>


					<script lang="ts">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <div ref="iframeContainer" style="height: 100%"></div>
</template>
