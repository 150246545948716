<script setup lang="ts">
import Badge from '@/components/badge/Badge.vue';
import { NumberVisualization } from './data';

interface Props {
  format: NumberVisualization;
  goal: App.Reports.Data.GoalResultData | null;
  total?: number;
}

const props = defineProps<Props>();

const remainder = computed(() => {
  if (!props.goal || !props.total || props.goal.complete) {
    return null;
  }

  const remainderPercentage = Math.max(0, props.goal.percent - props.goal.actual);
  if (props.format === NumberVisualization.Sum) {
    return String(Math.round(remainderPercentage * props.total));
  }

  return `${Math.round(remainderPercentage * 100)}%`;
});
</script>

<template>
  <Badge v-if="!!remainder" as="span" color="warning" size="xs">{{ remainder }} remaining</Badge>
</template>
