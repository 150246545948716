<script setup lang="ts">
import { groupBy } from 'lodash';
import Collapsible from '@/components/collapsible/Collapsible.vue';
import RecipientCard from './RecipientCard.vue';
import RecipientsEmptyState from '@/components/emptyState/RecipientsEmptyState.vue';
import RecipientSearch from './RecipientSearch.vue';
import Alert from '@/components/alert/Alert.vue';
import {
  getRecipientTypeLabel,
  pluralizeRecipientTypeLabel
} from '@/domains/sms/utils/recipientTypeFormatter';
import { isEqual } from 'lodash';

type RecipientData =
  | App.Sms.Data.SmsRecipientData
  | App.Sms.Data.AutomatedCampaigns.AutomatedOutboundMessageOverrideRecipientData;

const props = defineProps<{
  recipients: RecipientData[];
  errors: Record<string, unknown>;
  readonly?: boolean;
}>();

const emit = defineEmits<{
  (e: 'selectRecipient', recipient: RecipientData): void;
  (e: 'removeRecipient', recipient: RecipientData): void;
}>();

const duplicateError = ref<string | null>(null);

function handleSelectRecipient(recipient: App.Sms.Data.SmsRecipientData) {
  const existingRecipient = props.recipients.find((existingRecipient) => {
    return isEqual(recipient, existingRecipient);
  });

  if (existingRecipient) {
    duplicateError.value = 'Recipient already added.';
    return;
  }
  emit('selectRecipient', recipient as RecipientData);
}

const groupedRecipients = computed(() => {
  return groupBy(props.recipients, 'recipient_type');
});

function recipientErrors(recipient: RecipientData): App.Sms.Data.SmsRecipientValidationData[] {
  const errors = (props.errors['recipient-errors'] ??
    []) as App.Sms.Data.SmsRecipientValidationData[];

  return errors?.filter((error) => {
    // Check if phone number recipient has an error
    if (recipient.phone_number && error.recipient.phone_number === recipient.phone_number) {
      return true;
    }

    // Check if recipient has an error
    if (
      recipient.recipient_id &&
      error.recipient.recipient_id === recipient.recipient_id &&
      error.recipient.recipient_type === recipient.recipient_type
    ) {
      return true;
    }

    // Check if student recipient has an error for their parent
    if (
      error.recipient.student_id &&
      error.recipient.student_id === recipient.recipient_id &&
      error.recipient.recipient_type === 'student_parent' &&
      recipient.recipient_type === 'student'
    ) {
      return true;
    }

    // Check if parent recipient has an error for their student
    if (
      error.recipient.parent_id &&
      error.recipient.parent_id === recipient.recipient_id &&
      error.recipient.recipient_type === 'student' &&
      recipient.recipient_type === 'student_parent'
    ) {
      return true;
    }

    return false;
  });
}

const getRecipientIndex = (recipient: RecipientData) => {
  return props.recipients.findIndex((r) => r.recipient_id === recipient.recipient_id);
};

const getRecipientValidationError = (recipient: RecipientData) => {
  const index = getRecipientIndex(recipient);
  return props.errors?.recipients?.[index]?.recipient_id;
};
</script>

<template>
  <div class="flex flex-col gap-y-3.5 divide-y divide-slate-200">
    <div v-if="!readonly" class="space-y-2 px-4">
      <RecipientSearch @selectRecipient="handleSelectRecipient" />
      <Alert
        v-if="duplicateError"
        color="danger"
        variant="soft"
        :duration="3000"
        :title="duplicateError"
        @onClose="duplicateError = null"
      />
    </div>

    <div
      v-if="Object.keys(groupedRecipients).length"
      class="flex-1 divide-y divide-slate-200 overflow-y-auto"
    >
      <div v-for="recipientType in Object.keys(groupedRecipients)" :key="recipientType" class="p-3">
        <Collapsible
          :title="`${pluralizeRecipientTypeLabel(recipientType)} (${
            groupedRecipients[recipientType].length
          })`"
          :padded="false"
          isOpen
        >
          <div v-if="groupedRecipients[recipientType].length > 0">
            <div
              v-for="recipient in groupedRecipients[recipientType]"
              :key="recipient.recipient_id?.toString()"
            >
              <RecipientCard
                :recipient="recipient"
                :errors="recipientErrors(recipient)"
                @remove="emit('removeRecipient', recipient)"
                :readonly="readonly"
              />
              <template v-if="props.errors.recipients">
                <p v-if="getRecipientValidationError(recipient)" class="px-4 text-sm text-red-500">
                  {{ getRecipientValidationError(recipient) }}
                </p>
              </template>
            </div>
          </div>
          <div v-else class="pt-2 text-center text-sm text-slate-500">
            No {{ getRecipientTypeLabel(recipientType) }} recipients.
          </div>
        </Collapsible>
      </div>
    </div>
    <RecipientsEmptyState
      v-else
      illustrationSize="md"
      title="No recipients added"
      description="Search recipients to add using the search bar above."
    />
  </div>
</template>
