<script setup lang="ts" generic="T extends SelectableValue">
import { Selectable, SelectableValue } from '@/components/selectBox/selectBox';

import { ButtonColor, ButtonSize, ButtonVariant } from '@/hooks/useButtonClasses';

import Button from '@/components/button/Button.vue';
import DangerDialog from '@/components/dialog/DangerDialog.vue';
import FilterTemplateMenuItem from './FilterTemplateMenuItem.vue';
import Menu from '@/components/menu/Menu.vue';

import IconAngleDown from '@/icons/line/angle-down.svg';

const props = defineProps<{
  options: Selectable<T>[];
  hasFilters: boolean;
}>();

defineModel<Selectable<T> | null>('modelValue');

defineEmits<{
  'update:modelValue': [value: Selectable<T> | null];
  save: [];
}>();

const deletingTemplateId = ref<number | null>(null);
const templateToDelete = computed(() =>
  props.options.find((opt) => opt.value === deletingTemplateId.value)
);

const deleteForm = useForm({
  method: 'DELETE',
  url: '',
  fields: {
    _method: 'DELETE'
  },
  hooks: {
    success() {
      deletingTemplateId.value = null;
    }
  }
});

function handleDeleteTemplate() {
  deleteForm.submitWith({
    method: 'DELETE',
    url: route('filter-templates.delete', { filterTemplate: deletingTemplateId.value })
  });
}
</script>

<template>
  <Menu contentClasses="w-80 max-w-[90vw]" :contentOptions="{ align: 'end', sideOffset: 5 }">
    <template #trigger>
      <Button
        :color="ButtonColor.slate"
        :iconRight="IconAngleDown"
        :size="ButtonSize.sm"
        :variant="ButtonVariant.outlined"
      >
        Apply a template
      </Button>
    </template>

    <FilterTemplateMenuItem
      v-for="option in options"
      :key="option.value as string"
      :option="option"
      :processing="deleteForm.processing && deletingTemplateId === option.value"
      @click="() => $emit('update:modelValue', option)"
      @delete="() => (deletingTemplateId = option.value as number)"
    />
  </Menu>

  <DangerDialog
    :isOpen="!!deletingTemplateId"
    title="Delete Template"
    :isLoading="deleteForm.processing"
    confirmButtonLabel="Delete template"
    @onConfirm="handleDeleteTemplate"
    @onCancel="deletingTemplateId = null"
    @onClose="deletingTemplateId = null"
  >
    <p class="whitespace-pre-line text-sm leading-5 text-slate-500">
      Are you sure you want to delete the
      <strong class="font-bold">{{ templateToDelete?.label }}</strong> template?
    </p>
  </DangerDialog>
</template>
