<script setup lang="ts">
import { buildSelectableProgram } from '@/utils/buildSelectable';

import CenteredContent from '@/components/content/CenteredContent.vue';
import CustomTestForm from '@/components/customTests/CustomTestForm.vue';

const props = defineProps<{
  customTest: App.Configuration.Data.CustomTestData;
  returnUrl?: string;
}>();

const form = useForm({
  method: 'PUT',
  url: route('custom-tests.update', { customTest: props.customTest.id }),
  fields: {
    display_name: props.customTest.display_name,
    program_id: buildSelectableProgram(props.customTest.program),
    score: props.customTest.score,
    score2: props.customTest.score2,
    score3: props.customTest.score3,
    score4: props.customTest.score4,
    score5: props.customTest.score5
  },
  transform: (fields) => ({
    ...fields,
    program_id: fields.program_id?.value ?? null
  })
});
</script>


					<script lang="ts">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <CenteredContent>
    <CustomTestForm isEditing :form="form" :returnUrl="returnUrl" />
  </CenteredContent>
</template>
