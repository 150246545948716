export function useDialogHelpers() {
  const isDialogOpen = ref(false);

  function toggleDialog() {
    isDialogOpen.value = !isDialogOpen.value;
  }

  return {
    isDialogOpen,
    toggleDialog
  };
}
