<script setup lang="ts">
import { ButtonColor, ButtonSize, ButtonVariant } from '@/hooks/useButtonClasses';

import AuthForm from '@/components/form/AuthForm.vue';
import FormTextInput from '@/components/formTextInput/FormTextInput.vue';
import Button from '@/components/button/Button.vue';
import AuthFormWrapper from '@/components/auth/AuthFormWrapper.vue';
import PasswordStrength from '@/components/passwordStrength/PasswordStrength.vue';

import IconKoDotContainer from '@/icons/line/ko-dot-container.svg';
import IconCheck from '@/icons/line/check.svg';
import StackedFormFooter from '@/components/formFooter/StackedFormFooter.vue';

const props = defineProps<{
  email: string;
  token: string;
}>();

const resetPassword = useForm({
  method: 'POST',
  url: route('password.update'),
  fields: {
    email: props.email,
    token: props.token,
    password: '',
    password_confirmation: ''
  }
});

const isPasswordLongEnough = computed(() => resetPassword.fields.password.length >= 8);
const doPasswordsMatch = computed(
  () =>
    resetPassword.fields.password.length > 0 &&
    resetPassword.fields.password === resetPassword.fields.password_confirmation
);
</script>


					<script lang="ts">
					import __hybridly_layout_0 from '~/resources/domains/auth/layouts/one-column-guest.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <AuthFormWrapper
    textAlignment="center"
    title="Set a new password"
    description="Please enter a new password for your account"
  >
    <AuthForm @submit.prevent="resetPassword.submit">
      <FormTextInput
        label="Password"
        name="password"
        v-model="resetPassword.fields.password"
        type="password"
        placeholder="New Password"
        :error="resetPassword.errors.password"
      >
        <template #helperText>
          <PasswordStrength :password="resetPassword.fields.password" />
        </template>
      </FormTextInput>

      <FormTextInput
        label="Confirm Password"
        name="password_confirmation"
        v-model="resetPassword.fields.password_confirmation"
        type="password"
        placeholder="Confirm New Password"
      >
        <template #helperText>
          <PasswordStrength :password="resetPassword.fields.password_confirmation" />
        </template>
      </FormTextInput>

      <ul class="flex flex-col gap-4 pt-6 text-base text-slate-700">
        <li class="flex items-center gap-2">
          <IconCheck class="w-4 text-green-600" v-if="isPasswordLongEnough" />
          <IconKoDotContainer v-else />
          Password should be at least 8 characters long
        </li>
        <li class="flex items-center gap-2">
          <IconCheck class="w-4 text-green-600" v-if="doPasswordsMatch" />
          <IconKoDotContainer v-else />
          Confirm your password
        </li>
      </ul>

      <template #footer>
        <StackedFormFooter>
          <Button
            :color="ButtonColor.primary"
            :isDisabled="resetPassword.processing"
            :size="ButtonSize.lg"
            :variant="ButtonVariant.solid"
            type="submit"
            >Reset Password</Button
          >
          <Button
            :color="ButtonColor.slate"
            :href="route('login.show')"
            :size="ButtonSize.lg"
            :variant="ButtonVariant.invisible"
            >Back to login</Button
          >
        </StackedFormFooter>
      </template>
    </AuthForm>
  </AuthFormWrapper>
</template>
