<script setup lang="ts" generic="T extends SelectableValue">
import type { FormFieldProps, FormFieldSlots } from '@/components/formField/FormField';
import type { Selectable, SelectableValue, SelectBoxProps } from '@/components/selectBox/selectBox';
import { useFormField } from '@/components/formField/useFormField';
import FormField from '@/components/formField/FormField.vue';
import Combobox from '@/components/selectBox/combobox/ComboboxInput.vue';

export type FormComboboxProps<T extends SelectableValue> = SelectBoxProps<T> & FormFieldProps;

const props = defineProps<
  FormComboboxProps<T> & {
    clearable?: boolean;
  }
>();
defineSlots<FormFieldSlots>();

const emit = defineEmits<{
  'update:modelValue': [Selectable<T> | null];
  open: [];
}>();

function onValueChange(value: Selectable<T> | null): void {
  emit('update:modelValue', value);
}

const { formFieldProps, slots } = useFormField({ props });
</script>

<template>
  <FormField v-bind="formFieldProps">
    <Combobox
      :clearable="clearable"
      :hasError="hasError"
      :iconLeft="iconLeft"
      :isDisabled="isDisabled"
      :isLoading="isLoading"
      :modelValue="modelValue as Selectable<T>"
      :onFilter="onFilter"
      :options="options"
      :placeholder="placeholder"
      :showQueryAsOption="showQueryAsOption"
      @update:modelValue="onValueChange"
      @open="$emit('open')"
    />
    <template v-for="slot of slots" #[slot]>
      <slot :name="slot" />
    </template>
  </FormField>
</template>
