<script setup lang="ts">
import ComboboxInput from '@/components/selectBox/combobox/ComboboxInput.vue';
import { CssClass } from '@/@types/global';
import { Selectable, SelectableValue } from '@/components/selectBox/selectBox';
import { useInstitutesSearch } from '@/hooks/search/useInstitutesSearch';

defineProps<{
  modelValue: Selectable<SelectableValue> | null;
  inputClasses?: CssClass;
  isLoading?: boolean;
}>();

defineEmits(['update:modelValue']);

const { instituteOptions, isLoading: isSearching, onQueryChange } = useInstitutesSearch();
</script>

<template>
  <ComboboxInput
    :options="instituteOptions"
    :onFilter="onQueryChange"
    :isLoading="isLoading || isSearching"
    :modelValue="modelValue"
    :inputClasses="inputClasses"
    @update:modelValue="$emit('update:modelValue', $event)"
    @open="() => onQueryChange('')"
  />
</template>
