<script setup lang="ts">
type EnumData = App.Base.Data.EnumData;

import type { Selectable } from '@/components/selectBox/selectBox';

import CenteredContent from '@/components/content/CenteredContent.vue';
import TestForm from '@/components/students/studentActions/TestForm.vue';

const props = defineProps<{
  returnUrl?: string;
  student: App.Students.Data.StudentData;
  types: EnumData[];
  scoreMap: Record<string, Record<string, { rules?: string[]; step?: number }>>;
  labelMap: Record<string, string>;
  customTests: App.Configuration.Data.CustomTestData[];
  apCourses: EnumData[];
}>();

const typeOptions = computed(() => [
  ...props.types,
  {
    value: 'custom-test',
    label: 'Custom test',
    description: null
  }
]);

const form = useForm({
  method: 'POST',
  url: route('students.tests.store', { student: props.student.id }),
  fields: {
    type: null as Selectable<string> | null,
    subject: null as Selectable<number> | null,
    test: null as Selectable<string> | null,
    date: '',
    is_complete: false,
    scores: {},
    is_assisted: false,
    is_waiver_assisted: false,
    is_registered: false,
    is_waived: false
  },
  transform: (fields) => ({
    ...fields,
    type: fields.type?.value === 'custom-test' ? fields.test?.value : fields.type?.value,
    test: fields.test?.value
  })
});
</script>


					<script lang="ts">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <CenteredContent>
    <TestForm
      :apCourses
      :customTests
      :form
      :labelMap
      :returnUrl
      :scoreMap
      :student
      :types="typeOptions"
    />
  </CenteredContent>
</template>
