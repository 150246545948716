<script setup lang="ts">
type EnumData = App.Base.Data.EnumData;
type SuccessStoryData = App.SuccessStories.Data.SuccessStoryData;

import { buildSelectableSchool, buildSelectableStudent } from '@/utils/buildSelectable';

import CenteredContent from '@/components/content/CenteredContent.vue';
import SuccessStoryForm from '@/components/successStories/SuccessStoryForm.vue';

const props = defineProps<{
  successStory: SuccessStoryData;
  subjects: EnumData[];
}>();

const form = useForm({
  method: 'PUT',
  url: route('success-stories.update', { successStory: props.successStory.id }),
  fields: {
    title: props.successStory.title || '',
    subject: props.successStory.subject || '',
    school_id: buildSelectableSchool(props.successStory.school),
    student_id: buildSelectableStudent(props.successStory.student),
    is_parent: props.successStory.is_parent,
    content_json: props.successStory.content_json || '',
    content_html: props.successStory.content_html || ''
  },
  transform: (fields) => ({
    ...fields,
    subject: fields.subject?.value,
    school_id: fields.school_id?.value,
    student_id: fields.student_id?.value
  })
});
</script>

					<script lang="ts">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <CenteredContent>
    <SuccessStoryForm :form="form" :subjects="subjects" isEditing />
  </CenteredContent>
</template>
