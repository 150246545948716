<script setup lang="tsx">
import Checkbox from '@/components/selectBox/combobox/Checkbox.vue';
import MultiComboboxInput from '@/components/selectBox/combobox/MultiComboboxInput.vue';
import { Selectable } from '@/components/selectBox/selectBox';

const props = defineProps<{
  defaultStatuses: string[];
}>();

const emit = defineEmits<{
  change: [string[]];
}>();

/**
 * Using a ref instead of v-model cause on change request latency causes UI to hang
 */
const statuses = ref(props.defaultStatuses);

const options = [
  {
    label: 'Matched records',
    value: 'match'
  },
  {
    label: 'New records',
    value: 'new'
  },
  {
    label: 'Potential matches',
    value: 'pending'
  },
  {
    label: 'Errored records',
    value: 'error'
  }
];

const selectedOptions = computed(() =>
  statuses.value
    .map((selectedOptionValue) => options.find((option) => selectedOptionValue === option.value))
    .filter((selectedOption): selectedOption is Selectable<string> => !!selectedOption)
);

function handleChange(value: Selectable<string>[]) {
  statuses.value = value.map((option) => option.value);
  emit('change', statuses.value);
}

function handleOnlyClick(value: string) {
  statuses.value = [value];
  emit('change', statuses.value);
}
</script>

<template>
  <div class="flex flex-1 items-center gap-2">
    <label class="text-sm">Filter records:</label>
    <MultiComboboxInput
      :searchable="false"
      selectAll
      selectAllViewAs="single"
      selectAllLabel="All records"
      placeholder="Choose Status..."
      :modelValue="selectedOptions"
      @update:modelValue="handleChange"
      :options="options"
    >
      <template #option="{ checked, option, close }">
        <div class="flex w-full flex-row items-center justify-between gap-x-6">
          <div class="flex flex-row items-center gap-x-2">
            <Checkbox :checked="checked" />
            <div>
              <p>{{ option.label }}</p>
              <p v-if="option.description" class="text-2xs text-slate-400">
                {{ option.description }}
              </p>
            </div>
          </div>
          <button
            type="button"
            class="text-sm font-semibold text-blue-500 hover:text-blue-700"
            @click.stop.prevent="
              handleOnlyClick(option.value);
              close();
            "
          >
            Only
          </button>
        </div>
      </template>
    </MultiComboboxInput>
  </div>
</template>
