export type NestedOption = {
  children?: NestedOption[];
};

function walkChildren<T extends NestedOption>(accumulator: T[], option: T) {
  if (option.children) {
    return option.children.reduce(walkChildren, accumulator);
  }

  return accumulator.concat(option);
}

export function flattenNestedList<T extends NestedOption>(list: T[]): T[] {
  return list.reduce((accumulator: T[], childOption: T) => {
    return walkChildren(accumulator, childOption);
  }, [] as T[]);
}
