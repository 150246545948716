<script setup lang="ts">
import type { Selectable } from '@/components/selectBox/selectBox';
import type { TableSelectionState } from '@/hooks/useHybridlyTable';

import pluralize from 'pluralize';

import Alert from '@/components/alert/Alert.vue';
import Dialog from '@/components/dialog/Dialog.vue';
import Fieldset from '@/components/fieldset/Fieldset.vue';
import FormRows from '@/components/formRows/FormRows.vue';
import FormStudentGroupInput from '@/components/formComboboxInput/FormStudentGroupInput.vue';

const props = defineProps<{
  isOpen: boolean;
  selectedStudentsCount: number;
  tableSelectionState: TableSelectionState;
}>();

const emit = defineEmits<{
  onCancel: [void];
  onClose: [void];
}>();

const queryParameters = useQueryParameters();

const selectedStudentGroup = ref<Selectable<number> | null>(null);

const computedUrl = computed(() =>
  selectedStudentGroup.value
    ? route('student-groups.student.update', {
        studentGroup: selectedStudentGroup.value.value
      })
    : undefined
);

const form = useForm({
  method: 'PUT',
  fields: {
    ids: props.tableSelectionState.selectedRowIds || [],
    filters: queryParameters.filters || null,
    all: props.tableSelectionState.areAllRecordsSelected
  },
  transform: (fields) => ({
    ...fields,
    ids: props.tableSelectionState.selectedRowIds || [],
    filters: queryParameters.filters || null,
    all: props.tableSelectionState.areAllRecordsSelected,
    return_url: getRouterContext().url
  }),
  hooks: {
    success() {
      props.tableSelectionState.reset();
      emit('onClose');
    }
  }
});

function handleConfirm() {
  form.submitWith({
    url: computedUrl.value
  });
}
</script>

<template>
  <Dialog
    :confirmButtonDisabled="!computedUrl"
    :isOpen="isOpen"
    :isLoading="form.processing"
    confirmButtonLabel="Add to group"
    description="Add selected students to an existing group"
    title="Add To Group"
    @onConfirm="handleConfirm"
    @onClose="emit('onClose')"
    @onCancel="emit('onCancel')"
  >
    <FormRows>
      <Fieldset title="Add to">
        <FormStudentGroupInput
          :modelValue="selectedStudentGroup"
          label="Group"
          placeholder="Select group"
          @update:modelValue="(value) => (selectedStudentGroup = value)"
        />
        <Alert title="Selected Students" variant="soft">
          You are adding
          <span class="font-bold">{{ pluralize('student', selectedStudentsCount, true) }}</span>
          to the
          <span v-if="selectedStudentGroup?.label" class="font-bold">{{
            selectedStudentGroup?.label
          }}</span>
          group.
        </Alert>
      </Fieldset>
    </FormRows>
  </Dialog>
</template>
