<script setup lang="ts">
import type { HybridlyFormData } from '@/@types/global';
import type { Selectable } from '@/components/selectBox/selectBox';

import { ButtonColor, ButtonVariant } from '@/hooks/useButtonClasses';

import Button from '@/components/button/Button.vue';
import DangerDialog from '@/components/dialog/DangerDialog.vue';
import Fieldset from '@/components/fieldset/Fieldset.vue';
import Form from '@/components/form/Form.vue';
import FormCheckboxInput from '@/components/formCheckboxInput/FormCheckboxInput.vue';
import FormFooter from '@/components/formFooter/FormFooter.vue';
import FormHeader from '@/components/formHeader/FormHeader.vue';
import FormListboxInput from '@/components/formListboxInput/FormListboxInput.vue';
import FormProgramInput from '@/components/formComboboxInput/FormProgramInput.vue';
import FormTextInput from '@/components/formTextInput/FormTextInput.vue';
import ProgramStudentFieldRule from './ProgramStudentFieldRule.vue';

type EnumData = App.Base.Data.EnumData;

const props = defineProps<{
  form: HybridlyFormData<{
    field_label: string;
    type: string;
    program_id: number;
    enabled: boolean;
    in_results: boolean;
    rules: App.Configuration.Data.FieldRuleData[];
  }>;
  isEditing?: boolean;
  types: EnumData[];
  returnUrl?: string;
}>();

const isCancelDialogOpen = ref(false);

const { onBackForward } = useBackForward();

// TODO: Fix intecepting the back button
onBackForward(() => {
  router.abort();
});

function handleTypeChange(type: Selectable<string> | null) {
  props.form.fields.type = type;

  if (type?.value === 'number') {
    props.form.fields.rules = [
      {
        field_rule_id: 'between:',
        parameters: {
          min: null,
          max: null
        }
      },
      {
        field_rule_id: 'numeric',
        parameters: null
      }
    ];
  }
  if (type?.value === 'select') {
    props.form.fields.rules = [
      {
        field_rule_id: 'in:',
        parameters: {
          options: []
        }
      }
    ];
  }
}

function handleToggleCancelDialog() {
  if (props.form.isDirty) {
    isCancelDialogOpen.value = !isCancelDialogOpen.value;
    return;
  }
  handleRedirect();
}

function handleRedirect() {
  router.navigate({ url: props.returnUrl || route('program-student-fields.index') });
}

function handleRuleChange(fieldRuleId: string, parameters: unknown) {
  const rules = [...props.form.fields.rules];
  const existingRule = rules.find((r) => r.field_rule_id === fieldRuleId);

  if (existingRule) {
    existingRule.parameters = parameters;
  }

  props.form.fields.rules = rules;
}
</script>

<template>
  <Form @submit.prevent="form.submit">
    <template #header>
      <FormHeader
        canClose
        :title="`${isEditing ? 'Edit' : 'Add'} a Custom Field`"
        :description="
          isEditing
            ? 'Use the form below to edit the custom field.'
            : 'Fill out the form below add a new custom field.'
        "
        @onClose="handleToggleCancelDialog"
      />
    </template>
    <Fieldset title="Custom field information">
      <FormTextInput
        :error="form.errors.field_label"
        :helperText="
          isEditing
            ? 'You cannot rename this field because there is already student data saved for it.'
            : undefined
        "
        :isDisabled="isEditing"
        :modelValue="form.fields.field_label"
        label="Field name"
        name="field_label"
        placeholder="e.g. GSU"
        type="text"
        @update:modelValue="(value) => (form.fields.field_label = value)"
      />
      <FormProgramInput
        label="Program"
        :error="form.errors.program_id"
        :isDisabled="isEditing"
        :modelValue="form.fields.program_id"
        @update:modelValue="(value) => (form.fields.program_id = value)"
      />
      <FormListboxInput
        :error="form.errors.type"
        :isDisabled="isEditing"
        :modelValue="form.fields.type"
        :options="types"
        label="Custom field type"
        @update:modelValue="handleTypeChange"
      />
      <ProgramStudentFieldRule
        v-for="(rule, index) in form.fields.rules"
        :key="rule.field_rule_id"
        :rule="rule"
        :errors="form.errors.rules?.[index]?.parameters"
        :isEditing="isEditing"
        @change="handleRuleChange"
      />
    </Fieldset>
    <Fieldset title="Other settings">
      <FormCheckboxInput
        :checked="form.fields.enabled"
        :error="form.errors.enabled"
        helperText="When active, the field will be editable on the student profile page."
        label="Active"
        name="enabled"
        @update:checked="(value) => (form.fields.enabled = value)"
      />
      <FormCheckboxInput
        :checked="form.fields.in_results"
        :error="form.errors.in_results"
        helperText="When enabled, the field will be displayed on the student search results page."
        label="Display on student search page"
        name="in_results"
        @update:checked="(value) => (form.fields.in_results = value)"
      />
    </Fieldset>
    <template #footer>
      <FormFooter>
        <Button
          :color="ButtonColor.primary"
          :isDisabled="form.processing || !form.isDirty"
          :isLoading="form.processing"
          :variant="ButtonVariant.solid"
          type="submit"
          >Confirm</Button
        >
        <Button
          :color="ButtonColor.slate"
          :variant="ButtonVariant.outlined"
          type="button"
          @click="handleToggleCancelDialog"
        >
          Back to custom fields
        </Button>
      </FormFooter>
    </template>
  </Form>
  <DangerDialog
    :title="`Cancel ${isEditing ? 'editing' : 'adding a new'} custom field`"
    :message="`Are you sure you want to cancel ${
      isEditing ? 'editing' : 'adding'
    } this custom field? All ${isEditing ? 'changes' : 'information'} will be lost.`"
    :isOpen="isCancelDialogOpen"
    cancelButtonLabel="Stay on this page"
    confirmButtonLabel="Go back to Custom Fields"
    @onCancel="handleToggleCancelDialog"
    @onClose="handleToggleCancelDialog"
    @onConfirm="handleRedirect"
  />
</template>
