<script setup lang="ts">
import Badge from '@/components/badge/Badge.vue';
import Button from '@/components/button/Button.vue';
import IconButton from '@/components/button/IconButton.vue';
import Times from '@/icons/line/times.svg';
import RichContent from '@/components/richTextInput/RichContent.vue';

const emit = defineEmits<{
  onPrimaryClick: [void];
  onSecondaryClick: [void];
  onClose: [void];
}>();

type AlertProps = {
  alignment?: 'horizontal' | 'vertical';
  badgeLabel?: string;
  color?: AlertColor;
  description?: string | null;
  duration?: number | null;
  hideClose?: boolean;
  html?: string | null;
  icon?: Component | null;
  primaryButtonLabel?: string | null;
  secondaryButtonLabel?: string | null;
  squared?: boolean;
  title?: string | null;
  variant?: 'solid' | 'soft' | 'outlined';
};

export type AlertColor = 'primary' | 'secondary' | 'success' | 'warning' | 'danger';

const props = withDefaults(defineProps<AlertProps>(), {
  alignment: 'horizontal',
  variant: 'solid',
  color: 'primary'
});

const isHorizontal = computed(() => props.alignment === 'horizontal');
const isVertical = computed(() => props.alignment === 'vertical');
const isSolid = computed(() => props.variant === 'solid');

const descriptionClasses = computed(() => [
  'text-sm whitespace-pre-line',
  props.variant === 'solid' && props.color === 'primary' && 'text-primary-100',
  props.variant !== 'solid' && props.color === 'primary' && 'text-primary-700',
  props.variant === 'solid' && props.color === 'secondary' && 'text-secondary-100',
  props.variant !== 'solid' && props.color === 'secondary' && 'text-secondary-800',
  props.variant === 'solid' && props.color === 'success' && 'text-green-100',
  props.variant !== 'solid' && props.color === 'success' && 'text-green-700',
  props.variant === 'solid' && props.color === 'warning' && 'text-amber-800',
  props.variant !== 'solid' && props.color === 'warning' && 'text-amber-700',
  props.variant === 'solid' && props.color === 'danger' && 'text-red-100',
  props.variant !== 'solid' && props.color === 'danger' && 'text-red-700'
]);

function close() {
  emit('onClose');
}

const timeout = ref<NodeJS.Timeout>();

onMounted(() => {
  if (props.duration) {
    timeout.value = setTimeout(close, props.duration);
  }
});

onUnmounted(() => {
  if (timeout.value) {
    clearTimeout(timeout.value);
    timeout.value = undefined;
  }
});
</script>

<template>
  <div
    class="relative flex w-full gap-4 overflow-hidden"
    :class="{
      'px-6 py-4': true,
      'pb-6': duration,
      'rounded-lg': !squared,
      'border-transparent': variant === 'solid' || variant === 'soft',
      'border border-solid': variant === 'outlined',

      'bg-primary-50': variant === 'soft' && color === 'primary',
      'bg-secondary-50': variant === 'soft' && color === 'secondary',
      'bg-green-50': variant === 'soft' && color === 'success',
      'bg-amber-50': variant === 'soft' && color === 'warning',
      'bg-red-50': variant === 'soft' && color === 'danger',

      'border-primary-300 bg-primary-50': variant === 'outlined' && color === 'primary',
      'border-secondary-200 bg-secondary-50': variant === 'outlined' && color === 'secondary',
      'border-green-700/30 bg-green-50': variant === 'outlined' && color === 'success',
      'border-amber-300 bg-amber-50': variant === 'outlined' && color === 'warning',
      'border-red-300 bg-red-50': variant === 'outlined' && color === 'danger',

      'bg-primary-900': variant === 'solid' && color === 'primary',
      'bg-secondary-700': variant === 'solid' && color === 'secondary',
      'bg-green-700': variant === 'solid' && color === 'success',
      'bg-amber-400': variant === 'solid' && color === 'warning',
      'bg-red-700': variant === 'solid' && color === 'danger',

      'items-center': alignment === 'horizontal',
      'items-start': alignment === 'vertical'
    }"
  >
    <div class="flex grow flex-col gap-1">
      <div
        class="flex"
        :class="{ 'items-center gap-2': isHorizontal && (icon || badgeLabel), 'gap-4': isVertical }"
      >
        <div
          v-if="badgeLabel || icon"
          class="flex shrink-0"
          :class="{ 'items-center gap-2': isHorizontal, 'gap-4': isVertical }"
        >
          <Badge
            v-if="badgeLabel"
            :label="badgeLabel"
            :color="color"
            size="md"
            :variant="isSolid ? 'soft' : 'solid'"
          />
          <component
            v-if="icon"
            :is="icon"
            class="h-4 w-4"
            :class="{
              'text-white opacity-70': isSolid && color !== 'warning',
              'text-primary-700': !isSolid && color === 'primary',
              'text-secondary-700': !isSolid && color === 'secondary',
              'text-green-700': !isSolid && color === 'success',
              'text-amber-700': color === 'warning',
              'text-red-700': !isSolid && color === 'danger'
            }"
          />
        </div>
        <div class="flex flex-col gap-2.5">
          <div class="leading-5">
            <h4
              v-if="title"
              class="text-sm font-bold"
              :class="{
                'text-white': variant === 'solid' && color !== 'warning',
                'text-primary-900': variant !== 'solid' && color === 'primary',
                'text-secondary-900': variant !== 'solid' && color === 'secondary',
                'text-green-900': variant !== 'solid' && color === 'success',
                'text-amber-900': color === 'warning',
                'text-red-900': variant !== 'solid' && color === 'danger'
              }"
            >
              {{ title }}
            </h4>
            <p v-if="(description || $slots.default) && isVertical" :class="descriptionClasses">
              <slot>
                {{ description }}
              </slot>
            </p>
            <RichContent
              v-if="html && isVertical"
              :class="descriptionClasses"
              :html="html"
              :styleLinks="false"
            />
          </div>
          <div v-if="(primaryButtonLabel || secondaryButtonLabel) && isVertical" class="flex gap-2">
            <Button
              v-if="secondaryButtonLabel"
              :color="color"
              variant="outlined"
              @click="$emit('onSecondaryClick')"
              >{{ secondaryButtonLabel }}</Button
            >
            <Button
              v-if="primaryButtonLabel"
              :color="color"
              vairant="solid"
              @click="$emit('onPrimaryClick')"
              >{{ primaryButtonLabel }}</Button
            >
          </div>
        </div>
      </div>
      <p v-if="(description || $slots.default) && isHorizontal" :class="descriptionClasses">
        <slot>
          {{ description }}
        </slot>
      </p>
      <RichContent v-if="html && isHorizontal" :class="descriptionClasses" :html="html" />
    </div>
    <div v-if="(primaryButtonLabel || secondaryButtonLabel) && isHorizontal" class="flex gap-2">
      <Button
        v-if="secondaryButtonLabel"
        :color="color"
        variant="outlined"
        @click="$emit('onSecondaryClick')"
        >{{ secondaryButtonLabel }}</Button
      >
      <Button
        v-if="primaryButtonLabel"
        :color="color"
        variant="solid"
        @click="$emit('onPrimaryClick')"
      >
        {{ primaryButtonLabel }}</Button
      >
    </div>
    <IconButton
      v-if="isVertical && !hideClose"
      :color="color"
      :icon="Times"
      :variant="variant === 'solid' ? 'solid' : 'soft'"
      ariaLabel="Close alert"
      @click="close"
    />
    <div
      v-if="duration"
      class="absolute bottom-0 left-0 h-1.5 w-full rounded-t-none sm:h-2"
      :class="{
        'rounded-full': !squared,
        'bg-primary-200': color === 'primary',
        'bg-secondary-100': color === 'secondary' && variant === 'solid',
        'bg-secondary-200': color === 'secondary' && variant !== 'solid',
        'bg-green-100': color === 'success' && variant === 'solid',
        'bg-green-200': color === 'success' && variant !== 'solid',
        'bg-amber-100': color === 'warning' && variant === 'solid',
        'bg-amber-200': color === 'warning' && variant !== 'solid',
        'bg-red-200': color === 'danger'
      }"
    >
      <span
        class="progress-bar block h-full w-full"
        :class="{
          'bg-primary-500': color === 'primary',
          'bg-secondary-500': color === 'secondary' && variant === 'solid',
          'bg-secondary-600': color === 'secondary' && variant !== 'solid',
          'bg-amber-600': color === 'warning',
          'bg-green-500': color === 'success' && variant === 'solid',
          'bg-green-600': color === 'success' && variant !== 'solid',
          'bg-red-400': color === 'danger' && variant === 'solid',
          'bg-red-500': color === 'danger' && variant !== 'solid'
        }"
        :style="{ animationDuration: `${duration}ms` }"
      />
    </div>
  </div>
</template>

<style scoped>
.progress-bar {
  animation-name: progressBar;
  animation-timing-function: linear;
}
@keyframes progressBar {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
</style>
