<script setup lang="ts">
import CampaignForm from '@/components/automated-campaigns/CampaignForm.vue';
import CenteredContent from '@/components/content/CenteredContent.vue';

const props = defineProps<{
  campaign: App.Sms.Data.AutomatedCampaigns.AutomatedCampaignData;
}>();

const form = useForm({
  method: 'PUT',
  url: route('sms.campaigns.update', { campaign: props.campaign.id }),
  updateInitials: true,
  fields: {
    name: props.campaign.name,
    status: props.campaign.paused_at
      ? {
          label: 'Paused',
          value: 'paused'
        }
      : {
          label: 'Active',
          value: 'active'
        },
    student_group_id: props.campaign.student_group
      ? {
          value: props.campaign.student_group.id,
          label: props.campaign.student_group.name
        }
      : null,
    is_targeting_parents: props.campaign.is_targeting_parents ? 'true' : 'false'
  },
  transform: (fields) => ({
    ...fields,
    student_group_id: fields.student_group_id?.value ?? null,
    status: fields.status?.value,
    is_targeting_parents: fields.is_targeting_parents === 'true'
  })
});
</script>


					<script lang="ts">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <CenteredContent>
    <CampaignForm :campaign="campaign" :form="form" isEditing />
  </CenteredContent>
</template>
