<script setup lang="ts">
import { format } from 'date-fns';
import { getBooleanDisplayValue } from '@/utils/filterBoolean';

type EnumData = App.Base.Data.EnumData;

import FinancialAidForm from '@/components/students/studentActions/FinancialAidForm.vue';
import CenteredContent from '@/components/content/CenteredContent.vue';

const props = defineProps<{
  financialAid: App.Students.Data.FinancialAidData;
  returnUrl?: string;
  student: App.Students.Data.StudentData;
  statuses: EnumData[];
  studentCssProfiles: EnumData[];
  selectedForVerificationOptions: EnumData[];
}>();

const form = useForm({
  method: 'PUT',
  url: route('students.financial-aid.update', {
    student: props.student.id,
    financialAid: props.student.financial_aid?.id
  }),
  fields: {
    status: props.student.financial_aid?.status,
    date_submitted: props.student.financial_aid?.date_submitted
      ? format(new Date(props.student.financial_aid.date_submitted), 'yyyy-MM-dd')
      : null,
    css_profile: props.student.meta.css_profile
      ? {
          label:
            props.studentCssProfiles?.find(
              (profile) => profile.value === props.student.meta.css_profile
            )?.label ?? false,
          value: props.student.meta?.css_profile
        }
      : null,
    pell_eligible: {
      label: getBooleanDisplayValue<string>(
        props.student.pell_eligible,
        'Not set',
        'Eligible',
        'Not Eligible'
      ),
      value: props.student.pell_eligible
    },
    is_alternative: props.student.financial_aid?.is_alternative,
    selected_for_verification: props.student.financial_aid?.selected_for_verification
  },
  transform: (fields) => ({
    ...fields,
    status: fields.status?.value,
    css_profile: fields.css_profile?.value,
    pell_eligible: fields.pell_eligible?.value,
    selected_for_verification: fields.selected_for_verification?.value
  })
});
</script>


					<script lang="ts">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <CenteredContent>
    <FinancialAidForm
      :studentCssProfiles
      :selectedForVerificationOptions
      :form
      :statuses
      :student
      isEditing
    />
  </CenteredContent>
</template>
