<script setup lang="ts">
type EnumData = App.Base.Data.EnumData;

import { vIntersectionObserver } from '@vueuse/components';
import Badge from '@/components/badge/Badge.vue';
import Tooltip from '@/components/tooltip/Tooltip.vue';

const props = defineProps<{
  items: EnumData[];
}>();

const itemsContainer = ref<HTMLElement | null>(null);

const clippedItems = ref<{
  [key: number]: boolean;
}>(props.items.reduce((acc, _, index) => ({ ...acc, [index]: true }), {}));

const visibleItems = computed(() => {
  const firstItem = props.items.length > 0 ? props.items[0] : null;

  const clippedItemsFiltered = props.items
    .slice(1)
    .filter((_, index) => clippedItems.value[index + 1]);

  return firstItem ? [firstItem, ...clippedItemsFiltered] : [];
});

const hasHiddenBadges = computed(() => props.items.length !== visibleItems.value.length);
const hiddenBadgeCount = computed(() => props.items.length - visibleItems.value.length);
const badgeLabelToTruncate = computed(() =>
  props.items.reduce(
    (acc, cur, i) => (props.items[acc].label.length < cur.label.length ? i : acc),
    0
  )
);

function onItemIntersection(index: number, entries: IntersectionObserverEntry[]) {
  entries.forEach((entry) => {
    clippedItems.value = {
      ...clippedItems.value,
      [index]: entry.intersectionRatio === 1
    };
  });
}
</script>

<template>
  <div class="relative flex flex-1 shrink items-center justify-start overflow-hidden">
    <div class="flex flex-nowrap items-center gap-1">
      <div
        v-for="(item, index) in visibleItems"
        :key="index"
        class="flex flex-nowrap items-center gap-1"
      >
        <Badge :label="item.label" shape="rounded" size="md" variant="soft" />
        <Tooltip showArrow side="top">
          <template #trigger>
            <Badge
              v-if="hasHiddenBadges && visibleItems.length === index + 1"
              :label="`+${hiddenBadgeCount}`"
              shape="rounded"
              size="md"
              variant="soft"
            />
          </template>
          <div class="flex w-full max-w-[320px] flex-wrap gap-1 py-1.5">
            <ul class="list-outside list-disc pl-4">
              <li v-for="(item, index) in items" :key="index">
                {{ item.label }}
              </li>
            </ul>
          </div>
        </Tooltip>
      </div>
    </div>

    <!-- This is the hidden copy of the badges that we use to determine if a badge is clipped -->
    <div
      ref="itemsContainer"
      aria-hidden
      class="pointer-events-none invisible absolute left-0 top-0 flex w-full flex-wrap gap-1"
    >
      <div
        v-for="(item, index) in items"
        class="flex flex-nowrap items-center gap-1"
        :class="{ '': badgeLabelToTruncate === index }"
        :key="index"
        v-intersection-observer="[
          (observerEntries: IntersectionObserverEntry[]) =>
            onItemIntersection(index, observerEntries),
          { root: itemsContainer, threshold: 1 }
        ]"
      >
        <Badge :label="item.label" shape="rounded" size="md" variant="soft" />
        <Badge
          v-if="hasHiddenBadges && visibleItems.length === index + 1"
          :label="`+${hiddenBadgeCount}`"
          shape="rounded"
          size="md"
          variant="soft"
        />
      </div>
    </div>
  </div>
</template>
