<script setup lang="tsx">
type EnumData = App.Base.Data.EnumData;

import type { AppliedFilters } from '@/components/distiller/types';
import type { CustomCells, HybridlyRow } from '@/components/table/Table';
import type { IndexCollegeApplicationActionsTabNavCounts } from '@/components/students/studentActions/IndexCollegeApplicationActionsTabNav.vue';
import type { IndexStudentActionsTabNavCounts } from '@/components/students/studentActions/IndexStudentActionsTabNav.vue';

import { useHybridlyTable } from '@/hooks/useHybridlyTable';
import { useTableRowClick } from '@/hooks/useTableRowClick';

import IconButton from '@/components/button/IconButton.vue';
import IndexStudentActionsTabNav from '@/components/students/studentActions/IndexStudentActionsTabNav.vue';
import IndexCollegeApplicationActionsTabNav from '@/components/students/studentActions/IndexCollegeApplicationActionsTabNav.vue';
import ShowStudentPageLayout from '@/components/students/ShowStudentPageLayout.vue';
import ShowStudentPageTableLayout from '@/components/students/ShowStudentPageTableLayout.vue';
import TableBadgesCell from '@/components/table/TableBadgesCell.vue';
import TableListboxCell from '@/components/table/TableListboxCell.vue';
import TableBooleanCell from '@/components/table/TableBooleanCell.vue';
import TableEnumCell from '@/components/table/TableEnumCell.vue';

import AwardAlt from '@/icons/line/award-alt.svg';
import Plus from '@/icons/line/plus.svg';
import TableDateTimeCell from '@/components/table/TableDateTimeCell.vue';
import EmptyState from '@/components/emptyState/EmptyState.vue';

type ApplicationRow = HybridlyRow<App.Students.Data.ApplicationData>;

const props = defineProps<{
  appliedFilters: AppliedFilters;
  counts: IndexStudentActionsTabNavCounts & IndexCollegeApplicationActionsTabNavCounts;
  distiller: App.Search.Data.DistillerData;
  applications: Table<App.Students.Data.ApplicationData>;
  student: App.Students.Data.StudentData;
  schoolYear: 'current' | 'past';
  statuses: EnumData[];
  pastActive: boolean;
}>();

const { table } = useHybridlyTable<App.Students.Data.ApplicationData>(props, 'applications');
const { handleRowClick } = useTableRowClick();

const customCells = {
  first_choice: ({ row, column }) => (
    <TableBadgesCell
      badgesToDisplay={1}
      badgeVariant="soft"
      items={[row.value(column.key) ? 'Yes' : 'Alternative']}
      itemIcon={row.value(column.key) ? AwardAlt : undefined}
    />
  ),
  status: ({ row, column }) => (
    <TableListboxCell
      options={props.statuses}
      modelValue={row.value(column.key)}
      onUpdate:modelValue={(value) => handleStatusChange(value, row)}
    />
  ),
  selectivity: ({ row, column }) => <TableEnumCell enumData={row.value(column.key).selectivity} />,
  css_required: ({ row, column }) => <TableBooleanCell boolean={row.value(column.key) ?? false} />,
  program_length: ({ row, column }) => (
    <TableEnumCell enumData={row.value(column.key).program_length} />
  ),
  intend_to_enroll: ({ row, column }) => (
    <TableBooleanCell boolean={row.value(column.key) ?? false} />
  ),
  hbcu: ({ row, column }) => <TableBooleanCell boolean={row.value(column.key) ?? false} />,
  date_submitted: ({ row, column }) => <TableDateTimeCell date={row.value(column.key)} />
} as CustomCells<App.Students.Data.ApplicationData>;

function handleCollegeApplicationRowClick(event: MouseEvent, row: ApplicationRow) {
  const url = route('students.college-applications.show', {
    student: props.student.id,
    application: row.key
  });
  handleRowClick(url, event);
}

function handleStatusChange(value: string, row: ApplicationRow) {
  useForm({
    method: 'PUT',
    url: route('students.college-applications.status.update', {
      student: props.student.id,
      application: row.key
    }),
    fields: {
      status: value
    }
  }).submit();
}
</script>


					<script lang="tsx">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <ShowStudentPageLayout :student :title="student.name">
    <template #tabs>
      <IndexStudentActionsTabNav :student :counts />
    </template>
    <template #table>
      <ShowStudentPageTableLayout
        title="College applications"
        :appliedFilters
        :distiller
        :table="table"
        :customCells
        :stickyEndColumns="1"
        clickableRows
        @clickRow="($event, row) => handleCollegeApplicationRowClick($event, row as ApplicationRow)"
      >
        <template #tabs>
          <IndexCollegeApplicationActionsTabNav :counts :student :schoolYear />
        </template>
        <template #rightButtons>
          <!--
                TODO: Enable button once we have the endpoint
            -->
          <!-- <Button :color="ButtonColor.slate" :variant="ButtonVariant.outlined">Award letter analyzer (XLSX)</Button> -->
          <IconButton
            ariaLabel="Add college application"
            variant="soft"
            :icon="Plus"
            :href="route('students.college-applications.create', { student: student.id })"
          />
        </template>
        <template #emptyState>
          <EmptyState
            v-if="schoolYear === 'past'"
            title="No college applications found"
            description="College applications will appear on this page when they are available"
          />
          <EmptyState
            v-else
            title="No college applications found"
            description="Create a college application by clicking the button below"
            actionLabel="Add a college application"
            :actionHref="route('students.college-applications.create', { student: student.id })"
          />
        </template>
      </ShowStudentPageTableLayout>
    </template>
  </ShowStudentPageLayout>
</template>
