<script setup lang="ts">
import MultiComboboxInput from '@/components/selectBox/combobox/MultiComboboxInput.vue';
import { useRegionsSearch } from '@/hooks/search/useRegionsSearch';
import { Selectable } from '@/components/selectBox/selectBox';

const { regionOptions, isLoading, onQueryChange } = useRegionsSearch();

defineProps<{
  modelValue: Selectable<number>[];
}>();

defineEmits(['update:modelValue']);
</script>

<template>
  <MultiComboboxInput
    :options="regionOptions"
    :onFilter="onQueryChange"
    :isLoading="isLoading"
    :modelValue="modelValue"
    @update:modelValue="$emit('update:modelValue', $event)"
    @open="() => onQueryChange('')"
  />
</template>
