<script setup lang="ts">
import type { WithLoadedRelations } from '@/@types/global';

import pluralize from 'pluralize';

import { useSelectableRows } from '@/components/table/useSelectableRows';
import Button from '@/components/button/Button.vue';
import CheckboxInput from '@/components/checkboxInput/CheckboxInput.vue';
import MessagingPageLayout from '@/components/pageLayout/MessagingPageLayout.vue';
import Pagination from '@/components/pagination/Pagination.vue';
import PhoneNumberBadge from '@/components/messaging/phoneNumberBadge.vue';
import RecipientBadge from '@/components/messaging/recipientBadge.vue';
import SelectionBanner from '@/components/table/SelectionBanner.vue';
import SmsDropdownNavigation from '@/domains/sms/components/SmsDropdownNavigation.vue';
import TabNavItem from '@/components/tabNav/TabNavItem.vue';
import ThreadLink from '@/components/messaging/threadLink.vue';
import ThreadsEmptyState from '@/components/emptyState/ThreadsEmptyState.vue';

import EditIcon from '@/icons/line/edit.svg';
import EnvelopeIcon from '@/icons/line/envelope-check.svg';
import { ButtonColor, ButtonSize, ButtonVariant } from '@/hooks/useButtonClasses';
import DistillerData = App.Search.Data.DistillerData;
import { AppliedFilters } from '@/components/distiller/types';

const props = defineProps<{
  threads: Paginator<WithLoadedRelations<App.Sms.Data.ThreadData, 'recipient'>>;
  activeThreadId?: number | null;
  hasSmsEnabled: boolean;
  distiller: DistillerData;
  appliedFilters: AppliedFilters;
}>();

const queryParameters = useQueryParameters();

const threadsData = computed(() => props.threads.data);
const selectedState = useSelectableRows(threadsData, (thread) => thread.id);

const selectedThreadCount = computed(() => {
  if (selectedState.areAllPagesSelected.value) {
    return props.threads.meta.total;
  }
  return selectedState.selectedRowIds.value.length;
});

watch(
  () => props.threads,
  () => {
    selectedState.clearSelection();
  }
);

const form = useForm({
  method: 'put',
  fields: {
    all_threads: false,
    thread_ids: []
  },
  transform: () => ({
    all_threads: selectedState.areAllPagesSelected.value,
    thread_ids: selectedState.selectedRowIds.value
  }),
  only: ['threads', 'toasts'],
  hooks: {
    success: () => selectedState.clearSelection()
  }
});

function paramsForRoute(mergeParams?: Record<string, true | undefined>) {
  return {
    ...queryParameters,
    read: undefined,
    unread: undefined,
    starred: undefined,
    page: undefined,
    ...mergeParams
  };
}

function handleMarkAsRead() {
  form.submitWith({
    url: route('sms.threads.mark-read')
  });
}

function handleMarkAsUnread() {
  form.submitWith({
    url: route('sms.threads.mark-unread')
  });
}

const hasUnreadSelected = computed(() => {
  if (!selectedState.hasAnySelection.value) {
    return false;
  }

  const selectedIds = new Set(selectedState.selectedRowIds.value);
  return props.threads.data.some((thread) => selectedIds.has(thread.id) && !thread.is_read);
});
</script>


					<script lang="ts">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <MessagingPageLayout
    :paddedHeader="false"
    :hasActiveThread="!!activeThreadId"
    :distiller
    :appliedFilters
  >
    <template #title>
      <SmsDropdownNavigation />
    </template>

    <template #rightButtons>
      <Button
        v-if="hasSmsEnabled"
        :color="ButtonColor.primary"
        :href="route('sms.compose')"
        :iconLeft="EditIcon"
        :variant="ButtonVariant.solid"
        class="mr-4"
      >
        Compose
      </Button>
    </template>

    <template #prependTabs>
      <CheckboxInput
        v-if="hasSmsEnabled"
        :checked="selectedState.headerCheckedState.value"
        @update:checked="selectedState.onCheckedAll"
      />
    </template>

    <template #tabs v-if="!selectedState.hasAnySelection.value">
      <TabNavItem
        :isActive="
          !Object.keys(queryParameters).some((key) => ['read', 'unread', 'starred'].includes(key))
        "
        label="All"
        variant="filled"
        :href="route('sms.threads.index', paramsForRoute())"
      />
      <TabNavItem
        v-if="hasSmsEnabled"
        :isActive="queryParameters?.read === 'true'"
        label="Read"
        variant="filled"
        :href="route('sms.threads.index', paramsForRoute({ read: true }))"
      />
      <TabNavItem
        v-if="hasSmsEnabled"
        :isActive="queryParameters?.unread === 'true'"
        label="Unread"
        variant="filled"
        :href="route('sms.threads.index', paramsForRoute({ unread: true }))"
      />
      <TabNavItem
        v-if="hasSmsEnabled"
        :isActive="queryParameters?.starred === 'true'"
        label="Starred"
        variant="filled"
        :href="route('sms.threads.index', paramsForRoute({ starred: true }))"
      />
    </template>

    <template #appendTabs v-if="selectedState.hasAnySelection.value">
      <Button
        v-if="hasUnreadSelected"
        :color="ButtonColor.slate"
        :iconLeft="EnvelopeIcon"
        :isLoading="form.processing"
        :size="ButtonSize.md"
        :variant="ButtonVariant.invisible"
        type="button"
        @click="handleMarkAsRead"
      >
        Mark {{ pluralize('thread', selectedThreadCount, true) }} as read
      </Button>
      <Button
        v-if="!hasUnreadSelected && selectedState.hasAnySelection.value"
        :color="ButtonColor.slate"
        :iconLeft="EnvelopeIcon"
        :isLoading="form.processing"
        :size="ButtonSize.md"
        :variant="ButtonVariant.invisible"
        type="button"
        @click="handleMarkAsUnread"
      >
        Mark {{ pluralize('thread', selectedThreadCount, true) }} as unread
      </Button>
    </template>

    <template #selectionBanner>
      <SelectionBanner
        v-if="
          threads.meta.total > threads.meta.per_page && selectedState.isTableFullySelected.value
        "
        class="px-4 pb-2 pt-0"
        itemLabel="thread"
        :allRecordsSelected="selectedState.areAllPagesSelected.value"
        :totalRecordsCount="threads.meta.total"
        :selectedRecordsCount="selectedState.selectedRowIds.value.length"
        @toggle="selectedState.toggleAllPagesSelection"
      />
    </template>

    <template #threads>
      <div class="max-h-full min-h-0 flex-1 divide-y divide-zinc-200 overflow-y-auto" scroll-region>
        <ThreadLink
          v-for="thread in threads.data"
          :key="thread.id"
          :isActive="activeThreadId === thread.id"
          :href="
            route('sms.threads.show', {
              ...queryParameters,
              thread: thread.id
            })
          "
          :title="thread.recipient?.name ?? thread.phone_number ?? ''"
          :body="thread.recent_message?.body"
          :date="thread.last_message_timestamp"
          :options="{
            only: ['thread', 'meta', 'relatedThreads'],
            preserveScroll: true,
            preserveState: true
          }"
        >
          <template #prepend>
            <div v-if="hasSmsEnabled" class="relative flex items-center">
              <CheckboxInput
                @click.prevent.stop
                :checked="selectedState.selectedRowIds.value.includes(thread.id)"
                @update:checked="(selected) => selectedState.onChecked(selected, thread)"
                class="mr-2"
              />
              <div
                :class="[
                  'rounded-full',
                  'mr-2 h-2 w-2',
                  thread.is_read ? 'bg-zinc-300' : 'bg-indigo-600'
                ]"
              ></div>
            </div>
          </template>
          <template #appendBody>
            <div>
              <RecipientBadge
                v-if="!thread.is_phone_number_recipient"
                :isStudent="thread.is_student_recipient"
                :recipient="thread.recipient"
              />
              <PhoneNumberBadge v-else />
            </div>
          </template>
        </ThreadLink>

        <ThreadsEmptyState
          v-if="threads.data.length === 0"
          title="SMS Threads"
          :description="`You have ${threads.meta.total || 'no'} SMS threads`"
        />
      </div>

      <!-- @TODO: horizontal scrolling here is not ideal, we should implement onEachSide modifications -->
      <Pagination :maxLinks="2" compact :pagination="threads" class="overflow-x-scroll border-t" />
    </template>

    <template #thread>
      <slot>
        <div class="flex h-full w-full items-center justify-center">
          <ThreadsEmptyState
            title="SMS Threads"
            :description="
              selectedThreadCount
                ? `You have ${selectedThreadCount} threads selected`
                : `You have ${threads.meta.total || 'no'} SMS threads`
            "
          />
        </div>
      </slot>
    </template>
  </MessagingPageLayout>
</template>
