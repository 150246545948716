<script setup lang="ts">
import pluralize from 'pluralize';
import { Container } from 'vue-dndrop';
import type { DualListboxOption as DualListboxOptionType } from '@/components/selectBox/selectBox';
import DualListboxOption from './DualListboxOption.vue';
import SearchInput from '@/components/searchInput/SearchInput.vue';
import Button from '@/components/button/Button.vue';
import { ButtonColor, ButtonSize, ButtonVariant } from '@/hooks/useButtonClasses';
import { useDragAndDrop } from '@/hooks/useDragAndDrop';

const props = defineProps<{
  valuesInList: Set<string>;
  options: DualListboxOptionType[];
  optionsCount: number;
  isAvailableList: boolean;
}>();

const checkedValues = ref<Set<string>>(new Set());
const expandedOptions = ref<Set<string>>(new Set());
const searchQuery = ref('');

defineExpose({
  checkedValues
});

onMounted(() => {
  handleExpandAll();
});

const label = computed(() => (props.isAvailableList ? 'Available' : 'Chosen'));

const filteredOptions = computed(() => {
  if (!searchQuery.value) {
    return props.options;
  }

  const loweredSearchQuery = searchQuery.value.toLowerCase();

  function getMatchingOptions(option: DualListboxOptionType) {
    if (option.label.toLowerCase().includes(loweredSearchQuery)) {
      return option;
    }
    if (option.children?.length) {
      const filteredChildren = option.children.map(getMatchingOptions).filter(Boolean);

      if (filteredChildren.length) {
        return { ...option, children: filteredChildren };
      }
    }

    return null;
  }

  return props.options.map(getMatchingOptions).filter(Boolean);
});

const { applyDrag } = useDragAndDrop<string>([]);

function handleExpandAll() {
  function walkOptions(option: DualListboxOptionType) {
    if (option.children) {
      option.children.forEach(walkOptions);
    }

    expandedOptions.value.add(option.value);
  }

  props.options.forEach(walkOptions);
}

function handleCollapseAll() {
  expandedOptions.value.clear();
}

function handleToggleExpand({ expanded, values }: { expanded: boolean; values: string[] }) {
  if (expanded) {
    values.forEach((value) => expandedOptions.value.add(value));
    return;
  }

  values.forEach((value) => expandedOptions.value.delete(value));
}

function handleColumnOrderChange(order) {
  const updatedItems = applyDrag([...props.valuesInList], order);

  checkedValues.value = new Set(updatedItems);
}
</script>

<template>
  <div class="flex flex-1 flex-col">
    <h4 class="text-base font-bold">{{ label }}</h4>
    <p class="text-sm text-zinc-600">
      {{ pluralize('column', optionsCount, true) }} {{ label.toLowerCase() }}
    </p>
    <SearchInput v-model="searchQuery" class="my-2" />

    <div class="flex justify-between pb-1">
      <Button
        :variant="ButtonVariant.invisible"
        :size="ButtonSize.xs"
        :color="ButtonColor.secondary"
        @click="handleExpandAll"
        >Expand All</Button
      >
      <Button
        :variant="ButtonVariant.invisible"
        :size="ButtonSize.xs"
        :color="ButtonColor.secondary"
        @click="handleCollapseAll"
        >Collapse All</Button
      >
    </div>

    <div class="flex-1 overflow-y-auto rounded-lg border border-zinc-200 py-2 pl-2 pr-4">
      <Container @drop="handleColumnOrderChange" dragHandleSelector=".handle" class="flex flex-col">
        <DualListboxOption
          v-for="option in filteredOptions"
          :key="option.value"
          :option="option"
          :valuesInList="valuesInList"
          :checkedValues="checkedValues"
          :isAvailableList="isAvailableList"
          :expandedOptions="expandedOptions"
          @toggleExpand="handleToggleExpand"
        />
      </Container>
    </div>
  </div>
</template>
