<script setup lang="ts">
import Fieldset from '@/components/fieldset/Fieldset.vue';
import StepContainer from '@/components/stepContainer/StepContainer.vue';
import ReportColumnsForm from '@/domains/reports/components/form/ReportColumnsForm.vue';
import StepFooter from '@/components/stepContainer/StepFooter.vue';
import CreateCustomReport from './CreateCustomReport.vue';
import { AppliedFilters } from '@/components/distiller/types';

const props = defineProps<{
  customReport: App.Reports.Data.CustomReportData;
  filters: AppliedFilters;
  columnFilters: AppliedFilters;
  columns: App.Reports.Data.ColumnData[];
  reportTemplate?: App.Reports.Data.ReportTemplateData;
}>();

const nextForm = useForm({
  url: route('custom-reports.order-columns.create', { customReport: props.customReport.id }),
  only: ['columns', 'filters', 'column_filters'],
  key: `custom-reports.columns.next.${props.customReport.id}`,
  fields: {
    columns: props.columns ?? [],
    filters: props.filters ?? {},
    column_filters: props.columnFilters ?? {},
    report_template_id: props.reportTemplate?.id ?? null
  }
});

const prevForm = useForm({
  url: route('custom-reports.create', { customReport: props.customReport.id }),
  only: ['columns', 'filters', 'column_filters'],
  key: `custom-reports.columns.prev.${props.customReport.id}`,
  fields: {
    filters: props.filters,
    column_filters: props.columnFilters,
    report_template_id: props.reportTemplate?.id ?? null
  }
});
</script>


					<script lang="ts">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <CreateCustomReport :currentStep="1" :customReport="customReport">
    <StepContainer title="Select columns">
      <div class="mx-auto w-full max-w-6xl space-y-5">
        <div class="space-y-5">
          <Fieldset
            title="Select columns for the report"
            description="Select the columns you want to be exported in the report. You can add groups of columns or individual columns."
          >
            <ReportColumnsForm :customReport="customReport" :form="nextForm" />
          </Fieldset>

          <StepFooter
            :isNextDisabled="nextForm.fields.columns.length === 0"
            :isLoading="nextForm.processing"
            @previous="prevForm.submit()"
            @next="nextForm.submit()"
          />
        </div>
      </div>
    </StepContainer>
  </CreateCustomReport>
</template>
