import { onBeforeMount } from 'vue';
import { router } from 'hybridly/vue';

interface ScrollOptions {
  behavior?: ScrollBehavior;
  block?: ScrollLogicalPosition;
  delay?: number;
}

export function useAnchorScroll(options: ScrollOptions = {}) {
  const { behavior = 'smooth', block = 'start', delay = 0 } = options;

  onBeforeMount(() => {
    if (window.location.hash) {
      const hash = window.location.hash;

      router
        .navigate({
          url: window.location.pathname + window.location.search,
          preserveState: true,
          replace: true
        })
        .then(() => {
          setTimeout(() => {
            const element = document.getElementById(hash.substring(1));
            if (element) {
              element.scrollIntoView({ behavior, block });
            }
          }, delay);
        });
    }
  });
}
