<script setup lang="ts">
import { RouterLink } from 'hybridly/vue';
import Badge from '@/components/badge/Badge.vue';
import VerticalDivider from '@/components/verticalDivider/VerticalDivider.vue';
import getAsyncIcon from '@/components/icons/AsyncIcon';

import FileEditAltIcon from '@/icons/line/file-edit-alt.svg';
import CheckCircleIcon from '@/icons/line/check-circle.svg';
import AngleRightIcon from '@/icons/line/angle-right.svg';
import IconBadge from '@/components/badge/IconBadge.vue';
import pluralize from 'pluralize';

const props = defineProps<{
  uploadType: App.Bulkinator.Data.UploadTypeData;
  uploadSessions: App.Bulkinator.Data.UploadSessionData[];
}>();

const draftsLabel = computed(() => {
  const count = props.uploadSessions.filter(
    (session) => session.status.value !== 'finished'
  ).length;

  return pluralize('Draft', count, true);
});

const finishedLabel = computed(() => {
  const count = props.uploadSessions.filter(
    (session) => session.status.value === 'finished'
  ).length;

  return `${count} Finished`;
});
</script>

<template>
  <RouterLink
    :href="route('upload-sessions.draft.index', { uploadType: uploadType.id })"
    class="group flex cursor-pointer items-center gap-2 rounded-2xl border border-slate-200 px-4 py-3 shadow-sm hover:border-slate-400 hover:shadow-md focus:border-slate-500 active:ring-3 active:ring-slate-300/30"
  >
    <IconBadge
      :icon="getAsyncIcon(uploadType.icon)"
      iconClasses="opacity-70 group-hover:opacity-100"
    />

    <p class="font-semibold text-slate-900">{{ uploadType.name }}</p>
    <Badge size="lg" variant="soft" :label="uploadSessions.length" />
    <VerticalDivider />
    <div class="flex flex-1 items-center gap-2">
      <Badge size="lg" variant="soft" :label="draftsLabel" :iconLeft="FileEditAltIcon" />
      <Badge size="lg" variant="soft" :label="finishedLabel" :iconLeft="CheckCircleIcon" />
    </div>
    <AngleRightIcon class="h-6 w-6 text-slate-500 opacity-70 group-hover:opacity-100" />
  </RouterLink>
</template>
