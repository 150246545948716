<script setup lang="ts">
import FormTextInput from '@/components/formTextInput/FormTextInput.vue';

const props = defineProps<{
  rule: App.Configuration.Data.FieldRuleData;
  errors?: Record<string, string>;
  isEditing?: boolean;
}>();

const emit = defineEmits<{
  change: [parameters: unknown];
}>();

function handleInputChange(attribute: string, value: string | null) {
  emit('change', {
    ...props.rule.parameters,
    [attribute]: value ? parseInt(value) : null
  });
}
</script>

<template>
  <div class="flex gap-2">
    <FormTextInput
      :error="errors?.min"
      :modelValue="rule.parameters?.min ?? null"
      label="Minimum value that can be saved"
      name="number_field_min"
      placeholder="e.g. 1"
      type="number"
      @update:modelValue="(value) => handleInputChange('min', value)"
    />
    <FormTextInput
      :error="errors?.max"
      :modelValue="rule.parameters?.max ?? null"
      label="Maximum value that can be saved"
      name="number_field_max"
      placeholder="e.g. 100"
      type="number"
      @update:modelValue="(value) => handleInputChange('max', value)"
    />
  </div>
</template>
