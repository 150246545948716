import { RouteName } from 'hybridly';
import { useAuth } from '@/hooks/useAuth';

import IconAwardAlt from '@/icons/line/award-alt.svg';
import IconBuilding from '@/icons/line/building.svg';
import IconBullseye from '@/icons/line/bullseye.svg';
import IconChart from '@/icons/line/chart.svg';
import IconCommentAltNotes from '@/icons/line/comment-alt-notes.svg';
import IconComments from '@/icons/line/comments.svg';
import IconComparison from '@/icons/line/comparison.svg';
import IconCustomField from '@/icons/line/custom-field.svg';
import IconDownloadAlt from '@/icons/line/download-alt.svg';
import IconExclamationTriangle from '@/icons/line/exclamation-triangle.svg';
import IconFileCheckAlt from '@/icons/line/file-check-alt.svg';
import IconFilesLandscapeAlt from '@/icons/line/files-landscapes-alt.svg';
import IconGraduationCap from '@/icons/line/graduation-cap.svg';
import IconGraphBar from '@/icons/line/graph-bar.svg';
import IconImport from '@/icons/line/import.svg';
import IconLayerGroup from '@/icons/line/layer-group.svg';
import IconMapPin from '@/icons/line/map-pin.svg';
import IconMegaphone from '@/icons/line/megaphone.svg';
import IconRobot from '@/icons/line/robot.svg';
import IconSetting from '@/icons/line/setting.svg';
import IconShieldExclamation from '@/icons/line/shield-exclamation.svg';
import IconStudentGroups from '@/icons/line/student-groups.svg';
import IconUniversity from '@/icons/line/university.svg';
import IconUsersAlt from '@/icons/line/users-alt.svg';
import IconFileCheck from '@/icons/line/file-check.svg';
import { useUnreadSmsCount } from './useUnreadSmsCount';
import { useAdmitHubEscalationCount } from './useAdmitHubEscalationCount';

export type MenuItem = {
  label: string;
  icon: Component;
  isCollapsed: boolean;
  route: RouteName;
  isVisible: boolean;
  matches?: boolean;
  badgeCount?: number;
};

export type MenuItemGroup = {
  label: string | null;
  isVisible: boolean;
  items: MenuItem[];
};

export function useNavigation(): ComputedRef<MenuItemGroup[]> {
  const { hasPermission } = useAuth();
  const { current } = useRoute();
  const routesStartingWith = (base: string) => current.value?.startsWith(base);
  const unresolvedEscalationCount = useAdmitHubEscalationCount();
  const unreadSmsThreadCount = useUnreadSmsCount();

  return computed(() => {
    const navigationGroups = [
      {
        label: null,
        isVisible: true,
        items: [
          {
            label: 'Dashboard',
            icon: IconChart,
            route: 'dashboard.show',
            matches: routesStartingWith('dashboard'),
            isVisible: true
          },
          {
            label: 'Students',
            icon: IconGraduationCap,
            route: 'students.index',
            matches: routesStartingWith('students'),
            isVisible: hasPermission('read-student')
          },
          {
            label: 'Parents/Guardians',
            icon: IconUsersAlt,
            route: 'parents.index',
            matches: routesStartingWith('parents'),
            isVisible: hasPermission('access-student-parents')
          },
          {
            label: 'Interactions',
            icon: IconCommentAltNotes,
            route: 'interactions.index',
            matches: routesStartingWith('interactions'),
            isVisible: hasPermission('read-student')
          },
          {
            label: 'Student Groups',
            icon: IconStudentGroups,
            route: 'student-groups.index',
            matches: routesStartingWith('student-groups'),
            isVisible: hasPermission('read-student')
          },
          {
            label: 'Surveys',
            icon: IconSetting,
            route: 'surveys.index',
            matches: routesStartingWith('surveys'),
            isVisible: hasPermission('read-survey')
          },
          {
            label: 'Bulkinator',
            icon: IconLayerGroup,
            route: 'upload-types.index',
            matches: routesStartingWith('upload-types'),
            isVisible: hasPermission('access-bulkinator')
          },
          {
            label: 'Data Imports',
            icon: IconImport,
            route: 'data-imports.index',
            matches: routesStartingWith('data-imports'),
            isVisible: hasPermission('access-data-imports')
          },
          {
            label: 'Success Stories',
            icon: IconAwardAlt,
            route: 'success-stories.index',
            matches: routesStartingWith('success-stories'),
            isVisible: hasPermission('read-success-story')
          },
          {
            label: 'GRACE Resources',
            icon: IconFileCheckAlt,
            route: 'grace-resources.show',
            matches: routesStartingWith('grace-resources'),
            isVisible: true
          }
        ]
      },
      {
        label: 'MESSAGING',
        isVisible: true,
        items: [
          {
            label: 'SMS',
            icon: IconComments,
            route: 'sms.threads.index',
            badgeCount: unreadSmsThreadCount.value,
            matches: routesStartingWith('sms') && !routesStartingWith('sms.campaigns'),
            isVisible: hasPermission('read-sms')
          },
          {
            label: 'Campaigns',
            icon: IconMegaphone,
            route: 'sms.campaigns.index',
            matches: routesStartingWith('sms.campaigns'),
            isVisible: hasPermission('read-sms-campaign')
          },
          {
            label: 'Chatbot',
            icon: IconRobot,
            route: 'admithub.contacts.index',
            matches: routesStartingWith('admithub.contacts'),
            badgeCount: unresolvedEscalationCount.value,
            isVisible: hasPermission('read-admit-hub-chatbot') // TODO: This will also need to check if the program uses the chatbot
          },
          {
            label: 'Chatbot Escalations',
            icon: IconShieldExclamation,
            route: 'admithub.escalations.index',
            matches: routesStartingWith('admithub.escalations'),
            isVisible: hasPermission('read-admit-hub-escalations') // TODO: This will also need to check if the program uses the chatbot
          }
        ]
      },
      {
        label: 'REPORTS',
        isVisible: true,
        items: [
          {
            label: 'Reports',
            icon: IconComparison,
            route: 'report-templates.index',
            matches: routesStartingWith('report-templates') || routesStartingWith('custom-reports'),
            isVisible: true
          },
          {
            label: 'Report Results',
            icon: IconDownloadAlt,
            route: 'report-results.index',
            matches: routesStartingWith('report-results'),
            isVisible: true
          }
        ]
      },
      {
        label: 'CONFIGURATION',
        isVisible: true,
        items: [
          {
            label: 'Alerts',
            icon: IconExclamationTriangle,
            route: 'alerts.index',
            matches: routesStartingWith('alerts'),
            isVisible: hasPermission('read-alert')
          },
          {
            label: 'Test Types',
            icon: IconGraphBar,
            route: 'custom-tests.index',
            matches: routesStartingWith('custom-tests') || routesStartingWith('ap-courses'),
            isVisible: hasPermission('read-custom-test')
          },
          {
            label: 'Users',
            icon: IconUsersAlt,
            route: 'users.index',
            matches: routesStartingWith('users'),
            isVisible: hasPermission('read-user')
          },
          {
            label: 'Regions',
            icon: IconMapPin,
            route: 'regions.index',
            matches: routesStartingWith('regions'),
            isVisible: hasPermission('read-region')
          },
          {
            label: 'Programs',
            icon: IconFilesLandscapeAlt,
            route: 'programs.index',
            matches: routesStartingWith('programs'),
            isVisible: hasPermission('read-program')
          },
          {
            label: 'Schools',
            icon: IconBuilding,
            route: 'schools.index',
            matches: routesStartingWith('schools'),
            isVisible: hasPermission('read-school')
          },
          {
            label: 'Institutions',
            icon: IconUniversity,
            route: 'institutes.index',
            matches: routesStartingWith('institutes'),
            isVisible: hasPermission('read-institute')
          },
          {
            label: 'Custom Fields',
            icon: IconCustomField,
            route: 'program-student-fields.index',
            matches: routesStartingWith('program-student-fields'),
            isVisible: hasPermission('read-program-student-field')
          },
          {
            label: 'Goals',
            icon: IconBullseye,
            route: 'goals.index',
            matches: routesStartingWith('goals'),
            isVisible: hasPermission('read-goal')
          },
          {
            label: 'Documents',
            icon: IconFileCheck,
            route: 'documents.index',
            matches: routesStartingWith('documents'),
            isVisible: hasPermission('read-document-acknowledgements')
          }
        ]
      }
    ] as MenuItemGroup[];

    const filteredGroupsAndItems = [] as MenuItemGroup[];
    for (const navigationGroup of navigationGroups) {
      const visibleItems = [] as MenuItem[];
      for (const item of navigationGroup.items) {
        if (item.isVisible) {
          visibleItems.push(item);
        }
      }

      if (visibleItems.length === 0) {
        // this group has no visible items, skip it and carry on
        continue;
      }

      navigationGroup.isVisible = true;
      navigationGroup.items = visibleItems;
      filteredGroupsAndItems.push(navigationGroup);
    }

    return filteredGroupsAndItems;
  });
}
