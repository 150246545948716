<script setup lang="ts">
import InstituteComboBoxInput from '@/components/institutes/InstituteComboBoxInput.vue';
import ProgramComboBoxInput from '@/components/programs/ProgramComboBoxInput.vue';
import RegionComboBoxInput from '@/components/regions/RegionComboBoxInput.vue';
import ScholarshipComboBoxInput from '@/components/scholarships/ScholarshipComboBoxInput.vue';
import { Selectable, SelectableValue } from '@/components/selectBox/selectBox';
import StudentComboBoxInput from '@/components/students/StudentComboBoxInput.vue';

import { getEntity } from '@/api/UploadEntityApi';

const props = defineProps<{
  modelValue: number | string | null;
  name: string;
  hasError: boolean;
  column: App.Bulkinator.Data.UploadColumnData;
  id?: string;
  isInline?: boolean;
  isSuccessful?: boolean;
  isLoading?: boolean;
}>();

const emit = defineEmits<{
  'update:modelValue': [value: unknown];
  saveValue: [];
}>();

const selectedOpt = ref<Selectable<SelectableValue> | null>(null);

const buildEntityMap = {
  student: buildSelectableStudent,
  region: buildSelectableRegion,
  program: buildSelectableProgram,
  institute: buildSelectableInstitute,
  scholarship: buildSelectableScholarship
};

onMounted(() => {
  fetchEntity();
});

watch(
  () => props.modelValue,
  () => {
    fetchEntity();
  }
);

function fetchEntity() {
  if (!props.modelValue) {
    return;
  }

  getEntity(props.column.search?.value, props.modelValue)
    .then((entity: Selectable<SelectableValue>) => {
      selectedOpt.value = buildEntityMap[props.column.search?.value](entity);
    })
    .catch(() => {
      if (props.column.search?.value === 'scholarship') {
        selectedOpt.value = {
          label: props.modelValue as string,
          value: props.modelValue as string
        };
      } else {
        selectedOpt.value = {
          label: 'Error loading record',
          value: props.modelValue
        };
      }
    });
}

const component = computed(() => {
  return {
    region: RegionComboBoxInput,
    program: ProgramComboBoxInput,
    institute: InstituteComboBoxInput,
    scholarship: ScholarshipComboBoxInput,
    student: StudentComboBoxInput
  }[props.column.search?.value];
});

const selectedOptionAttribute = computed(() => {
  return {
    region: 'label',
    program: 'label',
    institute: 'value',
    scholarship: 'label',
    student: 'value'
  }[props.column.search?.value];
});

function handleChange(selected: Selectable<SelectableValue> | null) {
  selectedOpt.value = selected;
  emit('update:modelValue', selected?.[selectedOptionAttribute.value] ?? null);
  emit('saveValue');
}
</script>

<template>
  <component
    :is="component"
    :id="id"
    :isLoading="isLoading"
    :isSuccessful="isSuccessful"
    :modelValue="selectedOpt"
    class="w-full"
    :inputClasses="isInline ? '!h-full !border-none !rounded-sm !bg-transparent' : null"
    @update:modelValue="handleChange"
  />
</template>
