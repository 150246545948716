import axios from 'axios';

export async function search(
  filters: Record<string, unknown>
): Promise<App.Configuration.Data.InstituteData[]> {
  return axios.get(route('api.institutes.search', filters)).then((res) => res.data);
}

export async function getById(instituteId: number): Promise<App.Configuration.Data.InstituteData> {
  return axios
    .get(route('api.institutes.show', { institute: instituteId }))
    .then((res) => res.data);
}
