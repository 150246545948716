<script setup lang="ts">
import type { FormFieldProps, FormFieldSlots } from '@/components/formField/FormField';
import type {
  RichTextInputProps,
  RichTextPayload
} from '@/components/richTextInput/RichTextInput.vue';
import FormField from '@/components/formField/FormField.vue';
import RichTextInput from '@/components/richTextInput/RichTextInput.vue';
import { useFormField } from '@/components/formField/useFormField';

type FormRichTextInputProps = Omit<RichTextInputProps, 'hasError'> & FormFieldProps;

const props = defineProps<FormRichTextInputProps>();

defineSlots<FormFieldSlots>();

const emit = defineEmits<{
  'update:modelValue': [value: RichTextPayload];
}>();

function onValueChange(event: RichTextPayload): void {
  emit('update:modelValue', event);
}

const { formFieldProps, slots } = useFormField({ props });
</script>

<template>
  <FormField v-bind="formFieldProps">
    <RichTextInput
      v-bind="$props"
      :hasError="formFieldProps.hasError"
      @update:modelValue="onValueChange"
    />
    <template v-for="slot of slots" #[slot]>
      <slot :name="slot" />
    </template>
  </FormField>
</template>
