<script setup lang="ts">
import type { ShowStudentParentProps } from '@/domains/students/views/ShowStudentParent.vue';

import { useAuth } from '@/hooks/useAuth';

import IconButton from '@/components/button/IconButton.vue';
import InformationCollapsible from '@/components/collapsible/InformationCollapsible.vue';
import Slideout from '@/components/slideout/Slideout.vue';
import DefinitionList from '@/components/definitionList/DefinitionList.vue';
import DefinitionListItem from '@/components/definitionList/DefinitionListItem.vue';

import EditAlt from '@/icons/line/edit-alt.svg';
import IconUsersAlt from '@/icons/line/users-alt.svg';
import IconUser from '@/icons/line/user.svg';
import IconEnvelope from '@/icons/line/envelope.svg';
import IconPhone from '@/icons/line/phone.svg';
import IconGraduationCap from '@/icons/line/graduation-cap.svg';
import Badge from '@/components/badge/Badge.vue';

defineProps<ShowStudentParentProps>();

const { show, close } = useDialog();

const { hasPermission } = useAuth();
</script>

<template>
  <Slideout
    :title="parent.name ?? 'Parent/Guardian Information'"
    :isOpen="show"
    @onClose="close"
    :icon="IconUsersAlt"
  >
    <template #actions>
      <IconButton
        v-if="hasPermission('update-student-parent')"
        :href="route('parents.edit', { parent: parent.id })"
        :icon="EditAlt"
        ariaLabel="Edit Parent/Guardian"
        size="sm"
        variant="soft"
      />
    </template>
    <InformationCollapsible title="Parent/Guardian Information">
      <DefinitionList>
        <DefinitionListItem
          :description="parent.first_name ? parent.first_name : '--'"
          :icon="IconUser"
          title="First Name"
        />
        <DefinitionListItem
          :description="parent.last_name ? parent.last_name : '--'"
          :icon="IconUser"
          title="Last Name"
        />
        <DefinitionListItem
          :description="parent.email ? parent.email : '--'"
          :icon="IconEnvelope"
          title="Email"
        />
        <DefinitionListItem
          :description="parent.cell_phone ? parent.cell_phone : '--'"
          :icon="IconPhone"
          title="Cell Phone"
        />
        <DefinitionListItem
          :icon="IconGraduationCap"
          title="Students"
          :description="!parent.students?.length ? 'No students' : undefined"
        >
          <div v-if="parent.students?.length" class="flex flex-wrap gap-1">
            <Badge
              v-for="student in parent.students"
              :key="student.id"
              :iconLeft="IconGraduationCap"
              :label="student.name"
              :href="route('students.show', { student: student.id })"
              variant="outlined"
              shape="rounded"
            />
          </div>
        </DefinitionListItem>
      </DefinitionList>
    </InformationCollapsible>
  </Slideout>
</template>
