<script setup lang="ts">
type EnumData = App.Base.Data.EnumData;

import { countryOptions } from '@/utils/countries';
import { stateOptions } from '@/utils/states';

import CenteredContent from '@/components/content/CenteredContent.vue';
import InstituteForm from '@/components/institutes/InstituteForm.vue';

import InstituteData = App.Configuration.Data.InstituteData;

const props = defineProps<{
  institute: InstituteData;
  programLengths: Array<EnumData>;
  selectivities: Array<EnumData>;
  returnUrl?: string;
}>();

const form = useForm({
  method: 'PUT',
  url: route('institutes.update', { institute: props.institute.id }),
  fields: {
    name: props.institute.name,
    program_length: props.institute.program_length.value,
    selectivity: props.institute.selectivity,
    hbcu: props.institute.hbcu,
    ipeds: props.institute.ipeds,
    css_required: props.institute.css_required,
    address: props.institute.address,
    city: props.institute.city,
    state: stateOptions().find((option) => option.value === props.institute.state),
    country: countryOptions().find((option) => option.value === props.institute.country),
    zip_code: props.institute.zip_code,
    waiver_fee: props.institute.waiver_fee
  },
  transform: (fields) => ({
    ...fields,
    selectivity: fields.selectivity?.value,
    state: fields.state?.value,
    state_abbreviation: fields.state?.state_abbreviation,
    country: fields.country?.value,
    country_abbreviation: fields.country?.country_abbreviation
  })
});
</script>


					<script lang="ts">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <CenteredContent>
    <InstituteForm
      :programLengths="programLengths"
      :selectivities="selectivities"
      :form="form"
      isEditing
      :returnUrl="returnUrl"
    />
  </CenteredContent>
</template>
