<script setup lang="ts">
import ListboxInput from './ListboxInput.vue';

defineProps<{
  modelValue: boolean | null;
  name: string;
  hasError: boolean;
  column: App.Bulkinator.Data.UploadColumnData;
  id?: string;
  isSuccessful?: boolean;
  isLoading?: boolean;
}>();

defineEmits<{
  'update:modelValue': [value: unknown];
  saveValue: [];
}>();

const options = [
  {
    value: null,
    label: 'Not Set'
  },
  {
    value: false,
    label: 'No'
  },
  {
    value: true,
    label: 'Yes'
  }
];
</script>

<template>
  <ListboxInput
    :modelValue="modelValue"
    :id="id"
    :name="name"
    :isSuccessful="isSuccessful"
    :isLoading="isLoading"
    :hasError="hasError"
    :options="options"
    :column="column"
    @update:modelValue="$emit('update:modelValue', $event)"
    @saveValue="$emit('saveValue')"
  />
</template>
