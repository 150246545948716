<script setup lang="ts">
type SurveyData = App.Surveys.Data.SurveyData;

import SurveyOverviewBlockCard from '@/components/surveys/surveys/SurveyOverviewBlockCard.vue';
import CenteredContent from '@/components/content/CenteredContent.vue';
import MetricCard from '@/components/metricCard/MetricCard.vue';
import PageSection from '@/components/pageSection/PageSection.vue';
import ShowSurveyPageLayout from '@/components/surveys/surveys/ShowSurveyPageLayout.vue';

defineProps<{
  survey: SurveyData;
  metrics: {
    views: number;
    submissions: number;
    completion_rate: string;
  };
}>();
</script>


					<script lang="ts">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <ShowSurveyPageLayout :survey="survey" :title="survey.name">
    <template #metricCards>
      <MetricCard title="Views">{{ metrics.views }}</MetricCard>
      <MetricCard title="Submissions">{{ metrics.submissions }}</MetricCard>
      <MetricCard title="Completion Rate">{{ metrics.completion_rate }}</MetricCard>
    </template>
    <template #fullWidth>
      <div class="px-5">
        <CenteredContent size="lg">
          <PageSection title="Response Summary" :paddedContent="false">
            <div class="flex flex-col gap-y-6">
              <SurveyOverviewBlockCard
                v-for="block in survey.blocks"
                :block="block"
                :key="block.id"
              />
            </div>
          </PageSection>
        </CenteredContent>
      </div>
    </template>
  </ShowSurveyPageLayout>
</template>
