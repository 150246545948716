<script setup lang="ts">
import ListboxInput from '@/components/selectBox/listbox/ListboxInput.vue';
import { Selectable, SelectableValue } from '@/components/selectBox/selectBox';

const props = defineProps<{
  modelValue: SelectableValue;
  name: string;
  hasError: boolean;
  column: App.Bulkinator.Data.UploadColumnData;
  id?: string;
  options?: Selectable<SelectableValue>[];
  isInline?: boolean;
  isSuccessful?: boolean;
  isLoading?: boolean;
}>();

const emit = defineEmits<{
  'update:modelValue': [value: unknown];
  saveValue: [];
}>();

const options = computed<Selectable<SelectableValue>[]>(() => {
  return (
    props.options ||
    props.column.options?.map((option) => ({
      value: option,
      label: option
    })) ||
    []
  );
});

const selectedValue = computed(
  () =>
    options.value.find((opt) => opt.value === props.modelValue) ??
    ({
      value: props.modelValue,
      label: props.modelValue as string
    } as Selectable<SelectableValue>)
);

function handleChange(selected: Selectable<string | number | boolean | null> | null) {
  emit('update:modelValue', selected?.value);
  emit('saveValue');
}
</script>

<template>
  <ListboxInput
    :id="id"
    :hasError="hasError"
    :isLoading="isLoading"
    :isSuccessful="isSuccessful"
    :modelValue="selectedValue"
    :options="options ?? []"
    :inputClasses="isInline ? '!h-full !border-none !rounded-sm !bg-transparent' : null"
    @update:modelValue="handleChange"
  />
</template>
