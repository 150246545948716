<script setup lang="tsx">
type EnumData = App.Base.Data.EnumData;

import type { CustomCells, HybridlyRow } from '@/components/table/Table';
import type { AppliedFilters } from '@/components/distiller/types';

import { bulkCreate as bulkCreateFinancialAid } from '@/api/FinancialAidApi';
import { bulkCreate as bulkCreateCollegeApplication } from '@/api/CollegeApplicationApi';
import { bulkCreate as bulkCreateScholarship } from '@/api/ScholarshipApi';

import { ButtonColor, ButtonSize, ButtonVariant } from '@/hooks/useButtonClasses';
import { useAuth } from '@/hooks/useAuth';
import { useFilters } from '@/hooks/useFilters';
import { useHybridlyTable } from '@/hooks/useHybridlyTable';
import { useTableRowClick } from '@/hooks/useTableRowClick';

import BulkAddStudentGroupStudentDialog from '@/domains/students/components/dialogs/BulkAddStudentGroupStudentDialog.vue';
import BulkCreateStudentGroupDialog from '@/domains/students/components/dialogs/BulkCreateStudentGroupDialog.vue';
import BulkCreateStudentInteractionDialog from '@/domains/students/components/dialogs/BulkCreateStudentInteractionDialog.vue';
import BulkCreateStudentNoteDialog from '@/domains/students/components/dialogs/BulkCreateStudentNoteDialog.vue';
import BulkEmptyTrashButton from '@/components/table/BulkEmptyTrashButton.vue';
import BulkRemoveStudentFromStudentGroupDialog from '@/domains/students/components/dialogs/BulkRemoveStudentFromStudentGroupDialog.vue';
import BulkRestoreButton from '@/components/table/BulkRestoreButton.vue';
import BulkTrashButton from '@/components/table/BulkTrashButton.vue';
import BulkUpdateEnrollmentStatusDialog from '@/domains/students/components/dialogs/BulkUpdateEnrollmentStatusDialog.vue';
import BulkUpdateStudentPostSecondaryPlanDialog from '@/domains/students/components/dialogs/BulkUpdateStudentPostSecondaryPlanDialog.vue';
import Button from '@/components/button/Button.vue';
import EmptyState from '@/components/emptyState/EmptyState.vue';
import IndexPageLayout from '@/components/pageLayout/IndexPageLayout.vue';
import MenuButton from '@/components/button/MenuButton.vue';
import MenuItem from '@/components/menu/MenuItem.vue';
import TableBadgesCell from '@/components/table/TableBadgesCell.vue';
import TableBooleanCell from '@/components/table/TableBooleanCell.vue';
import TableDateCell from '@/components/table/TableDateCell.vue';
import TableEnumCell from '@/components/table/TableEnumCell.vue';
import TabNavDivider from '@/components/tabNav/TabNavDivider.vue';
import TabNavItem from '@/components/tabNav/TabNavItem.vue';
import VerticalDivider from '@/components/verticalDivider/VerticalDivider.vue';

import Building from '@/icons/line/building.svg';
import Check from '@/icons/line/check.svg';
import Clock from '@/icons/line/clock.svg';
import ExternalLinkAlt from '@/icons/line/external-link-alt.svg';
import Message from '@/icons/line/message.svg';
import Plus from '@/icons/line/plus.svg';
import Times from '@/icons/line/times.svg';
import UsersAlt from '@/icons/line/users-alt.svg';
import LayerGroup from '@/icons/line/layer-group.svg';

type StudentRow = HybridlyRow<App.Students.Data.StudentData>;

const props = defineProps<{
  hasSmsEnabled: boolean;
  appliedFilters: AppliedFilters;
  archiveActive: boolean;
  counts: {
    active_count: number;
    archived_count: number;
  };
  defaultSchool: App.Configuration.Data.SchoolData;
  distiller: App.Search.Data.DistillerData;
  enrollmentStatuses: EnumData[];
  postSecondaryPlans: EnumData[];
  students: Table<App.Students.Data.StudentData>;
}>();

const isSendingSms = ref(false);

const { hasPermission } = useAuth();

const { table, tableSelectionState } = useHybridlyTable<App.Students.Data.StudentData, 'cursor'>(
  props,
  'students'
);
const { handleRowClick } = useTableRowClick();

const [isInteractionOpen, toggleInteractionOpen] = useToggle(false);
const [isPostSecondaryPlanOpen, togglePostSecondaryPlanOpen] = useToggle(false);
const [isNoteOpen, toggleNoteOpen] = useToggle(false);
const [isEnrollmentStatusOpen, toggleEnrollmentStatusOpen] = useToggle(false);
const [isRemoveStudentGroupStudentOpen, toggleRemoveStudentGroupStudentOpen] = useToggle(false);
const [isCreateStudentGroupOpen, toggleCreateStudentGroupOpen] = useToggle(false);
const [isAddStudentGroupStudentOpen, toggleAddStudentGroupStudentOpen] = useToggle(false);
const queryParameters = useQueryParameters();

const { encodeFilters } = useFilters();

const encodedFilters = computed(() => encodeFilters(props.appliedFilters));

const isUpdateFinancialAidLoading = ref(false);
const isAddCollegeApplicationLoading = ref(false);
const isAddScholarshipLoading = ref(false);
const isAddOrUpdateMenuOpen = ref<boolean>(false);

const computedSelectedStudentsCount = computed(() =>
  tableSelectionState.value.areAllRecordsSelected
    ? props.counts.active_count
    : tableSelectionState.value.selectedRowIds.length
);

function getFinancialAidStatusIcon(status: string) {
  return {
    none: Times,
    'created-fsa-id': Clock,
    'in-progress': Clock,
    submitted: Clock,
    approved: Check,
    'not-filing': Times
  }[status];
}

const customCells = {
  school: ({ row, column }) => {
    return (
      <TableBadgesCell
        badgesToDisplay={1}
        itemIcon={Building}
        items={[row.value(column.key).name]}
      />
    );
  },
  dob: ({ row, column }) => <TableDateCell date={row.value(column.key)} />,
  status: ({ row, column }) => <TableEnumCell enumData={row.value(column.key)} />,
  last_meeting: ({ row, column }) => <TableDateCell date={row.value(column.key)} />,
  groups: ({ row, column }) => (
    <TableBadgesCell
      badgesToDisplay={2}
      badgeVariant="soft"
      itemIcon={UsersAlt}
      items={row.value(column.key).map((group: App.Students.Data.StudentGroupData) => group.name)}
    />
  ),
  grade: ({ row, column }) => <TableEnumCell enumData={row.value(column.key)} />,
  financial_aid: ({ row, column }) => (
    <TableBadgesCell
      badgesToDisplay={1}
      badgeVariant="soft"
      itemIcon={getFinancialAidStatusIcon(row.value('financial_aid').status.value)}
      items={[row.value(column.key).status.label]}
    />
  ),
  alternative_financial_aid: ({ row }) => (
    <TableBooleanCell boolean={row.value('financial_aid').is_alternative} />
  )
} as CustomCells<
  App.Students.Data.StudentData & {
    alternative_financial_aid: boolean;
  }
>;

function handleStudentRowClick(event: MouseEvent, row: StudentRow) {
  const url = route('students.show', {
    student: row.key
  });
  handleRowClick(url, event);
}

function handleToggleAddOrUpdateMenuOpenState() {
  isAddOrUpdateMenuOpen.value = !isAddOrUpdateMenuOpen.value;
}

async function handleAddCollegeApplicationClick() {
  isAddCollegeApplicationLoading.value = true;

  const response = await bulkCreateCollegeApplication({
    ids: tableSelectionState.value.selectedRowIds || [],
    filters: queryParameters.filters || null,
    all: tableSelectionState.value.areAllRecordsSelected
  });

  isAddCollegeApplicationLoading.value = false;
  handleToggleAddOrUpdateMenuOpenState();
  window.open(response.session_url, '_blank', 'noopener,noreferrer');
}

async function handleAddScholarshipClick() {
  isAddScholarshipLoading.value = true;

  const response = await bulkCreateScholarship({
    ids: tableSelectionState.value.selectedRowIds || [],
    filters: queryParameters.filters || null,
    all: tableSelectionState.value.areAllRecordsSelected
  });

  isAddScholarshipLoading.value = false;
  handleToggleAddOrUpdateMenuOpenState();
  window.open(response.session_url, '_blank', 'noopener,noreferrer');
}

function handleAddOrUpdateMenuInteractOutside() {
  isAddOrUpdateMenuOpen.value = false;
}

async function handleUpdateFinancialAidClick() {
  isUpdateFinancialAidLoading.value = true;

  const response = await bulkCreateFinancialAid({
    ids: tableSelectionState.value.selectedRowIds || [],
    filters: queryParameters.filters || null,
    all: tableSelectionState.value.areAllRecordsSelected
  });

  isUpdateFinancialAidLoading.value = false;
  handleToggleAddOrUpdateMenuOpenState();
  window.open(response.session_url, '_blank', 'noopener,noreferrer');
}
</script>


					<script lang="tsx">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <IndexPageLayout
    title="Students"
    :appliedFilters="appliedFilters"
    :distiller="distiller"
    :table="table"
    :customCells="customCells"
    :stickyEndColumns="1"
    :stickyStartColumns="4"
    :clickableRows="!archiveActive"
    @clickRow="($event, row) => handleStudentRowClick($event, row as StudentRow)"
    :tableSelectionState="tableSelectionState"
  >
    <template #rightButtons>
      <template v-if="tableSelectionState.hasSelection">
        <MenuButton
          v-if="!archiveActive"
          :isOpen="isAddOrUpdateMenuOpen"
          buttonColor="slate"
          buttonLabel="Add or update"
          buttonSize="sm"
          buttonVariant="outlined"
          @click="handleToggleAddOrUpdateMenuOpenState"
          @onInteractOutside="handleAddOrUpdateMenuInteractOutside"
        >
          <MenuItem
            v-if="hasPermission('create-interaction')"
            label="Add Interaction"
            description="Add the same interaction for all selected students"
            @click="() => toggleInteractionOpen()"
          />
          <MenuItem
            v-if="hasPermission('create-note')"
            label="Add Note"
            description="Add the same note for all selected students"
            @click="() => toggleNoteOpen()"
          />
          <MenuItem
            label="Add College Application"
            description="Add college application for all selected students using bulkinator"
            :iconRight="ExternalLinkAlt"
            :isLoading="isAddCollegeApplicationLoading"
            @click="handleAddCollegeApplicationClick()"
          />
          <MenuItem
            label="Add Scholarship"
            description="Add scholarship for all selected students using bulkinator"
            :iconRight="ExternalLinkAlt"
            :isLoading="isAddScholarshipLoading"
            @click="handleAddScholarshipClick()"
          />
          <MenuItem
            label="Add Postsecondary Plan"
            description="Add the same postsecondary plan for all selected students"
            @click="() => togglePostSecondaryPlanOpen()"
          />
          <MenuItem
            label="Change Enrollment Status"
            description="Change status for all selected students"
            @click="() => toggleEnrollmentStatusOpen()"
          />
          <MenuItem
            label="Update Financial Aid"
            description="Update financial aid for all selected students using bulkinator"
            :iconRight="ExternalLinkAlt"
            :isLoading="isUpdateFinancialAidLoading"
            @click="handleUpdateFinancialAidClick()"
          />
        </MenuButton>
        <MenuButton
          v-if="!archiveActive"
          buttonColor="slate"
          buttonLabel="Group"
          buttonSize="sm"
          buttonVariant="outlined"
        >
          <MenuItem
            label="Remove From Group"
            description="Remove selected students from an existing group"
            @click="() => toggleRemoveStudentGroupStudentOpen()"
          />
          <MenuItem
            label="Add To Group"
            description="Add selected students to an existing group"
            @click="() => toggleAddStudentGroupStudentOpen()"
          />
          <MenuItem
            label="New Group"
            description="Create a new group from selected students"
            @click="() => toggleCreateStudentGroupOpen()"
          />
        </MenuButton>
        <VerticalDivider v-if="!archiveActive" />
        <BulkTrashButton
          v-if="!archiveActive"
          recordType="Students"
          :route="route('students.bulk-archive')"
          :tableSelectionState="tableSelectionState"
        />
        <BulkRestoreButton
          v-if="archiveActive"
          recordType="Students"
          :route="route('students.bulk-unarchive')"
          :tableSelectionState="tableSelectionState"
        />
        <BulkEmptyTrashButton
          v-if="archiveActive"
          recordType="Students"
          :route="route('students.bulk-delete')"
          :tableSelectionState="tableSelectionState"
        />
        <Button
          v-if="hasSmsEnabled"
          :color="ButtonColor.primary"
          :href="
            route('sms.compose', {
              ids: tableSelectionState.selectedRowIds || [],
              filters: queryParameters.filters || null,
              all: tableSelectionState.areAllRecordsSelected
            })
          "
          @click="() => (isSendingSms = true)"
          :iconLeft="Message"
          :size="ButtonSize.sm"
          :variant="ButtonVariant.solid"
          :isLoading="isSendingSms"
          >Send SMS</Button
        >
      </template>

      <template v-else>
        <Button
          v-if="hasPermission('create-student')"
          :color="ButtonColor.slate"
          :href="route('upload-sessions.draft.index', { uploadType: 'student' })"
          :iconLeft="LayerGroup"
          :size="ButtonSize.sm"
          :variant="ButtonVariant.soft"
        >
          Bulk add
        </Button>
        <Button
          v-if="hasPermission('create-student')"
          :color="ButtonColor.primary"
          :href="route('students.create')"
          :iconLeft="Plus"
          :size="ButtonSize.sm"
          :variant="ButtonVariant.solid"
        >
          Add a student
        </Button>
      </template>
    </template>

    <template #tabs>
      <TabNavItem
        :badgeLabel="counts.active_count"
        :isActive="!archiveActive"
        label="Active"
        variant="inline"
        :href="
          route('students.index', {
            ...(encodedFilters ? { filters: encodedFilters } : {})
          })
        "
      />
      <TabNavDivider layout="inline" />
      <TabNavItem
        :badgeLabel="counts.archived_count ?? 0"
        :isActive="archiveActive"
        label="Trashed"
        variant="inline"
        :href="
          route('students.index', {
            archived: true,
            ...(encodedFilters ? { filters: encodedFilters } : {})
          })
        "
      />
    </template>

    <template #emptyState>
      <EmptyState
        v-if="archiveActive"
        title="No students were moved to the trash"
        description="Trash students will appear on this screen"
      />
      <EmptyState
        v-else
        title="No students found"
        :description="
          hasPermission('create-student') ? 'Create a new student by clicking the button below' : ''
        "
        :actionLabel="hasPermission('create-student') ? 'Add a student' : ''"
        :actionHref="route('students.create')"
      />
    </template>
  </IndexPageLayout>

  <BulkCreateStudentInteractionDialog
    v-if="isInteractionOpen"
    isOpen
    :selectedStudentsCount="computedSelectedStudentsCount"
    :tableSelectionState="tableSelectionState"
    @onCancel="() => toggleInteractionOpen()"
    @onClose="() => toggleInteractionOpen()"
  />
  <BulkUpdateStudentPostSecondaryPlanDialog
    v-if="isPostSecondaryPlanOpen"
    isOpen
    :postSecondaryPlans="postSecondaryPlans"
    :selectedStudentsCount="computedSelectedStudentsCount"
    :tableSelectionState="tableSelectionState"
    @onCancel="() => togglePostSecondaryPlanOpen()"
    @onClose="() => togglePostSecondaryPlanOpen()"
  />
  <BulkCreateStudentNoteDialog
    v-if="isNoteOpen"
    isOpen
    :selectedStudentsCount="computedSelectedStudentsCount"
    :tableSelectionState="tableSelectionState"
    @onCancel="() => toggleNoteOpen()"
    @onClose="() => toggleNoteOpen()"
  />
  <BulkUpdateEnrollmentStatusDialog
    v-if="isEnrollmentStatusOpen"
    :enrollmentStatuses="enrollmentStatuses"
    :selectedStudentsCount="computedSelectedStudentsCount"
    :tableSelectionState="tableSelectionState"
    isOpen
    @onCancel="() => toggleEnrollmentStatusOpen()"
    @onClose="() => toggleEnrollmentStatusOpen()"
  />
  <BulkCreateStudentGroupDialog
    v-if="isCreateStudentGroupOpen"
    :defaultSchool="defaultSchool"
    :selectedStudentsCount="computedSelectedStudentsCount"
    :tableSelectionState="tableSelectionState"
    isOpen
    @onCancel="() => toggleCreateStudentGroupOpen()"
    @onClose="() => toggleCreateStudentGroupOpen()"
  />
  <BulkAddStudentGroupStudentDialog
    v-if="isAddStudentGroupStudentOpen"
    :selectedStudentsCount="computedSelectedStudentsCount"
    :tableSelectionState="tableSelectionState"
    isOpen
    @onCancel="() => toggleAddStudentGroupStudentOpen()"
    @onClose="() => toggleAddStudentGroupStudentOpen()"
  />
  <BulkRemoveStudentFromStudentGroupDialog
    v-if="isRemoveStudentGroupStudentOpen"
    :selectedStudentsCount="computedSelectedStudentsCount"
    :tableSelectionState="tableSelectionState"
    isOpen
    @onCancel="() => toggleRemoveStudentGroupStudentOpen()"
    @onClose="() => toggleRemoveStudentGroupStudentOpen()"
  />
</template>
