<script setup lang="ts">
import {
  PopoverAnchor,
  PopoverContent,
  PopoverContentProps,
  PopoverPortal,
  PopoverRoot,
  PopoverTrigger
} from 'radix-vue';
import { useZindex } from '@/hooks/useZindex';

export type MenuProps = {
  contentClasses?: string;
  contentStyles?: Partial<CSSStyleDeclaration>;
  contentOptions?: Partial<PopoverContentProps>;
  isContentAnchored?: boolean;
  isOpen?: boolean;
};

withDefaults(defineProps<MenuProps>(), {
  contentClasses: '',
  contentOptions: () => ({}),
  isOpen: undefined
});

const emit = defineEmits<{
  onInteractOutside: [void];
}>();

const { nextIndex } = useZindex();
const zIndex = nextIndex();
</script>

<template>
  <PopoverRoot :open="isOpen">
    <PopoverAnchor v-if="isContentAnchored" asChild>
      <slot name="trigger" />
    </PopoverAnchor>
    <PopoverTrigger v-else as="div">
      <slot name="trigger" />
    </PopoverTrigger>
    <PopoverPortal>
      <PopoverContent
        v-bind="contentOptions"
        class="rounded-lg border border-slate-200 bg-white shadow-md transition-menu duration-150 ease-in-out data-[state=closed]:pointer-events-none data-[state=open]:pointer-events-auto data-[state=closed]:-mt-2 data-[state=open]:-mt-0 data-[state=open]:data-[side=bottom]:animate-slide-down-and-fade data-[state=open]:data-[side=left]:animate-slide-right-and-fade data-[state=open]:data-[side=right]:animate-slide-left-and-fade data-[state=open]:data-[side=top]:animate-slide-up-and-fade data-[state=closed]:opacity-0 data-[state=open]:opacity-100"
        :class="contentClasses"
        :style="{ ...contentStyles, zIndex }"
        :collisionPadding="4"
        @interactOutside="() => emit('onInteractOutside')"
      >
        <div
          v-if="$slots.header"
          class="px-3 py-2"
          :class="{
            'border-b border-slate-300': $slots.header
          }"
        >
          <slot name="header" />
        </div>
        <div class="max-h-72 overflow-y-auto">
          <div
            class="flex flex-col px-2.5 py-2"
            :class="{
              'px-2 py-3': !$slots.header && !$slots.footer,
              'px-2.5 py-2': $slots.header && $slots.footer,
              'px-2.5 pb-3 pt-2': $slots.header && !$slots.footer,
              'px-2.5 pb-2 pt-3': !$slots.header && $slots.footer
            }"
          >
            <slot />
          </div>
        </div>
        <div
          v-if="$slots.footer"
          class="px-3 py-2.5"
          :class="{
            'border-t border-slate-300': $slots.footer
          }"
        >
          <slot name="footer" />
        </div>
      </PopoverContent>
    </PopoverPortal>
  </PopoverRoot>
</template>

<style scoped>
[data-radix-popper-content-wrapper] {
  pointer-events: none;
}
</style>
