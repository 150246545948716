<script setup lang="ts">
import PaddedCellContent from '@/components/table/PaddedCellContent.vue';
import { NotificationCellSchema } from '@/components/notifications/schemas/NotificationCellSchema';
import BaseNotification from './notifications/BaseNotification.vue';
import { BaseNotificationDataSchema } from '@/components/notifications/schemas/BaseNotificationDataSchema';

const props = defineProps<{
  notificationId: string | number;
  value: unknown;
}>();

const notification = computed(() => {
  const notification = NotificationCellSchema.safeParse(props.value);
  return notification.success ? notification.data : null;
});

const data = computed(() => {
  const data = BaseNotificationDataSchema.safeParse(notification.value?.data);
  return data.success ? data.data : null;
});
</script>

<template>
  <PaddedCellContent class="flex h-full flex-col whitespace-normal">
    <BaseNotification v-if="data" v-bind="data" :notificationId="notificationId" />
    <template v-else> {{ value }} </template>
  </PaddedCellContent>
</template>
