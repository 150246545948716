export enum BadgeColor {
  primary = 'primary',
  secondary = 'secondary',
  slate = 'slate',
  success = 'success',
  warning = 'warning',
  danger = 'danger',
  info = 'info'
}

export enum BadgeVariant {
  solid = 'solid',
  outlined = 'outlined',
  soft = 'soft',
  invisible = 'invisible'
}

export enum BadgeShape {
  pill = 'pill',
  rounded = 'rounded'
}

export enum BadgeSize {
  xs = 'xs',
  sm = 'sm',
  md = 'md',
  lg = 'lg'
}

export function useBadgeClasses({
  isInteractive,
  isDisabled,
  shape,
  size,
  dashed = false
}: {
  isInteractive: boolean;
  isDisabled: boolean;
  shape: BadgeShape;
  size: BadgeSize;
  dashed?: boolean;
}) {
  return [
    'border',

    dashed && 'border-dashed',

    BadgeShape.pill === shape && 'rounded-full',
    BadgeShape.rounded === shape && 'rounded-[5px]',

    BadgeSize.xs === size && 'px-1.5 h-4 text-2xs leading-4 gap-x-1',
    BadgeSize.sm === size && 'px-1.5 h-5 text-2xs leading-5 gap-x-1',
    BadgeSize.md === size && 'px-2 py-1 h-6 text-sm leading-4 gap-x-1.5',
    BadgeSize.lg === size && 'px-1.5 h-7 text-sm leading-4 gap-x-1.5',

    !isInteractive && 'pointer-events-none',
    isInteractive && !isDisabled && 'hover:cursor-pointer',
    isInteractive && isDisabled && 'opacity-50 hover:cursor-not-allowed'
  ];
}
