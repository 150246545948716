<script setup lang="ts">
import pluralize from 'pluralize';
import Badge from '@/components/badge/Badge.vue';
import UsersAltIcon from '@/icons/line/users-alt.svg';

const props = defineProps<{
  recipient: App.Sms.Data.SmsRecipientData;
}>();

const meta = computed<App.Sms.Data.SmsRecipientStudentGroupCaseloadMetaData>(
  () => props.recipient.meta as unknown as App.Sms.Data.SmsRecipientStudentGroupCaseloadMetaData
);
</script>

<template>
  <Badge
    :iconLeft="UsersAltIcon"
    :label="pluralize('student', meta.students_count, true)"
    size="sm"
  />
</template>
