<script setup lang="ts" generic="T extends SelectableValue">
import type { FormFieldProps, FormFieldSlots } from '@/components/formField/FormField';
import type { Selectable, SelectableValue, SelectBoxProps } from '@/components/selectBox/selectBox';
import { useFormField } from '@/components/formField/useFormField';
import FormField from '@/components/formField/FormField.vue';
import Listbox from '@/components/selectBox/listbox/ListboxInput.vue';

type FormComboboxProps = SelectBoxProps<T> & FormFieldProps;

const props = defineProps<FormComboboxProps>();
defineSlots<FormFieldSlots>();

const emit = defineEmits<{
  'update:modelValue': [Selectable<T> | null];
}>();

function onValueChange(value: Selectable<T> | null): void {
  emit('update:modelValue', value);
}

const { formFieldProps, slots } = useFormField({ props });
</script>

<template>
  <FormField v-bind="formFieldProps">
    <Listbox
      :modelValue="modelValue as Selectable<T>"
      :options="options"
      :iconLeft="iconLeft"
      :placeholder="placeholder"
      :hasError="formFieldProps.hasError"
      :isDisabled="isDisabled"
      :isOptional="isOptional"
      @update:modelValue="onValueChange"
    />
    <template v-for="slot of slots" #[slot]>
      <slot :name="slot" />
    </template>
  </FormField>
</template>
