<script setup lang="ts">
import { ButtonColor, ButtonVariant } from '@/hooks/useButtonClasses';

import Button from '@/components/button/Button.vue';
import IconButton from '@/components/button/IconButton.vue';
import LightbulbIcon from '@/icons/line/lightbulb.svg';

defineProps<{
  isSmallScreen?: boolean;
}>();

onMounted(() => {
  window.Canny('initChangelog', {
    appID: '66f58171eb9761b4e15b11ba',
    position: 'bottom',
    align: 'right',
    theme: 'light'
  });
});
</script>

<template>
  <IconButton
    v-if="!isSmallScreen"
    ariaLabel="Open GRACE changelog"
    data-canny-changelog
    size="md"
    variant="soft"
    :icon="LightbulbIcon"
    :color="ButtonColor.slate"
  />
  <Button
    v-else
    :iconLeft="LightbulbIcon"
    :color="ButtonColor.slate"
    :variant="ButtonVariant.invisible"
    data-canny-changelog
    >GRACE changelog</Button
  >
</template>
