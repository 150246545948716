<script setup lang="ts">
import type { ShowTestProps } from '@/domains/students/views/ShowTest.vue';

import { formatDate } from '@/utils/date';

import { useDialogHelpers } from '@/hooks/useDialogHelpers';

import Badge from '@/components/badge/Badge.vue';
import BadgeBoolean from '@/components/badge/BadgeBoolean.vue';
import DangerDialog from '@/components/dialog/DangerDialog.vue';
import DefinitionList from '@/components/definitionList/DefinitionList.vue';
import DefinitionListItem from '@/components/definitionList/DefinitionListItem.vue';
import IconButton from '@/components/button/IconButton.vue';
import InformationCollapsible from '@/components/collapsible/InformationCollapsible.vue';
import Slideout from '@/components/slideout/Slideout.vue';

import ArchiveAlt from '@/icons/line/archive-alt.svg';
import Calendar from '@/icons/line/calendar.svg';
import Cell from '@/icons/line/cell.svg';
import EditAlt from '@/icons/line/edit-alt.svg';
import ElipsisCircle from '@/icons/line/elipsis-circle.svg';
import FilesLandscapesAlt from '@/icons/line/files-landscapes-alt.svg';
import InfoCircle from '@/icons/line/info-circle.svg';
import PlusCircle from '@/icons/line/plus-circle.svg';
import TachometerFastAlt from '@/icons/line/tachometer-fast-alt.svg';

const props = defineProps<ShowTestProps>();

const { show, close } = useDialog();
const { isDialogOpen, toggleDialog } = useDialogHelpers();

const form = useForm({
  method: 'DELETE',
  spoof: false,
  url: route('tests.delete', { test: props.test.id }),
  fields: {},
  hooks: {
    success() {
      close();
    }
  }
});

async function handleTestDelete() {
  await form.submit();
  toggleDialog();
}
</script>

<template>
  <Slideout
    :title="`${testType} #${test.id}`"
    :isOpen="show"
    @onClose="close"
    :icon="FilesLandscapesAlt"
  >
    <template #actions>
      <IconButton
        v-if="test.authorization.update"
        :href="
          route('students.tests.edit', {
            student: student.id,
            test: test.id
          })
        "
        :icon="EditAlt"
        ariaLabel="Edit exam"
        size="sm"
        variant="soft"
      />
      <IconButton
        v-if="test.authorization.delete"
        color="danger"
        :icon="ArchiveAlt"
        ariaLabel="Move exam to trash"
        size="sm"
        variant="soft"
        @click="toggleDialog"
      />
    </template>

    <div class="-m-4 flex flex-col divide-y">
      <div class="p-4">
        <InformationCollapsible title="General information">
          <div class="flex flex-col gap-4">
            <DefinitionList>
              <DefinitionListItem :description="test.id" :icon="InfoCircle" title="ID" />
              <DefinitionListItem :icon="Cell" title="Type">
                <Badge :label="testType" variant="soft" size="md" />
              </DefinitionListItem>
              <DefinitionListItem
                v-if="apCourse"
                :description="apCourse.name ?? 'No subject'"
                title="AP Course"
              />
              <DefinitionListItem
                :description="formatDate(test.date) || 'No test date'"
                :icon="Calendar"
                title="Date"
              />
            </DefinitionList>
            <DefinitionList>
              <DefinitionListItem
                v-for="(score, index) in test.scores"
                :description="score.score"
                :icon="index === 0 ? TachometerFastAlt : undefined"
                :key="score.id"
                :title="scoreLabels.find((item) => item.key === score.name)?.label ?? score.name"
              />
            </DefinitionList>
            <DefinitionList>
              <DefinitionListItem title="Has waiver">
                <BadgeBoolean :boolean="!!test.is_waived" />
              </DefinitionListItem>
              <DefinitionListItem title="Helped with waiver">
                <BadgeBoolean :boolean="!!test.is_waiver_assisted" />
              </DefinitionListItem>
              <DefinitionListItem title="Is registered?">
                <BadgeBoolean :boolean="!!test.is_registered" />
              </DefinitionListItem>
              <DefinitionListItem :icon="ElipsisCircle" title="Helped with registration">
                <BadgeBoolean :boolean="!!test.is_assisted" />
              </DefinitionListItem>
              <DefinitionListItem title="Test taken">
                <BadgeBoolean :boolean="!!test.is_complete" />
              </DefinitionListItem>
            </DefinitionList>
            <DefinitionList>
              <DefinitionListItem
                :description="formatDateTime(test.created_at) || 'No create date'"
                :icon="PlusCircle"
                title="Created"
              />
              <DefinitionListItem
                :description="formatDateTime(test.updated_at) || 'No update date'"
                title="Updated"
              />
            </DefinitionList>
          </div>
        </InformationCollapsible>
      </div>
    </div>
  </Slideout>
  <DangerDialog
    title="Delete Exam"
    message="Are you sure you want to delete this exam? This action is permanent and cannot be reverted."
    confirmButtonLabel="Delete"
    :isOpen="isDialogOpen"
    :isLoading="form.processing"
    @onConfirm="handleTestDelete"
    @onCancel="toggleDialog"
    @onClose="toggleDialog"
  />
</template>
