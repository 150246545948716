<script setup lang="ts">
import { HybridlyFormData } from '@/@types/global';
import { AppliedFilters } from '@/components/distiller/types';
import DualListbox from '@/components/selectBox/dualListbox/DualListbox.vue';
import { DualListboxOption } from '@/components/selectBox/selectBox';

type Column = App.Reports.Data.ColumnData;

const props = defineProps<{
  customReport: App.Reports.Data.CustomReportData;
  form: HybridlyFormData<{ filters: AppliedFilters }>;
}>();

function walkColumns(columnOrGroup: Column): DualListboxOption {
  if (columnOrGroup.columns) {
    return {
      value: columnOrGroup.transientId,
      label: columnOrGroup.title,
      children: columnOrGroup.columns.map(walkColumns)
    };
  }

  return {
    value: columnOrGroup.transientId,
    label: columnOrGroup.title
  };
}

const listboxOptions = computed(() => {
  return (
    props.customReport.columns?.reduce((acc: DualListboxOption[], columnOrGroup: Column) => {
      return acc.concat(walkColumns(columnOrGroup));
    }, []) ?? []
  );
});

function handleSelectedValuesUpdate(selectedColumns: Set<string>) {
  props.form.fields.columns = Array.from(selectedColumns);
}
</script>

<template>
  <DualListbox
    class="h-full max-h-[800px] pt-4"
    :options="listboxOptions"
    :selectedValues="form.fields.columns"
    @update:selectedValues="handleSelectedValuesUpdate"
  />
</template>
