<script setup lang="tsx">
import { useAuth } from '@/hooks/useAuth';

import CenteredContent from '@/components/content/CenteredContent.vue';
import DeleteRecordCard from '@/components/cards/DeleteRecordCard.vue';
import EditReportTemplateLayout from '@/domains/reports/components/EditReportTemplateLayout.vue';
import Fieldset from '@/components/fieldset/Fieldset.vue';
import FormTextareaInput from '@/components/formTextareaInput/FormTextareaInput.vue';
import FormTextInput from '@/components/formTextInput/FormTextInput.vue';

type ReportTemplateData = App.Reports.Data.ReportTemplateData;

defineProps<{
  template: ReportTemplateData;
}>();

const { user, hasPermission } = useAuth();
</script>


					<script lang="tsx">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <EditReportTemplateLayout :template="template">
    <template #default="{ form }">
      <CenteredContent :padded="false" size="md">
        <Fieldset title="Template details">
          <FormTextInput
            label="Template name"
            v-model="form.fields.name"
            placeholder="Enter your template name"
            :error="form.errors.name"
          />

          <FormTextareaInput
            v-model="form.fields.description"
            name="description"
            label="Template description"
            placeholder="Description of the report template"
            :error="form.errors.description"
            isOptional
          />
        </Fieldset>
      </CenteredContent>
    </template>
  </EditReportTemplateLayout>

  <CenteredContent
    v-if="
      (template.is_system_template && hasPermission('delete-system-report')) ||
      template.user?.id === user?.id
    "
    size="md"
    :padded="false"
  >
    <DeleteRecordCard
      recordName="report template"
      :deleteUrl="
        route('report-templates.delete', {
          reportTemplate: template.id
        })
      "
    />
  </CenteredContent>
</template>
