<script setup lang="ts">
import Alert from '@/components/alert/Alert.vue';
import Fieldset from '@/components/fieldset/Fieldset.vue';
import FormSwitchInput from '@/components/formSwitchInput/FormSwitchInput.vue';
import FormTextInput from '@/components/formTextInput/FormTextInput.vue';
import FormTextareaInput from '@/components/formTextareaInput/FormTextareaInput.vue';
import StepContainer from '@/components/stepContainer/StepContainer.vue';
import StepFooter from '@/components/stepContainer/StepFooter.vue';
import { CreateReportForm } from '@/domains/reports/types/CreateReportForm';
import ReportAccessForm from '@/domains/reports/components/form/ReportAccessForm.vue';
import FormCheckboxInput from '@/components/formCheckboxInput/FormCheckboxInput.vue';
import InfoCircleIcon from '@/icons/line/info-circle.svg';
import { useAuth } from '@/hooks/useAuth';
import CreateCustomReport from './CreateCustomReport.vue';
import { AppliedFilters } from '@/components/distiller/types';

type Column = App.Reports.Data.ColumnData;

const props = defineProps<{
  customReport: App.Reports.Data.CustomReportData;
  fileName: string;
  filters: AppliedFilters;
  columnFilters: AppliedFilters;
  columns: string[];
  reportTemplate: App.Reports.Data.ReportTemplateData;
  reportTemplateOwner: boolean | null;
}>();

const { hasPermission } = useAuth();

function walkColumns(acc: Record<string, Column>, columnOrGroup: Column) {
  if (columnOrGroup.columns) {
    return columnOrGroup.columns.reduce(walkColumns, acc);
  }

  acc[columnOrGroup.transientId] = columnOrGroup;

  return acc;
}

const keyedColumns = computed(
  () => props.customReport.columns?.reduce(walkColumns, {} as Record<string, Column>) ?? []
);

const prevForm = useForm({
  url: route('custom-reports.order-columns.create', { customReport: props.customReport.id }),
  method: 'post',
  fields: {
    filters: (props.filters ?? props.customReport.default_filters ?? {}) as AppliedFilters,
    column_filters: (props.columnFilters ?? {}) as AppliedFilters,
    columns: [...(props.columns ?? [])],
    report_template_id: props.reportTemplate?.id ?? null
  }
});

const nextForm = useForm<CreateReportForm>({
  url: route('custom-reports.generate', { customReport: props.customReport.id }),
  method: 'post',
  fields: {
    report_id: props.customReport.id,
    file_name: props.fileName,
    filters: (props.filters ?? props.customReport.default_filters ?? {}) as AppliedFilters,
    column_filters: (props.columnFilters ?? {}) as AppliedFilters,
    columns: [...(props.columns ?? [])],
    report_template_id: props.reportTemplate?.id ?? null,
    create_template: false,
    update_existing_template: false,
    is_system_template: false,
    share_template: false,
    template_name: '',
    template_description: '',
    program_ids: [],
    user_ids: []
  },
  transform: (data) => ({
    ...data,
    columns: Object.values(data.columns)
      .map((transientId) => {
        const column = keyedColumns.value[transientId];
        if (!column) {
          return null;
        }
        return {
          key: column.key,
          transientId: column.transientId,
          extras: column.extras ?? {}
        };
      })
      .filter(Boolean) as unknown,
    program_ids: data.program_ids?.map((program) => program.value) ?? [],
    user_ids: data.user_ids?.map((user) => user.value) ?? []
  })
});

function handleToggleSaveTemplate(event: boolean) {
  nextForm.fields.create_template = event;

  if (!event) {
    nextForm.resetFields('update_existing_template', 'template_name', 'template_description');
  }
}

function handleToggleUpdateExistingTemplate(event: boolean) {
  nextForm.fields.update_existing_template = event;

  if (event) {
    nextForm.fields.template_name = props.reportTemplate.name ?? '';
    nextForm.fields.template_description = props.reportTemplate.description ?? '';
  } else {
    nextForm.resetFields('template_name', 'template_description');
  }
}
</script>


					<script lang="ts">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <CreateCustomReport :currentStep="3" :customReport="customReport">
    <StepContainer title="Report details">
      <div class="mx-auto w-full max-w-xl space-y-10">
        <Fieldset title="Report details">
          <FormTextInput
            label="Report name"
            name="fileName"
            placeholder="Enter your report name"
            :error="nextForm.errors.file_name"
            v-model="nextForm.fields.file_name"
          />
        </Fieldset>
        <Fieldset title="Save as a template">
          <FormSwitchInput
            :checked="nextForm.fields.create_template"
            helperText="This will allow you to reuse this report configuration later to quickly export data."
            label="Save this report as a template for future use"
            name="create_template"
            @update:checked="handleToggleSaveTemplate($event)"
          />
        </Fieldset>
        <Fieldset
          v-if="nextForm.fields.create_template && reportTemplateOwner"
          title="Update existing template"
        >
          <FormCheckboxInput
            :checked="nextForm.fields.update_existing_template"
            :error="nextForm.errors.update_existing_template"
            helperText="This report is being generated from an existing report template, choosing this option will update that existing report."
            label="Update existing template"
            name="update_existing_template"
            @update:checked="handleToggleUpdateExistingTemplate($event)"
          />
        </Fieldset>

        <template v-if="nextForm.fields.create_template">
          <Fieldset title="Template details">
            <Alert
              :icon="InfoCircleIcon"
              :title="`This template will be ${
                nextForm.fields.update_existing_template ? 'updated' : 'saved'
              } for future use and will be editable at any time.`"
              variant="soft"
              alignment="horizontal"
            />
            <FormTextInput
              label="Template name"
              v-model="nextForm.fields.template_name"
              placeholder="Enter your template name"
              :error="nextForm.errors.template_name"
            />
            <FormTextareaInput
              v-model="nextForm.fields.template_description"
              name="template_description"
              label="Template description"
              placeholder="Description of the report template"
              isOptional
            />
          </Fieldset>
          <ReportAccessForm
            v-if="hasPermission('share-report-templates')"
            :customReport="customReport"
            :form="nextForm"
          />
        </template>

        <StepFooter
          nextButtonLabel="Export Data"
          :isLoading="nextForm.processing"
          @previous="prevForm.submit()"
          @next="nextForm.submit()"
        />
      </div>
    </StepContainer>
  </CreateCustomReport>
</template>
