<script setup lang="ts">
import { PopoverAnchor, PopoverClose, PopoverContent, PopoverPortal, PopoverRoot } from 'radix-vue';

import { ButtonColor, ButtonSize, ButtonVariant } from '@/hooks/useButtonClasses';
import { onNotification, onUserEvent } from '@/hooks/useWebsockets';

import Button from '@/components/button/Button.vue';
import Hyperlink from '@/components/links/Hyperlink.vue';
import IconButton from '@/components/button/IconButton.vue';
import Indicator from '@/components/button/Indicator.vue';

import IconBell from '@/icons/line/bell.svg';
import IconTimes from '@/icons/line/times.svg';

defineProps<{
  isSmallScreen?: boolean;
}>();

const unreadNotificationsCount = ref(0);
const showNotificationPopover = ref(false);
const notificationData = ref();

const messageParts = computed(() => notificationData.value?.message.split('{{ link }}') ?? []);

function loadUnreadNotificationsCount() {
  fetch(route('api.notifications.unread-count')).then(async (response) => {
    const data: App.Notifications.Data.UnreadNotificationSummaryData = await response.json();
    unreadNotificationsCount.value = data.unreadNotificationCount;
  });
}

onMounted(() => {
  loadUnreadNotificationsCount();
});

onNotification((notification) => {
  loadUnreadNotificationsCount();

  notificationData.value = notification;
  showNotificationPopover.value = true;

  setTimeout(() => {
    showNotificationPopover.value = false;
  }, 5000);
});

onUserEvent('NotificationReadStatusChanged', () => {
  loadUnreadNotificationsCount();
});

const unreadNotificationsLabel = computed(() => {
  return unreadNotificationsCount.value > 9 ? '9+' : unreadNotificationsCount.value.toString();
});

function handleCloseNotificationPopover() {
  showNotificationPopover.value = false;
  notificationData.value = null;
}

function handleActionClick() {
  router.navigate({
    url: route('notifications.action.show', { databaseNotification: notificationData.value.id })
  });
}
</script>

<template>
  <Indicator
    v-if="!isSmallScreen"
    :label="unreadNotificationsLabel"
    :show="unreadNotificationsCount > 0"
  >
    <PopoverRoot :open="showNotificationPopover">
      <PopoverAnchor>
        <IconButton
          :color="ButtonColor.slate"
          :href="route('notifications.index', { read: 'unread' })"
          :icon="IconBell"
          ariaLabel="Open notifications"
          :size="ButtonSize.md"
          :variant="ButtonVariant.soft"
        />
      </PopoverAnchor>
      <PopoverPortal>
        <PopoverContent
          :sideOffset="4"
          class="max-w-[300px] rounded-lg border border-b-4 border-slate-200 border-b-primary-800 bg-white px-4 py-2 shadow-md transition-menu duration-150 ease-in-out data-[state=closed]:pointer-events-none data-[state=open]:pointer-events-auto data-[state=open]:data-[side=bottom]:animate-slide-down-and-fade data-[state=closed]:opacity-0 data-[state=open]:opacity-100"
          @interactOutside="handleCloseNotificationPopover"
        >
          <div class="relative">
            <div class="flex flex-row items-center gap-2">
              <p class="text-sm font-bold leading-6">{{ notificationData?.title }}</p>
              <PopoverClose class="absolute right-0 top-0" @click="handleCloseNotificationPopover">
                <IconButton
                  :icon="IconTimes"
                  ariaLabel="Close notifications"
                  size="xs"
                  variant="invisible"
                />
              </PopoverClose>
            </div>
            <p class="text-sm font-medium leading-5">
              <template v-if="notificationData?.link && messageParts.length === 2">
                {{ messageParts[0] }}
                <Hyperlink
                  :href="notificationData?.link.href"
                  :download="notificationData?.link?.download"
                  @click.stop
                >
                  {{ notificationData?.link.text }}
                </Hyperlink>
                {{ messageParts[1] }}
              </template>
              <template v-else>
                {{ notificationData?.message }}
              </template>
            </p>
            <div>
              <Button
                v-if="notificationData?.action?.text"
                @click.prevent.stop="handleActionClick"
                :color="ButtonColor.slate"
                :variant="ButtonVariant.soft"
                class="mt-2"
                >{{ notificationData?.action.text }}</Button
              >
            </div>
          </div>
        </PopoverContent>
      </PopoverPortal>
    </PopoverRoot>
  </Indicator>
  <Button
    v-else
    :href="route('notifications.index', { read: 'unread' })"
    :iconLeft="IconBell"
    :color="ButtonColor.slate"
    :variant="ButtonVariant.invisible"
    >Notifications</Button
  >
</template>
