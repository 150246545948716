<script setup lang="ts">
type EnumData = App.Base.Data.EnumData;
type TwoFactorChannelData = App.Auth.Data.TwoFactorChannelData;

import type { HybridlyFormData } from '@/@types/global';

import { useAuth } from '@/hooks/useAuth';
import { ButtonColor, ButtonVariant } from '@/hooks/useButtonClasses';

import Account2faChannels from '@/components/account/Account2faChannels.vue';
import Alert from '@/components/alert/Alert.vue';
import Button from '@/components/button/Button.vue';
import DangerDialog from '@/components/dialog/DangerDialog.vue';
import Fieldset from '@/components/fieldset/Fieldset.vue';
import Form from '@/components/form/Form.vue';
import FormFooter from '@/components/formFooter/FormFooter.vue';
import FormHeader from '@/components/formHeader/FormHeader.vue';
import FormInlineFields from '@/components/formInlineFields/FormInlineFields.vue';
import FormListboxInput from '@/components/formListboxInput/FormListboxInput.vue';
import FormMultiProgramInput from '@/components/formComboboxInput/FormMultiProgramInput.vue';
import FormMultiRegionInput from '@/components/formComboboxInput/FormMultiRegionInput.vue';
import FormMultiSchoolInput from '@/components/formComboboxInput/FormMultiSchoolInput.vue';
import FormTextInput from '@/components/formTextInput/FormTextInput.vue';

import Envelope from '@/icons/line/envelope.svg';
import InfoCircle from '@/icons/line/info-circle.svg';
import Link from '@/icons/line/link.svg';
import Phone from '@/icons/line/phone.svg';

const props = defineProps<{
  form: HybridlyFormData<{
    first_name: string;
    last_name: string;
    email: string;
    role: EnumData;
    program_ids: [];
    region_ids: [];
    school_ids: [];
  }>;
  isEditing?: boolean;
  roles: EnumData[];
  channels?: TwoFactorChannelData[];
  returnUrl?: string;
}>();

const isCancelDialogOpen = ref(false);

const { hasPermission } = useAuth();
const { onBackForward } = useBackForward();

// TODO: Fix intecepting the back button
onBackForward(() => {
  router.abort();
});

function handleToggleCancelDialog() {
  if (props.form.isDirty) {
    isCancelDialogOpen.value = !isCancelDialogOpen.value;
    return;
  }

  handleRedirect();
}

function handleRedirect() {
  router.navigate({ url: props.returnUrl || route('users.index') });
}

function resetAttachments() {
  props.form.fields.program_ids = [];
  props.form.fields.region_ids = [];
  props.form.fields.school_ids = [];
}

watch(
  () => props.form.fields.role,
  (newValue, oldValue) => {
    if (newValue !== oldValue) {
      resetAttachments();
    }
  }
);
</script>

<template>
  <Form @submit.prevent="form.submit">
    <template #header>
      <FormHeader
        canClose
        :title="`${isEditing ? 'Edit' : 'Add'} a User`"
        :description="
          isEditing
            ? 'Use the form below to edit the user.'
            : 'Fill out the form below add a new user.'
        "
        @onClose="handleToggleCancelDialog"
      />
    </template>
    <Fieldset title="Account information">
      <FormInlineFields>
        <FormTextInput
          :error="form.errors.first_name"
          :modelValue="form.fields.first_name"
          label="First name"
          name="first_name"
          placeholder="e.g. John"
          @update:modelValue="(value) => (form.fields.first_name = value)"
        />
        <FormTextInput
          :error="form.errors.last_name"
          :modelValue="form.fields.last_name"
          label="Last name"
          name="last_name"
          placeholder="e.g. Doe"
          @update:modelValue="(value) => (form.fields.last_name = value)"
        />
      </FormInlineFields>
      <FormTextInput
        :error="form.errors.email"
        :iconLeft="Envelope"
        :modelValue="form.fields.email"
        label="Email"
        name="email"
        type="email"
        placeholder="example@mail.com"
        @update:modelValue="(value) => (form.fields.email = value)"
      />
    </Fieldset>
    <Fieldset title="User role">
      <FormListboxInput
        label="Role"
        placeholder="Select role"
        :error="form.errors.role"
        :modelValue="form.fields.role"
        :options="roles"
        @update:modelValue="(value) => (form.fields.role = value)"
      />
      <FormMultiProgramInput
        v-if="['program-director', 'assistant-pd'].includes(form.fields.role?.value)"
        :error="form.errors.program_ids"
        :modelValue="form.fields.program_ids"
        label="Programs"
        @update:modelValue="(value) => (form.fields.program_ids = value)"
      />
      <FormMultiRegionInput
        v-if="form.fields.role?.value === 'regional-director'"
        :error="form.errors.region_ids"
        :modelValue="form.fields.region_ids"
        label="Regions"
        @update:modelValue="(value) => (form.fields.region_ids = value)"
      />
      <FormMultiSchoolInput
        v-if="form.fields.role?.value === 'adviser'"
        :error="form.errors.school_ids"
        :modelValue="form.fields.school_ids"
        label="Schools"
        placeholder="Select schools"
        @update:modelValue="(value) => (form.fields.school_ids = value)"
      />
    </Fieldset>
    <Fieldset v-if="isEditing && hasPermission('create-sms')" title="SMS">
      <div class="flex gap-2">
        <FormTextInput
          :error="form.errors.sms_email"
          :iconLeft="Envelope"
          :isDisabled="!hasPermission('create-sms')"
          :modelValue="form.fields.sms_email"
          label="SMS email"
          name="sms_email"
          type="email"
          @update:modelValue="(value) => (form.fields.sms_email = value)"
        />
        <FormTextInput
          :error="form.errors.sms_phone_number"
          :iconLeft="Phone"
          :modelValue="form.fields.sms_phone_number"
          isDisabled
          label="SMS Phone"
          name="sms_phone_number"
          type="tel"
          @update:modelValue="(value) => (form.fields.sms_phone_number = value)"
        />
      </div>
    </Fieldset>
    <Fieldset v-if="isEditing && hasPermission('create-sms')" title="Contact link">
      <FormTextInput
        :error="form.errors.url"
        :iconLeft="Link"
        :modelValue="form.fields.url"
        label="Contact link"
        name="url"
        type="url"
        @update:modelValue="(value) => (form.fields.url = value)"
      />
    </Fieldset>
    <Fieldset v-if="isEditing" title="Two factor authentication">
      <Account2faChannels
        v-if="channels?.length"
        :channels="channels"
        tooltip="You cannot delete a user's only verified two factor authentication method."
      />
      <Alert
        v-else
        :icon="InfoCircle"
        alignment="vertical"
        title="Channels list will be visible when the user sets up their account."
        hideClose
      />
    </Fieldset>
    <template #footer>
      <FormFooter>
        <Button
          type="submit"
          :color="ButtonColor.primary"
          :isDisabled="form.processing || !form.isDirty"
          :isLoading="form.processing"
          :variant="ButtonVariant.solid"
          >Confirm</Button
        >
        <Button
          @click="handleToggleCancelDialog"
          :color="ButtonColor.slate"
          :variant="ButtonVariant.outlined"
          type="button"
        >
          Back to users
        </Button>
      </FormFooter>
    </template>
  </Form>

  <DangerDialog
    :title="`Cancel ${isEditing ? 'editing' : 'adding a new'} user`"
    :message="`Are you sure you want to cancel ${isEditing ? 'editing' : 'adding'} this user? All ${
      isEditing ? 'changes' : 'information'
    } will be lost.`"
    :isOpen="isCancelDialogOpen"
    cancelButtonLabel="Stay on this page"
    confirmButtonLabel="Go back to users"
    @onCancel="handleToggleCancelDialog"
    @onClose="handleToggleCancelDialog"
    @onConfirm="handleRedirect"
  />
</template>
