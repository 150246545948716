<script setup lang="ts">
import StudentData = App.Students.Data.StudentData;
import StudentParentData = App.Students.Data.StudentParentData;

import { RouterLink } from 'hybridly/vue';

import { ButtonColor, ButtonSize, ButtonVariant } from '@/hooks/useButtonClasses';

import Button from '@/components/button/Button.vue';

import NotesIcon from '@/icons/line/notes.svg';
import UserIcon from '@/icons/line/user-circle.svg';

const props = defineProps<{
  isStudent: boolean;
  recipient: StudentData | StudentParentData | null;
  recipientType: 'student' | 'parent' | 'admithub';
  phone?: string;
}>();

const studentId = computed(() => getStudentId(props.recipient));

const recipientLink = computed(() => {
  if (!studentId.value) {
    return null;
  }

  if (props.isStudent) {
    return route('students.show', { student: studentId.value });
  }

  return route('students.edit.parents-guardians', { student: studentId.value });
});

/**
 * @TODO: this only gets the first student id,
 * we need to handle multiple students.
 */
function getStudentId(data: StudentParentData | StudentData | null) {
  if (!data) {
    return null;
  }

  return 'students' in data ? data.students[0].id : data.id;
}
</script>

<template>
  <header class="flex items-center justify-between border-b px-4 py-2.5">
    <div>
      <h1 class="text-base font-bold text-zinc-900">
        <RouterLink v-if="recipientLink" :href="recipientLink">{{
          recipient?.name || phone
        }}</RouterLink>
        <template v-else>{{ recipient?.name || phone }}</template>
      </h1>
      <slot name="badge" />
    </div>
    <div class="flex flex-wrap justify-end gap-x-2 gap-y-1">
      <Button
        v-if="studentId"
        :color="ButtonColor.slate"
        :href="route('students.profile.show', { student: studentId })"
        :iconLeft="UserIcon"
        :size="ButtonSize.sm"
        :variant="ButtonVariant.outlined"
      >
        Student profile
      </Button>

      <Button
        v-if="studentId"
        :color="ButtonColor.slate"
        :href="route('students.notes.show', { student: studentId })"
        :iconLeft="NotesIcon"
        :size="ButtonSize.sm"
        :variant="ButtonVariant.outlined"
      >
        Notes
      </Button>
    </div>
  </header>
</template>
