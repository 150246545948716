<script setup lang="ts">
type EnumData = App.Base.Data.EnumData;

import type { HybridlyFormData } from '@/@types/global';
import type { Selectable } from '@/components/selectBox/selectBox';

import { ButtonColor, ButtonVariant } from '@/hooks/useButtonClasses';

import Button from '@/components/button/Button.vue';
import DangerDialog from '@/components/dialog/DangerDialog.vue';
import EmptyCard from '@/components/emptyCard/EmptyCard.vue';
import Fieldset from '@/components/fieldset/Fieldset.vue';
import Form from '@/components/form/Form.vue';
import FormCheckboxInput from '@/components/formCheckboxInput/FormCheckboxInput.vue';
import FormField from '@/components/formField/FormField.vue';
import FormFooter from '@/components/formFooter/FormFooter.vue';
import FormHeader from '@/components/formHeader/FormHeader.vue';
import FormListboxInput from '@/components/formListboxInput/FormListboxInput.vue';

const props = defineProps<{
  form: HybridlyFormData<{
    data_import_type: EnumData;
    is_dry_run: boolean;
    persist_if_successful: boolean;
    file: File;
  }>;
  dataImportTypes: App.Bulkinator.Data.DataImportTypeData[];
}>();

const isCancelDialogOpen = ref(false);

const { onBackForward } = useBackForward();

const computedDataImportTypes = computed<Selectable<string>[]>(() => {
  return props.dataImportTypes.map(buildSelectableDataImportType) as Selectable<string>[];
});

// TODO: Fix intecepting the back button
onBackForward(() => {
  router.abort();
});

function handleToggleCancelDialog() {
  if (props.form.isDirty) {
    isCancelDialogOpen.value = !isCancelDialogOpen.value;
    return;
  }
  handleRedirect();
}

function handleRedirect() {
  router.navigate({ url: route('data-imports.index') });
}
</script>

<template>
  <Form @submit.prevent="form.submit">
    <template #header>
      <FormHeader
        canClose
        title="Add a Data Import"
        description="Fill out the form below add a new data import."
        @onClose="handleToggleCancelDialog"
      />
    </template>
    <Fieldset title="Data import information">
      <FormListboxInput
        :error="form.errors.data_import_type"
        :options="computedDataImportTypes"
        :modelValue="form.fields.data_import_type"
        label="Data import type"
        helperText="Select the type of data you are importing."
        @update:modelValue="(value) => (form.fields.data_import_type = value)"
      />
      <EmptyCard
        v-if="form.fields.data_import_type.value"
        :actionLabel="`Download ${form.fields.data_import_type.label} Template`"
        :actionHref="
          route('data-imports.template.download', {
            dataImportType: form.fields.data_import_type.value
          })
        "
        description="You can use the import template as a starting point for your data import."
        isRounded
        title="Data Import Template"
      />
      <FormField
        :error="form.errors.file"
        helperText="Select the '.xlsx' file you would like to import."
        label="File"
      >
        <input
          class="text-sm text-slate-900"
          accept=".xlsx"
          type="file"
          @change="(value) => (form.fields.file = (value.target as HTMLInputElement).files?.[0])"
        />
      </FormField>
    </Fieldset>
    <Fieldset title="Data import options">
      <FormCheckboxInput
        :checked="form.fields.is_dry_run"
        :error="form.errors.is_dry_run"
        helperText="Is this import a dry run? Imported data will be rolled back and not persisted. Useful for validating the file is correctly running the import."
        label="Dry run"
        name="is_dry_run"
        @update:checked="(value) => (form.fields.is_dry_run = value)"
      />
      <FormCheckboxInput
        :checked="form.fields.persist_if_successful"
        :error="form.errors.persist_if_successful"
        helperText="If the dry run is successful, automatically re-run the data import with the dry run flag turned off."
        label="Run without dry run if successful"
        name="persist_if_successful"
        @update:checked="(value) => (form.fields.persist_if_successful = value)"
      />
    </Fieldset>
    <template #footer>
      <FormFooter>
        <Button
          :color="ButtonColor.primary"
          :variant="ButtonVariant.solid"
          :isDisabled="form.processing || !form.isDirty"
          :isLoading="form.processing"
          type="submit"
          >Confirm</Button
        >
        <Button
          :color="ButtonColor.slate"
          :variant="ButtonVariant.outlined"
          type="button"
          @click="handleToggleCancelDialog"
        >
          Back to data imports
        </Button>
      </FormFooter>
    </template>
  </Form>
  <DangerDialog
    :isOpen="isCancelDialogOpen"
    title="Cancel adding a new data import"
    message="Are you sure you want to cancel adding this data import? All information will be lost."
    cancelButtonLabel="Stay on this page"
    confirmButtonLabel="Go back to data imports"
    @onCancel="handleToggleCancelDialog"
    @onClose="handleToggleCancelDialog"
    @onConfirm="handleRedirect"
  />
</template>
