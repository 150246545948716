<script setup lang="ts">
import type { HybridlyInlineAction } from './Table';

import { ButtonColor, ButtonSize, ButtonVariant } from '@/hooks/useButtonClasses';

import Button from '@/components/button/Button.vue';
import Dialog from '@/components/dialog/Dialog.vue';
import DangerDialog from '@/components/dialog/DangerDialog.vue';
import getAsyncIcon from '@/components/icons/AsyncIcon';
import IconButton from '@/components/button/IconButton.vue';

const props = withDefaults(
  defineProps<{
    action: HybridlyInlineAction;
    displayIconButton?: boolean;
    isDisabled?: boolean;
  }>(),
  {
    displayIconButton: false,
    isDisabled: false
  }
);

const confirmingAction = ref<boolean>(false);
const isLoadingAction = ref<boolean>(false);

const metadata = computed(
  () => props.action.metadata
) as ComputedRef<App.Base.Data.TableActionMeta | null>;

async function executeAction() {
  isLoadingAction.value = true;

  return new Promise<void>((res, rej) => {
    props.action
      .execute()
      .catch((err) => {
        console.error('Error executing action', err);
        rej();
      })
      .finally(() => {
        isLoadingAction.value = false;

        res();
      });
  });
}

function handleActionClick() {
  if (metadata.value?.confirmation) {
    confirmingAction.value = true;
    return;
  }

  executeAction();
}

async function handleConfirmAction() {
  await executeAction();

  confirmingAction.value = false;
}

function handleCancelConfirmation() {
  confirmingAction.value = false;
}
</script>

<template>
  <IconButton
    v-if="displayIconButton"
    :size="ButtonSize.sm"
    :variant="ButtonVariant.invisible"
    :ariaLabel="action.label"
    :isLoading="isLoadingAction"
    :isDisabled="isDisabled || action.metadata?.disabled"
    :icon="getAsyncIcon(action.metadata?.icon ?? 'edit')"
    :color="(metadata?.color ?? ButtonColor.primary) as ButtonColor"
    :title="action.label"
    @click.stop="handleActionClick"
  />

  <Button
    v-else
    :size="ButtonSize.sm"
    :variant="ButtonVariant.solid"
    :isDisabled="isDisabled || action.metadata?.disabled"
    :iconLeft="getAsyncIcon(action.metadata?.icon ?? 'edit')"
    :color="(metadata?.color ?? ButtonColor.primary) as ButtonColor"
    :title="action.label"
    @click.stop="handleActionClick"
  >
    {{ action.label }}
  </Button>

  <component
    :is="metadata?.color === 'danger' ? DangerDialog : Dialog"
    v-if="metadata?.confirmation"
    :isOpen="confirmingAction"
    :title="metadata.confirmation.title"
    :message="metadata.confirmation.message"
    :isLoading="isLoadingAction"
    cancelButtonLabel="Cancel"
    :confirmButtonLabel="metadata.confirmation.buttonLabel ?? 'Move to trash'"
    @onConfirm="handleConfirmAction"
    @onCancel="handleCancelConfirmation"
    @onClose="handleCancelConfirmation"
  />
</template>
