<script setup lang="tsx">
import type { CustomCells, HybridlyRow } from '@/components/table/Table';

import { ButtonColor, ButtonSize, ButtonVariant } from '@/hooks/useButtonClasses';
import { useAuth } from '@/hooks/useAuth';
import { useFilters } from '@/hooks/useFilters';
import { useHybridlyTable } from '@/hooks/useHybridlyTable';
import { useTableRowClick } from '@/hooks/useTableRowClick';

import Button from '@/components/button/Button.vue';
import EmptyState from '@/components/emptyState/EmptyState.vue';
import IndexPageLayout from '@/components/pageLayout/IndexPageLayout.vue';
import PaddedCellContent from '@/components/table/PaddedCellContent.vue';
import TableBadgesCell from '@/components/table/TableBadgesCell.vue';
import TableEnumCell from '@/components/table/TableEnumCell.vue';
import TabNavItem from '@/components/tabNav/TabNavItem.vue';
import { AppliedFilters } from '@/components/distiller/types';

import Building from '@/icons/line/building.svg';
import Plus from '@/icons/line/plus.svg';
import BulkRestoreButton from '@/components/table/BulkRestoreButton.vue';
import BulkTrashButton from '@/components/table/BulkTrashButton.vue';

type GoalRow = HybridlyRow<App.Configuration.Data.GoalData>;

const props = defineProps<{
  goals: Table<App.Configuration.Data.GoalData>;
  appliedFilters: AppliedFilters;
  distiller: App.Search.Data.DistillerData;
  trashActive: boolean;
  counts: {
    active_count: number;
    trashed_count: number;
  };
}>();

const { hasPermission } = useAuth();

const { table, tableSelectionState } = useHybridlyTable<App.Configuration.Data.GoalData>(
  props,
  'goals'
);
const { handleRowClick } = useTableRowClick();

const { encodeFilters } = useFilters();

const encodedFilters = computed(() => encodeFilters(props.appliedFilters));

const customCells = {
  goal_type: ({ row, column }) => <TableEnumCell enumData={row.value(column.key)} />,
  grade: ({ row, column }) => <TableEnumCell enumData={row.value(column.key)} />,
  goal_percentage: ({ row, column }) => (
    <PaddedCellContent>{row.value(column.key)}%</PaddedCellContent>
  ),
  schools: ({ row, column }) => (
    <TableBadgesCell
      badgesToDisplay={2}
      items={row.value(column.key).map((school: App.Configuration.Data.SchoolData) => school.name)}
      itemIcon={Building}
    />
  )
} as CustomCells<App.Configuration.Data.GoalData>;

function handleGoalRowClick(event: MouseEvent, row: GoalRow) {
  const url = route('goals.show', {
    goal: row.key
  });
  handleRowClick(url, event);
}
</script>


					<script lang="tsx">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <IndexPageLayout
    title="Goals"
    :table="table"
    :appliedFilters="appliedFilters"
    :distiller="distiller"
    :customCells="customCells"
    :stickyEndColumns="1"
    :stickyStartColumns="1"
    :clickableRows="!trashActive"
    :tableSelectionState="tableSelectionState"
    @clickRow="($event, row) => handleGoalRowClick($event, row as GoalRow)"
  >
    <template #rightButtons>
      <template v-if="tableSelectionState.hasSelection">
        <BulkTrashButton
          v-if="!trashActive"
          recordType="Goals"
          :route="route('goals.bulk-delete')"
          :tableSelectionState="tableSelectionState"
        />

        <BulkRestoreButton
          v-if="trashActive"
          recordType="Goals"
          :route="route('goals.bulk-restore')"
          :tableSelectionState="tableSelectionState"
        />
      </template>
      <template v-else>
        <Button
          v-if="hasPermission('create-goal')"
          :color="ButtonColor.primary"
          :variant="ButtonVariant.solid"
          :size="ButtonSize.sm"
          :iconLeft="Plus"
          :href="route('goals.create')"
        >
          Add a new goal
        </Button>
      </template>
    </template>

    <template #tabs>
      <TabNavItem
        :badgeLabel="counts.active_count"
        :isActive="!trashActive"
        label="Active"
        variant="inline"
        :href="
          route('goals.index', {
            ...(encodedFilters ? { filters: encodedFilters } : {})
          })
        "
      />
      <TabNavDivider layout="inline" />
      <TabNavItem
        :badgeLabel="counts.trashed_count ?? 0"
        :isActive="trashActive"
        label="Trashed"
        variant="inline"
        :href="
          route('goals.index', {
            trashed: true,
            ...(encodedFilters ? { filters: encodedFilters } : {})
          })
        "
      />
    </template>

    <template #emptyState>
      <EmptyState
        v-if="trashActive"
        title="No goals were moved to the trash"
        description="Trashed goals will appear on this screen"
      />
      <EmptyState
        v-else
        title="No goals found"
        :description="
          hasPermission('create-goal') ? 'Create a new goal by clicking the button below' : ''
        "
        :actionLabel="hasPermission('create-goal') ? 'Add a goal' : ''"
        :actionHref="route('goals.create')"
      />
    </template>
  </IndexPageLayout>
</template>
