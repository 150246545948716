<script setup lang="tsx">
import { ButtonColor, ButtonVariant } from '@/hooks/useButtonClasses';

import { buildSelectableProgram, buildSelectableUser } from '@/utils/buildSelectable';

import Button from '@/components/button/Button.vue';
import Form from '@/components/form/Form.vue';
import FormFooter from '@/components/formFooter/FormFooter.vue';
import FormHeader from '@/components/formHeader/FormHeader.vue';
import TabNav from '@/components/tabNav/TabNav.vue';
import TabNavItem from '@/components/tabNav/TabNavItem.vue';
import CenteredContent from '@/components/content/CenteredContent.vue';
import { HybridlyFormData } from '@/@types/global';

type ReportTemplateData = App.Reports.Data.ReportTemplateData;

const props = defineProps<{
  template: ReportTemplateData;
}>();

defineEmits<{
  onSubmit: [];
}>();

const { matches } = useRoute();

type FormProps = {
  name: string;
  description: string;
  filters: App.Search.Data.FilterData[];
  column_filters: App.Search.Data.FilterData[];
  columns: App.Reports.Data.ColumnData[];
};

type Column = App.Reports.Data.ColumnData;

function walkColumns(acc: Record<string, Column>, columnOrGroup: Column) {
  if (columnOrGroup.columns) {
    return columnOrGroup.columns.reduce(walkColumns, acc);
  }

  acc[columnOrGroup.transientId] = columnOrGroup;

  return acc;
}

const keyedColumns = computed(
  () => props.template.report_type.columns?.reduce(walkColumns, {} as Record<string, Column>) ?? []
);

const form = useForm({
  method: 'put',
  url: route('report-templates.update', { reportTemplate: props.template.id }),
  fields: {
    name: props.template.name,
    description: props.template.description,
    filters: props.template.filters,
    column_filters: props.template.column_filters,
    columns: props.template.columns,
    share_template:
      props.template.is_system_template ||
      props.template.users?.length ||
      props.template.programs?.length,
    is_system_template: props.template.is_system_template,
    program_ids: (props.template.programs || []).map((program) => buildSelectableProgram(program)),
    user_ids: (props.template.users || []).map((user) => buildSelectableUser(user))
  },
  transform: (data) => ({
    ...data,
    columns: Object.values(data.columns)
      .map((transientId) => keyedColumns.value[transientId])
      .filter(Boolean)
      .map((column) => ({
        key: column.key,
        transientId: column.transientId,
        extras: column.extras ?? {}
      })) as unknown,
    program_ids: data.is_system_template
      ? []
      : data.program_ids?.map((program) => program?.value) ?? [],
    user_ids: data.is_system_template ? [] : data.user_ids?.map((user) => user?.value) ?? []
  }),
  updateInitials: true,
  reset: true
});

defineSlots<{
  default(props: { form: HybridlyFormData<FormProps> }): unknown;
}>();

function handleFormSubmit() {
  form.submit();
}

function onCancel() {
  router.get(route('report-templates.index'));
}
</script>


					<script lang="tsx">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <CenteredContent size="lg">
    <Form @submit.prevent="handleFormSubmit">
      <template #header>
        <FormHeader
          canClose
          :title="`Edit ${template.name} Template`"
          description="Use the forms below and hit 'Save changes' to edit the report template."
          @onClose="onCancel"
        />
      </template>

      <TabNav layout="inline">
        <TabNavItem
          :href="route('report-templates.edit', { reportTemplate: template.id })"
          :isActive="matches('report-templates.edit')"
          label="General Information"
          variant="inline"
        />
        <TabNavItem
          v-if="template.report_type.can_customize_filters"
          :href="route('report-templates.filters.edit', { reportTemplate: template.id })"
          :isActive="matches('report-templates.filters.edit')"
          label="Filters"
          variant="inline"
        />
        <TabNavItem
          v-if="template.report_type.can_customize_columns"
          :href="route('report-templates.columns.edit', { reportTemplate: template.id })"
          :isActive="matches('report-templates.columns.edit')"
          label="Columns"
          variant="inline"
        />
        <TabNavItem
          v-if="template.report_type.can_customize_columns"
          :href="route('report-templates.column-order.edit', { reportTemplate: template.id })"
          :isActive="matches('report-templates.column-order.edit')"
          label="Column Order"
          variant="inline"
        />
        <TabNavItem
          :href="route('report-templates.access.edit', { reportTemplate: template.id })"
          :isActive="matches('report-templates.access.edit')"
          label="Access"
          variant="inline"
        />
      </TabNav>

      <slot :form="form" />

      <template #footer>
        <CenteredContent size="md" :padded="false">
          <FormFooter>
            <Button
              :color="ButtonColor.primary"
              :isDisabled="form.processing || !form.isDirty"
              :isLoading="form.processing"
              :variant="ButtonVariant.solid"
              type="submit"
              >Save Changes</Button
            >
            <Button
              :color="ButtonColor.slate"
              :variant="ButtonVariant.outlined"
              type="button"
              @click="onCancel"
            >
              Cancel
            </Button>
          </FormFooter>
        </CenteredContent>
      </template>
    </Form>
  </CenteredContent>
</template>
