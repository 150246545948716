import { debounce } from 'lodash';

import { buildSelectableUser } from '@/utils/buildSelectable';

import { search } from '@/api/UserApi';

import { Selectable } from '@/components/selectBox/selectBox';

export function useUsersSearch(searchFilters?: Record<string, unknown>) {
  const searchQuery = ref('');
  const isLoading = ref(false);
  const userOptions = ref<Selectable<number>[]>([]);
  const localSearchFilters = ref(searchFilters);

  const searchUsers = debounce(async (query: string) => {
    const response = await search({
      filters: {
        ...localSearchFilters.value,
        search: query
      }
    });
    userOptions.value = response.map((user) => buildSelectableUser(user)) as Selectable<number>[];

    isLoading.value = false;
  }, 300);

  function updateSearchFilters(searchFilters?: Record<string, unknown>) {
    localSearchFilters.value = searchFilters;
    searchUsers(searchQuery.value);
  }

  function onQueryChange(query: string): void {
    isLoading.value = true;
    searchQuery.value = query;

    searchUsers(query);
  }

  return {
    userOptions,
    isLoading,
    onQueryChange,
    updateSearchFilters
  };
}
