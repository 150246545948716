<script setup lang="ts">
import type { ShowAlertProps } from '@/domains/configuration/views/alerts/ShowAlert.vue';

import capitalize from 'lodash/capitalize';

import { useAlertColor } from '@/hooks/useAlertColor';
import { useAuth } from '@/hooks/useAuth';
import { useDialogHelpers } from '@/hooks/useDialogHelpers';

import { formatDate } from '@/utils/date';

import Badge from '@/components/badge/Badge.vue';
import DangerDialog from '@/components/dialog/DangerDialog.vue';
import DefinitionList from '@/components/definitionList/DefinitionList.vue';
import DefinitionListItem from '@/components/definitionList/DefinitionListItem.vue';
import DefinitionListItemBadgeWrapper from '@/components/definitionList/DefinitionListItemBadgeWrapper.vue';
import IconButton from '@/components/button/IconButton.vue';
import InformationCollapsible from '@/components/collapsible/InformationCollapsible.vue';
import RichContent from '@/components/richTextInput/RichContent.vue';
import Slideout from '@/components/slideout/Slideout.vue';

import Calendar from '@/icons/line/calendar.svg';
import EditAlt from '@/icons/line/edit-alt.svg';
import Envelope from '@/icons/line/envelope.svg';
import FilesLandscapesAlt from '@/icons/line/files-landscapes-alt.svg';
import Megaphone from '@/icons/line/megaphone.svg';
import Pen from '@/icons/line/pen.svg';
import TagAlt from '@/icons/line/tag-alt.svg';
import TrashIcon from '@/icons/line/trash.svg';
import User from '@/icons/line/user.svg';

const props = defineProps<ShowAlertProps>();

const { getAlertColor } = useAlertColor();
const { show, close } = useDialog();
const { isDialogOpen, toggleDialog } = useDialogHelpers();

const { hasPermission } = useAuth();

const form = useForm({
  method: 'DELETE',
  spoof: false,
  url: route('alerts.delete', { alert: props.alert.id }),
  fields: {},
  hooks: {
    success() {
      close();
    }
  }
});

async function handleAlertDelete() {
  await form.submit();
  toggleDialog();
}
</script>

<template>
  <Slideout title="Alert Information" :isOpen="show" @onClose="close" :icon="Megaphone">
    <template #actions>
      <IconButton
        v-if="alert.authorization.update"
        :href="route('alerts.edit', { alert: alert.id })"
        :icon="EditAlt"
        ariaLabel="Edit alert"
        size="sm"
        variant="soft"
      />
      <IconButton
        v-if="alert.authorization.delete"
        color="danger"
        :icon="TrashIcon"
        ariaLabel="Move alert to trash"
        size="sm"
        variant="soft"
        @click="toggleDialog"
      />
    </template>
    <div class="-m-4 flex flex-col divide-y">
      <div class="p-4">
        <InformationCollapsible title="Alert Information">
          <DefinitionList>
            <DefinitionListItem title="Title" :description="alert.title" :icon="Pen" />
            <DefinitionListItem :description="alert.type.label" :icon="TagAlt" title="Type">
              <Badge
                :color="getAlertColor(alert.type.value)"
                :label="capitalize(alert.type.label)"
                shape="pill"
                size="md"
                variant="solid"
              />
            </DefinitionListItem>
            <DefinitionListItem
              :description="alert.creator?.name"
              :icon="User"
              title="Created by"
            />
            <DefinitionListItem
              :description="formatDate(alert.expiration_date) || 'No expiration date'"
              :icon="Calendar"
              title="Expiration Date"
            />
          </DefinitionList>
        </InformationCollapsible>
      </div>
      <div class="p-4">
        <InformationCollapsible title="Content">
          <div
            v-if="alert.content_html"
            class="rounded-2xl border border-slate-200 bg-slate-50 p-4"
          >
            <RichContent :html="alert.content_html" />
          </div>
          <p v-else class="text-sm text-slate-900">No content</p>
        </InformationCollapsible>
      </div>
      <div v-if="hasPermission('read-program')" class="p-4">
        <InformationCollapsible title="Program Details">
          <DefinitionList>
            <DefinitionListItem
              :description="!alert.programs?.length ? 'No programs' : undefined"
              :icon="FilesLandscapesAlt"
              title="Programs"
            >
              <DefinitionListItemBadgeWrapper
                v-if="alert.programs?.length"
                :badgeItems="
                  alert.programs?.map((program) => ({
                    id: program?.id,
                    label: program?.display_name
                  })) ?? []
                "
                :buttonRoute="route('programs.index')"
                buttonLabel="Go to programs"
              />
            </DefinitionListItem>
          </DefinitionList>
        </InformationCollapsible>
      </div>
      <div v-if="alert.users?.length" class="p-4">
        <InformationCollapsible title="Users">
          <div class="divide-y divide-slate-200">
            <DefinitionList
              v-for="user in alert.users"
              :key="user.id"
              class="py-4 first:pt-0 last:pb-0"
            >
              <DefinitionListItem :description="user.name" :icon="User" title="Name">
                <Badge :label="user.name" variant="soft" size="md" />
              </DefinitionListItem>
              <DefinitionListItem
                :description="user?.email ?? 'No email'"
                :icon="Envelope"
                title="Email"
              />
            </DefinitionList>
          </div>
        </InformationCollapsible>
      </div>
    </div>
  </Slideout>
  <DangerDialog
    title="Move Alert to Trash"
    message="Are you sure you want to move this alert to the trash?"
    confirmButtonLabel="Move to trash"
    :isOpen="isDialogOpen"
    :isLoading="form.processing"
    @onConfirm="handleAlertDelete"
    @onCancel="toggleDialog"
    @onClose="toggleDialog"
  />
</template>
