<script setup lang="ts">
import type { WithLoadedRelations } from '@/@types/global';

import { ButtonColor, ButtonVariant } from '@/hooks/useButtonClasses';

import Badge from '@/components/badge/Badge.vue';
import Button from '@/components/button/Button.vue';
import Slideout from '@/components/slideout/Slideout.vue';
import StudentInteractionDefinitionList from '@/components/students/StudentInteractionDefinitionList.vue';
import StudentInteractionDefinitionListItem from '@/components/students/StudentInteractionDefinitionListItem.vue';
import ThreadMessageList from '@/components/sms/ThreadMessageList.vue';

import DocumentLayoutLeft from '@/icons/line/document-layout-left.svg';
import IconTimes from '@/icons/line/times.svg';

type Props = {
  isOpen?: boolean;
  notification: App.Notifications.Data.NotificationData;
};

const props = defineProps<Props>();

const interaction = computed(() => props.notification.subject as App.Students.Data.InteractionData);

const isResolved = computed(
  () => !!interaction.value.approved_at || !!interaction.value.deleted_at
);

const emit = defineEmits<{ onClose: [void] }>();

const approvalForm = useForm({
  method: 'PUT',
  url: route('students.interactions.approve', {
    student: interaction.value.studentId,
    interaction: interaction.value.id
  }),
  updateInitials: true,
  fields: {
    approved: true
  },
  hooks: {
    success() {
      router.reload({ only: ['notifications'] });
    }
  }
});

const rejectForm = useForm({
  method: 'DELETE',
  fields: {},
  url: route('students.interactions.delete', {
    student: interaction.value.studentId,
    interaction: interaction.value.id
  }),
  updateInitials: true,
  hooks: {
    success() {
      router.reload({ only: ['notifications'] });
    }
  }
});

function onClose() {
  emit('onClose');
}

function handleApprove() {
  approvalForm.submit();
}

function handleReject() {
  rejectForm.submit();
}
</script>

<template>
  <Slideout title="Draft interactions" :isOpen="isOpen" @onClose="onClose">
    <template #content>
      <div class="flex h-full flex-col">
        <section class="sticky top-0 z-10 bg-white">
          <div class="border-b border-slate-200">
            <div class="flex flex-row items-center justify-between pb-4 pl-4 pt-5">
              <div class="text-base font-semibold leading-6 text-slate-900">Draft Interaction</div>
              <Button
                :color="ButtonColor.slate"
                :iconRight="IconTimes"
                :variant="ButtonVariant.invisible"
                @click="onClose"
                >Close</Button
              >
            </div>
          </div>
        </section>

        <ThreadMessageList
          v-if="interaction.thread"
          class="flex-1 bg-zinc-50"
          :thread="interaction.thread as WithLoadedRelations<App.Sms.Data.ThreadData, 'recipient'>"
          :highlightMessageIds="interaction.sms_message_ids"
        />

        <section class="sticky bottom-0 z-10 bg-white">
          <div class="border-y border-slate-200 px-4 py-3">
            <p class="text-base font-semibold leading-6 text-black">
              Draft Interaction Information
            </p>
          </div>

          <div class="border-b border-slate-200 px-5 py-4">
            <div class="flex flex-col gap-2">
              <div class="flex items-center gap-2">
                <DocumentLayoutLeft class="h-4.5 w-4.5 text-slate-700 opacity-70" />
                <h3 class="text-sm font-semibold leading-6 text-slate-700">Note</h3>
              </div>
              <p class="text-sm leading-5 text-slate-700">{{ interaction.notes }}</p>
            </div>
          </div>

          <div class="border-b border-slate-200 px-5 py-4">
            <div
              class="flex flex-col divide-y divide-slate-200 lg:flex-row lg:divide-x lg:divide-y-0"
            >
              <div class="flex flex-col gap-2">
                <div class="flex items-center gap-2">
                  <DocumentLayoutLeft class="h-4.5 w-4.5 text-slate-700 opacity-70" />
                  <h3 class="text-sm font-semibold leading-6 text-slate-700">Tags</h3>
                </div>
                <StudentInteractionDefinitionList>
                  <StudentInteractionDefinitionListItem title="Topic">
                    <Badge
                      v-for="topic in interaction.topics"
                      :key="topic.value"
                      :label="topic.label"
                      size="md"
                      variant="soft"
                    />
                  </StudentInteractionDefinitionListItem>
                  <StudentInteractionDefinitionListItem title="Type">
                    <Badge
                      v-for="type in interaction.types"
                      :key="type.value"
                      :label="type.label"
                      size="md"
                      variant="soft"
                    />
                  </StudentInteractionDefinitionListItem>
                </StudentInteractionDefinitionList>
              </div>
            </div>
          </div>
          <div class="flex flex-row gap-2 px-4 py-2">
            <template v-if="!isResolved">
              <Button
                class="w-1/2 py-2.5"
                :color="ButtonColor.danger"
                :isLoading="rejectForm.processing"
                :variant="ButtonVariant.outlined"
                @click.prevent="handleReject"
                >Reject</Button
              >
              <Button
                class="w-1/2 py-2.5"
                :isLoading="approvalForm.processing"
                :color="ButtonColor.primary"
                :variant="ButtonVariant.solid"
                @click.prevent="handleApprove"
                >Approve and save</Button
              >
            </template>
            <template v-else>
              <p class="w-full py-4 text-center font-bold">
                This draft interaction has been
                {{ interaction.deleted_at !== null ? 'rejected' : 'approved' }}.
              </p>
            </template>
          </div>
        </section>
      </div>
    </template>
  </Slideout>
</template>
