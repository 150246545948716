import SmsAttachmentData = App.Sms.Data.SmsAttachmentData;
import { v4 as uuidv4 } from 'uuid';
import { previewUrlForImageFile } from '@/hooks/files/useFileUpload';
import axios from 'axios';
import { ModelRef } from 'vue';

interface UploadingAttachment extends Omit<SmsAttachmentData, 'id' | 'created_at' | 'updated_at'> {
  id: string;
  file: File;
}

export function useSmsAttachmentUpload(attachments: ModelRef<SmsAttachmentData[]>) {
  const uploadingAttachments = ref<UploadingAttachment[]>([]);

  function handleFilesPicked(event: Event) {
    const target = event.target as HTMLInputElement;

    const pickedFiles = Array.from(target.files || []).map((file: File): UploadingAttachment => {
      return {
        id: uuidv4(),
        content_type: file.type,
        file_url: previewUrlForImageFile(file),
        file
      };
    });

    uploadingAttachments.value = [...uploadingAttachments.value, ...pickedFiles];

    pickedFiles.forEach((attachment) => {
      const formData = new FormData();
      formData.append('file', attachment.file);

      axios.post<SmsAttachmentData>(route('api.sms.attachments.upload'), formData).then((res) => {
        uploadingAttachments.value = uploadingAttachments.value.filter(
          (uploadingAttachment) => uploadingAttachment.id !== attachment.id
        );

        attachments.value = [...attachments.value, res.data];
      });
    });
  }

  function removeAttachment(id: string | number) {
    attachments.value = attachments.value.filter((attachment) => attachment.id !== id);
  }

  const attachmentThumbnails = computed<
    Array<{
      id: string | number;
      src: string;
      isUploading: boolean;
    }>
  >(() => {
    return [
      ...attachments.value.map((attachment: SmsAttachmentData) => ({
        id: attachment.id,
        src: attachment.file_url,
        isUploading: false
      })),
      ...uploadingAttachments.value.map((attachment: UploadingAttachment) => ({
        id: attachment.id,
        src: attachment.file_url,
        isUploading: true
      }))
    ];
  });

  return { handleFilesPicked, uploadingAttachments, removeAttachment, attachmentThumbnails };
}
