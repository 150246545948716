<script setup lang="ts">
import { groupBy } from 'lodash';
import {
  getRecipientTypeIcon,
  getRecipientTypeLabel
} from '@/domains/sms/utils/recipientTypeFormatter';
import { useSmsRecipientSearch } from '@/hooks/search/useSmsRecipientSearch';
import ComboboxInput from '@/components/selectBox/combobox/ComboboxInput.vue';
import { Selectable } from '@/components/selectBox/selectBox';
import SearchIcon from '@/icons/line/search.svg';
import ComboboxOption from '@/components/selectBox/combobox/ComboboxOption.vue';
import RecipientSearchOption from './RecipientSearchOption.vue';

const emit = defineEmits<{
  (e: 'selectRecipient', recipient: App.Sms.Data.SmsRecipientData): void;
}>();

const { onQueryChange, recipientOptions, isLoading } = useSmsRecipientSearch();

const groupedRecipientOptions = computed(() => {
  const selectableOptions = recipientOptions.value.map((recipient) =>
    buildSelectableSmsRecipient(recipient)
  );
  return groupBy(selectableOptions, 'meta.recipient_type');
});

function handleAddRecipient(selectableRecipient: Selectable<string> | null) {
  if (!selectableRecipient) {
    return;
  }

  const recipient = selectableRecipient.meta as App.Sms.Data.SmsRecipientData;

  if (recipient) {
    emit('selectRecipient', recipient);
  }
}

function buildSelectableSmsRecipient(
  recipient: App.Sms.Data.SmsRecipientData
): Selectable<string, App.Sms.Data.SmsRecipientData> {
  return {
    value: `${recipient.recipient_type}:${recipient.recipient_id}`,
    label: recipient.name ?? '',
    meta: recipient
  };
}
</script>


					<script lang="ts">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <ComboboxInput
    placeholder="Search recipients..."
    :iconLeft="SearchIcon"
    :isLoading="isLoading"
    :options="recipientOptions.map((recipient) => buildSelectableSmsRecipient(recipient))"
    :onFilter="onQueryChange"
    @update:modelValue="handleAddRecipient"
    @open="() => onQueryChange('')"
  >
    <template #options="{ focusOnInput }">
      <div
        v-for="recipientType in Object.keys(groupedRecipientOptions)"
        :key="recipientType"
        class="space-y-1"
      >
        <div class="flex items-center gap-1.5 pb-2 pt-1">
          <component
            :is="getRecipientTypeIcon(recipientType ?? 'phone_number')"
            class="h-4 w-4 text-slate-400"
          />
          <p class="text-2xs font-bold uppercase text-slate-600">
            {{ getRecipientTypeLabel(recipientType ?? 'phone_number') }}
          </p>
        </div>
        <ComboboxOption
          v-for="option in groupedRecipientOptions[recipientType]"
          :key="option.label"
          :option="option"
          :modelValue="null"
          @click="focusOnInput"
        >
          <RecipientSearchOption :option="option" />
        </ComboboxOption>
      </div>
    </template>
  </ComboboxInput>
</template>
