<script setup lang="ts">
import { DialogClose } from 'radix-vue';

import { RouterLink } from 'hybridly/vue';

const props = defineProps<{
  label: string;
  icon?: Component;
  isExternalLink?: boolean;
  href?: string;
}>();

const emit = defineEmits<{
  click: [void];
}>();

const rawIcon = toRaw(props.icon);

const computedButtonComponent = computed(() => {
  if (props.isExternalLink && props.href) {
    return 'a';
  }
  return props.href ? RouterLink : 'button';
});

function onMenuItemClick() {
  emit('click');
}
</script>

<template>
  <DialogClose>
    <component
      class="flex w-full items-center gap-1.5 truncate rounded-lg bg-white px-3 py-2.5 text-sm leading-5 text-slate-700 transition-colors duration-150 ease-in-out hover:bg-slate-100 active:bg-slate-200"
      :href="href"
      :is="computedButtonComponent"
      @click="onMenuItemClick"
    >
      <component v-if="icon" class="h-4 w-4 text-slate-500" :is="rawIcon" />
      {{ label }}
    </component>
  </DialogClose>
</template>
