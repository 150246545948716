import { debounce } from 'lodash';

import { buildSelectableCustomTest } from '@/utils/buildSelectable';

import { search } from '@/api/CustomTestApi';

import { Selectable } from '@/components/selectBox/selectBox';

export function useCustomTestsSearch(searchFilters?: Record<string, unknown>) {
  const searchQuery = ref('');
  const isLoading = ref(false);
  const customTestOptions = ref<Selectable<string>[]>([]);
  const localSearchFilters = ref(searchFilters);

  const searchCustomTests = debounce(async (query: string) => {
    const response = await search({
      filters: {
        ...localSearchFilters.value,
        search: query
      }
    });
    customTestOptions.value = response.map((customTest) =>
      buildSelectableCustomTest(customTest)
    ) as Selectable<string>[];

    isLoading.value = false;
  }, 300);

  function updateSearchFilters(searchFilters?: Record<string, unknown>) {
    localSearchFilters.value = searchFilters;
    searchCustomTests(searchQuery.value);
  }

  function onQueryChange(query: string): void {
    isLoading.value = true;
    searchQuery.value = query;

    searchCustomTests(query);
  }

  return {
    customTestOptions,
    isLoading,
    onQueryChange,
    updateSearchFilters
  };
}
