<script setup lang="ts">
import type { Selectable } from '@/components/selectBox/selectBox';

import CenteredContent from '@/components/content/CenteredContent.vue';
import ProgramStudentFieldForm from '@/components/programStudentFields/ProgramStudentFieldForm.vue';

type EnumData = App.Base.Data.EnumData;

defineProps<{
  returnUrl?: string;
  types: EnumData[];
}>();

const form = useForm({
  method: 'POST',
  url: route('program-student-fields.store'),
  fields: {
    field_label: '',
    type: null as Selectable<string> | null,
    program_id: null as Selectable<number> | null,
    enabled: false,
    in_results: false,
    rules: {}
  },
  transform: (fields) => ({
    ...fields,
    type: fields.type?.value,
    program_id: fields.program_id?.value ?? null
  })
});
</script>


					<script lang="ts">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <CenteredContent>
    <ProgramStudentFieldForm :form="form" :types="types" />
  </CenteredContent>
</template>
