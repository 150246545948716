import { AppliedFilters } from '@/components/distiller/types';
import { Selectable } from '@/components/selectBox/selectBox';
import { formatDate } from '@/utils/date';

export type PrepareFormValues = {
  filters: AppliedFilters;
};

export type CreateFormValues = {
  name: string;
  meta: {
    test_type: string | null;
    school_id: Selectable<number> | null;
    disenroll_missing_students: string | undefined;
    grade: Selectable<number> | null;
  };
};

export type UploadFormValues = {
  file: File | undefined;
  column_mappings: Record<string, Selectable<string>>;
};

type Step = {
  label: string;
  description: string;
  subtitle?: string;
};
type WizardOptions<T extends Record<string, Step> = Record<string, Step>> = {
  config: T;
  order: Array<keyof T>;
};

const useWizard = <T extends Record<string, Step>>({ config, order }: WizardOptions<T>) => {
  const guidedSteps = useStepper(order as string[], order[0] as string);

  const steps = computed(() => guidedSteps.stepNames.value.map((stepName) => config[stepName]));

  const currentStep = computed(() => config[guidedSteps.current.value]);

  return {
    currentStep,
    steps,
    guidedSteps
  };
};

export function defaultCreateFormValues({
  uploadType = '',
  testType = null
}: {
  uploadType?: string;
  testType?: string | null;
}): CreateFormValues {
  return {
    name: `${uploadType} Session - ${formatDate(new Date())}` ?? '',
    meta: {
      test_type: testType ?? null,
      school_id: null,
      disenroll_missing_students: undefined,
      grade: null
    }
  };
}

export const defaultUploadFormValues: UploadFormValues = {
  file: undefined,
  column_mappings: {}
};

export const transformCreateFormValues =
  <T extends CreateFormValues>(uploadType: App.Bulkinator.Data.UploadTypeData) =>
  ({ meta, name }: T) => {
    return {
      name,
      upload_type: uploadType.id,
      meta: {
        test_type: meta.test_type ?? null,
        grade: meta.grade?.value ?? null,
        school_id: meta.school_id?.value ?? null,
        disenroll_missing_students:
          meta.disenroll_missing_students === undefined
            ? null
            : meta.disenroll_missing_students === 'yes'
      }
    };
  };

export const transformMappingFormValues = <T extends UploadFormValues>({
  column_mappings,
  file
}: T) => {
  return {
    file,
    column_mappings: Object.entries(column_mappings).reduce(
      (acc, curr) => {
        const [header, selectable] = curr;

        if (selectable?.label) {
          acc[header] = selectable.label;
        }

        return acc;
      },
      {} as Record<string, string>
    )
  };
};

export const useUploadSessionFormWizard = <T extends object>(
  wizardOptions: WizardOptions,
  formOptions: Parameters<typeof useForm<T>>[0]
) => {
  const wizard = useWizard(wizardOptions);

  const form = useForm<T>(formOptions);

  return {
    form,
    ...wizard
  };
};
