<script setup lang="ts">
import { useAuth } from '@/hooks/useAuth';

import BulkCreateStudentNoteDialog from '@/domains/students/components/dialogs/BulkCreateStudentNoteDialog.vue';
import FloatingActionButton from '@/components/floatingActionButton/FloatingActionButton.vue';
import FloatingActionButtonItem from '@/components/floatingActionButton/FloatingActionButtonItem.vue';
import MenuButton from '@/components/button/MenuButton.vue';
import MenuItem from '@/components/menu/MenuItem.vue';
import MetricCardContainer from '@/components/metricCard/MetricCardContainer.vue';
import ShowStudentPageButtons from '@/components/students/ShowStudentPageButtons.vue';
import ShowStudentPageNav from '@/components/students/ShowStudentPageNav.vue';
import TabNav from '@/components/tabNav/TabNav.vue';
import TabNavDivider from '@/components/tabNav/TabNavDivider.vue';

import Notes from '@/icons/line/notes.svg';
import CommentPlus from '@/icons/line/comment-plus.svg';
import University from '@/icons/line/university.svg';
import Coins from '@/icons/line/coins.svg';
import Clipboard from '@/icons/line/clipboard.svg';
import FileCheckAlt from '@/icons/line/file-check-alt.svg';

const { hasPermission } = useAuth();

const [isNoteOpen, toggleNoteOpen] = useToggle(false);

defineProps<{
  student: App.Students.Data.StudentData;
  title: string;
}>();
</script>

<template>
  <div class="divide-y-slate-200 flex h-full flex-col divide-y">
    <div class="flex items-center gap-2.5 px-4 pb-2 pt-2.5">
      <div class="min-w-0 flex-1 shrink md:py-1.5">
        <div class="flex min-w-0 flex-grow items-center gap-2.5">
          <h1 class="truncate text-base font-semibold leading-6">{{ title }}</h1>
        </div>
      </div>
      <div class="flex">
        <div class="hidden lg:flex">
          <TabNavDivider class="ml-0 mr-2.5" layout="inline" />
          <ShowStudentPageButtons :student="student" />
        </div>
        <div class="hidden xl:flex">
          <TabNavDivider class="mx-2.5" layout="inline" />
          <ShowStudentPageNav :student="student" />
        </div>
        <TabNavDivider class="mx-2.5 hidden md:block" layout="inline" />
        <div class="hidden items-center md:flex">
          <MenuButton buttonLabel="Add">
            <MenuItem
              v-if="hasPermission('create-interaction')"
              :href="route('students.interactions.create', { student: student.id })"
              :iconLeft="CommentPlus"
              label="Interaction"
            />
            <MenuItem
              :href="route('students.college-applications.create', { student: student.id })"
              :iconLeft="University"
              label="College application"
            />
            <MenuItem
              :href="route('students.student-scholarships.create', { student: student.id })"
              :iconLeft="Coins"
              label="Scholarship application"
            />
            <MenuItem
              :href="route('students.tests.create', { student: student.id })"
              :iconLeft="Clipboard"
              label="Exams"
            />
            <MenuItem
              v-if="hasPermission('create-note')"
              :iconLeft="Notes"
              label="Note"
              @click="toggleNoteOpen()"
            />
          </MenuButton>
        </div>
        <Teleport to="body">
          <div class="fixed bottom-4 right-4 flex items-center justify-center md:hidden">
            <FloatingActionButton ariaLabel="Add">
              <FloatingActionButtonItem
                v-if="hasPermission('create-interaction')"
                :href="route('students.interactions.create', { student: student.id })"
                :icon="CommentPlus"
                label="Interaction"
              />
              <FloatingActionButtonItem
                :href="route('students.college-applications.create', { student: student.id })"
                :icon="University"
                label="College application"
              />
              <FloatingActionButtonItem
                :href="route('students.student-scholarships.create', { student: student.id })"
                :icon="Coins"
                label="Scholarship application"
              />
              <FloatingActionButtonItem
                :href="route('students.tests.create', { student: student.id })"
                :icon="Clipboard"
                label="Exams"
              />
              <FloatingActionButtonItem :icon="FileCheckAlt" label="Survey response" />
            </FloatingActionButton>
          </div>
        </Teleport>
      </div>
    </div>
    <div class="block shrink-0 overflow-x-auto px-4 py-2 lg:hidden">
      <ShowStudentPageButtons :student="student" />
    </div>
    <div class="block shrink-0 overflow-x-auto px-4 py-0.5 xl:hidden">
      <ShowStudentPageNav :student="student" />
    </div>
    <div v-if="$slots.subNav" class="shrink-0 gap-2 overflow-x-auto px-4 py-3">
      <slot name="subNav" />
    </div>
    <MetricCardContainer v-if="$slots.metricCards">
      <slot name="metricCards" />
    </MetricCardContainer>
    <TabNav v-if="$slots.tabs" class="-mb-px" layout="inline">
      <slot name="tabs" />
    </TabNav>
    <div v-if="$slots.table" class="grow">
      <slot name="table" />
    </div>
    <div v-if="$slots.default" class="grow">
      <slot />
    </div>
    <div v-if="$slots.constrained" class="flex grow flex-col overflow-hidden">
      <slot name="constrained" />
    </div>
  </div>
  <BulkCreateStudentNoteDialog
    v-if="isNoteOpen"
    :selectedStudents="[student]"
    isOpen
    @onCancel="() => toggleNoteOpen()"
    @onClose="() => toggleNoteOpen()"
  />
</template>
