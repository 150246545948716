<script setup lang="ts">
import type { AppliedFilter, AppliedFilters } from '@/components/distiller/types';
import type { HybridlyFormData } from '@/@types/global';

import pluralize from 'pluralize';

import { ButtonColor, ButtonSize, ButtonVariant } from '@/hooks/useButtonClasses';
import { useAuth } from '@/hooks/useAuth';
import { useFilters } from '@/hooks/useFilters';

import Alert from '@/components/alert/Alert.vue';
import Button from '@/components/button/Button.vue';
import DangerDialog from '@/components/dialog/DangerDialog.vue';
import Distiller from '@/components/distiller/Distiller.vue';
import Fieldset from '@/components/fieldset/Fieldset.vue';
import FilterProvider from '@/components/distiller/FilterProvider.vue';
import Form from '@/components/form/Form.vue';
import FormError from '@/components/formError/FormError.vue';
import FormField from '@/components/formField/FormField.vue';
import FormFooter from '@/components/formFooter/FormFooter.vue';
import FormHeader from '@/components/formHeader/FormHeader.vue';
import FormSchoolInput from '@/components/formComboboxInput/FormSchoolInput.vue';
import FormTextInput from '@/components/formTextInput/FormTextInput.vue';

const props = defineProps<{
  form: HybridlyFormData<{
    name: string;
    school_id: number;
    student_ids: number[];
  }>;
  appliedFilters?: AppliedFilters;
  distiller?: App.Search.Data.DistillerData;
  isEditing?: boolean;
  returnUrl?: string;
}>();

const emit = defineEmits<{
  'update:appliedFilters': [value: AppliedFilters];
}>();

const isCancelDialogOpen = ref(false);

const { hasPermission } = useAuth();
const { onBackForward } = useBackForward();
const { filteredRecordsCount, getRecordCount } = useFilters();

const appliedFiltersCount = computed(() =>
  props.appliedFilters ? Object.keys(props.appliedFilters).length : 0
);

const appliedFiltersQueryStringValue = computed(() =>
  appliedFiltersCount.value > 0 ? btoa(JSON.stringify(props.appliedFilters)) : undefined
);

// TODO: Fix intercepting the back button
onBackForward(() => {
  router.abort();
});

function handleToggleCancelDialog() {
  if (props.form.isDirty) {
    isCancelDialogOpen.value = !isCancelDialogOpen.value;
    return;
  }
  handleRedirect();
}

function handleRedirect() {
  router.navigate({ url: props.returnUrl || route('student-groups.index') });
}

onMounted(() => {
  if (props.distiller) {
    getRecordCount(props.distiller, appliedFiltersQueryStringValue.value);
  }
});

watch(
  () => props.appliedFilters,
  () => {
    if (props.distiller) {
      getRecordCount(props.distiller, appliedFiltersQueryStringValue.value);
    }
  },
  { deep: true }
);

watch(
  () => props.form.fields.school_id,
  (newSchool) => {
    if (newSchool?.value) {
      emit('update:appliedFilters', {
        ...props.appliedFilters,
        school: {
          operator: 'in',
          value: [
            {
              value: newSchool.value,
              label: newSchool.label
            }
          ]
        } as unknown as AppliedFilter
      });
    }
  },
  { immediate: true }
);
</script>

<template>
  <Form @submit.prevent="form.submit">
    <template #header>
      <FormHeader
        canClose
        :title="`${isEditing ? 'Edit' : 'Add'} a Student Group`"
        :description="
          isEditing
            ? 'Use the form below to edit the student group.'
            : 'Fill out the form below add a new student group.'
        "
        @onClose="handleToggleCancelDialog"
      />
    </template>
    <Fieldset title="Student group information">
      <FormTextInput
        :error="form.errors.name"
        :modelValue="form.fields.name"
        label="Group name"
        name="name"
        @update:modelValue="(value) => (form.fields.name = value)"
      />
      <div class="flex flex-col items-start gap-y-2">
        <FormSchoolInput
          :error="form.errors.school_id"
          :isDisabled="isEditing"
          :modelValue="form.fields.school_id"
          label="School"
          placeholder="Select school"
          @update:modelValue="(value) => (form.fields.school_id = value)"
        />
        <Button
          v-if="hasPermission('create-student-group-without-school') && !isEditing"
          :color="ButtonColor.slate"
          :disabled="!form.fields.school_id"
          :size="ButtonSize.sm"
          :variant="ButtonVariant.soft"
          @click="form.fields.school_id = null"
        >
          Remove school
        </Button>
      </div>
      <FormField v-if="appliedFilters" isOptional label="Select students to add to the group">
        <FilterProvider
          :modelValue="appliedFilters"
          @update:modelValue="(value) => emit('update:appliedFilters', value)"
        >
          <Distiller v-if="distiller" :distiller="distiller">
            <template #recordCountLabel>
              <p class="text-sm font-semibold text-slate-700">
                {{
                  `Matches ${formatNumber(filteredRecordsCount?.count)} ${pluralize(
                    'Student',
                    filteredRecordsCount?.count
                  )}`
                }}
              </p>
            </template>
          </Distiller>
          <FormError v-if="form.errors.filters">{{ form.errors.filters }}</FormError>
        </FilterProvider>
      </FormField>
      <Alert
        title="Student Group Updates"
        color="warning"
        variant="soft"
        description="Student groups are static and do not update automatically. If you need to add or remove students from a group, you will need to manually update the group."
      />
    </Fieldset>
    <template #footer>
      <FormFooter>
        <Button
          type="submit"
          :color="ButtonColor.primary"
          :isDisabled="form.processing || !form.isDirty"
          :isLoading="form.processing"
          :variant="ButtonVariant.solid"
          >Confirm</Button
        >
        <Button
          :color="ButtonColor.slate"
          :variant="ButtonVariant.outlined"
          type="button"
          @click="handleToggleCancelDialog"
        >
          Back to student groups
        </Button>
      </FormFooter>
    </template>
  </Form>
  <DangerDialog
    :title="`Cancel ${isEditing ? 'editing' : 'adding a new'} student group`"
    :message="`Are you sure you want to cancel ${
      isEditing ? 'editing' : 'adding'
    } this student group? All ${isEditing ? 'changes' : 'information'} will be lost.`"
    :isOpen="isCancelDialogOpen"
    cancelButtonLabel="Stay on this page"
    confirmButtonLabel="Go back to student groups"
    @onCancel="handleToggleCancelDialog"
    @onClose="handleToggleCancelDialog"
    @onConfirm="handleRedirect"
  />
</template>
