export function useTableRowClick() {
  function handleRowClick(url: string, event: MouseEvent) {
    if (event.metaKey || event.ctrlKey) {
      window.open(url, '_blank', 'noopener,noreferrer');
    } else {
      router.navigate({ url });
    }
  }

  return {
    handleRowClick
  };
}
