<script setup lang="ts">
type EnumData = App.Base.Data.EnumData;

import {
  SelectContent,
  SelectItem,
  SelectItemText,
  SelectPortal,
  SelectRoot,
  SelectTrigger,
  SelectViewport
} from 'radix-vue';

import { BadgeShape, BadgeVariant } from '@/hooks/useBadgeClasses';
import { useZindex } from '@/hooks/useZindex';

import Badge from '@/components/badge/Badge.vue';
import PaddedCellContent from '@/components/table/PaddedCellContent.vue';

import AngleDown from '@/icons/line/angle-down.svg';
import SelectBoxViewport from '@/components/selectBox/SelectBoxViewport.vue';
import SelectBoxItem from '@/components/selectBox/SelectBoxItem.vue';
import { Selectable } from '@/components/selectBox/selectBox';

import { computed, ref, watch } from 'vue';

export type TableBadgesCellProps = {
  options: EnumData[];
  modelValue: Selectable<string>;
  itemIcon?: Component;
};

const props = defineProps<TableBadgesCellProps>();

const emit = defineEmits<{
  'update:modelValue': [value: string];
}>();

const { nextIndex } = useZindex();
const zIndex = nextIndex();

const localLabel = ref(props.modelValue.label);

watch(
  () => props.modelValue,
  (newValue) => {
    localLabel.value = newValue.label;
  }
);

const localValue = computed({
  get: () => props.modelValue.value,
  set: (value) => emit('update:modelValue', value)
});

function handleValueChange(value: string) {
  const selectedOption = props.options.find((opt) => opt.value === value);
  localLabel.value = selectedOption?.label ?? '';
  emit('update:modelValue', value);
}
</script>

<template>
  <PaddedCellContent @click.stop>
    <SelectRoot @update:modelValue="handleValueChange" :modelValue="localValue">
      <SelectTrigger
        class="rounded-[5px] focus-within:border-slate-400 focus-within:ring-3 focus:outline-none focus:ring-3 focus:ring-slate-700/40 data-[state=open]:ring-3 data-[state=open]:ring-slate-700/40"
      >
        <Badge
          :iconLeft="itemIcon"
          :iconRight="AngleDown"
          :label="localLabel"
          :shape="BadgeShape.rounded"
          :variant="BadgeVariant.soft"
          class="cursor-pointer truncate"
        />
      </SelectTrigger>
      <SelectPortal>
        <SelectContent
          align="start"
          :sideOffset="4"
          position="popper"
          class="w-[--radix-combobox-trigger-width] rounded-lg border border-slate-200 bg-white drop-shadow-md"
          :style="{ zIndex }"
        >
          <SelectBoxViewport :as="SelectViewport">
            <SelectBoxItem
              :as="SelectItem"
              v-for="option in options"
              :class="{
                'ml-4': option.header
              }"
              :key="option.label"
              :value="option.value"
              :disabled="option?.disabled ?? false"
            >
              <SelectItemText>
                <div class="font-semibold leading-5 text-slate-900">{{ option.label }}</div>
                <div v-if="option.description" class="leading-5 text-slate-500">
                  {{ option.description }}
                </div>
              </SelectItemText>
            </SelectBoxItem>
          </SelectBoxViewport>
        </SelectContent>
      </SelectPortal>
    </SelectRoot>
  </PaddedCellContent>
</template>
