<script setup lang="ts">
type EnumData = App.Base.Data.EnumData;

import type { Selectable } from '@/components/selectBox/selectBox';
import type { TableSelectionState } from '@/hooks/useHybridlyTable';

import pluralize from 'pluralize';

import Alert from '@/components/alert/Alert.vue';
import Dialog from '@/components/dialog/Dialog.vue';
import Fieldset from '@/components/fieldset/Fieldset.vue';
import FormRows from '@/components/formRows/FormRows.vue';
import FormMultiComboboxInput from '@/components/formComboboxInput/FormMultiComboboxInput.vue';
import FormError from '@/components/formError/FormError.vue';

const props = defineProps<{
  postSecondaryPlans: EnumData[];
  isOpen: boolean;
  selectedStudentsCount: number;
  tableSelectionState: TableSelectionState;
}>();

const emit = defineEmits<{
  onCancel: [void];
  onClose: [void];
}>();

const queryParameters = useQueryParameters();

const form = useForm({
  method: 'POST',
  url: route('students.bulk-update.post-secondary-plans'),
  fields: {
    ids: props.tableSelectionState.selectedRowIds || [],
    filters: queryParameters.filters || null,
    all: props.tableSelectionState.areAllRecordsSelected,
    post_secondary_plans: [] as Selectable<string>[]
  },
  transform: (fields) => ({
    ...fields,
    ids: props.tableSelectionState.selectedRowIds || [],
    filters: queryParameters.filters || null,
    all: props.tableSelectionState.areAllRecordsSelected,
    post_secondary_plans:
      fields.post_secondary_plans?.map((postSecondaryPlan) => postSecondaryPlan.value) ?? []
  }),
  hooks: {
    success() {
      props.tableSelectionState.reset();
      emit('onClose');
    }
  }
});

function handleConfirm() {
  form.submit();
}
</script>

<template>
  <Dialog
    :isOpen="isOpen"
    :isLoading="form.processing"
    confirmButtonLabel="Change status"
    description="ådd the same post-secondary plan for all selected students"
    title="Add Post-Secondary Plan"
    @onConfirm="handleConfirm"
    @onClose="emit('onClose')"
    @onCancel="emit('onCancel')"
  >
    <FormRows>
      <Fieldset title="Change to">
        <div class="flex flex-col gap-y-1.5">
          <FormMultiComboboxInput
            label="Post-secondary plan"
            :options="postSecondaryPlans"
            :modelValue="form.fields.post_secondary_plans"
            @update:modelValue="(value) => (form.fields.post_secondary_plans = value)"
          />
          <div v-if="form.errors" class="flex flex-col gap-y-1">
            <FormError v-for="(error, index) in form.errors" :key="index">{{ error }}</FormError>
          </div>
        </div>
        <Alert title="Selected Students" variant="soft">
          You are updating the post-secondary plans for
          <span class="font-bold">{{ selectedStudentsCount }}</span> selected
          {{ pluralize('student', selectedStudentsCount) }}.
        </Alert>
      </Fieldset>
    </FormRows>
  </Dialog>
</template>
