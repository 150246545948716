<script setup lang="ts">
type ProgramStudentFieldData = App.Configuration.Data.ProgramStudentFieldData;
type StudentData = App.Students.Data.StudentData;

import { useAuth } from '@/hooks/useAuth';

import { filterBoolean } from '@/utils/filterBoolean';

import CenteredContent from '@/components/content/CenteredContent.vue';
import Fieldset from '@/components/fieldset/Fieldset.vue';
import StudentFormWrapper from '@/components/students/StudentFormWrapper.vue';
import CustomField from '@/components/customFields/CustomField.vue';
import EmptyState from '@/components/emptyState/EmptyState.vue';

const props = defineProps<{
  programStudentFields: ProgramStudentFieldData[];
  student: StudentData;
  returnUrl?: string;
}>();

const { hasPermission } = useAuth();

function transformForm(fields) {
  const transformedFields = {};
  for (const key in fields) {
    const value = fields[key];
    if (
      typeof value === 'object' &&
      value.hasOwnProperty('label') &&
      value.hasOwnProperty('value')
    ) {
      transformedFields[key] = value.value;
    } else {
      transformedFields[key] = value;
    }
  }
  return transformedFields;
}

const form = useForm({
  method: 'PUT',
  url: route('students.update.custom-fields', { student: props.student.id }),
  updateInitials: true,
  fields: props.programStudentFields.reduce((fields, customField) => {
    if (customField.type?.value === 'select') {
      fields[customField.field_key] = {
        label: props.student.meta[customField.field_key],
        value: props.student.meta[customField.field_key]
      };
    } else if (customField.type?.value === 'boolean') {
      fields[customField.field_key] = filterBoolean(props.student.meta[customField.field_key]);
    } else {
      fields[customField.field_key] = props.student.meta[customField.field_key];
    }
    return fields;
  }, {}),
  transform: (fields) => ({
    ...transformForm(fields)
  })
});
</script>


					<script lang="ts">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <CenteredContent>
    <StudentFormWrapper
      isEditing
      :form="form"
      :hideControls="!programStudentFields.length"
      :isDirty="form.isDirty"
      :isProcessing="form.processing"
      :returnUrl
      :studentId="student.id"
      @onSubmit="form.submit"
    >
      <Fieldset v-if="programStudentFields.length" title="Custom Field Group Name">
        <CustomField
          v-for="customField in programStudentFields"
          :key="customField.id"
          :customField
          :error="form.errors[customField.field_key]"
          :modelValue="form.fields[customField.field_key]"
          @update:modelValue="(value) => (form.fields[customField.field_key] = value)"
        />
      </Fieldset>
      <EmptyState
        v-else
        title="No custom fields found"
        :description="
          hasPermission('create-program-student-field')
            ? 'Create a new custom field by clicking the button below'
            : 'Custom fields will appear on this screen'
        "
        :actionLabel="
          hasPermission('create-program-student-field') ? 'Add a custom field' : undefined
        "
        :actionHref="
          hasPermission('create-program-student-field')
            ? route('program-student-fields.create')
            : undefined
        "
      />
    </StudentFormWrapper>
  </CenteredContent>
</template>
