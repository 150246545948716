<script setup lang="ts">
import CenteredContent from '@/components/content/CenteredContent.vue';
import ApCourseForm from '@/components/apCourses/ApCourseForm.vue';

const props = defineProps<{
  apCourse: App.Configuration.Data.ApCourseData;
  returnUrl?: string;
}>();

const form = useForm({
  method: 'PUT',
  url: route('ap-courses.update', { apCourse: props.apCourse.id }),
  fields: {
    display_name: props.apCourse.display_name
  }
});
</script>


					<script lang="ts">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <CenteredContent>
    <ApCourseForm isEditing :form="form" :returnUrl="returnUrl" />
  </CenteredContent>
</template>
