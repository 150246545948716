<script setup lang="ts" generic="T extends SelectableValue">
import type { Selectable, SelectableValue } from '@/components/selectBox/selectBox';

import { useStudentsSearch } from '@/hooks/search/useStudentsSearch';

import FormMultiComboboxInput, {
  FormMultiComboboxProps
} from '@/components/formComboboxInput/FormMultiComboboxInput.vue';

type Props = Omit<FormMultiComboboxProps<number>, 'options' | 'searchable'> & {
  schoolIds?: number[];
};

const props = defineProps<Props>();

defineEmits<{
  'update:modelValue': [Selectable<number>[]];
}>();

const { studentOptions, isLoading, onQueryChange, updateSearchFilters } = useStudentsSearch(
  props.searchFilters
);

watch(
  () => props.schoolIds,
  (schoolIds) => {
    updateSearchFilters({ school_ids: schoolIds });
  },
  { immediate: true }
);
</script>

<template>
  <FormMultiComboboxInput
    v-bind="$props"
    :isLoading="isLoading"
    :options="studentOptions"
    :onFilter="onQueryChange"
    @update:modelValue="(value) => $emit('update:modelValue', value)"
    @open="() => onQueryChange('')"
  />
</template>
