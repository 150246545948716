<script setup lang="ts" generic="T extends SelectableValue">
import FormMultiComboboxInput, {
  FormMultiComboboxProps
} from '@/components/formComboboxInput/FormMultiComboboxInput.vue';
import { Selectable, SelectableValue } from '@/components/selectBox/selectBox';
import { useUsersSearch } from '@/hooks/search/useUsersSearch';

type Props = Omit<FormMultiComboboxProps<number>, 'options' | 'searchable'> & {
  programIds?: number[];
};

const props = defineProps<Props>();

defineEmits<{
  'update:modelValue': [Selectable<number>[]];
}>();

const { userOptions, isLoading, onQueryChange, updateSearchFilters } = useUsersSearch(
  props.searchFilters
);

watch(
  () => props.programIds,
  (programIds) => {
    updateSearchFilters({ program_ids: programIds });
  }
);
</script>

<template>
  <FormMultiComboboxInput
    v-bind="$props"
    :isLoading="isLoading"
    :options="userOptions"
    :selectAll="selectAll"
    :onFilter="onQueryChange"
    @update:modelValue="(value) => $emit('update:modelValue', value)"
    @open="() => onQueryChange('')"
  />
</template>
