<script setup lang="ts">
import type { DualListboxOption } from '@/components/selectBox/selectBox';
import IconButton from '@/components/button/IconButton.vue';
import { ButtonSize, ButtonVariant } from '@/hooks/useButtonClasses';
import DualListboxOptionList from './DualListboxOptionList.vue';

import AngleDoubleRightIcon from '@/icons/line/angle-double-right.svg';
import AngleDoubleLeftIcon from '@/icons/line/angle-double-left.svg';

const props = defineProps<{
  selectedValues: string[]; // Values that are in the right column, left column is computed
  options: DualListboxOption[];
}>();

const emit = defineEmits<{
  'update:selectedValues': [Set<string>];
}>();

const availableList = ref();
const selectedList = ref();

type GroupedValues = Record<'available' | 'selected', Set<string>>;

export type DualListboxContext = {
  handleMoveCheckedToSelected: (arr: Set<string>) => void;
  handleMoveCheckedToAvailable: (arr: Set<string>) => void;
};

const groupedValues = computed(() => {
  function walkOptions(option: DualListboxOption, groups: GroupedValues) {
    if (option.children) {
      option.children.forEach((childOption) => walkOptions(childOption, groups));
      return;
    }

    if (props.selectedValues.includes(option.value)) {
      groups.selected.add(option.value);
    } else {
      groups.available.add(option.value);
    }
  }

  return props.options.reduce(
    (groups: GroupedValues, option: DualListboxOption) => {
      walkOptions(option, groups);
      return groups;
    },
    {
      available: new Set(),
      selected: new Set()
    } as GroupedValues
  );
});

function handleMoveCheckedToSelected(values) {
  handleSelectedValuesUpdate([...props.selectedValues, ...values]);
}

function handleMoveCheckedToAvailable(values) {
  handleSelectedValuesUpdate([...props.selectedValues.filter((value) => !values.has(value))]);
}

function handleMoveAllToSelected() {
  const flattenedOptions = flattenNestedList(props.options).map((opt) => opt.value);
  handleSelectedValuesUpdate(flattenedOptions);
}

function handleMoveAllToAvailable() {
  handleSelectedValuesUpdate([]);
}

function handleSelectedValuesUpdate(values: string[]) {
  emit('update:selectedValues', new Set(values));

  availableList.value.checkedValues.clear();
  selectedList.value.checkedValues.clear();
}

provide('dual-listbox', {
  handleMoveCheckedToSelected,
  handleMoveCheckedToAvailable
});
</script>

<template>
  <div class="flex min-h-72 gap-8">
    <DualListboxOptionList
      ref="availableList"
      :valuesInList="groupedValues.available"
      :options="options"
      :isAvailableList="true"
      :optionsCount="groupedValues.available.size"
    />

    <div class="flex flex-col items-center justify-center gap-2">
      <IconButton
        :icon="AngleDoubleRightIcon"
        :size="ButtonSize.sm"
        :variant="ButtonVariant.outlined"
        :isDisabled="groupedValues.available.size === 0"
        :tooltipProps="{ side: 'right', showArrow: true }"
        ariaLabel="Move all options to the Chosen column"
        @click="handleMoveAllToSelected"
      />

      <IconButton
        :icon="AngleDoubleLeftIcon"
        :size="ButtonSize.sm"
        :variant="ButtonVariant.outlined"
        :tooltipProps="{ side: 'right', showArrow: true }"
        :isDisabled="groupedValues.selected.size === 0"
        ariaLabel="Move column to chosen"
        @click="handleMoveAllToAvailable"
      />
    </div>

    <DualListboxOptionList
      ref="selectedList"
      :valuesInList="groupedValues.selected"
      :options="options"
      :isAvailableList="false"
      :optionsCount="groupedValues.selected.size"
    />
  </div>
</template>
