<script setup lang="ts">
import type { Selectable } from '@/components/selectBox/selectBox';
import type { SurveyAnswerChoiceType } from '@/components/surveys/sessoins/SurveyBlock.vue';

import { RadioGroupRoot } from 'radix-vue';

import SurveyBlockRadio from '@/components/surveys/sessoins/SurveyBlockRadio.vue';
import FormInstituteInput from '@/components/formComboboxInput/FormInstituteInput.vue';
import FormError from '@/components/formError/FormError.vue';

const props = defineProps<{
  block: App.Surveys.Data.SurveyBlockData;
  choices?: SurveyAnswerChoiceType[];
  errors?: Record<string, string>;
  value?: string;
}>();

const emit = defineEmits<{
  'update:modelValue': [value: SurveyAnswerChoiceType | null];
}>();

const isInstituteBlock = computed(() => props.block.choice_type?.value === 'institute');
const computedValue = computed(() => {
  const choice = props.choices?.[0];
  if (!choice) {
    return null;
  }
  return choice.survey_choice_id ?? choice.answer_id;
});

const selectedInstituteOption = ref<Selectable<number> | null>(
  props.choices?.[0]?.answer_option as Selectable<number> | null
);

function handleValueUpdate(value: string | number) {
  const choice = props.block.choices?.find((option) => option.id.toString() === value) ?? null;

  const updateObject = props.block.dynamic_choice_type
    ? {
        answer_type: props.block.dynamic_choice_type?.value,
        answer_id: choice?.choice_value
      }
    : {
        survey_choice_id: choice?.id as number
      };

  emit('update:modelValue', updateObject);
}

function handleInstituteValueUpdate(value: Selectable<number> | null) {
  selectedInstituteOption.value = value;
  emit('update:modelValue', {
    answer_id: value?.value,
    answer_type: props.block.dynamic_choice_type?.value
  });
}
</script>

<template>
  <div class="flex w-full flex-col gap-1.5">
    <FormInstituteInput
      v-if="isInstituteBlock"
      :modelValue="selectedInstituteOption"
      @update:modelValue="(value) => handleInstituteValueUpdate(value)"
    />

    <RadioGroupRoot
      class="flex flex-col gap-1"
      :ariaLabel="block.block_text"
      :modelValue="computedValue?.toString()"
      @update:modelValue="(value) => handleValueUpdate(value)"
    >
      <SurveyBlockRadio
        v-for="option in block.choices"
        :key="option.id"
        :label="option.choice_text"
        :value="option.id.toString()"
      />
    </RadioGroupRoot>
    <FormError v-if="errors?.choices">{{ errors.choices }}</FormError>
  </div>
</template>
