<script setup lang="ts">
type EnumData = App.Base.Data.EnumData;

import type { HybridlyFormData } from '@/@types/global';
import type { Selectable } from '@/components/selectBox/selectBox';

import { ButtonColor, ButtonSize, ButtonVariant } from '@/hooks/useButtonClasses';

import Button from '@/components/button/Button.vue';
import Fieldset from '@/components/fieldset/Fieldset.vue';
import FormDateInput from '@/components/formDateInput/FormDateInput.vue';
import FormField from '@/components/formField/FormField.vue';
import FormInlineFields from '@/components/formInlineFields/FormInlineFields.vue';
import FormInstituteInput from '@/components/formComboboxInput/FormInstituteInput.vue';
import FormListboxInput from '@/components/formListboxInput/FormListboxInput.vue';
import FormMultiComboboxInput from '@/components/formComboboxInput/FormMultiComboboxInput.vue';
import FormMultiInstituteInput from '@/components/formComboboxInput/FormMultiInstituteInput.vue';
import FormMultiScholarshipInput from '@/components/formComboboxInput/FormMultiScholarshipInput.vue';
import FormTextareaInput from '@/components/formTextareaInput/FormTextareaInput.vue';
import IconButton from '@/components/button/IconButton.vue';

import PlusCircle from '@/icons/line/plus-circle.svg';
import Trash from '@/icons/line/trash.svg';
import FormError from '@/components/formError/FormError.vue';
import FormCustomTestInput from '@/components/formComboboxInput/FormCustomTestInput.vue';

const props = defineProps<{
  form: HybridlyFormData<{
    notes: string;
    topics: EnumData[];
    application_assisted_institute_ids: number[];
    award_letter_review_institute_ids: number[];
    scholarship_names: string[];
    tests: [];
    types: EnumData[];
    institute_id: number;
    meeting_length: EnumData[];
    duration: EnumData[];
    date: string;
  }>;
  mediums: EnumData[];
  meetingLengths: EnumData[];
  student?: App.Students.Data.StudentData;
  testTypes: EnumData[];
  topics: EnumData[];
  types: EnumData[];
}>();

const isTopicCollegeApplicationAssistance = computed<boolean>(() => {
  return props.form.fields.topics.some(
    (topic: EnumData) => topic.value === 'college-application-assistance'
  );
});

const isTopicAwardLetterReview = computed<boolean>(() => {
  const topics = [
    'comparing-financial-aid-award-letters',
    'award-letter-review',
    'tuition-and-billing'
  ];
  return props.form.fields.topics.some((topic: EnumData) => topics.includes(topic.value));
});

const isTopicScholarshipAssistance = computed<boolean>(() => {
  return props.form.fields.topics.some(
    (topic: EnumData) => topic.value === 'scholarship-assistance'
  );
});

const isTopicExamRegistrationAssistance = computed<boolean>(() => {
  return props.form.fields.topics.some(
    (topic: EnumData) => topic.value === 'exam-registration-assistance'
  );
});

const isTypeCampusOrCollegeRepVisit = computed<boolean>(() => {
  return props.form.fields.types.some(
    (type: EnumData) => type.value === 'campus-visit' || type.value === 'college-rep-visit'
  );
});

const computedTestTypes = computed<EnumData[]>(() => [
  ...props.testTypes,
  {
    value: 'custom-test',
    label: 'Custom test',
    description: null
  }
]);

onMounted(() => {
  handleTopicChange(props.form.fields.topics);
});

function addTest() {
  props.form.fields.tests.push({
    type: null,
    date: null,
    test: null
  });
}

function removeTest(index: number) {
  props.form.fields.tests.splice(index, 1);

  if (!props.form.fields.tests.length) {
    props.form.fields.tests = props.form.initial.tests;
  }
}

function handleTopicChange(topics: Selectable<string>[]) {
  const selectedTopicValues = topics.map((topic) => topic.value);

  const topicFieldMappings = [
    {
      topicValues: ['college-application-assistance'],
      fieldKey: 'application_assisted_institute_ids'
    },
    {
      topicValues: [
        'award-letter-review',
        'comparing-financial-aid-award-letters',
        'tuition-and-billing'
      ],
      fieldKey: 'award_letter_review_institute_ids'
    },
    {
      topicValues: ['scholarship-assistance'],
      fieldKey: 'scholarship_names'
    },
    {
      topicValues: ['exam-registration-assistance'],
      fieldKey: 'tests',
      afterSet: () => {
        if (!props.form.fields.tests.length) {
          addTest();
        }
      }
    }
  ];

  topicFieldMappings.forEach(({ topicValues, fieldKey, afterSet }) => {
    const isSelected = topicValues.some((topicValue) => selectedTopicValues.includes(topicValue));
    const field = props.form.fields[fieldKey];

    if (isSelected) {
      if (!field || !field.length) {
        props.form.fields[fieldKey] = props.form.initial[fieldKey] || [];

        if (afterSet) {
          afterSet();
        }
      }
    } else {
      props.form.fields[fieldKey] = null;
    }
  });

  props.form.fields.topics = topics;
}

function handleTypeChange(types: Selectable<string>[]) {
  const selectedTypeValues = types.map((type) => type.value);

  const requiresInstituteId =
    selectedTypeValues.includes('campus-visit') || selectedTypeValues.includes('college-rep-visit');

  if (requiresInstituteId) {
    if (props.form.fields.institute_id === null || props.form.fields.institute_id === undefined) {
      props.form.fields.institute_id = props.form.initial.institute_id ?? null;
    }
  } else {
    props.form.fields.institute_id = null;
  }

  props.form.fields.types = types;
}
</script>

<template>
  <div class="divide-y-slate-200 flex flex-col gap-y-8">
    <Fieldset title="Interaction general information">
      <FormTextareaInput
        :error="form.errors.notes"
        :modelValue="form.fields.notes"
        label="Notes"
        name="notes"
        @update:modelValue="(value) => (form.fields.notes = value)"
      />
    </Fieldset>
    <Fieldset title="Interaction topics">
      <FormMultiComboboxInput
        :error="form.errors.topics"
        :modelValue="form.fields.topics"
        :options="topics"
        label="Topics"
        placeholder="Select interaction topics"
        @update:modelValue="handleTopicChange($event)"
      />
      <FormMultiInstituteInput
        v-if="isTopicCollegeApplicationAssistance"
        :error="form.errors.application_assisted_institute_ids"
        :modelValue="form.fields.application_assisted_institute_ids ?? []"
        label="Assisted applications for these institutions"
        @update:modelValue="(value) => (form.fields.application_assisted_institute_ids = value)"
      />
      <FormMultiInstituteInput
        v-if="isTopicAwardLetterReview"
        :error="form.errors.award_letter_review_institute_ids"
        :modelValue="form.fields.award_letter_review_institute_ids ?? []"
        label="Reviewed award letters for these institutions"
        @update:modelValue="(value) => (form.fields.award_letter_review_institute_ids = value)"
      />
      <FormMultiScholarshipInput
        v-if="isTopicScholarshipAssistance"
        :error="form.errors.scholarship_names"
        :modelValue="form.fields.scholarship_names ?? []"
        label="Scholarships that were assisted"
        @update:modelValue="(value) => (form.fields.scholarship_names = value)"
      />
      <FormField v-if="isTopicExamRegistrationAssistance" label="Exams that were assisted">
        <div
          class="flex items-center gap-2"
          v-for="(test, index) in props.form.fields.tests"
          :key="index"
        >
          <div class="flex grow flex-col gap-4 rounded-lg bg-slate-50 p-4 md:flex-row">
            <div class="flex w-full flex-col gap-3">
              <FormListboxInput
                label="Exam type"
                :error="form.errors.tests?.[index]?.type"
                :options="computedTestTypes"
                :modelValue="test.type"
                @update:modelValue="(value) => (test.type = value)"
              />
              <FormCustomTestInput
                v-if="test.type?.value === 'custom-test'"
                :error="form.errors.tests?.[index].test"
                :modelValue="test.test"
                :programIds="student?.school?.program?.id ? [student.school.program.id] : []"
                label="Test"
                @update:modelValue="(value) => (test.test = value)"
              />
            </div>
            <div class="flex w-full flex-col gap-3">
              <FormDateInput
                :error="form.errors.tests?.[index]?.date"
                :modelValue="test.date"
                autoPosition
                label="Date"
                name="date"
                format="MMM dd, yyyy"
                :clearable="false"
                @update:modelValue="(value) => (test.date = value)"
              />
            </div>
          </div>
          <IconButton
            :isDisabled="index === 0"
            :icon="Trash"
            ariaLabel="Remove exam"
            color="danger"
            variant="invisible"
            @click="removeTest(index)"
          />
        </div>
        <FormError v-if="typeof form.errors.tests !== 'object'">{{ form.errors.tests }}</FormError>
        <Button
          :color="ButtonColor.slate"
          :iconLeft="PlusCircle"
          :size="ButtonSize.xs"
          :variant="ButtonVariant.invisible"
          @click="addTest"
        >
          Add a test
        </Button>
      </FormField>
    </Fieldset>
    <Fieldset title="Interaction types">
      <FormMultiComboboxInput
        :error="form.errors.types"
        :modelValue="form.fields.types"
        :options="types"
        label="Types"
        placeholder="Select interaction types"
        @update:modelValue="handleTypeChange($event)"
      />
      <FormInstituteInput
        v-if="isTypeCampusOrCollegeRepVisit"
        :error="form.errors.institute_id"
        :modelValue="form.fields.institute_id"
        label="Institution that was visited"
        @update:modelValue="(value) => (form.fields.institute_id = value)"
      />
    </Fieldset>
    <Fieldset title="Interaction details">
      <FormInlineFields>
        <FormListboxInput
          :error="form.errors.medium"
          :modelValue="form.fields.medium"
          :options="mediums"
          label="Medium"
          @update:modelValue="(value) => (form.fields.medium = value)"
        />
        <FormListboxInput
          :error="form.errors.meeting_length"
          :modelValue="form.fields.meeting_length"
          :options="meetingLengths"
          label="Duration"
          @update:modelValue="(value) => (form.fields.meeting_length = value)"
        />
      </FormInlineFields>
      <FormDateInput
        :error="form.errors.date"
        :modelValue="form.fields.date"
        autoPosition
        label="Date"
        name="date"
        format="MMM dd, yyyy"
        :clearable="false"
        @update:modelValue="(value) => (form.fields.date = value)"
      />
    </Fieldset>
  </div>
</template>
