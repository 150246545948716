<script setup lang="ts">
type EnumData = App.Base.Data.EnumData;

import type { HybridlyFormData } from '@/@types/global';

import { ButtonColor, ButtonVariant } from '@/hooks/useButtonClasses';

import Alert from '@/components/alert/Alert.vue';
import Button from '@/components/button/Button.vue';
import DangerDialog from '@/components/dialog/DangerDialog.vue';
import Fieldset from '@/components/fieldset/Fieldset.vue';
import Form from '@/components/form/Form.vue';
import FormCheckboxInput from '@/components/formCheckboxInput/FormCheckboxInput.vue';
import FormDateInput from '@/components/formDateInput/FormDateInput.vue';
import FormFooter from '@/components/formFooter/FormFooter.vue';
import FormHeader from '@/components/formHeader/FormHeader.vue';
import FormMultiUserInput from '@/components/formComboboxInput/FormMultiUserInput.vue';
import FormRegionInput from '@/components/formComboboxInput/FormRegionInput.vue';
import FormTextareaInput from '@/components/formTextareaInput/FormTextareaInput.vue';
import FormTextInput from '@/components/formTextInput/FormTextInput.vue';

import ExclamationTriangle from '@/icons/line/exclamation-triangle.svg';

const props = defineProps<{
  isEditing?: boolean;
  form: HybridlyFormData<{
    display_name: string | null;
    description: string | null;
    is_kpi_national_excluded: boolean | false;
    grade_up_date: string;
    region_id: EnumData | null;
    user_ids: number[];
  }>;
  returnUrl?: string;
}>();

const isCancelDialogOpen = ref(false);

const { onBackForward } = useBackForward();

// TODO: Fix intecepting the back button
onBackForward(() => {
  router.abort();
});

function handleToggleCancelDialog() {
  if (props.form.isDirty) {
    isCancelDialogOpen.value = !isCancelDialogOpen.value;
    return;
  }
  handleRedirect();
}

function handleRedirect() {
  router.navigate({ url: props.returnUrl || route('programs.index') });
}
</script>

<template>
  <Form @submit.prevent="form.submit">
    <template #header>
      <FormHeader
        canClose
        :title="`${isEditing ? 'Edit' : 'Add'} a Program`"
        :description="
          isEditing
            ? 'Use the form below to edit the program.'
            : 'Fill out the form below add a new program.'
        "
        @onClose="handleToggleCancelDialog"
      />
    </template>
    <Fieldset title="Program information">
      <FormTextInput
        :error="form.errors.display_name"
        :modelValue="form.fields.display_name"
        label="Name"
        name="display_name"
        placeholder="e.g. GSU"
        type="text"
        @update:modelValue="(value) => (form.fields.display_name = value)"
      />
      <FormTextareaInput
        :error="form.errors.description"
        :modelValue="form.fields.description"
        label="Description"
        name="description"
        placeholder="Description"
        @update:modelValue="(value) => (form.fields.description = value)"
      />
      <FormRegionInput
        :error="form.errors.region_id"
        :isDisabled="isEditing"
        :modelValue="form.fields.region_id"
        label="Region"
        @update:modelValue="(value) => (form.fields.region_id = value)"
      />
      <FormMultiUserInput
        label="Program Directors and Assistant Program Directors"
        :error="form.errors.user_ids"
        :modelValue="form.fields.user_ids"
        :searchFilters="{ role: 'program-director' }"
        @update:modelValue="(value) => (form.fields.user_ids = value)"
      />
    </Fieldset>
    <Fieldset title="Grade up">
      <FormDateInput
        :error="form.errors.grade_up_date"
        :modelValue="form.fields.grade_up_date"
        helperText="You can setup a Grade Up date to move all of the students within the program move up automatically."
        label="Grade Up Date"
        name="grade_up_date"
        placeholder="Grade Up Date"
        format="MMM dd, yyyy"
        :clearable="false"
        @update:modelValue="(value) => (form.fields.grade_up_date = value)"
      />
      <Alert
        :icon="ExclamationTriangle"
        description="Setting this date will cause the Grade Up process to run on the specified date. Grade Up is irreversible."
        title="Important note"
        variant="soft"
      />
    </Fieldset>
    <Fieldset title="Other settings">
      <FormCheckboxInput
        :checked="form.fields.is_kpi_national_excluded"
        :error="form.errors.is_kpi_national_excluded"
        label="Exclude from National KPI Total?"
        name="is_kpi_national_excluded"
        @update:checked="(value) => (form.fields.is_kpi_national_excluded = value)"
      />
    </Fieldset>
    <template #footer>
      <FormFooter>
        <Button
          :color="ButtonColor.primary"
          :isDisabled="form.processing || !form.isDirty"
          :isLoading="form.processing"
          :variant="ButtonVariant.solid"
          type="submit"
          >Confirm</Button
        >
        <Button
          @click="handleToggleCancelDialog"
          :color="ButtonColor.slate"
          :variant="ButtonVariant.outlined"
          type="button"
        >
          Back to programs
        </Button>
      </FormFooter>
    </template>
  </Form>
  <DangerDialog
    :title="`Cancel ${isEditing ? 'editing' : 'adding a new'} program`"
    :message="`Are you sure you want to cancel ${
      isEditing ? 'editing' : 'adding'
    } this program? All ${isEditing ? 'changes' : 'information'} will be lost.`"
    :isOpen="isCancelDialogOpen"
    cancelButtonLabel="Stay on this page"
    confirmButtonLabel="Go back to programs"
    @onCancel="handleToggleCancelDialog"
    @onClose="handleToggleCancelDialog"
    @onConfirm="handleRedirect"
  />
</template>
