<script setup lang="ts">
import { RouterLink } from 'hybridly/vue';
import { TransitionChild, TransitionRoot } from '@headlessui/vue';

import { ButtonColor } from '@/hooks/useButtonClasses';
import { useGlobalSearch } from '@/hooks/search/useGlobalSearch';
import { useZindex } from '@/hooks/useZindex';
import { onKeyStroke } from '@vueuse/core';

import { Selectable } from '@/components/selectBox/selectBox';
import IconButton from '@/components/button/IconButton.vue';
import Loading from '@/components/loading/Loading.vue';
import SearchInput from '@/components/searchInput/SearchInput.vue';

import SearchIcon from '@/icons/line/search.svg';
import TimesIcon from '@/icons/line/times.svg';

const { nextIndex } = useZindex();
const zIndex = nextIndex();

const searchInput = ref<typeof SearchInput | null>(null);
const searchQuery = ref('');
const isOpen = ref(false);
const activeIndex = ref(0);
const searchOptionsRef = ref<(HTMLElement | null)[]>([]);

const { globalSearchOptions, isLoading, onQueryChange } = useGlobalSearch();

function handleSearchInputKeyup(evt: KeyboardEvent) {
  if (evt.key === 'ArrowDown') {
    activeIndex.value = Math.min(
      activeIndex.value + 1,
      (globalSearchOptions.value?.length ?? 0) - 1
    );
  }
  if (evt.key === 'ArrowUp') {
    activeIndex.value = Math.max(activeIndex.value - 1, 0);
  }
  if (evt.key === 'Enter') {
    handleOptionClick(globalSearchOptions.value[activeIndex.value]);
  }
}

onKeyStroke('Escape', () => {
  if (isOpen.value) {
    handleCloseGlobalSearch();
  }
});

onKeyStroke(
  'k',
  (e) => {
    if (e.metaKey) {
      e.preventDefault();

      toggleGlobalSearch();
    }
  },
  { target: document }
);

function focusSearchInput() {
  if (searchInput.value && searchInput.value.inputEl) {
    searchInput.value.inputEl.inputEl.focus();
  }
}

function toggleGlobalSearch() {
  isOpen.value = !isOpen.value;
}

function resetSearchQuery() {
  searchQuery.value = '';
  onQueryChange('');
}

function handleCloseGlobalSearch() {
  isOpen.value = false;
}

function handleOptionClick(option: Selectable<number>) {
  isOpen.value = false;
  resetSearchQuery();

  router.navigate({
    url: route('students.show', { student: option.value })
  });
}

watch(globalSearchOptions, () => {
  activeIndex.value = 0;
});

watch(isOpen, (newValue) => {
  if (newValue) {
    resetSearchQuery();
    activeIndex.value = 0;
    nextTick(() => focusSearchInput());
  }
});

watch(searchQuery, (newQuery) => {
  onQueryChange(newQuery);
});

watch(activeIndex, (newIndex) => {
  searchOptionsRef.value[newIndex]?.scrollIntoView({
    block: 'end'
  });
});
</script>

<template>
  <IconButton
    :icon="SearchIcon"
    ariaLabel="Search students"
    size="md"
    variant="soft"
    :color="ButtonColor.slate"
    @click="toggleGlobalSearch"
  />

  <teleport to="body">
    <TransitionRoot
      appear
      :show="isOpen"
      :data-state="isOpen ? 'open' : 'closed'"
      ariaLive="assertive"
      class="isolate"
    >
      <TransitionChild
        as="template"
        enter="ease-in-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in-out duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div
          class="fixed inset-0 bg-black/70"
          @click="handleCloseGlobalSearch"
          :style="{ zIndex }"
        />
      </TransitionChild>
      <div
        class="pointer-events-none fixed inset-0 flex flex-col items-center justify-start px-4"
        :style="{ zIndex }"
      >
        <TransitionChild
          as="template"
          enter="transform transition ease-in-out duration-500"
          enterFrom="opacity-0 -translate-y-4"
          enterTo="opacity-100 translate-y-0"
          leave="transform transition ease-in-out duration-300"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 -translate-y-4"
        >
          <div
            class="divide-y-slate-200 pointer-events-auto mt-6 flex w-full max-w-lg flex-col divide-y rounded-lg border border-slate-200 bg-white md:mt-12 lg:mt-24"
          >
            <div class="flex items-center gap-2 p-4">
              <SearchInput
                v-model="searchQuery"
                ref="searchInput"
                class="flex-1"
                @blur="activeIndex = -1"
                @focus="activeIndex = 0"
                @keyup="handleSearchInputKeyup"
              />
              <IconButton
                :icon="TimesIcon"
                @click.stop="handleCloseGlobalSearch"
                ariaLabel="Close search"
                size="sm"
                variant="outlined"
              />
            </div>
            <div class="h-full max-h-80 rounded-b-lg bg-slate-50">
              <div v-if="isLoading" class="flex flex-col py-4">
                <Loading iconColor="primary" title="Loading results..." />
              </div>
              <div v-else class="flex h-full flex-col overflow-hidden">
                <p
                  class="border-b border-slate-200 bg-slate-50 p-4 pb-1 text-sm font-semibold text-slate-900"
                >
                  Students
                </p>
                <ul
                  v-if="globalSearchOptions.length"
                  class="flex flex-col gap-y-1 overflow-y-auto p-4"
                >
                  <li
                    v-for="(option, i) in globalSearchOptions"
                    :key="option.value"
                    ref="searchOptionsRef"
                    class="py-0.5"
                  >
                    <RouterLink
                      @click="handleOptionClick(option)"
                      class="block overflow-hidden rounded-lg border border-slate-200 bg-white px-3 py-2 transition-colors duration-150 ease-in-out hover:bg-slate-50"
                      :class="{ 'ring-2 ring-primary-200': i === activeIndex }"
                      tabIndex="-1"
                    >
                      <p class="text-sm font-medium leading-5 text-slate-900">{{ option.label }}</p>
                      <p
                        class="flex items-center gap-x-1 text-2xs font-normal leading-4 text-slate-500"
                      >
                        {{ option.description }}
                      </p>
                    </RouterLink>
                  </li>
                </ul>
                <div v-else class="flex h-full flex-col items-center justify-center">
                  <div class="px-4 py-6 text-center text-sm text-slate-600">
                    No results found. Try refining your search term or check your spelling.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </TransitionChild>
      </div>
    </TransitionRoot>
  </teleport>
</template>
