export function useAuth() {
  const security = useProperty<App.Auth.Data.SecurityData>('security');

  const permissions = computed(() => security.value?.permissions ?? []);
  const user = computed(() => security.value?.user ?? null);

  function hasPermission(permission: App.Auth.Enums.Permission) {
    return permissions.value.includes(permission);
  }

  function hasAnyPermission(permissions: App.Auth.Enums.Permission[]) {
    return permissions.some((permission) => hasPermission(permission));
  }

  return {
    hasAnyPermission,
    hasPermission,
    permissions,
    user
  };
}
