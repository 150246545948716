<script setup lang="ts">
import CenteredContent from '@/components/content/CenteredContent.vue';
import EditReportTemplateLayout from '@/domains/reports/components/EditReportTemplateLayout.vue';
import ReportAccessForm from '@/domains/reports/components/form/ReportAccessForm.vue';

type ReportTemplateData = App.Reports.Data.ReportTemplateData;

defineProps<{
  template: ReportTemplateData;
}>();
</script>


					<script lang="ts">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <EditReportTemplateLayout :template="template">
    <template #default="{ form }">
      <CenteredContent :padded="false" size="md">
        <ReportAccessForm :customReport="template.report_type" :form="form" />
      </CenteredContent>
    </template>
  </EditReportTemplateLayout>
</template>
