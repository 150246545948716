<script setup lang="ts">
import FormError from '@/components/formError/FormError.vue';
import Label from '@/components/label/Label.vue';
import { uniqueId } from 'lodash';

interface RadioInput {
  error?: string;
  helperText?: string;
  label: string;
  value: string;
}

export type RadioInputProps = {
  ariaLabel: string;
  hasError?: boolean;
  isDisabled?: boolean;
  name: string;
  radioInputs: RadioInput[];
  value?: string;
  direction?: 'horizontal' | 'vertical';
};

const props = withDefaults(defineProps<RadioInputProps>(), {
  direction: 'vertical'
});

const uniqueName = computed(() => uniqueId(props.name));

const emit = defineEmits<{
  'update:modelValue': [value: string];
}>();

function onInput(event: string) {
  emit('update:modelValue', event);
}
</script>

<template>
  <div
    class="flex gap-2.5"
    :class="{
      'flex-col': direction === 'vertical',
      'flex-row': direction === 'horizontal'
    }"
  >
    <label
      v-for="radioInput in radioInputs"
      :key="radioInput.value"
      :disabled="isDisabled"
      :class="[
        'group relative flex cursor-pointer items-center gap-2',
        'disabled:cursor-not-allowed'
      ]"
    >
      <input
        type="radio"
        :aria-label="ariaLabel"
        :name="uniqueName"
        :disabled="isDisabled"
        :class="[
          'h-4.5 w-4.5 cursor-pointer rounded-full border-slate-300 text-white',
          'transition-all duration-150 ease-in-out',
          'checked:border-secondary-800 checked:text-white',
          'focus:ring-3 focus:ring-secondary-200 focus:ring-offset-0 checked:focus:border-secondary-800',
          'group-hover:ring-3 group-hover:ring-secondary-200/50',
          'group-hover:disabled:ring-0',
          hasError && 'errored !border-red-500/50 focus:ring-red-200 group-hover:ring-red-200'
        ]"
        :value="radioInput.value"
        :checked="radioInput.value === value"
        @input="() => onInput(radioInput.value)"
      />

      <div
        class="flex flex-col"
        :class="{
          'opacity-50 group-hover:cursor-not-allowed': isDisabled
        }"
      >
        <Label as="div" class="leading-5">
          {{ radioInput.label }}
        </Label>
        <p v-if="radioInput.helperText" class="select-none text-sm leading-5 text-slate-500">
          {{ radioInput.helperText }}
        </p>
        <FormError v-if="radioInput.error" class="leading-5" hasIcon>
          {{ radioInput.error }}
        </FormError>
      </div>
    </label>
  </div>
</template>

<style scoped>
[type='radio']:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='%230f4499' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='4'/%3e%3c/svg%3e");
}
[type='radio']:checked.errored {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='%23b91c1c' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='4'/%3e%3c/svg%3e");
}
</style>
