<script setup lang="ts">
import IconButton from '@/components/button/IconButton.vue';

import CheckCircle from '@/icons/line/check-circle.svg';
import TimesCircle from '@/icons/line/times-circle.svg';
import Copy from '@/icons/line/copy.svg';

interface CopyProps {
  text: string;
  variant?: 'invisible' | 'input';
}

withDefaults(defineProps<CopyProps>(), {
  variant: 'invisible'
});

const copied = ref(false);
const errored = ref(false);

const button = ref(null);

function copyTextToClipboard(text: string) {
  navigator.clipboard.writeText(text).then(
    function () {
      copied.value = true;
      setTimeout(() => {
        copied.value = false;
      }, 3000);
    },
    function () {
      errored.value = true;
      setTimeout(() => {
        errored.value = false;
      }, 3000);
    }
  );
}
</script>

<template>
  <div
    class="flex items-center justify-between"
    :class="{
      ' rounded-lg border border-slate-200 bg-white': variant === 'input'
    }"
  >
    <div
      class="grow text-sm leading-5 text-slate-700"
      :class="{
        'truncate border-r border-slate-200 px-3 py-1.5': variant === 'input'
      }"
    >
      {{ text }}
    </div>
    <div
      class="relative flex"
      :class="{
        'ml-2': variant !== 'input',
        'transition-color rounded-r-lg bg-slate-50 duration-300': variant === 'input',
        'hover:bg-slate-100 active:bg-slate-200': variant === 'input' && !copied && !errored
      }"
    >
      <div class="absolute inset-0 flex items-center justify-center">
        <CheckCircle
          class="h-5 w-5 text-green-700 transition-[opacity,transform] duration-300"
          :class="{
            'scale-50 opacity-0': !copied,
            'scale-100 opacity-100': copied
          }"
        />
      </div>
      <div class="absolute inset-0 flex items-center justify-center">
        <TimesCircle
          class="h-5 w-5 text-red-700 transition-[opacity,transform] duration-300"
          :class="{
            'scale-50 opacity-0': !errored,
            'scale-100 opacity-100': errored
          }"
        />
      </div>
      <IconButton
        v-if="variant !== 'input'"
        ref="button"
        ariaLabel="Copy text"
        class="-my-1.5 transition-[opacity,transform] duration-300"
        :class="{
          'scale-50 opacity-0': copied || errored,
          'scale-100 opacity-100': !copied && !errored
        }"
        :icon="Copy"
        variant="invisible"
        @click="copyTextToClipboard(text)"
      />
      <button
        v-else
        class="rounded-r-lg px-2.5 py-1.5 transition-[opacity,transform] duration-300 focus:ring-3 focus:ring-slate-300 focus-visible:outline-none"
        @click="copyTextToClipboard(text)"
        :class="{
          'scale-50 opacity-0': copied || errored,
          'scale-100 opacity-100': !copied && !errored
        }"
      >
        <Copy class="h-5 w-5 text-slate-800" />
      </button>
    </div>
  </div>
</template>
