<script setup lang="ts">
type AutomatedInboundMessageData = App.Sms.Data.AutomatedCampaigns.AutomatedInboundMessageData;

import { NodeProps } from '@vue-flow/core';

import { ButtonColor } from '@/hooks/useButtonClasses';

import Button from '@/components/button/Button.vue';
import Condition from '@/components/automated-campaigns/Condition.vue';
import NodeAddPopover from './NodeAddPopover.vue';
import NodeCard from '@/components/automated-campaigns/NodeCard.vue';
import NodeOutline from '@/components/automated-campaigns/NodeOutline.vue';

import CornerUpLeftAltIcon from '@/icons/line/corner-up-left-alt.svg';

const props = defineProps<NodeProps<AutomatedInboundMessageData>>();

const outboundMessageForm = useForm({
  fields: {
    inbound_message_id: props.data.id
  },
  method: 'post',
  url: route('sms.outbound-messages.store', { campaign: props.data.campaign_id })
});

const actionForm = useForm({
  fields: {
    inbound_message_id: props.data.id
  },
  method: 'post',
  url: route('sms.actions.store', { campaign: props.data.campaign_id })
});

async function handleAddOutboundMessage(closePopover: () => void) {
  await outboundMessageForm.submit();

  closePopover();
}

async function handleAddAction(closePopover: () => void) {
  await actionForm.submit();

  closePopover();
}
</script>

<template>
  <NodeOutline
    class="px-2 pb-6 pt-2"
    :hasAlerts="!data.is_valid_node"
    :validationResults="data.validation_results as Record<string, string[]>"
    :selected
  >
    <NodeCard>
      <template #icon>
        <div class="inline-block rounded bg-teal-50 p-1.5">
          <CornerUpLeftAltIcon class="h-4 w-4 text-teal-600" />
        </div>
      </template>
      <template #label>
        <Condition
          v-if="['positive', 'negative', 'help', 'opt-out'].includes(data.type)"
          operator="If reply is"
          :b="data.type"
        />
        <Condition v-if="data.type === 'any'" operator="If any reply is" b="received" />
        <Condition
          v-if="data.type === 'custom'"
          prefix="If reply is"
          a="custom"
          operator="and content is"
          :b="data.body ?? ''"
        />
      </template>
      <template #badges>
        <slot name="badges" />
      </template>
    </NodeCard>

    <template #addButton>
      <NodeAddPopover>
        <template #default="{ closePopover }">
          <Button
            :color="ButtonColor.primary"
            :isLoading="actionForm.processing"
            class="block w-full"
            size="xs"
            variant="soft"
            @click="handleAddAction(closePopover)"
            >Action</Button
          >
          <Button
            variant="soft"
            :color="ButtonColor.primary"
            size="xs"
            class="block w-full"
            :isLoading="outboundMessageForm.processing"
            @click="handleAddOutboundMessage(closePopover)"
            >Outbound Message</Button
          >
        </template>
      </NodeAddPopover>
    </template>
  </NodeOutline>
</template>
