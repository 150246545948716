<script setup lang="ts">
import IconButton from '@/components/button/IconButton.vue';

import { ButtonColor, ButtonSize, ButtonVariant } from '@/hooks/useButtonClasses';
import { useDialogHelpers } from '@/hooks/useDialogHelpers';

import { formatDate } from '@/utils/date';
import { formatNumber } from '@/utils/numbers';

import Button from '@/components/button/Button.vue';
import DangerDialog from '@/components/dialog/DangerDialog.vue';
import FormError from '@/components/formError/FormError.vue';

import EditAlt from '@/icons/line/edit-alt.svg';
import Save from '@/icons/line/save.svg';
import Times from '@/icons/line/times.svg';
import Trash from '@/icons/line/trash.svg';

const props = defineProps<{
  note: App.Students.Data.NoteData;
}>();

const { isDialogOpen, toggleDialog } = useDialogHelpers();

const isEditing = ref(false);
const isEditTextareaFocused = ref(false);

const deleteForm = useForm({
  method: 'DELETE',
  spoof: false,
  url: route('notes.delete', {
    note: props.note.id
  }),
  fields: {},
  hooks: {
    success() {
      close();
      router.reload({ only: ['notes'] });
    }
  }
});

const editForm = useForm({
  method: 'PUT',
  spoof: false,
  updateInitials: true,
  reset: true,
  url: route('notes.update', {
    note: props.note.id
  }),
  fields: {
    text: props.note.text ?? ''
  },
  hooks: {
    success() {
      router.reload({ only: ['notes'] });
      toggleEditingState();
    }
  }
});

function toggleEditingState() {
  isEditing.value = !isEditing.value;
}

function handleToggleTextareaFocusState() {
  editForm.clearErrors();
  isEditTextareaFocused.value = !isEditTextareaFocused.value;
}

function handleNoteDeleteClick() {
  toggleDialog();
}

async function handleNoteDelete() {
  await deleteForm.submit();
  toggleDialog();
}

async function handleNoteEdit() {
  await editForm.submit();
}
</script>

<template>
  <div class="rounded-lg border border-slate-200 bg-slate-50">
    <div class="flex justify-between gap-2 px-4 pb-4 pt-5">
      <div>
        <p class="text-xl font-semibold leading-6 text-slate-900">
          {{ note.created_at ? formatDate(note.created_at) : 'No creation date' }}
        </p>
        <p class="mt-1 text-sm font-semibold leading-4 text-slate-500">
          {{ note.user?.name ?? 'Unknown user' }}
        </p>
      </div>
      <div v-if="isEditing">
        <Button
          :color="ButtonColor.slate"
          :iconRight="Times"
          :size="ButtonSize.sm"
          :variant="ButtonVariant.invisible"
          @click="toggleEditingState"
          >Cancel</Button
        >
      </div>
      <div v-else class="flex gap-1">
        <IconButton
          v-if="note.authorization.update"
          :icon="EditAlt"
          ariaLabel="Edit note"
          size="sm"
          variant="invisible"
          @click="toggleEditingState"
        />
        <IconButton
          v-if="note.authorization.delete"
          :icon="Trash"
          ariaLabel="Delete note"
          color="danger"
          size="sm"
          variant="invisible"
          @click="handleNoteDeleteClick"
        />
      </div>
    </div>
    <form
      v-if="isEditing"
      class="rounded-b-lg border-t border-slate-200"
      :class="{
        'ring-3 ring-slate-300/30': isEditTextareaFocused && !editForm.errors.text,
        'ring-3 ring-red-300/30': isEditTextareaFocused && editForm.errors.text
      }"
    >
      <textarea
        class="block w-full border-0 px-4 py-3 text-[16px] leading-5 text-slate-800 focus:shadow-none focus:ring-0 md:text-sm"
        :class="{
          'bg-white': !editForm.errors.text,
          'bg-red-50': editForm.errors.text
        }"
        rows="8"
        @blur="handleToggleTextareaFocusState"
        @focus="handleToggleTextareaFocusState"
        v-model="editForm.fields.text"
      />
      <div class="flex items-center border-t border-slate-200 px-2 py-1.5">
        <div class="grow">
          <FormError v-if="editForm.errors.text">{{ editForm.errors.text }}</FormError>
          <p
            v-else
            class="text-2xs"
            :class="{
              'text-red-700': editForm.fields.text?.length > 65535,
              'text-slate-500': editForm.fields.text?.length <= 65535
            }"
          >
            {{ formatNumber(editForm.fields.text?.length || 0) }} / 65,535
          </p>
        </div>
        <Button
          class="shrink-0"
          :color="ButtonColor.primary"
          :iconRight="Save"
          :isDisabled="editForm.processing"
          :isLoading="editForm.processing"
          :size="ButtonSize.sm"
          :variant="ButtonVariant.solid"
          @click="handleNoteEdit"
          >Save changes</Button
        >
      </div>
    </form>
    <div v-else class="border-t border-slate-200 px-4 pb-3.5 pt-3">
      <p class="break-words text-sm leading-5 text-slate-800">{{ note.text }}</p>
    </div>
  </div>
  <DangerDialog
    title="Delete Note"
    message="Are you sure you want to delete this note?"
    confirmButtonLabel="Delete note"
    :isOpen="isDialogOpen"
    :isLoading="deleteForm.processing"
    @onConfirm="handleNoteDelete"
    @onCancel="toggleDialog"
    @onClose="toggleDialog"
  />
</template>
