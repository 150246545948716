<script setup lang="tsx">
import type { CustomCells, HybridlyRow } from '@/components/table/Table';
import { IndexStudentActionsTabNavCounts } from '@/components/students/studentActions/IndexStudentActionsTabNav.vue';

import { useHybridlyTable } from '@/hooks/useHybridlyTable';
import { useTableRowClick } from '@/hooks/useTableRowClick';

import { AppliedFilters } from '@/components/distiller/types';

import IconButton from '@/components/button/IconButton.vue';
import IndexStudentActionsTabNav from '@/components/students/studentActions/IndexStudentActionsTabNav.vue';
import ShowStudentPageLayout from '@/components/students/ShowStudentPageLayout.vue';
import ShowStudentPageTableLayout from '@/components/students/ShowStudentPageTableLayout.vue';
import TableBadgesCell from '@/components/table/TableBadgesCell.vue';
import TableMoneyCell from '@/components/table/TableMoneyCell.vue';

import Plus from '@/icons/line/plus.svg';
import EmptyState from '@/components/emptyState/EmptyState.vue';

type StudentScholarshipRow = HybridlyRow<App.Students.Data.StudentScholarshipData>;

const props = defineProps<{
  appliedFilters: AppliedFilters;
  counts: IndexStudentActionsTabNavCounts;
  distiller: App.Search.Data.DistillerData;
  studentScholarships: Table<App.Students.Data.StudentScholarshipData>;
  student: App.Students.Data.StudentData;
}>();

const { table } = useHybridlyTable<App.Students.Data.StudentScholarshipData>(
  props,
  'studentScholarships'
);
const { handleRowClick } = useTableRowClick();

const customCells = {
  amount: ({ row, column }) => <TableMoneyCell value={row.value(column.key)} />,
  status: ({ row, column }) => (
    <TableBadgesCell
      badgesToDisplay={1}
      badgeVariant="soft"
      items={[row.value(column.key).label]}
    />
  )
} as CustomCells<App.Students.Data.StudentScholarshipData>;

function handleStudentScholarshipRowClick(event: MouseEvent, row: StudentScholarshipRow) {
  const url = route('students.student-scholarships.show', {
    student: props.student.id,
    studentScholarship: row.key
  });
  handleRowClick(url, event);
}
</script>


					<script lang="tsx">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <ShowStudentPageLayout :student :title="student.name">
    <template #tabs>
      <IndexStudentActionsTabNav :student :counts />
    </template>
    <template #table>
      <ShowStudentPageTableLayout
        title="Scholarship applications"
        :appliedFilters
        :distiller
        :table="table"
        :customCells
        :stickyEndColumns="1"
        clickableRows
        @clickRow="($event, row) => handleStudentScholarshipRowClick($event, row as StudentScholarshipRow)"
      >
        <template #rightButtons>
          <IconButton
            ariaLabel="Add scholarship application"
            variant="soft"
            :icon="Plus"
            :href="route('students.student-scholarships.create', { student: student.id })"
          />
        </template>
        <template #emptyState>
          <EmptyState
            title="No scholarship applications found"
            description="Create a scholarship application by clicking the button below"
            actionLabel="Add a scholarship applications"
            :actionHref="route('students.student-scholarships.create', { student: student.id })"
          />
        </template>
      </ShowStudentPageTableLayout>
    </template>
  </ShowStudentPageLayout>
</template>
