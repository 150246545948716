<script setup lang="ts">
import TabNav from '@/components/tabNav/TabNav.vue';
import TabNavItem from '@/components/tabNav/TabNavItem.vue';
import ThreadMessageListSent from '@/components/sms/ThreadMessageListSent.vue';
import ThreadMessageListScheduled from '@/components/sms/ThreadMessageListScheduled.vue';
import { WithLoadedRelations } from '@/@types/global';

withDefaults(
  defineProps<{
    thread: WithLoadedRelations<App.Sms.Data.ThreadData, 'recipient'>;
    showMessageTypeToggle?: boolean;
    lastUpdatedAt?: string;
    highlightMessageIds?: number[];
    scrollToLatestMessage?: boolean;
    hasSmsEnabled?: boolean;
  }>(),
  {
    lastUpdatedAt: undefined,
    highlightMessageIds: () => [],
    showMessageTypeToggle: false,
    hasSmsEnabled: false
  }
);

export type MessageType = 'sent' | 'scheduled';

const messageType = defineModel<MessageType>('messageType', {
  default: 'sent'
});

const messageContainer = useTemplateRef<HTMLDivElement>('messageContainer');
</script>

<template>
  <section
    ref="messageContainer"
    class="group relative flex max-h-full flex-1 flex-col overflow-hidden"
  >
    <div
      v-if="showMessageTypeToggle"
      class="absolute top-1 flex w-full justify-center justify-self-end"
    >
      <div class="rounded-lg border border-zinc-200 bg-white p-1 shadow-sm">
        <TabNav floating layout="inline" class="grid auto-cols-fr grid-flow-col">
          <TabNavItem
            :isActive="messageType === 'sent'"
            @click="messageType = 'sent'"
            class="justify-center"
            label="Sent"
          />
          <TabNavItem
            :isActive="messageType === 'scheduled'"
            @click="messageType = 'scheduled'"
            class="justify-center"
            label="Scheduled"
          />
        </TabNav>
      </div>
    </div>

    <ThreadMessageListSent
      v-if="messageType === 'sent'"
      :thread
      :messageContainer
      :lastUpdatedAt
      :highlightMessageIds
      :scrollToLatestMessage
      :hasSmsEnabled="hasSmsEnabled"
    />
    <ThreadMessageListScheduled
      v-else-if="messageType === 'scheduled'"
      :messageContainer
      :threadId="thread.id"
    />
  </section>
</template>
