<script setup lang="ts">
type EnumData = App.Base.Data.EnumData;
import type { Selectable } from '@/components/selectBox/selectBox';

import UserForm from '@/components/user/UserForm.vue';
import CenteredContent from '@/components/content/CenteredContent.vue';

defineProps<{
  roles: EnumData[];
  returnUrl?: string;
}>();

const form = useForm({
  method: 'POST',
  url: route('users.store'),
  fields: {
    first_name: '',
    last_name: '',
    email: '',
    role: null as Selectable<string> | null,
    program_ids: [] as Selectable<number>[],
    region_ids: [] as Selectable<number>[],
    school_ids: [] as Selectable<number>[]
  },
  transform: (fields) => ({
    ...fields,
    role: fields.role?.value,
    program_ids: fields.program_ids.map((program) => program.value),
    region_ids: fields.region_ids.map((region) => region.value),
    school_ids: fields.school_ids.map((school) => school.value)
  })
});
</script>


					<script lang="ts">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <CenteredContent>
    <UserForm :form="form" :roles="roles" :returnUrl="returnUrl" />
  </CenteredContent>
</template>
