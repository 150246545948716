<script setup lang="ts">
import pluralize from 'pluralize';
import CollapsibleContent from '@/components/collapsible/CollapsibleContent.vue';
const props = defineProps<{
  uploadSession: App.Bulkinator.Data.UploadSessionData;
  uploadType: App.Bulkinator.Data.UploadTypeData;
}>();

const repartitionCounts = ref<App.Bulkinator.Data.RepartitionCountData[]>([]);

function loadRepartitionCounts() {
  fetch(
    route('api.upload-sessions.repartition-count', { uploadSession: props.uploadSession.id })
  ).then(async (response) => {
    const data: App.Bulkinator.Data.RepartitionCountData[] = await response.json();
    repartitionCounts.value = data;
  });
}

function pluralizeCount(count: number) {
  return pluralize(props.uploadType.record_name, count);
}

onMounted(() => {
  loadRepartitionCounts();
});
</script>

<template>
  <div class="space-y-2">
    <CollapsibleContent v-for="repartition in repartitionCounts" :key="repartition.name">
      <span class="font-bold text-zinc-700">{{ repartition.name }}</span> will be modified and have
      <span class="font-bold text-zinc-700"
        >{{ repartition.count }} {{ pluralizeCount(repartition.count) }}</span
      >.
    </CollapsibleContent>
  </div>
</template>
