import axios from 'axios';
import AdmitHubContactData = App.AdmitHub.Data.AdmitHubContactData;
import { WithLoadedRelations } from '@/@types/global';

export async function showAdmitHubContact(
  contactId: number
): Promise<WithLoadedRelations<AdmitHubContactData, 'contactable'>> {
  return axios
    .get(route('api.admithub.contact.show', { contact: contactId }))
    .then((res) => res.data);
}

export async function getAdmitHubContactEscalationCount(): Promise<number> {
  return axios.get(route('api.admithub.escalations.count')).then((res) => res.data);
}
