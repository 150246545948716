<script setup lang="ts">
import type { CheckedValue } from '@/components/checkboxInput/CheckboxInput.vue';

import FormCheckboxInput from '@/components/formCheckboxInput/FormCheckboxInput.vue';

defineProps<{
  customField: App.Configuration.Data.ProgramStudentFieldData;
  error?: string;
  modelValue: CheckedValue;
}>();

const emit = defineEmits<{
  'update:modelValue': [value: boolean];
}>();

function onValueChange(event: boolean): void {
  emit('update:modelValue', event);
}
</script>

<template>
  <FormCheckboxInput
    :error
    :label="customField.field_label"
    :checked="modelValue"
    :name="customField.field_key"
    @update:checked="onValueChange"
  />
</template>
