<script setup lang="ts">
import { ButtonColor, ButtonVariant } from '@/hooks/useButtonClasses';

import Button from '@/components/button/Button.vue';
import FormError from '@/components/formError/FormError.vue';
import IconButton from '@/components/button/IconButton.vue';
import SurveyBlock from '@/components/surveys/sessoins/SurveyBlock.vue';

import AngleDown from '@/icons/line/angle-down.svg';
import AngleUp from '@/icons/line/angle-up.svg';
import ArrowRight from '@/icons/line/arrow-right.svg';
import Message from '@/icons/line/message.svg';

const props = defineProps<{
  answer: App.Surveys.Data.SurveySessionAnswerData;
  block: App.Surveys.Data.SurveyBlockData;
  blockCount: number;
  surveySession: App.Surveys.Data.SurveySessionData;
  survey: App.Surveys.Data.SurveyData;
  prevBlockId: number | null;
  nextBlockId: number | null;
  errors: Record<string, string>;
}>();

const isNavigatingNext = ref(false);
const isNavigatingPrev = ref(false);
const currentQuestion = computed(() => props.block.order + 1);

const canNavigateToNextBlock = computed(() => {
  if (!props.nextBlockId) {
    return false;
  }

  return props.block.is_required && !props.answer ? false : true;
});

function formatChoice(answerChoice: App.Surveys.Data.SurveySessionAnswerChoiceData) {
  return {
    survey_choice_id: answerChoice.choice?.id ?? null,
    answer_type: answerChoice.answer_type,
    answer_id: answerChoice.answer_id,
    answer_option: answerChoice.answer_option
  };
}

const form = useForm({
  method: 'PUT',
  url: route('survey.store', {
    surveySession: props.surveySession.id,
    block: props.block.id
  }),
  preserveState: false,
  fields: {
    value: props.answer?.value ?? '',
    choices: props.answer?.choices ? props.answer.choices.map((choice) => formatChoice(choice)) : []
  },
  transform: (fields) => ({
    ...fields,
    choices: fields.choices.map((choice) => {
      return choice;
    }),
    survey_session_id: props.surveySession.id,
    survey_block_id: props.block.id
  })
});

function handleContinueClick() {
  form.submit();
}

function navigateToBlock(direction: 'next' | 'prev') {
  const blockId = direction === 'next' ? props.nextBlockId : props.prevBlockId;

  if (direction === 'next') {
    isNavigatingNext.value = true;
  } else {
    isNavigatingPrev.value = true;
  }

  form.reset();
  router.navigate({
    url: route('survey.show', { surveySession: props.surveySession.id, block: blockId }),
    hooks: {
      success() {
        isNavigatingPrev.value = false;
        isNavigatingNext.value = false;
      }
    }
  });
}
</script>


					<script lang="ts">
					import __hybridly_layout_0 from '~/resources/domains/surveys/layouts/TwoColumns.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <form @submit.prevent="handleContinueClick" class="flex max-w-142 flex-col gap-y-4">
    <p class="text-sm leading-5 text-slate-500">Question {{ currentQuestion }}</p>
    <div class="flex w-full flex-col gap-1.5">
      <SurveyBlock :block="block" :errors="errors" :form="form" :surveySession="surveySession" />
      <FormError>{{ errors.survey_session_id }}</FormError>
    </div>
    <div class="flex grow items-center justify-between">
      <Button
        :color="ButtonColor.slate"
        :iconLeft="currentQuestion === blockCount ? Message : ArrowRight"
        :isLoading="form.processing"
        :variant="ButtonVariant.outlined"
        @click="handleContinueClick"
      >
        {{ currentQuestion === blockCount ? 'Submit' : 'Continue' }}
      </Button>
      <Button
        v-if="canNavigateToNextBlock"
        :color="ButtonColor.slate"
        :isLoading="isNavigatingNext"
        :variant="ButtonVariant.invisible"
        @click="navigateToBlock('next')"
        >Skip</Button
      >
    </div>
  </form>
  <div
    class="fixed bottom-[env(safe-area-inset-bottom)] right-[env(safe-area-inset-right)] flex gap-1 pb-8 pr-8"
  >
    <IconButton
      @click="currentQuestion > 1 && navigateToBlock('prev')"
      :icon="AngleUp"
      :isDisabled="currentQuestion === 1 || isNavigatingPrev"
      :isLoading="isNavigatingPrev"
      ariaLabel="Previous question"
    />
    <IconButton
      @click="canNavigateToNextBlock && navigateToBlock('next')"
      :icon="AngleDown"
      :isDisabled="!canNavigateToNextBlock || isNavigatingNext"
      :isLoading="isNavigatingNext"
      ariaLabel="Next question"
    />
  </div>
</template>
