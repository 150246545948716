<script setup lang="ts">
import RecipientProfileHeader from '@/components/messaging/recipientProfileHeader.vue';
import RecipientBadge from '@/components/messaging/recipientBadge.vue';
import ThreadMessageList, { MessageType } from '@/components/sms/ThreadMessageList.vue';
import { WithLoadedRelations } from '@/@types/global';
import ThreadData = App.Sms.Data.ThreadData;
import RelatedThreadData = App.Sms.Data.RelatedThreadData;
import PhoneNumberBadge from '@/components/messaging/phoneNumberBadge.vue';
import RelatedThreadsNav from '@/components/sms/RelatedThreadsNav.vue';
import FormSwitchInput from '@/components/formSwitchInput/FormSwitchInput.vue';

defineEmits<{
  showAdmitHubContact: [contactId: number];
  showThread: [thread: WithLoadedRelations<ThreadData, 'recipient'>];
}>();

const props = withDefaults(
  defineProps<{
    thread: WithLoadedRelations<ThreadData, 'recipient'>;
    hasSmsEnabled: boolean;
    showRecipientHeader?: boolean;
    relatedThreads: RelatedThreadData[];
  }>(),
  {
    showRecipientHeader: true
  }
);

function updateMessagePreference(optIn: boolean) {
  router.put(route('sms.threads.message-preferences.update'), {
    data: {
      thread_id: props.thread.id,
      opt_in: optIn
    },
    only: ['thread', 'relatedThreads'],
    preserveState: true,
    preserveScroll: true
  });
}

const messageType = useStorage<MessageType>(`thread-${props.thread.id}-message-type`, 'sent');

onMounted(() => {
  router.put(route('sms.threads.mark-read'), {
    data: {
      all_threads: false,
      preventToast: true,
      thread_ids: [props.thread.id]
    },
    only: ['threads', 'thread', 'hasUnreadThread'],
    preserveState: true,
    preserveScroll: true
  });
});
</script>

<template>
  <div class="flex max-h-full min-h-0 flex-1 flex-col">
    <slot name="header">
      <RecipientProfileHeader
        v-if="showRecipientHeader"
        :isStudent="thread.is_student_recipient"
        :phone="thread.phone_number || ''"
        :recipient="thread.recipient"
        :recipientType="thread.is_student_recipient ? 'student' : 'parent'"
      >
        <template #badge>
          <div class="mt-0.5 flex">
            <RecipientBadge
              v-if="!thread.is_phone_number_recipient"
              :isStudent="thread.is_student_recipient"
              :recipient="thread.recipient"
            />
            <PhoneNumberBadge v-else />
          </div>
        </template>
      </RecipientProfileHeader>
    </slot>

    <slot name="tab-nav" v-if="!thread.is_phone_number_recipient">
      <RelatedThreadsNav
        :recipient="thread.recipient"
        type="thread"
        :id="thread.id"
        :relatedThreads="relatedThreads"
        @showThread="$emit('showThread', $event)"
        @showAdmitHubContact="$emit('showAdmitHubContact', $event)"
      />
    </slot>

    <div
      v-if="!thread.is_phone_number_recipient"
      class="flex h-12 items-center border-b border-zinc-200 px-3 py-2"
    >
      <FormSwitchInput
        label="SMS Opt-in Status"
        v-model:checked="thread.recipient.sms_opted_in"
        @update:checked="updateMessagePreference"
        name="optInStatus"
      />
    </div>

    <ThreadMessageList
      v-model:message-type="messageType"
      :thread="thread"
      :showMessageTypeToggle="!thread.is_phone_number_recipient"
      :lastUpdatedAt="thread.updated_at"
      :hasSmsEnabled="hasSmsEnabled"
      scrollToLatestMessage
    />
  </div>
</template>
