<script setup lang="ts">
import PaddedCellContent from '@/components/table/PaddedCellContent.vue';
import Tooltip from '@/components/tooltip/Tooltip.vue';

import PlusCircleIcon from '@/icons/line/plus-circle.svg';
import CheckCircleIcon from '@/icons/line/check-circle.svg';
import ExclamationCircleIcon from '@/icons/line/exclamation-circle.svg';
import QuestionCircleIcon from '@/icons/line/question-circle.svg';

const props = defineProps<{
  status: string;
  uploadType: App.Bulkinator.Data.UploadTypeData;
}>();

const classes = computed(() => {
  return {
    new: 'bg-blue-100 text-blue-800',
    pending: 'bg-amber-100 text-amber-800',
    match: 'bg-green-100 text-green-800',
    error: 'bg-red-100 text-red-800'
  }[props.status];
});

const description = computed(() => {
  return {
    new: `This row will create a new ${props.uploadType.record_name} in the system.`,
    pending: `This row is still pending and doesn't have enough information to match to a ${props.uploadType.record_name}.`,
    match: `This row will update an existing ${props.uploadType.record_name} in the system.`,
    error: `This row has an error and will not be processed until the errors are resolved.`
  }[props.status];
});

const IconComponent = computed(() => {
  return {
    new: PlusCircleIcon,
    pending: QuestionCircleIcon,
    match: CheckCircleIcon,
    error: ExclamationCircleIcon
  }[props.status];
});
</script>

<template>
  <PaddedCellContent
    class="flex h-full items-center justify-center border-r border-slate-200"
    :class="classes"
  >
    <Tooltip side="top" showArrow>
      <template v-slot:trigger>
        <component :is="IconComponent" class="h-4.5 w-4.5" />
      </template>

      {{ description }}
    </Tooltip>
  </PaddedCellContent>
</template>
