<script setup lang="ts">
import ShowSurveyPageLayout from '@/components/surveys/surveys/ShowSurveyPageLayout.vue';
import SurveyBlockPreview from '@/components/surveys/surveys/SurveyBlockPreview.vue';
import SurveyBlockList from '@/components/surveys/surveys/SurveyBlockList.vue';

const props = defineProps<{
  survey: App.Surveys.Data.SurveyData;
  block: App.Surveys.Data.SurveyBlockData;
}>();

const addBlockForm = useForm<{
  after_block_id: number | null;
  survey_category_id: number | null;
}>({
  method: 'POST',
  preserveState: false,
  url: route('surveys.blocks.store', {
    survey: props.survey.id
  }),
  fields: {
    after_block_id: null,
    survey_category_id: props.block.category?.id ?? null
  }
});

function handleAddQuestionClick(blockId: number | null) {
  addBlockForm.fields.after_block_id = blockId;
  addBlockForm.submit();
}
</script>


					<script lang="ts">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <ShowSurveyPageLayout :survey="survey" :title="survey.name">
    <template #leftColumn>
      <SurveyBlockList
        :blocks="survey.blocks ?? []"
        :selectedBlock="block.id"
        :survey="survey"
        @addQuestion="handleAddQuestionClick"
      />
    </template>
    <SurveyBlockPreview :block="block" :survey="survey" />
  </ShowSurveyPageLayout>
</template>
