import axios from 'axios';

export async function getInteractionOptions(): Promise<{
  interactionTopics: App.Base.Data.EnumData[];
  interactionTypes: App.Base.Data.EnumData[];
  interactionMediums: App.Base.Data.EnumData[];
  interactionMeetingLengths: App.Base.Data.EnumData[];
}> {
  return axios.get(route('api.interactions.options')).then((res) => res.data);
}
