import { debounce } from 'lodash';

import { search } from '@/api/ScholarshipApi';

import { buildSelectableScholarship } from '@/utils/buildSelectable';

import { Selectable } from '@/components/selectBox/selectBox';

export function useScholarshipsSearch(searchFilters?: Record<string, unknown>) {
  const searchQuery = ref('');
  const isLoading = ref(false);
  const scholarshipOptions = ref<Selectable<number>[]>([]);

  const searchScholarships = debounce(async (query: string) => {
    const response = await search({
      filters: {
        ...searchFilters,
        search: query
      }
    });
    scholarshipOptions.value = response.map((scholarship) =>
      buildSelectableScholarship(scholarship)
    ) as Selectable<number>[];

    isLoading.value = false;
  }, 300);

  function onQueryChange(query: string): void {
    isLoading.value = true;
    searchQuery.value = query;

    searchScholarships(query);
  }

  return {
    scholarshipOptions,
    isLoading,
    onQueryChange
  };
}
