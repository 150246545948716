<script setup lang="ts">
type EnumData = App.Base.Data.EnumData;
type StudentData = App.Students.Data.StudentData;

import type { Selectable } from '@/components/selectBox/selectBox';

import { ButtonColor, ButtonSize, ButtonVariant } from '@/hooks/useButtonClasses';

import Button from '@/components/button/Button.vue';
import CenteredContent from '@/components/content/CenteredContent.vue';
import EmptyCard from '@/components/emptyCard/EmptyCard.vue';
import Fieldset from '@/components/fieldset/Fieldset.vue';
import FormListboxInput from '@/components/formListboxInput/FormListboxInput.vue';
import FormTextInput from '@/components/formTextInput/FormTextInput.vue';
import IconButton from '@/components/button/IconButton.vue';
import StudentFormWrapper from '@/components/students/StudentFormWrapper.vue';

import PlusCircle from '@/icons/line/plus-circle.svg';
import Trash from '@/icons/line/trash.svg';

const props = defineProps<{
  majors: EnumData[];
  student: StudentData;
  returnUrl?: string;
}>();

const form = useForm({
  method: 'PUT',
  url: route('students.update.academic-details', { student: props.student.id }),
  updateInitials: true,
  fields: {
    gpa: props.student.gpa,
    majors: props.student.majors
      ? props.student.majors.map((major) => major.major as Selectable<number>)
      : null
  },
  transform: (fields) => ({
    ...fields,
    majors: fields.majors?.map((major) => major.value) ?? []
  })
});

function handleAddMajor() {
  form.fields.majors?.push({
    ...props.majors[0]
  });
}

function handleRemoveMajor(index) {
  form.fields.majors?.splice(index, 1);
}

function handleUpdateMajors(index: number, value: Selectable<number> | null) {
  if (form.fields.majors && value !== null) {
    form.fields.majors[index] = value;
  }
}
</script>


					<script lang="ts">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <CenteredContent>
    <StudentFormWrapper
      isEditing
      :form="form"
      :isDirty="form.isDirty"
      :isProcessing="form.processing"
      :studentId="student.id"
      @onSubmit="form.submit"
    >
      <div class="flex flex-col gap-8">
        <Fieldset title="Student information">
          <FormTextInput
            :error="form.errors.gpa"
            :modelValue="form.fields.gpa"
            label="GPA"
            name="gpa"
            step="any"
            type="number"
            @update:modelValue="(value) => (form.fields.gpa = value)"
          />
        </Fieldset>

        <Fieldset title="Majors">
          <template v-if="form.fields.majors?.length">
            <div class="flex flex-col gap-2.5">
              <div class="flex flex-col gap-5">
                <div
                  v-for="(major, index) in form.fields.majors"
                  :key="major.value"
                  class="flex items-end gap-2"
                >
                  <FormListboxInput
                    :label="`Major ${index + 1}`"
                    :error="form.errors?.majors?.[index]"
                    :options="majors"
                    :modelValue="major"
                    class="grow"
                    @update:modelValue="(value) => handleUpdateMajors(index, value)"
                  />
                  <IconButton
                    :icon="Trash"
                    ariaLabel="Remove major"
                    color="danger"
                    variant="invisible"
                    @click="handleRemoveMajor(index)"
                  />
                </div>
              </div>
              <div>
                <Button
                  :color="ButtonColor.slate"
                  :iconLeft="PlusCircle"
                  :size="ButtonSize.xs"
                  :variant="ButtonVariant.invisible"
                  @click="handleAddMajor"
                  >Add a major</Button
                >
              </div>
            </div>
          </template>
          <EmptyCard
            v-else
            title="No major was added"
            description="Add a major by clicking the button below"
            actionLabel="New major"
            isDashed
            isRounded
            variant="invisible"
            size="md"
            @actionClick="handleAddMajor"
          />
        </Fieldset>
      </div>
    </StudentFormWrapper>
  </CenteredContent>
</template>
