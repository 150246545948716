<script setup lang="ts">
import { ChartComponentRef, Doughnut } from 'vue-chartjs';
import ChartAnnotations from 'chartjs-plugin-annotation';
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  BarElement,
  CategoryScale,
  LinearScale,
  ArcElement,
  ChartOptions,
  Plugin
} from 'chart.js';

import { useChartTooltip } from '@/hooks/useChartTooltip';
import { useTailwind } from '@/hooks/useTailwind';
import { DashboardConfig } from '@/domains/dashboard/config';
import { ChartjsDatasetItem, formatChartDataValue } from './data';
import ChartLegend from './ChartLegend.vue';
import GoalTargetBadge from './GoalTargetBadge.vue';

ChartJS.register(
  Title,
  Tooltip,
  BarElement,
  CategoryScale,
  LinearScale,
  ArcElement,
  ChartAnnotations
);

interface Props {
  labels: string[];
  dataset: ChartjsDatasetItem[];
  dashboardConfig: DashboardConfig;
}

const props = defineProps<Props>();

const { externalTooltipHandler } = useChartTooltip({
  anchorPosition: 'bottom'
});
const { theme } = useTailwind();

const chart = ref<ChartComponentRef | null>(null);

const colors = [
  theme.colors.secondary[800],
  theme.colors.secondary[50],
  theme.colors.secondary[900],
  theme.colors.secondary[600],
  theme.colors.primary[700]
];

const legendItems = computed(() =>
  props.dataset.map((d, i) => ({
    text: props.labels[i],
    color: colors[i],
    goal: d.goal,
    total: d.total
  }))
);

const chartData = computed(() => ({
  labels: props.labels,
  datasets: [
    {
      backgroundColor: colors,
      data: props.dataset.map((d) => d.percent)
    }
  ]
}));

const doughnutHolePlugin = computed<Plugin<'doughnut'>>(() => ({
  id: 'doughnutHolePlugin',
  beforeDraw(chart) {
    const {
      ctx,
      chartArea: { top, width, height }
    } = chart;

    const yCenter = top + height / 2;
    const xCenter = width / 2;
    const titleLineHeight = 28;
    const value = props.dataset[0];

    ctx.textAlign = 'center';
    // title
    const title = formatChartDataValue(value, props.dashboardConfig.numberFormat);
    ctx.fillStyle = theme.colors.slate[800];
    ctx.font = `bold 24px/${titleLineHeight}px "Lato", sans-serif`;
    ctx.fillText(title, xCenter, yCenter);

    // subtitle
    const subtitle = `of ${value.total} students`;
    ctx.font = `ultra-condensed normal 11px/20px "Lato", sans-serif`;
    ctx.fillStyle = theme.colors.slate[600];
    ctx.fillText(subtitle, xCenter, yCenter + 15);
    chart.update();
  }
}));

const goalLinePlugin = computed<Plugin<'doughnut'>>(() => {
  return {
    id: 'goalLinePlugin',
    afterDatasetsDraw(chart) {
      const pointerValue = props.dataset[0].goal?.percent;
      if (pointerValue === null || pointerValue === undefined || !props.dashboardConfig.showGoal) {
        return;
      }

      const { ctx, data } = chart;
      ctx.save();
      const { x: xCenter, y: yCenter } = chart.getDatasetMeta(0).data[0];
      // chart.js plugins docs are sparse - it is unclear how to get the correct TElement hence casting unfortunately
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const innerRadius = (chart.getDatasetMeta(0).data[0] as any).innerRadius as number;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const outerRadius = (chart.getDatasetMeta(0).data[0] as any).outerRadius as number;
      const doughnutThickness = outerRadius - innerRadius;
      const angle = Math.PI / 180;

      const totalSum = data.datasets[0].data.reduce((acc, curr) => acc + (curr as number) ?? 0, 0);
      const targetPointerRotation = (pointerValue / totalSum) * 360;
      // line
      ctx.translate(xCenter, yCenter);
      ctx.rotate(angle * targetPointerRotation);
      ctx.beginPath();
      ctx.fillStyle = theme.colors.amber[400];
      ctx.lineWidth = 2;
      ctx.setLineDash([2, 2]);
      ctx.strokeStyle = theme.colors.amber[400];
      ctx.moveTo(0, -innerRadius);
      ctx.lineTo(0, -outerRadius);
      ctx.stroke();
      ctx.restore();

      // arc
      function degreesToRadians(degrees: number) {
        return degrees * angle;
      }
      const startAngle = degreesToRadians(0) - Math.PI / 2;
      const endAngle = degreesToRadians(targetPointerRotation) - Math.PI / 2;
      ctx.beginPath();
      ctx.arc(xCenter, yCenter, innerRadius + doughnutThickness / 2, startAngle, endAngle, false);
      ctx.lineWidth = doughnutThickness - 1;
      ctx.strokeStyle = '#F59E0B29';
      ctx.stroke();
    }
  };
});

const chartOptions = computed<ChartOptions<'doughnut'>>(() => ({
  responsive: true,
  maintainAspectRatio: false,
  cutout: '70%',
  plugins: {
    tooltip: {
      enabled: false,
      position: 'nearest',
      external: externalTooltipHandler,
      padding: 5,
      bodyFont: {
        family: 'Lato, sans-serif',
        size: 12
      },
      callbacks: {
        label: function (context) {
          return formatChartDataValue(
            props.dataset[context.dataIndex],
            props.dashboardConfig.numberFormat
          );
        }
      }
    }
  }
}));
</script>

<template>
  <div class="flex flex-col gap-4 px-7 py-5 lg:flex-row">
    <div class="relative h-32 w-32">
      <Doughnut
        :options="chartOptions"
        :data="chartData"
        ref="chart"
        :plugins="[doughnutHolePlugin, goalLinePlugin]"
      />
    </div>
    <div class="flex flex-col justify-between">
      <ChartLegend
        :items="legendItems"
        :chart="chart?.chart ?? null"
        direction="column"
        :dashboardConfig="dashboardConfig"
      />

      <GoalTargetBadge
        v-if="dashboardConfig.showGoal"
        :format="dashboardConfig.numberFormat"
        :data="dataset[0]"
      />
    </div>
  </div>
</template>
