<script setup lang="ts">
import LoadingSquaresIcon from '@/icons/line/loading-squares.svg';

export type Props = {
  iconColor?: 'primary' | 'secondary' | 'slate';
  title: string;
  description?: string;
};

const props = withDefaults(defineProps<Props>(), {
  iconColor: 'primary'
});

const classes = computed(() => ({
  'text-primary-800': props.iconColor === 'primary',
  'text-secondary-800': props.iconColor === 'secondary',
  'text-slate-800': props.iconColor === 'slate'
}));
</script>

<template>
  <section class="flex flex-col items-center gap-3 self-stretch">
    <div class="py-2.5">
      <LoadingSquaresIcon :class="classes" />
    </div>

    <p class="text-base font-semibold text-black">{{ title }}</p>
    <p v-if="description" class="text-sm font-normal text-zinc-600">{{ description }}</p>
  </section>
</template>
