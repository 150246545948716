<script setup lang="ts">
import type { SurveyAnswerChoiceType } from '@/components/surveys/sessoins/SurveyBlock.vue';

import { RadioGroupRoot } from 'radix-vue';
import SurveyBlockRadio from '@/components/surveys/sessoins/SurveyBlockRadio.vue';
import FormError from '@/components/formError/FormError.vue';

const props = defineProps<{
  block: App.Surveys.Data.SurveyBlockData;
  choices?: SurveyAnswerChoiceType[];
  errors?: Record<string, string>;
  value?: string;
}>();

const computedValue = computed(() => props.choices?.[0]?.survey_choice_id ?? null);

const emit = defineEmits<{
  'update:modelValue': [value: SurveyAnswerChoiceType[]];
}>();

function handleValueUpdate(value: string) {
  const choice = props.block.choices?.find((option) => option.id === parseInt(value)) ?? null;

  emit(
    'update:modelValue',
    choice
      ? [
          {
            survey_choice_id: choice.id as number
          }
        ]
      : []
  );
}
</script>

<template>
  <div class="flex w-full flex-col gap-1.5">
    <RadioGroupRoot
      :ariaLabel="block.block_text"
      :modelValue="computedValue?.toString()"
      class="flex flex-col gap-1 lg:flex-row"
      orientation="vertical"
      @update:modelValue="(value) => handleValueUpdate(value)"
    >
      <SurveyBlockRadio
        v-for="option in block.choices"
        :key="option.id"
        :id="option.id.toString()"
        :label="option.choice_text"
        :value="option.id.toString()"
        class="w-full"
      />
    </RadioGroupRoot>
    <FormError v-if="errors?.choices">{{ errors.choices }}</FormError>
  </div>
</template>
