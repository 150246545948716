<script setup lang="ts">
import pluralize from 'pluralize';
import { debounce, cloneDeep } from 'lodash';

import { BadgeColor, BadgeVariant } from '@/hooks/useBadgeClasses';
import { ButtonColor, ButtonSize, ButtonVariant } from '@/hooks/useButtonClasses';
import { useFilters } from '@/hooks/useFilters';

import Badge from '@/components/badge/Badge.vue';
import FilterProvider from '@/components/distiller/FilterProvider.vue';
import { AppliedFilters } from '@/components/distiller/types';
import Distiller from '@/components/distiller/Distiller.vue';
import FilterTemplates from '@/components/filters/filterTemplate/FilterTemplates.vue';
import Button from '@/components/button/Button.vue';
import Dialog from '@/components/dialog/Dialog.vue';

import IconPlus from '@/icons/line/plus.svg';
import IconFilter from '@/icons/line/filter.svg';
import SpinnerIcon from '@/icons/line/spinner.svg';

const props = defineProps<{
  title: string;
  distiller: App.Search.Data.DistillerData;
  appliedFilters?: AppliedFilters;
  scopedAppliedFilters?: AppliedFilters;
}>();

defineModel<boolean>('modelValue');

defineEmits<{
  onClearAllFiltersClick: [void];
  onAddFilterClick: [void];
  onClose: [void];
}>();

const {
  filteredRecordsCount,
  formattedFilteredRecordsCount,
  getRecordCount,
  isLoadingRecordCount
} = useFilters();

const isOpen = ref(false);
const isReloading = ref(false);
const appliedFilters = ref(props.appliedFilters || {});

const appliedFiltersCount = computed(() =>
  appliedFilters.value ? Object.keys(appliedFilters.value).length : 0
);

const hasAppliedFilters = computed(() => appliedFiltersCount.value > 0);

const filterBadgeLabel = computed(() => {
  return pluralize('filter', appliedFiltersCount.value, true);
});

const appliedFiltersQueryStringValue = computed(() =>
  hasAppliedFilters.value ? btoa(JSON.stringify(appliedFilters.value)) : undefined
);

function handleFilterTemplateChange(filterTemplate: App.Search.Data.FilterTemplateData | null) {
  if (!filterTemplate) {
    return;
  }

  appliedFilters.value = cloneDeep(filterTemplate.filters as unknown as AppliedFilters);
}

function handleClearAndApply() {
  handleClear();
  handleApplyFilters();
}

function handleApplyFilters() {
  isReloading.value = true;

  router.reload({
    preserveState: true,
    preserveScroll: true,
    data: {
      page: undefined,
      filters: appliedFiltersQueryStringValue.value
    },
    hooks: {
      success() {
        closeFilters();
      },
      after() {
        isReloading.value = false;
      }
    }
  });
}

const refreshRecordCount = debounce(() => {
  getRecordCount(props.distiller, {
    ...appliedFilters.value,
    ...(props.scopedAppliedFilters || {})
  } as AppliedFilters);
}, 350);

function handleClear() {
  appliedFilters.value = {};
}

function openFilters() {
  refreshRecordCount();
  isOpen.value = true;
}

function closeFilters() {
  appliedFilters.value = props.appliedFilters || {};

  isOpen.value = false;
}

onMounted(() => {
  refreshRecordCount();
});

watch(
  () => appliedFilters.value,
  () => {
    refreshRecordCount();
  },
  { deep: true }
);
</script>

<template>
  <span class="flex items-center gap-2">
    <Badge
      as="button"
      :iconLeft="hasAppliedFilters ? IconFilter : IconPlus"
      :label="hasAppliedFilters ? filterBadgeLabel : 'Add filter'"
      :variant="BadgeVariant.outlined"
      :color="BadgeColor.slate"
      dashed
      @click="openFilters"
    />
    <Button
      v-if="hasAppliedFilters"
      :variant="ButtonVariant.invisible"
      :size="ButtonSize.xs"
      :color="ButtonColor.secondary"
      @click="handleClearAndApply"
    >
      Clear filters
    </Button>
  </span>

  <Dialog
    :isOpen="isOpen"
    :title="title"
    :isLoading="isReloading"
    contentClass="max-w-4xl"
    confirmButtonLabel="Apply Filters"
    @onCancel="closeFilters"
    @onClose="closeFilters"
    @onConfirm="handleApplyFilters"
  >
    <template #footerHelperText>
      <SpinnerIcon v-if="isLoadingRecordCount" class="h-4 w-4 animate-spin text-secondary-900" />
      <p v-else class="text-sm font-semibold text-slate-700">
        Matches
        {{ formattedFilteredRecordsCount }}
        {{ pluralize(distiller.name?.label ?? 'Record', filteredRecordsCount?.count, false) }}
      </p>
    </template>
    <template #header>
      <div class="flex items-center justify-between text-base font-semibold text-slate-900">
        {{ title }}

        <div class="flex items-center gap-4 divide-x divide-gray-100">
          <FilterTemplates
            :title="title"
            :distiller="distiller"
            :appliedFilters="appliedFilters"
            @update:filterTemplate="handleFilterTemplateChange"
          />

          <div v-if="hasAppliedFilters" class="border-l border-gray-100 pl-4">
            <Button
              :variant="ButtonVariant.invisible"
              :size="ButtonSize.sm"
              :color="ButtonColor.slate"
              @click="handleClear"
              >Clear all filters</Button
            >
          </div>
        </div>
      </div>
    </template>

    <div class="w-full flex-1 space-y-4">
      <p v-if="!hasAppliedFilters" class="text-sm text-slate-700">
        To get started with filtering your data, select the filter you'd like to use from the list
        below.
      </p>

      <FilterProvider v-model="appliedFilters">
        <Distiller :distiller="distiller" />
      </FilterProvider>
    </div>
  </Dialog>
</template>
