<script setup lang="tsx">
import { withModifiers } from 'vue';
import { RouterLink } from 'hybridly/vue';

import {
  BadgeColor,
  BadgeShape,
  BadgeSize,
  BadgeVariant,
  useBadgeClasses
} from '@/hooks/useBadgeClasses';
import { useButtonClasses } from '@/hooks/useButtonClasses';

import IconMultiply from '@/icons/line/multiply.svg';
import { formatNumber } from '@/utils/numbers';

export type BadgeProps = {
  as?: 'span' | 'button';
  href?: string; // setting an href will override the `as` prop and render a RouterLink
  color?: BadgeColor | 'primary' | 'secondary' | 'danger' | 'warning' | 'success';
  iconColor?:
    | 'success'
    | 'danger'
    | 'indicator-primary'
    | 'indicator-secondary'
    | 'indicator-warning';
  iconLeft?: Component;
  iconRight?: Component;
  isDisabled?: boolean;
  dashed?: boolean;
  label?: string | number | null;
  shape?: BadgeShape | 'pill' | 'rounded';
  size?: BadgeSize | 'xs' | 'sm' | 'md' | 'lg';
  variant?: BadgeVariant | 'solid' | 'soft' | 'outlined' | 'invisible';
  clearable?: boolean;
};

const props = withDefaults(defineProps<BadgeProps>(), {
  as: 'span',
  color: BadgeColor.slate,
  shape: BadgeShape.pill,
  size: BadgeSize.lg,
  variant: BadgeVariant.outlined,
  dashed: false,
  clearable: false
});

const rawIconLeft = toRaw(props.iconLeft);
const rawIconRight = toRaw(props.iconRight);

const emit = defineEmits<{
  onClear: [];
}>();

const isInteractive = computed(() => !!props.href || !!props.clearable || props.as === 'button');

const classes = computed(() => {
  return [
    ...useButtonClasses({
      color: props.color,
      variant: props.variant,
      isInteractive: isInteractive.value
    }),
    ...useBadgeClasses({
      isInteractive: isInteractive.value,
      isDisabled: props.isDisabled,
      shape: props.shape as BadgeShape,
      size: props.size as BadgeSize,
      dashed: props.dashed
    })
  ];
});

const iconClasses = computed(() => [
  'shrink-0',
  {
    'opacity-70': !props.iconColor?.startsWith('indicator-'),
    'text-red-700': props.iconColor === 'danger',
    'text-green-700': props.iconColor === 'success',
    '-mx-1': props.iconColor?.startsWith('indicator-'),
    'text-primary-700': props.iconColor === 'indicator-primary',
    'text-secondary-600': props.iconColor === 'indicator-secondary',
    'text-amber-500': props.iconColor === 'indicator-warning',
    'hover:opacity-100': props.clearable,
    'w-3 h-3': props.size === 'xs' || props.size === 'sm',
    'w-4 h-4': props.size === 'md' || props.size === 'lg'
  }
]);

const computedComponent = computed(() => {
  if (props.isDisabled) {
    return 'button';
  }

  if (props.href) {
    return RouterLink;
  }

  if (props.as === 'button') {
    return 'button';
  }

  return 'span';
});

const iconWrapper = computed(() => {
  if (props.clearable) {
    return <button type="button" onClick={withModifiers(() => emit('onClear'), ['stop'])} />;
  }
  return 'span';
});
</script>

<template>
  <component
    :is="computedComponent"
    class="inline-flex min-w-0 shrink grow-0 items-center whitespace-nowrap font-semibold transition-colors duration-150 ease-in-out"
    :class="classes"
    :disabled="props.isDisabled"
    :href="href"
  >
    <span v-if="rawIconLeft" :class="iconClasses">
      <component :is="rawIconLeft" />
    </span>
    <span class="truncate">
      <slot>{{ formatNumber(props.label) }}</slot>
    </span>
    <component v-if="rawIconRight || clearable" :is="iconWrapper" :class="iconClasses">
      <component :is="clearable ? IconMultiply : rawIconRight" />
    </component>
  </component>
</template>
