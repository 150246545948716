import { getUploadSessionCount } from '@/api/UploadSessionCountApi';
import { ref } from 'vue';

export function useUploadSessionCount(uploadSessionId: number) {
  const isLoading = ref(false);
  const counts = ref({
    new: 0,
    pending: 0,
    match: 0,
    error: 0
  });

  async function loadUploadSessionCount() {
    try {
      isLoading.value = true;
      const response = await getUploadSessionCount(uploadSessionId);
      counts.value = response;
    } finally {
      isLoading.value = false;
    }
  }

  return {
    counts,
    isLoading,
    loadUploadSessionCount
  };
}
