<script setup lang="tsx">
import IconButton from '@/components/button/IconButton.vue';
import GuidedSteps from '@/components/guidedSteps/GuidedSteps.vue';
import GuidedStepsContainer from '@/components/guidedSteps/GuidedStepsContainer.vue';
import PageHeader from '@/components/pageHeader/PageHeader.vue';
import TimesIcon from '@/icons/line/times.svg';

const props = defineProps<{
  customReport: App.Reports.Data.CustomReportData;
  currentStep: number;
}>();

const steps = [
  ...(props.customReport.can_customize_filters
    ? [
        {
          label: 'Filters',
          description: 'Select filters to apply'
        }
      ]
    : []),
  ...(props.customReport.can_customize_columns
    ? [
        {
          label: 'Columns',
          description: 'Select columns for the report'
        },
        {
          label: 'Order Columns',
          description: 'Order the selected columns'
        }
      ]
    : []),
  {
    label: 'Details',
    description: 'Finalize the details'
  }
];

const currentStep = computed(() => {
  if (props.currentStep >= steps.length) {
    return steps.length - 1;
  }

  return props.currentStep;
});
</script>

<template>
  <div class="divide-y-slate-200 flex h-full flex-col divide-y">
    <PageHeader :title="`Create New ${customReport.name}`" centered>
      <template #rightButtons>
        <IconButton
          :icon="TimesIcon"
          size="sm"
          variant="invisible"
          ariaLabel="Create new report"
          @click="router.get(route('report-templates.index'))"
        />
      </template>
    </PageHeader>

    <GuidedStepsContainer class="px-20">
      <GuidedSteps
        :steps="steps"
        :currentStep="currentStep"
        direction="horizontal"
        :showStepNumber="false"
        :showBadge="false"
        class="mx-auto"
      />
    </GuidedStepsContainer>

    <slot />
  </div>
</template>
