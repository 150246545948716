<script setup lang="ts">
import type { ButtonProps } from '@/components/button/Button.vue';

import Button from '@/components/button/Button.vue';

import CheckCircle from '@/icons/line/check-circle.svg';
import TimesCircle from '@/icons/line/times-circle.svg';
import Copy from '@/icons/line/copy.svg';

type CopyButtonProps = Pick<ButtonProps, 'size' | 'variant'> & {
  label?: string;
  text: string;
};

defineProps<CopyButtonProps>();

const copied = ref(false);
const errored = ref(false);

const button = ref(null);

const computedIcon = computed(() => {
  if (!copied.value && !errored.value) {
    return Copy;
  }
  if (copied.value) {
    return CheckCircle;
  }
  if (errored.value) {
    return TimesCircle;
  }
});

function copyTextToClipboard(text: string) {
  navigator.clipboard.writeText(text).then(
    function () {
      copied.value = true;
      setTimeout(() => {
        copied.value = false;
      }, 3000);
    },
    function () {
      errored.value = true;
      setTimeout(() => {
        errored.value = false;
      }, 3000);
    }
  );
}
</script>

<template>
  <Button
    ref="button"
    :iconLeft="computedIcon"
    :size="size"
    :variant="variant"
    @click="copyTextToClipboard(text)"
  >
    {{ label ?? 'Copy' }}
  </Button>
</template>
