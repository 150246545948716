<script setup lang="ts">
import type { HybridlyFormData } from '@/@types/global';
import { Selectable, SelectableValue } from '@/components/selectBox/selectBox';

import EmptyCard from '@/components/emptyCard/EmptyCard.vue';
import SurveyBlockDate from '@/components/surveys/sessoins/blocks/SurveyBlockDate.vue';
import SurveyBlockEmail from '@/components/surveys/sessoins/blocks/SurveyBlockEmail.vue';
import SurveyBlockInstruction from '@/components/surveys/sessoins/blocks/SurveyBlockInstruction.vue';
import SurveyBlockMultiselect from '@/components/surveys/sessoins/blocks/SurveyBlockMultiselect.vue';
import SurveyBlockPhone from '@/components/surveys/sessoins/blocks/SurveyBlockPhone.vue';
import SurveyBlockRange from '@/components/surveys/sessoins/blocks/SurveyBlockRange.vue';
import SurveyBlockSelect from '@/components/surveys/sessoins/blocks/SurveyBlockSelect.vue';
import SurveyBlockText from '@/components/surveys/sessoins/blocks/SurveyBlockText.vue';
import SurveyBlockTextarea from '@/components/surveys/sessoins/blocks/SurveyBlockTextarea.vue';

export interface SurveyAnswerChoiceType {
  answer_id?: string | number | null;
  answer_option?: Selectable<SelectableValue> | null;
  answer_type?: string;
  survey_choice_id?: number | string;
}

const props = defineProps<{
  block: App.Surveys.Data.SurveyBlockData;
  errors?: Record<string, string>;
  form?: HybridlyFormData<{
    value: string;
    choices: SurveyAnswerChoiceType[];
  }>;
  surveySession?: App.Surveys.Data.SurveySessionData;
}>();

const isRequiredWithoutChoices = computed(() => {
  return (
    props.surveySession &&
    props.block.is_required &&
    props.block.choices_from_survey_block &&
    !props.block.choices?.length
  );
});

const isWithoutChoices = computed(() => {
  return (
    props.surveySession &&
    !props.block.is_required &&
    props.block.choices_from_survey_block &&
    !props.block.choices?.length
  );
});

function getBlockComponent(blockType: string) {
  return {
    boolean: SurveyBlockSelect,
    date: SurveyBlockDate,
    email: SurveyBlockEmail,
    instruction: SurveyBlockInstruction,
    multiselect: SurveyBlockMultiselect,
    phone: SurveyBlockPhone,
    range: SurveyBlockRange,
    select: SurveyBlockSelect,
    text: SurveyBlockText,
    textarea: SurveyBlockTextarea
  }[blockType];
}

function handleFormatAnswer(answer: SurveyAnswerChoiceType | SurveyAnswerChoiceType[]) {
  const choicesBlockTypes = ['select', 'multiselect', 'range', 'boolean'];

  if (choicesBlockTypes.includes(props.block.block_type.value)) {
    formatChoiceAnswer(answer);
    return;
  }

  formatValueAnswer(answer);
}

function formatChoiceAnswer(answer: SurveyAnswerChoiceType | SurveyAnswerChoiceType[]) {
  if (!props.form) {
    return;
  }

  if (!Array.isArray(answer)) {
    answer = [answer];
  }

  props.form.fields.choices = answer;
}

function formatValueAnswer(answer: SurveyAnswerChoiceType | SurveyAnswerChoiceType[]) {
  if (!props.form) {
    return;
  }

  props.form.fields.value = answer;
}
</script>

<template>
  <div class="flex flex-col gap-y-4">
    <h2 class="text-lg font-medium leading-5">{{ block.block_text }}</h2>
    <component
      v-if="!isRequiredWithoutChoices && !isWithoutChoices"
      :block="block"
      :choices="form?.fields.choices"
      :errors="errors"
      :is="getBlockComponent(block.block_type.value)"
      :value="form?.fields.value"
      @update:modelValue="(value) => handleFormatAnswer(value)"
    />
    <EmptyCard
      v-if="isRequiredWithoutChoices"
      :actionHref="
        route('survey.show', {
          surveySession: surveySession?.id,
          block: block.choices_from_survey_block?.id
        })
      "
      :description="`To answer this question, you need to select an option from the previous question: &quot;${block.choices_from_survey_block?.block_text}&quot;`"
      actionLabel="Go Back and Answer"
      class="bg-white"
      isDashed
      isRounded
      title="Missing Previous Answer"
      variant="invisible"
    />
    <EmptyCard
      v-if="isWithoutChoices"
      :actionHref="
        route('survey.show', {
          surveySession: surveySession?.id,
          block: block.choices_from_survey_block?.id
        })
      "
      :description="`You can answer this question by selecting an option from the previous question: &quot;${block.choices_from_survey_block?.block_text}&quot;, or you can skip it and move on.`"
      actionLabel="Go Back and Answer (Optional)"
      class="bg-white"
      isDashed
      isRounded
      title="Optional: Missing Previous Answer"
      variant="invisible"
    />
  </div>
</template>
