<script setup lang="ts">
import type { ButtonProps } from '@/components/button/Button.vue';

import { PopoverTrigger } from 'radix-vue';

import { ButtonColor } from '@/hooks/useButtonClasses';

import Button from '@/components/button/Button.vue';
import ButtonGroup from '@/components/buttonGroup/ButtonGroup.vue';
import Menu from '@/components/menu/Menu.vue';

import AngleDown from '@/icons/line/angle-down.svg';

withDefaults(
  defineProps<{
    buttonColor?: ButtonProps['color'];
    buttonHref?: string;
    buttonLabel: string;
    buttonSize?: ButtonProps['size'];
    buttonVariant?: ButtonProps['variant'];
    matchTriggerWidth?: boolean;
    block?: boolean;
    isOpen?: boolean;
  }>(),
  {
    buttonColor: ButtonColor.primary,
    buttonVariant: 'solid',
    block: false,
    isOpen: undefined
  }
);

const emit = defineEmits<{
  click: [void];
  onInteractOutside: [void];
}>();

const buttonGroupRef = ref<InstanceType<typeof ButtonGroup> | null>(null);

const computedButtonGroupWidth = computed<number>(() => buttonGroupRef.value?.$el.clientWidth || 0);
</script>

<template>
  <Menu
    :contentStyles="matchTriggerWidth ? { width: `${computedButtonGroupWidth}px` } : {}"
    :contentOptions="{ sideOffset: 4, align: 'end' }"
    :isOpen="isOpen"
    isContentAnchored
    @onInteractOutside="() => emit('onInteractOutside')"
  >
    <template #trigger>
      <PopoverTrigger asChild>
        <slot name="trigger">
          <Button
            :color="buttonColor"
            :href="buttonHref"
            :iconRight="AngleDown"
            :size="buttonSize"
            :variant="buttonVariant"
            :class="{
              'w-full justify-between': block
            }"
            @click="emit('click')"
          >
            <span class="block w-full text-center">{{ buttonLabel }}</span>
          </Button>
        </slot>
      </PopoverTrigger>
    </template>
    <slot />
  </Menu>
</template>
