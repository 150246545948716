<script setup lang="ts">
import { buildSelectableEnum, buildSelectableSchool } from '@/utils/buildSelectable';

import CenteredContent from '@/components/content/CenteredContent.vue';
import GoalForm from '@/components/goals/GoalForm.vue';

type GoalData = App.Configuration.Data.GoalData;
type EnumData = App.Base.Data.EnumData;

const props = defineProps<{
  goal: GoalData;
  types: EnumData[];
  grades: EnumData[];
}>();

const form = useForm({
  method: 'PUT',
  url: route('goals.update', { goal: props.goal.id }),
  fields: {
    goal_type: buildSelectableEnum(props.goal.goal_type),
    grade: buildSelectableEnum(props.goal.grade),
    goal_percentage: props.goal.goal_percentage || null,
    school_ids: (props.goal.schools || []).map((school) => buildSelectableSchool(school))
  },
  transform: (fields) => ({
    ...fields,
    goal_type: fields.goal_type?.value,
    grade: fields.grade?.value,
    school_ids: fields.school_ids.map((school) => school?.value)
  })
});
</script>


					<script lang="ts">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <CenteredContent>
    <GoalForm :form="form" :types="types" :grades="grades" isEditing />
  </CenteredContent>
</template>
