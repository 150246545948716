<script setup lang="ts">
import ImageUploadIcon from '@/icons/line/image-upload.svg';
import IconButton from '@/components/button/IconButton.vue';
import DataHookCombobox from '@/components/sms/DataHookCombobox.vue';
import DataHookData = App.Sms.Data.DataHookData;
import { ButtonProps } from '@/components/button/Button.vue';

withDefaults(
  defineProps<{
    isDisabled?: boolean;
    canUploadAttachments?: boolean;
    size?: ButtonProps['size'];
  }>(),
  {
    isDisabled: false,
    size: 'md',
    canUploadAttachments: true
  }
);

const emit = defineEmits<{
  filesPicked: [Event];
  insertDataHook: [hook: DataHookData];
}>();
</script>

<template>
  <div class="flex items-center">
    <template v-if="canUploadAttachments">
      <IconButton
        variant="invisible"
        color="slate"
        :icon="ImageUploadIcon"
        as="label"
        ariaLabel="Upload Attachments"
        :size
      >
        <input
          type="file"
          multiple
          @change="(fileList) => emit('filesPicked', fileList)"
          accept=".jpg,.png,.gif"
          class="sr-only"
        />
      </IconButton>
      <span aria-hidden="true" class="mr-2 h-5 w-[1px] bg-zinc-200" />
    </template>
    <DataHookCombobox @insert="(hook) => emit('insertDataHook', hook)" :size :isDisabled />
  </div>
</template>
