<script setup lang="ts">
const props = defineProps<{
  description?: string | number;
  hideIcon?: boolean;
  icon?: Component;
  slotPadding?: string;
  title: string;
}>();

const rawIcon = toRaw(props.icon);
</script>

<template>
  <dt class="flex items-start gap-2 py-2 text-sm font-medium leading-4 text-slate-500">
    <component
      v-if="!hideIcon"
      :is="rawIcon ?? 'div'"
      class="h-4 w-4 flex-shrink-0 text-slate-500"
    />
    {{ title }}
  </dt>
  <dd class="flex" :class="[slotPadding ?? 'py-1']">
    <slot>
      <span class="inline-block py-1 text-sm leading-4 text-slate-900">{{ description }}</span>
    </slot>
  </dd>
</template>
