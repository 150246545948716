<script setup lang="tsx">
type EnumData = App.Base.Data.EnumData;
type StudentData = App.Students.Data.StudentData;
type ApplicationData = App.Students.Data.ApplicationData;
type TestData = App.Students.Data.TestData;
type StudentScholarshipData = App.Students.Data.StudentScholarshipData;

import { AppliedFilter, AppliedFilters } from '@/components/distiller/types';
import type { CustomCells } from '@/components/table/Table';
import type { Selectable } from '@/components/selectBox/selectBox';

import { ButtonColor, ButtonSize, ButtonVariant } from '@/hooks/useButtonClasses';
import { useAuth } from '@/hooks/useAuth';
import { useHybridlyTable } from '@/hooks/useHybridlyTable';
import { useSchoolsSearch } from '@/hooks/search/useSchoolsSearch';

import BulkCreateStudentInteractionDialog from '@/domains/students/components/dialogs/BulkCreateStudentInteractionDialog.vue';
import Button from '@/components/button/Button.vue';
import ComboboxInput from '@/components/selectBox/combobox/ComboboxInput.vue';
import EmptyState from '@/components/emptyState/EmptyState.vue';
import IndexPageLayout from '@/components/pageLayout/IndexPageLayout.vue';
import TableBadgesCell from '@/components/table/TableBadgesCell.vue';
import TableDateCell from '@/components/table/TableDateCell.vue';
import TableDateTimeCell from '@/components/table/TableDateTimeCell.vue';
import TableEnumCell from '@/components/table/TableEnumCell.vue';
import TableTruncatedTextCell from '@/components/table/TableTruncatedTextCell.vue';

import Plus from '@/icons/line/plus.svg';
import UserCircle from '@/icons/line/user-circle.svg';

const props = defineProps<{
  appliedFilters: AppliedFilters;
  interactions: App.Students.Data.InteractionData[];
  distiller: App.Search.Data.DistillerData;
  defaultSchool: App.Configuration.Data.SchoolData;
}>();

const selectedSchool = ref<Selectable<number> | null>(
  buildSelectableSchool(props.defaultSchool) ?? null
);

const { hasPermission } = useAuth();
const { schoolOptions, isLoading, onQueryChange } = useSchoolsSearch();
const { table } = useHybridlyTable<App.Configuration.Data.CustomTestData>(props, 'interactions');
const [isInteractionOpen, toggleInteractionOpen] = useToggle(false);

const customCells = {
  students: ({ row, column }) => (
    <TableBadgesCell
      badgesToDisplay={2}
      itemIcon={UserCircle}
      items={row.value(column.key).map((student: StudentData) => student.name)}
    />
  ),
  date: ({ row, column }) => <TableDateCell date={row.value(column.key)} />,
  types: ({ row, column }) => (
    <TableBadgesCell
      badgesToDisplay={2}
      items={row.value(column.key).map((user: EnumData) => user.label)}
    />
  ),
  topics: ({ row, column }) => (
    <TableBadgesCell
      badgesToDisplay={2}
      items={row.value(column.key).map((user: EnumData) => user.label)}
    />
  ),
  notes: ({ row, column }) => <TableTruncatedTextCell text={row.value(column.key)} />,
  medium: ({ row, column }) => <TableEnumCell enumData={row.value(column.key)} />,
  meeting_length: ({ row, column }) => <TableEnumCell enumData={row.value(column.key)} />,
  applications: ({ row, column }) => (
    <TableBadgesCell
      badgesToDisplay={2}
      items={row
        .value(column.key)
        .map((application: ApplicationData) => application?.institute?.name)}
    />
  ),
  tests: ({ row, column }) => (
    <TableBadgesCell
      badgesToDisplay={2}
      items={row.value(column.key).map((test: TestData) => test?.type)}
    />
  ),
  studentScholarships: ({ row, column }) => (
    <TableBadgesCell
      badgesToDisplay={2}
      items={row
        .value(column.key)
        .map(
          (studentScholarships: StudentScholarshipData) => studentScholarships?.scholarship?.name
        )}
    />
  ),
  created_at: ({ row, column }) => <TableDateTimeCell date={row.value(column.key)} />
} as CustomCells<App.Configuration.Data.CustomTestData>;

function loadInteractions() {
  router.reload({
    method: 'get',
    data: {
      school_id: selectedSchool.value?.value
    },
    only: ['interactions']
  });
}

onMounted(() => {
  onQueryChange('');
});

function onSelectSchool(opt: Selectable<number> | null) {
  selectedSchool.value = opt;
  loadInteractions();
}

const scopedAppliedFilters = computed<AppliedFilters | undefined>(() => {
  return selectedSchool?.value?.value
    ? {
        student: {
          operator: 'has',
          value: null,
          school: {
            operator: 'in',
            value: [{ value: selectedSchool.value.value }]
          }
        } as unknown as AppliedFilter
      }
    : undefined;
});
</script>


					<script lang="tsx">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <IndexPageLayout
    recordType="interactions"
    title="Interactions"
    :appliedFilters="appliedFilters"
    :scopedAppliedFilters="scopedAppliedFilters"
    :customCells="customCells"
    :distiller="distiller"
    :selectionsEnabled="false"
    :stickyEndColumns="1"
    :stickyStartColumns="2"
    :table="table"
  >
    <template #title>
      <ComboboxInput
        inputClasses="border-none"
        contentWidth="max-w-sm w-full"
        :isLoading="isLoading"
        :options="schoolOptions"
        :modelValue="selectedSchool"
        :onFilter="onQueryChange"
        placeholder="Loading..."
        @update:modelValue="onSelectSchool"
      >
        <template #trigger="triggerSlotProps">
          <p class="mr-1 truncate text-lg font-bold leading-5 text-slate-800">
            {{
              triggerSlotProps.modelValue?.label
                ? `${triggerSlotProps.modelValue?.label} Interactions`
                : triggerSlotProps.placeholder
            }}
          </p>
        </template>
      </ComboboxInput>
    </template>
    <template #rightButtons>
      <Button
        v-if="hasPermission('create-interaction')"
        :color="ButtonColor.primary"
        :iconLeft="Plus"
        :size="ButtonSize.sm"
        :variant="ButtonVariant.solid"
        @click="toggleInteractionOpen()"
      >
        Add a new interaction
      </Button>
    </template>
    <template #emptyState>
      <EmptyState
        @click="toggleInteractionOpen()"
        actionLabel="Add a new interaction"
        description="Interactions will appear on this screen"
        title="No interactions found"
      />
    </template>
  </IndexPageLayout>
  <BulkCreateStudentInteractionDialog
    v-if="isInteractionOpen"
    isOpen
    @onCancel="() => toggleInteractionOpen()"
    @onClose="() => toggleInteractionOpen()"
  />
</template>
