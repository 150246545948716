<script setup lang="ts">
import ComboboxInput from '@/components/selectBox/combobox/ComboboxInput.vue';
import { useProgramsSearch } from '@/hooks/search/useProgramsSearch';
import { Selectable, SelectableValue } from '@/components/selectBox/selectBox';
import { CssClass } from '@/@types/global';

const { programOptions, isLoading: isSearching, onQueryChange } = useProgramsSearch();

defineProps<{
  modelValue: Selectable<SelectableValue> | null;
  inputClasses?: CssClass;
  isLoading?: boolean;
}>();

defineEmits(['update:modelValue']);
</script>

<template>
  <ComboboxInput
    :options="programOptions"
    :onFilter="onQueryChange"
    :isLoading="isLoading || isSearching"
    :modelValue="modelValue"
    :inputClasses="inputClasses"
    @update:modelValue="$emit('update:modelValue', $event)"
    @open="() => onQueryChange('')"
  />
</template>
