<script setup lang="tsx" generic="T extends Record<string, unknown>">
import type { TableColumn } from '@/components/table/Table';
import PaddedCellContent from '@/components/table/PaddedCellContent.vue';
import Check from '@/icons/line/check.svg';
import SearchableGroupedListboxes from '@/components/selectBox/listbox/SearchableGroupedListboxes.vue';
import Arrow from '@/icons/line/arrow-up.svg';
import Columns from '@/icons/line/columns.svg';
import ExclamationCircle from '@/icons/line/exclamation-circle.svg';
import Dialog from '@/components/dialog/Dialog.vue';
import invert from 'lodash/invert';
import GroupedListBox from '@/components/selectBox/listbox/GroupedListBox.vue';

defineOptions({
  inheritAttrs: false
});

const props = defineProps<{
  column: TableColumn<T>;
  sort?: string;
  uploadSessionId: App.Bulkinator.Data.UploadSessionData['id'];
  columnMappings: App.Bulkinator.Data.UploadSessionData['column_mappings'];
  fileHeaders: string[];
  availableColumns: App.Bulkinator.Data.UploadColumnData[];
  isMappable: boolean;
}>();

const emit = defineEmits<{
  sort: [string | null];
}>();

const hoverableElement = ref();
const isHovered = useElementHover(hoverableElement);

/**
 * UploadSession.column_mappings is usually keyed by File Header (lowercase)
 * We're inverting it so it's keyed by the Column.name
 */
const columnMappingsKeyedByColumn = computed(() => invert(props.columnMappings));
const mappedColumnName = computed(() => props.columnMappings[props.column.key]);
const alreadyMappedColumnDialogTrigger = ref<string | null>(null);

const groups = computed(() => {
  const result = [
    {
      key: 'sort',
      label: 'Sort option',
      icon: Arrow,
      options: [
        { label: 'Sort A-Z', value: props.column.key },
        { label: 'Sort Z-A', value: `-${props.column.key}` }
      ]
    }
  ];

  if (props.isMappable) {
    result.push({
      key: 'match',
      label: 'Matching column',
      icon: Columns,
      options: props.availableColumns.map((col) => ({
        label: col.name,
        value: col.name
      }))
    });
  }

  return result;
});

const form = useForm<{
  match: string | null;
  sort: string | null;
}>({
  method: 'PUT',
  url: route('upload-sessions.columns.update', { uploadSession: props.uploadSessionId }),
  fields: {
    sort: getSort(props.sort),
    match: mappedColumnName.value ?? null
  },
  only: ['uploadRecords', 'sort', 'uploadSession'],
  reset: false,
  transform: ({ match }) => ({
    column_mappings: updateColumnMapping(match)
  }),
  hooks: {
    success() {
      closeDialog();
    }
  }
});

function isColumnAlreadyMapped(col: string | null): boolean {
  if (!col) {
    return false;
  }
  return !!columnMappingsKeyedByColumn.value[col];
}

function updateColumnMapping(col: string | null) {
  const newMapping = {};

  // we're un-setting this header
  if (!col) {
    newMapping[props.column.key] = null;
  } else if (col) {
    const swapHeader = columnMappingsKeyedByColumn.value[col];

    // set the new value
    newMapping[props.column.key] = col;

    if (swapHeader) {
      newMapping[swapHeader] = mappedColumnName.value ?? null;
    }
  }

  return newMapping;
}

function openDialog(column: string) {
  const matchedColumn = props.availableColumns.find((col) => col.name === column);

  if (matchedColumn) {
    alreadyMappedColumnDialogTrigger.value = matchedColumn.name;
  }
}

function closeDialog() {
  alreadyMappedColumnDialogTrigger.value = null;
}

function confirmDialog() {
  form.submit();
}

function cancelDialog() {
  alreadyMappedColumnDialogTrigger.value = null;
  form.fields.match = mappedColumnName?.value ?? null;
}

function handleChange(group: string, value: string | null) {
  form.fields[group] = value;
  if (group === 'sort') {
    emit('sort', value);
  } else if (group === 'match') {
    if (value && isColumnAlreadyMapped(value)) {
      openDialog(value);
    } else {
      form.submit();
    }
  }
}

function getSort(sort?: string | null) {
  return sort === props.column.key || sort === `-${props.column.key}` ? sort : null;
}

watch(props, (newProps) => {
  if (newProps.sort !== form.fields.sort) {
    form.fields.sort = getSort(newProps.sort);
  }
});

watch(mappedColumnName, (newMappedFileHeader) => {
  form.fields.match = newMappedFileHeader ?? null;
});
</script>

<template>
  <PaddedCellContent class="group/upload-session-header-cell" ref="hoverableElement"
    ><div class="flex flex-row items-center justify-between">
      <span class="whitespace-nowrap">{{ column.label ?? '' }}</span>
      <div class="flex flex-row items-center gap-x-2">
        <div
          v-if="!!mappedColumnName && !isHovered"
          class="flex h-7 w-7 items-center justify-center"
        >
          <Check class="h-4 w-4 text-green-600" />
        </div>

        <!-- I want to fully unmount the listbox when hover ends so the listbox's dropdown won't remain open -->
        <!-- hence JS over CSS for hover so I can conditionally render the underlying listbox -->
        <SearchableGroupedListboxes
          v-if="isHovered"
          :groups="groups"
          :hideSearch="groups.length === 1"
        >
          <template #group="{ group }">
            <GroupedListBox
              class="normal-case"
              :group="group"
              :modelValue="form.fields[group.key]"
              @update:modelValue="(v) => handleChange(group.key, v)"
            />
          </template>
        </SearchableGroupedListboxes>
      </div>

      <Dialog
        :isOpen="!!alreadyMappedColumnDialogTrigger"
        :icon="ExclamationCircle"
        title="Confirm column header mapping"
        :isLoading="form.processing"
        variant="warning"
        confirmButtonLabel="Confirm column header mapping modification"
        @onConfirm="confirmDialog"
        @onClose="closeDialog"
        @onCancel="cancelDialog"
      >
        <template #message>
          <span className="font-bold">{{ alreadyMappedColumnDialogTrigger }}</span> is already
          mapped to another column. Confirming the mapping will swap these two columns.
        </template>
      </Dialog>
    </div>
  </PaddedCellContent>
</template>
