import { Selectable } from '@/components/selectBox/selectBox';

export const states = [
  {
    state: 'Alabama',
    state_abbreviation: 'AL'
  },
  {
    state: 'Alaska',
    state_abbreviation: 'AK'
  },
  {
    state: 'Arizona',
    state_abbreviation: 'AZ'
  },
  {
    state: 'Arkansas',
    state_abbreviation: 'AR'
  },
  {
    state: 'California',
    state_abbreviation: 'CA'
  },
  {
    state: 'Colorado',
    state_abbreviation: 'CO'
  },
  {
    state: 'Connecticut',
    state_abbreviation: 'CT'
  },
  {
    state: 'Delaware',
    state_abbreviation: 'DE'
  },
  {
    state: 'District',
    state_abbreviation: 'DC'
  },
  {
    state: 'Florida',
    state_abbreviation: 'FL'
  },
  {
    state: 'Georgia',
    state_abbreviation: 'GA'
  },
  {
    state: 'Hawaii',
    state_abbreviation: 'HI'
  },
  {
    state: 'Idaho',
    state_abbreviation: 'ID'
  },
  {
    state: 'Illinois',
    state_abbreviation: 'IL'
  },
  {
    state: 'Indiana',
    state_abbreviation: 'IN'
  },
  {
    state: 'Iowa',
    state_abbreviation: 'IA'
  },
  {
    state: 'Kansas',
    state_abbreviation: 'KS'
  },
  {
    state: 'Kentucky',
    state_abbreviation: 'KY'
  },
  {
    state: 'Louisiana',
    state_abbreviation: 'LA'
  },
  {
    state: 'Maine',
    state_abbreviation: 'ME'
  },
  {
    state: 'Maryland',
    state_abbreviation: 'MD'
  },
  {
    state: 'Massachusetts',
    state_abbreviation: 'MA'
  },
  {
    state: 'Michigan',
    state_abbreviation: 'MI'
  },
  {
    state: 'Minnesota',
    state_abbreviation: 'MN'
  },
  {
    state: 'Mississippi',
    state_abbreviation: 'MS'
  },
  {
    state: 'Missouri',
    state_abbreviation: 'MO'
  },
  {
    state: 'Montana',
    state_abbreviation: 'MT'
  },
  {
    state: 'Nebraska',
    state_abbreviation: 'NE'
  },
  {
    state: 'Nevada',
    state_abbreviation: 'NV'
  },
  {
    state: 'New',
    state_abbreviation: 'NH'
  },
  {
    state: 'New',
    state_abbreviation: 'NJ'
  },
  {
    state: 'New',
    state_abbreviation: 'NM'
  },
  {
    state: 'New',
    state_abbreviation: 'NY'
  },
  {
    state: 'North',
    state_abbreviation: 'NC'
  },
  {
    state: 'North',
    state_abbreviation: 'ND'
  },
  {
    state: 'Ohio',
    state_abbreviation: 'OH'
  },
  {
    state: 'Oklahoma',
    state_abbreviation: 'OK'
  },
  {
    state: 'Oregon',
    state_abbreviation: 'OR'
  },
  {
    state: 'Pennsylvania',
    state_abbreviation: 'PA'
  },
  {
    state: 'Rhode',
    state_abbreviation: 'RI'
  },
  {
    state: 'South',
    state_abbreviation: 'SC'
  },
  {
    state: 'South',
    state_abbreviation: 'SD'
  },
  {
    state: 'Tennessee',
    state_abbreviation: 'TN'
  },
  {
    state: 'Texas',
    state_abbreviation: 'TX'
  },
  {
    state: 'Utah',
    state_abbreviation: 'UT'
  },
  {
    state: 'Vermont',
    state_abbreviation: 'VT'
  },
  {
    state: 'Virginia',
    state_abbreviation: 'VA'
  },
  {
    state: 'Washington',
    state_abbreviation: 'WA'
  },
  {
    state: 'West',
    state_abbreviation: 'WV'
  },
  {
    state: 'Wisconsin',
    state_abbreviation: 'WI'
  },
  {
    state: 'Wyoming',
    state_abbreviation: 'WY'
  }
];

export interface SelectableState extends Selectable<string> {
  state_abbreviation: string;
}

export function stateOptions(): Array<SelectableState> {
  return [...states.values()].map(({ state, state_abbreviation }) => ({
    label: state,
    value: state,
    state_abbreviation
  }));
}
