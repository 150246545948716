<script setup lang="ts">
import { ButtonColor, ButtonSize, ButtonVariant } from '@/hooks/useButtonClasses';

import AuthForm from '@/components/form/AuthForm.vue';
import StackedFormFooter from '@/components/formFooter/StackedFormFooter.vue';
import FormTextInput from '@/components/formTextInput/FormTextInput.vue';
import Button from '@/components/button/Button.vue';
import AuthFormWrapper from '@/components/auth/AuthFormWrapper.vue';
import FormListboxInput from '@/components/formListboxInput/FormListboxInput.vue';
import { Selectable } from '@/components/selectBox/selectBox';

const channelTypes = [
  {
    label: 'SMS',
    value: 'sms'
  },
  {
    label: 'Email',
    value: 'email'
  }
] as Selectable<string>[];

const form = useForm({
  method: 'POST',
  url: route('two-factor.guest.channel.store'),
  fields: {
    type: channelTypes[0],
    destination: ''
  },
  transform: (fields) => ({
    ...fields,
    type: fields.type.value
  })
});

const isSms = computed(() => form.fields.type.value === 'sms');

function handleTypeChange(value: Selectable<string> | null) {
  form.fields.type = value as Selectable<string>;
  form.fields.destination = '';
}

function handleSubmit() {
  form.submit();
}
</script>


					<script lang="ts">
					import __hybridly_layout_0 from '~/resources/domains/auth/layouts/one-column-guest.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <AuthFormWrapper
    title="Set up two-step verification"
    description="Two-step verification protects your account by requiring an additional code when you log in on a device or location that we do not recognize."
  >
    <AuthForm @submit.prevent="handleSubmit">
      <FormListboxInput
        label="Device type"
        name="type"
        :options="channelTypes"
        :error="form.errors.type as string"
        :modelValue="form.fields.type"
        @update:modelValue="handleTypeChange"
      />

      <FormTextInput
        required
        :label="isSms ? 'Phone number' : 'Email address'"
        :placeholder="
          isSms
            ? 'Enter your phone number'
            : 'Enter your email address (not your GRACE email address)'
        "
        name="destination"
        :type="isSms ? 'tel' : 'email'"
        :error="form.errors.destination"
        :modelValue="form.fields.destination"
        @update:modelValue="(value) => (form.fields.destination = value as string)"
      />

      <template #footer>
        <StackedFormFooter>
          <Button
            :color="ButtonColor.primary"
            :isDisabled="!form.isDirty"
            :isLoading="form.processing"
            :size="ButtonSize.lg"
            :variant="ButtonVariant.solid"
            type="submit"
            >Send two-step verification code</Button
          >
        </StackedFormFooter>
      </template>
    </AuthForm>
  </AuthFormWrapper>
</template>
