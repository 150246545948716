<script setup lang="ts">
type EnumData = App.Base.Data.EnumData;
type StudentData = App.Students.Data.StudentData;

import type { CheckedValue } from '@/components/checkboxInput/CheckboxInput.vue';
import type { Selectable } from '@/components/selectBox/selectBox';

import { useAnchorScroll } from '@/hooks/useAnchorScroll';

import { buildSelectableEnum, buildSelectableGroup } from '@/utils/buildSelectable';

import CenteredContent from '@/components/content/CenteredContent.vue';
import Divider from '@/components/divider/Divider.vue';
import Fieldset from '@/components/fieldset/Fieldset.vue';
import FormCheckboxInput from '@/components/formCheckboxInput/FormCheckboxInput.vue';
import FormDateInput from '@/components/formDateInput/FormDateInput.vue';
import FormError from '@/components/formError/FormError.vue';
import FormInlineFields from '@/components/formInlineFields/FormInlineFields.vue';
import FormListboxInput from '@/components/formListboxInput/FormListboxInput.vue';
import FormMultiComboboxInput from '@/components/formComboboxInput/FormMultiComboboxInput.vue';
import FormMultiStudentGroupInput from '@/components/formComboboxInput/FormMultiStudentGroupInput.vue';
import FormTextInput from '@/components/formTextInput/FormTextInput.vue';
import StudentFormWrapper from '@/components/students/StudentFormWrapper.vue';

const props = defineProps<{
  grades: EnumData[];
  postSecondaryPlans: EnumData[];
  statuses: EnumData[];
  student: StudentData;
  returnUrl?: string;
}>();

useAnchorScroll();

const form = useForm({
  method: 'PUT',
  url: route('students.edit.enrollment-details', { student: props.student.id }),
  updateInitials: true,
  fields: {
    enroll_date: props.student.enroll_date,
    status: props.student.status as Selectable<string> | null,
    groups: (props.student.groups || []).map((group) => buildSelectableGroup(group)),
    post_secondary_plans: (props.student.post_secondary_plans || []).map((post_secondary_plan) =>
      buildSelectableEnum(post_secondary_plan.post_secondary_plan)
    ),
    grade: props.student.grade as Selectable<number> | null,
    class_of: props.student.class_of,
    first_gen: props.student.first_gen as CheckedValue,
    free_lunch: props.student.free_lunch
  },
  transform: (fields) => ({
    ...fields,
    status: form.fields.status?.value,
    groups: fields.groups.map((group) => group?.value),
    post_secondary_plans: fields.post_secondary_plans.map(
      (post_secondary_plan) => post_secondary_plan?.value
    ),
    grade: form.fields.grade?.value
  }),
  hooks: {
    error() {
      form.resetFields('groups');
    }
  }
});
</script>


					<script lang="ts">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <CenteredContent>
    <StudentFormWrapper
      isEditing
      :form="form"
      :isDirty="form.isDirty"
      :isProcessing="form.processing"
      :returnUrl
      :studentId="student.id"
      @onSubmit="form.submit"
    >
      <div class="flex flex-col gap-8">
        <Fieldset title="Enrollment details">
          <FormDateInput
            :clearable="false"
            :error="form.errors.enroll_date"
            :modelValue="form.fields.enroll_date"
            label="Enroll Date"
            name="enroll_date"
            placeholder="e.g. 10/15/2024"
            @update:modelValue="(value) => (form.fields.enroll_date = value)"
          />
          <FormListboxInput
            :error="form.errors.status"
            :options="statuses"
            :modelValue="form.fields.status"
            label="Enrollment Status"
            @update:modelValue="(value) => (form.fields.status = value)"
          />
          <div class="flex flex-col gap-y-1.5">
            <FormMultiStudentGroupInput
              :modelValue="form.fields.groups"
              label="Groups"
              @update:modelValue="(value) => (form.fields.groups = value)"
            />
            <template v-if="form.errors.groups">
              <FormError v-for="error in form.errors.groups" :key="error" :error="error" />
            </template>
          </div>
          <FormMultiComboboxInput
            :error="form.errors.post_secondary_plans"
            :modelValue="form.fields.post_secondary_plans"
            :options="postSecondaryPlans"
            label="Post-secondary plans"
            @update:modelValue="(value) => (form.fields.post_secondary_plans = value)"
          />
          <FormInlineFields>
            <FormListboxInput
              :error="form.errors.grade"
              :options="grades"
              :modelValue="form.fields.grade"
              label="Grade"
              @update:modelValue="(value) => (form.fields.grade = value)"
            />
            <FormTextInput
              :error="form.errors.class_of"
              :modelValue="form.fields.class_of"
              label="Class of"
              name="class_of"
              placeholder="e.g. 2024"
              type="number"
              @update:modelValue="(value) => (form.fields.class_of = value)"
            />
          </FormInlineFields>
        </Fieldset>
        <Divider />
        <Fieldset title="First generation student">
          <FormCheckboxInput
            :checked="form.fields.first_gen"
            :error="form.errors.first_gen"
            label="This student is a first generation student"
            helperText="Many educational institutions and programs offer support services and resources specifically designed to assist first-generation students in their academic journey."
            name="first_gen"
            @update:checked="(value) => (form.fields.first_gen = value)"
          />
        </Fieldset>
        <Fieldset id="fr-price-lunch" title="F/R price lunch">
          <FormCheckboxInput
            :checked="form.fields.free_lunch"
            :error="form.errors.free_lunch"
            label="This student qualifies and/or receives free and/or reduced price meals at school."
            name="free_lunch"
            @update:checked="(value) => (form.fields.free_lunch = value)"
          />
        </Fieldset>
      </div>
    </StudentFormWrapper>
  </CenteredContent>
</template>
