<script setup lang="tsx">
import type { CustomCells, HybridlyRow } from '@/components/table/Table';
import { AppliedFilters } from '@/components/distiller/types';

import { ButtonColor, ButtonSize, ButtonVariant } from '@/hooks/useButtonClasses';
import { useFilters } from '@/hooks/useFilters';
import { useHybridlyTable } from '@/hooks/useHybridlyTable';
import { useTableRowClick } from '@/hooks/useTableRowClick';

import BulkRestoreButton from '@/components/table/BulkRestoreButton.vue';
import BulkTrashButton from '@/components/table/BulkTrashButton.vue';
import Button from '@/components/button/Button.vue';
import EmptyState from '@/components/emptyState/EmptyState.vue';
import IndexPageLayout from '@/components/pageLayout/IndexPageLayout.vue';
import PaddedCellContent from '@/components/table/PaddedCellContent.vue';
import TableBadgesCell from '@/components/table/TableBadgesCell.vue';
import TabNavDivider from '@/components/tabNav/TabNavDivider.vue';
import TabNavItem from '@/components/tabNav/TabNavItem.vue';

import Plus from '@/icons/line/plus.svg';
import IconGraduationCap from '@/icons/line/graduation-cap.svg';

type StudentParentRow = HybridlyRow<App.Students.Data.StudentParentData>;

const props = defineProps<{
  appliedFilters: AppliedFilters;
  distiller: App.Search.Data.DistillerData;
  parents: Table<App.Students.Data.StudentParentData>;
  trashActive: boolean;
  counts: {
    active_count: number;
    trashed_count: number;
  };
}>();

const { table, tableSelectionState } = useHybridlyTable<App.Students.Data.StudentParentData>(
  props,
  'parents'
);

const { encodeFilters } = useFilters();
const { handleRowClick } = useTableRowClick();

const encodedFilters = computed(() => encodeFilters(props.appliedFilters));

const customCells = {
  students: ({ row, column }) =>
    row.value(column.key) ? (
      <TableBadgesCell
        badgesToDisplay={2}
        itemIcon={IconGraduationCap}
        items={row.value(column.key).map((student: App.Students.Data.StudentData) => ({
          label: student.name,
          href: route('students.show', { student: student.id })
        }))}
      />
    ) : (
      <PaddedCellContent>--</PaddedCellContent>
    )
} as CustomCells<App.Students.Data.StudentParentData>;

function handleStudentParentRowClick(event: MouseEvent, row: StudentParentRow) {
  if ((event.target as Element)?.closest('a')) {
    return;
  }

  const url = route('parents.show', {
    parent: row.key
  });
  handleRowClick(url, event);
}
</script>


					<script lang="tsx">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <IndexPageLayout
    title="Parents/Guardians"
    :table="table"
    :appliedFilters="appliedFilters"
    :distiller="distiller"
    :customCells="customCells"
    :stickyEndColumns="1"
    :stickyStartColumns="1"
    :clickableRows="!trashActive"
    :tableSelectionState="tableSelectionState"
    @clickRow="($event, row) => handleStudentParentRowClick($event, row as StudentParentRow)"
  >
    <template #rightButtons>
      <template v-if="tableSelectionState.hasSelection">
        <BulkTrashButton
          v-if="!trashActive"
          recordType="Parents/Guardians"
          :route="route('parents.bulk-delete')"
          :tableSelectionState="tableSelectionState"
        />
        <BulkRestoreButton
          v-if="trashActive"
          recordType="Parents/Guardians"
          :route="route('parents.bulk-restore')"
          :tableSelectionState="tableSelectionState"
        />
      </template>

      <template v-else>
        <Button
          :color="ButtonColor.primary"
          :href="route('parents.create')"
          :iconLeft="Plus"
          :size="ButtonSize.sm"
          :variant="ButtonVariant.solid"
        >
          Add a parent/guardian
        </Button>
      </template>
    </template>

    <template #tabs>
      <TabNavItem
        :badgeLabel="counts.active_count"
        :isActive="!trashActive"
        label="Active"
        variant="inline"
        :href="
          route('parents.index', {
            ...(encodedFilters ? { filters: encodedFilters } : {})
          })
        "
      />
      <TabNavDivider layout="inline" />
      <TabNavItem
        :badgeLabel="counts.trashed_count ?? 0"
        :isActive="trashActive"
        label="Trashed"
        variant="inline"
        :href="
          route('parents.index', {
            trashed: true,
            ...(encodedFilters ? { filters: encodedFilters } : {})
          })
        "
      />
    </template>

    <template #emptyState>
      <EmptyState
        v-if="trashActive"
        title="No parents/guardians have been moved to the trash"
        description="Trashed parents/guardians will appear on this screen"
      />
      <EmptyState
        v-else
        title="No parents/guardians found"
        description="Create a new parent/guardian by clicking the button below"
        actionLabel="Add a parent/guardian"
        :actionHref="route('parents.create')"
      />
    </template>
  </IndexPageLayout>
</template>
