<script setup lang="tsx">
import type { CustomCells, HybridlyRow } from '@/components/table/Table';
import { AppliedFilters } from '@/components/distiller/types';

import { ButtonColor, ButtonSize, ButtonVariant } from '@/hooks/useButtonClasses';
import { useFilters } from '@/hooks/useFilters';
import { useHybridlyTable } from '@/hooks/useHybridlyTable';
import { useTableRowClick } from '@/hooks/useTableRowClick';

import BulkRestoreButton from '@/components/table/BulkRestoreButton.vue';
import BulkTrashButton from '@/components/table/BulkTrashButton.vue';
import Button from '@/components/button/Button.vue';
import EmptyState from '@/components/emptyState/EmptyState.vue';
import IndexPageLayout from '@/components/pageLayout/IndexPageLayout.vue';
import PaddedCellContent from '@/components/table/PaddedCellContent.vue';
import TableBadgesCell from '@/components/table/TableBadgesCell.vue';
import TableDateTimeCell from '@/components/table/TableDateTimeCell.vue';
import TableTruncatedTextCell from '@/components/table/TableTruncatedTextCell.vue';
import TabNavDivider from '@/components/tabNav/TabNavDivider.vue';
import TabNavItem from '@/components/tabNav/TabNavItem.vue';

import Building from '@/icons/line/building.svg';
import Plus from '@/icons/line/plus.svg';

type StudentRow = HybridlyRow<App.Students.Data.StudentData>;

const props = defineProps<{
  appliedFilters: AppliedFilters;
  distiller: App.Search.Data.DistillerData;
  studentGroups: Table<App.Students.Data.StudentGroupData>;
  trashActive: boolean;
  counts: {
    active_count: number;
    trashed_count: number;
  };
}>();

const { table, tableSelectionState } = useHybridlyTable<App.Students.Data.StudentGroupData>(
  props,
  'studentGroups'
);

const { encodeFilters } = useFilters();
const { handleRowClick } = useTableRowClick();

const encodedFilters = computed(() => encodeFilters(props.appliedFilters));

const customCells = {
  name: ({ row, column }) => <TableTruncatedTextCell text={row.value(column.key)} />,
  school: ({ row, column }) =>
    row.value(column.key) ? (
      <TableBadgesCell
        badgesToDisplay={1}
        itemIcon={Building}
        items={[row.value(column.key)?.name]}
      />
    ) : (
      <PaddedCellContent>--</PaddedCellContent>
    ),
  created_at: ({ row, column }) => <TableDateTimeCell date={row.value(column.key)} />
} as CustomCells<App.Students.Data.StudentGroupData>;

function handleStudentGroupRowClick(event: MouseEvent, row: StudentRow) {
  const url = route('student-groups.show', {
    studentGroup: row.key
  });
  handleRowClick(url, event);
}
</script>


					<script lang="tsx">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <IndexPageLayout
    title="Student Groups"
    :table="table"
    :appliedFilters="appliedFilters"
    :distiller="distiller"
    :customCells="customCells"
    :stickyEndColumns="1"
    :stickyStartColumns="1"
    :clickableRows="!trashActive"
    :tableSelectionState="tableSelectionState"
    @clickRow="($event, row) => handleStudentGroupRowClick($event, row as StudentRow)"
  >
    <template #rightButtons>
      <template v-if="tableSelectionState.hasSelection">
        <BulkTrashButton
          v-if="!trashActive"
          recordType="Student Groups"
          :route="route('student-groups.bulk-delete')"
          :tableSelectionState="tableSelectionState"
        />
        <BulkRestoreButton
          v-if="trashActive"
          recordType="Student Groups"
          :route="route('student-groups.bulk-restore')"
          :tableSelectionState="tableSelectionState"
        />
      </template>

      <template v-else>
        <Button
          :color="ButtonColor.primary"
          :href="route('student-groups.create')"
          :iconLeft="Plus"
          :size="ButtonSize.sm"
          :variant="ButtonVariant.solid"
        >
          Add a new student group
        </Button>
      </template>
    </template>

    <template #tabs>
      <TabNavItem
        :badgeLabel="counts.active_count"
        :isActive="!trashActive"
        label="Active"
        variant="inline"
        :href="
          route('student-groups.index', {
            ...(encodedFilters ? { filters: encodedFilters } : {})
          })
        "
      />
      <TabNavDivider layout="inline" />
      <TabNavItem
        :badgeLabel="counts.trashed_count ?? 0"
        :isActive="trashActive"
        label="Trashed"
        variant="inline"
        :href="
          route('student-groups.index', {
            trashed: true,
            ...(encodedFilters ? { filters: encodedFilters } : {})
          })
        "
      />
    </template>

    <template #emptyState>
      <EmptyState
        v-if="trashActive"
        title="No student groups were moved to the trash"
        description="Trash student groups will appear on this screen"
      />
      <EmptyState
        v-else
        title="No student groups found"
        description="Create a new student group by clicking the button below"
        actionLabel="Add a student group"
        :actionHref="route('student-groups.create')"
      />
    </template>
  </IndexPageLayout>
</template>
