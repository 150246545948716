<script setup lang="ts">
type EnumData = App.Base.Data.EnumData;

import type { HybridlyFormData } from '@/@types/global';
import type { Selectable } from '@/components/selectBox/selectBox';

import { ButtonColor, ButtonSize, ButtonVariant } from '@/hooks/useButtonClasses';

import Button from '@/components/button/Button.vue';
import DangerDialog from '@/components/dialog/DangerDialog.vue';
import Divider from '@/components/divider/Divider.vue';
import Fieldset from '@/components/fieldset/Fieldset.vue';
import Form from '@/components/form/Form.vue';
import FormCheckboxInput from '@/components/formCheckboxInput/FormCheckboxInput.vue';
import FormDateInput from '@/components/formDateInput/FormDateInput.vue';
import FormFooter from '@/components/formFooter/FormFooter.vue';
import FormHeader from '@/components/formHeader/FormHeader.vue';
import FormListboxInput from '@/components/formListboxInput/FormListboxInput.vue';

import EditAlt from '@/icons/line/edit-alt.svg';
import FormField from '@/components/formField/FormField.vue';

const props = defineProps<{
  isEditing?: boolean;
  form: HybridlyFormData<{
    status: EnumData;
    date_submitted: string;
    css_profile: EnumData;
    pell_eligible: EnumData;
    is_alternative: boolean;
    selected_for_verification: EnumData;
  }>;
  returnUrl?: string;
  studentCssProfiles: EnumData[];
  statuses: EnumData[];
  selectedForVerificationOptions: EnumData[];
  student: App.Students.Data.StudentData;
}>();

const isCancelDialogOpen = ref(false);

const { onBackForward } = useBackForward();

// TODO: Fix intecepting the back button
onBackForward(() => {
  router.abort();
});

function handleToggleCancelDialog() {
  if (props.form.isDirty) {
    isCancelDialogOpen.value = !isCancelDialogOpen.value;
    return;
  }
  handleRedirect();
}

function handleRedirect() {
  router.navigate({
    url: props.returnUrl || route('students.financial-aid.index', { student: props.student.id })
  });
}

function handleStatusChange(status: Selectable<string> | null) {
  if (!status) {
    return;
  }

  props.form.fields.status = status;

  if (status.value !== props.form.initial.status.value) {
    props.form.fields.date_submitted = null;
    return;
  }

  props.form.fields.date_submitted = props.form.initial.date_submitted;
}
</script>

<template>
  <Form @submit.prevent="form.submit">
    <template #header>
      <FormHeader
        canClose
        :title="`${isEditing ? 'Edit' : 'Add'} Financial Aid`"
        :description="
          isEditing
            ? 'Use the form below to edit the financial aid information.'
            : 'Fill out the form below add a new financial aid information.'
        "
        @onClose="handleToggleCancelDialog"
      />
    </template>
    <div class="divide-y-slate-200 flex flex-col gap-y-8">
      <div class="flex flex-col gap-y-8">
        <Fieldset title="Financial aid application status">
          <FormListboxInput
            :error="form.errors.status"
            :modelValue="form.fields.status"
            :options="statuses"
            label="Status"
            @update:modelValue="handleStatusChange"
          />
          <FormDateInput
            :error="form.errors.date_submitted"
            :modelValue="form.fields.date_submitted"
            label="Status Date"
            name="date_submitted"
            format="MMM dd, yyyy"
            :clearable="false"
            @update:modelValue="(value) => (form.fields.date_submitted = value)"
          />
          <FormListboxInput
            :error="form.errors.css_profile"
            :modelValue="form.fields.css_profile"
            :options="studentCssProfiles"
            isOptional
            label="CSS profile"
            @update:modelValue="(value) => (form.fields.css_profile = value)"
          />
          <FormListboxInput
            :error="form.errors.selected_for_verification"
            v-model="form.fields.selected_for_verification"
            :options="selectedForVerificationOptions"
            label="Selected for Verification"
          />
        </Fieldset>
        <Divider />
        <Fieldset title="Financial aid eligibility">
          <FormListboxInput
            :error="form.errors.pell_eligible"
            :modelValue="form.fields.pell_eligible"
            :options="[
              {
                label: 'Not set',
                value: null
              },
              {
                label: 'Not eligible',
                value: false
              },
              {
                label: 'Eligible',
                value: true
              }
            ]"
            label="Pell eligibility"
            @update:modelValue="(value) => (form.fields.pell_eligible = value)"
          />
          <FormField label="F/R price lunch eligibility">
            <Button
              :color="ButtonColor.secondary"
              :href="
                route('students.edit.enrollment-details', {
                  student: student?.id
                }) + '#fr-price-lunch'
              "
              :iconLeft="EditAlt"
              :size="ButtonSize.xs"
              :variant="ButtonVariant.invisible"
              >Edit the F/R price lunch eligibility field on the Enrollment Details page</Button
            >
          </FormField>
        </Fieldset>
        <Divider />
        <Fieldset title="Others">
          <FormCheckboxInput
            :checked="form.fields.is_alternative"
            :error="form.errors.is_alternative"
            label="Alternative financial aid"
            name="is_alternative"
            @update:checked="(value) => (form.fields.is_alternative = value)"
          />
        </Fieldset>
      </div>
    </div>
    <template #footer>
      <FormFooter>
        <Button
          type="submit"
          :color="ButtonColor.primary"
          :isDisabled="form.processing || !form.isDirty"
          :isLoading="form.processing"
          :variant="ButtonVariant.solid"
          >Confirm</Button
        >
        <Button
          :color="ButtonColor.slate"
          :variant="ButtonVariant.outlined"
          type="button"
          @click="handleToggleCancelDialog"
        >
          Back to financial aid application
        </Button>
      </FormFooter>
    </template>
  </Form>
  <DangerDialog
    :title="`Cancel ${isEditing ? 'editing' : 'adding a new'} financial aid information`"
    :message="`Are you sure you want to cancel ${
      isEditing ? 'editing' : 'adding'
    } this financial aid information? All ${isEditing ? 'changes' : 'information'} will be lost.`"
    :isOpen="isCancelDialogOpen"
    cancelButtonLabel="Stay on this page"
    confirmButtonLabel="Go back to financial aid application"
    @onCancel="handleToggleCancelDialog"
    @onClose="handleToggleCancelDialog"
    @onConfirm="handleRedirect"
  />
</template>
