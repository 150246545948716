<script setup lang="ts">
import { SwitchRoot, SwitchThumb } from 'radix-vue';

export type SwitchInputProps = {
  checked: boolean;
  hasError?: boolean;
  isDisabled?: boolean;
  name: string;
};

defineProps<SwitchInputProps>();

const emit = defineEmits<{
  'update:checked': [value: boolean];
}>();

function onCheckedChange(event: boolean) {
  emit('update:checked', event);
}
</script>

<template>
  <SwitchRoot
    @update:checked="onCheckedChange"
    :checked="checked"
    :disabled="isDisabled"
    :name="name"
    class="relative flex h-5 w-8 shrink-0 cursor-pointer rounded-full border bg-slate-300 transition-control duration-150 ease-in-out hover:ring hover:ring-secondary-500/20 focus:ring focus:ring-secondary-500/20 focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-secondary-900 hover:data-[disabled]:border-transparent hover:data-[disabled]:ring-0"
    :class="{
      'border-transparent data-[state=unchecked]:hover:border-slate-400': !hasError,
      'border-red-500': hasError
    }"
  >
    <SwitchThumb
      class="my-auto block h-3.5 w-3.5 translate-x-0.5 rounded-full bg-white shadow-sm transition-transform duration-100 will-change-transform data-[state=checked]:translate-x-3.5"
    />
  </SwitchRoot>
</template>
