<script setup lang="ts">
import type { ShowSchoolProps } from '@/domains/configuration/views/schools/ShowSchool.vue';

import { ButtonColor, ButtonSize, ButtonVariant } from '@/hooks/useButtonClasses';
import { useAuth } from '@/hooks/useAuth';
import { useDialogHelpers } from '@/hooks/useDialogHelpers';

import Badge from '@/components/badge/Badge.vue';
import Button from '@/components/button/Button.vue';
import DangerDialog from '@/components/dialog/DangerDialog.vue';
import DefinitionList from '@/components/definitionList/DefinitionList.vue';
import DefinitionListItem from '@/components/definitionList/DefinitionListItem.vue';
import DefinitionListItemBadgeWrapper from '@/components/definitionList/DefinitionListItemBadgeWrapper.vue';
import IconButton from '@/components/button/IconButton.vue';
import InformationCollapsible from '@/components/collapsible/InformationCollapsible.vue';
import Slideout from '@/components/slideout/Slideout.vue';

import Building from '@/icons/line/building.svg';
import CheckCircle from '@/icons/line/check-circle.svg';
import DollarSign from '@/icons/line/dollar-sign.svg';
import EditAlt from '@/icons/line/edit-alt.svg';
import ExternalLinkAlt from '@/icons/line/external-link-alt.svg';
import FilesLandscapeAlt from '@/icons/line/files-landscapes-alt.svg';
import GraduationCap from '@/icons/line/graduation-cap.svg';
import LocationPinAlt from '@/icons/line/location-pin-alt.svg';
import MapPin from '@/icons/line/map-pin.svg';
import TrashIcon from '@/icons/line/trash.svg';
import UsersAlt from '@/icons/line/users-alt.svg';

const props = defineProps<ShowSchoolProps>();

const { show, close } = useDialog();
const { isDialogOpen, toggleDialog } = useDialogHelpers();

const { hasPermission } = useAuth();

const form = useForm({
  method: 'DELETE',
  spoof: false,
  url: route('schools.delete', { school: props.school.id }),
  fields: {},
  hooks: {
    success() {
      close();
    }
  }
});

async function handleSchoolDelete() {
  await form.submit();
  toggleDialog();
}
</script>

<template>
  <Slideout :title="school.name" :isOpen="show" @onClose="close" :icon="Building">
    <template #actions>
      <IconButton
        v-if="school.authorization.update"
        :href="route('schools.edit', { school: school.id })"
        :icon="EditAlt"
        ariaLabel="Edit school"
        size="sm"
        variant="soft"
      />
      <IconButton
        v-if="school.authorization.delete"
        color="danger"
        :icon="TrashIcon"
        ariaLabel="Move school to trash"
        size="sm"
        variant="soft"
        @click="toggleDialog"
      />
    </template>
    <InformationCollapsible title="General information">
      <div class="flex flex-col gap-4">
        <DefinitionList>
          <DefinitionListItem v-if="hasPermission('read-region')" :icon="MapPin" title="Region">
            <Badge
              :label="school.program?.region?.display_name ?? 'No region'"
              variant="soft"
              size="md"
            />
          </DefinitionListItem>
          <DefinitionListItem
            :description="!school.district ? 'No district' : undefined"
            title="District"
          >
            <Badge v-if="school.district" :label="school.district" variant="soft" size="md" />
          </DefinitionListItem>
        </DefinitionList>
        <DefinitionList>
          <DefinitionListItem
            v-if="hasPermission('read-program')"
            :description="!school.program ? 'No program' : undefined"
            :icon="FilesLandscapeAlt"
            title="Program"
          >
            <Badge
              v-if="school.program"
              :label="school.program.display_name"
              variant="soft"
              size="md"
            />
          </DefinitionListItem>
        </DefinitionList>
        <DefinitionList>
          <DefinitionListItem
            :description="school.address ?? 'No address'"
            :icon="LocationPinAlt"
            title="Address"
          />
          <DefinitionListItem :description="school.city ?? 'No city'" title="City" />
          <DefinitionListItem :description="school.state ?? 'No state'" title="State" />
          <DefinitionListItem :description="school.zip_code ?? 'No ZIP Code'" title="ZIP Code" />
        </DefinitionList>
        <DefinitionList>
          <DefinitionListItem
            :description="!school.advisers?.length ? 'No advisers' : undefined"
            :icon="UsersAlt"
            title="Advisers"
          >
            <DefinitionListItemBadgeWrapper
              v-if="school.advisers?.length"
              :badgeCount="3"
              :badgeItems="
                school.advisers?.map((adviser) => ({
                  id: adviser?.id,
                  label: adviser?.name
                })) ?? []
              "
              :buttonRoute="route('users.index')"
              buttonLabel="Go to users"
            />
          </DefinitionListItem>
        </DefinitionList>
        <DefinitionList>
          <DefinitionListItem :icon="GraduationCap" title="Students">
            <div class="flex flex-col gap-y-2">
              <span class="py-0.5 text-sm leading-5 text-slate-900">{{
                school.students_count ?? 'No students'
              }}</span>
              <div>
                <Button
                  :color="ButtonColor.slate"
                  :href="route('students.index')"
                  :iconLeft="ExternalLinkAlt"
                  :size="ButtonSize.xs"
                  :variant="ButtonVariant.outlined"
                >
                  Go to students list
                </Button>
              </div>
            </div>
          </DefinitionListItem>
        </DefinitionList>
        <DefinitionList>
          <DefinitionListItem :icon="CheckCircle" title="Is served">
            <Badge
              :label="school.is_served ? 'Is served' : 'Is not served'"
              variant="soft"
              size="md"
            />
          </DefinitionListItem>
        </DefinitionList>
        <DefinitionList>
          <DefinitionListItem :icon="DollarSign" title="FAFSA DoE Count">
            <span class="py-0.5 text-sm leading-5 text-slate-900">{{
              school.fafsa_doe_count ?? '--'
            }}</span>
          </DefinitionListItem>
          <DefinitionListItem title="FAFSA DoE Update Date">
            <span class="py-0.5 text-sm leading-5 text-slate-900">{{
              school.fafsa_doe_update_date ? formatDate(school.fafsa_doe_update_date) : '--'
            }}</span>
          </DefinitionListItem>
          <DefinitionListItem title="FAFSA DoE Count Updated At">
            <span class="py-0.5 text-sm leading-5 text-slate-900">{{
              school.fafsa_doe_count_updated_at
                ? formatDateTime(school.fafsa_doe_count_updated_at)
                : '--'
            }}</span>
          </DefinitionListItem>
        </DefinitionList>
      </div>
    </InformationCollapsible>
  </Slideout>
  <DangerDialog
    title="Move School to Trash"
    message="Are you sure you want to move this school to the trash?"
    confirmButtonLabel="Move to trash"
    :isOpen="isDialogOpen"
    :isLoading="form.processing"
    @onConfirm="handleSchoolDelete"
    @onCancel="toggleDialog"
    @onClose="toggleDialog"
  />
</template>
