<script setup lang="ts">
import type { Selectable } from '@/components/selectBox/selectBox';

import CenteredContent from '@/components/content/CenteredContent.vue';
import SurveyForm from '@/components/surveys/surveys/SurveyForm.vue';

const form = useForm({
  method: 'POST',
  url: route('surveys.store'),
  fields: {
    name: '',
    description: '',
    creation_method: 'new',
    is_template: false,
    parent_survey_id: null as Selectable<number> | null,
    program_id: null,
    school_ids: [] as Selectable<number>[]
  },
  transform: (fields) => ({
    ...fields,
    school_ids: fields.school_ids?.map((school) => school.value) ?? [],
    parent_survey_id: fields.parent_survey_id?.value ?? null
  })
});
</script>


					<script lang="ts">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <CenteredContent>
    <SurveyForm :form="form" />
  </CenteredContent>
</template>
