<script setup lang="tsx">
import type { CustomCells, HybridlyRow } from '@/components/table/Table';
import type { AppliedFilters } from '@/components/distiller/types';

import { BadgeColor, BadgeVariant } from '@/hooks/useBadgeClasses';
import { ButtonColor, ButtonSize, ButtonVariant } from '@/hooks/useButtonClasses';
import { useAuth } from '@/hooks/useAuth';
import { useFilters } from '@/hooks/useFilters';
import { useHybridlyTable } from '@/hooks/useHybridlyTable';
import { useTableRowClick } from '@/hooks/useTableRowClick';

import BulkActionButton from '@/components/table/BulkActionButton.vue';
import BulkRestoreButton from '@/components/table/BulkRestoreButton.vue';
import BulkTrashButton from '@/components/table/BulkTrashButton.vue';
import Button from '@/components/button/Button.vue';
import EmptyState from '@/components/emptyState/EmptyState.vue';
import IndexPageLayout from '@/components/pageLayout/IndexPageLayout.vue';
import TableBadgesCell from '@/components/table/TableBadgesCell.vue';
import TableBadgesWithDescriptionCell from '@/components/table/TableBadgesWithDescriptionCell.vue';
import TableDateTimeCell from '@/components/table/TableDateTimeCell.vue';
import TableDualTextCell from '@/components/table/TableDualTextCell.vue';
import TableEnumCell from '@/components/table/TableEnumCell.vue';
import TabNavDivider from '@/components/tabNav/TabNavDivider.vue';
import TabNavItem from '@/components/tabNav/TabNavItem.vue';

import Building from '@/icons/line/building.svg';
import FilesLandscapesAlt from '@/icons/line/files-landscapes-alt.svg';
import LayerGroup from '@/icons/line/layer-group.svg';
import MapPin from '@/icons/line/map-pin.svg';
import Message from '@/icons/line/message.svg';
import Plus from '@/icons/line/plus.svg';
import TrashIcon from '@/icons/line/trash.svg';

type UserRow = HybridlyRow<App.Auth.Data.UserData>;

const props = defineProps<{
  users: Table<App.Auth.Data.UserData>;
  appliedFilters: AppliedFilters;
  distiller: App.Search.Data.DistillerData;
  trashActive: boolean;
  counts: {
    active_count: number;
    trashed_count: number;
  };
}>();

const { hasPermission } = useAuth();

const { table, tableSelectionState } = useHybridlyTable<App.Auth.Data.UserData>(props, 'users');
const { handleRowClick } = useTableRowClick();

const { encodeFilters } = useFilters();

const encodedFilters = computed(() => encodeFilters(props.appliedFilters));

const customCells = {
  profile_data: ({ row, column }) => (
    <TableDualTextCell
      primaryText={row.value(column.key).name}
      secondaryText={row.value(column.key).email}
    />
  ),
  roles: ({ row, column }) => (
    <TableBadgesCell
      badgesToDisplay={1}
      badgeVariant={BadgeVariant.soft}
      badgeColor={BadgeColor.secondary}
      items={row.value(column.key).map((role: App.Auth.Data.RoleData) => role?.display_name)}
    />
  ),
  can_access: ({ row, column }) => {
    if (row.value('roles')[0]?.name === 'administrator') {
      return <TableBadgesCell badgesToDisplay={1} items={['--']} />;
    }

    const regions = row.value(column.key).regions ?? [];
    if (regions.length) {
      return (
        <TableBadgesWithDescriptionCell
          badgesToDisplay={1}
          badgeVariant={BadgeVariant.outlined}
          badgeColor={BadgeColor.slate}
          items={regions.map((region: App.Configuration.Data.RegionData) => ({
            label: region.display_name,
            description: `| ${region.programs_count} Programs | ${region.schools_count} Schools`
          }))}
          itemIcon={MapPin}
        />
      );
    }

    const programs = row.value(column.key).programs ?? [];
    if (programs.length) {
      return (
        <TableBadgesWithDescriptionCell
          badgesToDisplay={1}
          items={programs.map((program: App.Configuration.Data.ProgramData) => ({
            label: program.display_name,
            description: ` | ${program.schools_count} Schools`
          }))}
          itemIcon={FilesLandscapesAlt}
        />
      );
    }

    const schools = row.value(column.key).schools ?? [];
    if (schools.length) {
      return (
        <TableBadgesCell
          badgesToDisplay={1}
          items={schools.map((school: App.Configuration.Data.SchoolData) => school?.name)}
          itemIcon={Building}
        />
      );
    }
  },
  two_factor_status: ({ row, column }) => <TableEnumCell enumData={row.value(column.key)} />,
  last_login_at: ({ row, column }) => <TableDateTimeCell date={row.value(column.key)} />,
  created_at: ({ row, column }) => <TableDateTimeCell date={row.value(column.key)} />
} as CustomCells<App.Auth.Data.UserData>;

function handleUserRowClick(event: MouseEvent, row: UserRow) {
  const url = route('users.show', {
    user: row.key
  });
  handleRowClick(url, event);
}
</script>


					<script lang="tsx">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <IndexPageLayout
    title="Users"
    :table="table"
    :appliedFilters="appliedFilters"
    :distiller="distiller"
    :customCells="customCells"
    :stickyEndColumns="1"
    :stickyStartColumns="1"
    :clickableRows="!trashActive"
    @clickRow="($event, row) => handleUserRowClick($event, row as UserRow)"
    :tableSelectionState="tableSelectionState"
  >
    <template #rightButtons>
      <template v-if="tableSelectionState.hasSelection">
        <BulkActionButton
          v-if="!trashActive && hasPermission('update-user')"
          :color="ButtonColor.slate"
          :iconLeft="Message"
          :route="route('users.bulk-password-reset')"
          :tableSelectionState="tableSelectionState"
          confirmButtonLabel="Send password reset email"
          message="Are you sure you want to send a password reset email notification for the selected users?"
          method="post"
          title="Send password reset email"
          variant="outlined"
        />
        <BulkActionButton
          v-if="!trashActive && hasPermission('read-user-two-factor-details')"
          :color="ButtonColor.danger"
          :iconLeft="TrashIcon"
          :route="route('users.bulk-delete-two-factor-channel')"
          :tableSelectionState="tableSelectionState"
          confirmButtonLabel="Delete"
          message="Are you sure you want to delete the two factor channels for the selected users?"
          method="delete"
          title="Delete two factor channels"
        />
        <BulkTrashButton
          v-if="!trashActive"
          recordType="Users"
          :route="route('users.bulk-delete')"
          :tableSelectionState="tableSelectionState"
        />
        <BulkRestoreButton
          v-if="trashActive"
          recordType="Users"
          :route="route('users.bulk-restore')"
          :tableSelectionState="tableSelectionState"
        />
      </template>
      <template v-else>
        <Button
          v-if="hasPermission('create-user')"
          :color="ButtonColor.slate"
          :href="route('upload-sessions.draft.index', { uploadType: 'user' })"
          :iconLeft="LayerGroup"
          :size="ButtonSize.sm"
          :variant="ButtonVariant.soft"
        >
          Bulk add
        </Button>
        <Button
          v-if="hasPermission('create-user')"
          :color="ButtonColor.primary"
          :href="route('users.create')"
          :iconLeft="Plus"
          :size="ButtonSize.sm"
          :variant="ButtonVariant.solid"
        >
          Add a new user
        </Button>
      </template>
    </template>

    <template #tabs>
      <TabNavItem
        :badgeLabel="counts.active_count"
        :isActive="!trashActive"
        label="Active"
        variant="inline"
        :href="
          route('users.index', {
            ...(encodedFilters ? { filters: encodedFilters } : {})
          })
        "
      />
      <TabNavDivider layout="inline" />
      <TabNavItem
        :badgeLabel="counts.trashed_count ?? 0"
        :isActive="trashActive"
        label="Trashed"
        variant="inline"
        :href="
          route('users.index', {
            trashed: true,
            ...(encodedFilters ? { filters: encodedFilters } : {})
          })
        "
      />
    </template>

    <template #emptyState>
      <EmptyState
        v-if="trashActive"
        title="No users were moved to the trash"
        description="Trashed users will appear on this screen"
      />
      <EmptyState
        v-else
        title="No users found"
        :description="
          hasPermission('create-user') ? 'Create a new user by clicking the button below' : ''
        "
        :actionLabel="hasPermission('create-user') ? 'Add a user' : ''"
        :actionHref="route('users.create')"
      />
    </template>
  </IndexPageLayout>
</template>
