<script setup lang="ts">
import type { AppliedFilters } from '@/components/distiller/types';

import pluralize from 'pluralize';
import { capitalize } from 'lodash';

import { ButtonColor, ButtonSize, ButtonVariant } from '@/hooks/useButtonClasses';
import { useAuth } from '@/hooks/useAuth';
import { useFilters } from '@/hooks/useFilters';

import { formatDate } from '@/utils/date';

import Badge from '@/components/badge/Badge.vue';
import Button from '@/components/button/Button.vue';
import CopyButton from '@/components/button/CopyButton.vue';
import EmptyState from '@/components/emptyState/EmptyState.vue';
import IndexSurveyPageLayout from '@/components/surveys/surveys/IndexSurveyPageLayout.vue';
import TabNavDivider from '@/components/tabNav/TabNavDivider.vue';
import TabNavItem from '@/components/tabNav/TabNavItem.vue';
import VerticalDivider from '@/components/verticalDivider/VerticalDivider.vue';

import EditAlt from '@/icons/line/edit-alt.svg';
import Eye from '@/icons/line/eye.svg';
import FileBlank from '@/icons/line/file-blank.svg';
import Plus from '@/icons/line/plus.svg';

const props = defineProps<{
  appliedFilters: AppliedFilters;
  distiller: App.Search.Data.DistillerData;
  surveys: Paginator<App.Surveys.Data.SurveyData>;
  status: string | null;
  counts: {
    all: number;
    active: number;
    inactive: number;
    completed: number;
  };
}>();

const { hasPermission } = useAuth();
const { matches } = useRoute();

const { encodeFilters } = useFilters();

const encodedFilters = computed(() => encodeFilters(props.appliedFilters));
</script>


					<script lang="ts">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <IndexSurveyPageLayout
    :appliedFilters="appliedFilters"
    :distiller="distiller"
    :pagination="surveys"
    title="Surveys"
  >
    <template #rightButtons>
      <Button
        v-if="hasPermission('create-survey')"
        :color="ButtonColor.primary"
        :href="route('surveys.create')"
        :iconLeft="Plus"
        :size="ButtonSize.sm"
        :variant="ButtonVariant.solid"
      >
        Add a new survey
      </Button>
    </template>
    <template #tabs>
      <TabNavItem
        :badgeLabel="counts.all"
        :isActive="matches('surveys.index')"
        label="All"
        variant="inline"
        :href="
          route('surveys.index', {
            ...(encodedFilters ? { filters: encodedFilters } : {})
          })
        "
      />
      <TabNavItem
        :badgeLabel="counts.active"
        :isActive="matches('surveys.active.index')"
        label="Active"
        variant="inline"
        :href="
          route('surveys.active.index', {
            ...(encodedFilters ? { filters: encodedFilters } : {})
          })
        "
      />
      <TabNavItem
        :badgeLabel="counts.inactive"
        :isActive="matches('surveys.inactive.index')"
        label="Inactive"
        variant="inline"
        :href="
          route('surveys.inactive.index', {
            ...(encodedFilters ? { filters: encodedFilters } : {})
          })
        "
      />
      <TabNavDivider layout="inline" />
      <TabNavItem
        :badgeLabel="counts.completed"
        :isActive="matches('surveys.completed.index')"
        label="Completed"
        variant="inline"
        :href="
          route('surveys.completed.index', {
            ...(encodedFilters ? { filters: encodedFilters } : {})
          })
        "
      />
    </template>
    <div class="flex flex-col gap-y-2">
      <template v-if="surveys.data.length">
        <div
          v-for="survey in surveys.data"
          :key="survey.id"
          class="divide-y-slate-200 divide-y rounded-2xl border border-slate-200 bg-white"
        >
          <div class="p-4">
            <div class="flex flex-col gap-2 md:flex-row md:items-center md:justify-between">
              <div class="flex flex-col">
                <div class="flex items-center gap-x-1.5">
                  <h2 class="font-semibold leading-6 text-slate-700">{{ survey.name }}</h2>
                  <Badge :label="survey.status.label" shape="rounded" variant="soft" size="md" />
                </div>
                <div
                  class="flex items-center gap-x-1.5 text-sm font-medium leading-5 text-slate-500"
                >
                  <span>{{ pluralize('question', survey.blocks?.length ?? 0, true) }}</span>
                  <VerticalDivider />
                  <span
                    >Last updated:
                    {{ survey.updated_at ? formatDate(survey.updated_at) : 'never' }}</span
                  >
                </div>
              </div>

              <div class="flex gap-x-2">
                <CopyButton
                  v-if="survey.url"
                  :color="ButtonColor.slate"
                  :size="ButtonSize.sm"
                  :text="survey.url"
                  :variant="ButtonVariant.soft"
                  label="Copy link"
                />
                <Button
                  :color="ButtonColor.slate"
                  :href="route('surveys.show', { survey: survey })"
                  :iconLeft="hasPermission('update-survey') ? EditAlt : Eye"
                  :size="ButtonSize.sm"
                  :variant="ButtonVariant.soft"
                  >{{ hasPermission('update-survey') ? 'Edit' : 'View' }}</Button
                >
              </div>
            </div>
          </div>
          <div class="px-4 py-2">
            <div class="flex items-center gap-x-2">
              <div class="rounded-lg bg-slate-100 p-1">
                <FileBlank class="size-4.5 text-slate-500" />
              </div>
              <span class="text-sm font-medium leading-5 text-slate-500"
                ><span class="font-bold text-slate-700">{{
                  survey.sessions ? survey.sessions?.length : '0'
                }}</span>
                {{ pluralize('response', survey.sessions?.length) }}</span
              >
            </div>
          </div>
        </div>
      </template>
      <EmptyState
        v-else
        :title="`No ${status ?? ''} surveys found`"
        :description="`${capitalize(status ?? '')} ${
          status ? 'surveys' : 'Surveys'
        } will appear on this screen`"
      />
    </div>
  </IndexSurveyPageLayout>
</template>
