<script setup lang="tsx">
import pluralize from 'pluralize';

import { ButtonColor, ButtonVariant } from '@/hooks/useButtonClasses';

import Badge from '@/components/badge/Badge.vue';
import Button from '@/components/button/Button.vue';
import Dialog from '@/components/dialog/Dialog.vue';

import Child from '@/icons/line/child.svg';
import ExternalLinkAlt from '@/icons/line/external-link-alt.svg';

const props = defineProps<{
  uploadSession: App.Bulkinator.Data.UploadSessionData;
}>();

const [isDialogOpen, setIsDialogOpen] = useToggle(false);
const childSessions = computed(() => props.uploadSession.child_upload_sessions ?? []);
const sessionType = computed(() => {
  switch (props.uploadSession.type.value) {
    case 'manual':
      return 'Manual';
    case 'error':
      return 'Error';
    case 'disenrolled':
      return 'Disenrolling';
    default:
      return '';
  }
});
</script>

<template>
  <Badge
    v-if="!!childSessions.length"
    size="sm"
    :iconLeft="Child"
    variant="soft"
    @click="setIsDialogOpen(true)"
    class="mt-2 hover:cursor-pointer"
    >{{ childSessions.length }} child {{ pluralize('session', childSessions.length) }}</Badge
  >

  <Dialog
    v-if="childSessions.length"
    :isOpen="isDialogOpen"
    title="Child sessions"
    @onClose="setIsDialogOpen(false)"
  >
    <template #default>This session has had child sessions generated automatically:</template>

    <template #body>
      <ul>
        <li v-for="childSession in childSessions" :key="childSession.id">
          <a
            target="_blank"
            :href="route('upload-sessions.show', { uploadSession: childSession.id })"
            class="flex flex-row items-center justify-between rounded-lg px-3 py-2 hover:bg-zinc-100"
          >
            <span class="flex flex-row items-center gap-x-1.5 text-sm leading-5 text-zinc-900">
              <component :is="ExternalLinkAlt" class="h-4 w-4 text-zinc-500" />
              {{ childSession.name }}
            </span>

            <Badge v-if="sessionType" variant="soft"> {{ sessionType }} session </Badge>
          </a>
        </li>
      </ul>
    </template>

    <template #footer>
      <Button
        :color="ButtonColor.slate"
        :variant="ButtonVariant.invisible"
        type="button"
        @click="setIsDialogOpen(false)"
        >Close</Button
      >
    </template>
  </Dialog>
</template>
