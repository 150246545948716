<script setup lang="ts">
import MultiComboboxInput from '@/components/selectBox/combobox/MultiComboboxInput.vue';
import { Selectable } from '@/components/selectBox/selectBox';
import { useSurveysSearch } from '@/hooks/search/useSurveysSearch';

const { surveyOptions, isLoading, onQueryChange } = useSurveysSearch();

defineProps<{
  modelValue: Selectable<number>[];
}>();

defineEmits(['update:modelValue']);
</script>

<template>
  <MultiComboboxInput
    :selectAll="true"
    :options="surveyOptions"
    :onFilter="onQueryChange"
    :isLoading="isLoading"
    :modelValue="modelValue"
    @update:modelValue="$emit('update:modelValue', $event)"
    @open="() => onQueryChange('')"
  />
</template>
