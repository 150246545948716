<script setup lang="ts">
import type { Selectable } from '@/components/selectBox/selectBox';

import CenteredContent from '@/components/content/CenteredContent.vue';
import GoalForm from '@/components/goals/GoalForm.vue';

type EnumData = App.Base.Data.EnumData;

defineProps<{
  types: EnumData[];
  grades: EnumData[];
}>();

const form = useForm({
  method: 'POST',
  url: route('goals.store'),
  fields: {
    goal_type: {} as Selectable<string>,
    grade: {} as Selectable<number>,
    goal_percentage: null,
    school_ids: [] as Selectable<number>[]
  },
  transform: (fields) => ({
    ...fields,
    goal_type: fields.goal_type?.value ?? null,
    grade: fields.grade?.value ?? null,
    school_ids: fields.school_ids?.map((school) => school.value) ?? []
  })
});
</script>


					<script lang="ts">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <CenteredContent>
    <GoalForm :form="form" :types="types" :grades="grades" />
  </CenteredContent>
</template>
