<script setup lang="ts">
import CenteredContent from '@/components/content/CenteredContent.vue';
import ApCourseForm from '@/components/apCourses/ApCourseForm.vue';

defineProps<{
  returnUrl?: string;
}>();

const form = useForm({
  method: 'POST',
  url: route('ap-courses.store'),
  fields: {
    display_name: ''
  }
});
</script>


					<script lang="ts">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <CenteredContent>
    <ApCourseForm :form="form" :returnUrl="returnUrl" />
  </CenteredContent>
</template>
