import axios from 'axios';

let controller: AbortController | undefined;

export async function recordCount(
  distiller: string,
  appliedFilters: string | undefined
): Promise<App.Search.Data.SearchRecordCountData> {
  if (controller) {
    controller.abort();
    controller = undefined;
  }

  controller = new AbortController();

  return axios
    .post(
      route('search.record-count'),
      {
        distiller,
        applied_filters: appliedFilters || null
      },
      {
        signal: controller.signal
      }
    )
    .then((res) => res.data)
    .catch(() => {});
}
