<script setup lang="ts">
type EnumData = App.Base.Data.EnumData;

import type { HybridlyFormData } from '@/@types/global';

import { ButtonColor, ButtonVariant } from '@/hooks/useButtonClasses';

import Button from '@/components/button/Button.vue';
import DangerDialog from '@/components/dialog/DangerDialog.vue';
import Form from '@/components/form/Form.vue';
import FormFooter from '@/components/formFooter/FormFooter.vue';
import FormHeader from '@/components/formHeader/FormHeader.vue';

const props = defineProps<{
  isEditing?: boolean;
  form: HybridlyFormData<{
    notes: string;
    topics: EnumData[];
    institute_ids: number[];
    scholarship_names: string[];
    tests: [];
    types: EnumData[];
    institute_id: number;
    meeting_length: EnumData[];
    duration: EnumData[];
    date: string;
  }>;
  returnUrl?: string;
  student: App.Students.Data.StudentData;
}>();

const isCancelDialogOpen = ref(false);

const { onBackForward } = useBackForward();

// TODO: Fix intecepting the back button
onBackForward(() => {
  router.abort();
});

function handleToggleCancelDialog() {
  if (props.form.isDirty) {
    isCancelDialogOpen.value = !isCancelDialogOpen.value;
    return;
  }
  handleRedirect();
}

function handleRedirect() {
  router.navigate({
    url: props.returnUrl || route('students.interactions.index', { student: props.student.id })
  });
}
</script>

<template>
  <Form @submit.prevent="form.submit">
    <template #header>
      <FormHeader
        canClose
        :title="`${isEditing ? 'Edit' : 'Add'} a Interaction`"
        :description="
          isEditing
            ? 'Use the form below to edit the interaction.'
            : 'Fill out the form below add a new interaction.'
        "
        @onClose="handleToggleCancelDialog"
      />
    </template>
    <slot />
    <template #footer>
      <FormFooter>
        <Button
          :color="ButtonColor.primary"
          :isDisabled="form.processing || !form.isDirty"
          :isLoading="form.processing"
          :variant="ButtonVariant.solid"
          type="submit"
          >Confirm</Button
        >
        <Button
          :color="ButtonColor.slate"
          :variant="ButtonVariant.outlined"
          type="button"
          @click="handleToggleCancelDialog"
        >
          Back to interactions
        </Button>
      </FormFooter>
    </template>
    <template #delete>
      <slot name="delete" />
    </template>
  </Form>
  <DangerDialog
    :title="`Cancel ${isEditing ? 'editing' : 'adding a new'} interaction`"
    :message="`Are you sure you want to cancel ${
      isEditing ? 'editing' : 'adding'
    } this interaction? All ${isEditing ? 'changes' : 'information'} will be lost.`"
    :isOpen="isCancelDialogOpen"
    cancelButtonLabel="Stay on this page"
    confirmButtonLabel="Go back to interactions"
    @onCancel="handleToggleCancelDialog"
    @onClose="handleToggleCancelDialog"
    @onConfirm="handleRedirect"
  />
</template>
