<script setup lang="ts" generic="T extends Record<string, unknown>">
import type { BadgeProps } from '@/components/badge/Badge.vue';
import Badge from '@/components/badge/Badge.vue';
import PaddedCellContent from '@/components/table/PaddedCellContent.vue';

interface TableBadgesCellItem {
  label: string;
  description: string;
}

export type TableBadgesCellProps = {
  items: TableBadgesCellItem[];
  badgesToDisplay: number;
  itemIcon?: Component;
  badgeVariant?: BadgeProps['variant'];
  badgeColor?: BadgeProps['color'];
  badgeSize?: BadgeProps['size'];
  badgeShape?: BadgeProps['shape'];
};

const props = withDefaults(defineProps<TableBadgesCellProps>(), {
  badgeShape: 'rounded',
  badgeVariant: 'outlined'
});

const filteredItems = computed(() => props.items.filter((item) => item !== null));
const trimmedItems = computed(() => filteredItems.value.slice(0, props.badgesToDisplay));
</script>

<template>
  <PaddedCellContent>
    <div class="flex flex-wrap gap-1">
      <Badge
        v-for="item in trimmedItems"
        :iconLeft="itemIcon"
        :key="item.label"
        :shape="badgeShape"
        :size="badgeSize"
        :variant="badgeVariant"
        :color="badgeColor"
        class="truncate"
      >
        {{ item.label }} <span class="text-slate-500">{{ item.description }}</span>
      </Badge>
      <Badge
        v-if="badgesToDisplay && items.length > badgesToDisplay"
        :shape="badgeShape"
        :size="badgeSize"
        :variant="badgeVariant"
        :color="badgeColor"
        class="truncate"
      >
        +{{ items.length - trimmedItems.length }}
      </Badge>
    </div>
  </PaddedCellContent>
</template>
