<script setup lang="ts">
import type { ShowProgramProps } from '@/domains/configuration/views/programs/ShowProgram.vue';

import { useAuth } from '@/hooks/useAuth';
import { useDialogHelpers } from '@/hooks/useDialogHelpers';

import Badge from '@/components/badge/Badge.vue';
import DangerDialog from '@/components/dialog/DangerDialog.vue';
import DefinitionList from '@/components/definitionList/DefinitionList.vue';
import DefinitionListItem from '@/components/definitionList/DefinitionListItem.vue';
import DefinitionListItemBadgeWrapper from '@/components/definitionList/DefinitionListItemBadgeWrapper.vue';
import IconButton from '@/components/button/IconButton.vue';
import InformationCollapsible from '@/components/collapsible/InformationCollapsible.vue';
import Slideout from '@/components/slideout/Slideout.vue';

import Building from '@/icons/line/building.svg';
import DocumentLayoutLeft from '@/icons/line/document-layout-left.svg';
import EditAlt from '@/icons/line/edit-alt.svg';
import Envelope from '@/icons/line/envelope.svg';
import FilesLandscapeAlt from '@/icons/line/files-landscapes-alt.svg';
import MapPin from '@/icons/line/map-pin.svg';
import TrashIcon from '@/icons/line/trash.svg';
import User from '@/icons/line/user.svg';

const props = defineProps<ShowProgramProps>();

const { show, close } = useDialog();
const { isDialogOpen, toggleDialog } = useDialogHelpers();

const { hasPermission } = useAuth();
const form = useForm({
  method: 'DELETE',
  spoof: false,
  url: route('programs.delete', { program: props.program.id }),
  fields: {},
  hooks: {
    success() {
      close();
    }
  }
});

async function handleProgramDelete() {
  await form.submit();
  toggleDialog();
}
</script>

<template>
  <Slideout :title="program.display_name" :isOpen="show" @onClose="close" :icon="FilesLandscapeAlt">
    <template #actions>
      <IconButton
        v-if="program.authorization.update"
        :href="route('programs.edit', { program: program.id })"
        :icon="EditAlt"
        ariaLabel="Edit program"
        size="sm"
        variant="soft"
      />
      <IconButton
        v-if="program.authorization.delete"
        color="danger"
        :icon="TrashIcon"
        ariaLabel="Move program to trash"
        size="sm"
        variant="soft"
        @click="toggleDialog"
      />
    </template>
    <div class="-m-4 flex flex-col divide-y">
      <div class="p-4">
        <InformationCollapsible title="Region information">
          <DefinitionList>
            <DefinitionListItem
              :description="program.description ?? 'No description'"
              :icon="DocumentLayoutLeft"
              title="Description"
            />
            <DefinitionListItem
              v-if="hasPermission('read-region')"
              :description="!program.region ? 'No region' : undefined"
              :icon="MapPin"
              title="Region"
            >
              <Badge
                v-if="program.region"
                :label="program.region.display_name"
                variant="soft"
                size="md"
              />
            </DefinitionListItem>
            <DefinitionListItem
              v-if="hasPermission('read-school')"
              :description="!program.schools?.length ? 'No schools' : undefined"
              :icon="Building"
              title="Schools"
            >
              <DefinitionListItemBadgeWrapper
                v-if="program.schools?.length"
                :badgeItems="
                  program.schools?.map((school) => ({
                    id: school?.id,
                    label: school?.name
                  })) ?? []
                "
                :buttonRoute="route('schools.index')"
                buttonLabel="Go to schools"
              />
            </DefinitionListItem>
          </DefinitionList>
        </InformationCollapsible>
      </div>
      <div v-if="program.users?.length" class="p-4">
        <InformationCollapsible title="Program Directors">
          <div class="divide-y divide-slate-200">
            <DefinitionList
              v-for="director in program.users"
              :key="director.id"
              class="py-4 first:pt-0 last:pb-0"
            >
              <DefinitionListItem :description="director.name" :icon="User" title="Name">
                <Badge :label="director.name" variant="soft" size="md" />
              </DefinitionListItem>
              <DefinitionListItem
                :description="director?.email ?? 'No email'"
                :icon="Envelope"
                title="Email"
              />
            </DefinitionList>
          </div>
        </InformationCollapsible>
      </div>
    </div>
  </Slideout>
  <DangerDialog
    title="Move Program to Trash"
    message="Are you sure you want to move this program to the trash?"
    confirmButtonLabel="Move to trash"
    :isOpen="isDialogOpen"
    :isLoading="form.processing"
    @onConfirm="handleProgramDelete"
    @onCancel="toggleDialog"
    @onClose="toggleDialog"
  />
</template>
