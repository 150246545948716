import axios from 'axios';

export async function getSmsUnreadCount(): Promise<App.Sms.Data.UnreadSmsCountData> {
  return axios.get(route('api.sms.unread-count')).then((res) => res.data);
}

export async function indexThreadMessages(
  threadId: number,
  page: number = 1
): Promise<Paginator<App.Sms.Data.SmsData>> {
  return axios
    .get(route('api.sms.thread.messages.index', { thread: threadId, page }))
    .then((res) => res.data);
}

export async function indexThreadScheduledMessages(
  threadId: number,
  page: number = 1
): Promise<Paginator<App.Sms.Data.UpcomingMessageData>> {
  return axios
    .get(route('api.sms.thread.scheduled.index', { thread: threadId, page }))
    .then((res) => res.data);
}
