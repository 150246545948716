<script setup lang="ts">
import ComboboxInput from '@/components/selectBox/combobox/ComboboxInput.vue';
import { CssClass } from '@/@types/global';
import { Selectable, SelectableValue } from '@/components/selectBox/selectBox';
import { useScholarshipsSearch } from '@/hooks/search/useScholarshipsSearch';

defineProps<{
  modelValue: Selectable<SelectableValue> | null;
  inputClasses?: CssClass;
  isLoading?: boolean;
  isSuccessful?: boolean;
}>();

const emit = defineEmits(['update:modelValue']);

function handleUpdate(value: Selectable<SelectableValue> | null) {
  emit('update:modelValue', value);
}

const { scholarshipOptions, isLoading: isSearching, onQueryChange } = useScholarshipsSearch();
</script>

<template>
  <ComboboxInput
    showQueryAsOption
    :options="scholarshipOptions"
    :onFilter="onQueryChange"
    :isLoading="isLoading || isSearching"
    :isSuccessful="isSuccessful"
    :modelValue="modelValue"
    :inputClasses="inputClasses"
    @update:modelValue="handleUpdate"
    @open="() => onQueryChange('')"
  />
</template>
