<script setup lang="ts" generic="T extends Record<string, unknown>">
import type { TableColumn } from '@/components/table/Table';

import { CollapsibleContent, CollapsibleRoot, CollapsibleTrigger } from 'radix-vue';

import { useSortableRows } from '@/components/table/useSortableRows';

import Table from '@/components/table/Table.vue';
import Badge from '@/components/badge/Badge.vue';

import AngleRight from '@/icons/line/angle-right.svg';
import EmptyState from '@/components/emptyState/EmptyState.vue';

interface TableData {
  columns: TableColumn<T>[];
  records: T[];
}

const props = defineProps<{
  badgeCount: number;
  cardSectionTitle?: string;
  student: App.Students.Data.StudentData;
  table: TableData;
  title: string;
}>();

defineEmits<{
  clickRow: [event: MouseEvent, row: T];
}>();

const rows = computed(() => props.table.records);
const { sortedRows, currentSort, handleSort } = useSortableRows(rows, '-date');
</script>

<template>
  <CollapsibleRoot
    class="group divide-y divide-zinc-200 overflow-hidden"
    type="single"
    :collapsible="true"
  >
    <CollapsibleTrigger
      class="gpa-x-4 group flex w-full items-center justify-between border-zinc-200 py-4.5 pl-3 pr-4 group-data-[state=closed]:border-b"
    >
      <div class="flex items-center gap-x-3">
        <AngleRight
          class="h-5 w-5 text-zinc-500 opacity-70 transition-transform duration-200 group-data-[state=open]:rotate-90"
        />
        <h3 class="font-bold capitalize leading-6 text-zinc-900">{{ title }}</h3>
      </div>
      <Badge :label="`Atempts: ${badgeCount ?? 0}`" variant="soft" />
    </CollapsibleTrigger>
    <CollapsibleContent class="divide-y divide-zinc-200">
      <div v-if="cardSectionTitle && table.records.length" class="flex flex-col gap-y-2 p-4 pb-3">
        <h4 class="text-2xs font-bold uppercase text-zinc-500">
          {{ cardSectionTitle }}
        </h4>
        <div v-if="$slots.cards" class="flex shrink-0 gap-2 overflow-x-auto">
          <slot name="cards" />
        </div>
      </div>
      <Table
        class="border-b-0"
        :columns="table.columns"
        :rows="sortedRows"
        :currentSort="currentSort"
        :getRowId="(row) => row.id as number"
        :stickyEndColumns="1"
        clickableRows
        @clickRow="($event, row) => $emit('clickRow', $event, row)"
        @sort="handleSort"
      >
        <template #emptyState>
          <EmptyState
            class="border-b border-zinc-200"
            inline
            title="No tests found"
            description="Create a new test by clicking the button below"
            actionLabel="Add a test"
            :actionHref="route('students.tests.create', { student: student.id })"
          />
        </template>
      </Table>
    </CollapsibleContent>
  </CollapsibleRoot>
</template>
