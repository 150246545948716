<script setup lang="tsx">
import type { CustomCells } from '@/components/table/Table';

import { useHybridlyTable } from '@/hooks/useHybridlyTable';

import EmptyState from '@/components/emptyState/EmptyState.vue';
import IndexPageLayout from '@/components/pageLayout/IndexPageLayout.vue';
import { AppliedFilters } from '@/components/distiller/types';
import TableBadgesCell from '@/components/table/TableBadgesCell.vue';
import TableDateTimeCell from '@/components/table/TableDateTimeCell.vue';

import UserCircle from '@/icons/line/user-circle.svg';

const props = defineProps<{
  documentAcknowledgements: Table<App.Auth.Data.DocumentAcknowledgementData>;
  appliedFilters: AppliedFilters;
  distiller: App.Search.Data.DistillerData;
}>();

const { table } = useHybridlyTable<App.Auth.Data.DocumentAcknowledgementData>(
  props,
  'documentAcknowledgements'
);

const customCells = {
  user: ({ row, column }) => (
    <TableBadgesCell
      badgesToDisplay={1}
      itemIcon={UserCircle}
      items={[row.value(column.key).name]}
    />
  ),
  created_at: ({ row, column }) => <TableDateTimeCell date={row.value(column.key)} />
} as CustomCells<App.Configuration.Data.GoalData>;
</script>


					<script lang="tsx">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <IndexPageLayout
    title="Documents"
    :table="table"
    :appliedFilters="appliedFilters"
    :distiller="distiller"
    :customCells="customCells"
    :selectionsEnabled="false"
  >
    <template #emptyState>
      <EmptyState
        title="No document acknowledgements found"
        description="Document acknowledgements will appear on this screen"
      />
    </template>
  </IndexPageLayout>
</template>
