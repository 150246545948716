<script setup lang="ts">
import IndexAdmitHubContacts from '@/domains/admit-hub/views/IndexAdmitHubContacts.vue';
import AdmitHubContactData = App.AdmitHub.Data.AdmitHubContactData;
import { AppliedFilters } from '@/components/distiller/types';
import AdmitHubContact from '@/components/admitHub/AdmitHubContact.vue';
import { WithLoadedRelations } from '@/@types/global';

defineProps<{
  contact: WithLoadedRelations<AdmitHubContactData, 'contactable'>;
  contacts: Paginator<AdmitHubContactData>;
  distiller: App.Search.Data.DistillerData;
  appliedFilters: AppliedFilters;
}>();
</script>


					<script lang="ts">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <IndexAdmitHubContacts
    :contacts="contacts"
    :activeContactId="contact.id"
    :distiller
    :appliedFilters
  >
    <AdmitHubContact :contact="contact" />
  </IndexAdmitHubContacts>
</template>
