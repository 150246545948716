<script setup lang="ts">
import { CssClass } from '@/@types/global';
import ComboboxInput from '@/components/selectBox/combobox/ComboboxInput.vue';
import { useRegionsSearch } from '@/hooks/search/useRegionsSearch';
import { Selectable, SelectableValue } from '@/components/selectBox/selectBox';

const { regionOptions, isLoading: isSearching, onQueryChange } = useRegionsSearch();

defineProps<{
  modelValue: Selectable<SelectableValue> | null;
  inputClasses?: CssClass;
  isLoading?: boolean;
}>();

defineEmits(['update:modelValue']);
</script>

<template>
  <ComboboxInput
    :options="regionOptions"
    :onFilter="onQueryChange"
    :isLoading="isLoading || isSearching"
    :modelValue="modelValue"
    :inputClasses="inputClasses"
    @update:modelValue="$emit('update:modelValue', $event)"
    @open="() => onQueryChange('')"
  />
</template>
