<script setup lang="ts">
import { useAuth } from '@/hooks/useAuth';

import TabNav from '@/components/tabNav/TabNav.vue';
import TabNavItem from '@/components/tabNav/TabNavItem.vue';

defineProps<{
  survey: App.Surveys.Data.SurveyData;
}>();

const { hasPermission } = useAuth();
const { matches } = useRoute();
</script>

<template>
  <TabNav layout="inline" floating>
    <TabNavItem
      :href="route('surveys.show', { survey: survey.id })"
      :isActive="matches('surveys.show')"
      label="Overview"
    />
    <TabNavItem
      :href="route('surveys.sessions.index', { survey: survey.id })"
      :isActive="matches('surveys.sessions.index')"
      label="Responses"
    />
    <TabNavItem
      :href="route('surveys.blocks.index', { survey: survey.id })"
      :isActive="
        matches('surveys.blocks.index') ||
        matches('surveys.blocks.show') ||
        matches('surveys.blocks.settings.show') ||
        matches('surveys.blocks.logic.show')
      "
      label="Questions"
    />
    <TabNavItem
      v-if="hasPermission('update-survey')"
      :href="route('surveys.settings.edit', { survey: survey.id })"
      :isActive="matches('surveys.settings.edit')"
      label="Settings"
    />
  </TabNav>
</template>
