<script setup lang="ts">
import Badge from '@/components/badge/Badge.vue';
import GraduationCapIcon from '@/icons/line/graduation-cap.svg';

const props = defineProps<{
  recipient: App.Sms.Data.SmsRecipientData;
}>();

const meta = computed<App.Sms.Data.SmsRecipientStudentParentMetaData>(
  () => props.recipient.meta as unknown as App.Sms.Data.SmsRecipientStudentParentMetaData
);
</script>

<template>
  <Badge :iconLeft="GraduationCapIcon" :label="meta.student?.name" size="sm" />
</template>
