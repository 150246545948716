<script setup lang="ts">
import Badge from '@/components/badge/Badge.vue';
import Tooltip from '@/components/tooltip/Tooltip.vue';
import VerticalDivider from '@/components/verticalDivider/VerticalDivider.vue';

const props = defineProps<{
  counts: {
    '1_on_1': number;
    group: number;
    parent: number;
    other: number;
  };
}>();

const totalCount = computed(() => Object.values(props.counts).reduce((acc, cur) => acc + cur, 0));

function getCountLabel(type) {
  return {
    '1_on_1': '1 on 1',
    group: 'Group',
    parent: 'Parent/Guardian',
    other: 'Other'
  }[type];
}
</script>

<template>
  <div class="flex flex-wrap items-center rounded-lg border border-slate-100 bg-slate-50">
    <div
      class="lg:border-b-none flex shrink-0 basis-full items-center justify-center gap-x-1 border-b border-slate-100 px-3 py-2 text-sm font-semibold leading-5 text-slate-700 lg:basis-auto"
    >
      Total
      <Badge :label="totalCount" size="sm" variant="soft" />
      <Tooltip side="top">Totals are based on the interactions in the current school year</Tooltip>
    </div>
    <VerticalDivider class="mx-3 hidden lg:block" layout="inline" />
    <div
      v-for="(count, key) in counts"
      :key="key"
      class="flex grow items-center justify-center gap-x-1 px-3 py-2 text-sm font-semibold leading-5 text-slate-700 lg:grow-0"
    >
      {{ getCountLabel(key) }}
      <Badge :label="count" size="sm" variant="soft" />
    </div>
    <div class="shrink-0"></div>
  </div>
</template>
