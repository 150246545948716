<script setup lang="ts">
type EnumData = App.Base.Data.EnumData;

import type { HybridlyFormData } from '@/@types/global';

import { ButtonColor, ButtonVariant } from '@/hooks/useButtonClasses';

import Button from '@/components/button/Button.vue';
import DangerDialog from '@/components/dialog/DangerDialog.vue';
import Fieldset from '@/components/fieldset/Fieldset.vue';
import Form from '@/components/form/Form.vue';
import FormDateInput from '@/components/formDateInput/FormDateInput.vue';
import FormFooter from '@/components/formFooter/FormFooter.vue';
import FormHeader from '@/components/formHeader/FormHeader.vue';
import FormSchoolInput from '@/components/formComboboxInput/FormSchoolInput.vue';
import FormTextInput from '@/components/formTextInput/FormTextInput.vue';
import FormListboxInput from '@/components/formListboxInput/FormListboxInput.vue';

const props = defineProps<{
  form: HybridlyFormData<{
    first_name: string | null;
    last_name: string | null;
    middle_name: string | null;
    preferred_name: string | null;
    grade: EnumData | null;
    dob: string | null;
    school_id: EnumData | null;
  }>;
  grades: EnumData[];
  returnUrl?: string;
}>();

const isCancelDialogOpen = ref(false);

const { onBackForward } = useBackForward();

// TODO: Fix intercepting the back button
onBackForward(() => {
  router.abort();
});

function handleToggleCancelDialog() {
  if (props.form.isDirty) {
    isCancelDialogOpen.value = !isCancelDialogOpen.value;
    return;
  }
  handleRedirect();
}

function handleRedirect() {
  router.navigate({ url: props.returnUrl || route('students.index') });
}
</script>

<template>
  <Form @submit.prevent="form.submit">
    <template #header>
      <FormHeader
        canClose
        title="Add a Student"
        description="Fill out the form below add a new student."
        @onClose="handleToggleCancelDialog"
      />
    </template>
    <Fieldset title="Student information">
      <FormTextInput
        :error="form.errors.first_name"
        :modelValue="form.fields.first_name"
        label="First name"
        name="first_name"
        type="text"
        @update:modelValue="(value) => (form.fields.first_name = value)"
      />
      <FormTextInput
        :error="form.errors.preferred_name"
        :modelValue="form.fields.preferred_name"
        label="Preferred name (optional)"
        name="preferred_name"
        placeholder="Student's preferred name"
        @update:modelValue="(value) => (form.fields.preferred_name = value)"
      />
      <FormTextInput
        :error="form.errors.middle_name"
        :modelValue="form.fields.middle_name"
        label="Middle name"
        name="middle_name"
        @update:modelValue="(value) => (form.fields.middle_name = value)"
      />
      <FormTextInput
        :error="form.errors.last_name"
        :modelValue="form.fields.last_name"
        label="Last name"
        name="last_name"
        type="text"
        @update:modelValue="(value) => (form.fields.last_name = value)"
      />
      <FormDateInput
        :error="form.errors.dob"
        :modelValue="form.fields.dob"
        label="Date of birth"
        name="dob"
        @update:modelValue="(value) => (form.fields.dob = value)"
      />
    </Fieldset>
    <Fieldset title="Academic details">
      <FormListboxInput
        :error="form.errors.grade"
        :options="grades"
        :modelValue="form.fields.grade"
        label="Grade"
        @update:modelValue="(value) => (form.fields.grade = value)"
      />
      <FormSchoolInput
        :error="form.errors.school_id"
        :modelValue="form.fields.school_id"
        label="School"
        placeholder="Select a school"
        @update:modelValue="(value) => (form.fields.school_id = value)"
      />
    </Fieldset>
    <template #footer>
      <FormFooter>
        <Button
          type="submit"
          :color="ButtonColor.primary"
          :isDisabled="form.processing || !form.isDirty"
          :isLoading="form.processing"
          :variant="ButtonVariant.solid"
          >Confirm
        </Button>
        <Button
          :color="ButtonColor.slate"
          :variant="ButtonVariant.outlined"
          type="button"
          @click="handleToggleCancelDialog"
          >Back to students
        </Button>
      </FormFooter>
    </template>
  </Form>
  <DangerDialog
    title="Cancel adding a new student"
    message="Are you sure you want to cancel adding this student? All information will be lost."
    :isOpen="isCancelDialogOpen"
    cancelButtonLabel="Stay on this page"
    confirmButtonLabel="Go back to students"
    @onCancel="handleToggleCancelDialog"
    @onClose="handleToggleCancelDialog"
    @onConfirm="handleRedirect"
  />
</template>
