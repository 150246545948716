<script setup lang="ts">
import type { Selectable } from '@/components/selectBox/selectBox';
import type { TableSelectionState } from '@/hooks/useHybridlyTable';

import pluralize from 'pluralize';

import Alert from '@/components/alert/Alert.vue';
import Dialog from '@/components/dialog/Dialog.vue';
import Fieldset from '@/components/fieldset/Fieldset.vue';
import FormRows from '@/components/formRows/FormRows.vue';
import FormSchoolInput from '@/components/formComboboxInput/FormSchoolInput.vue';
import FormTextInput from '@/components/formTextInput/FormTextInput.vue';

const props = defineProps<{
  defaultSchool: App.Configuration.Data.SchoolData;
  isOpen: boolean;
  selectedStudentsCount: number;
  tableSelectionState: TableSelectionState;
}>();

const emit = defineEmits<{
  onCancel: [void];
  onClose: [void];
}>();

const queryParameters = useQueryParameters();

const form = useForm({
  method: 'POST',
  url: route('student-groups.store'),
  fields: {
    ids: props.tableSelectionState.selectedRowIds || [],
    filters: queryParameters.filters || null,
    all: props.tableSelectionState.areAllRecordsSelected,
    name: '',
    school_id: buildSelectableSchool(props.defaultSchool) as Selectable<number> | null
  },
  transform: (fields) => ({
    ...fields,
    ids: props.tableSelectionState.selectedRowIds || [],
    filters: queryParameters.filters || null,
    all: props.tableSelectionState.areAllRecordsSelected,
    school_id: fields.school_id?.value,
    return_url: getRouterContext().url
  }),
  hooks: {
    success() {
      props.tableSelectionState.reset();
      emit('onClose');
    }
  }
});

function handleConfirm() {
  form.submit();
}
</script>

<template>
  <Dialog
    :isOpen="isOpen"
    :isLoading="form.processing"
    confirmButtonLabel="Create group"
    description="Create a new group from selected students"
    title="New Group"
    @onConfirm="handleConfirm"
    @onClose="emit('onClose')"
    @onCancel="emit('onCancel')"
  >
    <FormRows>
      <Fieldset title="Group information">
        <FormTextInput
          name="name"
          label="Group name"
          :modelValue="form.fields.name"
          :error="form.errors.name"
          @update:modelValue="(value) => (form.fields.name = value)"
        />
        <FormSchoolInput
          :error="form.errors.school_id"
          :modelValue="form.fields.school_id"
          label="School"
          placeholder="Select school"
          @update:modelValue="(value) => (form.fields.school_id = value)"
        />
        <Alert title="Selected Students" variant="soft">
          You adding <span class="font-bold">{{ selectedStudentsCount }}</span>
          {{ pluralize('student', selectedStudentsCount) }} to this group.
        </Alert>
      </Fieldset>
    </FormRows>
  </Dialog>
</template>
