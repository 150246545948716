<script setup lang="ts">
type EnumData = App.Base.Data.EnumData;

import type { HybridlyFormData } from '@/@types/global';

import { ButtonColor, ButtonVariant } from '@/hooks/useButtonClasses';

import Button from '@/components/button/Button.vue';
import DangerDialog from '@/components/dialog/DangerDialog.vue';
import Fieldset from '@/components/fieldset/Fieldset.vue';
import Form from '@/components/form/Form.vue';
import FormFooter from '@/components/formFooter/FormFooter.vue';
import FormHeader from '@/components/formHeader/FormHeader.vue';
import FormMultiSchoolInput from '@/components/formComboboxInput/FormMultiSchoolInput.vue';
import FormProgramInput from '@/components/formComboboxInput/FormProgramInput.vue';
import FormSchoolInput from '@/components/formComboboxInput/FormSchoolInput.vue';
import FormSurveyInput from '@/components/formComboboxInput/FormSurveyInput.vue';
import FormSwitchInput from '@/components/formSwitchInput/FormSwitchInput.vue';
import FormTextareaInput from '@/components/formTextareaInput/FormTextareaInput.vue';
import FormTextInput from '@/components/formTextInput/FormTextInput.vue';
import RadioCardInput from '@/components/radioCardInput/RadioCardInput.vue';

import FilePlus from '@/icons/line/file-plus.svg';
import Copy from '@/icons/line/copy.svg';

const props = defineProps<{
  form: HybridlyFormData<{
    name: string;
    description: string;
    creation_method: 'new' | 'duplicate';
    parent_survey_id: number;
    is_template: boolean;
    program_id: number;
    school_ids: EnumData[];
  }>;
  isEditing?: boolean;
}>();

const isCancelDialogOpen = ref(false);

const { onBackForward } = useBackForward();

// TODO: Fix intecepting the back button
onBackForward(() => {
  router.abort();
});

function handleToggleCancelDialog() {
  if (props.form.isDirty) {
    isCancelDialogOpen.value = !isCancelDialogOpen.value;
    return;
  }
  handleRedirect();
}

function handleRedirect() {
  router.navigate({ url: route('surveys.index') });
}

function handleCreationMethodUpdate(value) {
  props.form.resetFields('parent_survey_id', 'is_template', 'school_ids');
  props.form.fields.creation_method = value;
}

function handleIsTemplateUpdate(value) {
  props.form.resetFields('school_ids');
  props.form.fields.is_template = value;
}
</script>

<template>
  <Form @submit.prevent="form.submit">
    <template #header>
      <FormHeader
        canClose
        :title="`${isEditing ? 'Edit' : 'Add'} a Survey`"
        :description="
          isEditing
            ? 'Use the form below to edit the survey.'
            : 'Fill out the form below add a new survey.'
        "
        @onClose="handleToggleCancelDialog"
      />
    </template>
    <Fieldset title="Creation method">
      <RadioCardInput
        :radioInputs="[
          {
            label: 'Create a new survey',
            value: 'new',
            icon: FilePlus
          },
          {
            label: 'Duplicate existing survey',
            value: 'duplicate',
            icon: Copy
          }
        ]"
        :value="form.fields.creation_method"
        ariaLabel="Creation method"
        name="creation_method"
        @update:modelValue="handleCreationMethodUpdate($event)"
      />
    </Fieldset>
    <Fieldset title="General information">
      <FormTextInput
        :error="form.errors.name"
        :modelValue="form.fields.name"
        label="Survey name"
        name="name"
        placeholder="e.g. Academic Progress Check-in"
        @update:modelValue="(value) => (form.fields.name = value)"
      />
      <FormTextareaInput
        :error="form.errors.description"
        :modelValue="form.fields.description"
        label="Survey description"
        name="description"
        @update:modelValue="(value) => (form.fields.description = value)"
      />
      <FormSwitchInput
        v-if="form.fields.creation_method === 'new'"
        label="This new survey is a template"
        name="is_template"
        :checked="form.fields.is_template"
        @update:checked="handleIsTemplateUpdate($event)"
      />
      <FormSurveyInput
        v-if="form.fields.creation_method === 'duplicate'"
        label="Choose which survey to duplicate"
        :error="form.errors.parent_survey_id"
        :modelValue="form.fields.parent_survey_id"
        @update:modelValue="(value) => (form.fields.parent_survey_id = value)"
      />
    </Fieldset>
    <Fieldset v-if="!form.fields.is_template" title="Access and permissions">
      <FormProgramInput
        label="Filter schools by program"
        :error="form.errors.program_id"
        :modelValue="form.fields.program_id"
        isOptional
        placeholder="Select a program"
        @update:modelValue="(value) => (form.fields.program_id = value)"
      />
      <FormSchoolInput
        v-if="form.fields.creation_method === 'new'"
        label="School"
        :error="form.errors.school_ids"
        :modelValue="form.fields.school_ids[0]"
        :programIds="[form.fields.program_id?.value]"
        placeholder="Select a school"
        @update:modelValue="(value) => (form.fields.school_ids = [value])"
      />
      <FormMultiSchoolInput
        v-if="form.fields.creation_method === 'duplicate'"
        :selectAll="true"
        label="School"
        :error="form.errors.school_ids"
        :modelValue="form.fields.school_ids"
        :programIds="[form.fields.program_id?.value]"
        placeholder="Select schools"
        @update:modelValue="(value) => (form.fields.school_ids = value)"
      />
    </Fieldset>
    <template #footer>
      <FormFooter>
        <Button
          type="submit"
          :color="ButtonColor.primary"
          :isDisabled="form.processing || !form.isDirty"
          :isLoading="form.processing"
          :variant="ButtonVariant.solid"
          >Confirm</Button
        >
        <Button
          :color="ButtonColor.slate"
          :variant="ButtonVariant.outlined"
          type="button"
          @click="handleToggleCancelDialog"
        >
          Back to surveys
        </Button>
      </FormFooter>
    </template>
  </Form>
  <DangerDialog
    :title="`Cancel ${isEditing ? 'editing' : 'adding a new'} survey`"
    :message="`Are you sure you want to cancel ${
      isEditing ? 'editing' : 'adding'
    } this survey? All ${isEditing ? 'changes' : 'information'} will be lost.`"
    :isOpen="isCancelDialogOpen"
    cancelButtonLabel="Stay on this page"
    confirmButtonLabel="Go back to surveys"
    @onCancel="handleToggleCancelDialog"
    @onClose="handleToggleCancelDialog"
    @onConfirm="handleRedirect"
  />
</template>
