<script setup lang="tsx">
import { formatDateTime } from '@/utils/date';
import { ButtonVariant } from '@/hooks/useButtonClasses';
import { BadgeSize, BadgeVariant } from '@/hooks/useBadgeClasses';
import { statusColors } from '@/domains/reports/reportStatusColors';
import { AppliedFilters } from '@/components/distiller/types';

import { capitalize } from 'lodash';

import { formatDate } from '@/utils/date';

import Badge from '@/components/badge/Badge.vue';
import DefinitionList from '@/components/definitionList/DefinitionList.vue';
import DefinitionListItem from '@/components/definitionList/DefinitionListItem.vue';
import DefinitionListItemBadgeWrapper from '@/components/definitionList/DefinitionListItemBadgeWrapper.vue';
import Distiller from '@/components/distiller/Distiller.vue';
import FilterProvider from '@/components/distiller/FilterProvider.vue';
import IconButton from '@/components/button/IconButton.vue';
import InformationCollapsible from '@/components/collapsible/InformationCollapsible.vue';
import Slideout from '@/components/slideout/Slideout.vue';

import ComparisonIcon from '@/icons/line/comparison.svg';
import TextFieldsIcon from '@/icons/line/text-fields.svg';
import FileAltIcon from '@/icons/line/file-edit-alt.svg';
import CalendarIcon from '@/icons/line/calendar.svg';
import UserCircleIcon from '@/icons/line/user-circle.svg';
import StopwatchIcon from '@/icons/line/stopwatch.svg';
import DownloadAltIcon from '@/icons/line/download-alt.svg';
import RedoIcon from '@/icons/line/redo.svg';
import InfoCircleIcon from '@/icons/line/info-circle.svg';
import FilterIcon from '@/icons/line/filter.svg';

const props = defineProps<{
  reportResult: App.Reports.Data.ReportResultData;
  availableColumns: Record<string, App.Reports.Data.ColumnData>;
}>();

const { show, close } = useDialog();

const columnsInReport = computed(() => {
  return (
    props.reportResult.columns
      ?.map((column) => {
        return props.availableColumns[column.transientId] ?? null;
      })
      .filter(Boolean) ?? []
  );
});

function getFilterLabel(filter) {
  return filter
    .replace('_id', '')
    .split('_')
    .map((word) => {
      if (word === 'sms') {
        return 'SMS';
      }
      return capitalize(word);
    })
    .join(' ');
}
</script>


					<script lang="tsx">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <Slideout
    :title="reportResult.file_name ?? 'Report Result'"
    :isOpen="show"
    size="md"
    @onClose="close"
    :icon="ComparisonIcon"
  >
    <template #actions>
      <IconButton
        v-if="reportResult.download_url"
        :tooltipProps="{ side: 'top' }"
        :href="reportResult.download_url"
        :icon="DownloadAltIcon"
        :variant="ButtonVariant.soft"
        isExternalLink
        color="primary"
        ariaLabel="Download the report file"
      />

      <IconButton
        v-if="reportResult.finished_at"
        :tooltipProps="{ side: 'top' }"
        :href="route('report-results.custom-report.create', { reportResult: reportResult.uuid })"
        :icon="RedoIcon"
        :variant="ButtonVariant.soft"
        ariaLabel="Re-generate the report"
      />
    </template>

    <div class="-m-4 flex flex-col divide-y">
      <div class="p-4">
        <InformationCollapsible title="Report Information">
          <DefinitionList>
            <DefinitionListItem title="Status" :icon="InfoCircleIcon">
              <div>
                <Badge
                  :color="statusColors[reportResult.status.value] ?? 'primary'"
                  :label="reportResult.status.label"
                  :variant="BadgeVariant.soft"
                  size="sm"
                />
              </div>
            </DefinitionListItem>
            <DefinitionListItem
              title="Report"
              :description="reportResult.report.label"
              :icon="TextFieldsIcon"
            />
            <DefinitionListItem
              title="Template"
              :description="reportResult.template?.name ?? '--'"
              :icon="FileAltIcon"
            />
            <DefinitionListItem
              title="Created by"
              :description="reportResult.user?.name ?? '--'"
              :icon="UserCircleIcon"
            />
            <DefinitionListItem
              title="Started at"
              :description="formatDateTime(reportResult.started_at) as string"
              :icon="CalendarIcon"
            />
            <DefinitionListItem
              title="Run time"
              :description="reportResult.run_time || '--'"
              :icon="StopwatchIcon"
            />
          </DefinitionList>
        </InformationCollapsible>
      </div>

      <div class="p-4">
        <InformationCollapsible title="Filters">
          <DefinitionList v-if="reportResult.has_custom_filters">
            <DefinitionListItem
              v-for="(filter, key, index) in reportResult.filters"
              :key="key"
              :icon="index === 0 ? FilterIcon : undefined"
              :title="getFilterLabel(key)"
            >
              <template v-if="Array.isArray(filter)">
                <DefinitionListItemBadgeWrapper
                  v-if="filter.length"
                  :badgeItems="
                    filter.map((filter) => ({
                      label: filter?.label,
                      id: filter?.value
                    })) ?? []
                  "
                />
                <span class="text-sm text-slate-900" v-else>--</span>
              </template>
              <div v-else>
                <template v-if="filter">
                  <Badge
                    v-if="String(key) === 'start_date' || String(key) === 'end_date'"
                    :label="formatDate(filter)"
                    :size="BadgeSize.md"
                    :variant="BadgeVariant.soft"
                  />
                  <Badge
                    v-else
                    :label="filter?.label || filter"
                    :size="BadgeSize.md"
                    :variant="BadgeVariant.soft"
                  />
                </template>
                <span class="text-sm text-slate-900" v-else>--</span>
              </div>
            </DefinitionListItem>
          </DefinitionList>
          <FilterProvider
            v-if="Object.keys(reportResult.filters ?? {}).length && reportResult.distiller"
            v-model="reportResult.filters as unknown as AppliedFilters"
          >
            <Distiller isDisabled :distiller="reportResult.distiller" />
          </FilterProvider>
          <div v-else>
            <p class="text-sm text-slate-900">No filters applied for this report.</p>
          </div>
        </InformationCollapsible>
      </div>

      <div class="p-4" v-if="reportResult.columns?.length">
        <InformationCollapsible :title="`Columns (${reportResult.columns?.length ?? 0})`">
          <ol class="grid list-none grid-cols-[auto_1fr]">
            <li
              v-for="(column, index) in columnsInReport"
              :key="column.key"
              class="col-span-2 grid grid-cols-subgrid items-center gap-2 text-slate-700"
            >
              <p class="text-right font-mono text-sm font-light tabular-nums text-slate-500">
                {{ index + 1 }}.
              </p>
              <p class="text-sm text-slate-900">
                {{ column.title }}
              </p>
            </li>
          </ol>
        </InformationCollapsible>
      </div>
    </div>
  </Slideout>
</template>
