<script setup lang="tsx">
import pluralize from 'pluralize';

import { ButtonColor, ButtonSize, ButtonVariant } from '@/hooks/useButtonClasses';

import Button from '@/components/button/Button.vue';
import CenteredContent from '@/components/content/CenteredContent.vue';
import Divider from '@/components/divider/Divider.vue';
import FormHeader from '@/components/formHeader/FormHeader.vue';
import FormListboxInput from '@/components/formListboxInput/FormListboxInput.vue';

import BrowsingOnlineIllustration from '@/images/browsing-online-illustration.svg';
import SpreadsheetIllustration from '@/images/spreadsheet-illustration.svg';

const props = defineProps<{
  uploadType: App.Bulkinator.Data.UploadTypeData;
  testTypes: App.Base.Data.EnumData[];
}>();

const isStudentUploadType = computed(() => props.uploadType.id === 'student');

const pluralizedRecordName = computed(() => {
  return pluralize(props.uploadType.record_name, 2);
});

const selectedTestType = ref<App.Base.Data.EnumData | null>(null);
const customTestUploadType = computed(() => {
  if (selectedTestType.value) {
    return `test-${selectedTestType.value?.value}`;
  }

  return null;
});

function handleClose() {
  router.get(
    route('upload-sessions.draft.index', {
      uploadType: props.uploadType.id
    })
  );
}
</script>


					<script lang="tsx">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <CenteredContent class="px-4">
    <FormHeader
      title="Create a New Session"
      description="Select the creation method of your new session."
      canClose
      @onClose="handleClose"
    />

    <div v-if="uploadType.id === 'test'" class="space-y-12 py-6">
      <FormListboxInput v-model="selectedTestType" :options="testTypes" label="Select test type" />
      <div v-if="!selectedTestType">
        <p class="text-center text-lg font-semibold text-zinc-700">
          You must select a test type to continue with this upload session.
        </p>
      </div>
    </div>

    <div v-if="uploadType.id !== 'test' || selectedTestType" class="py-8">
      <div
        class="flex origin-top scale-110 flex-col items-center gap-4 lg:flex-row"
        :class="{
          'flex-col-reverse lg:flex-row-reverse': isStudentUploadType
        }"
      >
        <div
          class="space-y-6 rounded-lg px-6 py-12 text-center"
          :class="{
            'origin-right border border-zinc-200 lg:scale-80': isStudentUploadType,
            'border border-zinc-500 ring-3 ring-zinc-300/50': !isStudentUploadType
          }"
        >
          <h3 class="text-xl font-bold">
            Start from existing data<br />
            in the system
          </h3>

          <BrowsingOnlineIllustration class="mx-auto w-36" />

          <p class="text-sm font-medium text-zinc-500">
            Populate your upload session with data that are already in the system.
          </p>

          <Button
            :size="ButtonSize.lg"
            :variant="isStudentUploadType ? ButtonVariant.soft : ButtonVariant.solid"
            :color="isStudentUploadType ? ButtonColor.slate : ButtonColor.primary"
            :href="
              route('upload-sessions.create.existing', {
                uploadType: customTestUploadType ?? uploadType.id
              })
            "
            >Select {{ pluralizedRecordName }} in the system</Button
          >
          <Divider label="or" />
          <Button
            :size="ButtonSize.md"
            :variant="ButtonVariant.invisible"
            :color="ButtonColor.slate"
            :href="
              route('upload-sessions.create.empty', {
                uploadType: customTestUploadType ?? uploadType.id
              })
            "
            >Create empty new session</Button
          >
        </div>

        <div
          class="space-y-6 rounded-lg px-6 py-12 text-center"
          :class="{
            'origin-left border border-zinc-500 ring-3 ring-zinc-300/50 lg:scale-120':
              isStudentUploadType,
            'border border-zinc-200': !isStudentUploadType
          }"
        >
          <h3 class="text-lg font-bold">Upload an Excel file</h3>

          <SpreadsheetIllustration class="mx-auto w-36" />

          <p class="text-sm font-medium text-zinc-500">
            Create your upload session from an existing spreadsheet.
          </p>

          <Button
            :size="ButtonSize.lg"
            :variant="isStudentUploadType ? ButtonVariant.solid : ButtonVariant.soft"
            :color="isStudentUploadType ? ButtonColor.primary : ButtonColor.slate"
            :href="
              route('upload-sessions.create.manual', {
                uploadType: customTestUploadType ?? uploadType.id
              })
            "
            >Upload a file</Button
          >
        </div>
      </div>
    </div>
  </CenteredContent>
</template>
