<script setup lang="ts">
import { startOfDay, formatISO } from 'date-fns';
import VueDatePicker from '@vuepic/vue-datepicker';

import { ButtonColor, ButtonSize, ButtonVariant } from '@/hooks/useButtonClasses';

import Button from '@/components/button/Button.vue';

import '@vuepic/vue-datepicker/dist/main.css';

const todoTextarea = ref<HTMLTextAreaElement | null>(null);
const shouldPreventInteractOutside = inject('shouldPreventInteractOutside') as Ref<boolean>;

const form = useForm<{ text: string; due_date: string }>({
  method: 'POST',
  spoof: false,
  url: route('todos.store'),
  fields: {
    text: '',
    due_date: formatISO(startOfDay(new Date()))
  },
  hooks: {
    success() {
      router.reload({ only: ['todos'] });
    }
  }
});

function togglePreventInteractOutside() {
  shouldPreventInteractOutside.value = !shouldPreventInteractOutside.value;
}

function resizeTextarea() {
  if (todoTextarea.value) {
    todoTextarea.value.style.height = '1px';
    todoTextarea.value.style.height = todoTextarea.value.scrollHeight + 'px';
  }
}

watch(
  () => form.fields.text,
  () => {
    nextTick(resizeTextarea);
  }
);
onMounted(() => {
  nextTick(resizeTextarea);
});
</script>

<template>
  <form @submit.prevent="form.submit">
    <div
      class="overflow-hidden rounded-lg border border-slate-200 bg-white transition-colors duration-150 ease-in-out focus-within:border-slate-400"
      :class="{
        'opacity-50': form.processing
      }"
    >
      <div class="relative">
        <textarea
          :disabled="form.processing"
          class="block min-h-13 w-full resize-none border-0 px-3 py-1.5 text-[16px] leading-5 text-slate-700 focus:outline-none focus:ring-0 md:text-sm"
          name="todo"
          ref="todoTextarea"
          placeholder="Add task"
          v-model="form.fields.text"
          @input="resizeTextarea"
          @keydown.enter.exact.prevent="form.submit"
        />
      </div>
      <div class="border-t border-slate-200 bg-zinc-50 px-2 py-1">
        <div class="flex items-center justify-between gap-x-1">
          <div class="flex items-center gap-x-1 text-sm text-slate-500">
            <p class="leading-5">Due:</p>
            <VueDatePicker
              :clearable="false"
              :enableTimePicker="false"
              :required="true"
              :teleport="true"
              format="MMM dd, yyyy"
              position="right"
              v-model="form.fields.due_date"
              @closed="togglePreventInteractOutside"
              @open="togglePreventInteractOutside"
            >
              <template #dp-input="{ value }">
                <input
                  :value="value"
                  class="flex-inline cursor-pointer rounded-md border border-slate-200 bg-white px-1 py-0 text-sm text-slate-500 focus:ring-0"
                  name="due_date"
                  readonly
                />
              </template>
            </VueDatePicker>
          </div>
          <Button
            :color="ButtonColor.primary"
            :isLoading="form.processing"
            :size="ButtonSize.xs"
            :variant="ButtonVariant.solid"
            type="submit"
            >Add task</Button
          >
        </div>
        <div v-if="form.errors.text" class="px-2 pt-2">
          <p class="text-sm text-red-500">{{ form.errors.text }}</p>
        </div>
      </div>
    </div>
  </form>
</template>
