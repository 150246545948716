<script setup lang="ts">
type EnumData = App.Base.Data.EnumData;

import type { Selectable } from '@/components/selectBox/selectBox';

import CenteredContent from '@/components/content/CenteredContent.vue';
import SuccessStoryForm from '@/components/successStories/SuccessStoryForm.vue';

const props = defineProps<{
  subjects: EnumData[];
}>();

const form = useForm({
  method: 'POST',
  url: route('success-stories.store'),
  fields: {
    title: '',
    subject: props.subjects.find((item) => item.value === 'student') as Selectable<string> | null,
    school_id: null as Selectable<number> | null,
    student_id: null as Selectable<number> | null,
    is_parent: false,
    content_json: '',
    content_html: ''
  },
  transform: (fields) => ({
    ...fields,
    subject: fields.subject?.value,
    school_id: fields.school_id?.value,
    student_id: fields.student_id?.value
  })
});
</script>

					<script lang="ts">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <CenteredContent>
    <SuccessStoryForm :form="form" :subjects="subjects" />
  </CenteredContent>
</template>
