const current = ref(1);

export function useZindex() {
  function nextIndex() {
    current.value++;
    return current.value;
  }

  return {
    nextIndex
  };
}
