<script setup lang="tsx">
import { ButtonColor, ButtonVariant } from '@/hooks/useButtonClasses';

import Button from '@/components/button/Button.vue';
import EmptyState from '@/components/emptyState/EmptyState.vue';
import PageHeader from '@/components/pageHeader/PageHeader.vue';
import PageSection from '@/components/pageSection/PageSection.vue';
import Pagination from '@/components/pagination/Pagination.vue';
import UploadSessionCard from '@/domains/bulkinator/components/UploadSessionCard.vue';
import UploadSessionsTabNav from '@/domains/bulkinator/components/UploadSessionTabNav.vue';

import ClipboardsIllustration from '@/images/clipboards-illustration.svg';

defineProps<{
  title: string;
  description: string;
  emptyStateTitle: string;
  emptyStateDescription: string;
  uploadType: App.Bulkinator.Data.UploadTypeData;
  uploadSessions: Paginator<App.Bulkinator.Data.UploadSessionData>;
  testTypes: App.Base.Data.EnumData[];
  draftCount: number;
  finishedCount: number;
}>();
</script>


					<script lang="tsx">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <div class="divide-y-zinc-200 flex h-full flex-col divide-y">
    <PageHeader :title="`${uploadType.name} Bulkinator`">
      <template #rightButtons>
        <Button
          :color="ButtonColor.primary"
          :href="route('upload-sessions.create', { uploadType: uploadType.id })"
          :variant="ButtonVariant.solid"
          class="hidden md:inline-flex"
        >
          New {{ uploadType.record_name }} upload session
        </Button>
      </template>
    </PageHeader>

    <div class="flex grow flex-col">
      <UploadSessionsTabNav
        :uploadType="uploadType.id"
        :draftCount="draftCount"
        :finishedCount="finishedCount"
      />

      <PageSection :title="title" :description="description">
        <div v-if="uploadSessions.data.length" class="space-y-2">
          <UploadSessionCard
            v-for="uploadSession in uploadSessions.data"
            :key="uploadSession.id"
            :uploadSession="uploadSession"
            :testTypes="testTypes"
          />
        </div>
        <template v-else>
          <EmptyState :title="emptyStateTitle" :description="emptyStateDescription">
            <ClipboardsIllustration class="max-w-[292px]" />
          </EmptyState>
        </template>
      </PageSection>
    </div>
    <Pagination v-if="uploadSessions.data.length" :pagination="uploadSessions" />
  </div>
</template>
