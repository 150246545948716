<script setup lang="ts">
import { DateTimeFormat } from '@/components/dateTime/DateTime.vue';
import MessageAttachments from './MessageAttachments.vue';
import MessageBubble from './MessageBubble.vue';
import { useTemplateRef } from 'vue';

const props = withDefaults(
  defineProps<{
    message: App.Sms.Data.SmsData;
    showDateTime: boolean;
    dateTimeFormat?: DateTimeFormat;
    scrollIntoViewOnMount?: boolean;
    messageBubbleClasses?: unknown[];
  }>(),
  {
    dateTimeFormat: 'time'
  }
);

const rootEl = useTemplateRef<HTMLDivElement>('rootEl');

onMounted(() => {
  if (props.scrollIntoViewOnMount && rootEl.value) {
    rootEl.value.scrollIntoView();
  }
});
</script>

<template>
  <div
    ref="rootEl"
    class="flex flex-col gap-y-px"
    :class="{
      'items-end': message.direction.value === 'outbound'
    }"
  >
    <MessageAttachments :message="message" />
    <MessageBubble
      :class="messageBubbleClasses"
      :message="message"
      :showDateTime="showDateTime"
      :dateTimeFormat="dateTimeFormat"
      :scrollIntoViewOnMount="scrollIntoViewOnMount"
    />
  </div>
</template>
