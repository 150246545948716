<script setup lang="ts">
type EnumData = App.Base.Data.EnumData;
type SurveyData = App.Surveys.Data.SurveyData;

import CenteredContent from '@/components/content/CenteredContent.vue';
import ShowSurveyPageLayout from '@/components/surveys/surveys/ShowSurveyPageLayout.vue';
import SurveySettingsForm from '@/components/surveys/surveys/SurveySettingsForm.vue';

const props = defineProps<{
  survey: SurveyData;
  surveyStatuses: EnumData[];
}>();

const form = useForm({
  method: 'PUT',
  url: route('surveys.settings.update', { survey: props.survey.id }),
  preserveState: false,
  fields: {
    name: props.survey.name,
    status: props.survey.status,
    is_template: props.survey.is_template
  },
  transform: (fields) => ({
    ...fields,
    status: fields.status?.value
  })
});

const deleteForm = useForm({
  method: 'DELETE',
  url: route('surveys.delete', { survey: props.survey.id }),
  preserveState: false,
  fields: {} as Record<string, never>
});
</script>


					<script lang="ts">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <ShowSurveyPageLayout :survey="survey" :title="survey.name">
    <template #fullWidth>
      <CenteredContent>
        <SurveySettingsForm
          :survey="survey"
          :deleteForm="deleteForm"
          :form="form"
          :surveyStatuses="surveyStatuses"
        />
      </CenteredContent>
    </template>
  </ShowSurveyPageLayout>
</template>
