<script setup lang="ts">
type Block = App.Surveys.Data.SurveyBlockData;
type Survey = App.Surveys.Data.SurveyData;

import { ButtonColor, ButtonVariant } from '@/hooks/useButtonClasses';

import Button from '@/components/button/Button.vue';
import EmptyCard from '@/components/emptyCard/EmptyCard.vue';
import SurveyBlock from '@/components/surveys/sessoins/SurveyBlock.vue';

import ArrowRight from '@/icons/line/arrow-right.svg';
import Message from '@/icons/line/message.svg';

const props = defineProps<{
  survey: Survey;
  block: Block;
}>();

const blockCount = computed(() => (props.survey.blocks ? props.survey.blocks.length : null));
const currentQuestion = computed(() => props.block.order + 1);
const dependentBlock = computed(
  () => props.survey.blocks?.find((b) => b.id === props.block.choices_from_survey_block?.id)
);
</script>

<template>
  <div>
    <div class="flex max-w-142 flex-col gap-y-4">
      <p class="text-sm leading-5 text-slate-500">Question Preview</p>
      <div>
        <SurveyBlock :block="block" />
        <EmptyCard
          v-if="block.choices_from_survey_block"
          :actionHref="
            route('surveys.blocks.settings.show', {
              survey: survey.id,
              block: dependentBlock?.id
            })
          "
          :description="`The answer from &quot;${dependentBlock?.block_text}&quot; question will be used to determine which choices are available for this question.`"
          actionLabel="Edit Dependent Question Settings"
          class="bg-white"
          isDashed
          isRounded
          title="This question is dependent on the answer to another question."
          variant="invisible"
        />
      </div>
      <div class="flex grow items-center justify-between">
        <Button
          :color="ButtonColor.slate"
          :iconLeft="currentQuestion === blockCount ? Message : ArrowRight"
          :variant="ButtonVariant.outlined"
        >
          {{ currentQuestion === blockCount ? 'Submit' : 'Continue' }}
        </Button>
        <Button
          v-if="!block.is_required"
          :color="ButtonColor.slate"
          :variant="ButtonVariant.invisible"
          >Skip</Button
        >
      </div>
    </div>
  </div>
</template>
