<script setup lang="ts">
import CheckboxInput from '@/components/checkboxInput/CheckboxInput.vue';

defineProps<{
  hasError?: boolean;
  error?: string;
  label: string;
  checked: boolean;
}>();

const emit = defineEmits<{
  'update:checked': [checked: boolean];
}>();

function handleChecked(checked: boolean) {
  emit('update:checked', checked);
}
</script>

<template>
  <label
    class="flex cursor-pointer items-center gap-x-2 gap-y-1 rounded-lg border border-slate-200 bg-white px-3 py-2 has-[[data-orientation=vertical]]:flex-col-reverse has-[[data-orientation=vertical]]:text-center"
  >
    <CheckboxInput :checked="checked" @update:checked="handleChecked" />
    <span class="text-sm leading-5 text-slate-700">{{ label }}</span>
  </label>
</template>
