<script setup lang="ts">
type Column = App.Reports.Data.ColumnData;
type KeyedColumns = {
  [transientId: string]: Column;
};

import type { HybridlyFormData } from '@/@types/global';
import type { AppliedFilters } from '@/components/distiller/types';

import { Container, Draggable } from 'vue-dndrop';

import { useDragAndDrop } from '@/hooks/useDragAndDrop';

import ElipsisDoubleVAlt from '@/icons/line/elipsis-double-v-alt.svg';

const props = defineProps<{
  customReport: App.Reports.Data.CustomReportData;
  form: HybridlyFormData<{ filters: AppliedFilters }>;
}>();

const { applyDragForMultipleItems } = useDragAndDrop();

const selectedColumns = ref<string[]>([]);
const indexBeingDragged = ref<number | null>(null);
const isDragging = ref(false);

function walkColumns(acc: KeyedColumns, columnOrGroup: Column): KeyedColumns {
  if (columnOrGroup.columns) {
    return columnOrGroup.columns.reduce(walkColumns, acc);
  }

  acc[columnOrGroup.transientId] = columnOrGroup;
  return acc;
}

const keyedColumns = computed(() => {
  return (
    props.customReport.columns?.reduce(walkColumns, {} as KeyedColumns) ?? ({} as KeyedColumns)
  );
});

const orderedColumns = computed(() => {
  const keyedColumnsValue = keyedColumns.value;

  return props.form.fields.columns
    .map((transientId: string) => keyedColumnsValue[transientId])
    .filter(Boolean);
});

function handleColumnOrderChange(evt: unknown) {
  props.form.fields.columns = applyDragForMultipleItems(
    [...props.form.fields.columns],
    evt
  ) as string[];
  selectedColumns.value = [];
}

function handleDragStart() {
  isDragging.value = true;
}

function handleDragEnd() {
  isDragging.value = false;
}

function getChildPayload(index: number) {
  indexBeingDragged.value = index;

  return selectedColumns.value?.length
    ? selectedColumns.value
    : [orderedColumns.value[index]?.transientId];
}

function toggleColumnSelection(e: MouseEvent, id: string) {
  const isMarkingAsSelected = selectedColumns.value.includes(id);
  const ids = [id];

  if (e.shiftKey && selectedColumns.value.length > 0) {
    const startIndex = orderedColumns.value.findIndex(
      (column) => column.transientId === selectedColumns.value[0]
    );
    const endIndex = orderedColumns.value.findIndex((column) => column.transientId === id);

    const minIndex = Math.min(startIndex, endIndex);
    const maxIndex = Math.max(startIndex, endIndex);

    for (let i = minIndex; i <= maxIndex; i++) {
      ids.push(orderedColumns.value[i].transientId);
    }
  }

  selectedColumns.value = isMarkingAsSelected
    ? selectedColumns.value.filter((columnId) => !ids.includes(columnId))
    : [...new Set([...selectedColumns.value, ...ids])];
}
</script>

<template>
  <Container
    @drop="handleColumnOrderChange"
    @dragStart="handleDragStart"
    @dragEnd="handleDragEnd"
    dragHandleSelector=".handle"
    :getChildPayload="getChildPayload"
    lockAxis="y"
    class="flex flex-col space-y-1.5 rounded-md border-dashed border-slate-200 px-2 py-2.5"
  >
    <Draggable
      v-for="(column, i) in orderedColumns"
      :key="column.transientId"
      class="cursor-pointer rounded px-2 py-1 font-semibold"
      :class="
        selectedColumns.includes(column.transientId)
          ? [
              'bg-primary-50 ring-2 ring-primary-500/50 hover:bg-primary-100',
              {
                '!opacity-50': isDragging && indexBeingDragged !== i
              }
            ]
          : 'bg-slate-50 hover:bg-slate-100'
      "
    >
      <div class="flex items-center" @click="(e) => toggleColumnSelection(e, column.transientId)">
        <p class="flex-1 select-none truncate text-sm">
          {{ column.title }}
        </p>
        <ElipsisDoubleVAlt class="handle h-4 w-4 cursor-grab text-slate-500 hover:text-slate-700" />
      </div>
    </Draggable>
  </Container>
</template>
