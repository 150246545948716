import { useHead } from '@unhead/vue';
import { get } from 'lodash';

type BreadcrumbData = App.Base.Data.BreadcrumbData;

export function usePageMeta(): {
  breadcrumbs: ComputedRef<BreadcrumbData[]>;
} {
  const dialog = useDialog();
  const properties = useProperties() as {
    meta: App.Base.Data.PageMetaData;
  };

  const title = computed(() => {
    return (get(dialog.properties.value, 'meta.title') ?? properties.meta?.title ?? '') as string;
  });

  const breadcrumbs = computed(() => {
    const dialogBreadcrumbs = (dialog.properties.value?.breadcrumbs ?? []) as BreadcrumbData[];

    if (dialogBreadcrumbs.length > 0) {
      return dialogBreadcrumbs as BreadcrumbData[];
    }

    return properties.meta?.breadcrumbs ?? [];
  });

  useHead({
    title
  });

  return {
    breadcrumbs
  };
}
