<script setup lang="ts">
type Block = App.Surveys.Data.SurveyBlockData;
type EnumData = App.Base.Data.EnumData;
type Survey = App.Surveys.Data.SurveyData;

import { buildSelectableSurveyBlock, buildSelectableSurveyChoice } from '@/utils/buildSelectable';

import ShowSurveyPageLayout from '@/components/surveys/surveys/ShowSurveyPageLayout.vue';
import SurveyBlockPreview from '@/components/surveys/surveys/SurveyBlockPreview.vue';
import SurveyBlockSkipLogic from '@/components/surveys/surveys/SurveyBlockSkipLogic.vue';

const props = defineProps<{
  availableBlockOptions: Block[];
  block: Block;
  blockSkipLogicOptions: EnumData[];
  referencedBlocks: Block[];
  survey: Survey;
}>();

const computedSkipChoices = computed(() => {
  const skipLogicChoices = props.block?.skip_logic_choices;
  const selectedBlock = skipLogicChoices?.[0]?.selected_block;

  if (!selectedBlock?.dynamic_choice_type?.value) {
    return skipLogicChoices?.map((choice) => buildSelectableSurveyChoice(choice.choice)) ?? [];
  }

  return skipLogicChoices?.map((choice) => choice.answer_option);
});

const form = useForm({
  method: 'PUT',
  url: route('surveys.blocks.logic.update', {
    survey: props.survey.id,
    block: props.block.id
  }),
  updateInitials: true,
  fields: {
    block_text: props.block.block_text ?? '',
    skip_block_id:
      buildSelectableSurveyBlock(props.block.skip_logic_choices?.[0]?.selected_block) ?? null,
    skip_logic_operator: props.block.skip_logic_operator,
    skip_choices: computedSkipChoices.value
  },
  transform: (fields) => ({
    ...fields,
    skip_block_id: fields.skip_block_id?.value,
    skip_logic_operator: fields.skip_logic_operator?.value ?? null,
    skip_choices: formatSkipLogicChoice(fields.skip_choices)
  })
});

function formatSkipLogicChoice(skipLogicChoices) {
  const selectedBlock = props.availableBlockOptions.filter(
    (block) => block.id === form.fields.skip_block_id?.value
  )[0];

  const chocieType = selectedBlock?.dynamic_choice_type?.value;

  if (chocieType) {
    return skipLogicChoices?.map((choice) => ({
      survey_selected_block_id: selectedBlock.id,
      answer_id: choice.value,
      answer_type: chocieType,
      survey_choice_id: null
    }));
  }

  return skipLogicChoices?.map((choice) => ({
    survey_selected_block_id: selectedBlock.id,
    answer_id: null,
    answer_type: null,
    survey_choice_id: choice.value
  }));
}
</script>


					<script lang="ts">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <ShowSurveyPageLayout :survey="survey" :title="survey.name">
    <template #leftColumn>
      <SurveyBlockSkipLogic
        :availableBlockOptions="availableBlockOptions"
        :block="block"
        :blockSkipLogicOptions="blockSkipLogicOptions"
        :form="form"
        :referencedBlocks="referencedBlocks"
        :survey="survey"
      />
    </template>
    <SurveyBlockPreview :block="block" :survey="survey" />
  </ShowSurveyPageLayout>
</template>
