<script setup lang="ts">
import { ButtonColor, ButtonSize, ButtonVariant } from '@/hooks/useButtonClasses';

import Badge from '@/components/badge/Badge.vue';
import Button from '@/components/button/Button.vue';

import ExternalLinkAlt from '@/icons/line/external-link-alt.svg';

const props = defineProps<{
  badgeItems: { id: number; label: string }[] | [];
  badgeCount?: number;
  buttonRoute?: string;
  buttonLabel?: string;
}>();

const computedBadges = computed(() =>
  props.badgeCount ? props.badgeItems.slice(0, props.badgeCount) : props.badgeItems
);
</script>

<template>
  <div class="flex flex-col gap-y-2">
    <div v-if="badgeItems?.length" class="flex flex-wrap gap-1">
      <Badge
        v-for="badge in computedBadges"
        :label="badge.label"
        :key="badge.id"
        variant="soft"
        size="md"
      />
      <Badge
        v-if="badgeCount && badgeItems.length > badgeCount"
        :label="`+${badgeItems.length - badgeItems.slice(0, 3).length}`"
        variant="soft"
        size="md"
      />
    </div>
    <div v-if="buttonRoute && buttonLabel">
      <Button
        :color="ButtonColor.slate"
        :href="buttonRoute"
        :iconLeft="ExternalLinkAlt"
        :size="ButtonSize.xs"
        :variant="ButtonVariant.outlined"
      >
        {{ buttonLabel }}
      </Button>
    </div>
  </div>
</template>
