<script setup lang="ts" generic="T extends SelectableValue">
import FormMultiComboboxInput, {
  FormMultiComboboxProps
} from '@/components/formComboboxInput/FormMultiComboboxInput.vue';
import { Selectable, SelectableValue } from '@/components/selectBox/selectBox';
import { useProgramsSearch } from '@/hooks/search/useProgramsSearch';

const props = defineProps<Omit<FormMultiComboboxProps<number>, 'options' | 'searchable'>>();

defineEmits<{
  'update:modelValue': [Selectable<number>[]];
}>();

const { programOptions, isLoading, onQueryChange } = useProgramsSearch(props.searchFilters);
</script>

<template>
  <FormMultiComboboxInput
    v-bind="$props"
    :isLoading="isLoading"
    :options="programOptions"
    :onFilter="onQueryChange"
    @update:modelValue="(value) => $emit('update:modelValue', value)"
    @open="() => onQueryChange('')"
  />
</template>
