<script setup lang="ts">
import { PopoverClose } from 'radix-vue';
import { RouterLink } from 'hybridly/vue';
import SpinnerIcon from '@/icons/line/spinner.svg';

const props = withDefaults(
  defineProps<{
    description?: string;
    href?: string;
    iconLeft?: Component;
    iconRight?: Component;
    isExternalLink?: boolean;
    isLoading?: boolean;
    label?: string;
    size?: 'lg' | 'sm';
    variant?: 'basic' | 'advanced';
  }>(),
  {
    isLoading: false,
    size: 'lg',
    variant: 'basic'
  }
);

const emit = defineEmits<{
  click: [void];
}>();

const rawIconLeft = toRaw(props.iconLeft);
const rawIconRight = toRaw(props.iconRight);

const computedButtonComponent = computed(() => {
  if (props.isExternalLink && props.href) {
    return 'a';
  }
  return props.href ? RouterLink : 'button';
});

function onMenuItemClick() {
  emit('click');
}
</script>

<template>
  <PopoverClose as="div">
    <component
      v-bind="$attrs"
      class="my-1 flex w-full items-center gap-1.5 truncate whitespace-normal rounded-lg bg-white px-3 text-left text-sm leading-5 -outline-offset-1 transition-colors duration-150 ease-in-out hover:bg-slate-100 active:bg-slate-200"
      :class="{
        'py-2.5': props.size === 'lg',
        'py-1.5': props.size === 'sm'
      }"
      :href="href"
      :is="computedButtonComponent"
      @click="onMenuItemClick"
    >
      <slot>
        <div class="flex flex-col">
          <div class="flex w-full items-center gap-x-1.5 truncate">
            <SpinnerIcon v-if="isLoading" class="h-4 w-4 animate-spin text-secondary-900" />
            <component v-else-if="iconLeft" class="h-4 w-4 text-secondary-900" :is="rawIconLeft" />
            <span
              class="grow text-secondary-900"
              :class="{
                'font-semibold': description
              }"
            >
              {{ label }}
            </span>
            <component v-if="iconRight" class="h-4 w-4 text-secondary-900" :is="rawIconRight" />
          </div>
          <div v-if="description" class="max-w-86 text-slate-600">
            {{ description }}
          </div>
        </div>
      </slot>
    </component>
  </PopoverClose>
</template>
