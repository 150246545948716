import Echo, { Channel } from 'laravel-echo';
import Pusher from 'pusher-js';

export type WebsocketsContext = {
  listenPrivately: (event: string, callback: (evt: unknown) => void) => void;
  listenForNotification: (callback: (evt: unknown) => void) => void;
};

const pusherAppKey = window.Grace.pusher_app_key;
const pusherAppCluster = window.Grace.pusher_app_cluster;

if (!window.Pusher) {
  window.Pusher = Pusher;
}

if (!window.Echo && pusherAppKey && pusherAppCluster) {
  window.Echo = new Echo({
    broadcaster: 'pusher',
    key: pusherAppKey,
    cluster: pusherAppCluster,
    forceTLS: true
  });
}

function getChannel(channel: string): Channel | null {
  if (!window.Echo) {
    return null;
  }

  return window.Echo.private(channel);
}

function userChannel(): Channel | null {
  const user = useProperty<App.Auth.Data.UserData>('security.user');
  if (!user.value) {
    return null;
  }
  return getChannel(`user.${user.value.id}`);
}

export function onUserEvent(event: string, callback: (data: unknown) => void) {
  const channel = userChannel();
  onMounted(() => {
    channel?.listen(`.${event}`, callback);
  });
  onBeforeUnmount(() => {
    channel?.stopListening(event);
  });
}

export function onNotification(callback: (data: unknown) => void) {
  const channel = userChannel();
  onMounted(() => {
    channel?.notification(callback);
  });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function onEvent(channel: string, event: string, callback: (data: any) => void) {
  const channelInstance = getChannel(channel);
  onMounted(() => {
    channelInstance?.error((error) => {
      console.error('Error with Websocket message', error);
    });
    channelInstance?.listen(`.${event}`, callback);
  });
  onBeforeUnmount(() => {
    channelInstance?.stopListening(`.${event}`);
  });
}
