<script setup lang="ts">
import FormField from '@/components/formField/FormField.vue';
import UploadRecordInput from '@/domains/bulkinator/components/table/input/UploadRecordInput.vue';

const props = defineProps<{
  uploadRecord: App.Bulkinator.Data.UploadRecordData;
  column: App.Bulkinator.Data.UploadColumnData;
  isLocked: boolean;
}>();

const error = computed(() => {
  return props.uploadRecord.validation_results?.[props.column.name]?.[0] ?? null;
});
</script>

<template>
  <FormField :label="column.name" :hasError="!!error" :error="error">
    <template #default="{ id }">
      <UploadRecordInput
        class="flex-1"
        :id="id"
        :column="column"
        :row="uploadRecord"
        :value="uploadRecord.data[column.name] ?? null"
        :error="error"
        :isLocked="isLocked || column.readOnly"
      />
    </template>
  </FormField>
</template>
