<script setup lang="tsx">
import type { AppliedFilters } from '@/components/distiller/types';
import type { CustomCells } from '@/components/table/Table';

import { ButtonColor, ButtonSize, ButtonVariant } from '@/hooks/useButtonClasses';
import { useHybridlyTable } from '@/hooks/useHybridlyTable';

import Button from '@/components/button/Button.vue';
import EmptyState from '@/components/emptyState/EmptyState.vue';
import IndexPageLayout from '@/components/pageLayout/IndexPageLayout.vue';
import PaddedCellContent from '@/components/table/PaddedCellContent.vue';
import TableBadgesCell from '@/components/table/TableBadgesCell.vue';
import TableBooleanCell from '@/components/table/TableBooleanCell.vue';
import TableDateTimeCell from '@/components/table/TableDateTimeCell.vue';

import Building from '@/icons/line/building.svg';
import DownloadAlt from '@/icons/line/download-alt.svg';
import FilesLandscapeAlt from '@/icons/line/files-landscapes-alt.svg';

const props = defineProps<{
  escalations: Table<App.AdmitHub.Data.AdmitHubEscalationData>;
  appliedFilters: AppliedFilters;
  distiller: App.Search.Data.DistillerData;
}>();

const { table } = useHybridlyTable<App.AdmitHub.Data.AdmitHubEscalationData>(props, 'escalations');

const customCells = {
  student_id: ({ row, column }) => (
    <PaddedCellContent>{row.value(column.key).id}</PaddedCellContent>
  ),
  school: ({ row }) => {
    return (
      <TableBadgesCell
        badgesToDisplay={1}
        items={[row.value('student_id').school.name]}
        itemIcon={Building}
      />
    );
  },
  program: ({ row }) => {
    return (
      <TableBadgesCell
        badgesToDisplay={1}
        items={[row.value('student_id').school.program.display_name]}
        itemIcon={FilesLandscapeAlt}
      />
    );
  },
  contact: ({ row, column }) => (
    <PaddedCellContent>{row.value(column.key).contactable?.name}</PaddedCellContent>
  ),
  occurred_at: ({ row, column }) => <TableDateTimeCell date={row.value(column.key)} />,
  resolved_at: ({ row, column }) => {
    if (row.value(column.key)?.resolved_at) {
      return <TableDateTimeCell date={row.value(column.key)?.resolved_at} />;
    }

    return <TableBooleanCell boolean={false} />;
  }
} as CustomCells<App.AdmitHub.Data.AdmitHubEscalationData>;
</script>


					<script lang="tsx">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <IndexPageLayout
    title="Chatbot Escalations"
    :table="table"
    :appliedFilters="appliedFilters"
    :distiller="distiller"
    :customCells="customCells"
    :selectionsEnabled="false"
    :stickyEndColumns="1"
    :stickyStartColumns="2"
  >
    <template #rightButtons>
      <Button
        :iconLeft="DownloadAlt"
        :href="
          route('custom-reports.create', {
            customReport: 'mainstay-escalations-report'
          })
        "
        :color="ButtonColor.slate"
        :size="ButtonSize.sm"
        :variant="ButtonVariant.outlined"
      >
        Download escalations
      </Button>
    </template>
    <template #emptyState>
      <EmptyState
        title="No Chatbot escalations found"
        description="Chatbot escalations will appear on this page when they are available."
      />
    </template>
  </IndexPageLayout>
</template>
