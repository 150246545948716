<script setup lang="tsx">
import { BadgeVariant, BadgeColor, BadgeSize } from '@/hooks/useBadgeClasses';
import { ButtonColor, ButtonSize, ButtonVariant } from '@/hooks/useButtonClasses';
import { useAuth } from '@/hooks/useAuth';

import Badge from '@/components/badge/Badge.vue';
import Button from '@/components/button/Button.vue';
import IconButton from '@/components/button/IconButton.vue';
import Menu from '@/components/menu/Menu.vue';
import MenuItem from '@/components/menu/MenuItem.vue';
import ReportFavoriteButton from '@/domains/reports/components/ReportFavoriteButton.vue';
import ReportTemplateGenerateButton from './ReportTemplateGenerateButton.vue';
import Tooltip from '@/components/tooltip/Tooltip.vue';

import DocumentLayoutLeftIcon from '@/icons/line/document-layout-left.svg';
import EllipsisHIcon from '@/icons/line/ellipsis-h.svg';
import FileEditAltIcon from '@/icons/line/file-edit-alt.svg';
import ShareAltIcon from '@/icons/line/share-alt.svg';

const props = defineProps<{
  template: App.Reports.Data.ReportTemplateData;
  isFavorite: boolean;
}>();

const { user, hasPermission } = useAuth();

const isSharedTemplate = computed(() => {
  if (props.template.is_system_template) {
    return false;
  }

  return props.template.user?.id !== user.value?.id;
});

const badgeTooltipContent = computed(() => {
  if (props.template.is_system_template) {
    return 'This is a system template. You can generate reports directly from this template or use it as a starting point to create your own custom template. System templates cannot be edited.';
  }
  return 'This is a custom template. You can generate reports directly from this template, edit its settings, or use it as a base to create another custom template.';
});
</script>

<template>
  <div
    class="flex flex-col divide-y divide-slate-200 rounded-lg border border-slate-200 bg-white shadow-sm"
  >
    <div class="grow px-3.5 pb-4 pt-5">
      <div class="flex items-start justify-between">
        <div class="mt-1 flex gap-2">
          <h4 class="text-sm font-semibold text-slate-900">{{ template.name }}</h4>
          <Tooltip v-if="template.description" side="top" showArrow>
            <template #trigger>
              <DocumentLayoutLeftIcon class="h-3.5 w-3.5 text-slate-500" />
            </template>
            {{ template.description }}
          </Tooltip>
        </div>

        <div class="flex shrink-0 items-center gap-1">
          <Menu
            v-if="
              hasPermission('update-any-report-template') ||
              (template.is_system_template && hasPermission('update-system-report')) ||
              template.user?.id === user?.id
            "
          >
            <template #trigger>
              <IconButton
                :icon="EllipsisHIcon"
                :tooltipProps="{ side: 'top', showArrow: true }"
                ariaLabel="More actions"
                variant="invisible"
                color="slate"
                size="sm"
              />
            </template>
            <MenuItem
              :href="route('report-templates.edit', { reportTemplate: template.id })"
              :iconLeft="FileEditAltIcon"
              label="Edit report template"
            />
          </Menu>
          <ReportFavoriteButton :template="template" :isFavorite="isFavorite" />
        </div>
      </div>

      <p class="text-2xs">{{ template.report_type.name }}</p>

      <div class="mt-1 flex items-center gap-2 leading-none">
        <Tooltip side="top">
          {{ badgeTooltipContent }}

          <template #trigger>
            <Badge :variant="BadgeVariant.soft" :color="BadgeColor.slate" :size="BadgeSize.sm"
              >{{ template.is_system_template ? 'System' : 'Custom' }} template</Badge
            >
          </template>
        </Tooltip>

        <Tooltip v-if="isSharedTemplate" side="top" showArrow :sideOffset="5">
          This template was created by {{ template.user?.name ?? 'an unknown user' }} and was shared
          with you.

          <template #trigger>
            <Badge
              :iconLeft="ShareAltIcon"
              :variant="BadgeVariant.soft"
              :color="BadgeColor.slate"
              :size="BadgeSize.sm"
              >Shared</Badge
            >
          </template>
        </Tooltip>
      </div>
    </div>

    <div class="flex flex-wrap gap-2 px-3.5 py-2">
      <Button
        class="flex-1"
        :variant="ButtonVariant.soft"
        :color="ButtonColor.slate"
        :size="ButtonSize.sm"
        :hrefOptions="{
          method: 'post',
          data: {
            report_template_id: template.id
          }
        }"
        :href="
          route('custom-reports.create', {
            customReport: template.report_type.id
          })
        "
        >Use this template</Button
      >
      <ReportTemplateGenerateButton
        v-if="!template.requires_input && !template.is_system_template"
        :template="template"
      />
    </div>
  </div>
</template>
