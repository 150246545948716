<script setup lang="ts">
import { RouterLink } from 'hybridly/vue';

import { formatDate } from '@/utils/date';

import { ButtonColor, ButtonSize, ButtonVariant } from '@/hooks/useButtonClasses';
import { useTailwind } from '@/hooks/useTailwind';

import Badge from '@/components/badge/Badge.vue';
import Button from '@/components/button/Button.vue';
import Hyperlink from '@/components/links/Hyperlink.vue';

import ExternalLinkAlt from '@/icons/line/external-link-alt.svg';
import Copy from '@/icons/line/copy.svg';
import ExclamationCircle from '@/icons/line/exclamation-circle.svg';
import IndicatorDot from '@/icons/line/indicator-dot.svg';

const props = defineProps<{
  uploadSession: App.Bulkinator.Data.UploadSessionData;
  testTypes: App.Base.Data.EnumData[];
}>();

const { theme } = useTailwind();
const { width: windowWidth } = useWindowSize();

const isSmallScreen = computed(() => windowWidth.value <= parseInt(theme?.screens.md ?? '0px'));

const isFullRosterUpload = computed(() => {
  return props.uploadSession.meta?.disenroll_missing_students ?? false;
});

const testType = computed(() => {
  const testTypeId = props.uploadSession.meta?.test_type;
  if (testTypeId) {
    return props.testTypes.find((testType) => testType.value === testTypeId);
  }
  return null;
});
</script>

<template>
  <component
    :is="!isSmallScreen ? RouterLink : 'div'"
    :href="
      !isSmallScreen
        ? route('upload-sessions.show', { uploadSession: uploadSession.id })
        : undefined
    "
    class="group block cursor-pointer rounded-lg border border-slate-200 shadow-sm"
    :class="{
      'hover:border-slate-400 hover:shadow-md focus:border-slate-500 active:ring-3 active:ring-slate-300/30':
        !isSmallScreen
    }"
  >
    <div class="flex items-center justify-between rounded-t-lg bg-white px-4 pb-3 pt-5">
      <div class="space-y-1">
        <div class="flex items-center">
          <p class="font-semibold text-slate-900">{{ uploadSession.name }}</p>
          <p class="flex items-center gap-1 pl-2 text-sm text-slate-600">
            <Badge
              v-if="testType"
              :label="`${testType.label} Exam`"
              variant="soft"
              color="secondary"
            />
            <Badge
              v-if="uploadSession.type.value === 'disenrolled'"
              size="md"
              :iconLeft="IndicatorDot"
              iconColor="indicator-warning"
              label="Disenrolling upload"
              class="mt-px"
            />
            <Badge
              v-else-if="uploadSession.type.value === 'error'"
              size="md"
              color="danger"
              :iconLeft="ExclamationCircle"
              label="Error session"
              class="mt-px"
            />
            <Badge
              v-else-if="uploadSession.upload_type.value === 'student'"
              size="md"
              :iconLeft="IndicatorDot"
              :iconColor="isFullRosterUpload ? 'indicator-secondary' : 'indicator-primary'"
              :label="isFullRosterUpload ? 'Full roster upload' : 'Partial roster upload'"
              class="mt-px"
            />
          </p>
        </div>
        <p class="text-sm text-slate-600">
          <template v-if="uploadSession.parent_upload_session">
            {{
              uploadSession.type.value === 'manual'
                ? 'Duplicate from'
                : 'Automatically created from'
            }}
            <Badge
              :iconRight="ExternalLinkAlt"
              variant="soft"
              :label="uploadSession.parent_upload_session.name"
            />
          </template>
          <template v-else-if="uploadSession.file">
            Created from spreadsheet:
            <Hyperlink :href="uploadSession.file" :download="true" iconColor="slate">
              {{ uploadSession.original_filename }}
            </Hyperlink>
          </template>
          <template v-else> Created from scratch </template>
        </p>
      </div>

      <div class="flex items-center gap-1">
        <Button
          v-if="uploadSession.status.value === 'finished'"
          :color="ButtonColor.slate"
          :iconLeft="Copy"
          :size="ButtonSize.sm"
          :variant="ButtonVariant.soft"
          class="hidden md:flex"
          >Duplicate</Button
        >
        <Button
          :color="ButtonColor.slate"
          :size="ButtonSize.sm"
          :variant="ButtonVariant.soft"
          class="hidden md:flex"
          >View session</Button
        >
      </div>
    </div>

    <div
      class="flex flex-col justify-between gap-1 rounded-b-lg border-t border-slate-200 bg-slate-50 px-3 py-2 text-2xs text-slate-500 md:flex-row"
    >
      <div class="divide-x-slate 200 flex items-center gap-2 divide-x">
        <p>
          Created on
          <span class="text-slate-700">{{ formatDate(uploadSession.created_at) }}</span> by
          <span class="text-slate-700">{{ uploadSession.user?.name }}</span>
        </p>
      </div>

      <div class="flex flex-wrap items-center gap-1">
        <Badge size="sm" variant="soft" :label="`${uploadSession.new_records_count} Newly added`" />
        <Badge size="sm" variant="soft" :label="`${uploadSession.match_records_count} Updated`" />
        <Badge
          size="sm"
          variant="soft"
          :label="`${formatNumber(uploadSession.pending_records_count)} Potential matches`"
        />
        <Badge size="sm" variant="soft" :label="`${uploadSession.error_records_count} Errors`" />
      </div>
    </div>
  </component>
</template>
