<script setup lang="ts">
import type { FilterConditionData, FilterConditionType, OutboundMessageFilterData } from './types';
import type { Selectable } from '@/components/selectBox/selectBox';

import { useStorage } from '@vueuse/core';
import pluralize from 'pluralize';

import { index } from '@/api/FilterTypeApi';

import { ButtonColor } from '@/hooks/useButtonClasses';

import Badge from '@/components/badge/Badge.vue';
import Button from '@/components/button/Button.vue';
import Dialog from '@/components/dialog/Dialog.vue';
import FormField from '@/components/formField/FormField.vue';
import OutboundMessageFilterRow from './OutboundMessageFilterRow.vue';

import IconFilter from '@/icons/line/filter.svg';
import IconPlus from '@/icons/line/plus.svg';

const props = defineProps<{
  modelValue: OutboundMessageFilterData[];
  errors: Record<string, Record<string, string>>;
  isPaused: boolean;
}>();

const localSelectedFilters = ref<OutboundMessageFilterData[]>([...props.modelValue]);
const localErrors = ref<Record<string, Record<string, string>>>(props.errors);

watch(
  () => props.modelValue,
  (value) => (localSelectedFilters.value = [...value])
);
watch(
  () => props.errors,
  (value) => (localErrors.value = value)
);

const emit = defineEmits<{
  'update:modelValue': [modelValue: OutboundMessageFilterData[]];
}>();

const isDialogOpen = ref(false);

const filterConditions = useStorage<FilterConditionData[]>('smsFilterTypes', []);
onBeforeMount(async () => (filterConditions.value = (await index()).filters));

const errorCount = computed<number>(() => Object.keys(localErrors.value || {}).length);
const hasErrors = computed<boolean>(() => errorCount.value > 0);

const hasFilters = computed(() => localSelectedFilters.value.length > 0);

const filterBadgeLabel = computed(() =>
  pluralize('filter', localSelectedFilters.value.length, true)
);

const availableFilterConditionTypes = computed(() => {
  return filterConditions.value
    .filter(({ type }: FilterConditionData) => {
      return !localSelectedFilters.value.map(({ filter_type }) => filter_type).includes(type.value);
    })
    .map(({ type }: FilterConditionData): Selectable<FilterConditionType> => {
      return {
        label: type.label,
        value: type.value
      };
    });
});

function updateModelValue(value: OutboundMessageFilterData[]) {
  emit('update:modelValue', value);
  localErrors.value = {};
}

function handleClear() {
  updateModelValue([]);
}

function handleClearAndApply() {
  handleClear();

  isDialogOpen.value = false;
}

function handleApplyFilters() {
  updateModelValue(localSelectedFilters.value);
  isDialogOpen.value = false;
}

function handleResetAndClose() {
  localSelectedFilters.value = [...props.modelValue];
  isDialogOpen.value = false;
}

function removeFilter(filterType: FilterConditionType | undefined) {
  if (!filterType) {
    return;
  }

  localSelectedFilters.value = localSelectedFilters.value.filter(
    (filter) => filter.filter_type?.value !== filterType
  );
}

function addFilter() {
  localSelectedFilters.value.push({
    value: null,
    filter_type: null,
    filter_relations: null
  });
}

function handleFilterChange(filter: OutboundMessageFilterData, index: number): void {
  localSelectedFilters.value[index] = filter;
}
</script>

<template>
  <FormField
    :error="hasErrors ? `${errorCount} invalid ${pluralize('filter', errorCount)}` : undefined"
  >
    <span class="flex items-center gap-2">
      <Badge
        as="button"
        type="button"
        :iconLeft="hasFilters ? IconFilter : IconPlus"
        :label="hasFilters ? filterBadgeLabel : 'Add filter'"
        variant="outlined"
        dashed
        @click="isDialogOpen = true"
      />
      <Button
        v-if="hasFilters && isPaused"
        :color="ButtonColor.slate"
        size="xs"
        variant="invisible"
        @click="handleClearAndApply"
      >
        Clear filters
      </Button>
    </span>

    <Dialog
      :isOpen="isDialogOpen"
      title="Filers"
      :isLoading="false"
      contentClass="max-w-4xl"
      :cancelButtonLabel="isPaused ? 'Cancel' : undefined"
      confirmButtonLabel="Apply"
      :confirmButtonDisabled="!isPaused"
      @onCancel="handleResetAndClose"
      @onClose="isDialogOpen = false"
      @onConfirm="handleApplyFilters"
    >
      <template #header>
        <div class="flex items-center justify-between text-base font-semibold text-slate-900">
          Filters
        </div>
      </template>

      <div class="w-full flex-1 space-y-4 p-2">
        <ul class="flex flex-col space-y-2">
          <li
            v-if="localSelectedFilters.length === 0"
            class="flex flex-col items-center justify-center gap-2 py-2 text-center text-sm"
          >
            <span>No filters selected.</span>
            <Button
              :color="ButtonColor.slate"
              :isDisabled="availableFilterConditionTypes.length === 0"
              size="xs"
              type="button"
              variant="outlined"
              @click="addFilter"
            >
              Add a Filter
            </Button>
          </li>

          <OutboundMessageFilterRow
            v-for="(filter, index) in localSelectedFilters"
            :key="filter.filter_type?.value || index"
            :filter="filter"
            :filterConditions="filterConditions"
            :filterConditionTypes="availableFilterConditionTypes"
            :errors="errors?.[index]"
            :isPaused="isPaused"
            @remove="() => removeFilter(filter.filter_type?.value)"
            @filterChange="(filter) => handleFilterChange(filter, index)"
          />
        </ul>
      </div>

      <template #footerHelperText>
        <Button
          :color="ButtonColor.slate"
          :iconLeft="IconPlus"
          :isDisabled="availableFilterConditionTypes.length === 0 || !isPaused"
          type="button"
          variant="soft"
          @click="addFilter"
        >
          Add a Filter
        </Button>
      </template>
    </Dialog>
  </FormField>
</template>
