<script setup lang="ts">
import { ButtonColor, ButtonSize, ButtonVariant } from '@/hooks/useButtonClasses';
import { useFormHelpers } from '@/hooks/useFormHelpers';

import AuthForm from '@/components/form/AuthForm.vue';
import FormTextInput from '@/components/formTextInput/FormTextInput.vue';
import FormRows from '@/components/formRows/FormRows.vue';
import StackedFormFooter from '@/components/formFooter/StackedFormFooter.vue';
import Button from '@/components/button/Button.vue';
import AuthFormWrapper from '@/components/auth/AuthFormWrapper.vue';
import Alert from '@/components/alert/Alert.vue';

import IconExclamationTriangle from '@/icons/line/exclamation-triangle.svg';

import Logo from '@/images/cac-logo.svg';
import AbstractTriangle from '@/images/abstract-triangle.svg';

const login = useForm({
  method: 'POST',
  url: route('login.store'),
  fields: {
    email: '',
    password: ''
  }
});

const { isClean } = useFormHelpers(login);
</script>


					<script lang="ts">
					import __hybridly_layout_0 from '~/resources/domains/auth/layouts/two-columns-guest.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <AuthFormWrapper title="Log in to GRACE">
    <template v-slot:header>
      <div class="flex items-center justify-between">
        <div class="flex h-28 w-28 items-center rounded-full bg-slate-100">
          <Logo class="mx-auto h-10" />
        </div>
        <div class="w-[73px]">
          <AbstractTriangle />
        </div>
      </div>
    </template>

    <AuthForm @submit.prevent="login.submit">
      <FormRows>
        <FormTextInput
          label="Email"
          name="email"
          type="email"
          placeholder="Email"
          :modelValue="login.fields.email"
          :error="login.errors.email"
          @update:modelValue="(value: any) => (login.fields.email = value)"
        />
        <FormTextInput
          label="Password"
          name="password"
          type="password"
          placeholder="Password"
          :modelValue="login.fields.password"
          :error="login.errors.password"
          @update:modelValue="(value: any) => (login.fields.password = value)"
        />
      </FormRows>
      <Alert
        v-if="login.hasErrors"
        :icon="IconExclamationTriangle"
        variant="soft"
        color="danger"
        title="The credentials are incorrect"
        description="If you've forgotten your password we can help you recover it. Please click the Forgot Password button below."
      />
      <template #footer>
        <StackedFormFooter>
          <Button
            :color="ButtonColor.primary"
            :isDisabled="login.processing || isClean"
            :size="ButtonSize.lg"
            :variant="ButtonVariant.solid"
            type="submit"
            >Login</Button
          >
          <Button
            :color="ButtonColor.slate"
            :href="route('password.request')"
            :size="ButtonSize.lg"
            :variant="ButtonVariant.invisible"
            >Forgot Password</Button
          >
        </StackedFormFooter>
      </template>
    </AuthForm>
  </AuthFormWrapper>
</template>
