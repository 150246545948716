<script setup lang="ts">
import CenteredContent from '@/components/content/CenteredContent.vue';
import EditReportTemplateLayout from '@/domains/reports/components/EditReportTemplateLayout.vue';
import Fieldset from '@/components/fieldset/Fieldset.vue';
import ReportColumnOrderForm from '@/domains/reports/components/form/ReportColumnOrderForm.vue';

type ReportTemplateData = App.Reports.Data.ReportTemplateData;

defineProps<{
  template: ReportTemplateData;
}>();
</script>


					<script lang="ts">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <EditReportTemplateLayout :template="template">
    <template #default="{ form }">
      <CenteredContent :padded="false" size="md">
        <Fieldset
          title="Order report columns"
          description="Use the handles on the right to drag and drop columns to set the order that they'll appear in the report. You can hold the `Shift` key to select a range of columns."
        >
          <ReportColumnOrderForm :customReport="template.report_type" :form="form" />
        </Fieldset>
      </CenteredContent>
    </template>
  </EditReportTemplateLayout>
</template>
