<script setup lang="ts">
import TabNavDivider from '@/components/tabNav/TabNavDivider.vue';
import TabNavItem from '@/components/tabNav/TabNavItem.vue';

export interface IndexTestTypeTabNavCounts {
  apCourses: number;
  customTests: number;
}

defineProps<{
  counts: IndexTestTypeTabNavCounts;
}>();

const { matches } = useRoute();
</script>

<template>
  <TabNavItem
    variant="inline"
    :badgeLabel="counts.apCourses"
    :href="route('ap-courses.index')"
    :isActive="matches('ap-courses.index')"
    label="AP Courses"
  />
  <TabNavDivider layout="inline" />
  <TabNavItem
    :badgeLabel="counts.customTests"
    :href="route('custom-tests.index')"
    :isActive="matches('custom-tests.index')"
    label="Custom Tests"
    variant="inline"
  />
</template>
