<script setup lang="ts">
import type { UploadFormValues } from './useUploadSessionFormWizard';

import pluralize from 'pluralize';

import { ButtonColor, ButtonSize, ButtonVariant } from '@/hooks/useButtonClasses';

import Alert from '@/components/alert/Alert.vue';
import Button from '@/components/button/Button.vue';
import Divider from '@/components/divider/Divider.vue';
import Dropzone from '@/components/fileUpload/Dropzone.vue';
import Fieldset from '@/components/fieldset/Fieldset.vue';

import InfoCircle from '@/icons/line/info-circle.svg';

const props = defineProps<{
  processing?: boolean;
  uploadType: App.Bulkinator.Data.UploadTypeData;
  identifierLabels: string[];
  form: ReturnType<typeof useForm<UploadFormValues>>;
}>();

const emit = defineEmits<{ cancel: [void]; submit: [void]; drop: [File | undefined] }>();

function handleFileChange(file?: File) {
  props.form.fields.file = file;
  emit('drop', file);
}

function handleDownloadTemplate() {
  window.location.href = route('upload-sessions.template.download', {
    uploadType: props.uploadType.id
  });
}
</script>

<template>
  <form @submit.prevent="emit('submit')" class="flex flex-col gap-y-3">
    <section class="flex flex-col gap-y-5 px-5 py-6">
      <Alert
        title="Getting started with an upload session?"
        alignment="horizontal"
        :icon="InfoCircle"
        variant="soft"
        color="secondary"
      >
        <p>Please note the following things before proceeding further:</p>
        <ol class="list-inside list-decimal">
          <li>You can upload .xlsx or .csv file</li>
          <li>
            Make sure your file has correct identifier column headers to allow GRACE to
            automatically match already existing entries:
          </li>
          <ul class="list-inside list-disc pl-4">
            <li v-for="col in identifierLabels" :key="col">{{ col }}</li>
          </ul>
        </ol>
      </Alert>

      <Dropzone
        :modelValue="form.fields.file"
        @update:modelValue="handleFileChange"
        @error="(e) => form.setErrors({ file: e })"
        :accept="['.csv', '.xlsx']"
        :error="form.errors.file"
        :processing="processing || form.processing"
        :successful="form.successful"
      />

      <Fieldset title="File Template" description="Choose a template as your starting point.">
        <div class="space-y-4 text-center">
          <template v-if="uploadType.allows_template_downloads">
            <Button
              :color="ButtonColor.slate"
              :variant="ButtonVariant.soft"
              type="button"
              @click="handleDownloadTemplate"
              >Download a blank template</Button
            >
            <Divider label="or" />
          </template>
          <Button
            :color="ButtonColor.secondary"
            :href="route('upload-sessions.create.export.existing', { uploadType: uploadType.id })"
            :variant="ButtonVariant.solid"
            type="button"
          >
            Download a template with existing {{ pluralize(uploadType.name) }}
          </Button>
        </div>
      </Fieldset>
    </section>

    <section class="flex flex-row justify-end gap-x-2 px-5 py-2.5">
      <Button
        :color="ButtonColor.slate"
        :size="ButtonSize.sm"
        :variant="ButtonVariant.outlined"
        type="button"
        @click="$emit('cancel')"
        >Previous</Button
      >
      <Button
        :color="ButtonColor.primary"
        :disabled="!form.fields.file || form.errors.file"
        :isLoading="form.processing"
        :size="ButtonSize.sm"
        :variant="ButtonVariant.solid"
        type="submit"
        >Continue</Button
      >
    </section>
  </form>
</template>
