<script setup lang="ts">
import type { FormFieldProps, FormFieldSlots } from '@/components/formField/FormField';
import type { RadioInputProps } from '@/components/radioInput/RadioInput.vue';

import { useFormField } from '@/components/formField/useFormField';

import FormField from '@/components/formField/FormField.vue';
import RadioInput from '@/components/radioInput/RadioInput.vue';

type FormRadioInputProps = RadioInputProps & FormFieldProps;

const props = defineProps<FormRadioInputProps>();
defineSlots<FormFieldSlots>();

const emit = defineEmits<{
  'update:modelValue': [value: string];
}>();

function onModelValueChange(event: string) {
  emit('update:modelValue', event);
}

const { formFieldProps, slots } = useFormField({ props });
</script>

<template>
  <FormField v-bind="formFieldProps" isFieldset>
    <RadioInput
      :isDisabled="isDisabled"
      :value="value"
      v-bind="$props"
      @update:modelValue="onModelValueChange"
    />
    <template v-for="slot of slots" #[slot]>
      <slot :name="slot" />
    </template>
  </FormField>
</template>
