<script setup lang="ts">
import { HybridlyFormData } from '@/@types/global';

import { ButtonColor } from '@/hooks/useButtonClasses';

import Button from '@/components/button/Button.vue';
import DangerDialog from '@/components/dialog/DangerDialog.vue';
import Fieldset from '@/components/fieldset/Fieldset.vue';
import Form from '@/components/form/Form.vue';
import FormFooter from '@/components/formFooter/FormFooter.vue';
import FormHeader from '@/components/formHeader/FormHeader.vue';
import FormProgramInput from '@/components/formComboboxInput/FormProgramInput.vue';
import FormTextInput from '@/components/formTextInput/FormTextInput.vue';

const props = defineProps<{
  form: HybridlyFormData<{
    display_name: string;
    program_id: number;
    score: number;
    score2: number;
    score3: number;
    score4: number;
    score5: number;
  }>;
  isEditing?: boolean;
  returnUrl?: string;
}>();

const isCancelDialogOpen = ref(false);

const { onBackForward } = useBackForward();

// TODO: Fix intecepting the back button
onBackForward(() => {
  router.abort();
});

function handleToggleCancelDialog() {
  if (props.form.isDirty) {
    isCancelDialogOpen.value = !isCancelDialogOpen.value;
    return;
  }
  handleRedirect();
}

function handleRedirect() {
  router.navigate({ url: route('custom-tests.index') });
}
</script>

<template>
  <Form @submit.prevent="form.submit">
    <template #header>
      <FormHeader
        canClose
        :title="`${isEditing ? 'Edit' : 'Add'} a Custom Test`"
        :description="
          isEditing
            ? 'Use the form below to edit the custom test.'
            : 'Fill out the form below add a new custom test.'
        "
        @onClose="handleToggleCancelDialog"
      />
    </template>
    <Fieldset title="Test information">
      <FormTextInput
        type="text"
        name="display_name"
        label="Custom test name"
        :modelValue="form.fields.display_name"
        :error="form.errors.display_name"
        @update:modelValue="(value) => (form.fields.display_name = value)"
      />
      <FormProgramInput
        label="Program"
        :isDisabled="isEditing"
        :error="form.errors.program_id"
        :modelValue="form.fields.program_id"
        @update:modelValue="(value) => (form.fields.program_id = value)"
      />
    </Fieldset>
    <Fieldset title="Score types">
      <FormTextInput
        type="text"
        name="score"
        label="Score 1"
        :modelValue="form.fields.score"
        :error="form.errors.score"
        @update:modelValue="(value) => (form.fields.score = value)"
      />
      <FormTextInput
        type="text"
        name="score2"
        label="Score 2"
        :modelValue="form.fields.score2"
        :error="form.errors.score2"
        @update:modelValue="(value) => (form.fields.score2 = value)"
      />
      <FormTextInput
        type="text"
        name="score3"
        label="Score 3"
        :modelValue="form.fields.score3"
        :error="form.errors.score3"
        @update:modelValue="(value) => (form.fields.score3 = value)"
      />
      <FormTextInput
        type="text"
        name="score4"
        label="Score 4"
        :modelValue="form.fields.score4"
        :error="form.errors.score4"
        @update:modelValue="(value) => (form.fields.score4 = value)"
      />
      <FormTextInput
        type="text"
        name="score5"
        label="Score 5"
        :modelValue="form.fields.score5"
        :error="form.errors.score5"
        @update:modelValue="(value) => (form.fields.score5 = value)"
      />
    </Fieldset>
    <template #footer>
      <FormFooter>
        <Button
          :color="ButtonColor.primary"
          :isDisabled="form.processing || !form.isDirty"
          :isLoading="form.processing"
          type="submit"
          variant="solid"
          >Confirm</Button
        >
        <Button
          :color="ButtonColor.slate"
          type="button"
          variant="outlined"
          @click="handleToggleCancelDialog"
        >
          Back to custom tests
        </Button>
      </FormFooter>
    </template>
  </Form>
  <DangerDialog
    :title="`Cancel ${isEditing ? 'editing' : 'adding a new'} custom test`"
    :message="`Are you sure you want to cancel ${
      isEditing ? 'editing' : 'adding'
    } this custom test? All ${isEditing ? 'changes' : 'information'} will be lost.`"
    :isOpen="isCancelDialogOpen"
    cancelButtonLabel="Stay on this page"
    confirmButtonLabel="Go back to custom tests"
    @onCancel="handleToggleCancelDialog"
    @onClose="handleToggleCancelDialog"
    @onConfirm="handleRedirect"
  />
</template>
