<script setup lang="tsx">
import EmptyState from '@/components/emptyState/EmptyState.vue';
import TabNavItem from '@/components/tabNav/TabNavItem.vue';

import Envelope from '@/icons/line/envelope.svg';
import IndexPageLayout from '@/components/pageLayout/IndexPageLayout.vue';
import NotificationCell from '@/components/notifications/components/table/NotificationCell.vue';
import { CustomCells } from '@/components/table/Table';
import { useHybridlyTable } from '@/hooks/useHybridlyTable';
import NotificationHoverableDateInlineActionsCell from '@/components/notifications/components/table/NotificationHoverableDateInlineActionsCell.vue';
import NotificationReadStatusCell from '@/components/notifications/components/table/NotificationReadStatusCell.vue';
import BulkActionButton from '@/components/table/BulkActionButton.vue';
import BulkTrashButton from '@/components/table/BulkTrashButton.vue';
import CheckboxInput from '@/components/checkboxInput/CheckboxInput.vue';

const props = defineProps<{
  notifications: Table<App.Configuration.Data.IndexNotificationTableData>;
  counts: {
    read_count: number;
    unread_count: number;
  };
  read?: 'read' | 'unread' | 'all';
}>();

const { table, tableSelectionState } =
  useHybridlyTable<App.Configuration.Data.IndexNotificationTableData>(props, 'notifications');

const selectAllCheckboxValue = computed(() => {
  if (
    tableSelectionState.value?.arePageRecordsSelected ||
    tableSelectionState.value?.areAllRecordsSelected
  ) {
    return true;
  }

  if (tableSelectionState.value?.hasSelection) {
    return 'indeterminate';
  }

  return false;
});

const hasUnreadSelected = computed(() => {
  if (!tableSelectionState.value?.hasSelection) {
    return false;
  }

  const selectedIds = new Set(tableSelectionState.value.selectedRowIds);
  return props.notifications.records.some(
    (record) =>
      selectedIds.has((record.id as unknown as { value: string }).value) &&
      (record.read_at as unknown as { value: string })?.value === null
  );
});

const customCells = {
  read_at: ({ row, column }) => <NotificationReadStatusCell value={row.value(column.key)} />,
  data: ({ row, column }) => (
    <NotificationCell notificationId={row.key} value={row.value(column.key)} />
  ),
  created_at: ({ row, column }) => (
    <NotificationHoverableDateInlineActionsCell
      actions={row.actions}
      value={row.value(column.key)}
    />
  )
} as CustomCells<App.Configuration.Data.IndexNotificationTableData>;
</script>


					<script lang="tsx">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <IndexPageLayout
    title="Notifications"
    :table="table"
    :customCells="customCells"
    :stickyEndColumns="1"
    :stickyStartColumns="2"
    :tableSelectionState="tableSelectionState"
    view="list"
    :clickableRows="false"
  >
    <template #rightButtons>
      <BulkActionButton
        v-if="read === 'all' ? hasUnreadSelected : read !== 'read'"
        title="Mark selected as read"
        message=""
        color="slate"
        variant="invisible"
        method="post"
        :iconLeft="Envelope"
        :route="route('notifications.bulk-read')"
        :tableSelectionState="tableSelectionState"
        confirmButtonLabel=""
        :requiresConfirmation="false"
      />
      <BulkActionButton
        v-if="read === 'all' ? !hasUnreadSelected : read !== 'unread'"
        title="Mark selected as unread"
        message=""
        color="slate"
        variant="invisible"
        method="post"
        :iconLeft="Envelope"
        :route="route('notifications.bulk-unread')"
        :tableSelectionState="tableSelectionState"
        confirmButtonLabel=""
        :requiresConfirmation="false"
      />

      <BulkTrashButton
        recordType="Notifications"
        :route="route('notifications.bulk-delete')"
        :tableSelectionState="tableSelectionState"
      />
    </template>

    <template #tabs>
      <div class="px-4 py-2" @click.stop="() => tableSelectionState?.togglePageRows()">
        <CheckboxInput :checked="selectAllCheckboxValue" :isDisabled="table.records.length === 0" />
      </div>

      <TabNavItem
        :badgeLabel="counts.read_count + counts.unread_count"
        label="All"
        variant="inline"
        :href="route('notifications.index')"
        :isActive="read === 'all' || !read"
      />

      <TabNavItem
        :badgeLabel="counts.unread_count"
        label="Unread"
        variant="inline"
        :href="route('notifications.index', { read: 'unread' })"
        :isActive="read === 'unread'"
      />

      <TabNavItem
        :badgeLabel="counts.read_count"
        label="Read"
        variant="inline"
        :href="route('notifications.index', { read: 'read' })"
        :isActive="read === 'read'"
      />
    </template>

    <template #emptyState>
      <EmptyState title="No notifications" description="" />
    </template>
  </IndexPageLayout>
</template>
