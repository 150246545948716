<script setup lang="ts">
import DateInput from '@/components/dateInput/DateInput.vue';
import parse from 'date-fns/parse';
import formatISO from 'date-fns/formatISO';

const props = defineProps<{
  modelValue: string | null;
  name: string;
  isLoading: boolean;
  hasError: boolean;
  column: App.Bulkinator.Data.UploadColumnData;
  id?: string;
  isSuccessful?: boolean;
  isInline?: boolean;
}>();

const valueAsIso = computed(() => {
  if (!props.modelValue) {
    return null;
  }

  const formats = ['MM/dd/yyyy', 'MM-dd-yyyy', 'yyyy-MM-dd'];

  for (const format of formats) {
    try {
      const parsed = parse(props.modelValue, format, new Date());

      return formatISO(parsed);
    } catch (e) {
      // no op
    }
  }

  return props.modelValue;
});

const emit = defineEmits<{
  'update:modelValue': [value: string | null];
  saveValue: [];
}>();

function handleValueChange(value: string | null) {
  emit('update:modelValue', value);
  emit('saveValue');
}
</script>

<template>
  <DateInput
    :modelValue="valueAsIso"
    :id="id"
    :name="name"
    :isLoading="isLoading"
    :isSuccessful="isSuccessful"
    :hasError="hasError"
    format="MM/dd/yyyy"
    outputFormat="MM/dd/yyyy"
    @update:modelValue="handleValueChange"
    @blur="$emit('saveValue')"
    :inputClasses="
      isInline
        ? [
            '!h-10 !border-none !rounded-sm !bg-transparent',
            {
              '!bg-red-200 !text-red-800': hasError
            }
          ]
        : null
    "
  />
</template>

<style scoped>
:deep(> div),
:deep(.dp__input_wrap) {
  height: 100%;
}
</style>
