<script setup lang="ts">
import type { AppliedFilters } from '@/components/distiller/types';

import pluralize from 'pluralize';

import { useFilters } from '@/hooks/useFilters';
import { ButtonColor, ButtonSize, ButtonVariant } from '@/hooks/useButtonClasses';

import Button from '@/components/button/Button.vue';
import Dialog from '@/components/dialog/Dialog.vue';
import Distiller from '@/components/distiller/Distiller.vue';
import FilterProvider from '@/components/distiller/FilterProvider.vue';
import FilterTemplates from '@/components/filters/filterTemplate/FilterTemplates.vue';
import FormError from '@/components/formError/FormError.vue';

import SpinnerIcon from '@/icons/line/spinner.svg';

const props = defineProps<{
  title: string;
  distiller: App.Search.Data.DistillerData;
  studentGroup: App.Students.Data.StudentGroupData;
}>();

const {
  filteredRecordsCount,
  formattedFilteredRecordsCount,
  getRecordCount,
  isLoadingRecordCount
} = useFilters();

const isOpen = ref(false);
const isLoading = ref(false);
const appliedFilters = ref({});

const appliedFiltersCount = computed(() =>
  appliedFilters.value ? Object.keys(appliedFilters.value).length : 0
);

const appliedFiltersQueryStringValue = computed(() =>
  appliedFiltersCount.value > 0 ? btoa(JSON.stringify(appliedFilters.value)) : undefined
);

const form = useForm({
  method: 'PUT',
  spoof: false,
  url: route('student-groups.student.update', {
    studentGroup: props.studentGroup.id
  }),
  fields: {
    filters: appliedFilters.value || null
  },
  transform: (fields) => ({
    ...fields,
    filters: appliedFilters.value
  }),
  hooks: {
    success() {
      closeFilters();
    },
    after() {
      isLoading.value = false;
    }
  }
});

function handleFilterTemplateChange(filterTemplate: App.Search.Data.FilterTemplateData | null) {
  if (!filterTemplate) {
    return;
  }

  appliedFilters.value = filterTemplate.filters as unknown as AppliedFilters;
}

function handleClear() {
  appliedFilters.value = {};
}

function openFilters() {
  isOpen.value = true;
}

function closeFilters() {
  appliedFilters.value = {};

  isOpen.value = false;
}

function handleAddStudents() {
  isLoading.value = true;
  form.submit();
}

onMounted(() => {
  getRecordCount(props.distiller, appliedFiltersQueryStringValue.value);
});

watch(
  () => appliedFilters.value,
  () => {
    getRecordCount(props.distiller, appliedFiltersQueryStringValue.value);
  },
  { deep: true }
);
</script>

<template>
  <Button
    :color="ButtonColor.primary"
    :size="ButtonSize.sm"
    :variant="ButtonVariant.solid"
    @click="openFilters"
  >
    Add students to the student group
  </Button>
  <Dialog
    :isOpen="isOpen"
    :title="title"
    :isLoading="isLoading"
    contentClass="max-w-4xl"
    :confirmButtonDisabled="!appliedFiltersCount"
    confirmButtonLabel="Add matched students to group"
    @onCancel="closeFilters"
    @onClose="closeFilters"
    @onConfirm="handleAddStudents"
  >
    <template #footerHelperText>
      <SpinnerIcon v-if="isLoadingRecordCount" class="h-4 w-4 animate-spin text-secondary-900" />
      <p v-else class="text-sm font-semibold text-slate-700">
        Matches
        {{ formattedFilteredRecordsCount }}
        {{ pluralize(distiller.name?.label ?? 'Record', filteredRecordsCount?.count, false) }}
      </p>
    </template>
    <template #header>
      <div class="flex items-center justify-between text-base font-semibold text-slate-900">
        Manage Students
        <div class="flex items-center gap-4 divide-x divide-gray-100">
          <FilterTemplates
            :title="title"
            :distiller="distiller"
            :appliedFilters="appliedFilters"
            @update:filterTemplate="handleFilterTemplateChange"
          />
          <div v-if="appliedFiltersCount > 0" class="border-l border-gray-100 pl-4">
            <Button
              :variant="ButtonVariant.invisible"
              :size="ButtonSize.sm"
              :color="ButtonColor.slate"
              @click="handleClear"
              >Clear all filters</Button
            >
          </div>
        </div>
      </div>
    </template>
    <div class="w-full flex-1 space-y-4">
      <p v-if="appliedFiltersCount === 0" class="text-sm text-slate-700">
        Use the filters below to find the students which you wish to add to this student group.
      </p>
      <FilterProvider v-model="appliedFilters">
        <Distiller :distiller="distiller" />
        <FormError>{{ form.errors.filters }}</FormError>
      </FilterProvider>
    </div>
  </Dialog>
</template>
