<script setup lang="ts">
import { formatDate, formatDateTime, formatTime } from '@/utils/date';
import { isPast, isWeekend, isToday } from 'date-fns';
import VueDatePicker, { DatePickerInstance } from '@vuepic/vue-datepicker';
import Tooltip from '@/components/tooltip/Tooltip.vue';

export type DateTimeFormat = 'date' | 'time' | 'datetime';

const date = defineModel<string | null>('date', { required: false, default: null });

const props = withDefaults(
  defineProps<{
    format?: DateTimeFormat;
    toLocalTimezone?: boolean;
    picker?: boolean;
    timezone?: string;
    displayTimezone?: boolean;
  }>(),
  {
    format: 'date'
  }
);

const formats = {
  date: formatDate,
  time: formatTime,
  datetime: formatDateTime
};

const formattedDate = computed(() => {
  const formatted = formats[props.format](date.value);
  const timezoneToDisplay = props.timezone ?? Intl.DateTimeFormat().resolvedOptions().timeZone;

  if (!props.displayTimezone || !timezoneToDisplay) {
    return formatted;
  }

  return `${formatted} ${timezoneToDisplay}`;
});

const datePicker = ref<DatePickerInstance>();

function disabledDates(date: Date) {
  if (isToday(date)) {
    return false;
  }
  return isPast(date) || isWeekend(date);
}

interface MinTime {
  hours?: number | string;
  minutes?: number | string;
  seconds?: number | string;
}

const minTime = ref<MinTime | undefined>();

function handleDateSelected(date: Date) {
  if (isToday(date)) {
    const now = new Date();

    minTime.value = {
      hours: now.getHours(),
      minutes: now.getMinutes(),
      seconds: now.getSeconds()
    };

    return;
  }

  minTime.value = undefined;
}

defineExpose({ datePicker });
</script>

<template>
  <VueDatePicker
    v-if="picker"
    ref="datePicker"
    v-model="date"
    :timezone="timezone"
    format="MMM dd, yyyy hh:mm a"
    class="inline w-fit cursor-pointer hover:underline"
    @internalModelChange="handleDateSelected"
    :disabledDates
    :minTime
    :teleport="true"
  >
    <template #day="{ day, date }">
      <slot name="day" v-bind="{ day, date }">
        <template v-if="isWeekend(date)">
          <Tooltip :delayDuration="250">
            <template #trigger>{{ day }}</template>
            We're unable to support scheduling on weekends due to support availability.
          </Tooltip>
        </template>
        <template v-else>
          {{ day }}
        </template>
      </slot>
    </template>
    <template #trigger>
      <slot>
        <time :datetime="date ?? undefined">{{ formattedDate }}</time>
      </slot>
    </template>
  </VueDatePicker>
  <time v-else :datetime="date ?? undefined">{{ formattedDate }}</time>
</template>
