<script setup lang="tsx">
import UploadSessionSettingsForm from '@/domains/bulkinator/components/forms/UploadSessionSettingsForm.vue';
import IconButton from '@/components/button/IconButton.vue';
import GuidedSteps from '@/components/guidedSteps/GuidedSteps.vue';
import GuidedStepsContainer from '@/components/guidedSteps/GuidedStepsContainer.vue';
import PageHeader from '@/components/pageHeader/PageHeader.vue';
import Times from '@/icons/line/times.svg';
import StepContainer from '@/components/stepContainer/StepContainer.vue';
import {
  defaultCreateFormValues,
  transformCreateFormValues,
  useUploadSessionFormWizard
} from '@/domains/bulkinator/components/forms/useUploadSessionFormWizard';

const props = defineProps<{
  uploadType: App.Bulkinator.Data.UploadTypeData;
  testType?: App.Base.Data.EnumData;
}>();

const { guidedSteps, steps, currentStep, form } = useUploadSessionFormWizard(
  {
    config: {
      create: {
        label: 'Create',
        description: 'Set and create upload session',
        subtitle: 'Almost done'
      }
    },
    order: ['create']
  },
  {
    method: 'POST',
    url: route('upload-sessions.empty.store'),
    fields: defaultCreateFormValues({
      uploadType: props.uploadType.name,
      testType: props.testType?.value
    }),
    updateInitials: true,
    transform: transformCreateFormValues(props.uploadType)
  }
);

function handleClose() {
  router.navigate({
    url: route('upload-sessions.create', {
      uploadType: props.uploadType.id,
      testType: props.testType?.value
    })
  });
}
</script>


					<script lang="tsx">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <PageHeader title="Create an empty session" :centered="true">
    <template #rightButtons>
      <IconButton
        :icon="Times"
        @click="handleClose"
        ariaLabel="Cancel empty session creation"
        size="sm"
        variant="invisible"
      />
    </template>
  </PageHeader>

  <GuidedStepsContainer class="border-y px-20">
    <GuidedSteps
      :steps="steps"
      :currentStep="guidedSteps.index.value"
      direction="horizontal"
      :showStepNumber="false"
      :showBadge="false"
      class="mx-auto"
    />
  </GuidedStepsContainer>

  <StepContainer :title="currentStep.description" :description="currentStep.subtitle">
    <section v-if="guidedSteps.current.value === 'create'">
      <div class="mx-auto max-w-2xl">
        <UploadSessionSettingsForm
          :form="form"
          :uploadType="uploadType"
          @submit="form.submit"
          @cancel="handleClose"
        />
      </div>
    </section>
  </StepContainer>
</template>
