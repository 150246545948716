<script setup lang="tsx">
import type { CustomCells, HybridlyRow } from '@/components/table/Table';

import { ButtonColor, ButtonSize, ButtonVariant } from '@/hooks/useButtonClasses';
import { useAuth } from '@/hooks/useAuth';
import { useFilters } from '@/hooks/useFilters';
import { useHybridlyTable } from '@/hooks/useHybridlyTable';
import { useTableRowClick } from '@/hooks/useTableRowClick';

import Button from '@/components/button/Button.vue';
import EmptyState from '@/components/emptyState/EmptyState.vue';
import IndexPageLayout from '@/components/pageLayout/IndexPageLayout.vue';
import TabNavDivider from '@/components/tabNav/TabNavDivider.vue';
import TabNavItem from '@/components/tabNav/TabNavItem.vue';
import TableBadgesCell from '@/components/table/TableBadgesCell.vue';
import BulkTrashButton from '@/components/table/BulkTrashButton.vue';
import BulkRestoreButton from '@/components/table/BulkRestoreButton.vue';
import { AppliedFilters } from '@/components/distiller/types';

import LayerGroup from '@/icons/line/layer-group.svg';
import MapPin from '@/icons/line/map-pin.svg';
import Plus from '@/icons/line/plus.svg';
import UserCircle from '@/icons/line/user-circle.svg';
import TableTruncatedTextCell from '@/components/table/TableTruncatedTextCell.vue';

type ProgramRow = HybridlyRow<App.Configuration.Data.ProgramData>;

const props = defineProps<{
  programs: Table<App.Configuration.Data.ProgramData>;
  appliedFilters: AppliedFilters;
  distiller: App.Search.Data.DistillerData;
  trashActive: boolean;
  counts: {
    active_count: number;
    trashed_count: number;
  };
}>();

const { hasPermission } = useAuth();

const { table, tableSelectionState } = useHybridlyTable<App.Configuration.Data.ProgramData>(
  props,
  'programs'
);
const { handleRowClick } = useTableRowClick();
const { encodeFilters } = useFilters();

const encodedFilters = computed(() => encodeFilters(props.appliedFilters));

const customCells = {
  display_name: ({ row, column }) => <TableTruncatedTextCell text={row.value(column.key)} />,
  users: ({ row, column }) => (
    <TableBadgesCell
      badgesToDisplay={2}
      itemIcon={UserCircle}
      items={row.value(column.key).map((user: App.Auth.Data.UserData) => user.name)}
    />
  ),
  region: ({ row, column }) => (
    <TableBadgesCell
      badgesToDisplay={1}
      itemIcon={MapPin}
      items={[row.value(column.key).display_name]}
    />
  )
} as CustomCells<App.Configuration.Data.ProgramData>;

function handleProgramRowClick(event: MouseEvent, row: ProgramRow) {
  const url = route('programs.show', {
    program: row.key
  });
  handleRowClick(url, event);
}
</script>


					<script lang="tsx">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <IndexPageLayout
    title="Programs"
    :table="table"
    :appliedFilters="appliedFilters"
    :distiller="distiller"
    :customCells="customCells"
    :stickyEndColumns="1"
    :stickyStartColumns="2"
    :clickableRows="!trashActive"
    :tableSelectionState="tableSelectionState"
    @clickRow="($event, row) => handleProgramRowClick($event, row as ProgramRow)"
  >
    <template #rightButtons>
      <template v-if="tableSelectionState.hasSelection">
        <BulkTrashButton
          v-if="!trashActive"
          recordType="Programs"
          :route="route('programs.bulk-delete')"
          :tableSelectionState="tableSelectionState"
        />
        <BulkRestoreButton
          v-if="trashActive"
          recordType="Programs"
          :route="route('programs.bulk-restore')"
          :tableSelectionState="tableSelectionState"
        />
      </template>

      <template v-else>
        <Button
          v-if="hasPermission('create-program')"
          :color="ButtonColor.slate"
          :href="route('upload-sessions.draft.index', { uploadType: 'program' })"
          :iconLeft="LayerGroup"
          :size="ButtonSize.sm"
          :variant="ButtonVariant.soft"
        >
          Bulk add
        </Button>
        <Button
          v-if="hasPermission('create-program')"
          :color="ButtonColor.primary"
          :href="route('programs.create')"
          :iconLeft="Plus"
          :size="ButtonSize.sm"
          :variant="ButtonVariant.solid"
        >
          Add a program
        </Button>
      </template>
    </template>

    <template #tabs>
      <TabNavItem
        :badgeLabel="counts.active_count"
        :isActive="!trashActive"
        label="Active"
        variant="inline"
        :href="
          route('programs.index', {
            ...(encodedFilters ? { filters: encodedFilters } : {})
          })
        "
      />
      <TabNavDivider layout="inline" />
      <TabNavItem
        :badgeLabel="counts.trashed_count ?? 0"
        :isActive="trashActive"
        label="Trashed"
        variant="inline"
        :href="
          route('programs.index', {
            trashed: true,
            ...(encodedFilters ? { filters: encodedFilters } : {})
          })
        "
      />
    </template>

    <template #emptyState>
      <EmptyState
        v-if="trashActive"
        title="No programs were moved to the trash"
        description="Trashed programs will appear on this screen"
      />
      <EmptyState
        v-else
        title="No programs found"
        :description="
          hasPermission('create-program') ? 'Create a new program by clicking the button below' : ''
        "
        :actionLabel="hasPermission('create-program') ? 'Add a program' : ''"
        :actionHref="route('programs.create')"
      />
    </template>
  </IndexPageLayout>
</template>
