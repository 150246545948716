// @ts-expect-error - Can't figure out how to import virtual module correctly
import { initializeHybridly } from 'virtual:hybridly/config';
import { createHead, useHead } from '@unhead/vue';
import * as Sentry from '@sentry/vue';
import { App } from 'vue';

import './tailwind.css';
import { definePlugin } from 'hybridly';

function UrlPlugin() {
  return definePlugin({
    name: 'hybridly:url-plugin',
    initialized(context) {
      if (context.routing) {
        context.routing.url = window.Grace.app_url;
      }
    }
  });
}

initializeHybridly({
  responseErrorModals: true,
  plugins: [UrlPlugin()],
  enhanceVue: (vue, payload) => {
    vue.use(createHead());

    setupSentry(vue, payload);

    useHead({
      titleTemplate: (title) => (title ? `${title} — GRACE` : 'GRACE')
    });
  }
});

type Payload = {
  view: {
    properties: {
      security: {
        user: {
          id: number;
          email: string;
          name: string;
        };
      };
    };
  };
};

interface HttpError extends Error {
  response?: {
    status?: number;
  };
  message: string;
}

function setupSentry(vue: App<Element>, payload: Payload) {
  const sentryDsn = window.Grace.sentry_dsn_public;

  if (!sentryDsn) {
    return;
  }

  Sentry.init({
    app: vue,
    dsn: sentryDsn,
    environment: window.Grace.env,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        maskAllInputs: true
      })
    ],
    tracesSampleRate: 0.3,
    tracePropagationTargets: [window.Grace.app_url],
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0,

    beforeSend(event, hint) {
      const error = hint.originalException as HttpError;

      if (
        error &&
        error.response &&
        (error.response.status === 401 || error.response.status === 419)
      ) {
        return null;
      }
      return event;
    }
  });

  if (payload?.view?.properties?.security?.user) {
    const user = payload.view.properties.security.user;
    Sentry.setUser({
      id: user.id,
      email: user.email,
      name: user.name
    });
  }
}
