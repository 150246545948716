<script setup lang="ts">
import EmptyCard from '@/components/emptyCard/EmptyCard.vue';
import EmptyState from '@/components/emptyState/EmptyState.vue';
import PageHeader from '@/components/pageHeader/PageHeader.vue';
import ShowStudentPageLayout from '@/components/students/ShowStudentPageLayout.vue';
import StudentSurveyCard from '@/components/students/surveys/StudentSurveyCard.vue';
import StudentSurveyCardList from '@/components/students/surveys/StudentSurveyCardList.vue';
import StudentSurveySessionCard from '@/components/students/surveys/StudentSurveySessionCard.vue';
import StudentSurveySessionCardList from '@/components/students/surveys/StudentSurveySessionCardList.vue';

defineProps<{
  student: App.Students.Data.StudentData;
  surveys: App.Surveys.Data.SurveyData[];
}>();
</script>


					<script lang="ts">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <ShowStudentPageLayout :student="student" :title="student.name">
    <div class="h-full overflow-hidden">
      <div
        class="flex h-full flex-col divide-y divide-slate-200 overflow-hidden lg:flex-row lg:divide-x lg:divide-y-0"
      >
        <section class="divide-y-slate-200 flex min-w-0 grow-0 flex-col divide-y lg:grow">
          <PageHeader headingAs="h2" title="Survey Responses" />
          <StudentSurveySessionCardList v-if="student.survey_sessions?.length">
            <StudentSurveySessionCard
              v-for="session in student.survey_sessions"
              :key="session.id"
              :session="session"
              :student="student"
            />
          </StudentSurveySessionCardList>
          <EmptyState
            v-else
            title="No survey response"
            description="Once a survey response has been matched to the student, it will appear here."
          />
        </section>
        <aside class="flex grow flex-col bg-slate-50 lg:w-full lg:max-w-sm lg:grow-0">
          <div class="flex h-full grow flex-col gap-y-2 overflow-auto p-4">
            <h3 class="text-base font-semibold leading-6 text-slate-900">Survey List</h3>
            <StudentSurveyCardList v-if="surveys.length">
              <StudentSurveyCard v-for="survey in surveys" :key="survey.id" :survey="survey" />
            </StudentSurveyCardList>
            <EmptyCard
              v-else
              isDashed
              isRounded
              title="No survey available"
              description="No surveys are available for this student. Surveys that can be responded will appear here."
            />
          </div>
        </aside>
      </div>
    </div>
  </ShowStudentPageLayout>
</template>
