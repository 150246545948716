<script setup lang="ts">
import zxcvbn, { ZXCVBNScore } from 'zxcvbn';

const props = defineProps<{
  password: string;
}>();

const emit = defineEmits<{
  warning: [warning: string];
}>();

const strengths = {
  0: 'Password Strength',
  1: 'Weak',
  2: 'Moderate',
  3: 'Strong',
  4: 'Great!'
};

const passwordStrength = computed(() => {
  const result = zxcvbn(props.password);
  return {
    score: result.score,
    warning: result.feedback.warning,
    strength: strengths[result.score]
  };
});

watch(
  () => passwordStrength.value.warning,
  (warning) => {
    emit('warning', warning);
  }
);

const indicatorColor: Record<ZXCVBNScore, string> = {
  0: 'bg-slate-300',
  1: 'bg-red-400',
  2: 'bg-orange-300',
  3: 'bg-emerald-600',
  4: 'bg-teal-600'
};
</script>

<template>
  <div class="space-y-1">
    <div class="flex h-1 w-full space-x-px overflow-hidden rounded-full">
      <div
        v-for="(_, i) of Array.from('x'.repeat(4))"
        :key="i"
        class="flex-grow"
        :class="
          passwordStrength.score >= i + 1 ? indicatorColor[passwordStrength.score] : 'bg-slate-300'
        "
      ></div>
    </div>
    <p class="text-sm font-medium text-slate-500">{{ passwordStrength.strength }}</p>
  </div>
</template>
