<script setup lang="ts">
import CampaignForm from '@/components/automated-campaigns/CampaignForm.vue';
import CenteredContent from '@/components/content/CenteredContent.vue';
import { Selectable } from '@/components/selectBox/selectBox';

const form = useForm({
  method: 'POST',
  url: route('sms.campaigns.store'),
  fields: {
    name: '',
    student_group_id: null as Selectable<number> | null,
    is_targeting_parents: 'false'
  },
  transform: (fields) => ({
    ...fields,
    student_group_id: fields.student_group_id?.value ?? null,
    is_targeting_parents: fields.is_targeting_parents === 'true'
  })
});
</script>


					<script lang="ts">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <CenteredContent>
    <CampaignForm :form="form" />
  </CenteredContent>
</template>
