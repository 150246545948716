<script setup lang="ts">
import ExternalLinkAlt from '@/icons/line/external-link-alt.svg';
import Download from '@/icons/line/download-alt.svg';

const props = defineProps<{
  href: string;
  download?: boolean;
  iconColor?: 'slate';
}>();

const icon = computed(() => (props.download ? Download : ExternalLinkAlt));

const iconClasses = computed(() => {
  if (props.iconColor === 'slate') {
    return 'text-slate-500/70';
  }
  return '';
});
</script>

<template>
  <a
    :href="href"
    class="inline-flex flex-row items-center gap-1 p-0 align-bottom font-bold text-slate-900 hover:underline"
    :download="download ? true : undefined"
  >
    <slot></slot>
    <component :is="icon" class="w-4" :class="iconClasses" />
  </a>
</template>
