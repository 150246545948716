<script setup lang="ts">
import { ButtonColor, ButtonSize, ButtonVariant } from '@/hooks/useButtonClasses';
import { TableSelectionState } from '@/hooks/useHybridlyTable';

import Button from '@/components/button/Button.vue';
import DangerDialog from '@/components/dialog/DangerDialog.vue';
import Dialog from '@/components/dialog/Dialog.vue';

export type ButtonProps = {
  title: string;
  dialogTitle?: string;
  message: string;
  variant?: ButtonVariant | keyof typeof ButtonVariant;
  color: ButtonColor | keyof typeof ButtonColor;
  iconLeft?: Component;
  requiresConfirmation?: boolean;
  confirmButtonLabel: string;
  method: 'get' | 'put' | 'post' | 'patch' | 'delete';
  route: string;
  tableSelectionState: TableSelectionState;
};

const props = withDefaults(defineProps<ButtonProps>(), {
  requiresConfirmation: true,
  variant: 'solid'
});
const queryParameters = useQueryParameters();

const isConfirming = ref(false);

const form = useForm({
  method: props.method,
  url: props.route,
  fields: {
    ids: props.tableSelectionState.selectedRowIds || [],
    filters: queryParameters.filters || null,
    all: props.tableSelectionState.areAllRecordsSelected
  },
  transform: () => ({
    ids: props.tableSelectionState.selectedRowIds || [],
    filters: queryParameters.filters || null,
    all: props.tableSelectionState.areAllRecordsSelected
  }),
  hooks: {
    success() {
      props.tableSelectionState.reset();
      isConfirming.value = false;
    }
  }
});

function handleConfirm() {
  form.submit();
}

function handleCancel() {
  isConfirming.value = false;
}
</script>

<template>
  <Button
    v-if="tableSelectionState.hasSelection"
    :size="ButtonSize.sm"
    :variant="variant"
    :color="color"
    :iconLeft="iconLeft"
    @click.prevent="requiresConfirmation ? (isConfirming = true) : handleConfirm()"
  >
    {{ title }}
  </Button>

  <component
    :is="color === 'danger' ? DangerDialog : Dialog"
    :isOpen="isConfirming"
    :title="dialogTitle || title"
    :message="message"
    :isLoading="form.processing"
    cancelButtonLabel="Cancel"
    :confirmButtonLabel="confirmButtonLabel"
    @onConfirm="handleConfirm"
    @onCancel="handleCancel"
    @onClose="handleCancel"
  />
</template>
