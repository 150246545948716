<script setup lang="tsx" generic="T extends Record<string, unknown>">
import type { HybridlyRow, TableColumn } from '@/components/table/Table';
import type { IndexStudentActionsTabNavCounts } from '@/components/students/studentActions/IndexStudentActionsTabNav.vue';

import { useTableRowClick } from '@/hooks/useTableRowClick';

import IconButton from '@/components/button/IconButton.vue';
import IndexStudentActionsTabNav from '@/components/students/studentActions/IndexStudentActionsTabNav.vue';
import MetricCard from '@/components/metricCard/MetricCard.vue';
import PaddedCellContent from '@/components/table/PaddedCellContent.vue';
import PageHeader from '@/components/pageHeader/PageHeader.vue';
import ShowStudentPageLayout from '@/components/students/ShowStudentPageLayout.vue';
import TableActionButtonsCell from '@/components/table/TableActionButtonsCell.vue';
import TableBooleanCell from '@/components/table/TableBooleanCell.vue';
import TableDualTextDateCell from '@/components/table/TableDualTextDateCell.vue';
import TestSection from '@/components/students/studentActions/TestSection.vue';

import Plus from '@/icons/line/plus.svg';
import { InlineAction } from 'hybridly/vue';

type TestRow = HybridlyRow<App.Students.Data.TestData>;

const props = defineProps<{
  apCourses: {
    columns: TableColumn<T>[];
    records: App.Students.Data.TestData[];
  };
  counts: IndexStudentActionsTabNavCounts;
  customTests: {
    display_name: string;
    columns: TableColumn<T>[];
    records: App.Configuration.Data.CustomTestData[];
  }[];
  sats: {
    columns: TableColumn<T>[];
    records: App.Students.Data.TestData[];
  };
  psats: {
    columns: TableColumn<T>[];
    records: App.Students.Data.TestData[];
  };
  acts: {
    columns: TableColumn<T>[];
    records: App.Students.Data.TestData[];
  };
  student: App.Students.Data.StudentData;
  satSuperScores: Record<string, number>;
  actSuperScores: Record<string, number>;
}>();

const { handleRowClick } = useTableRowClick();

const showSatSuperScores = computed(() => Object.keys(props.satSuperScores).length > 0);
const showActSuperScores = computed(() => Object.keys(props.actSuperScores).length > 0);

const generateColumnsObject = ({ key, label, cellRender, isSortable }: TableColumn<T>) => ({
  key,
  label,
  ...(cellRender && { cellRender }),
  ...(isSortable && { isSortable })
});

function transformColumnsArray(
  columns: TableColumn<T>[],
  transformations: TableColumn<T>[]
): TableColumn<T>[] {
  return columns.map((column) => {
    const transformation = transformations.find((t) => t.key === column.key) as TableColumn<T>;
    if (transformation) {
      return generateColumnsObject({
        key: column.key,
        label: column.label,
        cellRender: transformation.cellRender,
        isSortable: transformation.isSortable
      });
    }

    return generateColumnsObject({
      key: column.key,
      label: column.label,
      cellRender: ({ row, column }) => (
        <PaddedCellContent>{row[column.key] ?? '--'}</PaddedCellContent>
      )
    });
  }) as unknown as TableColumn<T>[];
}

const customCells = [
  {
    key: 'date',
    cellRender: ({ row }) => <TableDualTextDateCell date={row.date} showCurrentYearBadge />,
    isSortable: true
  },
  {
    key: 'is_assisted',
    cellRender: ({ row }) => <TableBooleanCell boolean={row.is_assisted} />
  },
  {
    key: 'is_waived',
    cellRender: ({ row }) => <TableBooleanCell boolean={row.is_waived} />
  },
  {
    key: 'is_waiver_assisted',
    cellRender: ({ row }) => <TableBooleanCell boolean={row.is_waiver_assisted} />
  },
  {
    key: 'is_registered',
    cellRender: ({ row }) => <TableBooleanCell boolean={row.is_registered} />
  },
  {
    key: 'is_complete',
    cellRender: ({ row }) => <TableBooleanCell boolean={row.is_complete} />
  },
  {
    key: 'actions',
    cellRender: ({ row }) => <TableActionButtonsCell actions={row.actions} />
  }
];

function generateRowActionExecuteMethod(record: T, action: InlineAction) {
  return async () => {
    let method: 'get' | 'delete' = 'get';
    let url = route('students.tests.edit', { student: props.student.id, test: record.id });

    if (action.name === 'delete') {
      method = 'delete';
      url = route('tests.delete', { test: record.id });
    }

    await router.navigate({
      method,
      url,
      preserveState: true
    });
  };
}

function transformRecordsArray(records) {
  return records.map((record) => {
    const updatedActions = record.actions.map((action) => {
      const execute = generateRowActionExecuteMethod(record, action);
      return { ...action, execute };
    });

    return { ...record, value: (column: string) => record[column], actions: updatedActions };
  });
}

const actTable = computed(() => ({
  columns: transformColumnsArray(props.acts.columns, customCells),
  records: transformRecordsArray(props.acts.records)
}));
const satTable = computed(() => ({
  columns: transformColumnsArray(props.sats.columns, customCells),
  records: transformRecordsArray(props.sats.records)
}));
const psatTable = computed(() => ({
  columns: transformColumnsArray(props.psats.columns, customCells),
  records: transformRecordsArray(props.psats.records)
}));
const apCourseTable = computed(() => ({
  columns: transformColumnsArray(props.apCourses.columns, customCells),
  records: transformRecordsArray(props.apCourses.records)
}));
const customTestTables = computed(() => [
  ...props.customTests.map((test) => {
    return {
      ...test,
      columns: transformColumnsArray(test.columns, customCells),
      records: transformRecordsArray(test.records)
    };
  })
]);

function handleTestRowClick(event: MouseEvent, row: TestRow) {
  const url = route('students.tests.show', {
    student: props.student.id,
    test: row.id
  });
  handleRowClick(url, event);
}
</script>


					<script lang="tsx">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <ShowStudentPageLayout :student :title="student.name">
    <template #tabs>
      <IndexStudentActionsTabNav :student :counts />
    </template>
    <template #table>
      <PageHeader headingAs="h2" title="Exams">
        <template #rightButtons>
          <IconButton
            ariaLabel="Add exam"
            variant="soft"
            :icon="Plus"
            :href="route('students.tests.create', { student: student.id })"
          />
        </template>
      </PageHeader>
      <div class="border-t border-zinc-200">
        <TestSection
          :badgeCount="satTable.records.length"
          :student
          :table="satTable"
          title="SAT"
          :cardSectionTitle="showSatSuperScores ? 'SAT Super Score' : undefined"
          @clickRow="($event, row) => handleTestRowClick($event, row as unknown as TestRow)"
        >
          <template v-if="showSatSuperScores" #cards>
            <MetricCard
              v-for="(satSuperScore, label) in satSuperScores"
              :key="label"
              :title="label"
              backgroundColor="gray"
            >
              {{ satSuperScore }}
            </MetricCard>
          </template>
        </TestSection>
        <TestSection
          :badgeCount="actTable.records.length"
          :student
          :table="actTable"
          title="ACT"
          :cardSectionTitle="showActSuperScores ? 'ACT Super Score' : undefined"
          @clickRow="($event, row) => handleTestRowClick($event, row as unknown as TestRow)"
        >
          <template v-if="showActSuperScores" #cards>
            <MetricCard
              v-for="(actSuperScore, label) in actSuperScores"
              :key="label"
              :title="label"
              backgroundColor="gray"
            >
              {{ actSuperScore }}
            </MetricCard>
          </template>
        </TestSection>
        <TestSection
          :badgeCount="psatTable.records.length"
          :student
          :table="psatTable"
          title="PSAT"
          @clickRow="($event, row) => handleTestRowClick($event, row as unknown as TestRow)"
        />
        <TestSection
          :badgeCount="apCourseTable.records.length"
          :student
          :table="apCourseTable"
          title="AP Course"
          @clickRow="($event, row) => handleTestRowClick($event, row as unknown as TestRow)"
        />
        <TestSection
          v-for="customTest in customTestTables"
          :badgeCount="customTest.records.length"
          :key="customTest.display_name"
          :student
          :table="customTest"
          :title="customTest.display_name"
          @clickRow="($event, row) => handleTestRowClick($event, row as unknown as TestRow)"
        />
      </div>
    </template>
  </ShowStudentPageLayout>
</template>
