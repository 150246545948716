<script setup lang="ts">
import { RouterLink } from 'hybridly/vue';

import Badge from '@/components/badge/Badge.vue';
import UnreadIndicator from '@/components/unreadIndicator/UnreadIndicator.vue';

export type TabNavigationProps = {
  badgeLabel?: string | number;
  label: string;
  hasUnread?: boolean;
  icon?: Component;
  isActive?: boolean;
  isDisabled?: boolean;
  href?: string;
  variant?: 'filled' | 'inline';
};

const props = withDefaults(defineProps<TabNavigationProps>(), {
  variant: 'filled'
});

const emit = defineEmits<{
  click: [void];
}>();

const rawIcon = toRaw(props.icon);

function onTabNavigationClick() {
  if (!props.isDisabled) {
    emit('click');
  }
}
</script>

<template>
  <component
    class="group/tab-nav-item relative z-20 flex shrink-0 items-center gap-1 truncate px-3 py-2 text-sm font-semibold leading-5 transition-colors duration-150 ease-in-out hover:text-slate-600"
    :class="[
      isDisabled && 'cursor-not-allowed opacity-50',
      variant === 'filled' && [
        'rounded-lg',
        {
          'hover:bg-secondary-50 hover:text-secondary-900 active:bg-secondary-100': !isDisabled,
          'text-slate-700': !isActive,
          'bg-secondary-50 text-secondary-900': isActive
        }
      ],
      variant === 'inline' && [
        'grow-0 justify-center',
        {
          'text-slate-500': !isActive,
          'text-secondary-900': isActive
        }
      ]
    ]"
    :ariaDisabled="isDisabled"
    :ariaLabel="hasUnread ? `${label}, unread items` : undefined"
    :disabled="isDisabled"
    :is="href && !isDisabled ? RouterLink : 'button'"
    :href="!isDisabled ? href : undefined"
    @click="onTabNavigationClick"
  >
    <component v-if="icon" class="h-4 w-4 text-slate-500" :is="rawIcon" />
    {{ label }}
    <Badge
      v-if="badgeLabel !== undefined && badgeLabel !== null"
      :label="badgeLabel"
      size="sm"
      variant="soft"
    />
    <div
      v-if="variant === 'inline'"
      class="absolute inset-x-0 bottom-0 block transition-colors duration-150 ease-in-out"
      :class="{
        'group-hover/tab-nav-item:h-0.5 group-hover/tab-nav-item:bg-secondary-800 group-active/tab-nav-item:h-0.5 group-active/tab-nav-item:bg-secondary-800':
          !isDisabled,
        'h-px bg-slate-200': !isActive,
        'h-0.5 bg-secondary-800': isActive
      }"
    />
    <UnreadIndicator v-if="hasUnread" class="absolute right-1 top-1" />
  </component>
</template>
