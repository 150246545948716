<script setup lang="ts">
import { ButtonColor, ButtonSize, ButtonVariant } from '@/hooks/useButtonClasses';

import Button from '@/components/button/Button.vue';
import DangerDialog from '@/components/dialog/DangerDialog.vue';

import TrashIcon from '@/icons/line/trash.svg';

const props = defineProps<{
  uploadRecord: App.Bulkinator.Data.UploadRecordData;
}>();

const [isCancelDialogOpen, toggleCancelDialog] = useToggle(false);

const form = useForm({
  url: route('upload-records.delete', { uploadRecord: props.uploadRecord.id }),
  method: 'delete',
  fields: {},
  only: ['uploadRecords', 'uploadSession']
});

function handleRemove() {
  form.submit();
}
</script>

<template>
  <div class="px-5 py-2.5">
    <Button
      :size="ButtonSize.lg"
      :variant="ButtonVariant.invisible"
      :color="ButtonColor.danger"
      :iconLeft="TrashIcon"
      class="w-full text-center"
      @click="() => toggleCancelDialog()"
      >Remove from import</Button
    >
    <DangerDialog
      title="Remove record from import"
      message="Are you sure you want to remove this record from the import?"
      confirmButtonLabel="Remove record"
      :isLoading="form.processing"
      :isOpen="isCancelDialogOpen"
      @onCancel="() => toggleCancelDialog()"
      @onClose="() => toggleCancelDialog()"
      @onConfirm="handleRemove"
    />
  </div>
</template>
