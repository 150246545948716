<script setup lang="ts">
import { ButtonColor, ButtonVariant } from '@/hooks/useButtonClasses';

import Button from '@/components/button/Button.vue';
import EmptyCard from '@/components/emptyCard/EmptyCard.vue';
import IconButton from '@/components/button/IconButton.vue';
import TabNav from '@/components/tabNav/TabNav.vue';
import TabNavItem from '@/components/tabNav/TabNavItem.vue';
import CreateTodoForm from '@/components/todos/CreateTodoForm.vue';
import TodoList from '@/components/todos/TodoList.vue';

import IconTimes from '@/icons/line/times.svg';

type TodoData = App.Configuration.Data.TodoData;

const props = defineProps<{
  todoList: TodoData[];
  doneList: TodoData[];
}>();

const emit = defineEmits<{
  onClose: [void];
}>();

const activeTab = ref<'todo' | 'done'>('todo');

const activeList = computed(() => (activeTab.value === 'todo' ? props.todoList : props.doneList));

const form = useForm({
  method: 'DELETE',
  spoof: false,
  url: route('todos.bulk-delete'),
  fields: {
    ids: []
  },
  transform: () => ({
    ids: props.doneList.map((todo) => todo.id)
  }),
  hooks: {
    success() {
      router.reload({ only: ['todos'] });
    }
  }
});

function handleClearTasks() {
  form.submit();
}

function handleTabChange(tab) {
  activeTab.value = tab;
}

function handleClose() {
  emit('onClose');
}
</script>

<template>
  <div class="h-full overflow-y-auto md:flex md:h-auto md:min-h-0 md:flex-col">
    <header
      class="flex flex-row items-center justify-between gap-x-6 border-b border-b-slate-200 px-5 py-5 md:px-3"
    >
      <p class="text-base font-semibold leading-normal text-slate-900">To-Do List</p>
      <div class="flex items-center gap-x-2">
        <Button
          :color="ButtonColor.secondary"
          :isLoading="form.processing"
          :variant="ButtonVariant.invisible"
          type="button"
          @click="handleClearTasks"
          >Clear done tasks</Button
        >
        <IconButton
          class="block md:hidden"
          :icon="IconTimes"
          ariaLabel="Close slideout"
          variant="soft"
          @click="handleClose"
        />
      </div>
    </header>
    <section>
      <TabNav layout="inline">
        <TabNavItem
          :isActive="activeTab === 'todo'"
          label="To-do"
          :badgeLabel="todoList.length"
          variant="inline"
          @click="handleTabChange('todo')"
        />
        <TabNavItem
          :isActive="activeTab === 'done'"
          label="Done"
          :badgeLabel="doneList.length"
          variant="inline"
          @click="handleTabChange('done')"
        />
      </TabNav>
    </section>
    <section class="overflow-y-auto bg-zinc-50 px-2.5 pb-4 pt-3 md:px-1.5">
      <TodoList v-if="activeList.length" :todos="activeList" />
      <EmptyCard
        v-else
        :title="activeTab === 'todo' ? `No currently active to-dos` : `No completed to-dos`"
        :description="
          activeTab === 'todo'
            ? `Currently active to-dos will appear on this tab`
            : `Completed to-dos will appear on this tab`
        "
        isDashed
        isRounded
        size="md"
        variant="invisible"
      />
    </section>
    <section v-if="activeTab === 'todo'" class="border-t border-slate-200 px-3 py-2">
      <CreateTodoForm />
    </section>
  </div>
</template>
