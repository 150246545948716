<script setup lang="ts">
import Badge from '@/components/badge/Badge.vue';
import Button from '@/components/button/Button.vue';
import DangerDialog from '@/components/dialog/DangerDialog.vue';
import Tooltip from '@/components/tooltip/Tooltip.vue';
import ShieldCheck from '@/icons/line/shield-check.svg';
import ShieldSlash from '@/icons/line/shield-slash.svg';

defineProps<{
  isVerified: boolean;
  destination: string;
  channelType: string;
  allowDelete: boolean;
  tooltip?: string;
}>();

const emit = defineEmits<{
  onDelete: [void];
}>();

const isDeleteChannelDialogOpen = ref(false);

function handleToggleDeleteChannelDialog() {
  isDeleteChannelDialogOpen.value = !isDeleteChannelDialogOpen.value;
}

function handleDeleteChannel() {
  emit('onDelete');
  handleToggleDeleteChannelDialog();
}
</script>

<template>
  <li class="flex justify-between p-3">
    <div class="flex gap-x-2">
      <div class="h-6 w-6 rounded-full bg-slate-100 p-1 text-slate-700">
        <component :is="isVerified ? ShieldCheck : ShieldSlash" class="h-4 w-4 opacity-70" />
      </div>
      <span class="text-slate-900">{{ destination }}</span>
      <Badge :label="channelType" size="md" variant="solid" />
    </div>
    <Tooltip :isDisabled="allowDelete" showArrow side="top">
      <template #trigger>
        <Button
          :isDisabled="!allowDelete"
          color="danger"
          size="xs"
          variant="soft"
          @click="handleToggleDeleteChannelDialog"
        >
          Delete
        </Button>
      </template>
      <p>{{ tooltip }}</p>
    </Tooltip>

    <DangerDialog
      title="Delete two factor authentication device"
      message="Are you sure you want to delete this two factor authentication device? You will not be able to revert this action."
      :isOpen="isDeleteChannelDialogOpen"
      :confirmButtonLabel="`Delete two factor authentication device`"
      @onCancel="handleToggleDeleteChannelDialog"
      @onClose="handleToggleDeleteChannelDialog"
      @onConfirm="handleDeleteChannel"
    />
  </li>
</template>
