import AutomatedActionTypeData = App.Sms.Data.AutomatedCampaigns.AutomatedActionTypeData;
import { index, indexKeyOptions } from '@/api/AutomatedActionTypeApi';
import AutomatedActionType = App.Sms.Enums.AutomatedActionType;
import EnumData = App.Base.Data.EnumData;
import AutomatedActionData = App.Sms.Data.AutomatedCampaigns.AutomatedActionData;

const STORAGE_KEYS = {
  AUTOMATED_ACTION_TYPES: 'smsAutomatedActionTypes',
  AUTOMATED_ACTION_TYPE_KEY_OPTIONS: 'smsAutomatedActionTypeKeyOptions'
};

type AutomatedActionTypeKeyOptions = {
  [key in AutomatedActionType]: EnumData[];
};

export type UseAutomatedActionTypesProps = Ref<
  Pick<AutomatedActionData, 'action_type' | 'key' | 'value'>
>;

export function useAutomatedActionTypes(fields: UseAutomatedActionTypesProps) {
  const automatedActionTypes = useStorage<AutomatedActionTypeData[]>(
    STORAGE_KEYS.AUTOMATED_ACTION_TYPES,
    []
  );

  const automatedActionTypeKeyOptions = useStorage<AutomatedActionTypeKeyOptions>(
    STORAGE_KEYS.AUTOMATED_ACTION_TYPE_KEY_OPTIONS,
    () => ({}) as AutomatedActionTypeKeyOptions
  );

  onMounted(async () => {
    automatedActionTypes.value = await index();
  });

  watchEffect(async () => {
    if (fields.value.action_type.requires_key) {
      automatedActionTypeKeyOptions.value[fields.value.action_type.value] = await indexKeyOptions(
        fields.value.action_type.value
      );
    }
  });

  const selectedAutomatedActionKey = computed(() => {
    return automatedActionKeyOptions.value.find(
      (keyOption) => keyOption.value === fields.value.key
    );
  });

  const automatedActionKeyOptions = computed(() => {
    return automatedActionTypeKeyOptions.value[fields.value.action_type.value] || [];
  });

  const automatedActionValues = [
    { label: 'Yes', value: 'Yes' },
    { label: 'No', value: 'No' },
    { label: 'Not set', value: 'Not set' }
  ];

  const selectedAutomatedActionValue = computed(() => {
    return automatedActionValues.find((value) => value.value === fields.value.value);
  });

  return {
    automatedActionTypes,
    automatedActionKeyOptions,
    selectedAutomatedActionKey,
    automatedActionValues,
    selectedAutomatedActionValue
  };
}
