import { groupBy, mapKeys, sortBy } from 'lodash';
import { formatDate } from '@/utils/date';
import { parseJSON } from 'date-fns';
import { UnwrapNestedRefs } from 'vue';

import SmsData = App.Sms.Data.SmsData;
import UpcomingMessageData = App.Sms.Data.UpcomingMessageData;

type SortKey = Extract<keyof (SmsData & UpcomingMessageData), string>;

export function useMessageList<T extends SmsData | UpcomingMessageData>({
  infiniteScrollDirection,
  sortKey,
  messageContainer,
  threadId,
  api,
  lastUpdatedAt
}: {
  infiniteScrollDirection: 'top' | 'bottom';
  sortKey: SortKey[];
  messageContainer: HTMLElement | null;
  threadId: number;
  lastUpdatedAt?: string;
  api: (threadId: number, page?: number) => Promise<Paginator<T>>;
}) {
  const paginatedMessages = ref<Paginator<T> | null>();
  const isLoading = ref(false);

  const messages = computed<T[]>(() => sortBy(paginatedMessages.value?.data || [], sortKey));

  const groupedMessages = computed(() => {
    const groupedByDate = groupBy(messages.value, (message) =>
      formatDate(parseJSON(message[sortKey[0]]), { format: 'yyyy-MM-dd' })
    );
    return mapKeys(groupedByDate, (value) => formatDate(value[0][sortKey[0]]));
  });

  const firstPage = computed(() => paginatedMessages.value?.meta.first_page || 1);
  const currentPage = computed(() => paginatedMessages.value?.meta.current_page || 1);
  const nextPage = computed(() => (paginatedMessages.value?.meta.current_page || 1) + 1);
  const lastPage = computed(() => paginatedMessages.value?.meta.last_page || 1);
  const hasNextPage = computed(() => nextPage.value <= lastPage.value);

  async function fetchAndSetMessages(page: number = 1) {
    isLoading.value = true;

    const newMessagesData = await api(threadId, page);

    paginatedMessages.value = {
      ...newMessagesData,
      data: [...(paginatedMessages.value?.data || []), ...(newMessagesData.data || [])]
    };

    isLoading.value = false;
  }

  function pushMessage(message: T) {
    const hasExistingMessage = paginatedMessages.value?.data.find((m) => m.id === message.id);

    if (hasExistingMessage) {
      return;
    }

    paginatedMessages.value?.data.push(message);
  }

  function updateMessage(message: T) {
    const existingMessageIndex = paginatedMessages.value?.data.findIndex(
      (m) => m.id === message.id
    );

    if (existingMessageIndex !== undefined && existingMessageIndex >= 0) {
      paginatedMessages.value!.data[existingMessageIndex] = message as T;
    }
  }

  async function refreshAllPages() {
    if (paginatedMessages.value?.data) {
      const data: T[] = [];

      for (let page = firstPage.value; page <= currentPage.value; page++) {
        data.push(...(await api(threadId, page)).data);
      }

      return (paginatedMessages.value.data = data);
    }

    await fetchAndSetMessages(1);
  }

  async function loadMoreMessages({ y }: UnwrapNestedRefs<ReturnType<typeof useScroll>>) {
    const previousScrollHeight = messageContainer?.scrollHeight ?? 0;
    await fetchAndSetMessages(nextPage.value);
    await nextTick();

    if (infiniteScrollDirection === 'top' && messageContainer) {
      messageContainer.scrollTop = y + (messageContainer.scrollHeight - previousScrollHeight);
    }
  }

  useInfiniteScroll(messageContainer, loadMoreMessages, {
    distance: 10,
    direction: infiniteScrollDirection,
    canLoadMore: () => hasNextPage.value
  });

  watch([() => threadId, () => lastUpdatedAt], async () => await fetchAndSetMessages(1), {
    immediate: true
  });

  return {
    paginatedMessages,
    messages,
    groupedMessages,
    refreshAllPages,
    loadMoreMessages,
    fetchAndSetMessages,
    pushMessage,
    updateMessage,
    isLoading
  };
}
