<script setup lang="ts">
type AutomatedCampaignData = App.Sms.Data.AutomatedCampaigns.AutomatedCampaignData;

import { ButtonColor, ButtonSize, ButtonVariant } from '@/hooks/useButtonClasses';

import Alert from '@/components/alert/Alert.vue';
import Badge from '@/components/badge/Badge.vue';
import Button from '@/components/button/Button.vue';
import EmptyState from '@/components/emptyState/EmptyState.vue';
import MessageFlowItem from '@/components/automated-campaigns/MessageFlowItem.vue';
import TabNav from '@/components/tabNav/TabNav.vue';
import TabNavItem from '@/components/tabNav/TabNavItem.vue';

import ExclamationCircleIcon from '@/icons/line/exclamation-circle.svg';
import InfoCircleIcon from '@/icons/line/info-circle.svg';
import PauseCircleIcon from '@/icons/line/pause-circle.svg';
import PlusCircleIcon from '@/icons/line/plus-circle.svg';

const props = defineProps<{
  campaign: AutomatedCampaignData;
}>();

const { matches } = useRoute();

const statusForm = useForm({
  method: 'put',
  url: route('sms.campaigns.status.update', { campaign: props.campaign.id }),
  updateInitials: true,
  fields: {
    status: props.campaign.paused_at ? 'paused' : 'active'
  }
});

const createFlowForm = useForm({
  method: 'post',
  url: route('sms.outbound-messages.store', { campaign: props.campaign.id }),
  updateInitials: true,
  fields: {}
});

function updateCampaignStatus() {
  statusForm.fields.status = statusForm.fields.status === 'paused' ? 'active' : 'paused';
  statusForm.submit();
}
</script>


					<script lang="ts">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <div class="flex h-full flex-col">
    <header class="flex justify-between border-b px-4 pb-2 pt-2.5">
      <div class="mr-2.5 flex flex-grow items-center overflow-hidden">
        <h1
          :title="campaign.name"
          class="mr-2.5 truncate text-base font-bold leading-6 text-zinc-900"
        >
          {{ campaign.name }}
        </h1>

        <div class="flex flex-shrink-0 items-center gap-2.5">
          <Badge variant="soft" size="md" :label="campaign.paused_at ? 'Paused' : 'Active'" />
          <span aria-hidden="true" class="block h-4 w-[1px] bg-zinc-200" />
          <Button
            :color="ButtonColor.primary"
            :iconLeft="PauseCircleIcon"
            :isLoading="statusForm.processing"
            :size="ButtonSize.sm"
            :variant="campaign.paused_at ? ButtonVariant.solid : ButtonVariant.outlined"
            @click="updateCampaignStatus"
          >
            {{ campaign.paused_at ? 'Activate Campaign' : 'Pause Campaign' }}
          </Button>
        </div>
      </div>

      <TabNav layout="inline" floating>
        <TabNavItem
          label="Message Flows"
          variant="filled"
          :isActive="matches('sms.campaigns.show', { campaign })"
          :href="route('sms.campaigns.show', { campaign })"
        />
        <TabNavItem
          label="Settings"
          variant="filled"
          :isDisabled="!campaign.paused_at"
          :href="route('sms.campaigns.edit', { campaign: campaign.id })"
        />
      </TabNav>
    </header>

    <div class="border-b px-4 pb-2 pt-2.5">
      <Alert
        :color="campaign.paused_at ? 'warning' : 'secondary'"
        variant="soft"
        :icon="campaign.paused_at ? ExclamationCircleIcon : InfoCircleIcon"
        :title="
          campaign.paused_at
            ? 'This campaign is currently paused. No messages will be sent or replied to until it is activated.'
            : 'To edit audience and message flows, the campaign must be paused.'
        "
      />
    </div>

    <main class="flex h-full">
      <div class="flex flex-grow flex-col">
        <div class="flex items-center gap-x-2 border-b px-4 py-2.5">
          <div class="flex items-center text-sm font-bold">
            Sending to
            <Badge
              class="mx-1"
              :label="campaign.is_targeting_parents ? 'Parents/Guardians' : 'Students'"
              size="md"
              variant="soft"
              color="secondary"
            />
            in the
            <Badge
              :href="route('student-groups.show', { studentGroup: campaign.student_group?.id })"
              class="mx-1"
              :label="campaign.student_group?.name"
              size="md"
              variant="soft"
              color="primary"
            />
          </div>
        </div>
        <div class="flex-grow">
          <EmptyState
            v-if="campaign.message_flow_count === 0"
            inline
            title="No Message Flows"
            description="Create a new message flow by clicking the button below"
            :actionLabel="campaign.paused_at ? 'Add a Message Flow' : undefined"
            :isLoading="createFlowForm.processing"
            @click="createFlowForm.submit"
          />
          <div v-else>
            <div class="flex h-12 items-center justify-between px-3 pt-4">
              <h2 class="font-bold leading-5 text-slate-900">Message Flows</h2>
              <Button
                :color="ButtonColor.slate"
                :iconLeft="PlusCircleIcon"
                :isDisabled="!campaign.paused_at"
                :isLoading="createFlowForm.processing"
                :variant="ButtonVariant.soft"
                @click="createFlowForm.submit"
                >New Flow</Button
              >
            </div>
            <ol class="mt-2 space-y-1 px-3">
              <MessageFlowItem
                v-for="(message, index) in campaign.message_flows"
                :key="message.id"
                :campaign="campaign"
                :message="message"
                :index="index"
              />
            </ol>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>
