<script setup lang="ts">
import { BadgeColor, BadgeSize, BadgeVariant } from '@/hooks/useBadgeClasses';
import Badge from '@/components/badge/Badge.vue';

const props = defineProps<{
  vertical?: boolean;
  horizontal?: boolean;
  forAddButton?: boolean;
  firstNode?: boolean;
  lastNode?: boolean;
}>();

const height = computed(() => {
  return props.forAddButton ? 'h-4' : 'h-[23px]';
});
const leftPosition = computed(() => {
  return props.lastNode || props.forAddButton ? 'left-[-1px]' : 'left-0';
});
</script>

<template>
  <div class="relative shrink-0" :class="{ 'w-4': vertical, 'w-10': horizontal }">
    <div
      v-if="vertical"
      class="absolute bottom-0 left-0 right-0 top-0 border-r border-slate-200 content-['']"
    />
    <div
      v-if="horizontal"
      class="absolute bottom-0 right-0 border-t border-slate-200 bg-white content-['']"
      :class="[height, leftPosition]"
    >
      <div
        v-if="!firstNode && !forAddButton"
        class="absolute bottom-[13px] left-0 right-0 mx-auto text-center"
      >
        <Badge :size="BadgeSize.xs" :color="BadgeColor.slate" :variant="BadgeVariant.soft"
          >and</Badge
        >
      </div>
    </div>
  </div>
</template>
