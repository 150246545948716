<script setup lang="ts">
import type { ChartOptions } from 'chart.js';
import { Bar, ChartComponentRef } from 'vue-chartjs';
import { Chart as ChartJS, Title, Tooltip, BarElement, CategoryScale, LinearScale } from 'chart.js';
import ChartAnnotations from 'chartjs-plugin-annotation';
import { useChartTooltip } from '@/hooks/useChartTooltip';
import { useTailwind } from '@/hooks/useTailwind';
import { ChartjsDatasetItem, formatChartDataValue, formatGoalTarget } from './data';
import { DashboardConfig } from '@/domains/dashboard/config';
import ChartLegend from './ChartLegend.vue';

ChartJS.register(Title, Tooltip, BarElement, CategoryScale, LinearScale, ChartAnnotations);

interface Props {
  labels: string[];
  dataset: ChartjsDatasetItem[];
  dashboardConfig: DashboardConfig;
}

const props = defineProps<Props>();

const { theme } = useTailwind();
const { externalTooltipHandler } = useChartTooltip({
  anchorPosition: 'top'
});

const chart = ref<ChartComponentRef | null>(null);

const colors = [
  theme.colors.secondary[800],
  theme.colors.secondary[900],
  theme.colors.secondary[600],
  theme.colors.primary[700],
  theme.colors.primary[700],
  theme.colors.primary[700]
];

function resetSize() {
  chart.value?.chart?.resize();
}

onMounted(() => {
  // we need to force resize responsive charts for printing - https://github.com/chartjs/Chart.js/issues/1350
  // it's also why we adjust the canvas height and width on print media
  window.addEventListener('beforeprint', resetSize);
  window.addEventListener('afterprint', resetSize);
});

onBeforeUnmount(() => {
  window.removeEventListener('beforeprint', resetSize);
  window.removeEventListener('afterprint', resetSize);
});

const data = computed(() => ({
  backgroundColor: colors,
  borderRadius: 6,
  data: props.dataset.map((d) => d.percent)
}));

const legendItems = computed(() =>
  props.labels.map((l, i) => ({
    text: l,
    color: colors[i],
    goal: props.dataset[i]?.goal ?? null,
    total: props.dataset[i]?.total
  }))
);

const goalLabelColors = {
  success: {
    borderColor: theme.colors.green[800],
    color: theme.colors.green[800],
    backgroundColor: theme.colors.green[50]
  },
  default: {
    borderColor: theme.colors.slate[200],
    color: theme.colors.slate[700],
    backgroundColor: 'white'
  },
  failure: {
    borderColor: theme.colors.amber[800],
    color: theme.colors.amber[800],
    backgroundColor: theme.colors.amber[50]
  }
} as const;

const annotations = computed(() => {
  const goalBoxes = props.dataset.map((d, i) =>
    d.goal
      ? {
          type: 'box',
          display: props.dashboardConfig.showGoal ?? false,
          backgroundColor: '#F59E0B1F',
          borderColor: 'transparent',
          xMax: i + 0.355,
          xMin: i - 0.355,
          yMin: 0,
          yMax: d.goal.percent
        }
      : null
  );
  const goalLines = props.dataset.map((d, i) =>
    d.goal
      ? {
          type: 'line',
          display: props.dashboardConfig.showGoal ?? false,
          borderColor: theme.colors.amber[400],
          borderDash: [2, 2],
          borderWidth: 2,
          xMax: i + 0.355,
          xMin: i - 0.355,
          xScaleID: 'x',
          yMax: d.goal?.percent ?? null,
          yMin: d.goal?.percent ?? null,
          yScaleID: 'y'
        }
      : null
  );
  const goalLabels = props.dataset.map((d, i) => {
    let goalStatus: keyof typeof goalLabelColors = 'default';
    if (d.goal && props.dashboardConfig.showGoal) {
      goalStatus = d.goal.complete ? 'success' : 'failure';
    }
    const color = goalLabelColors[goalStatus];
    return {
      type: 'label',
      borderRadius: 8,
      borderWidth: 1,
      borderColor: color.borderColor,
      backgroundColor: color.backgroundColor,
      color: color.color,
      content:
        d.goal && props.dashboardConfig.showGoal
          ? `${d.goal.complete ? '' : ''} ${formatGoalTarget(
              d,
              props.dashboardConfig.numberFormat
            )}`
          : formatChartDataValue(d, props.dashboardConfig.numberFormat),
      font: {
        family: '"chartjs-icons", "Lato"',
        size: 13,
        weight: 600,
        lineHeight: '20px'
      },
      padding: {
        left: 8,
        right: 8,
        top: 2,
        bottom: 2
      },
      position: {
        x: 'center',
        y: 'start'
      },
      xValue: props.labels[i],
      yValue:
        d.goal && props.dashboardConfig.showGoal ? Math.max(d.goal.percent, d.percent) : d.percent,
      yAdjust: 2
    };
  });
  return [...goalBoxes, ...goalLines, ...goalLabels].reduce((acc, curr, i) => {
    if (curr) {
      acc[curr.type + i] = curr;
    }
    return acc;
  }, {});
});

/**
 * The default max of our bar chart is 100%.
 * But if any dataset value surpasses 0.8, the data label will be cutoff.
 * We'll add a buffer in those instances.
 */
function getSuggestedMax(dataset: ChartjsDatasetItem[]): number {
  const datasetMax = dataset.map((d) => Math.max(d.percent, d.goal?.percent ?? 0));
  const max = datasetMax.reduce((acc, curr) => Math.max(acc, curr), 0);

  return Math.min(max + 0.3, 1);
}

const chartOptions = computed<ChartOptions<'bar'>>(() => ({
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      display: false,
      offset: true
    },
    y: {
      beginAtZero: true,
      max: getSuggestedMax(props.dataset),
      ticks: {
        format: {
          style: 'percent',
          maximumFractionDigits: 0,
          minimumFractionDigits: 0
        }
      }
    }
  },
  plugins: {
    annotation: {
      annotations: annotations.value
    },
    tooltip: {
      enabled: false,
      position: 'nearest',
      external: externalTooltipHandler,
      padding: 5,
      bodyFont: {
        family: 'Lato, sans-serif',
        size: 12
      },
      callbacks: {
        label: function (context) {
          return formatChartDataValue(
            props.dataset[context.dataIndex],
            props.dashboardConfig.numberFormat
          );
        }
      }
    }
  }
}));
</script>

<template>
  <div class="px-7">
    <div class="relative mb-5 mt-8 h-40">
      <Bar
        class="print:!h-full print:!w-full"
        :options="chartOptions"
        :data="{
          labels: labels,
          datasets: [data]
        }"
        ref="chart"
      />
    </div>
    <ChartLegend
      class="py-5"
      :items="legendItems"
      :chart="chart?.chart ?? null"
      direction="row"
      :dashboardConfig="dashboardConfig"
    />
  </div>
</template>
