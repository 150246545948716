<script setup lang="tsx">
import type { AppliedFilters } from '@/components/distiller/types';
import type { CustomCells, HybridlyRow } from '@/components/table/Table';

import { ButtonColor, ButtonSize, ButtonVariant } from '@/hooks/useButtonClasses';
import { useAuth } from '@/hooks/useAuth';
import { useHybridlyTable } from '@/hooks/useHybridlyTable';
import { useTableRowClick } from '@/hooks/useTableRowClick';

import Button from '@/components/button/Button.vue';
import EmptyState from '@/components/emptyState/EmptyState.vue';
import IndexPageLayout from '@/components/pageLayout/IndexPageLayout.vue';
import PaddedCellContent from '@/components/table/PaddedCellContent.vue';
import TableBadgesCell from '@/components/table/TableBadgesCell.vue';
import TableBooleanCell from '@/components/table/TableBooleanCell.vue';
import TableDateTimeCell from '@/components/table/TableDateTimeCell.vue';

import Plus from '@/icons/line/plus.svg';
import UserCircle from '@/icons/line/user-circle.svg';

type DataImportRow = HybridlyRow<App.Bulkinator.Data.DataImportData>;

const props = defineProps<{
  appliedFilters: AppliedFilters;
  dataImports: App.Bulkinator.Data.DataImportData[];
  distiller: App.Search.Data.DistillerData;
}>();

const { hasPermission } = useAuth();
const { table, tableSelectionState } = useHybridlyTable<App.Configuration.Data.CustomTestData>(
  props,
  'dataImports'
);
const { handleRowClick } = useTableRowClick();

const customCells = {
  user: ({ row, column }) => (
    <TableBadgesCell
      badgesToDisplay={1}
      itemIcon={UserCircle}
      items={[row.value(column.key).name]}
    />
  ),
  data_import_type: ({ row, column }) => (
    <PaddedCellContent>{row.value(column.key).name}</PaddedCellContent>
  ),
  is_successful: ({ row, column }) => <TableBooleanCell boolean={row.value(column.key)} />,
  is_dry_run: ({ row, column }) => <TableBooleanCell boolean={row.value(column.key)} />,
  started_at: ({ row, column }) => <TableDateTimeCell date={row.value(column.key)} />,
  created_at: ({ row, column }) => <TableDateTimeCell date={row.value(column.key)} />
} as CustomCells<App.Configuration.Data.CustomTestData>;

function handleDataImportRowClick(event: MouseEvent, row: DataImportRow) {
  const url = route('data-imports.show', {
    dataImport: row.key
  });
  handleRowClick(url, event);
}
</script>


					<script lang="tsx">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <IndexPageLayout
    title="Data Imports"
    :appliedFilters="appliedFilters"
    :customCells="customCells"
    :distiller="distiller"
    :selectionsEnabled="false"
    :stickyEndColumns="0"
    :stickyStartColumns="1"
    :table="table"
    :tableSelectionState="tableSelectionState"
    clickableRows
    @clickRow="($event, row) => handleDataImportRowClick($event, row as DataImportRow)"
  >
    <template #rightButtons>
      <Button
        v-if="hasPermission('access-data-imports')"
        :color="ButtonColor.primary"
        :href="route('data-imports.create')"
        :iconLeft="Plus"
        :size="ButtonSize.sm"
        :variant="ButtonVariant.solid"
      >
        Add a new data import
      </Button>
    </template>
    <template #emptyState>
      <EmptyState
        :actionHref="route('data-imports.create')"
        title="No data imports found"
        :description="
          hasPermission('access-data-imports')
            ? 'Create a new data import by clicking the button below'
            : ''
        "
        :actionLabel="hasPermission('access-data-imports') ? 'Add a data import' : ''"
      />
    </template>
  </IndexPageLayout>
</template>
