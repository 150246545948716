<script setup lang="ts">
import { FormControlFieldProps } from '@/components/formControlField/FormControlField';
import type { CheckboxInputProps } from '@/components/checkboxInput/CheckboxInput.vue';

import CheckboxInput from '@/components/checkboxInput/CheckboxInput.vue';
import FormControlField from '@/components/formControlField/FormControlField.vue';
import { useFormField } from '@/components/formField/useFormField';
import { FormFieldSlots } from '@/components/formField/FormField';

type FormCheckboxInputProps = Omit<CheckboxInputProps, 'hasError'> & FormControlFieldProps;

const props = defineProps<FormCheckboxInputProps>();
defineSlots<FormFieldSlots>();

const emit = defineEmits<{
  'update:checked': [checked: boolean];
}>();

function onCheckedChange(checked: boolean) {
  emit('update:checked', checked);
}

const { formFieldProps, slots } = useFormField({ props });
</script>

<template>
  <FormControlField v-bind="formFieldProps">
    <CheckboxInput
      :checked="checked"
      :hasError="formFieldProps.hasError"
      :isDisabled="isDisabled"
      :name="name"
      class="shrink-0"
      @update:checked="onCheckedChange"
    />
    <template v-for="slot of slots" #[slot]>
      <slot :name="slot" />
    </template>
  </FormControlField>
</template>
