<script setup lang="ts">
import { ButtonColor, ButtonVariant } from '@/hooks/useButtonClasses';

import Button from '@/components/button/Button.vue';
import FormError from '@/components/formError/FormError.vue';
import FormField from '@/components/formField/FormField.vue';
import IconButton from '@/components/button/IconButton.vue';
import TextInput from '@/components/textInput/TextInput.vue';

import PlusCircle from '@/icons/line/plus-circle.svg';
import TimesCircle from '@/icons/line/times-circle.svg';

const rawTimesCircle = toRaw(TimesCircle);

const props = defineProps<{
  rule: App.Configuration.Data.FieldRuleData;
  errors?: Record<string, string>;
  isEditing?: boolean;
}>();

const emit = defineEmits<{
  change: [value: unknown];
}>();

const options = computed(() => props.rule.parameters?.options ?? []);
const error = computed(() => props.errors?.options ?? null);
const disabledOptions = props.rule.parameters?.options ?? [];

function handleOptionsChange(options: string[]) {
  emit('change', { options });
}

function handleAddOption() {
  handleOptionsChange([...options.value, '']);
}

function handleRemoveOption(index: number) {
  const newOptions = [...options.value];
  newOptions.splice(index, 1);

  handleOptionsChange(newOptions);
}

function handleInputChange(index: number, value: string | number) {
  const newOptions = [...options.value];
  newOptions[index] = value.toString();

  handleOptionsChange(newOptions);
}
</script>

<template>
  <FormField
    label="Single select options"
    :helperText="
      isEditing
        ? 'You cannot rename options once they are created because there may be student data associated to the option which will not be updated.'
        : undefined
    "
  >
    <div v-for="(option, i) in options" class="flex flex-col gap-2" :key="i">
      <div class="flex gap-2">
        <div class="grow">
          <TextInput
            :hasError="!!error"
            :isDisabled="isEditing && disabledOptions.includes(option)"
            :modelValue="option"
            :name="`option-${i}`"
            placeholder="e.g. GSU"
            type="text"
            @update:modelValue="(value) => handleInputChange(i, value)"
          />
        </div>
        <div class="grow-0">
          <IconButton
            :icon="rawTimesCircle"
            ariaLabel="Remove option"
            color="danger"
            variant="invisible"
            @click="handleRemoveOption(i)"
          />
        </div>
      </div>
      <FormError v-if="error">{{ error }}</FormError>
    </div>
    <div>
      <Button
        :color="ButtonColor.slate"
        :iconLeft="PlusCircle"
        :variant="ButtonVariant.invisible"
        @click="handleAddOption"
      >
        Add an option
      </Button>
    </div>
  </FormField>
</template>
