<script setup lang="tsx">
import getAsyncIcon from '@/components/icons/AsyncIcon';
import MenuButton from '@/components/button/MenuButton.vue';
import MenuItem from '@/components/menu/MenuItem.vue';
import MetricCard from '@/components/metricCard/MetricCard.vue';
import MetricCardContainer from '@/components/metricCard/MetricCardContainer.vue';
import PageHeader from '@/components/pageHeader/PageHeader.vue';
import PageSection from '@/components/pageSection/PageSection.vue';
import UploadTypeCard from '@/components/bulkinator/UploadTypeCard.vue';

defineProps<{
  statusCounts: {
    drafts: number;
    finished: number;
    errors: number;
  };
  uploadTypes: App.Bulkinator.Data.UploadTypeData[];
  uploadSessions: Record<string, App.Bulkinator.Data.UploadSessionData[]>;
}>();
</script>


					<script lang="tsx">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <div class="divide-y-slate-200 flex h-full flex-col divide-y">
    <PageHeader title="Bulkinator">
      <template #rightButtons>
        <MenuButton buttonSize="sm" buttonLabel="New upload session">
          <MenuItem
            v-for="uploadType in uploadTypes"
            :key="uploadType.id"
            :iconLeft="getAsyncIcon(uploadType.icon)"
            :label="uploadType.name"
            :href="
              route('upload-sessions.create', {
                uploadType: uploadType.id
              })
            "
          />
        </MenuButton>
      </template>
    </PageHeader>

    <MetricCardContainer>
      <MetricCard class="min-w-38" title="Total Drafts">{{ statusCounts['drafts'] }}</MetricCard>
      <MetricCard class="min-w-38" title="Total Finished">{{
        statusCounts['finished']
      }}</MetricCard>
      <MetricCard class="min-w-38" title="Sessions with errors">{{
        statusCounts['errors']
      }}</MetricCard>
    </MetricCardContainer>

    <PageSection title="Categories" description="Select a category to access">
      <div class="space-y-2.5">
        <UploadTypeCard
          v-for="uploadType in uploadTypes"
          :key="uploadType.id"
          :uploadType="uploadType"
          :uploadSessions="uploadSessions[uploadType.id] ?? []"
        />
      </div>
    </PageSection>
  </div>
</template>
