<script setup lang="ts">
import { Selectable } from '@/components/selectBox/selectBox';
import RecipientStudentBadges from './RecipientStudentBadges.vue';
import RecipientStudentParentBadges from './RecipientStudentParentBadges.vue';
import RecipientStudentGroupCaseloadBadges from './RecipientStudentGroupCaseloadBadges.vue';
import RecipientPhoneNumberBadges from './RecipientPhoneNumberBadges.vue';

const props = defineProps<{
  option: Selectable<string, App.Sms.Data.SmsRecipientData>;
}>();

const recipientBadgeComponent = computed(() => {
  return {
    student: RecipientStudentBadges,
    student_parent: RecipientStudentParentBadges,
    student_group: RecipientStudentGroupCaseloadBadges,
    caseload: RecipientStudentGroupCaseloadBadges,
    phone_number: RecipientPhoneNumberBadges
  }[props.option.meta?.recipient_type ?? 'unknown'];
});
</script>

<template>
  <div class="w-full space-y-1 text-sm">
    <p class="font-semibold">{{ option.label }}</p>

    <div class="flex flex-wrap items-center gap-1">
      <component
        v-if="recipientBadgeComponent"
        :is="recipientBadgeComponent"
        :recipient="option.meta as App.Sms.Data.SmsRecipientData"
      />
    </div>
  </div>
</template>
