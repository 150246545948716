<script setup lang="ts">
type EnumData = App.Base.Data.EnumData;
type TwoFactorChannelData = App.Auth.Data.TwoFactorChannelData;
type UserData = App.Auth.Data.UserData;

import {
  buildSelectableProgram,
  buildSelectableRegion,
  buildSelectableRole,
  buildSelectableSchool
} from '@/utils/buildSelectable';

import UserForm from '@/components/user/UserForm.vue';
import CenteredContent from '@/components/content/CenteredContent.vue';

const props = defineProps<{
  channels: TwoFactorChannelData[];
  roles: EnumData[];
  user: UserData;
  returnUrl?: string;
}>();

const form = useForm({
  method: 'PUT',
  url: route('users.update', {
    user: props.user.id
  }),
  fields: {
    first_name: props.user.first_name,
    last_name: props.user.last_name,
    email: props.user.email,
    role: buildSelectableRole(props.user.roles?.[0]),
    program_ids: (props.user.programs || []).map((program) => buildSelectableProgram(program)),
    region_ids: (props.user.regions || []).map((region) => buildSelectableRegion(region)),
    school_ids: (props.user.schools || []).map((school) => buildSelectableSchool(school)),
    sms_email: props.user.sms_email,
    sms_phone_number: props.user.sms_phone_number,
    url: props.user.url
  },
  transform: (fields) => ({
    ...fields,
    role: fields.role?.value,
    program_ids: fields.program_ids.map((program) => program?.value),
    region_ids: fields.region_ids.map((region) => region?.value),
    school_ids: fields.school_ids.map((school) => school?.value)
  })
});
</script>


					<script lang="ts">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <CenteredContent>
    <UserForm :form="form" :channels="channels" :roles="roles" :returnUrl="returnUrl" isEditing />
  </CenteredContent>
</template>
