<script setup lang="ts">
import type { ShowRegionProps } from '@/domains/configuration/views/regions/ShowRegion.vue';

import { useAuth } from '@/hooks/useAuth';
import { useDialogHelpers } from '@/hooks/useDialogHelpers';

import Badge from '@/components/badge/Badge.vue';
import DangerDialog from '@/components/dialog/DangerDialog.vue';
import DefinitionList from '@/components/definitionList/DefinitionList.vue';
import DefinitionListItem from '@/components/definitionList/DefinitionListItem.vue';
import DefinitionListItemBadgeWrapper from '@/components/definitionList/DefinitionListItemBadgeWrapper.vue';
import IconButton from '@/components/button/IconButton.vue';
import InformationCollapsible from '@/components/collapsible/InformationCollapsible.vue';
import Slideout from '@/components/slideout/Slideout.vue';

import DocumentLayoutLeft from '@/icons/line/document-layout-left.svg';
import EditAlt from '@/icons/line/edit-alt.svg';
import Envelope from '@/icons/line/envelope.svg';
import FilesLandscapesAlt from '@/icons/line/files-landscapes-alt.svg';
import MapPin from '@/icons/line/map-pin.svg';
import TrashIcon from '@/icons/line/trash.svg';
import User from '@/icons/line/user.svg';

const props = defineProps<ShowRegionProps>();

const { show, close } = useDialog();
const { isDialogOpen, toggleDialog } = useDialogHelpers();

const { hasPermission } = useAuth();

const form = useForm({
  method: 'DELETE',
  spoof: false,
  url: route('regions.delete', { region: props.region.id }),
  fields: {},
  hooks: {
    success() {
      close();
    }
  }
});

async function handleRegionDelete() {
  await form.submit();
  toggleDialog();
}
</script>

<template>
  <Slideout :title="region.display_name" :isOpen="show" @onClose="close" :icon="MapPin">
    <template v-if="!region.deleted_at" #actions>
      <IconButton
        v-if="region.authorization.update"
        :href="route('regions.edit', { region: region.id })"
        :icon="EditAlt"
        ariaLabel="Edit region"
        size="sm"
        variant="soft"
      />
      <IconButton
        v-if="region.authorization.delete"
        color="danger"
        :icon="TrashIcon"
        ariaLabel="Move region to trash"
        size="sm"
        variant="soft"
        @click="toggleDialog"
      />
    </template>
    <div class="-m-4 flex flex-col divide-y">
      <div class="p-4">
        <InformationCollapsible title="Region information">
          <DefinitionList>
            <DefinitionListItem
              v-if="region.description"
              :description="region.description"
              :icon="DocumentLayoutLeft"
              title="Description"
            />
            <DefinitionListItem
              v-if="hasPermission('read-program')"
              :description="!region.programs?.length ? 'No programs' : undefined"
              :icon="FilesLandscapesAlt"
              title="Programs"
            >
              <DefinitionListItemBadgeWrapper
                v-if="region.programs?.length"
                :badgeItems="
                  region.programs?.map((program) => ({
                    id: program?.id,
                    label: program?.display_name
                  })) ?? []
                "
                :buttonRoute="route('programs.index')"
                buttonLabel="Go to programs"
              />
            </DefinitionListItem>
          </DefinitionList>
        </InformationCollapsible>
      </div>
      <div v-if="region.users?.length" class="p-4">
        <InformationCollapsible title="Region Directors">
          <DefinitionList v-for="director in region.users" :key="director?.id ?? undefined">
            <DefinitionListItem
              :description="!region.programs ? '' : undefined"
              :icon="User"
              title="Name"
            >
              <Badge :label="director.name" variant="soft" size="md" />
            </DefinitionListItem>
            <DefinitionListItem
              v-if="director.email"
              :description="director.email"
              :icon="Envelope"
              title="Email"
            />
          </DefinitionList>
        </InformationCollapsible>
      </div>
    </div>
  </Slideout>
  <DangerDialog
    title="Move Region to Trash"
    message="Are you sure you want to move this region to the trash?"
    confirmButtonLabel="Move to trash"
    :isOpen="isDialogOpen"
    :isLoading="form.processing"
    @onConfirm="handleRegionDelete"
    @onCancel="toggleDialog"
    @onClose="toggleDialog"
  />
</template>
