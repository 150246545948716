import { getAdmitHubContactEscalationCount } from '@/api/AdmitHubApi';
import { onUserEvent } from '@/hooks/useWebsockets';

export function useAdmitHubEscalationCount(): ComputedRef<number> {
  const count = ref<number>(0);

  onUserEvent('admithub-escalation-count-updated', refreshCount);

  onMounted(refreshCount);

  async function refreshCount() {
    count.value = await getAdmitHubContactEscalationCount();
  }

  return computed(() => count.value);
}
