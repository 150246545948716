<script setup lang="ts">
type EnumData = App.Base.Data.EnumData;

import type { HybridlyFormData } from '@/@types/global';

import { ButtonColor, ButtonVariant } from '@/hooks/useButtonClasses';

import Button from '@/components/button/Button.vue';
import DangerDialog from '@/components/dialog/DangerDialog.vue';
import Fieldset from '@/components/fieldset/Fieldset.vue';
import Form from '@/components/form/Form.vue';
import FormFooter from '@/components/formFooter/FormFooter.vue';
import FormHeader from '@/components/formHeader/FormHeader.vue';
import FormInlineFields from '@/components/formInlineFields/FormInlineFields.vue';
import FormInstituteInput from '@/components/formComboboxInput/FormInstituteInput.vue';
import FormListboxInput from '@/components/formListboxInput/FormListboxInput.vue';
import FormScholarshipInput from '@/components/formComboboxInput/FormScholarshipInput.vue';
import FormTextInput from '@/components/formTextInput/FormTextInput.vue';

const props = defineProps<{
  isEditing?: boolean;
  form: HybridlyFormData<{
    type: EnumData;
    institute_id: number;
    scholarship_id: number;
    amount: number;
    years_renewable: number;
    status: EnumData;
  }>;
  returnUrl?: string;
  statuses: EnumData[];
  types: EnumData[];
  student: App.Students.Data.StudentData;
}>();

const isCancelDialogOpen = ref(false);

const { onBackForward } = useBackForward();

// TODO: Fix intecepting the back button
onBackForward(() => {
  router.abort();
});

function handleToggleCancelDialog() {
  if (props.form.isDirty) {
    isCancelDialogOpen.value = !isCancelDialogOpen.value;
    return;
  }
  handleRedirect();
}

function handleRedirect() {
  router.navigate({
    url:
      props.returnUrl || route('students.student-scholarships.index', { student: props.student.id })
  });
}
</script>

<template>
  <Form @submit.prevent="form.submit">
    <template #header>
      <FormHeader
        canClose
        :title="`${isEditing ? 'Edit' : 'Add'} a Scholarship Application`"
        :description="
          isEditing
            ? 'Use the form below to edit the scholarship application.'
            : 'Fill out the form below add a new scholarship application.'
        "
        @onClose="handleToggleCancelDialog"
      />
    </template>
    <div class="divide-y-slate-200 flex flex-col gap-y-8">
      <Fieldset title="Global information">
        <FormListboxInput
          :error="form.errors.type"
          :modelValue="form.fields.type"
          :options="types"
          label="Scholarship type"
          @update:modelValue="(value) => (form.fields.type = value)"
        />
        <FormInstituteInput
          v-if="form.fields.type?.value === 'institutional-aid'"
          :error="form.errors.institute_id"
          :modelValue="form.fields.institute_id"
          label="Institution"
          @update:modelValue="(value) => (form.fields.institute_id = value)"
        />
        <FormScholarshipInput
          :error="form.errors.scholarship_name"
          :modelValue="form.fields.scholarship_name"
          label="Scholarship"
          @update:modelValue="(value) => (form.fields.scholarship_name = value)"
        />
        <div class="flex flex-col gap-y-1.5">
          <FormInlineFields>
            <FormTextInput
              :error="form.errors.amount"
              :modelValue="form.fields.amount"
              label="Amount"
              name="amount"
              placeholder="Enter amount"
              prepend="$"
              type="number"
              @update:modelValue="(value) => (form.fields.amount = value)"
            />
            <FormTextInput
              :error="form.errors.years_renewable"
              :modelValue="form.fields.years_renewable"
              label="Years renewable"
              name="years_renewable"
              placeholder="e.g. 2"
              type="number"
              @update:modelValue="(value) => (form.fields.years_renewable = value)"
            />
          </FormInlineFields>
          <p class="flex flex-row justify-end text-sm font-semibold text-slate-500">
            Total amount: ${{ form.fields.amount * form.fields.years_renewable }}
          </p>
        </div>
        <div class="flex items-end gap-x-2">
          <FormListboxInput
            :error="form.errors.status"
            :modelValue="form.fields.status"
            :options="statuses"
            class="grow"
            label="Status"
            @update:modelValue="(value) => (form.fields.status = value)"
          />
        </div>
      </Fieldset>
    </div>
    <template #footer>
      <FormFooter>
        <Button
          type="submit"
          :color="ButtonColor.primary"
          :isDisabled="form.processing || !form.isDirty"
          :isLoading="form.processing"
          :variant="ButtonVariant.solid"
          >Confirm</Button
        >
        <Button
          :color="ButtonColor.slate"
          :variant="ButtonVariant.outlined"
          type="button"
          @click="handleToggleCancelDialog"
        >
          Back to scholarship applications
        </Button>
      </FormFooter>
    </template>
  </Form>
  <DangerDialog
    :title="`Cancel ${isEditing ? 'editing' : 'adding a new'} scholarship application`"
    :message="`Are you sure you want to cancel ${
      isEditing ? 'editing' : 'adding'
    } this scholarship application? All ${isEditing ? 'changes' : 'information'} will be lost.`"
    :isOpen="isCancelDialogOpen"
    cancelButtonLabel="Stay on this page"
    confirmButtonLabel="Go back to applications page"
    @onCancel="handleToggleCancelDialog"
    @onClose="handleToggleCancelDialog"
    @onConfirm="handleRedirect"
  />
</template>
