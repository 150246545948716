import { Ref, ref, computed, watch, onMounted, onBeforeUnmount } from 'vue';

export function useStickyColumns(
  stickyStartColumns: Ref<number | undefined>,
  stickyEndColumns: Ref<number | undefined>
) {
  const tableRef = ref<HTMLElement | null>(null);
  const tableHeaderRef = ref<HTMLElement | null>(null);
  const isLayoutReady = ref(false);
  const columnWidths = ref<number[]>([]);
  let resizeObserver: ResizeObserver | null = null;

  const headerCells = computed(() => tableHeaderRef.value?.querySelectorAll('th') ?? []);

  const updateColumnWidths = async () => {
    if (!tableHeaderRef.value || !headerCells.value.length) {
      return;
    }

    await new Promise((resolve) => requestAnimationFrame(resolve));

    columnWidths.value = Array.from(headerCells.value).map((cell) => {
      void cell.offsetHeight;
      return cell.offsetWidth;
    });

    isLayoutReady.value = true;
  };

  watch(
    [tableHeaderRef, () => headerCells.value.length],
    async ([newHeaderRef]) => {
      if (newHeaderRef && headerCells.value.length) {
        await updateColumnWidths();
      }
    },
    { immediate: true }
  );

  onMounted(() => {
    resizeObserver = new ResizeObserver(updateColumnWidths);

    if (tableHeaderRef.value) {
      resizeObserver.observe(tableHeaderRef.value);

      const stickyCount = Math.max(stickyStartColumns.value ?? 0, stickyEndColumns.value ?? 0);

      if (stickyCount > 0) {
        Array.from(headerCells.value)
          .slice(0, stickyCount)
          .forEach((cell) => resizeObserver?.observe(cell));
      }
    }
  });

  onBeforeUnmount(() => {
    resizeObserver?.disconnect();
    resizeObserver = null;
  });

  const stickyColumnLeftValues = computed(() => {
    if (!isLayoutReady.value || !columnWidths.value.length) {
      return [];
    }

    return columnWidths.value
      .slice(0, stickyStartColumns.value ?? 0)
      .reduce<number[]>((acc, width) => [...acc, (acc[acc.length - 1] ?? 0) + width], [0])
      .slice(0, -1);
  });

  const stickyEndColumnRightValues = computed(() => {
    if (!isLayoutReady.value || !columnWidths.value.length) {
      return [];
    }

    const endColumns = columnWidths.value.slice(
      Math.max(columnWidths.value.length - (stickyEndColumns.value ?? 0), 0)
    );

    return endColumns
      .reduce<number[]>((acc, width) => [...acc, (acc[acc.length - 1] ?? 0) + width], [0])
      .slice(0, -1);
  });

  const stickyStartColumnsWidth = computed(() => {
    if (!isLayoutReady.value || !columnWidths.value.length) {
      return 0;
    }

    return columnWidths.value
      .slice(0, stickyStartColumns.value ?? 0)
      .reduce((sum, width) => sum + width, 0);
  });

  const stickyEndColumnsWidth = computed(() => {
    if (!isLayoutReady.value || !columnWidths.value.length) {
      return 0;
    }

    return columnWidths.value
      .slice(Math.max(columnWidths.value.length - (stickyEndColumns.value ?? 0), 0))
      .reduce((sum, width) => sum + width, 0);
  });

  return {
    tableRef,
    tableHeaderRef,
    stickyColumnLeftValues,
    stickyEndColumnRightValues,
    stickyStartColumnsWidth,
    stickyEndColumnsWidth
  };
}
