<script setup lang="ts">
type EnumData = App.Base.Data.EnumData;

import CenteredContent from '@/components/content/CenteredContent.vue';
import AlertForm from '@/components/alerts/AlertForm.vue';
import { Selectable } from '@/components/selectBox/selectBox';

defineProps<{
  types: EnumData[];
  returnUrl?: string;
}>();

const form = useForm({
  method: 'POST',
  url: route('alerts.store'),
  fields: {
    title: '',
    expiration_date: '',
    content_json: null,
    content_html: null,
    type: null as Selectable<string> | null,
    program_ids: [] as Selectable<number>[],
    user_ids: [] as Selectable<number>[]
  },
  transform: (fields) => ({
    ...fields,
    type: fields.type?.value,
    program_ids: fields.program_ids?.map((program) => program.value) ?? [],
    user_ids: fields.user_ids?.map((user) => user.value) ?? []
  })
});
</script>


					<script lang="ts">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <CenteredContent>
    <AlertForm :form="form" :types="types" :returnUrl="returnUrl" />
  </CenteredContent>
</template>
