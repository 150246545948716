<script setup lang="ts">
import { ButtonColor, ButtonVariant } from '@/hooks/useButtonClasses';

import Button from '@/components/button/Button.vue';
import DangerDialog from '@/components/dialog/DangerDialog.vue';
import Form from '@/components/form/Form.vue';
import FormFooter from '@/components/formFooter/FormFooter.vue';
import FormHeader from '@/components/formHeader/FormHeader.vue';
import TabNav from '@/components/tabNav/TabNav.vue';
import TabNavItem from '@/components/tabNav/TabNavItem.vue';

const props = defineProps<{
  hideControls?: boolean;
  isEditing?: boolean;
  isDirty: boolean;
  isProcessing: boolean;
  returnUrl?: string;
  studentId: number;
}>();

const emit = defineEmits<{
  onSubmit: [void];
}>();

const isCancelDialogOpen = ref(false);

const { matches } = useRoute();
const { onBackForward } = useBackForward();

// TODO: Fix intecepting the back button
onBackForward(() => {
  router.abort();
});

function handleToggleCancelDialog() {
  if (props.isDirty) {
    isCancelDialogOpen.value = !isCancelDialogOpen.value;
    return;
  }
  handleRedirect();
}

function handleRedirect() {
  router.navigate({
    url:
      props.returnUrl ||
      route('students.show', {
        student: props.studentId
      })
  });
}

function handleFormSubmit() {
  emit('onSubmit');
}
</script>

<template>
  <div>
    <Form @submit.prevent="handleFormSubmit">
      <template #header>
        <FormHeader
          canClose
          :title="`${isEditing ? 'Edit' : 'Add'} a Student`"
          :description="
            isEditing
              ? 'Use the form below to edit the student.'
              : 'Fill out the form below add a new student.'
          "
          @onClose="handleToggleCancelDialog"
        />
      </template>
      <TabNav layout="inline">
        <TabNavItem
          :href="route('students.edit', { student: studentId })"
          :isActive="matches('students.edit')"
          label="General"
          variant="inline"
        />
        <TabNavItem
          :href="route('students.edit.parents-guardians', { student: studentId })"
          :isActive="matches('students.edit.parents-guardians')"
          label="Parents/Guardians"
          variant="inline"
        />
        <TabNavItem
          :href="route('students.edit.academic-details', { student: studentId })"
          :isActive="matches('students.edit.academic-details')"
          label="Academic Details"
          variant="inline"
        />
        <TabNavItem
          :href="route('students.edit.enrollment-details', { student: studentId })"
          :isActive="matches('students.edit.enrollment-details')"
          label="Enrollment Details"
          variant="inline"
        />
        <TabNavItem
          :href="route('students.edit.custom-fields', { student: studentId })"
          :isActive="matches('students.edit.custom-fields')"
          label="Custom Fields"
          variant="inline"
        />
      </TabNav>
      <slot />
      <template #footer>
        <FormFooter v-if="!hideControls">
          <Button
            type="submit"
            :color="ButtonColor.primary"
            :isDisabled="isProcessing || !isDirty"
            :isLoading="isProcessing"
            :variant="ButtonVariant.solid"
            >Confirm</Button
          >
          <Button
            :color="ButtonColor.slate"
            :variant="ButtonVariant.outlined"
            type="button"
            @click="handleToggleCancelDialog"
            >Back to student profile</Button
          >
        </FormFooter>
      </template>
    </Form>

    <DangerDialog
      :title="`Cancel ${isEditing ? 'editing' : 'adding a new'} student`"
      :message="`Are you sure you want to cancel ${
        isEditing ? 'editing' : 'adding'
      } this student? All ${isEditing ? 'changes' : 'information'} will be lost.`"
      :isOpen="isCancelDialogOpen"
      cancelButtonLabel="Stay on this page"
      confirmButtonLabel="Go back to student overview"
      @onCancel="handleToggleCancelDialog"
      @onClose="handleToggleCancelDialog"
      @onConfirm="handleRedirect"
    />
  </div>
</template>
