<script setup lang="ts">
type AdmitHubEscalationData = App.AdmitHub.Data.AdmitHubEscalationData;
type EnumData = App.Base.Data.EnumData;

import { ButtonColor, ButtonSize, ButtonVariant } from '@/hooks/useButtonClasses';

import AdmitHubMessage from '@/components/admitHub/AdmitHubMessage.vue';
import Badge from '@/components/badge/Badge.vue';
import Button from '@/components/button/Button.vue';
import DateTime from '@/components/dateTime/DateTime.vue';
import IconButton from '@/components/button/IconButton.vue';
import RadioInput from '@/components/radioInput/RadioInput.vue';
import Slideout from '@/components/slideout/Slideout.vue';

import IconGraduationCap from '@/icons/line/graduation-cap.svg';
import IconTimes from '@/icons/line/times.svg';

const props = defineProps<{
  escalation: AdmitHubEscalationData;
  resolutionTypes: EnumData[];
}>();

const { show, close } = useDialog();

const { fields, submit, processing } = useForm({
  method: 'POST',
  url: route('admithub.escalations.resolutions.store', { escalation: props.escalation.id }),
  fields: { resolved_via: props.escalation.resolution?.resolved_via },
  hooks: {
    success: () => close()
  }
});
</script>

<template>
  <Slideout title="Escalation" :isOpen="show" @onClose="close">
    <template #content>
      <form class="flex h-full flex-col overflow-y-scroll" @submit.prevent="submit">
        <header class="sticky top-0 flex items-center justify-between border-b bg-white px-3 py-2">
          <h3 class="text-base font-bold text-zinc-900">Escalated Message</h3>
          <IconButton
            :icon="IconTimes"
            ariaLabel="Close escalation"
            variant="invisible"
            @click="close"
          />
        </header>

        <div class="border-b px-3 py-2">
          <h3 class="text-base font-bold text-zinc-900">
            {{ escalation.contact?.contactable?.name }}
          </h3>
          <Badge
            class="mt-0.5"
            variant="soft"
            label="Student"
            size="sm"
            :iconLeft="IconGraduationCap"
          />
        </div>

        <div class="flex flex-col items-center border-b bg-zinc-50/50 px-3 py-4">
          <DateTime
            :date="escalation.occurred_at"
            class="pb-2 text-center text-sm font-bold text-zinc-500"
          />
          <AdmitHubMessage
            v-if="escalation.message"
            :message="escalation.message"
            centered
            class="border"
          />
        </div>

        <div class="border-b px-3 py-2">
          <h3 class="text-base font-bold text-zinc-900">Resolve Escalation</h3>
          <p class="text-sm text-zinc-500">
            Choose the option that best represents the way you resolved the escalation.
          </p>
        </div>

        <div class="flex-1 px-3 py-4">
          <RadioInput
            class="!gap-4"
            v-model="fields.resolved_via"
            :ariaLabel="'Resolve Escalation'"
            :radioInputs="resolutionTypes"
            name="resolve_escalation"
          />
        </div>

        <div class="sticky bottom-0 flex items-center space-x-2 border-t bg-white px-3 py-2">
          <Button
            :color="ButtonColor.slate"
            :size="ButtonSize.lg"
            :variant="ButtonVariant.outlined"
            class="w-full"
            @click="close"
          >
            Cancel
          </Button>
          <Button
            :color="ButtonColor.primary"
            :isDisabled="processing"
            :size="ButtonSize.lg"
            :variant="ButtonVariant.solid"
            class="w-full"
            type="submit"
          >
            Resolve
          </Button>
        </div>
      </form>
    </template>
  </Slideout>
</template>
