<script setup lang="ts">
import CenteredContent from '@/components/content/CenteredContent.vue';
import RegionForm from '@/components/regions/RegionForm.vue';
import { Selectable } from '@/components/selectBox/selectBox';

defineProps<{
  returnUrl?: string;
}>();

const form = useForm({
  method: 'POST',
  url: route('regions.store'),
  fields: {
    display_name: '',
    description: '',
    director_ids: [] as Selectable<number>[]
  },
  transform: (fields) => ({
    ...fields,
    director_ids: fields.director_ids.map((item) => item.value)
  })
});
</script>


					<script lang="ts">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <CenteredContent>
    <RegionForm :form="form" :returnUrl="returnUrl" />
  </CenteredContent>
</template>
