<script setup lang="ts">
type Block = App.Surveys.Data.SurveyBlockData;
type Survey = App.Surveys.Data.SurveyData;

import { ButtonColor, ButtonSize, ButtonVariant } from '@/hooks/useButtonClasses';
import { useDialogHelpers } from '@/hooks/useDialogHelpers';

import Button from '@/components/button/Button.vue';
import DangerDialog from '@/components/dialog/DangerDialog.vue';
import IconButton from '@/components/button/IconButton.vue';
import TabNav from '@/components/tabNav/TabNav.vue';
import TabNavItem from '@/components/tabNav/TabNavItem.vue';

import ArrowLeft from '@/icons/line/arrow-left.svg';
import ExternalLinkAlt from '@/icons/line/external-link-alt.svg';

const props = defineProps<{
  block: Block;
  href: string;
  referencedBlocks: Block[];
  survey: Survey;
  title: string;
}>();

const { isDialogOpen, toggleDialog } = useDialogHelpers();
const { matches } = useRoute();

const form = useForm({
  method: 'DELETE',
  spoof: false,
  preserveState: true,
  only: ['block'],
  url: route('surveys.blocks.delete', {
    survey: props.survey.id,
    block: props.block.id
  }),
  fields: {},
  hooks: {
    success() {
      toggleDialog();
    }
  }
});

async function handleSurveyBlockDelete() {
  form.submit();
}
</script>

<template>
  <div class="flex items-center gap-x-2 px-4">
    <IconButton
      :href="href"
      :icon="ArrowLeft"
      ariaLabel="Back to question list"
      size="sm"
      variant="soft"
    />
    <h2 class="grow py-5 text-2xs font-bold uppercase leading-4 text-slate-600">
      {{ title }}
    </h2>
    <Button
      :color="ButtonColor.danger"
      :size="ButtonSize.sm"
      :variant="ButtonVariant.invisible"
      @click="toggleDialog"
      >Delete</Button
    >
  </div>
  <slot />
  <TabNav layout="inline">
    <TabNavItem
      :isActive="matches('surveys.blocks.settings.show')"
      class="w-1/2"
      :href="route('surveys.blocks.settings.show', { survey: survey.id, block: block.id })"
      label="Settings"
      variant="inline"
    />
    <TabNavItem
      :isActive="matches('surveys.blocks.logic.show')"
      class="w-1/2"
      :href="route('surveys.blocks.logic.show', { survey: survey.id, block: block.id })"
      label="Skip Logic"
      variant="inline"
    />
  </TabNav>
  <DangerDialog
    v-if="referencedBlocks.length"
    title="Delete Question"
    message="This question is referenced by the following question:"
    confirmButtonLabel="Delete question"
    :isOpen="isDialogOpen"
    :isLoading="form.processing"
    @onConfirm="handleSurveyBlockDelete"
    @onCancel="toggleDialog"
    @onClose="toggleDialog"
  >
    <template #body>
      <div class="flex flex-col gap-y-4">
        <div
          v-for="referencedBlock in referencedBlocks"
          :key="referencedBlock.id"
          class="flex gap-x-2 rounded-lg border border-slate-200 bg-white py-4 pl-3 pr-2 text-sm font-medium leading-5 text-slate-900"
        >
          <span>{{ referencedBlock.order + 1 }}</span>
          <span class="grow">{{ referencedBlock.block_text }}</span>
          <IconButton
            :href="
              route('surveys.blocks.settings.show', {
                survey: survey.id,
                block: referencedBlock.id
              })
            "
            :icon="ExternalLinkAlt"
            ariaLabel="Show referenced question"
            size="xs"
            variant="invisible"
          />
        </div>
        <p class="text-sm leading-5 text-slate-500">
          By deleting it, you will also delete the reference made to this question. Do you want to
          continue?
        </p>
      </div>
    </template>
  </DangerDialog>
  <DangerDialog
    v-else
    title="Delete Question"
    message="Are you sure you want to delete this question?"
    confirmButtonLabel="Delete question"
    :isOpen="isDialogOpen"
    :isLoading="form.processing"
    @onConfirm="handleSurveyBlockDelete"
    @onCancel="toggleDialog"
    @onClose="toggleDialog"
  />
</template>
