<script setup lang="tsx" generic="T extends Record<string, unknown>">
import { HybridlyTable } from '@/@types/hybridly';
import TableCheckboxCell from './TableCheckboxCell.vue';
import type { CustomCells, HybridlyRow, TableColumn } from './Table';
import Table from './Table.vue';
import TableActionButtonsCell from './TableActionButtonsCell.vue';
import { TableSelectionState } from '@/hooks/useHybridlyTable';
import SelectionBanner from './SelectionBanner.vue';

const props = withDefaults(
  defineProps<{
    table: HybridlyTable;
    customCells?: CustomCells<T>;
    customHeaderCells?: CustomCells<T>;
    selectionsEnabled?: boolean;
    stickyStartColumns?: number;
    stickyEndColumns?: number;
    clickableRows?: boolean;
    tableSelectionState?: TableSelectionState;
    bordered?: boolean;
    view?: 'table' | 'list';
  }>(),
  {
    selectionsEnabled: true,
    view: 'table'
  }
);

defineEmits<{
  clickRow: [event: MouseEvent, row: T];
}>();

defineSlots<{
  emptyState: void;
}>();

const selectAllCheckboxValue = computed(() => {
  if (
    props.tableSelectionState?.arePageRecordsSelected ||
    props.tableSelectionState?.areAllRecordsSelected
  ) {
    return true;
  }

  if (props.tableSelectionState?.hasSelection) {
    return 'indeterminate';
  }

  return false;
});

const columns = computed(() => {
  const outputColumns = [
    ...(props.selectionsEnabled
      ? [
          {
            key: 'select',
            cellRender: ({ row }) => (
              <TableCheckboxCell
                isDisabled={props.tableSelectionState?.areAllRecordsSelected}
                checked={
                  props.tableSelectionState?.hasRowSelected(
                    (row as unknown as HybridlyRow<T>).key
                  ) ?? false
                }
                onChecked={() =>
                  props.tableSelectionState?.toggleRow((row as unknown as HybridlyRow<T>).key)
                }
              />
            ),
            headerCellRender: () => (
              <TableCheckboxCell
                checked={selectAllCheckboxValue.value}
                onChecked={() => props.tableSelectionState?.togglePageRows()}
                isDisabled={props.table.records.length === 0}
              />
            ),
            width: '52px'
          } as TableColumn<T>
        ]
      : []),
    ...props.table.columns
      .filter((column) => {
        return column.metadata?.hidden !== true;
      })
      .map((column) => ({
        label: column.label,
        key: column.name,
        isSortable: column.isSortable,
        onSort: column.toggleSort,
        cellRender: props.customCells ? props.customCells[column.name as keyof T] : null,
        headerCellRender: props.customHeaderCells
          ? props.customHeaderCells[column.name as keyof T]
          : null,
        value: ({ row }) => row.value(column.name),
        ...(column.metadata ?? {})
      }))
  ];

  if (props.view !== 'list' && props.table.inlineActions.length > 0) {
    outputColumns.push({
      key: 'actions',
      label: '',
      width: `${
        24 + // cell padding
        34 * props.table.inlineActions.length + // button width
        (props.table.inlineActions.length > 1 ? (props.table.inlineActions.length - 1) * 8 : 0) // 8px gap between buttons
      }px`,
      cellRender: (ctx: { row: HybridlyRow<T> }) => (
        <TableActionButtonsCell actions={ctx.row.actions} />
      )
    } as unknown as TableColumn<HybridlyRow<T>>);
  }

  return outputColumns;
}) as ComputedRef<TableColumn<T>[]>;

const currentSortKey = computed(() => {
  const currentSort = props.table.currentSorts()[0];
  if (!currentSort) {
    return null;
  }

  const column = currentSort.name;
  return currentSort.direction === 'desc' ? `-${column}` : column;
});

const hasMultiplePages = computed(() => {
  const meta = (props.table.paginator as Paginator)?.meta;
  return meta.total > meta.per_page || meta.next_page_url || meta.prev_page_url;
});

function handleSort(columnKey: string | null) {
  if (!columnKey) {
    props.table.clearSorts();
    return;
  }

  const sortColumn = columnKey.startsWith('-') ? columnKey.slice(1) : columnKey;
  props.table.toggleSort(sortColumn, {
    direction: columnKey.startsWith('-') ? 'desc' : 'asc'
  });
}

function handleStickyColumnCellClick(event: Event, row: T) {
  event.stopPropagation();

  props.tableSelectionState?.toggleRow((row as unknown as HybridlyRow<T>).key);
}
</script>

<template>
  <Table
    v-if="table.records.length"
    :columns="columns"
    :rows="table.records as any"
    :selectedRowIds="tableSelectionState?.selectedRowIds ?? []"
    :clickableRows="clickableRows"
    :stickyStartColumns="stickyStartColumns"
    :stickyEndColumns="stickyEndColumns"
    :getRowId="(row) => row.key as number"
    :currentSort="currentSortKey"
    :bordered="bordered"
    @clickRow="($event, row) => $emit('clickRow', $event, row)"
    @clickStickyCell="handleStickyColumnCellClick"
    @sort="handleSort"
    :view="view"
  >
    <template #selectionBanner>
      <tr
        v-if="
          hasMultiplePages &&
          (tableSelectionState?.arePageRecordsSelected ||
            tableSelectionState?.areAllRecordsSelected)
        "
        class="relative z-50"
      >
        <td
          class="border-b border-gray-200 bg-white px-3 py-4 text-sm text-slate-700"
          :colspan="columns.length"
        >
          <SelectionBanner
            itemLabel="row"
            :allRecordsSelected="tableSelectionState?.areAllRecordsSelected ?? false"
            :totalRecordsCount="(table.paginator as Paginator).meta.total"
            :selectedRecordsCount="tableSelectionState?.selectedRowIds.length ?? 0"
            @toggle="() => tableSelectionState?.toggleAllRows()"
          />
        </td>
      </tr>
    </template>
  </Table>
  <template v-if="table.records.length === 0">
    <slot name="emptyState" />
  </template>
</template>
