<script setup lang="ts">
import { provide, watch, defineModel, defineEmits } from 'vue';
import type { AppliedFilters } from '@/components/distiller/types';

const appliedFilters = defineModel<AppliedFilters>();

const emit = defineEmits<{
  'update:appliedFilters': [value: AppliedFilters];
}>();

provide('filters', appliedFilters);

watch(
  () => appliedFilters.value,
  (newValue) => {
    if (newValue) {
      emit('update:appliedFilters', newValue);
    }
  }
);
</script>

<template>
  <slot />
</template>
