<script setup lang="ts">
import type { FormFieldProps, FormFieldSlots } from '@/components/formField/FormField';
import type { TextareaInputProps } from '@/components/textareaInput/TextareaInput.vue';
import FormField from '@/components/formField/FormField.vue';
import TextareaInput from '@/components/textareaInput/TextareaInput.vue';
import { useFormField } from '@/components/formField/useFormField';

type FormTextareaInputProps = Omit<TextareaInputProps, 'hasError'> & FormFieldProps;

const props = defineProps<FormTextareaInputProps>();
defineSlots<FormFieldSlots>();

const emit = defineEmits<{
  'update:modelValue': [value: string | number | null];
}>();

function onValueChange(event: string | number | null): void {
  emit('update:modelValue', event);
}

const { formFieldProps, slots } = useFormField({ props });
</script>

<template>
  <FormField v-bind="formFieldProps">
    <TextareaInput
      :hasError="hasError"
      :isDisabled="isDisabled"
      :modelValue="modelValue"
      :name="name"
      :placeholder="placeholder"
      :variant="variant"
      @update:modelValue="onValueChange"
    />
    <template v-for="slot of slots" #[slot]>
      <slot :name="slot" />
    </template>
  </FormField>
</template>
