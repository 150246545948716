<script setup lang="ts" generic="T extends SelectableValue">
import type { FormFieldProps, FormFieldSlots } from '@/components/formField/FormField';
import type {
  MultiSelectBoxProps,
  Selectable,
  SelectableValue
} from '@/components/selectBox/selectBox';
import { useFormField } from '@/components/formField/useFormField';
import FormField from '@/components/formField/FormField.vue';
import MultiCombobox from '@/components/selectBox/combobox/MultiComboboxInput.vue';

export type FormMultiComboboxProps<T extends SelectableValue> = MultiSelectBoxProps<T> &
  FormFieldProps;

const props = withDefaults(defineProps<FormMultiComboboxProps<T>>(), { searchable: true });
defineSlots<FormFieldSlots>();

const emit = defineEmits<{
  'update:modelValue': [Selectable<T>[]];
  open: [];
}>();

function onValueChange(value: Selectable<T>[]): void {
  emit('update:modelValue', value);
}

const { formFieldProps, slots } = useFormField({ props });
</script>

<template>
  <FormField v-bind="formFieldProps">
    <MultiCombobox
      multiple
      v-bind="$props"
      :selectAll="selectAll"
      @update:modelValue="onValueChange"
      @open="$emit('open')"
    />
    <template v-for="slot of slots" #[slot]>
      <slot :name="slot" />
    </template>
  </FormField>
</template>
