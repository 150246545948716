<script setup lang="ts">
import IconBadge from '@/components/badge/IconBadge.vue';
import ExclamationCircleIcon from '@/icons/line/exclamation-circle.svg';
import Tooltip from '@/components/tooltip/Tooltip.vue';
import { campaignNodeEditorKey } from '@/utils/keys';

const props = defineProps<{
  hasAlerts?: boolean;
  validationResults?: Record<string, string[]>;
  onBadgeClick?: () => void;
  selected?: boolean;
}>();

defineOptions({
  inheritAttrs: false
});

defineEmits<{
  add: [e: MouseEvent];
}>();

const campaign = inject(campaignNodeEditorKey);

const validationMessages = computed(() => {
  return Object.values(props.validationResults || {}).flatMap((messages) => messages);
});
</script>

<template>
  <div>
    <div
      v-bind="$attrs"
      :class="[
        'group/node transition-background relative w-80 rounded-xl border hover:bg-slate-100',
        {
          'border-slate-200 bg-slate-50': !selected,
          'border-transparent bg-slate-100 ring-2 ring-secondary-900': selected
        }
      ]"
    >
      <slot />

      <Tooltip v-if="hasAlerts" class="absolute -right-3 -top-3">
        <div>
          <p v-for="(message, index) in validationMessages" :key="index">
            {{ message }}
          </p>
        </div>

        <template #trigger>
          <IconBadge @click="onBadgeClick" :icon="ExclamationCircleIcon" color="warning" isCircle />
        </template>
      </Tooltip>
    </div>

    <slot v-if="campaign?.paused_at" name="addButton" />
  </div>
</template>
