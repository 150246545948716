<script setup lang="ts">
import CenteredContent from '@/components/content/CenteredContent.vue';
import StudentGroupForm from '@/components/studentGroups/StudentGroupForm.vue';

const props = defineProps<{
  defaultSchool: App.Configuration.Data.SchoolData;
  distiller: App.Search.Data.DistillerData;
  returnUrl?: string;
}>();

const appliedFilters = ref({});

const form = useForm({
  method: 'POST',
  url: route('student-groups.store'),
  fields: {
    name: '',
    school_id: buildSelectableSchool(props.defaultSchool),
    filters: appliedFilters.value || null
  },
  transform: (fields) => ({
    ...fields,
    school_id: fields.school_id?.value,
    filters: appliedFilters.value
  })
});
</script>


					<script lang="ts">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <CenteredContent>
    <StudentGroupForm
      :appliedFilters="appliedFilters"
      :distiller="distiller"
      :form="form"
      :returnUrl="returnUrl"
      @update:appliedFilters="(event) => (appliedFilters = event)"
    />
  </CenteredContent>
</template>
