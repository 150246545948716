<template>
  <div class="divide-y-slate-200 flex w-full flex-col divide-y">
    <slot name="header" />
    <div class="divide-y-slate-200 flex flex-1 flex-col divide-y overflow-y-auto">
      <div class="flex flex-col gap-y-12 px-4 py-6">
        <slot />
      </div>
    </div>
  </div>
</template>
