<script setup lang="ts">
type EnumData = App.Base.Data.EnumData;

import type { HybridlyFormData } from '@/@types/global';

import { getById as getInstituteById } from '@/api/InstituteApi';

import { ButtonColor, ButtonVariant } from '@/hooks/useButtonClasses';

import Button from '@/components/button/Button.vue';
import DangerDialog from '@/components/dialog/DangerDialog.vue';
import Divider from '@/components/divider/Divider.vue';
import Fieldset from '@/components/fieldset/Fieldset.vue';
import Form from '@/components/form/Form.vue';
import FormCheckboxInput from '@/components/formCheckboxInput/FormCheckboxInput.vue';
import FormDateInput from '@/components/formDateInput/FormDateInput.vue';
import FormFooter from '@/components/formFooter/FormFooter.vue';
import FormHeader from '@/components/formHeader/FormHeader.vue';
import FormInlineFields from '@/components/formInlineFields/FormInlineFields.vue';
import FormInstituteInput from '@/components/formComboboxInput/FormInstituteInput.vue';
import FormListboxInput from '@/components/formListboxInput/FormListboxInput.vue';
import FormTextInput from '@/components/formTextInput/FormTextInput.vue';

const props = defineProps<{
  isEditing?: boolean;
  form: HybridlyFormData<{
    institute_id: number;
    date_submitted: string;
    status: EnumData;
    intend_to_enroll: EnumData;
    has_waiver: EnumData;
    waiver: number;
    first_choice: boolean;
    award_meeting_held: boolean;
    early_action: boolean;
    early_decision: boolean;
    sent_hs_transcript: boolean;
    applied_to_honors_program: boolean;
    financial_aid_submitted_to_school: boolean;
  }>;
  returnUrl?: string;
  statuses: EnumData[];
  student: App.Students.Data.StudentData;
}>();

const isCancelDialogOpen = ref(false);
const isIntendToEnrollDialogOpen = ref(false);

const { onBackForward } = useBackForward();

// TODO: Fix intecepting the back button
onBackForward(() => {
  router.abort();
});

function handleStatusChange(status) {
  props.form.fields.status = status;
  props.form.fields.intend_to_enroll = false;
}

function handleInstituteChange(institute) {
  props.form.fields.institute_id = institute;

  if (props.form.fields.has_waiver?.value) {
    updateWaiverAmount(institute?.value);
  }
}

function updateWaiverAmount(instituteId) {
  if (!instituteId) {
    props.form.fields.waiver = null;
    return;
  }

  getInstituteById(instituteId)
    .then((institute) => {
      props.form.fields.waiver = institute.waiver_fee ?? null;
    })
    .catch(() => {
      props.form.fields.waiver = null;
    });
}

function handleHasWaiverChange(hasWaiver) {
  props.form.fields.has_waiver = hasWaiver;
  props.form.fields.waiver = null;

  if (hasWaiver.value) {
    updateWaiverAmount(props.form.fields.institute_id?.value);
  } else {
    props.form.fields.waiver = null;
  }
}

function handleToggleIntendToEnrollDialog() {
  isIntendToEnrollDialogOpen.value = !isIntendToEnrollDialogOpen.value;
}

function handleToggleCancelDialog() {
  if (props.form.isDirty) {
    isCancelDialogOpen.value = !isCancelDialogOpen.value;
    return;
  }
  handleRedirect();
}

function handleRedirect() {
  router.navigate({
    url:
      props.returnUrl || route('students.college-applications.index', { student: props.student.id })
  });
}

function handleFormSubmit() {
  if (props.form.fields.intend_to_enroll?.value && !props.form.initial.intend_to_enroll?.value) {
    handleToggleIntendToEnrollDialog();
  } else {
    props.form.submit();
  }
}
</script>

<template>
  <Form @submit.prevent="handleFormSubmit">
    <template #header>
      <FormHeader
        canClose
        :title="`${isEditing ? 'Edit' : 'Add'} a College Application`"
        :description="
          isEditing
            ? 'Use the form below to edit the college application.'
            : 'Fill out the form below add a new college application.'
        "
        @onClose="handleToggleCancelDialog"
      />
    </template>
    <div class="divide-y-slate-200 flex flex-col gap-y-8">
      <Fieldset title="Global information">
        <FormInstituteInput
          :error="form.errors.institute_id"
          :modelValue="form.fields.institute_id"
          label="Institution"
          @update:modelValue="handleInstituteChange"
        />
        <FormDateInput
          :isDisabled="true"
          :error="form.errors.date_submitted"
          :modelValue="form.fields.date_submitted"
          label="Application Status Date"
          helperText="The application status date is automatically set when the college application has a submitted status."
          name="date_submitted"
          format="MMM dd, yyyy"
          :clearable="false"
          @update:modelValue="(value) => (form.fields.date_submitted = value)"
        />
        <FormListboxInput
          :error="form.errors.status"
          :modelValue="form.fields.status"
          :options="statuses"
          label="Status"
          @update:modelValue="handleStatusChange"
        />
        <FormListboxInput
          :error="form.errors.intend_to_enroll"
          :isDisabled="form.fields.status?.value !== 'accepted'"
          :modelValue="form.fields.intend_to_enroll"
          :options="[
            {
              label: 'No',
              value: false
            },
            {
              label: 'Yes',
              value: true
            }
          ]"
          label="Intend to Enroll"
          helperText="You can set this field when the status is Accepted only"
          @update:modelValue="(value) => (form.fields.intend_to_enroll = value)"
        />
      </Fieldset>
      <Divider />
      <Fieldset title="Waiver">
        <FormInlineFields>
          <FormListboxInput
            :error="form.errors.has_waiver"
            :modelValue="form.fields.has_waiver"
            :options="[
              {
                label: 'No',
                value: false
              },
              {
                label: 'Yes',
                value: true
              }
            ]"
            label="Has Waiver"
            @update:modelValue="handleHasWaiverChange"
          />
          <FormTextInput
            :error="form.errors.waiver"
            :isDisabled="!form.fields.has_waiver?.value"
            :modelValue="form.fields.waiver"
            label="Waiver Amount"
            name="waiver"
            placeholder="Enter amount"
            prepend="$"
            type="number"
            @update:modelValue="(value) => (form.fields.waiver = value)"
          />
        </FormInlineFields>
      </Fieldset>
      <Divider />
      <Fieldset title="Application information">
        <FormCheckboxInput
          :checked="form.fields.first_choice"
          :error="form.errors.first_choice"
          label="First Choice"
          name="first_choice"
          @update:checked="(value) => (form.fields.first_choice = value)"
        />

        <FormCheckboxInput
          :checked="form.fields.early_action"
          :error="form.errors.early_action"
          label="Early Action"
          name="early_action"
          @update:checked="(value) => (form.fields.early_action = value)"
        />
        <FormCheckboxInput
          :checked="form.fields.early_decision"
          :error="form.errors.early_decision"
          label="Early Decision"
          name="early_decision"
          @update:checked="(value) => (form.fields.early_decision = value)"
        />
        <FormCheckboxInput
          :checked="form.fields.sent_hs_transcript"
          :error="form.errors.sent_hs_transcript"
          label="Sent HS Transcript"
          name="sent_hs_transcript"
          @update:checked="(value) => (form.fields.sent_hs_transcript = value)"
        />
        <FormCheckboxInput
          :checked="form.fields.applied_to_honors_program"
          :error="form.errors.applied_to_honors_program"
          label="Applied to Honors Program"
          name="applied_to_honors_program"
          @update:checked="(value) => (form.fields.applied_to_honors_program = value)"
        />
        <FormCheckboxInput
          :checked="form.fields.financial_aid_submitted_to_school"
          :error="form.errors.financial_aid_submitted_to_school"
          label="Financial Aid Submitted to School"
          name="financial_aid_submitted_to_school"
          @update:checked="(value) => (form.fields.financial_aid_submitted_to_school = value)"
        />
        <FormCheckboxInput
          :isDisabled="true"
          :checked="form.fields.award_meeting_held"
          :error="form.errors.award_meeting_held"
          label="1:1 Award Letter Meeting Held"
          name="award_meeting_held"
          helperText="This field is automatically via the student's interactions."
          @update:checked="(value) => (form.fields.award_meeting_held = value)"
        />
      </Fieldset>
    </div>
    <template #footer>
      <FormFooter>
        <Button
          type="submit"
          :color="ButtonColor.primary"
          :isDisabled="form.processing || !form.isDirty"
          :isLoading="form.processing"
          :variant="ButtonVariant.solid"
          >Confirm</Button
        >
        <Button
          @click="handleToggleCancelDialog"
          :color="ButtonColor.slate"
          :variant="ButtonVariant.outlined"
          type="button"
        >
          Back to college applications
        </Button>
      </FormFooter>
    </template>
  </Form>
  <DangerDialog
    :title="`Cancel ${isEditing ? 'editing' : 'adding a new'} college application`"
    :message="`Are you sure you want to cancel ${
      isEditing ? 'editing' : 'adding'
    } this college application? All ${isEditing ? 'changes' : 'information'} will be lost.`"
    :isOpen="isCancelDialogOpen"
    cancelButtonLabel="Stay on this page"
    confirmButtonLabel="Go back to college applications"
    @onCancel="handleToggleCancelDialog"
    @onClose="handleToggleCancelDialog"
    @onConfirm="handleRedirect"
  />
  <DangerDialog
    title="Intend to Enroll confirmation"
    message="Setting the 'Intend to Enroll' status to 'Yes' for this college application will deactivate this status for any other college application that currently has it activated. Are you sure you want to proceed?"
    :isOpen="isIntendToEnrollDialogOpen"
    confirmButtonLabel="Confirm Intend to Enroll"
    @onCancel="handleToggleIntendToEnrollDialog"
    @onClose="handleToggleIntendToEnrollDialog"
    @onConfirm="form.submit"
  />
</template>
