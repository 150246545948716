<script setup lang="ts">
import TabNavItem from '@/components/tabNav/TabNavItem.vue';

export interface IndexStudentActionsTabNavCounts {
  collegeApplications: number;
  scholarships: number;
  exams: number;
}

defineProps<{
  counts: IndexStudentActionsTabNavCounts;
  student: App.Students.Data.StudentData;
}>();

const { matches } = useRoute();
</script>

<template>
  <TabNavItem
    :badgeLabel="counts.collegeApplications"
    :href="route('students.college-applications.index', { student: student.id })"
    :isActive="matches('students.college-applications.index')"
    label="College Applications"
    variant="inline"
  />
  <TabNavItem
    variant="inline"
    :badgeLabel="counts.scholarships"
    :href="route('students.student-scholarships.index', { student: student.id })"
    :isActive="matches('students.student-scholarships.index')"
    label="Scholarships"
  />
  <TabNavItem
    :badgeLabel="counts.exams"
    :href="route('students.tests.index', { student: student.id })"
    :isActive="matches('students.tests.index')"
    label="Exams"
    variant="inline"
  />
  <TabNavItem
    :href="route('students.financial-aid.index', { student: student.id })"
    :isActive="matches('students.financial-aid.index')"
    label="Financial Aid"
    variant="inline"
  />
</template>
