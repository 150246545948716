<script setup lang="ts">
import TabNav from '@/components/tabNav/TabNav.vue';
import TabNavItem from '@/components/tabNav/TabNavItem.vue';
import { RouteName } from 'hybridly';

defineProps<{
  student: App.Students.Data.StudentData;
}>();

const { matches } = useRoute();

function matchAny(...routes: RouteName[]): boolean {
  return routes.some((route) => matches(route));
}
</script>

<template>
  <TabNav layout="inline" floating>
    <TabNavItem
      :href="route('students.show', { student: student.id })"
      :isActive="matches('students.show')"
      label="Overview"
    />
    <TabNavItem
      :href="route('students.interactions.index', { student: student.id })"
      :isActive="matches('students.interactions.index')"
      label="Interactions"
    />
    <TabNavItem
      :href="route('students.college-applications.index', { student: student.id })"
      :isActive="
        matchAny(
          'students.college-applications.index',
          'students.student-scholarships.index',
          'students.tests.index',
          'students.financial-aid.index'
        )
      "
      label="Student Actions"
    />
    <TabNavItem
      :href="route('students.surveys.index', { student: student.id })"
      :isActive="matches('students.surveys.index')"
      label="Surveys"
    />
    <TabNavItem
      :href="route('students.communication.sms.show', { student: student.id })"
      :isActive="matches('students.communication.sms.show')"
      label="Communication"
      :hasUnread="student?.has_unread_thread"
    />
  </TabNav>
</template>
