<script setup lang="ts">
import { ButtonColor, ButtonSize, ButtonVariant } from '@/hooks/useButtonClasses';

import Button from '@/components/button/Button.vue';
import UploadState from './UploadState.vue';

import UploadCloud from '@/icons/line/upload-cloud.svg';

const ERROR_UNSUPPORTED_FILE_TYPE = 'Unsupported file type';

const props = defineProps<{
  accept?: string[];
  error?: string;
  processing?: boolean;
  successful?: boolean;
  disabled?: boolean;
}>();

const [isDraggingOver, setIsDraggingOver] = useToggle(false);
const model = defineModel<File | undefined>();
const emit = defineEmits<{ error: [string] }>();

const fileDialog = useFileDialog();

const state = computed(() => {
  if (props.error) {
    return 'error';
  }
  if (props.processing) {
    return 'loading';
  }
  if (props.successful) {
    return 'success';
  }
  return 'success';
});

function openFileDialog() {
  if (props.disabled) {
    return;
  }
  fileDialog.open({ accept: props.accept?.join(',') });
  fileDialog.onChange((files) => {
    model.value = files ? files[0] : undefined;
  });
}

function dropHandler(ev: DragEvent) {
  setIsDraggingOver(false);
  if (props.disabled) {
    return;
  }

  const items = Array.from(ev.dataTransfer?.items ?? [])
    .filter((item) => item.kind === 'file')
    .map((item) => item.getAsFile());

  const files = (items.length ? items : Array.from(ev.dataTransfer?.files ?? [])).filter(
    (f): f is File => !!f
  );

  model.value = files[0] ?? undefined;

  if (files[0]) {
    const { name } = files[0];

    if (!props.accept?.includes(name.slice(name.lastIndexOf('.')))) {
      emit('error', ERROR_UNSUPPORTED_FILE_TYPE);
    }
  }
}

function dragLeaveHandler(ev: DragEvent) {
  if (
    ev.currentTarget instanceof HTMLElement &&
    ev.relatedTarget instanceof HTMLElement &&
    ev.currentTarget.contains(ev.relatedTarget)
  ) {
    return;
  }
  setIsDraggingOver(false);
}

watch(model, (v) => {
  if (!v) {
    fileDialog.reset();
  }
});
</script>

<template>
  <div
    v-if="!modelValue"
    :class="[
      'flex max-h-64 flex-col gap-4 rounded-lg border border-dashed p-8 text-center',
      {
        'border-zinc-300 bg-white': !disabled && !isDraggingOver,
        'focus-within:border-slate-400 focus-within:shadow-slate-100 focus-within:drop-shadow hover:border-slate-400 hover:bg-slate-50':
          !disabled,
        'border-slate-200 bg-slate-50': disabled,
        'border-slate-400 bg-slate-50': isDraggingOver
      }
    ]"
    @drop.prevent="dropHandler"
    @dragover.prevent
    @dragenter="setIsDraggingOver(true)"
    @dragleave="dragLeaveHandler"
    @dragend="setIsDraggingOver(false)"
  >
    <div class="flex flex-col items-center gap-4">
      <UploadCloud class="h-10" />

      <div>
        <p class="text-base font-medium leading-4 text-zinc-800">Drag and drop a file to upload</p>
        <p class="text-sm font-normal leading-4 text-zinc-500">.csv, .xlsx up to 10 MB</p>
      </div>
    </div>

    <p class="text-[11px] font-bold uppercase leading-4 text-zinc-800">OR</p>

    <div>
      <Button
        :color="ButtonColor.slate"
        :disabled="disabled"
        :size="ButtonSize.md"
        :variant="ButtonVariant.outlined"
        type="button"
        @click="openFileDialog"
        >Browse content</Button
      >
    </div>
  </div>

  <template v-else>
    <UploadState
      :modelValue="modelValue"
      @update:modelValue="model = $event"
      :state="state"
      :accept="accept"
      :error="error"
    />
  </template>
</template>
