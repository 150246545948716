<script setup lang="ts">
type EnumData = App.Base.Data.EnumData;

import type { Selectable } from '@/components/selectBox/selectBox';
import { formatDate } from '@/utils/date';

import CenteredContent from '@/components/content/CenteredContent.vue';
import StudentInteractionForm from '@/components/students/studentActions/StudentInteractionForm.vue';
import StudentInteractionFormWrapper from '@/components/students/studentActions/StudentInteractionFormWrapper.vue';

const props = defineProps<{
  returnUrl?: string;
  student: App.Students.Data.StudentData;
  mediums: EnumData[];
  meetingLengths: EnumData[];
  testTypes: EnumData[];
  topics: EnumData[];
  types: EnumData[];
}>();

const form = useForm({
  method: 'POST',
  url: route('students.interactions.store', { student: props.student.id }),
  fields: {
    notes: '',
    topics: [] as Selectable<string>[] | null,
    application_assisted_institute_ids: null as Selectable<number>[] | null,
    award_letter_review_institute_ids: null as Selectable<number>[] | null,
    scholarship_names: null as Selectable<string>[] | null,
    tests: [] as Array<{
      type: Selectable<string>;
      date: string;
      test?: Selectable<string>;
    }>,
    types: [] as Selectable<string>[] | null,
    institute_id: null as Selectable<number> | null,
    medium: { value: 'in-person', label: 'In Person' } as Selectable<string>,
    meeting_length: null as Selectable<string> | null,
    date: formatDate(new Date(), { format: 'yyyy-MM-dd' })
  },
  transform: (fields) => {
    return {
      ...fields,
      topics: fields.topics?.map((type) => type.value) ?? [],
      application_assisted_institute_ids:
        fields.application_assisted_institute_ids?.map((institute) => institute.value) ?? null,
      award_letter_review_institute_ids:
        fields.award_letter_review_institute_ids?.map((institute) => institute.value) ?? null,
      scholarship_names: fields.scholarship_names?.map((scholarship) => scholarship.label) ?? null,
      tests:
        fields.tests && fields.tests.length > 0
          ? fields.tests.map((testItem) => ({
              type:
                testItem.type?.value === 'custom-test' && testItem.test?.value
                  ? testItem.test?.value
                  : testItem.type?.value,
              date: testItem.date,
              test: testItem.test?.value
            }))
          : null,
      types: fields.types?.map((type) => type.value) ?? null,
      institute_id: fields.institute_id?.value,
      medium: fields.medium?.value,
      meeting_length: fields.meeting_length?.value
    };
  }
});
</script>


					<script lang="ts">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <CenteredContent>
    <StudentInteractionFormWrapper :form="form" :student="student">
      <StudentInteractionForm
        :form="form"
        :mediums="mediums"
        :meetingLengths="meetingLengths"
        :student="student"
        :testTypes="testTypes"
        :topics="topics"
        :types="types"
      />
    </StudentInteractionFormWrapper>
  </CenteredContent>
</template>
