<script setup lang="ts">
import UserData = App.Auth.Data.UserData;

const isImpersonating = useProperty<boolean>('isImpersonating');
const user = useProperty<UserData>('security.user');

const deleteImpersonating = useForm({
  method: 'POST',
  fields: { _method: 'DELETE' },
  url: route('impersonation.delete')
});
</script>

<template>
  <div
    v-if="isImpersonating"
    class="flex items-center gap-x-6 bg-yellow-600 px-6 py-2 sm:px-3.5 sm:before:flex-1"
  >
    <p class="leading-6 text-white">
      You are currently impersonating user <span class="font-bold">{{ user.name }}</span>
    </p>
    <div class="flex flex-1 justify-end">
      <form @submit.prevent="deleteImpersonating.submit">
        <button
          type="submit"
          class="-m-3 p-3 focus-visible:outline-offset-[-4px]"
          :disabled="deleteImpersonating.processing"
        >
          <svg
            class="h-5 w-5 text-white"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z"
            />
          </svg>
        </button>
      </form>
    </div>
  </div>
</template>
