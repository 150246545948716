<script setup lang="ts">
type TwoFactorChannelData = App.Auth.Data.TwoFactorChannelData;

import { computed } from 'vue';
import Account2faChannel from '@/components/account/Account2faChannel.vue';

const props = defineProps<{
  channels?: TwoFactorChannelData[];
  tooltip?: string;
}>();

const allowDeleteVerifiedChannels = computed(() => {
  return (props.channels ?? []).filter((channel) => channel.isVerified).length > 1;
});

function handle2faChannelDelete(channel: number) {
  useForm({
    method: 'DELETE',
    spoof: false,
    url: route('channel.delete', { channel }),
    updateInitials: true,
    fields: {},
    only: ['channels']
  }).submit();
}
</script>

<template>
  <ul class="flex flex-col divide-y divide-slate-200">
    <Account2faChannel
      v-for="channel in channels"
      :isVerified="channel.isVerified"
      :destination="channel.redactedDestination"
      :channelType="channel.channelTypeDisplayName"
      :allowDelete="!channel.isVerified || allowDeleteVerifiedChannels"
      :key="channel.id"
      :tooltip="tooltip"
      @onDelete="handle2faChannelDelete(channel.id)"
    />
  </ul>
</template>
