<script setup lang="ts">
type ProgramData = App.Configuration.Data.ProgramData;

import { buildSelectableRegion, buildSelectableUser } from '@/utils/buildSelectable';

import ProgramForm from '@/components/programs/ProgramForm.vue';
import CenteredContent from '@/components/content/CenteredContent.vue';

const props = defineProps<{
  program: ProgramData;
  returnUrl?: string;
}>();

const form = useForm({
  method: 'PUT',
  url: route('programs.update', { program: props.program.id }),
  fields: {
    display_name: props.program.display_name,
    description: props.program.description,
    is_kpi_national_excluded: props.program.is_kpi_national_excluded,
    grade_up_date: props.program.grade_up_date,
    region_id: buildSelectableRegion(props.program?.region),
    user_ids: (props.program.users || []).map((user) => buildSelectableUser(user))
  },
  transform: (fields) => ({
    ...fields,
    region_id: fields.region_id?.value,
    user_ids: fields.user_ids.map((user) => user?.value)
  })
});
</script>


					<script lang="ts">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <CenteredContent>
    <ProgramForm isEditing :form="form" :returnUrl="returnUrl" />
  </CenteredContent>
</template>
