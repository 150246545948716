<script setup lang="ts">
import { ButtonColor, ButtonSize, ButtonVariant } from '@/hooks/useButtonClasses';
import { useAuth } from '@/hooks/useAuth';

import Button from '@/components/button/Button.vue';
import Dialog from '@/components/dialog/Dialog.vue';
import FormField from '@/components/formField/FormField.vue';
import FormRows from '@/components/formRows/FormRows.vue';
import FormTextareaInput from '@/components/formTextareaInput/FormTextareaInput.vue';
import IconButton from '@/components/button/IconButton.vue';
import Indicator from '@/components/button/Indicator.vue';

import LifeRingIcon from '@/icons/line/life-ring.svg';
import Trash from '@/icons/line/trash.svg';
import UploadCloud from '@/icons/line/upload-cloud.svg';

defineProps<{
  isSmallScreen?: boolean;
}>();

const { user } = useAuth();
const messageStorage = useLocalStorage(`bug-report-message-${user.value?.id}`, '');

const isOpen = ref(false);
const isDraggingOver = ref(false);
const selectedFiles = ref<File[]>([]);
const fileInput = ref<HTMLInputElement | null>(null);

const form = useForm<{
  user_agent: string;
  url: string;
  message: string;
  files: File[];
}>({
  method: 'post',
  url: route('bug-report.store'),
  fields: {
    user_agent: navigator.userAgent,
    url: window.location.href,
    message: messageStorage.value,
    files: []
  },
  hooks: {
    success() {
      form.fields.message = '';
      selectedFiles.value = [];
      isOpen.value = false;
    }
  }
});

const fileErrors = computed(() => {
  const errors = form.errors.files as unknown as string[];
  return Object.values(errors ?? [])
    .map((message) => message)
    .join(', ');
});

function onDragEnter(event: DragEvent) {
  event.preventDefault();
  isDraggingOver.value = true;
}

function onDragLeave(event: DragEvent) {
  event.preventDefault();
  const dropZone = event.currentTarget as HTMLElement;
  const relatedTarget = event.relatedTarget as HTMLElement;

  if (!dropZone.contains(relatedTarget)) {
    isDraggingOver.value = false;
  }
}

function onFilesDropped(event: DragEvent) {
  event.preventDefault();
  isDraggingOver.value = false;
  const files = Array.from(event.dataTransfer?.files || []);
  selectedFiles.value.push(...files);
  updateFormFiles();
}

function onFileInputChange(event: Event) {
  const files = Array.from((event.target as HTMLInputElement).files || []);
  selectedFiles.value.push(...files);
  updateFormFiles();
}

function removeFile(index: number) {
  selectedFiles.value.splice(index, 1);
  updateFormFiles();
}

function updateFormFiles() {
  form.fields.files = selectedFiles.value;
}

watch(
  () => form.fields.message,
  (newValue) => {
    messageStorage.value = newValue;
  }
);
</script>

<template>
  <Indicator v-if="!isSmallScreen" label="" :show="!!form.fields.message">
    <IconButton
      ariaLabel="Submit bug report"
      :size="ButtonSize.md"
      :variant="ButtonVariant.soft"
      :icon="LifeRingIcon"
      :color="ButtonColor.slate"
      @click="isOpen = true"
    />
  </Indicator>
  <Button
    v-else
    :color="ButtonColor.slate"
    :iconLeft="LifeRingIcon"
    :variant="ButtonVariant.invisible"
    @click="isOpen = true"
    >Submit bug report</Button
  >
  <Dialog
    :isOpen="isOpen"
    title="Submit bug report"
    :isLoading="form.processing"
    contentClass="max-w-2xl"
    confirmButtonLabel="Submit bug report"
    cancelButtonLabel="Close"
    footerHelperText="You can close this dialog without losing your changes."
    @onCancel="isOpen = false"
    @onClose="isOpen = false"
    @onConfirm="form.submit"
  >
    <FormRows>
      <FormTextareaInput
        label="Bug report"
        name="message"
        v-model="form.fields.message"
        :error="form.errors.message"
      />
      <FormField label="Attachments" :error="fileErrors">
        <div
          class="rounded-lg border-2 border-dashed p-6 transition-colors"
          :class="[isDraggingOver ? 'border-primary-500' : 'border-slate-300']"
          @drop="onFilesDropped"
          @dragover.prevent
          @dragenter="onDragEnter"
          @dragleave="onDragLeave"
        >
          <div class="flex flex-col items-center gap-2 text-center">
            <UploadCloud class="h-6 w-6 text-slate-500" />
            <p class="text-sm leading-5 text-slate-700">Drag and drop files here or</p>
            <input
              type="file"
              multiple
              class="hidden"
              ref="fileInput"
              @change="onFileInputChange"
            />
            <Button
              @click="fileInput?.click()"
              :color="ButtonColor.primary"
              :size="ButtonSize.xs"
              :variant="ButtonVariant.invisible"
              >Browse for files</Button
            >
          </div>
          <div v-if="selectedFiles.length" class="mt-4 space-y-2">
            <div
              v-for="(file, index) in selectedFiles"
              :key="index"
              class="flex items-center justify-between rounded bg-gray-50 p-2"
            >
              <span class="truncate text-sm font-medium text-slate-900">{{ file.name }}</span>
              <IconButton
                color="danger"
                :icon="Trash"
                ariaLabel="Remove file from upload"
                size="sm"
                variant="invisible"
                @click="removeFile(index)"
              />
            </div>
          </div>
        </div>
      </FormField>
    </FormRows>
  </Dialog>
</template>
