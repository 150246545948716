<script setup lang="ts">
import PaddedCellContent from '@/components/table/PaddedCellContent.vue';
import IconButton from '@/components/button/IconButton.vue';
import { TableRow } from '@/components/table/Table';

import EditIcon from '@/icons/line/edit.svg';
import { ButtonSize, ButtonColor, ButtonVariant } from '@/hooks/useButtonClasses';

const props = defineProps<{
  row: TableRow;
}>();

const [isEditing, toggleEditing] = useToggle(false);

function handleClickRow() {
  toggleEditing();

  router.get(route('upload-records.edit', { uploadRecord: props.row.id }), {
    only: ['uploadRecord'],
    hooks: {
      success() {
        // Wait a second for the slide out animation to run
        setTimeout(() => {
          toggleEditing();
        }, 1000);
      }
    }
  });
}
</script>

<template>
  <PaddedCellContent>
    <IconButton
      :icon="EditIcon"
      :color="ButtonColor.slate"
      :variant="ButtonVariant.soft"
      :size="ButtonSize.sm"
      :isLoading="isEditing"
      ariaLabel="Edit record"
      @click="handleClickRow"
    />
  </PaddedCellContent>
</template>
