<script setup lang="ts">
import pluralize from 'pluralize';
import { SegmentedMessage } from 'sms-segments-calculator';

const props = defineProps<{
  message: string | null;
}>();

const segmentedMessage = computed<SegmentedMessage>(
  () => new SegmentedMessage(props.message ?? '')
);
</script>

<template>
  <p>{{ message?.length ?? 0 }} / 1000</p>
  <p class="pl-2">
    {{ segmentedMessage.segmentsCount }}
    {{ pluralize('Message', segmentedMessage.segmentsCount) }}
  </p>
</template>
