<script setup lang="tsx">
import type { Selectable } from '@/components/selectBox/selectBox';

import CenteredContent from '@/components/content/CenteredContent.vue';
import DataImportForm from '@/domains/bulkinator/components/dataImports/DataImportForm.vue';

defineProps<{
  dataImportTypes: App.Bulkinator.Data.DataImportTypeData[];
}>();

const form = useForm({
  method: 'POST',
  url: route('data-imports.store'),
  fields: {
    data_import_type: {} as Selectable<string>,
    file: null,
    is_dry_run: false,
    persist_if_successful: false
  },
  transform: (fields) => ({
    ...fields,
    data_import_type: fields.data_import_type?.value ?? null
  })
});
</script>


					<script lang="tsx">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <CenteredContent>
    <DataImportForm :dataImportTypes="dataImportTypes" :form="form" />
  </CenteredContent>
</template>
