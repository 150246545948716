import { useId } from 'radix-vue';
import { FormFieldProps } from '@/components/formField/FormField';

export function useFormField<P extends FormFieldProps>({ props }: { props: P }) {
  const formFieldProps = computed(() => ({
    hasError: !!props.error || props.hasError,
    label: props.label,
    error: props.error,
    helperText: props.helperText,
    isDisabled: props.isDisabled,
    isOptional: props.isOptional
  }));

  const componentSlots = useSlots();
  const slots = computed(() =>
    ['label', 'helperText', 'error', 'info'].filter((s) => !!componentSlots[s])
  );

  return {
    id: useId(),
    formFieldProps,
    slots: slots.value
  };
}
