<script setup lang="ts">
import TextInput from '@/components/textInput/TextInput.vue';
import LockIcon from '@/icons/line/lock.svg';

const props = defineProps<{
  modelValue: string | number | null;
  name: string;
  isLoading: boolean;
  hasError: boolean;
  column: App.Bulkinator.Data.UploadColumnData;
  id?: string;
  isSuccessful?: boolean;
  isLocked?: boolean;
  isInline?: boolean;
}>();

defineEmits<{
  'update:modelValue': [value: unknown];
  saveValue: [];
}>();

const inputType = computed(() => {
  return (
    {
      integer: 'number',
      email: 'email'
    }[props.column.type.value] ?? 'text'
  );
});
</script>

<template>
  <TextInput
    :modelValue="modelValue"
    :id="id"
    :name="name"
    :isDisabled="isLocked"
    :iconRight="isLocked ? LockIcon : undefined"
    :isSuccessful="isSuccessful"
    :isLoading="isLoading"
    :hasError="hasError"
    :type="inputType"
    @update:modelValue="$emit('update:modelValue', $event)"
    @blur="$emit('saveValue')"
    :inputClasses="
      isInline
        ? [
            '!h-full !border-none !rounded-sm !bg-transparent',
            {
              '!bg-red-200 !text-red-800': hasError
            }
          ]
        : null
    "
  />
</template>
