<script setup lang="ts">
import TextInput from '@/domains/bulkinator/components/table/input/TextInput.vue';
import BooleanInput from '@/domains/bulkinator/components/table/input/BooleanInput.vue';
import DateInput from '@/domains/bulkinator/components/table/input/DateInput.vue';
import ListboxInput from './ListboxInput.vue';
import SearchInput from './SearchInput.vue';

const props = defineProps<{
  value: unknown;
  column: App.Bulkinator.Data.UploadColumnData;
  row: App.Bulkinator.Data.UploadRecordData;
  id?: string;
  error?: string;
  isLocked?: boolean;
  isInline?: boolean;
}>();

const emit = defineEmits<{
  success: [];
}>();

const displaySuccess = ref(false);

watch(
  () => props.value,
  () => {
    form.fields[props.column.name] = props.value;
  }
);

watch(
  () => props.column,
  () => {
    form.fields[props.column.name] = props.value;
  }
);

function handleSuccess() {
  displaySuccess.value = true;
  setTimeout(() => {
    displaySuccess.value = false;
  }, 2000);
}

const form = useForm({
  url: route('upload-records.deltas.update', { uploadRecord: props.row.id }),
  method: 'PATCH',
  fields: {
    [props.column.name]: props.value
  },
  hooks: {
    success() {
      handleSuccess();
      emit('success');
    }
  },
  only: ['uploadRecords'],
  preserveState: true,
  preserveScroll: true,
  updateInitials: true
});

const inputComponent = computed(() => {
  if (props.column.options?.length) {
    return ListboxInput;
  }

  return (
    {
      string: TextInput,
      boolean: BooleanInput,
      date: DateInput,
      search: SearchInput
    }[props.column.type.value] ?? TextInput
  );
});

function handleUpdate(val: string | number | boolean | null) {
  form.fields[props.column.name] = val;
}

function handleSaveValue() {
  if (form.hasDirty(props.column.name)) {
    form.submit();
  }
}
</script>

<template>
  <component
    :class="{
      'h-full': isInline
    }"
    :is="inputComponent"
    :modelValue="form.fields[column.name]"
    :id="id"
    :name="`${row.id}-${column.name}`"
    :hasError="!!error"
    :column="column"
    :isSuccessful="displaySuccess"
    :isLoading="form.processing"
    :isLocked="isLocked"
    :isDisabled="isLocked"
    :isInline="isInline"
    @update:modelValue="handleUpdate"
    @saveValue="handleSaveValue"
  />
</template>
