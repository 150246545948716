import readXlsxFile from 'read-excel-file';
import { parse } from 'csv-parse/browser/esm';
import { Selectable } from '@/components/selectBox/selectBox';

/**
 * A column can only be matched at most one time.
 */
const generateMapping = (
  headers: string[],
  columns: Array<{ label: string; value: string }>
): Record<string, Selectable<string>> => {
  const selectedIndices = new Set<number>();

  return headers.reduce((acc, header) => {
    const matchIndex = columns.findIndex(
      (col, i) =>
        col.label.trim().toLocaleLowerCase() === header.trim().toLocaleLowerCase() &&
        !selectedIndices.has(i)
    );

    const match = matchIndex >= 0 ? columns[matchIndex] : null;

    if (match) {
      selectedIndices.add(matchIndex);
      acc[header] = { label: match.label, value: match.value };
    }

    return acc;
  }, {});
};

const extractHeaders = async (file?: File): Promise<string[]> => {
  const extension = getFileExtension(file);
  if (!file || !extension) {
    return [];
  }

  let result: string[] = [];
  if (extension === '.csv') {
    const data = await file.text();
    const csv: string[][] = await new Promise((resolve) => {
      parse(data, { delimiter: ',', to_line: 2, skip_empty_lines: true }, function (e, records) {
        if (e) {
          throw e instanceof Error ? e : new Error('Error parsing csv');
        } else {
          resolve(records);
        }
      });
    });
    result = csv?.[0] ?? [];
  } else if (extension === '.xlsx') {
    const rows = await readXlsxFile(file);
    result = rows?.[0] ?? [];
  }

  if (result.length === 0) {
    throw new Error('No headings found in file');
  }

  return result;
};

const excludeHeaders = (
  headers: string[],
  hideForEditColumns: Array<{ label: string; value: string }>
) => {
  if (!hideForEditColumns.length || !headers.length) {
    return headers;
  }

  const lowercaseColumns = hideForEditColumns.map((col) => col.label.toLowerCase());
  return headers.filter((h) => !lowercaseColumns.includes(h.toLowerCase()));
};

type Props = {
  onError: (e: Error) => void;
  columns: Array<{ label: string; value: string }>;
  hideForEditColumns: Array<{ label: string; value: string }>;
  onChange: (value: { headers: string[]; mappings: Record<string, Selectable<string>> }) => void;
};

export const useSpreadsheetColumnHeaders = ({
  onChange,
  onError,
  columns,
  hideForEditColumns
}: Props) => {
  const state = useAsyncState(
    async (file?: File) => {
      const rawHeaders = await extractHeaders(file);
      const headersExcludingHiddenColumns = excludeHeaders(rawHeaders, hideForEditColumns);
      const mappings = generateMapping(headersExcludingHiddenColumns, columns);

      return { headers: headersExcludingHiddenColumns, mappings };
    },
    { headers: [], mappings: {} }
  );

  watch(state.state, (value) => {
    onChange(value);
  });

  watch(state.error, (e) => {
    const error =
      e instanceof Error
        ? e
        : typeof e === 'string'
          ? new Error(e)
          : new Error('Something went wrong');
    onError(error);
  });

  return state;
};
