<script setup lang="ts">
import { ButtonColor, ButtonSize, ButtonVariant } from '@/hooks/useButtonClasses';

import AuthFormWrapper from '@/components/auth/AuthFormWrapper.vue';
import Button from '@/components/button/Button.vue';

import IconCheck from '@/icons/line/3d-icon-check.svg';

defineProps<{
  email: string;
}>();
</script>


					<script lang="ts">
					import __hybridly_layout_0 from '~/resources/domains/auth/layouts/two-columns-guest.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <AuthFormWrapper
    textAlignment="center"
    title="Link sent! Check your inbox!"
    :description="`We’ll send a forgotten password link to ${email} if it exists in our system.`"
  >
    <template v-slot:header>
      <div class="flex items-center justify-center">
        <IconCheck class="h-20 w-20" />
      </div>
    </template>
    <Button
      :color="ButtonColor.primary"
      :href="route('login.show')"
      :size="ButtonSize.lg"
      :variant="ButtonVariant.solid"
      class="w-full justify-center"
      >Back to login</Button
    >
  </AuthFormWrapper>
</template>
