<script setup lang="ts">
type AlertData = App.Configuration.Data.AlertData;

import { useAlertColor } from '@/hooks/useAlertColor';

import Alert from '@/components/alert/Alert.vue';

import InfoCircle from '@/icons/line/info-circle.svg';

defineProps<{
  alerts: AlertData[];
}>();

const { getAlertColor } = useAlertColor();
</script>

<template>
  <TransitionGroup
    v-if="alerts?.length"
    ariaLive="assertive"
    moveClass="transition-toast duration-500"
    enterActiveClass="transition-toast duration-500"
    leaveActiveClass="transition-toast duration-500 absolute inset-x-0 top-0"
    enterFromClass="opacity-0"
    leaveToClass="opacity-0"
    tag="ul"
    class="relative flex flex-col gap-2 p-4"
  >
    <Alert
      v-for="alert in alerts"
      :color="getAlertColor(alert.type.value)"
      :title="alert.title"
      :html="alert.content_html"
      :icon="InfoCircle"
      :key="alert.id"
      alignment="vertical"
      hideClose
    />
  </TransitionGroup>
</template>
