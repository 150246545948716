<script setup lang="ts">
import { AccordionRoot } from 'radix-vue';
import EditNodePanel from '@/components/automated-campaigns/nodePanels/EditNodePanel.vue';
import AutomatedActionData = App.Sms.Data.AutomatedCampaigns.AutomatedActionData;
import { useAutomatedActionTypes } from '@/hooks/useAutomatedActionTypes';
import FormListboxInput from '@/components/formListboxInput/FormListboxInput.vue';
import NodePanelAccordionHeader from '@/components/slideout/slideoutAccordion/SlideoutAccordionHeader.vue';
import NodePanelAccordionContent from '@/components/slideout/slideoutAccordion/SlideoutAccordionContent.vue';
import NodePanelAccordionItem from '@/components/slideout/slideoutAccordion/SlideoutAccordionItem.vue';
import DeleteActionDialog from '@/components/automated-campaigns/dialogs/DeleteActionDialog.vue';

const props = defineProps<{
  automatedAction: AutomatedActionData;
  isPaused: boolean;
}>();

const form = useForm<Pick<AutomatedActionData, 'action_type' | 'key' | 'value'>>({
  method: 'PUT',
  url: route('sms.actions.update', { action: props.automatedAction.id }),
  updateInitials: true,
  fields: {
    action_type: props.automatedAction.action_type,
    key: props.automatedAction.key,
    value: props.automatedAction.value
  },
  transform(data) {
    return {
      ...data,
      action_type: data.action_type.value
    };
  }
});

watch(
  () => form.fields.action_type,
  (newActionType, previousActionType) => {
    if (newActionType.value !== previousActionType.value) {
      form.fields.key = null;
      form.fields.value = null;
      form.clearErrors('key', 'value');
    }
  }
);

const requiresKey = computed<boolean>(() => {
  return form.fields.action_type.requires_key;
});

const requiresValue = computed<boolean>(() => {
  return form.fields.action_type.requires_value;
});

const {
  automatedActionTypes,
  automatedActionKeyOptions,
  selectedAutomatedActionKey,
  automatedActionValues,
  selectedAutomatedActionValue
} = useAutomatedActionTypes(toRef(() => form.fields));
</script>

<template>
  <DeleteActionDialog :action="automatedAction">
    <template #default="{ openDialog }">
      <EditNodePanel
        title="Edit Action"
        :onSubmit="form.submit"
        :onDelete="() => openDialog()"
        :reset="form.reset"
        :isDirty="form.isDirty"
        :processing="form.processing"
        :isPaused
      >
        <AccordionRoot
          type="multiple"
          :defaultValue="['settings', 'type']"
          class="flex flex-grow flex-col"
        >
          <NodePanelAccordionItem value="type">
            <NodePanelAccordionHeader title="Action Type" />
            <NodePanelAccordionContent>
              <FormListboxInput
                v-model="form.fields.action_type"
                :error="form.errors.action_type as string"
                :options="automatedActionTypes"
                label="Type"
                :isDisabled="!isPaused"
              />
            </NodePanelAccordionContent>
          </NodePanelAccordionItem>
          <NodePanelAccordionItem value="settings" v-if="requiresKey || requiresValue">
            <NodePanelAccordionHeader title="Action Settings" />
            <NodePanelAccordionContent>
              <FormListboxInput
                v-if="requiresKey"
                :error="form.errors.key"
                :modelValue="selectedAutomatedActionKey"
                @update:modelValue="(selected) => (form.fields.key = selected?.value ?? null)"
                label="Field"
                :options="automatedActionKeyOptions"
                :isDisabled="!isPaused"
              />

              <FormListboxInput
                v-if="requiresValue"
                :error="form.errors.value"
                :modelValue="selectedAutomatedActionValue"
                @update:modelValue="(selected) => (form.fields.value = selected?.value ?? null)"
                :options="automatedActionValues"
                :isDisabled="!isPaused"
                label="Value"
              />
            </NodePanelAccordionContent>
          </NodePanelAccordionItem>
        </AccordionRoot>
      </EditNodePanel>
    </template>
  </DeleteActionDialog>
</template>
