<script setup lang="ts">
type Block = App.Surveys.Data.SurveyBlockData;
type Survey = App.Surveys.Data.SurveyData;

import type { HybridlyFormData } from '@/@types/global';
import type { Selectable } from '@/components/selectBox/selectBox';

import debounce from 'lodash/debounce';

import { ButtonColor, ButtonVariant } from '@/hooks/useButtonClasses';

import { buildSelectableSurveyBlock, buildSelectableSurveyChoice } from '@/utils/buildSelectable';

import Alert from '@/components/alert/Alert.vue';
import Button from '@/components/button/Button.vue';
import ComboboxInput from '@/components/selectBox/combobox/ComboboxInput.vue';
import Fieldset from '@/components/fieldset/Fieldset.vue';
import FormError from '@/components/formError/FormError.vue';
import InstituteComboBoxInput from '@/components/institutes/InstituteComboBoxInput.vue';
import InstituteMultiComboBoxInput from '@/components/institutes/InstituteMultiComboBoxInput.vue';
import ListboxInput from '@/components/selectBox/listbox/ListboxInput.vue';
import MultiComboboxInput from '@/components/selectBox/combobox/MultiComboboxInput.vue';
import SurveyBlockPanelBody from '@/components/surveys/surveys/SurveyBlockPanelBody.vue';
import SurveyBlockPanelHeader from '@/components/surveys/surveys/SurveyBlockPanelHeader.vue';
import TextInput from '@/components/textInput/TextInput.vue';

import InfoCircle from '@/icons/line/info-circle.svg';
import Trash from '@/icons/line/trash.svg';

const props = defineProps<{
  block: Block;
  availableBlockOptions: Block[];
  blockSkipLogicOptions: Selectable<string>[];
  form: HybridlyFormData<{
    block_text: string;
    skip_block_id: number;
    skip_logic_operator: Selectable<string>;
    skip_choices: Selectable<number> | null;
  }>;
  referencedBlocks: Block[];
  survey: Survey;
}>();

const multiComboboxInputValue = computed({
  get: () => props.form.fields.skip_choices,
  set: (value) => {
    handleFormUpdate('skip_choices', value);
  }
});

const hasSkipLogic = computed(
  () =>
    props.form.fields.skip_block_id &&
    props.form.fields.skip_logic_operator &&
    props.form.fields.skip_choices
);

const computedAvailableBlockOptions = computed<Selectable<number>[]>(() => {
  return props.availableBlockOptions.map((block) =>
    buildSelectableSurveyBlock(block)
  ) as Selectable<number>[];
});

const commputedSelectedBlock = computed(() =>
  props.availableBlockOptions.find((block) => block.id === props.form.fields.skip_block_id?.value)
);

const computedAvailableBlockChoiceOptions = computed<Selectable<number>[]>(() => {
  return commputedSelectedBlock.value
    ? (commputedSelectedBlock.value.choices?.map((choice) =>
        buildSelectableSurveyChoice(choice)
      ) as Selectable<number>[])
    : [];
});

const debouncedSubmit = debounce(() => {
  props.form.submit();
}, 600);

function handleFormUpdate(
  key: string,
  value:
    | string
    | number
    | boolean
    | Selectable<string>
    | Selectable<number>
    | Selectable<string>[]
    | Selectable<number>[]
    | null
) {
  props.form.fields[key] = value;
  debouncedSubmit();
}

function handleSkipBlockChange(value) {
  props.form.fields.skip_block_id = value;

  if (props.form.initial.skip_block_id?.value === value?.value) {
    props.form.fields.skip_choices = props.form.initial.skip_choices;
  } else {
    props.form.fields.skip_choices = [];
  }
}

function handleDeleteSkipLogic() {
  props.form.clear('skip_block_id');
  props.form.fields.skip_logic_operator = null;
  props.form.fields.skip_choices = [];
  props.form.submit();
}
</script>

<template>
  <SurveyBlockPanelBody>
    <template #header>
      <SurveyBlockPanelHeader
        :block="block"
        :href="
          route('surveys.blocks.show', {
            survey: survey.id,
            block: block.id
          })
        "
        :referencedBlocks="referencedBlocks"
        :survey="survey"
        title="Question Settings"
      >
        <div class="flex items-center gap-x-2 px-4 py-6">
          <label class="text-sm font-bold text-slate-800">{{ block.order + 1 }}.</label>
          <TextInput
            :error="form.errors.block_text"
            :modelValue="form.fields.block_text"
            class="grow"
            placeholder="What is this question?"
            @update:modelValue="handleFormUpdate('block_text', $event)"
          />
        </div>
      </SurveyBlockPanelHeader>
    </template>
    <template v-if="block.block_text">
      <div class="flex flex-col gap-y-5">
        <div class="flex flex-col gap-y-2">
          <Fieldset title="Skip logic">
            <template v-if="computedAvailableBlockOptions.length">
              <p class="text-sm font-medium leading-5 text-slate-700">
                Configure the skip logic for this block which determines if/when this block should
                be skipped based on previous answer.
              </p>
              <div class="flex flex-col gap-y-2 rounded-lg bg-slate-50 p-2">
                <label class="py-2 text-sm font-medium leading-4 text-slate-700"
                  >Skip this question if...</label
                >
                <ListboxInput
                  :modelValue="form.fields.skip_block_id"
                  :options="computedAvailableBlockOptions"
                  placeholder="Select previous question"
                  @update:modelValue="handleSkipBlockChange"
                />
                <ListboxInput
                  v-if="form.fields.skip_block_id"
                  :modelValue="form.fields.skip_logic_operator"
                  :options="blockSkipLogicOptions"
                  placeholder="Select skip logic condition"
                  @update:modelValue="(value) => (form.fields.skip_logic_operator = value)"
                />
                <ComboboxInput
                  v-if="
                    form.fields.skip_block_id &&
                    form.fields.skip_logic_operator &&
                    commputedSelectedBlock?.block_type.value !== 'multiselect' &&
                    commputedSelectedBlock?.dynamic_choice_type?.value !== 'institute'
                  "
                  :modelValue="form.fields.skip_choices[0]"
                  :options="computedAvailableBlockChoiceOptions"
                  placeholder="Select option(s)"
                  @update:modelValue="
                    handleFormUpdate('skip_choices', [$event as Selectable<number>])
                  "
                />
                <MultiComboboxInput
                  v-if="
                    form.fields.skip_block_id &&
                    form.fields.skip_logic_operator &&
                    commputedSelectedBlock?.block_type.value === 'multiselect' &&
                    commputedSelectedBlock?.dynamic_choice_type?.value !== 'institute'
                  "
                  :modelValue="multiComboboxInputValue"
                  :options="computedAvailableBlockChoiceOptions"
                  placeholder="Select option(s)"
                  @update:modelValue="handleFormUpdate('skip_choices', $event)"
                />
                <InstituteComboBoxInput
                  v-if="
                    form.fields.skip_block_id &&
                    form.fields.skip_logic_operator &&
                    commputedSelectedBlock?.block_type.value !== 'multiselect' &&
                    commputedSelectedBlock?.dynamic_choice_type?.value === 'institute'
                  "
                  :modelValue="form.fields.skip_choices[0]"
                  placeholder="Select option(s)"
                  @update:modelValue="handleFormUpdate('skip_choices', [$event])"
                />
                <InstituteMultiComboBoxInput
                  v-if="
                    form.fields.skip_block_id &&
                    form.fields.skip_logic_operator &&
                    commputedSelectedBlock?.block_type.value === 'multiselect' &&
                    commputedSelectedBlock?.dynamic_choice_type?.value === 'institute'
                  "
                  :modelValue="form.fields.skip_choices"
                  placeholder="Select option(s)"
                  @update:modelValue="handleFormUpdate('skip_choices', $event)"
                />
              </div>
            </template>
            <p v-else class="text-sm font-medium leading-5 text-slate-700">
              No skip logic options are available for this question because no previous questions
              are of Boolean, Range, Select, or Multiselect type.
            </p>
          </Fieldset>
          <FormError v-if="form.errors.skip_logic_operator">{{
            form.errors.skip_logic_operator
          }}</FormError>
          <FormError v-if="form.errors.choices">{{ form.errors.choices }}</FormError>
        </div>
        <div v-if="hasSkipLogic" class="flex justify-end">
          <Button
            :color="ButtonColor.danger"
            :iconLeft="Trash"
            :variant="ButtonVariant.outlined"
            @click="handleDeleteSkipLogic"
            >Remove skip logic</Button
          >
        </div>
      </div>
    </template>
    <template v-else>
      <Alert
        :icon="InfoCircle"
        alignment="vertical"
        title="Block skip logic will be available once a you have selected a title for this question."
        variant="soft"
        color="secondary"
        hideClose
      />
    </template>
  </SurveyBlockPanelBody>
</template>
