<script setup lang="ts">
import MessagingPageLayout from '@/components/pageLayout/MessagingPageLayout.vue';
import TabNavItem from '@/components/tabNav/TabNavItem.vue';
import Pagination from '@/components/pagination/Pagination.vue';
import ShieldExclamationIcon from '@/icons/line/shield-exclamation.svg';
import ThreadsEmptyState from '@/components/emptyState/ThreadsEmptyState.vue';
import { ComponentPublicInstance } from 'vue';
import ThreadLink from '@/components/messaging/threadLink.vue';
import { AppliedFilters } from '@/components/distiller/types';
import EmptyState from '@/components/emptyState/EmptyState.vue';
import RecipientBadge from '@/components/messaging/recipientBadge.vue';

const props = defineProps<{
  contacts: Paginator<App.AdmitHub.Data.AdmitHubContactData>;
  activeContactId?: number | null;
  appliedFilters: AppliedFilters;
  distiller: App.Search.Data.DistillerData;
}>();

const activeThreadLinkEl: Ref<ComponentPublicInstance | null> = ref(null);

const queryParameters = useQueryParameters();

onMounted(() => {
  if (props.activeContactId && activeThreadLinkEl.value) {
    activeThreadLinkEl.value.$el.scrollIntoView();
  }
});

const hasAppliedFilters = computed(() => Object.keys(props.appliedFilters).length > 0);
</script>


					<script lang="ts">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <MessagingPageLayout
    title="Chatbot"
    paddedHeader
    :distiller
    :appliedFilters
    :hasActiveThread="!!activeContactId"
  >
    <template #tabs>
      <TabNavItem
        :isActive="!queryParameters?.escalated && !queryParameters?.unresolved"
        label="All"
        variant="filled"
        :href="
          route('admithub.contacts.index', {
            ...queryParameters,
            page: undefined,
            escalated: undefined,
            unresolved: undefined
          })
        "
      />
      <TabNavItem
        :isActive="queryParameters?.escalated"
        label="Escalated"
        variant="filled"
        :href="
          route('admithub.contacts.index', {
            ...queryParameters,
            page: undefined,
            escalated: true,
            unresolved: undefined
          })
        "
      />
      <TabNavItem
        :isActive="queryParameters?.unresolved"
        label="Unresolved"
        variant="filled"
        :href="
          route('admithub.contacts.index', {
            ...queryParameters,
            page: undefined,
            unresolved: true,
            escalated: undefined
          })
        "
      />
    </template>

    <template #threads>
      <div class="max-h-full min-h-0 flex-1 divide-y divide-zinc-200 overflow-y-auto" scroll-region>
        <EmptyState
          title="No Contacts"
          :description="
            hasAppliedFilters ? 'No contacts match the selected filters' : 'No contacts available'
          "
          v-if="contacts.meta.total === 0"
        />
        <ThreadLink
          v-for="contact in contacts.data"
          :key="contact.id"
          :isActive="activeContactId === contact.id"
          :href="
            route('admithub.contacts.show', {
              contact: contact.id,
              ...queryParameters
            })
          "
          :title="contact.contactable?.name"
          :body="contact.latestMessage?.body"
          :date="contact.latestMessage?.created"
          :options="{ only: ['contact', 'student'], preserveScroll: true, preserveState: true }"
        >
          <template #prepend>
            <div class="h-4 w-4 pr-5">
              <ShieldExclamationIcon
                v-if="contact.unresolved_escalation_count"
                class="h-4 w-4 text-red-700"
              />
            </div>
          </template>
          <template #appendBody>
            <div v-if="contact.contactable">
              <RecipientBadge
                :isStudent="contact.is_student_contact"
                :recipient="contact.contactable"
              />
            </div>
          </template>
        </ThreadLink>
      </div>

      <!-- @TODO: horizontal scrolling here is not ideal, we should implement onEachSide modifications -->
      <Pagination
        :maxLinks="2"
        :showPageNumbers="false"
        compact
        :pagination="contacts"
        class="sticky bottom-0 overflow-x-scroll border-t bg-white md:relative"
      />
    </template>

    <template #thread>
      <slot>
        <div class="flex h-full w-full items-center justify-center">
          <ThreadsEmptyState
            title="Chatbot Threads"
            :description="`You have ${contacts.meta.total} chatbot conversations`"
          />
        </div>
      </slot>
    </template>
  </MessagingPageLayout>
</template>
