<script setup lang="ts">
import { useStudentsSearch } from '@/hooks/search/useStudentsSearch';
import { Selectable, SelectableValue } from '@/components/selectBox/selectBox';
import ComboboxInput from '@/components/selectBox/combobox/ComboboxInput.vue';
import { CssClass } from '@/@types/global';

const props = defineProps<{
  modelValue: Selectable<SelectableValue> | null;
  inputClasses?: CssClass;
  isLoading?: boolean;
  schoolIds?: number[];
  searchFilters?: Record<string, unknown>;
}>();

defineEmits(['update:modelValue']);

const {
  studentOptions,
  isLoading: isSearching,
  onQueryChange,
  updateSearchFilters
} = useStudentsSearch(props.searchFilters);

watch(
  () => props.schoolIds,
  (schoolIds) => {
    updateSearchFilters({ school_ids: schoolIds });
  }
);
</script>

<template>
  <ComboboxInput
    :options="studentOptions"
    :onFilter="onQueryChange"
    :isLoading="isLoading || isSearching"
    :modelValue="modelValue"
    :inputClasses="inputClasses"
    placeholder="Search for a student..."
    @update:modelValue="$emit('update:modelValue', $event)"
    @open="() => onQueryChange('')"
  />
</template>
