<script setup lang="ts">
import { debounce } from 'lodash';
import { AvailableFilter, AppliedFilter, AppliedFilterValue } from '@/components/distiller/types';
import ListboxInput from '@/components/selectBox/listbox/ListboxInput.vue';
import TextInput from '@/components/textInput/TextInput.vue';
import SelectFilterInput from '@/components/distiller/SelectFilterInput.vue';
import DateInput from '@/components/dateInput/DateInput.vue';

import { useFilters } from '@/hooks/useFilters';
import DateRangeInput from './DateRangeInput.vue';

type FilterOperator = App.Search.Enums.FilterOperator;

const props = withDefaults(
  defineProps<{
    path: string;
    filter: AvailableFilter;
    operator: FilterOperator;
    appliedFilter: AppliedFilter;
    isDisabled?: boolean;
    isReadonly?: boolean;
  }>(),
  {
    isDisabled: false
  }
);

const booleanOptions = [
  { label: 'Yes', value: true },
  { label: 'No', value: false }
];

const textInput = ref<{ inputEl: Ref<HTMLInputElement | null> | null }>({ inputEl: null });

onMounted(() => {
  if (textInput.value?.inputEl) {
    textInput.value.inputEl.focus();
  }
});

const { updateFilterValue } = useFilters();

const filterOptions = computed(
  () =>
    props.filter?.options?.map((opt) => {
      return {
        label: opt.label,
        value: opt.value,
        description: opt.description
      };
    }) ?? []
);

const handleUpdateFilterValue = debounce((updatedValue: AppliedFilterValue) => {
  updateFilterValue(props.path, updatedValue);
}, 300);
</script>

<template>
  <div class="min-w-0 flex-1">
    <SelectFilterInput
      v-if="filter.type === 'select'"
      class="w-full"
      :filterOptions="filterOptions"
      :appliedFilter="appliedFilter"
      :filter="filter"
      :operator="operator"
      :isDisabled="isDisabled"
      :isReadonly="isReadonly"
      @update:filterValue="handleUpdateFilterValue"
    />

    <ListboxInput
      v-if="filter.type === 'boolean'"
      class="w-full"
      :modelValue="booleanOptions.find((option) => option.value === appliedFilter?.value)"
      :isDisabled="isDisabled"
      :isReadonly="isReadonly"
      :options="booleanOptions"
      @update:modelValue="(opt) => handleUpdateFilterValue(opt?.value ?? null)"
    />

    <TextInput
      v-if="['text', 'number'].includes(filter.type)"
      class="w-full"
      ref="textInput"
      name="value"
      :isDisabled="isDisabled"
      :isReadonly="isReadonly"
      :type="filter.type === 'text' || filter.type === 'number' ? filter.type : 'text'"
      :modelValue="appliedFilter?.value as string"
      @update:modelValue="handleUpdateFilterValue"
    />

    <template v-if="filter.type === 'date'">
      <DateRangeInput
        v-if="operator === 'between'"
        class="w-full"
        :isDisabled="isDisabled"
        :isReadonly="isReadonly"
        :modelValue="appliedFilter?.value as (string | null)[]"
        @update:modelValue="handleUpdateFilterValue"
      />
      <DateInput
        v-else
        class="w-full"
        name="value"
        :isDisabled="isDisabled"
        :isReadonly="isReadonly"
        :modelValue="appliedFilter?.value as string"
        @update:modelValue="handleUpdateFilterValue"
      />
    </template>
  </div>
</template>
