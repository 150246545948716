<script setup lang="ts">
import Badge from '@/components/badge/Badge.vue';
import CalendarIcon from '@/icons/line/calendar.svg';
import GraduationCapIcon from '@/icons/line/graduation-cap.svg';

const props = defineProps<{
  recipient: App.Sms.Data.SmsRecipientData;
}>();

const meta = computed<App.Sms.Data.SmsRecipientStudentMetaData>(
  () => props.recipient.meta as unknown as App.Sms.Data.SmsRecipientStudentMetaData
);

const dob = computed(() => formatDate(meta.value.dob) ?? meta.value.dob);
</script>

<template>
  <Badge :iconLeft="CalendarIcon" :label="dob" size="sm" title="Date of Birth" />
  <Badge :iconLeft="GraduationCapIcon" :label="meta.grade" size="sm" title="Grade" />
</template>
