import { Ref, computed } from 'vue';
import TableCheckboxCell from '@/components/table/TableCheckboxCell.vue';
import { TableColumn } from './Table';

type SelectableRows<T extends Record<string, unknown>, K> = {
  onChecked: (checked: boolean, row: T) => void;
  onCheckedAll: (checked: boolean) => void;
  clearSelection: () => void;
  isTableFullySelected: Ref<boolean>;
  isTablePartiallySelected: Ref<boolean>;
  headerCheckedState: Ref<boolean | 'indeterminate' | false>;
  areAllPagesSelected: Ref<boolean>;
  toggleAllPagesSelection: () => void;
  hasAnySelection: Ref<boolean>;
  selectedRowIds: Ref<K[]>;
  selectableColumnDefinition: {
    key: string;
    cellRender?: (ctx: { row: T; column: TableColumn<T> }) => VNode | null;
    headerCellRender?: (ctx: { column: TableColumn<T> }) => VNode | null;
    width: string;
  };
};

export function useSelectableRows<T extends Record<string, unknown>, K>(
  rows: ComputedRef<T[]>,
  getRowId: (row: T) => K
): SelectableRows<T, K> {
  const selectedRowIds = ref<K[]>([]) as Ref<K[]>;
  const areAllPagesSelected = ref(false);

  function clearSelection() {
    selectedRowIds.value = [];
    areAllPagesSelected.value = false;
  }

  function toggleAllPagesSelection() {
    areAllPagesSelected.value = !areAllPagesSelected.value;
  }

  function onChecked(checked: boolean, row: T) {
    if (checked) {
      selectedRowIds.value.push(getRowId(row));
      return;
    }

    const deselectedRowId = getRowId(row);
    selectedRowIds.value = selectedRowIds.value.filter((rowId) => rowId !== deselectedRowId);
  }

  function onCheckedAll(checked: boolean) {
    if (checked) {
      selectedRowIds.value = rows.value.map((row) => getRowId(row));
      return;
    }

    selectedRowIds.value = [];
  }

  const isTableFullySelected = computed<boolean>(() =>
    rows.value.length
      ? rows.value.every((row) => selectedRowIds.value.includes(getRowId(row)))
      : false
  );

  const isTablePartiallySelected = computed(() =>
    rows.value.length
      ? rows.value.some((row) => selectedRowIds.value.includes(getRowId(row)))
      : false
  );

  const headerCheckedState = computed(() => {
    if (isTableFullySelected.value) {
      return true;
    }

    if (isTablePartiallySelected.value) {
      return 'indeterminate';
    }

    return false;
  });

  const hasAnySelection = computed(
    () => selectedRowIds.value.length > 0 || areAllPagesSelected.value
  );

  const selectableColumnDefinition = {
    key: 'select',
    cellRender: ({ row }) =>
      h(TableCheckboxCell, {
        checked: selectedRowIds.value.includes(row.id as K),
        onChecked: (checked: boolean) => onChecked(checked, row)
      }),
    headerCellRender: () =>
      h(TableCheckboxCell, { checked: headerCheckedState.value, onChecked: onCheckedAll }),
    width: '52px'
  };

  return {
    hasAnySelection,
    onChecked,
    onCheckedAll,
    clearSelection,
    isTableFullySelected,
    isTablePartiallySelected,
    headerCheckedState,
    areAllPagesSelected,
    toggleAllPagesSelection,
    selectedRowIds,
    selectableColumnDefinition
  };
}
