<script setup lang="ts">
import { RouterLink } from 'hybridly/vue';

import Badge from '@/components/badge/Badge.vue';
import VerticalDivider from '@/components/verticalDivider/VerticalDivider.vue';

type RouterLinkOptions = ExtractPropTypes<typeof RouterLink>['options'];

const props = defineProps<{
  badgeLabel?: string;
  description?: string;
  href?: string;
  hrefOptions?: RouterLinkOptions;
  showBadge?: boolean;
  showTitleBadge?: boolean;
  title: string;
  titleBadgeIcon?: Component;
  titleBadgeLabel?: string;
}>();

const rawTitleBadgeIconIcon = toRaw(props.titleBadgeIcon);

const computedCardComponent = computed(() => (props.href ? RouterLink : 'div'));
</script>

<template>
  <component
    :is="computedCardComponent"
    class="overflow-hidden rounded-2xl border border-slate-200 p-4"
    :class="{
      'cursor-pointer transition-colors duration-150 ease-in-out hover:bg-slate-50': href
    }"
    :href="href"
    :options="hrefOptions"
  >
    <div class="flex items-center justify-between gap-x-8">
      <div class="flex min-w-0 items-center gap-x-2">
        <div class="overflow-hidden">
          <h3 class="truncate text-base font-semibold leading-6 text-slate-700">
            {{ title }}
          </h3>
          <p class="text-sm leading-4 text-slate-500">
            {{ description }}
          </p>
        </div>
        <VerticalDivider v-if="showTitleBadge" size="lg" />
        <Badge
          v-if="showTitleBadge"
          :iconLeft="rawTitleBadgeIconIcon"
          :label="titleBadgeLabel"
          variant="soft"
          size="md"
        />
      </div>
      <Badge v-if="showBadge" :label="badgeLabel" size="md" variant="soft" shape="rounded" />
    </div>
  </component>
</template>
