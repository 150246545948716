<script setup lang="ts">
import type { EmptyStateProps } from './emptyStateProps';

import { ButtonColor, ButtonVariant } from '@/hooks/useButtonClasses';

import Button from '@/components/button/Button.vue';

import EmptyStateShapes from '@/images/empty-state-shapes.svg';

const props = withDefaults(defineProps<EmptyStateProps>(), {
  illustrationSize: 'lg',
  isLoading: false
});

const illustrationClass = computed(() => ({
  'w-48': props.illustrationSize === 'lg',
  'w-32': props.illustrationSize === 'md'
}));

defineEmits(['click']);

defineSlots<{
  default: { illustrationClass: Record<string, boolean> };
}>();
</script>

<template>
  <div
    class="flex h-full flex-col items-center justify-center px-6"
    :class="{
      'py-14 md:py-48': !inline,
      'py-6': inline
    }"
  >
    <div class="flex flex-col items-center gap-y-4">
      <slot v-bind="{ illustrationClass }">
        <EmptyStateShapes :class="illustrationClass" />
      </slot>
      <div class="text-center">
        <h3 class="text-base font-semibold leading-6 text-slate-900">{{ title }}</h3>
        <p class="text-sm leading-5 text-slate-500">{{ description }}</p>
      </div>
      <Button
        v-if="actionLabel"
        :color="ButtonColor.primary"
        :href="actionHref"
        :isLoading="isLoading"
        :variant="ButtonVariant.solid"
        @click="$emit('click')"
        >{{ actionLabel }}</Button
      >
    </div>
  </div>
</template>
