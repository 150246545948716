<script setup lang="ts">
type EnumData = App.Base.Data.EnumData;

import type { HybridlyFormData } from '@/@types/global';

import { ButtonColor, ButtonVariant } from '@/hooks/useButtonClasses';
import { useAuth } from '@/hooks/useAuth';

import Button from '@/components/button/Button.vue';
import Fieldset from '@/components/fieldset/Fieldset.vue';
import Form from '@/components/form/Form.vue';
import FormDelete from '@/components/formDelete/FormDelete.vue';
import FormFooter from '@/components/formFooter/FormFooter.vue';
import FormHeader from '@/components/formHeader/FormHeader.vue';
import FormListboxInput from '@/components/formListboxInput/FormListboxInput.vue';
import FormSwitchInput from '@/components/formSwitchInput/FormSwitchInput.vue';
import FormTextInput from '@/components/formTextInput/FormTextInput.vue';
import DangerDialog from '@/components/dialog/DangerDialog.vue';

const props = defineProps<{
  survey: App.Surveys.Data.SurveyData;
  form: HybridlyFormData<{
    name: string;
    status: EnumData;
    is_template: boolean;
  }>;
  isEditing?: boolean;
  surveyStatuses: EnumData[];
}>();

const isCancelDialogOpen = ref(false);

const { hasPermission } = useAuth();
const { onBackForward } = useBackForward();

// TODO: Fix intecepting the back button
onBackForward(() => {
  router.abort();
});

function handleToggleCancelDialog() {
  if (props.form.isDirty) {
    isCancelDialogOpen.value = !isCancelDialogOpen.value;
    return;
  }

  handleRedirect();
}

function handleRedirect() {
  router.navigate({ url: route('surveys.show', { survey: props.survey.id }) });
}
</script>

<template>
  <div class="flex flex-col gap-y-8">
    <Form @submit.prevent="form.submit">
      <template #header>
        <FormHeader
          title="Survey Settings"
          description="Use the form below to edit the survey settings."
        />
      </template>
      <Fieldset title="Survey information">
        <FormTextInput
          :error="form.errors.name"
          :modelValue="form.fields.name"
          label="Survey name"
          name="name"
          placeholder="e.g. Academic Progress Check-in"
          @update:modelValue="(value) => (form.fields.name = value)"
        />
        <FormListboxInput
          label="Survey status"
          :error="form.errors.status"
          :options="surveyStatuses"
          :modelValue="form.fields.status"
          @update:modelValue="(value) => (form.fields.status = value)"
        />
      </Fieldset>
      <Fieldset
        v-if="survey.sessions_count === 0 && hasPermission('create-survey-template')"
        title="Other settings"
      >
        <FormSwitchInput
          label="This survey is a template"
          name="is_template"
          :checked="form.fields.is_template"
          @update:modelValue="(value) => (form.fields.is_template = value)"
        />
      </Fieldset>
      <template #footer>
        <FormFooter>
          <Button
            type="submit"
            :color="ButtonColor.primary"
            :isDisabled="form.processing || !form.isDirty"
            :isLoading="form.processing"
            :variant="ButtonVariant.solid"
            >Update survey</Button
          >
          <Button
            :color="ButtonColor.slate"
            :variant="ButtonVariant.outlined"
            type="button"
            @click="handleToggleCancelDialog"
            >Cancel</Button
          >
        </FormFooter>
      </template>
      <template #delete>
        <FormDelete :route="route('surveys.delete', { survey: survey.id })" recordType="survey" />
      </template>
    </Form>
  </div>
  <DangerDialog
    :title="`Cancel updating survey setting`"
    :message="`Are you sure you want to cancel updating the survey settings? All changes will be lost.`"
    :isOpen="isCancelDialogOpen"
    cancelButtonLabel="Stay on this page"
    confirmButtonLabel="Go back to survey overview"
    @onCancel="handleToggleCancelDialog"
    @onClose="handleToggleCancelDialog"
    @onConfirm="handleRedirect"
  />
</template>
