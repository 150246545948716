<script setup lang="ts">
type EnumData = App.Base.Data.EnumData;

import { buildSelectableCustomTest } from '@/utils/buildSelectable';

import CenteredContent from '@/components/content/CenteredContent.vue';
import FormDelete from '@/components/formDelete/FormDelete.vue';
import StudentInteractionForm from '@/components/students/studentActions/StudentInteractionForm.vue';
import StudentInteractionFormWrapper from '@/components/students/studentActions/StudentInteractionFormWrapper.vue';

const props = defineProps<{
  returnUrl?: string;
  interaction: App.Students.Data.InteractionData;
  student: App.Students.Data.StudentData;
  customTests: App.Configuration.Data.CustomTestData[];
  mediums: EnumData[];
  meetingLengths: EnumData[];
  testTypes: EnumData[];
  topics: EnumData[];
  types: EnumData[];
}>();

const form = useForm({
  method: 'PUT',
  url: route('students.interactions.edit', {
    student: props.student.id,
    interaction: props.interaction.id
  }),
  fields: {
    notes: props.interaction.notes,
    topics: props.topics.filter(
      (topic) => props.interaction.topics?.map((t) => t.value).includes(topic.value)
    ),
    application_assisted_institute_ids: (
      props.interaction.application_assisted_applications || []
    ).map((application) => buildSelectableInstitute(application.institute)),
    award_letter_review_institute_ids: (
      props.interaction.award_letter_review_applications || []
    ).map((application) => buildSelectableInstitute(application.institute)),
    scholarship_names: (props.interaction.student_scholarships || []).map((studentScholarship) =>
      buildSelectableScholarship(studentScholarship.scholarship)
    ),
    tests: props.interaction.tests?.map((test) => buildTest(test)) ?? [],
    types: props.types.filter(
      (type) =>
        props.interaction.types?.map((selectedType) => selectedType.value).includes(type.value)
    ),
    institute_id: buildSelectableInstitute(props.interaction?.institute),
    medium: props.interaction.medium,
    meeting_length: props.interaction.meeting_length,
    date: props.interaction.date
  },
  transform: (fields) => ({
    ...fields,
    topics: fields.topics?.map((type) => type.value) ?? [],
    application_assisted_institute_ids:
      fields.application_assisted_institute_ids?.map((institute) =>
        institute ? institute.value : null
      ) ?? null,
    award_letter_review_institute_ids:
      fields.award_letter_review_institute_ids?.map((institute) =>
        institute ? institute.value : null
      ) ?? null,
    scholarship_names:
      fields.scholarship_names?.map((scholarship) => (scholarship ? scholarship.label : null)) ??
      null,
    tests:
      fields.tests && fields.tests.length > 0
        ? fields.tests.map((testItem) => ({
            type:
              testItem.type?.value === 'custom-test' && testItem.test?.value
                ? testItem.test?.value
                : testItem.type?.value,
            date: testItem.date,
            test: testItem.test?.value
          }))
        : null,
    types: fields.types?.map((type) => type.value) ?? null,
    institute_id: fields.institute_id?.value,
    medium: fields.medium?.value,
    meeting_length: fields.meeting_length?.value
  })
});

function buildTest(test) {
  const isCustomTest = !props.testTypes.find((type) => type.value === test.type);

  return {
    type: isCustomTest
      ? { value: 'custom-test', label: 'Custom Test' }
      : props.testTypes.find((testType) => testType.value === test.type),
    date: test.date,
    test: isCustomTest
      ? buildSelectableCustomTest(
          props.customTests.find((customTest) => customTest.name === test.type)
        )
      : null
  };
}
</script>


					<script lang="ts">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <CenteredContent>
    <StudentInteractionFormWrapper :form="form" :student="student" isEditing>
      <StudentInteractionForm
        :testTypes="testTypes"
        :form="form"
        :mediums="mediums"
        :meetingLengths="meetingLengths"
        :student="student"
        :topics="topics"
        :types="types"
        isEditing
      />
      <template #delete>
        <FormDelete
          :route="
            route('students.interactions.delete', {
              student: student.id,
              interaction: interaction.id
            })
          "
          recordType="interaction"
        />
      </template>
    </StudentInteractionFormWrapper>
  </CenteredContent>
</template>
