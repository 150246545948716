<script setup lang="ts">
import type { HybridlyFormData } from '@/@types/global';

import { ButtonColor, ButtonVariant } from '@/hooks/useButtonClasses';

import Button from '@/components/button/Button.vue';
import DangerDialog from '@/components/dialog/DangerDialog.vue';
import Fieldset from '@/components/fieldset/Fieldset.vue';
import Form from '@/components/form/Form.vue';
import FormFooter from '@/components/formFooter/FormFooter.vue';
import FormHeader from '@/components/formHeader/FormHeader.vue';
import FormTextInput from '@/components/formTextInput/FormTextInput.vue';
import Divider from '@/components/divider/Divider.vue';
import FormMultiStudentInput from '@/components/formComboboxInput/FormMultiStudentInput.vue';
import Envelope from '@/icons/line/envelope.svg';
import Phone from '@/icons/line/phone.svg';

import type { Selectable } from '@/components/selectBox/selectBox';

const props = defineProps<{
  form: HybridlyFormData<{
    first_name: string | null;
    last_name: string | null;
    email: string | null;
    cell_phone: string | null;
    student_ids: number[] | null;
  }>;
  returnUrl?: string;
}>();

const isCancelDialogOpen = ref(false);

function handleToggleCancelDialog() {
  if (props.form.isDirty) {
    isCancelDialogOpen.value = !isCancelDialogOpen.value;
    return;
  }
  handleRedirect();
}

function handleRedirect() {
  router.navigate({
    url: props.returnUrl || route('parents.index')
  });
}
</script>

<template>
  <Form @submit.prevent="form.submit">
    <template #header>
      <FormHeader
        canClose
        title="Add a Parent/Guardian"
        description="Use the form below to add a new parent/guardian."
        @onClose="handleToggleCancelDialog"
      />
    </template>
    <Fieldset title="Parent/Guardian information">
      <FormTextInput
        :error="form.errors.first_name"
        :modelValue="form.fields.first_name"
        label="First name"
        name="first_name"
        @update:modelValue="(value) => (form.fields.first_name = value as string)"
      />
      <FormTextInput
        :error="form.errors.last_name"
        :modelValue="form.fields.last_name"
        label="Last name"
        name="last_name"
        @update:modelValue="(value) => (form.fields.last_name = value as string)"
      />

      <FormTextInput
        :error="form.errors.email"
        :iconLeft="Envelope"
        :modelValue="form.fields.email"
        label="Email"
        name="email"
        @update:modelValue="(value) => (form.fields.email = value as string)"
      />
      <FormTextInput
        :error="form.errors.cell_phone"
        :iconLeft="Phone"
        :modelValue="form.fields.cell_phone"
        label="Phone"
        name="cell_phone"
        @update:modelValue="(value) => (form.fields.cell_phone = value as string)"
      />
    </Fieldset>
    <Divider />
    <Fieldset title="Students">
      <FormMultiStudentInput
        :error="form.errors.student_ids as unknown as string"
        :modelValue="form.fields.student_ids as Selectable<number>[]"
        label="Students"
        name="student_ids"
        @update:modelValue="(value) => (form.fields.student_ids = value)"
      />
    </Fieldset>
    <template #footer>
      <FormFooter>
        <Button
          type="submit"
          :color="ButtonColor.primary"
          :isDisabled="form.processing || !form.isDirty"
          :isLoading="form.processing"
          :variant="ButtonVariant.solid"
          >Confirm</Button
        >
        <Button
          @click="handleToggleCancelDialog"
          :color="ButtonColor.slate"
          :variant="ButtonVariant.outlined"
          type="button"
          >Back to parents/guardians</Button
        >
      </FormFooter>
    </template>
  </Form>
  <DangerDialog
    title="Cancel adding a new parent/guardian"
    message="Are you sure you want to cancel adding this parent/guardian? All information will be lost."
    :isOpen="isCancelDialogOpen"
    cancelButtonLabel="Stay on this page"
    confirmButtonLabel="Go back to parents/guardians"
    @onCancel="handleToggleCancelDialog"
    @onClose="handleToggleCancelDialog"
    @onConfirm="handleRedirect"
  />
</template>
