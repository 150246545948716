<script setup lang="ts">
import TwoFactorChannelData = App.Auth.Data.TwoFactorChannelData;
import type { HybridlyFormData } from '@/@types/global';

import { useAuth } from '@/hooks/useAuth';
import { ButtonColor } from '@/hooks/useButtonClasses';

import Account2faChannels from '@/components/account/Account2faChannels.vue';
import Alert from '@/components/alert/Alert.vue';
import Button from '@/components/button/Button.vue';
import DangerDialog from '@/components/dialog/DangerDialog.vue';
import Fieldset from '@/components/fieldset/Fieldset.vue';
import Form from '@/components/form/Form.vue';
import FormFooter from '@/components/formFooter/FormFooter.vue';
import FormHeader from '@/components/formHeader/FormHeader.vue';
import FormRows from '@/components/formRows/FormRows.vue';
import FormTextInput from '@/components/formTextInput/FormTextInput.vue';
import PasswordStrength from '@/components/passwordStrength/PasswordStrength.vue';

import Check from '@/icons/line/check.svg';
import Envelope from '@/icons/line/envelope.svg';
import KoDotContainer from '@/icons/line/ko-dot-container.svg';
import InfoCircle from '@/icons/line/info-circle.svg';
import Link from '@/icons/line/link.svg';
import Lock from '@/icons/line/lock.svg';
import Phone from '@/icons/line/phone.svg';

const props = defineProps<{
  form: HybridlyFormData<{
    first_name: string;
    last_name: string;
    password: string | null;
    new_password: string | null;
    new_password_confirmation: string | null;
    email: string;
    sms_email: string | null;
    sms_phone_number: string | null;
    url: string | null;
    channels: [];
  }>;
  channels: TwoFactorChannelData[];
}>();

const { hasPermission } = useAuth();

const isCancelDialogOpen = ref(false);

const isPasswordLongEnough = computed(() =>
  props.form.fields.new_password ? props.form.fields.new_password.length >= 8 : false
);
const doPasswordsMatch = computed(
  () =>
    props.form.fields.new_password &&
    props.form.fields.new_password.length > 0 &&
    props.form.fields.new_password === props.form.fields.new_password_confirmation
);

const { onBackForward } = useBackForward();

// TODO: Fix intecepting the back button
onBackForward(() => {
  router.abort();
});

function handleToggleCancelDialog() {
  if (props.form.isDirty) {
    isCancelDialogOpen.value = !isCancelDialogOpen.value;
    return;
  }

  handleRedirect();
}

function handleRedirect() {
  router.navigate({ url: route('dashboard.show') });
}
</script>

<template>
  <Form @submit.prevent="form.submit">
    <template #header>
      <FormHeader
        canClose
        title="My Account"
        description='Use the form belown and hit "Save Changes" to edit your account information.'
        @onClose="handleToggleCancelDialog"
      />
    </template>
    <Fieldset title="Account information">
      <div class="flex gap-2">
        <FormTextInput
          :error="form.errors.first_name"
          :modelValue="form.fields.first_name"
          label="First name"
          name="first_name"
          @update:modelValue="(value) => (form.fields.first_name = value)"
        />
        <FormTextInput
          :error="form.errors.last_name"
          :modelValue="form.fields.last_name"
          label="Last name"
          name="last_name"
          @update:modelValue="(value) => (form.fields.last_name = value)"
        />
      </div>
      <FormTextInput
        :error="form.errors.email"
        :modelValue="form.fields.email"
        isDisabled
        label="Email"
        helperText="You cannot edit your own email address. Please reach out to grace@advisingcorps.org to have your email address updated."
        name="email"
        type="email"
        @update:modelValue="(value) => (form.fields.email = value)"
      />
    </Fieldset>
    <Fieldset title="Password">
      <div class="flex flex-col gap-y-7">
        <FormTextInput
          :error="form.errors.password"
          :iconLeft="Lock"
          :modelValue="form.fields.password"
          label="Current password"
          name="password"
          type="password"
          @update:modelValue="(value) => (form.fields.password = value)"
        />
        <FormRows>
          <FormTextInput
            :error="form.errors.new_password"
            :iconLeft="Lock"
            :modelValue="form.fields.new_password"
            label="New password"
            name="new_password"
            type="password"
            @update:modelValue="(value) => (form.fields.new_password = value)"
          >
            <template #helperText>
              <PasswordStrength :password="form.fields.new_password" />
            </template>
          </FormTextInput>
          <FormTextInput
            :error="form.errors.new_password_confirmation"
            :iconLeft="Lock"
            :modelValue="form.fields.new_password_confirmation"
            label="Confirm new password"
            name="new_password_confirmation"
            type="password"
            @update:modelValue="(value) => (form.fields.new_password_confirmation = value)"
          />
        </FormRows>
        <ul class="flex flex-col gap-1.5 text-sm text-slate-700">
          <li class="flex items-center gap-2">
            <Check class="w-4 text-green-600" v-if="isPasswordLongEnough" />
            <KoDotContainer v-else />
            Password should be at least 8 characters long
          </li>
          <li class="flex items-center gap-2">
            <Check class="w-4 text-green-600" v-if="doPasswordsMatch" />
            <KoDotContainer v-else />
            Confirm your password
          </li>
        </ul>
      </div>
    </Fieldset>
    <Fieldset v-if="hasPermission('create-sms')" title="SMS">
      <FormTextInput
        :error="form.errors.sms_email"
        :iconLeft="Envelope"
        :isDisabled="!hasPermission('create-sms')"
        :modelValue="form.fields.sms_email"
        label="SMS email"
        name="sms_email"
        type="email"
        @update:modelValue="(value) => (form.fields.sms_email = value)"
      />
      <FormTextInput
        :error="form.errors.sms_phone_number"
        :iconLeft="Phone"
        :modelValue="form.fields.sms_phone_number"
        isDisabled
        label="SMS Phone"
        helperText="This phone number is the automatically assigned phone number used to send/receive SMS messages within GRACE. It cannot be updated."
        name="sms_phone_number"
        type="tel"
        @update:modelValue="(value) => (form.fields.sms_phone_number = value)"
      />
    </Fieldset>
    <Fieldset v-if="hasPermission('create-sms')" title="Contact link">
      <FormTextInput
        :error="form.errors.url"
        :iconLeft="Link"
        :modelValue="form.fields.url"
        label="Contact link"
        name="url"
        type="url"
        @update:modelValue="(value) => (form.fields.url = value)"
      />
    </Fieldset>
    <Fieldset title="Two factor authentication">
      <Account2faChannels
        v-if="channels.length"
        :channels="channels"
        tooltip="You cannot delete your only verified two factor authentication method."
      />
      <Alert
        v-else
        :icon="InfoCircle"
        alignment="vertical"
        title="Channels list will be visible when you set up your two factor authentication."
        hideClose
      />
    </Fieldset>
    <template #footer>
      <FormFooter>
        <Button
          :color="ButtonColor.primary"
          :isDisabled="form.processing || !form.isDirty"
          :isLoading="form.processing"
          type="submit"
          variant="solid"
          >Confirm</Button
        >
        <Button
          :color="ButtonColor.slate"
          type="button"
          variant="outlined"
          @click="handleToggleCancelDialog"
        >
          Back to dashboard
        </Button>
      </FormFooter>
    </template>
  </Form>

  <DangerDialog
    title="Cancel editting your account information"
    message="Are you sure you want to cancel editing your account information? All changes will be lost."
    :isOpen="isCancelDialogOpen"
    cancelButtonLabel="Stay on this page"
    confirmButtonLabel="Go back to dashboard"
    @onCancel="handleToggleCancelDialog"
    @onClose="handleToggleCancelDialog"
    @onConfirm="handleRedirect"
  />
</template>
