<script setup lang="ts">
type EnumData = App.Base.Data.EnumData;

import type { Selectable } from '@/components/selectBox/selectBox';
import type { TableSelectionState } from '@/hooks/useHybridlyTable';

import pluralize from 'pluralize';

import Alert from '@/components/alert/Alert.vue';
import Dialog from '@/components/dialog/Dialog.vue';
import Fieldset from '@/components/fieldset/Fieldset.vue';
import FormListboxInput from '@/components/formListboxInput/FormListboxInput.vue';
import FormRows from '@/components/formRows/FormRows.vue';

const props = defineProps<{
  enrollmentStatuses: EnumData[];
  isOpen: boolean;
  selectedStudentsCount: number;
  tableSelectionState: TableSelectionState;
}>();

const emit = defineEmits<{
  onCancel: [void];
  onClose: [void];
}>();

const queryParameters = useQueryParameters();

const form = useForm({
  method: 'POST',
  url: route('students.bulk-update.enrollment-status'),
  fields: {
    ids: props.tableSelectionState.selectedRowIds || [],
    filters: queryParameters.filters || null,
    all: props.tableSelectionState.areAllRecordsSelected,
    status: null as Selectable<string> | null
  },
  transform: (fields) => ({
    ...fields,
    ids: props.tableSelectionState.selectedRowIds || [],
    filters: queryParameters.filters || null,
    all: props.tableSelectionState.areAllRecordsSelected,
    status: fields.status?.value
  }),
  hooks: {
    success() {
      props.tableSelectionState.reset();
      emit('onClose');
    }
  }
});

function handleConfirm() {
  form.submit();
}
</script>

<template>
  <Dialog
    :isOpen="isOpen"
    :isLoading="form.processing"
    confirmButtonLabel="Change status"
    description="Change enrollment status for all selected students"
    title="Change Enrollment Status"
    @onConfirm="handleConfirm"
    @onClose="emit('onClose')"
    @onCancel="emit('onCancel')"
  >
    <FormRows>
      <Fieldset title="Change to">
        <FormListboxInput
          label="Enrollment status"
          :error="form.errors.status"
          :options="enrollmentStatuses"
          :modelValue="form.fields.status"
          @update:modelValue="(value) => (form.fields.status = value)"
        />
        <Alert title="Selected Students" variant="soft">
          You are updating the enrollment status for
          <span class="font-bold">{{ selectedStudentsCount }}</span> selected
          {{ pluralize('student', selectedStudentsCount) }}.
        </Alert>
      </Fieldset>
    </FormRows>
  </Dialog>
</template>
