<script setup lang="ts">
import TextInput from '@/components/textInput/TextInput.vue';
import type { TextInputProps } from '@/components/textInput/TextInputProps';
import SearchIcon from '@/icons/line/search.svg';

withDefaults(defineProps<TextInputProps>(), {
  placeholder: 'Search...'
});

const textInputRef = ref<typeof TextInput | null>(null);

defineExpose({ inputEl: textInputRef });
</script>

<template>
  <TextInput ref="textInputRef" v-bind="{ ...$props, ...$attrs }" :iconLeft="SearchIcon" />
</template>
