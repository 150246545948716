<script setup lang="ts">
import Badge from '@/components/badge/Badge.vue';
import { ChartjsDatasetItem, formatGoalTarget, NumberVisualization } from './data';
import CheckIcon from '@/icons/line/check.svg';
import CrosshairIcon from '@/icons/line/crosshair.svg';

interface Props {
  data?: ChartjsDatasetItem;
  format: NumberVisualization;
}
const props = defineProps<Props>();

const text = computed(() => (props.data ? formatGoalTarget(props.data, props.format) : ''));
const icon = computed(() => (props.data?.goal?.complete === true ? CheckIcon : CrosshairIcon));
const iconColor = computed(() => (props.data?.goal?.complete === true ? 'success' : 'danger'));
</script>

<template>
  <div v-if="data && data.goal">
    <Badge :iconLeft="icon" :iconColor="iconColor" size="md">
      {{ text }}
    </Badge>
  </div>
</template>
