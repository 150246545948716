<script lang="ts" setup>
type DataHookData = App.Sms.Data.DataHookData;

import PlusCircleIcon from '@/icons/line/plus-circle.svg';
import Button, { ButtonProps } from '@/components/button/Button.vue';
import {
  ComboboxRoot,
  ComboboxTrigger,
  ComboboxPortal,
  ComboboxContent,
  ComboboxGroup,
  ComboboxLabel,
  ComboboxItem,
  ComboboxAnchor
} from 'radix-vue';
import { groupBy } from 'lodash';
import { index as indexDataHooks } from '@/api/DataHookApi';
import { BadgeColor } from '@/hooks/useBadgeClasses';
import { ButtonColor, ButtonVariant } from '@/hooks/useButtonClasses';
import Badge from '@/components/badge/Badge.vue';
import ComboboxSearchInput from '@/components/selectBox/combobox/ComboboxSearchInput.vue';

type Props = {
  isDisabled?: boolean;
  hooks?: DataHookData[];
  size?: ButtonProps['size'];
};

const props = withDefaults(defineProps<Props>(), {
  isDisabled: false,
  hooks: () => [],
  size: 'md'
});

defineEmits<{
  insert: [hook: DataHookData];
}>();

const keys = useMagicKeys();
const trigger = ref();
const resolvedHooks = ref<DataHookData[]>(props.hooks);
const search = ref<string>('');
const groups = computed(() => Array.from(new Set(resolvedHooks.value.map(({ group }) => group))));
const groupedDataHooks = computed(() =>
  groupBy(
    resolvedHooks.value.filter(({ label }) =>
      search.value.length > 0 ? label.toLowerCase().includes(search.value.toLowerCase()) : true
    ),
    'group'
  )
);

function handleSearchQuery(query: string) {
  search.value = query;
}

async function fetchHooks() {
  const { hooks } = await indexDataHooks();

  resolvedHooks.value = hooks;
}

onBeforeMount(() => {
  if (resolvedHooks.value.length === 0) {
    fetchHooks();
  }
});

whenever(keys.ctrl_i, () => trigger.value?.$el.click());
</script>

<template>
  <ComboboxRoot
    :disabled="isDisabled"
    :modelValue="undefined"
    :filterFunction="(opts) => opts"
    :options="[]"
  >
    <ComboboxAnchor>
      <ComboboxTrigger asChild>
        <Button
          :size
          ref="trigger"
          as="button"
          tabindex="0"
          :color="ButtonColor.slate"
          :iconLeft="PlusCircleIcon"
          :variant="ButtonVariant.soft"
        >
          Tag
        </Button>
      </ComboboxTrigger>
    </ComboboxAnchor>

    <ComboboxPortal>
      <ComboboxContent
        align="start"
        :sideOffset="4"
        position="popper"
        class="w-full max-w-[512px] rounded-lg border border-slate-200 bg-white drop-shadow-md"
      >
        <ComboboxSearchInput :searchQuery="search" @searchQuery="handleSearchQuery" />
        <div class="divide-y border border-t-0">
          <ComboboxGroup
            v-for="group in groups"
            :key="group"
            class="grid grid-cols-[60px_1fr] gap-x-2 py-2"
          >
            <ComboboxLabel class="mt-1 px-3 text-2xs font-bold uppercase text-zinc-500">
              {{ group }}
            </ComboboxLabel>
            <div class="flex flex-wrap justify-start gap-x-2 gap-y-1 px-3 py-1">
              <ComboboxItem
                v-for="hook in groupedDataHooks[group]"
                :key="hook.id"
                :value="hook"
                @click="$emit('insert', hook)"
                asChild
              >
                <Badge
                  as="button"
                  variant="soft"
                  :color="BadgeColor.slate"
                  class="data-[highlighted]:border-slate-400 data-[highlighted]:ring-2 data-[highlighted]:ring-slate-700/40"
                >
                  {{ hook.label }}
                </Badge>
              </ComboboxItem>
            </div>
          </ComboboxGroup>
        </div>
      </ComboboxContent>
    </ComboboxPortal>
  </ComboboxRoot>
</template>
