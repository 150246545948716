<script setup lang="ts">
import { ButtonColor, ButtonSize, ButtonVariant } from '@/hooks/useButtonClasses';
import { useFilters } from '@/hooks/useFilters';

import Button from '@/components/button/Button.vue';

import Plus from '@/icons/line/plus.svg';

defineEmits<{
  'update:addingNewFilter': [value: boolean];
}>();

type Props = {
  title?: string;
  description?: string;
  prompt?: string;
  addingNewFilter: boolean;
};
withDefaults(defineProps<Props>(), {
  title: 'No filters are applied',
  prompt: 'Add a filter'
});
const { hasAppliedFilter } = useFilters();
</script>

<template>
  <section
    v-if="!hasAppliedFilter() && !addingNewFilter"
    class="rounded-2xl border border-dashed border-zinc-400 bg-zinc-50 py-14"
  >
    <div class="space-y-4 text-center">
      <div class="text-center">
        <p class="text-base font-semibold leading-6 text-zinc-900">{{ title }}</p>
        <p v-if="description" class="text-sm font-normal leading-5 text-zinc-500">
          {{ description }}
        </p>
      </div>
      <Button
        :color="ButtonColor.slate"
        :iconLeft="Plus"
        :size="ButtonSize.md"
        :variant="ButtonVariant.outlined"
        @click="$emit('update:addingNewFilter', true)"
        >{{ prompt }}</Button
      >
    </div>
  </section>
  <slot v-else></slot>
</template>
