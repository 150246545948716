<script setup lang="ts">
import pluralize from 'pluralize';

import { BadgeColor } from '@/hooks/useBadgeClasses';
import { ButtonColor, ButtonSize, ButtonVariant } from '@/hooks/useButtonClasses';

import Badge from '@/components/badge/Badge.vue';
import Button from '@/components/button/Button.vue';

import CheckCircleIcon from '@/icons/line/check-circle.svg';
import QuestionCircleIcon from '@/icons/line/question-circle.svg';
import PlusCircleIcon from '@/icons/line/plus-circle.svg';
import ExclamationCircleIcon from '@/icons/line/exclamation-circle.svg';
import TimesIcon from '@/icons/line/times.svg';

const props = defineProps<{
  uploadRecord: App.Bulkinator.Data.UploadRecordData;
  uploadType: App.Bulkinator.Data.UploadTypeData;
}>();

defineEmits<{
  close: [];
}>();

const matchCount = computed(() => props.uploadRecord.matches?.length ?? 0);
const errorCount = computed(() => Object.keys(props.uploadRecord.validation_results ?? {}).length);

const badgeData = computed(() => {
  return (
    {
      match: {
        color: 'success',
        label: 'Match found',
        iconLeft: CheckCircleIcon
      },
      pending: {
        color: 'warning',
        label: `${pluralize('match', matchCount.value, true)} pending`,
        iconLeft: QuestionCircleIcon
      },
      new: {
        color: 'primary',
        label: 'New entry',
        iconLeft: PlusCircleIcon
      },
      error: {
        color: 'danger',
        label: `${pluralize('error', errorCount.value, true)} found`,
        iconLeft: ExclamationCircleIcon
      }
    }[props.uploadRecord.status] ?? null
  );
});
</script>

<template>
  <div class="flex items-center justify-between border-b border-zinc-200 px-4 py-5">
    <div class="flex items-center gap-2">
      <h3 class="text-base font-semibold text-slate-900">Edit Record</h3>
      <Badge
        v-if="badgeData"
        :color="badgeData.color as BadgeColor"
        :label="badgeData.label"
        :iconLeft="badgeData.iconLeft"
        variant="soft"
        size="md"
        shape="rounded"
      />
    </div>

    <Button
      :size="ButtonSize.sm"
      :variant="ButtonVariant.invisible"
      :color="ButtonColor.slate"
      :iconLeft="TimesIcon"
      @click="$emit('close')"
      >Close</Button
    >
  </div>
</template>
