<script setup lang="ts">
import DangerDialog from '@/components/dialog/DangerDialog.vue';
import Button from '@/components/button/Button.vue';
import { ButtonColor } from '@/hooks/useButtonClasses';

const props = defineProps<{
  recordName: string;
  deleteUrl: string;
}>();

defineEmits<{
  click: [];
  cancel: [];
  delete: [];
}>();

const isDialogOpen = ref(false);

const form = useForm({
  method: 'delete',
  url: props.deleteUrl,
  preserveState: false,
  reset: true,
  fields: {}
});
</script>

<template>
  <div class="rounded-lg border border-slate-200 bg-slate-50 px-4 py-5">
    <h5 class="text-sm font-medium text-slate-900">Delete {{ recordName }}</h5>
    <p class="text-sm text-slate-700">
      Permanently delete this {{ recordName }}. This is irreversible.
    </p>
    <Button class="mt-4" :color="ButtonColor.danger" variant="solid" @click="isDialogOpen = true"
      >Delete {{ recordName }}</Button
    >
  </div>

  <DangerDialog
    :title="`Delete ${recordName}`"
    :message="`Are you sure you want to delete this ${recordName}? This action cannot be undone.`"
    :isOpen="isDialogOpen"
    :isLoading="form.processing"
    :confirmButtonLabel="`Delete ${recordName}`"
    @onCancel="isDialogOpen = false"
    @onClose="isDialogOpen = false"
    @onConfirm="form.submit"
  />
</template>
