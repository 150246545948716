<script setup lang="ts">
import get from 'lodash/get';

import Badge from '@/components/badge/Badge.vue';
import Tooltip from '@/components/tooltip/Tooltip.vue';

export type MetricCardProps = {
  badgeLabel?: string;
  badgeTruncatorItems?: Record<string, unknown>[];
  badgeTruncatorItemKey?: string;
  title: string;
  backgroundColor?: 'white' | 'gray';
};

const props = withDefaults(defineProps<MetricCardProps>(), {
  backgroundColor: 'white'
});

const filteredBadgeTruncatorItems = computed(() => props.badgeTruncatorItems?.slice(1) ?? []);

const filteredBadgeTruncatorLabel = computed(() => {
  const truncatorItemsLength = props.badgeTruncatorItems?.length ?? 0;
  return truncatorItemsLength > 1 ? `+${truncatorItemsLength - 1}` : undefined;
});
</script>

<template>
  <div
    class="w-full min-w-38 rounded-lg border border-slate-200 px-4 py-5"
    :class="{
      'bg-white': backgroundColor === 'white',
      'bg-slate-50': backgroundColor === 'gray'
    }"
  >
    <div class="truncate text-sm leading-6 text-slate-500">
      {{ title }}
    </div>
    <div class="flex items-center justify-between gap-2">
      <p class="truncate text-lg font-semibold leading-7"><slot /></p>
      <Tooltip
        v-if="filteredBadgeTruncatorLabel && filteredBadgeTruncatorItems && badgeTruncatorItemKey"
        showArrow
      >
        <template #trigger>
          <Badge :label="filteredBadgeTruncatorLabel" variant="soft" />
        </template>
        <ul class="list-outside list-disc py-1.5 pl-4">
          <li v-for="(item, index) in filteredBadgeTruncatorItems" :key="index">
            {{ get(item, badgeTruncatorItemKey) }}
          </li>
        </ul>
      </Tooltip>
      <Badge v-else-if="badgeLabel" :label="badgeLabel" variant="soft" />
    </div>
  </div>
</template>
