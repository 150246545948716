<template layout="student-parent">
  <div class="flex flex-col gap-y-4">
    <div
      v-for="item in 7"
      :key="item"
      class="divide-y divide-slate-200 rounded-xl border border-slate-200 bg-white py-2"
    >
      <div v-for="item in 3" :key="item" class="flex h-16 items-center gap-x-5 pl-5 pr-7">
        <div class="h-6 w-6 rounded-full bg-slate-200"></div>
        <div class="h-3 grow bg-slate-200"></div>
      </div>
    </div>
  </div>
</template>
