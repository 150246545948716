function getPositiveBooleanValues(): (string | number | boolean)[] {
  return [
    '1',
    1,
    true,
    'true',
    'y',
    'yes',
    'x',
    'oui',
    'ok',
    'okay',
    'o.k.',
    't',
    'pass',
    'complete',
    'affirmative',
    'positive',
    'correct',
    'yea',
    'yeah',
    'yup',
    'yep',
    'valid',
    '✔'
  ];
}

function getNegativeBooleanValues(): (string | number | boolean)[] {
  return [
    '0',
    0,
    false,
    'false',
    'n',
    'no',
    'none',
    'not required',
    'na',
    'n/a',
    'not applicable',
    'negative',
    'f',
    'fail',
    'failure',
    'incomplete',
    'incorrect',
    'invalid'
  ];
}

export function filterBoolean(value: string | number | boolean): boolean {
  let output: string | number | boolean = value;

  if (typeof value === 'string') {
    if (!isNaN(Number(value))) {
      output = parseInt(value);
    } else {
      const lowerCaseVar: string = value.toLowerCase();

      if (getPositiveBooleanValues().includes(lowerCaseVar)) {
        output = true;
      } else if (getNegativeBooleanValues().includes(lowerCaseVar)) {
        output = false;
      }
    }
  }

  return Boolean(output);
}

export function getBooleanDisplayValue<T>(
  value?: boolean | null,
  nullValue: T = 'Not set' as T,
  trueValue: T = 'Yes' as T,
  falseValue: T = 'No' as T
): T {
  if (typeof value === undefined || value === null) {
    return nullValue;
  }

  return value ? trueValue : falseValue;
}
