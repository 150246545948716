<script setup lang="ts">
type EnumData = App.Base.Data.EnumData;

import type { HybridlyFormData } from '@/@types/global';

import { ButtonColor, ButtonVariant } from '@/hooks/useButtonClasses';

import Button from '@/components/button/Button.vue';
import DangerDialog from '@/components/dialog/DangerDialog.vue';
import Fieldset from '@/components/fieldset/Fieldset.vue';
import Form from '@/components/form/Form.vue';
import FormFooter from '@/components/formFooter/FormFooter.vue';
import FormHeader from '@/components/formHeader/FormHeader.vue';
import FormListboxInput from '@/components/formListboxInput/FormListboxInput.vue';
import FormMultiSchoolInput from '@/components/formComboboxInput/FormMultiSchoolInput.vue';
import FormTextInput from '@/components/formTextInput/FormTextInput.vue';
import FormMultiProgramInput from '@/components/formComboboxInput/FormMultiProgramInput.vue';
import { Selectable } from '@/components/selectBox/selectBox';

const props = defineProps<{
  form: HybridlyFormData<{
    goal_type: string;
    grade: number;
    goal_percentage: number;
    school_ids: number[];
  }>;
  isEditing?: boolean;
  types: EnumData[];
  grades: EnumData[];
}>();

const isCancelDialogOpen = ref(false);
const programs = ref<Selectable<number>[]>([]);

const { onBackForward } = useBackForward();

// TODO: Fix intecepting the back button
onBackForward(() => {
  router.abort();
});

function handleToggleCancelDialog() {
  if (props.form.isDirty) {
    isCancelDialogOpen.value = !isCancelDialogOpen.value;
    return;
  }
  handleRedirect();
}

function handleRedirect() {
  router.navigate({ url: route('goals.index') });
}
</script>

<template>
  <Form @submit.prevent="form.submit">
    <template #header>
      <FormHeader
        canClose
        :title="`${isEditing ? 'Edit' : 'Add'} a Goal`"
        :description="
          isEditing
            ? 'Use the form below to edit the goal.'
            : 'Fill out the form below add a new goal.'
        "
        @onClose="handleToggleCancelDialog"
      />
    </template>
    <Fieldset title="Goal information">
      <FormListboxInput
        :error="form.errors.goal_type"
        :modelValue="form.fields.goal_type"
        :options="types"
        label="Goal type"
        placeholder="Select goal type"
        @update:modelValue="(value) => (form.fields.goal_type = value)"
      />
      <FormListboxInput
        label="Grade"
        name="grade"
        :options="grades"
        :error="form.errors.grade"
        :modelValue="form.fields.grade"
        @update:modelValue="(value) => (form.fields.grade = value)"
      />
      <FormTextInput
        :error="form.errors.goal_percentage"
        :modelValue="form.fields.goal_percentage"
        append="%"
        label="Goal percentage"
        name="goal_percentage"
        placeholder="e.g. 84"
        type="number"
        @update:modelValue="(value) => (form.fields.goal_percentage = value)"
      />
    </Fieldset>
    <Fieldset title="Which schools should this goal apply to?">
      <FormMultiProgramInput
        :modelValue="programs"
        label="Programs"
        placeholder="Select programs"
        @update:modelValue="(value) => (programs = value)"
      />
      <FormMultiSchoolInput
        :programIds="programs.map((program) => program.value)"
        :error="form.errors.school_ids"
        :modelValue="form.fields.school_ids"
        label="Schools"
        placeholder="Select schools"
        @update:modelValue="(value) => (form.fields.school_ids = value)"
      />
    </Fieldset>
    <template #footer>
      <FormFooter>
        <Button
          :color="ButtonColor.primary"
          :isDisabled="form.processing"
          :isLoading="form.processing"
          :variant="ButtonVariant.solid"
          type="submit"
          >Confirm</Button
        >
        <Button
          :color="ButtonColor.slate"
          :variant="ButtonVariant.outlined"
          type="button"
          @click="handleToggleCancelDialog"
        >
          Back to goals
        </Button>
      </FormFooter>
    </template>
  </Form>
  <DangerDialog
    :title="`Cancel ${isEditing ? 'editing' : 'adding a new'} goal`"
    :message="`Are you sure you want to cancel ${isEditing ? 'editing' : 'adding'} this goal? All ${
      isEditing ? 'changes' : 'information'
    } will be lost.`"
    :isOpen="isCancelDialogOpen"
    cancelButtonLabel="Stay on this page"
    confirmButtonLabel="Go back to goals"
    @onCancel="handleToggleCancelDialog"
    @onClose="handleToggleCancelDialog"
    @onConfirm="handleRedirect"
  />
</template>
