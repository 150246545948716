<script setup lang="ts">
type EnumData = App.Base.Data.EnumData;

import CustomFormCheckboxInput from '@/components/customFields/CustomFormCheckboxInput.vue';
import CustomFormNumberInput from '@/components/customFields/CustomFormNumberInput.vue';
import CustomFormSelectInput from '@/components/customFields/CustomFormSelectInput.vue';
import CustomFormTextInput from '@/components/customFields/CustomFormTextInput.vue';

defineProps<{
  customField: App.Configuration.Data.ProgramStudentFieldData;
  error?: string;
  modelValue?: string | number | null | EnumData;
}>();

const emit = defineEmits<{
  'update:modelValue': [value: string | number | null];
}>();

function getCustomFieldComponent(type) {
  return {
    text: CustomFormTextInput,
    boolean: CustomFormCheckboxInput,
    number: CustomFormNumberInput,
    select: CustomFormSelectInput
  }[type];
}

function onValueChange(event: string | number | null): void {
  emit('update:modelValue', event);
}
</script>

<template>
  <component
    :is="getCustomFieldComponent(customField.type.value)"
    :customField
    :error
    :modelValue
    @update:modelValue="onValueChange"
  />
</template>
