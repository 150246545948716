<script setup lang="ts">
import ComboboxInput from '@/components/selectBox/combobox/ComboboxInput.vue';
import MultiComboboxInput from '@/components/selectBox/combobox/MultiComboboxInput.vue';
import {
  AppliedFilter,
  AppliedFilterValue,
  AvailableFilter,
  AppliedFilterValuePrimitives
} from '@/components/distiller/types';
import { Selectable } from '@/components/selectBox/selectBox';

const props = withDefaults(
  defineProps<{
    appliedFilter: AppliedFilter;
    filter: AvailableFilter;
    filterOptions: Selectable<AppliedFilterValuePrimitives>[];
    operator: App.Search.Enums.FilterOperator;
    isDisabled?: boolean;
    isReadonly?: boolean;
  }>(),
  {
    isDisabled: false
  }
);

const emit = defineEmits<{
  'update:filterValue': [AppliedFilterValue];
}>();

function getSelectedMultiComboboxOptions() {
  if (props.appliedFilter?.value instanceof Array) {
    return props.filterOptions.filter(
      (filterOption) =>
        (props.appliedFilter.value as AppliedFilterValue[])?.includes(filterOption.value)
    );
  }
  return [];
}

const multiComboboxValue = ref(getSelectedMultiComboboxOptions());

watch(
  () => props.appliedFilter,
  () => {
    multiComboboxValue.value = getSelectedMultiComboboxOptions();
  }
);

watch(
  multiComboboxValue,
  () => {
    handleUpdateFilterValueFromMultiSelect(multiComboboxValue.value);
  },
  { deep: true }
);

function handleUpdateFilterValueFromMultiSelect(
  opt: Selectable<AppliedFilterValuePrimitives>[] | null
) {
  if (opt) {
    emit(
      'update:filterValue',
      opt.map((o) => o.value)
    );
  }
}
</script>

<template>
  <MultiComboboxInput
    v-if="['in', 'not_in'].includes(operator || '')"
    :options="filterOptions"
    :isDisabled="isDisabled"
    :isReadonly="isReadonly"
    placeholder="Select an option..."
    v-model="multiComboboxValue"
  />
  <ComboboxInput
    v-else
    :options="filterOptions || []"
    :modelValue="filterOptions?.find((filterOption) => filterOption.value === appliedFilter?.value)"
    :isDisabled="isDisabled"
    :isReadonly="isReadonly"
    placeholder="Select an option..."
    @update:modelValue="(opt) => $emit('update:filterValue', opt?.value ?? null)"
  />
</template>
