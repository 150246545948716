<script setup lang="tsx">
import UploadSessionSettingsForm from '@/domains/bulkinator/components/forms/UploadSessionSettingsForm.vue';
import IconButton from '@/components/button/IconButton.vue';
import GuidedSteps from '@/components/guidedSteps/GuidedSteps.vue';
import GuidedStepsContainer from '@/components/guidedSteps/GuidedStepsContainer.vue';
import PageHeader from '@/components/pageHeader/PageHeader.vue';
import Times from '@/icons/line/times.svg';
import StepContainer from '@/components/stepContainer/StepContainer.vue';
import {
  defaultCreateFormValues,
  defaultUploadFormValues,
  transformCreateFormValues,
  transformMappingFormValues,
  useUploadSessionFormWizard
} from '@/domains/bulkinator/components/forms/useUploadSessionFormWizard';
import UploadSessionFileUploadForm from '@/domains/bulkinator/components/forms/UploadSessionFileUploadForm.vue';
import pluralize from 'pluralize';
import UploadSessionColumnMappingForm from '@/domains/bulkinator/components/forms/UploadSessionColumnMappingForm.vue';
import Badge from '@/components/badge/Badge.vue';
import { useSpreadsheetColumnHeaders } from '@/hooks/useSpreadsheetColumnHeaders';
import FormSwitchInput from '@/components/formSwitchInput/FormSwitchInput.vue';
import QuestionCircle from '@/icons/line/question-circle.svg';

const props = defineProps<{
  uploadType: App.Bulkinator.Data.UploadTypeData;
  testType?: App.Base.Data.EnumData;
  identifierLabels: string[];
  uploadTypeColumnIds: Array<{ label: string; value: string }>;
  columns: App.Bulkinator.Data.UploadColumnData[];
}>();

const [showOnlyUnmapped, setShowOnlyUnmapped] = useToggle(false);

const { guidedSteps, steps, currentStep, form } = useUploadSessionFormWizard(
  {
    config: {
      upload: {
        label: 'Upload File',
        description: 'Select a spreadsheet'
      },
      mapping: {
        label: 'Column Mapping',
        description: 'Map header columns',
        subtitle: 'Unmapped columns will be stored but not displayed in the table view.'
      },
      create: {
        label: 'Create',
        description: 'Set and create upload session',
        subtitle: 'Almost done'
      }
    },
    order: ['upload', 'mapping', 'create']
  },
  {
    method: 'POST',
    url: route('upload-sessions.store'),
    fields: {
      ...defaultCreateFormValues({
        uploadType: props.uploadType.name,
        testType: props.testType?.value
      }),
      ...defaultUploadFormValues
    },
    updateInitials: true,
    transform: (v) => ({
      ...transformCreateFormValues(props.uploadType)(v),
      ...transformMappingFormValues(v)
    })
  }
);

function mapColumn(col: App.Bulkinator.Data.UploadColumnData) {
  return {
    label: col.name,
    value: col.column ?? col.name
  };
}

const hideForEditColumns = computed(() =>
  props.columns.filter((col) => col.hideForEdit).map(mapColumn)
);
const availableColumns = computed(() =>
  props.columns.filter((col) => !col.hideForEdit).map(mapColumn)
);

watch(form.errors, (errors) => {
  const firstErrorKey = Object.keys(errors)[0];

  if (!firstErrorKey) {
    return;
  }

  if (Object.keys(defaultCreateFormValues({})).includes(firstErrorKey)) {
    guidedSteps.goTo('create');
  } else if (firstErrorKey === 'file') {
    guidedSteps.goTo('upload');
  } else {
    guidedSteps.goTo('mapping');
  }
});

function handleClose() {
  router.navigate({
    url: route('upload-sessions.create', { uploadType: props.uploadType.id })
  });
}

const columnHeadersState = useSpreadsheetColumnHeaders({
  onChange: ({ mappings }) => {
    form.clearError('file');
    form.fields.column_mappings = mappings;
  },
  onError: (e) => {
    form.fields.column_mappings = {};
    form.setErrors({ file: e.message });
  },
  columns: availableColumns.value,
  hideForEditColumns: hideForEditColumns.value
});

// Only use this for counts
const unmappedHeadersCount = computed(() => {
  const mapped = Object.values(form.fields.column_mappings).filter((v) => !!v).length;
  const total = columnHeadersState.state.value.headers.length;

  return total - mapped;
});

function handleFileChange(file?: File) {
  form.fields.column_mappings = {};
  columnHeadersState.execute(undefined, file);
}
</script>


					<script lang="tsx">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <PageHeader title="Create Using an Excel File" :centered="true">
    <template #rightButtons>
      <IconButton
        :icon="Times"
        @click="handleClose"
        size="sm"
        variant="invisible"
        ariaLabel="Cancel manual session creation"
      />
    </template>
  </PageHeader>

  <GuidedStepsContainer class="border-y px-20">
    <GuidedSteps
      :steps="steps"
      :currentStep="guidedSteps.index.value"
      direction="horizontal"
      :showStepNumber="false"
      :showBadge="false"
      class="mx-auto"
    />
  </GuidedStepsContainer>

  <StepContainer :title="currentStep.description" :description="currentStep.subtitle">
    <template #secondaryActions>
      <template v-if="guidedSteps.current.value === 'mapping'">
        <FormSwitchInput
          name="show_only_unmapped"
          :checked="showOnlyUnmapped"
          @update:checked="(v) => setShowOnlyUnmapped(v)"
        >
          <template #label>Only show unmapped columns</template>
        </FormSwitchInput>
      </template>
    </template>

    <template #titleInformation>
      <template v-if="guidedSteps.current.value === 'mapping'">
        <Badge
          v-if="unmappedHeadersCount > 0"
          :iconLeft="QuestionCircle"
          variant="soft"
          color="warning"
          >{{ unmappedHeadersCount }} unmapped
          {{ pluralize('column', unmappedHeadersCount) }}</Badge
        >
      </template>
    </template>

    <template v-if="guidedSteps.current.value === 'upload'">
      <div class="mx-auto max-w-2xl">
        <UploadSessionFileUploadForm
          :form="form"
          :uploadType="uploadType"
          :identifierLabels="identifierLabels"
          :processing="columnHeadersState.isLoading.value"
          @submit="guidedSteps.goToNext()"
          @cancel="handleClose"
          @drop="handleFileChange"
        />
      </div>
    </template>

    <template v-else-if="guidedSteps.current.value === 'mapping'">
      <div class="mx-auto max-w-2xl">
        <UploadSessionColumnMappingForm
          :form="form"
          :headers="columnHeadersState.state.value.headers"
          :uploadType="uploadType"
          :columns="availableColumns"
          :showOnlyUnmapped="showOnlyUnmapped"
          :uploadTypeColumnIds="uploadTypeColumnIds"
          @submit="guidedSteps.goToNext()"
          @cancel="guidedSteps.goToPrevious()"
        />
      </div>
    </template>

    <template v-else-if="guidedSteps.current.value === 'create'">
      <div class="mx-auto max-w-2xl">
        <UploadSessionSettingsForm
          :form="form"
          :uploadType="uploadType"
          @submit="form.submit"
          @cancel="guidedSteps.goToPrevious()"
        />
      </div>
    </template>
  </StepContainer>
</template>
