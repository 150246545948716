import axios from 'axios';

export async function search(
  filters: Record<string, unknown>
): Promise<App.Configuration.Data.ScholarshipData[]> {
  return axios.get(route('api.scholarships.search', filters)).then((res) => res.data);
}

export async function bulkCreate(filters: Record<string, unknown>): Promise<{
  session_url: string;
}> {
  return axios.post(route('api.bulkinator.scholarship.store'), filters).then((res) => res.data);
}
