type AlertType = App.Configuration.Enums.AlertType;

import type { AlertColor } from '@/components/alert/Alert.vue';

export const colorMap: Record<AlertType, AlertColor> = {
  welcome: 'primary',
  info: 'secondary',
  success: 'success',
  warning: 'warning',
  danger: 'danger'
};

export function useAlertColor() {
  function getAlertColor(type: AlertType): AlertColor {
    return colorMap[type];
  }

  return {
    getAlertColor,
    colorMap
  };
}
