<script setup lang="ts">
import type { ShowApCourseProps } from '@/domains/configuration/views/ap-courses/ShowApCourse.vue';

import { useDialogHelpers } from '@/hooks/useDialogHelpers';

import { formatDateTime } from '@/utils/date';

import Badge from '@/components/badge/Badge.vue';
import DangerDialog from '@/components/dialog/DangerDialog.vue';
import DefinitionList from '@/components/definitionList/DefinitionList.vue';
import DefinitionListItem from '@/components/definitionList/DefinitionListItem.vue';
import IconButton from '@/components/button/IconButton.vue';
import InformationCollapsible from '@/components/collapsible/InformationCollapsible.vue';
import Slideout from '@/components/slideout/Slideout.vue';

import TrashIcon from '@/icons/line/trash.svg';
import Cell from '@/icons/line/cell.svg';
import EditAlt from '@/icons/line/edit-alt.svg';
import FilesLandscapeAlt from '@/icons/line/files-landscapes-alt.svg';
import PlusCircle from '@/icons/line/plus-circle.svg';

const props = defineProps<ShowApCourseProps>();

const { show, close } = useDialog();
const { isDialogOpen, toggleDialog } = useDialogHelpers();

const form = useForm({
  method: 'DELETE',
  spoof: false,
  url: route('ap-courses.delete', { apCourse: props.apCourse.id }),
  fields: {},
  hooks: {
    success() {
      close();
    }
  }
});

async function handleApCourseDelete() {
  await form.submit();
  toggleDialog();
}
</script>

<template>
  <Slideout
    :title="apCourse.display_name"
    :isOpen="show"
    @onClose="close"
    :icon="FilesLandscapeAlt"
  >
    <template #actions>
      <IconButton
        v-if="apCourse.authorization.update"
        :href="route('ap-courses.edit', { apCourse: apCourse.id })"
        :icon="EditAlt"
        ariaLabel="Edit AP course"
        size="sm"
        variant="soft"
      />
      <IconButton
        v-if="apCourse.authorization.delete"
        color="danger"
        :icon="TrashIcon"
        ariaLabel="Move AP course to trash"
        size="sm"
        variant="soft"
        @click="toggleDialog"
      />
    </template>
    <InformationCollapsible title="AP Course Information">
      <div class="flex flex-col gap-4">
        <DefinitionList>
          <DefinitionListItem :icon="Cell" title="Type">
            <Badge label="AP Course" variant="soft" size="md" />
          </DefinitionListItem>
        </DefinitionList>
        <DefinitionList>
          <DefinitionListItem
            :description="formatDateTime(apCourse.created_at) || 'No create date'"
            :icon="PlusCircle"
            title="Created"
          />
          <DefinitionListItem
            :description="formatDateTime(apCourse.updated_at) || 'No update date'"
            title="Updated"
          />
        </DefinitionList>
      </div>
    </InformationCollapsible>
  </Slideout>
  <DangerDialog
    title="Delete AP Course"
    message="Are you sure you want to delete this AP course? This action is permanent and cannot be reverted."
    confirmButtonLabel="Delete"
    :isOpen="isDialogOpen"
    :isLoading="form.processing"
    @onConfirm="handleApCourseDelete"
    @onCancel="toggleDialog"
    @onClose="toggleDialog"
  />
</template>
