<script setup lang="ts">
import pluralize from 'pluralize';

import { formatRelative } from '@/utils/date';

import { useUploadSessionCount } from '@/hooks/useUploadSessionCount';
import { ButtonColor, ButtonVariant } from '@/hooks/useButtonClasses';

import Button from '@/components/button/Button.vue';
import CollapsibleContent from '@/components/collapsible/CollapsibleContent.vue';
import Dialog from '@/components/dialog/Dialog.vue';
import FormTextInput from '@/components/formTextInput/FormTextInput.vue';
import IconBadge from '@/components/badge/IconBadge.vue';
import RepartitionCounts from './RepartitionCounts.vue';
import Tooltip from '@/components/tooltip/Tooltip.vue';

import ExclamationCircleIcon from '@/icons/line/exclamation-circle.svg';
import ImportIcon from '@/icons/line/import.svg';
import InfoCircleIcon from '@/icons/line/info-circle.svg';
import PlusCircleIcon from '@/icons/line/plus-circle.svg';
import QuestionCircleIcon from '@/icons/line/question-circle.svg';
import Spinner from '@/icons/line/spinner.svg';

const props = defineProps<{
  uploadSession: App.Bulkinator.Data.UploadSessionData;
  uploadType: App.Bulkinator.Data.UploadTypeData;
}>();

const [isOpen, toggleOpen] = useToggle(false);
const confirmationText = ref('');
const { counts, loadUploadSessionCount, isLoading } = useUploadSessionCount(props.uploadSession.id);

watch(
  () => isOpen.value,
  async (isOpen) => {
    if (isOpen) {
      await loadUploadSessionCount();
    }
  }
);

const confirmationButtonEnabled = computed(
  () => confirmationText.value.toLowerCase() === 'validate session'
);

function handleFormSubmit() {
  if (!confirmationButtonEnabled.value) {
    return;
  }

  form.submit();
}

const form = useForm({
  url: route('upload-sessions.import', { uploadSession: props.uploadSession.id }),
  method: 'POST',
  fields: {},
  only: ['uploadRecords', 'uploadSession'],
  hooks: {
    success() {
      toggleOpen();
    }
  }
});
</script>

<template>
  <Dialog
    title="Import Session"
    :isOpen="isOpen"
    :isLoading="form.processing"
    contentClass="max-w-2xl"
    :confirmButtonDisabled="!confirmationButtonEnabled"
    confirmButtonLabel="Import Session"
    @onCancel="() => toggleOpen()"
    @onClose="() => toggleOpen()"
    @onConfirm="handleFormSubmit"
  >
    <div class="space-y-4 text-sm">
      <p>
        Importing this session will create and update existing entries. The roster for your school
        will look like this after the import:
      </p>

      <RepartitionCounts v-if="uploadType.id === 'student'" :uploadSession :uploadType />

      <p class="font-bold">By importing this session, I understand that:</p>

      <div class="flex gap-2">
        <InfoCircleIcon class="mt-0.5 h-4 w-4 flex-shrink-0 text-slate-500" />
        <p>
          GRACE has identified matching entries, but in some cases, duplicates may still occur. If
          you are unsure about whether an entry already exists, please review the new entries and
          existing data.
        </p>
      </div>
      <div class="flex gap-2">
        <InfoCircleIcon class="mt-0.5 h-4 w-4 flex-shrink-0 text-slate-500" />
        <p>All matching entries will be overriden with new data.</p>
      </div>

      <CollapsibleContent class="space-y-4">
        <div class="flex items-center gap-2">
          <IconBadge
            v-if="isLoading"
            :icon="Spinner"
            color="success"
            isCircle
            class="animate-spin"
          />
          <IconBadge v-else :icon="InfoCircleIcon" color="success" isCircle />
          <p class="w-5 font-bold">{{ counts.match }}</p>
          <p>
            {{ pluralize(uploadType.record_name, counts.match, false) }} will be updated with new
            information.
          </p>
        </div>

        <div class="flex items-center gap-2">
          <IconBadge v-if="isLoading" :icon="Spinner" color="info" isCircle class="animate-spin" />
          <IconBadge v-else :icon="PlusCircleIcon" color="info" isCircle />
          <p class="w-5 font-bold">{{ counts.new }}</p>
          <p>{{ pluralize(uploadType.record_name, counts.new, false) }} will be newly added.</p>
        </div>

        <div class="flex items-center gap-2">
          <IconBadge
            v-if="isLoading"
            :icon="Spinner"
            color="warning"
            isCircle
            class="animate-spin"
          />
          <IconBadge v-else :icon="QuestionCircleIcon" color="warning" isCircle />
          <p class="w-5 font-bold">{{ counts.pending }}</p>
          <p>
            {{ pluralize(uploadType.record_name, counts.pending, false) }} still have potential
            matches. They will not be overwritten.
          </p>
        </div>

        <div class="flex items-center gap-2">
          <IconBadge
            v-if="isLoading"
            :icon="Spinner"
            color="danger"
            isCircle
            class="animate-spin"
          />
          <IconBadge v-else :icon="ExclamationCircleIcon" color="danger" isCircle />
          <p class="w-5 font-bold">{{ counts.error }}</p>
          <p>
            {{ pluralize(uploadType.record_name, counts.error, false) }} still have errors and must
            be reviewed. They will not be overwritten.
          </p>
        </div>
      </CollapsibleContent>

      <form @submit.prevent="handleFormSubmit">
        <FormTextInput
          name="confirmation"
          v-model="confirmationText"
          placeholder="VALIDATE SESSION"
        >
          <template #label>Confirm by typing <strong>VALIDATE SESSION</strong> below:</template>
        </FormTextInput>
      </form>
    </div>
  </Dialog>

  <Button
    v-if="uploadSession.status.value === 'draft'"
    :color="ButtonColor.primary"
    :iconLeft="ImportIcon"
    :variant="ButtonVariant.solid"
    @click="() => toggleOpen()"
    >Import session</Button
  >
  <Tooltip v-else-if="uploadSession.status.value === 'running'" showArrow side="top">
    <template #trigger>
      <Button isLoading :color="ButtonColor.primary" :variant="ButtonVariant.solid">
        Importing data...
      </Button>
    </template>

    Started import
    {{
      uploadSession.data_import?.started_at
        ? formatRelative(uploadSession.data_import.started_at)
        : 'a few minutes ago'
    }}
  </Tooltip>
</template>
