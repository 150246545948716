<script setup lang="ts">
import { RouterLink } from 'hybridly/vue';
import { useButtonClasses, ButtonColor, ButtonSize, ButtonVariant } from '@/hooks/useButtonClasses';
import { BadgeVariant } from '@/hooks/useBadgeClasses';

import type { ButtonProps } from '@/components/button/Button.vue';
import Tooltip from '@/components/tooltip/Tooltip.vue';

import SpinnerIcon from '@/icons/line/spinner.svg';

type IconButtonProps = Omit<ButtonProps, 'iconLeft' | 'iconRight'> & {
  ariaLabel: string;
  icon: Component;
  checked?: boolean;
  isToggle?: boolean;
  as?: Component | string;
  tooltipProps?: InstanceType<typeof Tooltip>['$props'];
};

const props = withDefaults(defineProps<IconButtonProps>(), {
  type: 'button',
  color: ButtonColor.slate,
  size: ButtonSize.md,
  variant: ButtonVariant.solid
});

const rawIcon = toRaw(props.icon);

const iconComponent = computed(() => (props.isLoading ? SpinnerIcon : rawIcon));

const dynamicAttributes = computed(() => {
  const attributes = {};

  if (props.isToggle) {
    attributes['aria-pressed'] = props.checked;
  }
  if (computedIconButtonComponent.value === 'button') {
    attributes['type'] = props.type;
  }

  return attributes;
});

const computedIconButtonComponent = computed(() => {
  if (props.as) {
    return props.as;
  }

  if (props.isExternalLink && props.href) {
    return 'a';
  }

  return props.href ? RouterLink : 'button';
});

const buttonClasses = computed(() => [
  ...useButtonClasses({
    color: props.isLoading ? ButtonColor.slate : props.color,
    variant: props.variant
  }),
  'border rounded-md',
  'hover:border-2',
  'aria-pressed:border',
  'focus:border-2',
  'active:border-2',
  'disabled:border disabled:hover:border disabled:aria-pressed:border disabled:active:ring-transparent',
  {
    'h-6': props.size === 'xs',
    'h-7': props.size === 'sm',
    'h-8': props.size === 'md',
    'h-10': props.size === 'lg'
  },
  BadgeVariant.outlined
    ? {
        'p-[5px] disabled:p-[5px] hover:p-1 focus:p-1 active:p-1': props.size === 'xs',
        'p-[7px] disabled:p-[7px] aria-pressed:hover:p-[7px] aria-pressed:p-[7px] hover:p-1.5 focus:p-1.5 active:p-1.5':
          props.size === 'sm' || props.size === 'md' || props.size === 'lg'
      }
    : {
        'p-1': props.size === 'xs',
        'p-1.5': props.size === 'sm' || props.size === 'md' || props.size === 'lg'
      }
]);

const iconClasses = computed(() => ({
  // Shared
  'opacity-80': !props.checked,

  // Shared
  'h-4 w-4': props.size === 'xs',
  'h-4.5 w-4.5': props.size === 'sm',
  'h-5 w-5': props.size === 'md' || props.size === 'lg',

  'animate-spin': props.isLoading
}));
</script>

<template>
  <Tooltip v-bind="tooltipProps" isTouchTooltipDisabled>
    {{ ariaLabel }}
    <template #trigger>
      <component
        :is="computedIconButtonComponent"
        :ariaLabel="ariaLabel"
        :ariaPressed="isToggle ? checked : null"
        :class="buttonClasses"
        :disabled="isDisabled || isLoading"
        :href="href"
        :options="hrefOptions"
        v-bind="dynamicAttributes"
      >
        <component :is="iconComponent" :class="iconClasses" />
        <slot />
      </component>
    </template>
  </Tooltip>
</template>
