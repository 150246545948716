<script setup lang="ts">
import { RouterLink } from 'hybridly/vue';
import { ButtonColor, ButtonSize, ButtonVariant } from '@/hooks/useButtonClasses';
import { useDialogHelpers } from '@/hooks/useDialogHelpers';
import IconButton from '@/components/button/IconButton.vue';
import DangerDialog from '@/components/dialog/DangerDialog.vue';
import PhoneIcon from '@/icons/line/phone.svg';
import EnvelopeIcon from '@/icons/line/envelope.svg';
import ElipsisDoubleVAlt from '@/icons/line/elipsis-double-v-alt.svg';
import TrashIcon from '@/icons/line/trash.svg';

type StudentParentData = App.Students.Data.StudentParentData;

const props = defineProps<{
  parent: StudentParentData;
  student: App.Students.Data.StudentData;
}>();

const { isDialogOpen, toggleDialog } = useDialogHelpers();
const isVisible = ref(true);

const deleteForm = useForm({
  method: 'DELETE',
  spoof: false,
  url: route('students.parents-guardians.remove', {
    student: props.student.id,
    parent: props.parent.id
  }),
  fields: {}
});

function handleRemoveParentClick() {
  toggleDialog();
}

function handleRemoveParentConfirm() {
  toggleDialog();
  isVisible.value = false;
}
</script>

<template>
  <Transition
    name="collapse"
    @leave="
      (el: Element, done: () => void) => {
        const element = el as HTMLElement;
        const height = element.getBoundingClientRect().height;
        element.style.height = height + 'px';
        void element.offsetHeight; // Force reflow
        element.style.height = '0px';
        element.addEventListener(
          'transitionend',
          async () => {
            done();
            deleteForm.submit();
          },
          { once: true }
        );
      }
    "
  >
    <div v-if="isVisible" class="will-change-height duration-400 transition-all ease-in-out">
      <div class="relative my-1 flex overflow-hidden rounded-lg border border-slate-200 bg-white">
        <div
          @click.prevent
          class="parent-card-handle flex cursor-grab items-center justify-center border-r border-slate-200 px-2 text-slate-500 hover:bg-slate-50/50 hover:text-slate-700 active:cursor-grabbing"
        >
          <ElipsisDoubleVAlt class="h-5 w-5" />
        </div>
        <RouterLink
          class="flex grow items-center gap-x-2 px-3 py-4 transition duration-150 ease-in-out hover:cursor-pointer"
          :href="route('parents.show', { parent: parent.id })"
          :options="{ preserveState: true, preserveScroll: true, only: ['parent'] }"
        >
          <div class="flex grow flex-col gap-y-0.5 overflow-hidden text-sm font-medium">
            <h3 class="flex gap-x-2 font-semibold text-slate-900">{{ parent.name }}</h3>
            <p class="flex items-center gap-x-1 text-slate-500">
              <EnvelopeIcon class="h-4 w-4" />
              {{ parent.email ?? '--' }}
            </p>
            <p class="flex items-center gap-x-1 text-slate-500">
              <PhoneIcon class="h-4 w-4" />
              {{ parent.cell_phone ?? '--' }}
            </p>
          </div>

          <IconButton
            :size="ButtonSize.sm"
            :variant="ButtonVariant.invisible"
            :color="ButtonColor.danger"
            :ariaLabel="`Remove ${parent.name} as parent/guardian`"
            :icon="TrashIcon"
            @click.prevent="handleRemoveParentClick"
          />
        </RouterLink>
      </div>
    </div>
  </Transition>
  <DangerDialog
    title="Remove Parent"
    message="Are you sure you want to remove this parent/guardian?"
    confirmButtonLabel="Remove parent/guardian"
    :isOpen="isDialogOpen"
    :isLoading="deleteForm.processing"
    @onConfirm="handleRemoveParentConfirm"
    @onCancel="toggleDialog"
    @onClose="toggleDialog"
  />
</template>

<style>
.collapse-leave-active {
  transition: all 400ms ease-in-out;
  overflow: hidden;
}

.collapse-leave-to {
  opacity: 0;
}
</style>
