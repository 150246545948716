<script setup lang="ts">
import type { PrepareFormValues } from './useUploadSessionFormWizard';

import { debounce } from 'lodash';
import pluralize from 'pluralize';

import { useFilters } from '@/hooks/useFilters';
import { ButtonColor, ButtonSize, ButtonVariant } from '@/hooks/useButtonClasses';

import Button from '@/components/button/Button.vue';
import Distiller from '@/components/distiller/Distiller.vue';
import DistillerEmptyState from '@/components/distiller/DistillerEmptyState.vue';
import FilterProvider from '@/components/distiller/FilterProvider.vue';

import SpinnerIcon from '@/icons/line/spinner.svg';

const props = defineProps<{
  uploadType: App.Bulkinator.Data.UploadTypeData;
  distiller: App.Search.Data.DistillerData;
  form: ReturnType<typeof useForm<PrepareFormValues>>;
}>();
const emit = defineEmits<{ cancel: [void]; submit: [void] }>();

const {
  filteredRecordsCount,
  formattedFilteredRecordsCount,
  getRecordCount,
  isLoadingRecordCount
} = useFilters();

const refreshRecordCount = debounce(() => {
  getRecordCount(props.distiller, props.form.fields.filters);
}, 350);

watch(() => props.form.fields.filters, refreshRecordCount, { deep: true });

const description = computed(() => {
  return 'Apply filters to limit which records are included in the upload.';
});

const hasAppliedFilter = computed(
  () => props.form.fields.filters && Object.values(props.form.fields.filters).some((v) => !!v)
);

const addingNewFilter = ref(false);
</script>

<template>
  <form @submit.prevent="emit('submit')" class="flex flex-col gap-y-3">
    <div class="space-y-2 px-5 py-6">
      <p class="text-2xs font-bold uppercase leading-3 text-zinc-500">Filters</p>
      <FilterProvider v-model="form.fields.filters">
        <DistillerEmptyState
          :description="description"
          :addingNewFilter="addingNewFilter"
          @update:addingNewFilter="addingNewFilter = $event"
        >
          <Distiller
            :distiller="distiller"
            title="Filters"
            v-model:addingNewFilter="addingNewFilter"
          />
        </DistillerEmptyState>
      </FilterProvider>
      <div class="flex items-center gap-2 pt-2 text-sm text-zinc-700">
        The selected filters will create an upload session with
        <SpinnerIcon v-if="isLoadingRecordCount" class="h-4 w-4 animate-spin text-secondary-900" />
        <template v-else>
          {{ formattedFilteredRecordsCount }}
          {{ pluralize(distiller.name?.label ?? 'Record', filteredRecordsCount?.count) }}
        </template>
        .
      </div>
    </div>

    <section class="flex flex-row justify-end gap-x-2 px-5 py-2.5">
      <Button
        :color="ButtonColor.slate"
        :size="ButtonSize.sm"
        :variant="ButtonVariant.outlined"
        type="button"
        @click="$emit('cancel')"
        >Previous</Button
      >
      <Button
        :color="ButtonColor.primary"
        :disabled="!hasAppliedFilter"
        :size="ButtonSize.sm"
        :variant="ButtonVariant.solid"
        type="submit"
        >Continue</Button
      >
    </section>
  </form>
</template>
