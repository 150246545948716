<script setup lang="ts">
import { CssClass } from '@/@types/global';
import type { TextInputProps } from '@/components/textInput/TextInputProps';
import VueDatePicker, { DatePickerInstance, VueDatePickerProps } from '@vuepic/vue-datepicker';

import TextInput from '@/components/textInput/TextInput.vue';
import Calendar from '@/icons/line/calendar.svg?component';
import '@vuepic/vue-datepicker/dist/main.css';
import { format as formatDate } from 'date-fns-tz';
import parseISO from 'date-fns/parseISO';
import parse from 'date-fns/parse';

type CustomProps = {
  autoApply?: boolean;
  class?: CssClass;
  modelValue?: string | null;
  format?: string;
  outputFormat?: string;
  isSuccessful?: boolean;
  isLoading?: boolean;
  isDisabled?: boolean;
  isReadonly?: boolean;
  inputClasses?: CssClass;
};
export type DateInputProps = Omit<TextInputProps, 'type'> & VueDatePickerProps & CustomProps;

const props = withDefaults(defineProps<DateInputProps>(), {
  autoApply: true,
  format: 'MM/dd/yyyy',
  inline: false,
  isLoading: false,
  outputFormat: 'yyyy-MM-dd'
});

const datePickerLibraryProps = computed(() => {
  const libKeys = Object.keys(VueDatePicker.props);

  return Object.keys(props).reduce((acc, key) => {
    if (libKeys.includes(key)) {
      acc[key] = props[key];
    }
    return acc;
  }, {});
});

const datePicker = ref<DatePickerInstance | null>(null);

const value = computed(() => (props.modelValue ? parseISO(props.modelValue) : null));

const emit = defineEmits<{
  'update:modelValue': [value: string | null]; // yyyy-MM-dd format
  blur: [];
}>();

function onValueChange(date: Date): void {
  if (!date) {
    emit('update:modelValue', null);
    return;
  }

  emit('update:modelValue', formatDate(date, props.outputFormat));
}

function onInputUpdate(val: string): void {
  try {
    const parsedValue = parse(val, props.format, new Date());
    if (props.format.length === val.length && parsedValue.toString() !== 'Invalid Date') {
      emit('update:modelValue', val);
    }
  } catch (e) {
    // no op
  }
}
</script>

<template>
  <VueDatePicker
    ref="datePicker"
    v-bind="datePickerLibraryProps"
    :autoApply="autoApply"
    :format="format"
    :disabled="isDisabled || isReadonly"
    :enableTimePicker="false"
    :modelValue="value"
    :teleport="true"
    @update:modelValue="onValueChange"
  >
    <template #dp-input="{ value }">
      <TextInput
        :hasError="hasError"
        :isDisabled="isDisabled"
        :isReadonly="isReadonly"
        :iconLeft="Calendar"
        :id="id"
        :modelValue="value"
        :name="name"
        :placeholder="placeholder"
        :variant="variant"
        :inputClasses="inputClasses"
        :isSuccessful="isSuccessful"
        :isLoading="isLoading"
        :class="$attrs.class"
        @update:modelValue="onInputUpdate"
        @blur="$emit('blur')"
      />
    </template>
  </VueDatePicker>
</template>

<style>
:root {
  --dp-font-family: theme(fontFamily.sans);
  --dp-cell-border-radius: 100%;
}

.dp__theme_light {
  --dp-primary-color: theme('colors.zinc.900');
}

button.dp__action_button.dp__action_select {
  @apply bg-slate-900;
}
</style>
