<script setup lang="ts">
import type { Selectable } from '@/components/selectBox/selectBox';

import { bulkCreate } from '@/api/ScholarshipApi';

import Dialog from '@/components/dialog/Dialog.vue';
import Fieldset from '@/components/fieldset/Fieldset.vue';
import FormMultiStudentInput from '@/components/formComboboxInput/FormMultiStudentInput.vue';
import FormRows from '@/components/formRows/FormRows.vue';
import axios from 'axios';

defineProps<{
  isOpen: boolean;
}>();

const emit = defineEmits<{
  onCancel: [void];
  onClose: [void];
}>();

const isLoading = ref(false);
const selectedStudentIds = ref<Selectable<number>[]>([]);

interface ValidationErrors {
  [key: string]: string[];
}
const errors: Ref<ValidationErrors> = ref({});

async function handleConfirm() {
  isLoading.value = true;

  try {
    const response = await bulkCreate({
      ids: selectedStudentIds.value.map((student) => student.value) ?? [],
      filters: null,
      all: false
    });

    isLoading.value = false;
    emit('onClose');
    window.open(response.session_url, '_blank', 'noopener,noreferrer');
    errors.value = {};
  } catch (error: unknown) {
    isLoading.value = false;

    if (axios.isAxiosError(error)) {
      errors.value = error.response?.data?.errors || {};
    }
  }
}
</script>

<template>
  <Dialog
    :isOpen="isOpen"
    :isLoading="isLoading"
    confirmButtonLabel="Add scholarship"
    description="Add scholarship for all selected students using bulkinator"
    title="Add Scholarship"
    @onConfirm="handleConfirm"
    @onClose="emit('onClose')"
    @onCancel="emit('onCancel')"
  >
    <FormRows>
      <Fieldset title="Selected students">
        <FormMultiStudentInput
          :error="errors?.ids?.[0]"
          :modelValue="selectedStudentIds"
          label="Student"
          placeholder="Select student"
          @update:modelValue="(value) => (selectedStudentIds = value)"
        />
      </Fieldset>
    </FormRows>
  </Dialog>
</template>
