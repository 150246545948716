<script setup lang="ts">
import CenteredContent from '@/components/content/CenteredContent.vue';
import SchoolForm from '@/components/schools/SchoolForm.vue';
import { Selectable } from '@/components/selectBox/selectBox';

defineProps<{
  returnUrl?: string;
}>();

const form = useForm({
  method: 'POST',
  url: route('schools.store'),
  fields: {
    name: '',
    district: '',
    program_id: {} as Selectable<number>,
    adviser_ids: [] as Selectable<number>[],
    address: '',
    state: {} as Selectable<string>,
    city: '',
    zip_code: '',
    is_served: false
  },
  transform: (fields) => ({
    ...fields,
    program_id: fields.program_id?.value ?? null,
    adviser_ids: fields.adviser_ids?.map((adviser) => adviser.value) ?? [],
    state: fields.state?.value ?? null
  })
});
</script>


					<script lang="ts">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <CenteredContent>
    <SchoolForm :form="form" :returnUrl="returnUrl" />
  </CenteredContent>
</template>
