<script setup lang="tsx">
import type { CustomCells, HybridlyRow } from '@/components/table/Table';

import { ButtonColor, ButtonSize, ButtonVariant } from '@/hooks/useButtonClasses';
import { useAuth } from '@/hooks/useAuth';
import { useFilters } from '@/hooks/useFilters';
import { useHybridlyTable } from '@/hooks/useHybridlyTable';
import { useTableRowClick } from '@/hooks/useTableRowClick';

import Button from '@/components/button/Button.vue';
import EmptyState from '@/components/emptyState/EmptyState.vue';
import IndexPageLayout from '@/components/pageLayout/IndexPageLayout.vue';
import TableBadgesCell from '@/components/table/TableBadgesCell.vue';
import TableBooleanCell from '@/components/table/TableBooleanCell.vue';
import TableDateTimeCell from '@/components/table/TableDateTimeCell.vue';
import TableEnumCell from '@/components/table/TableEnumCell.vue';
import TabNavDivider from '@/components/tabNav/TabNavDivider.vue';
import TabNavItem from '@/components/tabNav/TabNavItem.vue';
import { AppliedFilters } from '@/components/distiller/types';

import FilesLandscapeAlt from '@/icons/line/files-landscapes-alt.svg';
import Plus from '@/icons/line/plus.svg';
import BulkRestoreButton from '@/components/table/BulkRestoreButton.vue';
import BulkTrashButton from '@/components/table/BulkTrashButton.vue';
import TableTruncatedTextCell from '@/components/table/TableTruncatedTextCell.vue';

type ProgramStudentFieldRow = HybridlyRow<App.Configuration.Data.ProgramStudentFieldData>;

const props = defineProps<{
  appliedFilters: AppliedFilters;
  distiller: App.Search.Data.DistillerData;
  trashActive: boolean;
  counts: {
    active_count: number;
    trashed_count: number;
  };
  programStudentFields: Table<App.Configuration.Data.ProgramStudentFieldData>;
}>();

const { hasPermission } = useAuth();

const { table, tableSelectionState } = useHybridlyTable<App.Configuration.Data.InstituteData>(
  props,
  'programStudentFields'
);
const { handleRowClick } = useTableRowClick();

const { encodeFilters } = useFilters();

const encodedFilters = computed(() => encodeFilters(props.appliedFilters));

const customCells = {
  field_label: ({ row, column }) => <TableTruncatedTextCell text={row.value(column.key)} />,
  enabled: ({ row, column }) => <TableBooleanCell boolean={row.value(column.key) ?? false} />,
  in_results: ({ row, column }) => <TableBooleanCell boolean={row.value(column.key) ?? false} />,
  program: ({ row, column }) => (
    <TableBadgesCell
      badgesToDisplay={1}
      itemIcon={FilesLandscapeAlt}
      items={[row.value(column.key).display_name]}
    />
  ),
  type: ({ row, column }) => <TableEnumCell enumData={row.value(column.key)} />,
  readonly: ({ row, column }) => <TableBooleanCell boolean={row.value(column.key) ?? false} />,
  created_at: ({ row, column }) => <TableDateTimeCell date={row.value(column.key)} />
} as CustomCells<App.Configuration.Data.ProgramStudentFieldData>;

function handleProgramStudentFieldRowClick(event: MouseEvent, row: ProgramStudentFieldRow) {
  const url = route('program-student-fields.show', {
    programStudentField: row.key
  });
  handleRowClick(url, event);
}
</script>


					<script lang="tsx">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <IndexPageLayout
    title="Custom Fields"
    :table="table"
    :appliedFilters="appliedFilters"
    :distiller="distiller"
    :customCells="customCells"
    :stickyEndColumns="1"
    :stickyStartColumns="1"
    :clickableRows="!trashActive"
    :tableSelectionState="tableSelectionState"
    @clickRow="($event, row) => handleProgramStudentFieldRowClick($event, row as ProgramStudentFieldRow)"
  >
    <template #rightButtons>
      <template v-if="tableSelectionState.hasSelection">
        <BulkTrashButton
          v-if="!trashActive"
          recordType="Custom Fields"
          :route="route('program-student-fields.bulk-delete')"
          :tableSelectionState="tableSelectionState"
        />

        <BulkRestoreButton
          v-if="trashActive"
          recordType="Custom Fields"
          :route="route('program-student-fields.bulk-restore')"
          :tableSelectionState="tableSelectionState"
        />
      </template>

      <template v-else>
        <Button
          v-if="hasPermission('create-program-student-field')"
          :color="ButtonColor.primary"
          :variant="ButtonVariant.solid"
          :size="ButtonSize.sm"
          :iconLeft="Plus"
          :href="route('program-student-fields.create')"
        >
          Add a custom field
        </Button>
      </template>
    </template>

    <template #tabs>
      <TabNavItem
        :badgeLabel="counts.active_count"
        :isActive="!trashActive"
        label="Active"
        variant="inline"
        :href="
          route('program-student-fields.index', {
            ...(encodedFilters ? { filters: encodedFilters } : {})
          })
        "
      />
      <TabNavDivider layout="inline" />
      <TabNavItem
        :badgeLabel="counts.trashed_count ?? 0"
        :isActive="trashActive"
        label="Trashed"
        variant="inline"
        :href="
          route('program-student-fields.index', {
            trashed: true,
            ...(encodedFilters ? { filters: encodedFilters } : {})
          })
        "
      />
    </template>

    <template #emptyState>
      <EmptyState
        v-if="trashActive"
        title="No custom fields were moved to the trash"
        description="Trashed custom fields will appear on this screen"
      />
      <EmptyState
        v-else
        title="No custom fields found"
        :description="
          hasPermission('create-program-student-field')
            ? 'Create a new custom field by clicking the button below'
            : ''
        "
        :actionLabel="hasPermission('create-program-student-field') ? 'Add a custom field' : ''"
        :actionHref="route('program-student-fields.create')"
      />
    </template>
  </IndexPageLayout>
</template>
