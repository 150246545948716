<script setup lang="ts">
type ThreadData = App.Sms.Data.ThreadData;
type RelatedThreadData = App.Sms.Data.RelatedThreadData;
import type { WithLoadedRelations } from '@/@types/global';

import { ButtonColor, ButtonSize, ButtonVariant } from '@/hooks/useButtonClasses';

import Button from '@/components/button/Button.vue';
import ShowStudentPageLayout from '@/components/students/ShowStudentPageLayout.vue';
import ThreadWithRelated from '@/components/sms/ThreadWithRelated.vue';

import CommentsAltIcon from '@/icons/line/comments-alt.svg';
import ExpandAltIcon from '@/icons/line/expand-alt.svg';

defineProps<{
  hasSmsEnabled: boolean;
  student: App.Students.Data.StudentData;
  thread: WithLoadedRelations<ThreadData, 'recipient'>;
  relatedThreads: RelatedThreadData[];
}>();
</script>


					<script lang="ts">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <ShowStudentPageLayout :student="student" :title="student.name">
    <template #constrained>
      <div class="flex items-center justify-between border-b px-3 py-3">
        <div class="flex items-center gap-1">
          <CommentsAltIcon class="h-4 w-4 text-zinc-500" />
          <h2 class="text-base font-semibold leading-6 text-black">SMS Thread</h2>
        </div>

        <Button
          :color="ButtonColor.slate"
          :href="route('sms.threads.show', { thread: thread.id })"
          :iconRight="ExpandAltIcon"
          :size="ButtonSize.sm"
          :variant="ButtonVariant.invisible"
        >
          View in inbox
        </Button>
      </div>
      <ThreadWithRelated
        :hasSmsEnabled="hasSmsEnabled"
        :thread="thread"
        :relatedThreads="relatedThreads"
        :showRecipientHeader="false"
      />
    </template>
  </ShowStudentPageLayout>
</template>
