<script setup lang="ts" generic="T extends SelectableValue">
import { ButtonColor, ButtonSize, ButtonVariant } from '@/hooks/useButtonClasses';

import { AppliedFilters } from '@/components/distiller/types';
import CreateFilterTemplateDialog from '@/components/filters/filterTemplate/CreateFilterTemplateDialog.vue';
import { Selectable, SelectableValue } from '@/components/selectBox/selectBox';
import FilterTemplateSelector from './FilterTemplateSelector.vue';
import Button from '@/components/button/Button.vue';

import IconSave from '@/icons/line/save.svg';

const props = defineProps<{
  distiller: App.Search.Data.DistillerData;
  title: string;
  appliedFilters: AppliedFilters;
}>();

const emit = defineEmits<{
  'update:filterTemplate': [filterTemplate: App.Search.Data.FilterTemplateData | null];
}>();

const isCreateFilterTemplateDialogOpen = ref(false);

const hasAppliedFilters = computed(() => {
  return Object.keys(props.appliedFilters).length > 0;
});

const templateOptions = computed(() => {
  return (
    props.distiller.filterTemplates?.map((template) => ({
      label: template.name,
      value: template.id
    })) ?? ([] as Selectable<number>[])
  );
});

function handleFilterTemplateChange(value: Selectable<number> | null) {
  if (!value) {
    emit('update:filterTemplate', null);
    return;
  }

  const selectedTemplate = props.distiller.filterTemplates?.find(
    (template) => template.id === value.value
  );
  if (!selectedTemplate) {
    return;
  }

  emit('update:filterTemplate', selectedTemplate);
}
</script>

<template>
  <div class="flex items-center gap-2">
    <FilterTemplateSelector
      v-if="templateOptions.length > 0"
      :modelValue="null"
      :hasFilters="hasAppliedFilters"
      :options="templateOptions"
      @update:modelValue="handleFilterTemplateChange"
    />
    <Button
      v-if="hasAppliedFilters"
      :size="ButtonSize.sm"
      :variant="ButtonVariant.outlined"
      :color="ButtonColor.primary"
      :iconLeft="IconSave"
      @click="isCreateFilterTemplateDialogOpen = true"
      >Save template</Button
    >

    <CreateFilterTemplateDialog
      :isOpen="isCreateFilterTemplateDialogOpen"
      :subject="distiller.name?.value as string"
      :filters="appliedFilters"
      :templates="templateOptions"
      @onClose="isCreateFilterTemplateDialogOpen = false"
      @onCancel="isCreateFilterTemplateDialogOpen = false"
      @onSuccess="isCreateFilterTemplateDialogOpen = false"
    />
  </div>
</template>
