<script setup lang="tsx">
import { debounce } from 'lodash';

import { useAuth } from '@/hooks/useAuth';
import { ButtonColor, ButtonVariant } from '@/hooks/useButtonClasses';

import Button from '@/components/button/Button.vue';
import PageHeader from '@/components/pageHeader/PageHeader.vue';
import SearchInput from '@/components/searchInput/SearchInput.vue';
import TabNav from '@/components/tabNav/TabNav.vue';
import TabNavItem from '@/components/tabNav/TabNavItem.vue';
import TabNavDivider from '@/components/tabNav/TabNavDivider.vue';
import ReportTemplateList from '@/domains/reports/components/reportTemplate/ReportTemplateList.vue';
import EmptyState from '@/components/emptyState/EmptyState.vue';

import PlusIcon from '@/icons/line/plus.svg';

type ReportTemplateData = App.Reports.Data.ReportTemplateData;

const props = defineProps<{
  templates: ReportTemplateData[];
  status: string | null;
  counts: {
    myTemplates: number;
    sharedTemplates: number;
    systemTemplates: number;
  };
  favoriteTemplateIds: number[];
  search?: string | null;
}>();

const { user } = useAuth();

const searchQuery = ref(props.search);
const isSearching = ref(false);

const groupedTemplates = computed(() => {
  return props.templates.reduce(
    (acc, template) => {
      if (props.favoriteTemplateIds.includes(template.id)) {
        acc.favorites.push(template);
      }

      if (template.is_system_template) {
        acc.systemTemplates.push(template);
      } else if (template.user?.id !== user.value?.id && !template.is_system_template) {
        acc.sharedTemplates.push(template);
      } else {
        acc.myTemplates.push(template);
      }

      return acc;
    },
    {
      favorites: [] as ReportTemplateData[],
      myTemplates: [] as ReportTemplateData[],
      sharedTemplates: [] as ReportTemplateData[],
      systemTemplates: [] as ReportTemplateData[]
    }
  );
});

const emptyTemplatesDescription = computed(() => {
  return (
    {
      'my-templates': 'Create a new report or search for existing templates.',
      'shared-templates': 'No templates have been shared with you yet.',
      system: 'No system templates found.',
      all: 'We cannot find any report templates for you.'
    }[props.status ?? 'all'] ?? 'No templates found.'
  );
});

const performSearchRequest = debounce(() => {
  router.reload({
    data: {
      search: searchQuery.value
    },
    only: ['templates', 'counts'],
    hooks: {
      success() {
        isSearching.value = false;
      }
    }
  });
}, 400);

function handleSearchQueryChange(value: string) {
  isSearching.value = true;
  searchQuery.value = value || undefined;
  performSearchRequest();
}
</script>


					<script lang="tsx">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <div class="divide-y-slate-200 flex h-full flex-col divide-y">
    <PageHeader title="Reports">
      <template #leftButtons>
        <SearchInput
          class="w-72"
          placeholder="Search templates..."
          :isLoading="isSearching"
          :modelValue="searchQuery"
          @update:modelValue="handleSearchQueryChange"
        />
      </template>
      <template #rightButtons>
        <Button
          :href="route('custom-reports.types.index')"
          :iconLeft="PlusIcon"
          :color="ButtonColor.primary"
          :variant="ButtonVariant.solid"
          >New report</Button
        >
      </template>
    </PageHeader>

    <TabNav class="-mb-px" layout="inline">
      <TabNavItem
        variant="inline"
        :href="route('report-templates.index')"
        :isActive="status === null"
        label="All"
        :badgeLabel="counts.myTemplates + counts.systemTemplates + counts.sharedTemplates"
      />
      <TabNavDivider layout="inline" />
      <TabNavItem
        variant="inline"
        :href="route('report-templates.my-templates.index', { search: searchQuery })"
        :isActive="status === 'my-templates'"
        label="My templates"
        :badgeLabel="counts.myTemplates"
      />
      <TabNavItem
        variant="inline"
        :href="route('report-templates.shared-templates.index', { search: searchQuery })"
        :isActive="status === 'shared-templates'"
        label="Shared templates"
        :badgeLabel="counts.sharedTemplates"
      />
      <TabNavItem
        variant="inline"
        :href="route('report-templates.system.index', { search: searchQuery })"
        :isActive="status === 'system'"
        label="System templates"
        :badgeLabel="counts.systemTemplates"
      />
    </TabNav>

    <div v-if="templates.length > 0" class="space-y-4 p-4">
      <ReportTemplateList
        v-if="groupedTemplates.favorites.length > 0"
        title="Favorites"
        :templates="groupedTemplates.favorites"
        :favoriteTemplateIds="favoriteTemplateIds"
      />
      <ReportTemplateList
        v-if="groupedTemplates.myTemplates.length > 0"
        title="My Templates"
        :templates="groupedTemplates.myTemplates"
        :favoriteTemplateIds="favoriteTemplateIds"
      />
      <ReportTemplateList
        v-if="groupedTemplates.systemTemplates.length > 0"
        isOpen
        title="System Templates"
        :templates="groupedTemplates.systemTemplates"
        :favoriteTemplateIds="favoriteTemplateIds"
      />
      <ReportTemplateList
        v-if="groupedTemplates.sharedTemplates.length > 0"
        title="Shared With Me"
        :templates="groupedTemplates.sharedTemplates"
        :favoriteTemplateIds="favoriteTemplateIds"
      />
    </div>

    <div v-else class="pt-16">
      <EmptyState title="No templates found" :description="emptyTemplatesDescription" />
    </div>
  </div>
</template>
