<script setup lang="ts">
import MultiComboboxInput from '@/components/selectBox/combobox/MultiComboboxInput.vue';
import { Selectable } from '@/components/selectBox/selectBox';
import { useInstitutesSearch } from '@/hooks/search/useInstitutesSearch';

defineProps<{
  modelValue: Selectable<number>[];
}>();

defineEmits(['update:modelValue']);

const { instituteOptions, isLoading, onQueryChange } = useInstitutesSearch();
</script>

<template>
  <MultiComboboxInput
    :options="instituteOptions"
    :onFilter="onQueryChange"
    :isLoading="isLoading"
    :modelValue="modelValue"
    @update:modelValue="$emit('update:modelValue', $event)"
    @open="() => onQueryChange('')"
  />
</template>
