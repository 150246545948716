<script setup lang="ts">
import type { ShowInstituteProps } from '@/domains/configuration/views/institutes/ShowInstitute.vue';

import { useDialogHelpers } from '@/hooks/useDialogHelpers';

import { formatCurrency } from '@/utils/currency';

import BadgeBooleanList from '@/components/badge/BadgeBooleanList.vue';
import DangerDialog from '@/components/dialog/DangerDialog.vue';
import DefinitionList from '@/components/definitionList/DefinitionList.vue';
import DefinitionListItem from '@/components/definitionList/DefinitionListItem.vue';
import IconButton from '@/components/button/IconButton.vue';
import InformationCollapsible from '@/components/collapsible/InformationCollapsible.vue';
import Slideout from '@/components/slideout/Slideout.vue';

import TrashIcon from '@/icons/line/trash.svg';
import Clock from '@/icons/line/clock.svg';
import EditAlt from '@/icons/line/edit-alt.svg';
import Filter from '@/icons/line/filter.svg';
import LocationPinAlt from '@/icons/line/location-pin-alt.svg';
import University from '@/icons/line/university.svg';
import InfoCircle from '@/icons/line/info-circle.svg';
import DollarAlt from '@/icons/line/dollar-alt.svg';
import QuestionCircle from '@/icons/line/question-circle.svg';

const props = defineProps<ShowInstituteProps>();

defineOptions({
  inheritAttrs: false
});

const { show, close } = useDialog();
const { isDialogOpen, toggleDialog } = useDialogHelpers();

const form = useForm({
  method: 'DELETE',
  spoof: false,
  url: route('institutes.delete', { institute: props.institute.id }),
  fields: {},
  hooks: {
    success() {
      close();
    }
  }
});

async function handleInstituteDelete() {
  await form.submit();
  toggleDialog();
}
</script>

<template>
  <Slideout :title="institute.name" :isOpen="show" @onClose="close" :icon="University">
    <template #actions>
      <IconButton
        v-if="institute.authorization.update"
        :href="route('institutes.edit', { institute: institute.id })"
        :icon="EditAlt"
        ariaLabel="Edit institution"
        size="sm"
        variant="soft"
      />
      <IconButton
        v-if="institute.authorization.delete"
        color="danger"
        :icon="TrashIcon"
        ariaLabel="Move institution to trash"
        size="sm"
        variant="soft"
        @click="toggleDialog"
      />
    </template>
    <InformationCollapsible title="Institution Information">
      <div class="flex flex-col gap-4">
        <DefinitionList>
          <DefinitionListItem
            :description="institute.selectivity?.label ?? 'No selectivity'"
            :icon="Filter"
            title="Selectivity"
          />
        </DefinitionList>
        <DefinitionList>
          <DefinitionListItem
            :description="institute.program_length?.label ?? 'No program length'"
            :icon="Clock"
            title="Prog. Length"
          />
        </DefinitionList>
        <DefinitionList>
          <DefinitionListItem
            :description="institute.ipeds ?? '--'"
            :icon="InfoCircle"
            title="IPEDS Code"
          />
        </DefinitionList>
        <DefinitionList>
          <DefinitionListItem
            :description="institute.address ?? 'No address'"
            :icon="LocationPinAlt"
            title="Address"
          />
          <DefinitionListItem :description="institute.city ?? 'No city'" title="City" />
          <DefinitionListItem :description="institute.state ?? 'No state'" title="State" />
          <DefinitionListItem :description="institute.country ?? 'No country'" title="Country" />
          <DefinitionListItem :description="institute.zip_code ?? 'No ZIP Code'" title="ZIP Code" />
        </DefinitionList>
        <DefinitionList>
          <DefinitionListItem :icon="QuestionCircle" title="Other">
            <BadgeBooleanList
              :items="{
                HBCU: !!institute.hbcu,
                'CSS Profile Required': !!institute.css_required
              }"
            />
          </DefinitionListItem>
        </DefinitionList>
        <DefinitionList>
          <DefinitionListItem
            :description="institute.waiver_fee ? formatCurrency(institute.waiver_fee) : '--'"
            :icon="DollarAlt"
            title="Waiver fees"
          />
        </DefinitionList>
      </div>
    </InformationCollapsible>
  </Slideout>
  <DangerDialog
    title="Move Institution to Trash"
    message="Are you sure you want to move this institution to the trash?"
    confirmButtonLabel="Move to trash"
    :isOpen="isDialogOpen"
    :isLoading="form.processing"
    @onConfirm="handleInstituteDelete"
    @onCancel="toggleDialog"
    @onClose="toggleDialog"
  />
</template>
