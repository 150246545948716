<script setup lang="ts">
import type { HybridlyFormData } from '@/@types/global';
import type { Selectable } from '@/components/selectBox/selectBox';

import { ButtonColor, ButtonVariant } from '@/hooks/useButtonClasses';

import Button from '@/components/button/Button.vue';
import DangerDialog from '@/components/dialog/DangerDialog.vue';
import Fieldset from '@/components//fieldset/Fieldset.vue';
import Form from '@/components/form/Form.vue';
import FormCheckboxInput from '@/components/formCheckboxInput/FormCheckboxInput.vue';
import FormComboboxInput from '@/components/formComboboxInput/FormComboboxInput.vue';
import FormFooter from '@/components/formFooter/FormFooter.vue';
import FormHeader from '@/components/formHeader/FormHeader.vue';
import FormInlineFields from '@/components/formInlineFields/FormInlineFields.vue';
import FormMultiUserInput from '@/components/formComboboxInput/FormMultiUserInput.vue';
import FormProgramInput from '@/components/formComboboxInput/FormProgramInput.vue';
import FormTextInput from '@/components/formTextInput/FormTextInput.vue';

const props = defineProps<{
  form: HybridlyFormData<{
    name: string;
    district: string;
    program_id: string;
    adviser_ids: Selectable<number>[];
    address: string;
    state: string;
    city: string;
    zip_code: string;
    is_served: boolean;
  }>;
  isEditing?: boolean;
  returnUrl?: string;
}>();

const isCancelDialogOpen = ref(false);

const { onBackForward } = useBackForward();

// TODO: Fix intecepting the back button
onBackForward(() => {
  router.abort();
});

function handleToggleCancelDialog() {
  if (props.form.isDirty) {
    isCancelDialogOpen.value = !isCancelDialogOpen.value;
    return;
  }
  handleRedirect();
}

function handleRedirect() {
  router.navigate({ url: props.returnUrl || route('schools.index') });
}
</script>

<template>
  <Form @submit.prevent="form.submit">
    <template #header>
      <FormHeader
        canClose
        :title="`${isEditing ? 'Edit' : 'Add'} a School`"
        :description="
          isEditing
            ? 'Use the form below to edit the school.'
            : 'Fill out the form below add a new school.'
        "
        @onClose="handleToggleCancelDialog"
      />
    </template>
    <Fieldset title="General information">
      <FormTextInput
        :error="form.errors.name"
        :modelValue="form.fields.name"
        label="School name"
        name="name"
        type="text"
        @update:modelValue="(value) => (form.fields.name = value)"
      />
      <FormTextInput
        :error="form.errors.district"
        :modelValue="form.fields.district"
        label="District"
        name="district"
        type="text"
        @update:modelValue="(value) => (form.fields.district = value)"
      />
      <FormProgramInput
        label="Program"
        :error="form.errors.program_id"
        :modelValue="form.fields.program_id"
        @update:modelValue="(value) => (form.fields.program_id = value)"
      />
      <FormMultiUserInput
        label="Advisers (optional)"
        helperText="Selected advisers will be able to see students that are enrolled in the school"
        :error="form.errors.adviser_ids"
        :modelValue="form.fields.adviser_ids"
        :searchFilters="{ role: 'adviser' }"
        @update:modelValue="(value) => (form.fields.adviser_ids = value)"
      />
    </Fieldset>
    <Fieldset title="School address">
      <FormTextInput
        :error="form.errors.address"
        :modelValue="form.fields.address"
        label="Address"
        name="address"
        type="text"
        @update:modelValue="(value) => (form.fields.address = value)"
      />
      <FormComboboxInput
        :error="form.errors.state"
        :modelValue="form.fields.state"
        :options="stateOptions()"
        label="State"
        @update:modelValue="(value) => (form.fields.state = value)"
      />
      <FormInlineFields>
        <FormTextInput
          :error="form.errors.city"
          :modelValue="form.fields.city"
          label="City"
          name="city"
          type="text"
          @update:modelValue="(value) => (form.fields.city = value)"
        />
        <FormTextInput
          :error="form.errors.zip_code"
          :modelValue="form.fields.zip_code"
          label="Zip code"
          name="zip_code"
          type="text"
          @update:modelValue="(value) => (form.fields.zip_code = value)"
        />
      </FormInlineFields>
    </Fieldset>
    <Fieldset title="Other settings">
      <FormCheckboxInput
        :checked="form.fields.is_served"
        :error="form.errors.is_served"
        helperText="Is this school actively served by CAC?"
        label="Served by CAC"
        name="is_served"
        @update:checked="(value) => (form.fields.is_served = value)"
      />
    </Fieldset>
    <template #footer>
      <FormFooter>
        <Button
          type="submit"
          :color="ButtonColor.primary"
          :isDisabled="form.processing || !form.isDirty"
          :isLoading="form.processing"
          :variant="ButtonVariant.solid"
          >Confirm</Button
        >
        <Button
          :color="ButtonColor.slate"
          :variant="ButtonVariant.outlined"
          type="button"
          @click="handleToggleCancelDialog"
        >
          Back to schools
        </Button>
      </FormFooter>
    </template>
  </Form>

  <DangerDialog
    :title="`Cancel ${isEditing ? 'editing' : 'adding a new'} school`"
    :message="`Are you sure you want to cancel ${
      isEditing ? 'editing' : 'adding'
    } this school? All ${isEditing ? 'changes' : 'information'} will be lost.`"
    :isOpen="isCancelDialogOpen"
    cancelButtonLabel="Stay on this page"
    confirmButtonLabel="Go back to schools"
    @onCancel="handleToggleCancelDialog"
    @onClose="handleToggleCancelDialog"
    @onConfirm="handleRedirect"
  />
</template>
