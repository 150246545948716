<script lang="ts" setup>
import { ComboboxInput } from 'radix-vue';

import IconButton from '@/components/button/IconButton.vue';

import SearchIcon from '@/icons/line/search.svg';
import SpinnerIcon from '@/icons/line/spinner.svg';
import TimesIcon from '@/icons/line/times.svg';

const props = defineProps<{
  isLoading?: boolean;
  searchQuery?: string;
  isTouchPointer?: boolean;
}>();

const emit = defineEmits<{
  searchQuery: [query: string];
}>();

const iconComponent = computed(() => (props.isLoading ? SpinnerIcon : SearchIcon));

const searchInputElement = useTemplateRef<InstanceType<typeof ComboboxInput>>('searchInputElement');

defineExpose({ searchInputElement });

/**
 * The disabled input hack is necessary to prevent the keyboard from
 * opening on touch devices. Radix automatically focuses the input
 * when the combobox open state is toggled.
 */
const isDisabledFocusHack = ref<boolean>(props.isTouchPointer ?? false);
onMounted(() => setTimeout(() => (isDisabledFocusHack.value = false), 1));

function handleClearSearch() {
  emit('searchQuery', '');
  searchInputElement.value?.$el.focus();
}
</script>

<template>
  <div class="sticky top-0 rounded-t-lg border-x-0 border-b border-t-0 border-slate-200">
    <div class="relative flex items-center pl-4">
      <component
        :is="iconComponent"
        class="h-4 w-4 shrink-0 text-slate-500"
        :class="{
          'animate-spin': isLoading
        }"
      />
      <ComboboxInput
        ref="searchInputElement"
        placeholder="Search..."
        autocomplete="off"
        :disabled="isDisabledFocusHack"
        class="w-full flex-1 rounded-t-lg border-0 px-3 py-3.5 pr-0 !leading-none placeholder-slate-400 !outline-0 !ring-0 md:block md:text-sm"
        :value="searchQuery"
        @input="(e) => $emit('searchQuery', e.target.value)"
      />
      <IconButton
        v-if="searchQuery"
        :icon="TimesIcon"
        ariaLabel="Clear search"
        class="pr-1"
        size="sm"
        variant="invisible"
        @click="handleClearSearch"
      />
    </div>
  </div>
</template>
