<script setup lang="ts">
import type { ShowCollegeApplicationProps } from '@/domains/students/views/ShowCollegeApplication.vue';

import { useDialogHelpers } from '@/hooks/useDialogHelpers';

import { formatDate } from '@/utils/date';

import Badge from '@/components/badge/Badge.vue';
import BadgeBoolean from '@/components/badge/BadgeBoolean.vue';
import DangerDialog from '@/components/dialog/DangerDialog.vue';
import DefinitionList from '@/components/definitionList/DefinitionList.vue';
import DefinitionListItem from '@/components/definitionList/DefinitionListItem.vue';
import IconButton from '@/components/button/IconButton.vue';
import InformationCollapsible from '@/components/collapsible/InformationCollapsible.vue';
import Slideout from '@/components/slideout/Slideout.vue';

import TrashIcon from '@/icons/line/trash.svg';
import AwardAlt from '@/icons/line/award-alt.svg';
import EditAlt from '@/icons/line/edit-alt.svg';
import InfoCircle from '@/icons/line/info-circle.svg';
import LocationPinAlt from '@/icons/line/location-pin-alt.svg';
import University from '@/icons/line/university.svg';

const props = defineProps<ShowCollegeApplicationProps>();

const { show, close } = useDialog();
const { isDialogOpen, toggleDialog } = useDialogHelpers();

const form = useForm({
  method: 'DELETE',
  spoof: false,
  url: route('college-applications.delete', { application: props.application.id }),
  fields: {},
  hooks: {
    success() {
      close();
    }
  }
});

async function handleProgramDelete() {
  await form.submit();
  toggleDialog();
}
</script>

<template>
  <Slideout
    :title="application.institute?.name ?? 'College Application'"
    :isOpen="show"
    @onClose="close"
    :icon="University"
  >
    <template #badge>
      <Badge :label="application.status?.label ?? 'No Status'" variant="soft" shape="rounded" />
    </template>
    <template #actions>
      <IconButton
        v-if="application.authorization.update"
        :href="
          application.is_current_school_year
            ? route('students.college-applications.edit', {
                student: student.id,
                application: application.id
              })
            : undefined
        "
        :icon="EditAlt"
        :isDisabled="!application.is_current_school_year"
        ariaLabel="Edit college application"
        size="sm"
        variant="soft"
      />
      <IconButton
        v-if="application.authorization.delete"
        color="danger"
        :icon="TrashIcon"
        :isDisabled="!application.is_current_school_year"
        ariaLabel="Delete college application"
        size="sm"
        variant="soft"
        @click="toggleDialog"
      />
    </template>
    <div class="-m-4 flex flex-col divide-y">
      <div class="p-4">
        <InformationCollapsible title="General information">
          <DefinitionList>
            <DefinitionListItem
              :icon="InfoCircle"
              :description="formatDate(application.date_submitted) || 'Not submitted yet'"
              title="Application status date"
            />
            <DefinitionListItem
              :description="
                application.institute?.selectivity.label ?? 'No Selectivity Information'
              "
              title="Selectivity"
            />
            <DefinitionListItem title="Preference">
              <Badge
                :iconLeft="application.first_choice ? AwardAlt : undefined"
                :label="application.first_choice ? 'First Choice' : 'Alternative'"
                variant="soft"
                size="md"
              />
            </DefinitionListItem>
            <DefinitionListItem title="1:1 award letter meeting held">
              <BadgeBoolean :boolean="!!application.award_meeting_held" />
            </DefinitionListItem>
            <DefinitionListItem title="Early action">
              <BadgeBoolean :boolean="!!application.early_action" />
            </DefinitionListItem>
            <DefinitionListItem title="Early decision">
              <BadgeBoolean :boolean="!!application.early_decision" />
            </DefinitionListItem>
            <DefinitionListItem title="Sent HS transcript">
              <BadgeBoolean :boolean="!!application.sent_hs_transcript" />
            </DefinitionListItem>
            <DefinitionListItem title="Applied to honors program">
              <BadgeBoolean :boolean="!!application.applied_to_honors_program" />
            </DefinitionListItem>
            <DefinitionListItem title="Financial aid submitted to school">
              <BadgeBoolean :boolean="!!application.financial_aid_submitted_to_school" />
            </DefinitionListItem>
          </DefinitionList>
        </InformationCollapsible>
      </div>
      <div class="p-4">
        <InformationCollapsible title="Institution information">
          <div class="flex flex-col gap-4">
            <DefinitionList>
              <DefinitionListItem
                :description="application.institute?.name ?? 'No institution name'"
                :icon="University"
                title="Institution"
              />
              <DefinitionListItem
                :description="application.institute?.address ?? 'No address'"
                :icon="LocationPinAlt"
                title="Address"
              />
              <DefinitionListItem
                :description="application.institute?.city ?? 'No city'"
                title="City"
              />
              <DefinitionListItem
                :description="application.institute?.state ?? 'No state'"
                title="State"
              />
              <DefinitionListItem
                :description="application.institute?.zip_code ?? 'No ZIP Code'"
                title="ZIP Code"
              />
            </DefinitionList>
            <DefinitionList>
              <DefinitionListItem title="HBCU">
                <BadgeBoolean :boolean="!!application.institute?.hbcu" />
              </DefinitionListItem>
              <DefinitionListItem title="CSS required">
                <BadgeBoolean :boolean="!!application.institute?.css_required" />
              </DefinitionListItem>
              <DefinitionListItem
                title="Program length"
                :description="application.institute?.program_length.label ?? 'No program length'"
              />
            </DefinitionList>
          </div>
        </InformationCollapsible>
      </div>
    </div>
  </Slideout>
  <DangerDialog
    title="Delete College Application"
    message="Are you sure you want to delete this college application? This action is permanent and cannot be reverted."
    confirmButtonLabel="Delete"
    :isOpen="isDialogOpen"
    :isLoading="form.processing"
    @onConfirm="handleProgramDelete"
    @onCancel="toggleDialog"
    @onClose="toggleDialog"
  />
</template>
