<script setup lang="ts">
defineProps<{
  activeIndex: number;
}>();

const slots = useSlots();

const getSlotLength = () => slots.default?.().length || 1;
</script>

<template>
  <nav class="relative">
    <div
      class="absolute -top-px h-0.5 bg-secondary-900 transition-transform duration-200 ease-in-out"
      :style="{
        width: `calc(${100 / getSlotLength()}% - ${
          ((getSlotLength() - 1) / getSlotLength()) * 0.375
        }rem)`,
        transform: `translateX(calc(${activeIndex * 100}% + ${activeIndex * 0.375}rem))`
      }"
    ></div>
    <ul class="flex justify-between gap-x-1.5">
      <slot />
    </ul>
  </nav>
</template>
