<script setup lang="ts">
type SmsAttachmentData = App.Sms.Data.SmsAttachmentData;
type DataHookData = App.Sms.Data.DataHookData;

import { ButtonColor, ButtonSize, ButtonVariant } from '@/hooks/useButtonClasses';
import { useSmsAttachmentUpload } from '@/hooks/files/useSmsAttachmentUpload';
import { useVariants } from '@/hooks/useVariants';

import Button from '@/components/button/Button.vue';
import ComposeMessageContentToolbar from '@/components/sms/ComposeMessageContentToolbar.vue';
import DateTime from '@/components/dateTime/DateTime.vue';
import FormError from '@/components/formError/FormError.vue';
import MessageSegmentCount from '@/components/sms/MessageSegmentCount.vue';

import ClockIcon from '@/icons/line/clock.svg';
import IconTimes from '@/icons/line/times.svg';
import SpinnerIcon from '@/icons/line/spinner.svg';

interface ComposeMessageInputProps {
  variant?: 'campaign' | 'thread' | 'scheduled';
  canUploadAttachments?: boolean;
  canScheduleMessage?: boolean;
  error?: string;
  hasError?: boolean;
  isLoading?: boolean;
  isDisabled?: boolean;
  scheduledSendDateError?: string;
  wrapperClass?: string;
}

const props = withDefaults(defineProps<ComposeMessageInputProps>(), {
  variant: 'thread',
  canUploadAttachments: true,
  canScheduleMessage: true,
  isLoading: false,
  isDisabled: false,
  hasError: false
});

defineOptions({
  inheritAttrs: false
});

defineSlots<{
  (name: 'actionButtons'): undefined;
}>();

const emit = defineEmits<{
  send: [];
}>();

const message = defineModel<string>();
const scheduledSendDate = defineModel<string | null>('scheduledSendDate');
const attachments = defineModel<SmsAttachmentData[]>('attachments', {
  default: () => []
});
const textarea = ref<HTMLTextAreaElement | null>(null);

const { variety } = useVariants<NonNullable<ComposeMessageInputProps['variant']>>(props.variant);

const { handleFilesPicked, removeAttachment, attachmentThumbnails } =
  useSmsAttachmentUpload(attachments);

function focusTextarea() {
  textarea.value?.focus();
}

function handleDataHookInsert(hook: DataHookData) {
  if (message.value?.length) {
    message.value = message.value + `{{${hook.id}}}`;
    return focusTextarea();
  }
  message.value = `{{${hook.id}}}`;
  return focusTextarea();
}

function clearScheduledSendDate() {
  scheduledSendDate.value = null;
}

function handleKeyDown(event: KeyboardEvent) {
  if (event.key === 'Enter') {
    if (event.shiftKey) {
      return;
    }
    event.preventDefault();
    emit('send');
  }
}
</script>

<template>
  <div :class="['flex flex-col gap-1', variety('campaign') && 'h-full', wrapperClass]">
    <ComposeMessageContentToolbar
      v-if="variety('thread', 'scheduled')"
      size="sm"
      class="py-2"
      :canUploadAttachments="canUploadAttachments"
      :isDisabled="isDisabled"
      @filesPicked="handleFilesPicked"
      @insertDataHook="handleDataHookInsert"
    />

    <div :class="[scheduledSendDate && 'rounded-t-lg bg-zinc-100']">
      <div
        v-if="scheduledSendDate"
        class="flex items-center justify-between px-3 py-2 text-sm text-zinc-500"
      >
        <div class="flex items-center">
          <ClockIcon :class="['mr-2 h-4 w-4 text-slate-500']" />
          <div>
            <div class="flex items-center">
              Your message will be sent on
              <DateTime
                picker
                v-model:date="scheduledSendDate"
                format="datetime"
                class="ml-1 font-bold"
                :class="{
                  'text-red-700': scheduledSendDateError,
                  'text-zinc-700': !scheduledSendDateError
                }"
                displayTimezone
              />.
            </div>
            <FormError v-if="scheduledSendDateError">
              {{ scheduledSendDateError }}
            </FormError>
          </div>
        </div>

        <Button
          :color="ButtonColor.danger"
          :size="ButtonSize.sm"
          :variant="ButtonVariant.invisible"
          :iconLeft="IconTimes"
          @click="clearScheduledSendDate"
        >
          Clear scheduled date/time
        </Button>
      </div>
      <div
        @click.self="focusTextarea"
        :disabled="isDisabled"
        :class="[
          'relative flex flex-1 flex-col',
          'rounded-lg border border-slate-200 bg-white transition-input duration-150 ease-in-out',
          'enabled:focus-within:border-slate-700 enabled:focus-within:ring-3 enabled:focus-within:ring-slate-700/40',
          'enabled:hover:border-slate-700',
          (error || hasError) && '!border-red-500 !ring-red-500/40'
        ]"
      >
        <textarea
          v-bind="$attrs"
          ref="textarea"
          placeholder="My message..."
          :class="[
            'flex-1',
            'resize-none rounded-lg border-none px-3 py-1.5 text-[16px] text-slate-900 outline-0 md:text-sm',
            'focus:border-none focus:ring-0',
            'disabled:opacity-60 disabled:hover:border-slate-200'
          ]"
          :disabled="isDisabled"
          v-model="message"
          maxlength="1000"
          @keydown="handleKeyDown"
        />

        <div
          @click.self="focusTextarea"
          :class="[
            'flex gap-2 rounded-b-lg px-2 py-1.5',
            canUploadAttachments ? 'justify-between' : 'justify-end'
          ]"
        >
          <div v-if="canUploadAttachments" class="flex items-center space-x-2">
            <template v-for="image in attachmentThumbnails" :key="image.id">
              <div class="relative">
                <img
                  :src="image.src"
                  alt=""
                  class="h-12 w-12 rounded-xl object-cover"
                  :class="{ 'opacity-25': image.isUploading }"
                />
                <div
                  class="absolute inset-0 flex items-center justify-center"
                  v-show="image.isUploading"
                >
                  <SpinnerIcon class="h-6 w-6 animate-spin text-secondary-900" />
                </div>
                <button
                  type="button"
                  class="absolute right-0 top-0 -mr-1 -mt-1"
                  @click.stop="() => removeAttachment(image.id)"
                >
                  <IconTimes class="h-4 w-4 rounded-full bg-gray-400 text-gray-50" />
                </button>
              </div>
            </template>
          </div>

          <div class="flex items-center gap-2">
            <Button
              v-if="variety('scheduled')"
              :color="ButtonColor.danger"
              :iconLeft="ClockIcon"
              :variant="ButtonVariant.invisible"
            >
              Delete
            </Button>

            <DateTime
              v-if="canScheduleMessage"
              picker
              v-model:date="scheduledSendDate"
              format="datetime"
              :class="['ml-1 font-bold', scheduledSendDateError ? 'text-red-700' : 'text-zinc-700']"
            >
              <Button
                :color="ButtonColor.slate"
                :variant="ButtonVariant.invisible"
                :iconLeft="ClockIcon"
              >
                {{ scheduledSendDate ? 'Reschedule' : 'Schedule' }}
              </Button>
            </DateTime>

            <slot name="actionButtons" />

            <Button
              v-if="variety('thread', 'scheduled')"
              :color="ButtonColor.primary"
              :isLoading="isLoading"
              :variant="ButtonVariant.solid"
              @click="$emit('send')"
            >
              {{ scheduledSendDate ? 'Save' : 'Send now' }}
            </Button>
          </div>
        </div>
      </div>
    </div>

    <footer class="grid grid-cols-2 items-center justify-between">
      <FormError
        v-if="error"
        :error
        class="col-span-2"
        :class="{
          'pb-4': variety('campaign'),
          'pb-2': variety('thread', 'scheduled')
        }"
      />

      <ComposeMessageContentToolbar
        v-if="variety('campaign')"
        :canUploadAttachments="canUploadAttachments"
        :isDisabled="isDisabled"
        size="xs"
        @filesPicked="handleFilesPicked"
        @insertDataHook="handleDataHookInsert"
      />

      <div
        class="flex items-center gap-2 divide-x px-3 text-2xs leading-none text-slate-500"
        :class="[variety('campaign') && 'justify-end']"
      >
        <MessageSegmentCount :message="message ?? ''" />
      </div>
    </footer>
  </div>
</template>

<style scoped>
:deep(textarea) {
  field-sizing: content;
}
</style>
