<script setup lang="ts">
import type { AppliedFilters, AppliedFilter } from '@/components/distiller/types';
import type { HybridlyFormData } from '@/@types/global';
import type { Selectable } from '@/components/selectBox/selectBox';

import FormListboxInput from '@/components/formListboxInput/FormListboxInput.vue';
import FormMultiSchoolInput from '@/components/formComboboxInput/FormMultiSchoolInput.vue';
import FormProgramInput from '@/components/formComboboxInput/FormProgramInput.vue';
import Alert from '@/components/alert/Alert.vue';

import ExclamationTriangle from '@/icons/line/exclamation-triangle.svg';

const props = defineProps<{
  grades: App.Base.Data.EnumData[];
  form: HybridlyFormData<{ filters: AppliedFilters }>;
}>();

function handleProgramSelection(program: Selectable<number> | null) {
  props.form.fields.filters.program_id = program as unknown as AppliedFilter;
  props.form.fields.filters.school_ids = [] as unknown as AppliedFilter;
}
</script>

<template>
  <FormListboxInput
    label="Grade"
    name="grade"
    :options="grades"
    :modelValue="form.fields.filters.student_grade as any"
    @update:modelValue="(value) => (form.fields.filters.student_grade = value as any)"
  />
  <FormProgramInput
    label="Program"
    :key="form.fields.filters.program_id?.value as any"
    :modelValue="form.fields.filters.program_id as any"
    @update:modelValue="handleProgramSelection"
  />
  <FormMultiSchoolInput
    label="Schools"
    :key="form.fields.filters.program_id?.value as any"
    :programIds="
      form.fields.filters.program_id ? [form.fields.filters.program_id.value as number] : []
    "
    :modelValue="form.fields.filters.school_ids as any"
    :isDisabled="!form.fields.filters.program_id"
    selectAll
    :helperText="
      !form.fields.filters.program_id
        ? 'You must select a program first before selecting schools.'
        : ''
    "
    @update:modelValue="(value) => (form.fields.filters.school_ids = value as any)"
  />

  <Alert
    v-if="form.errors.filters"
    :icon="ExclamationTriangle"
    :description="formatErrors(form.errors.filters)"
    title="Filter errors"
    color="danger"
    variant="soft"
  />
</template>
