export enum ButtonColor {
  primary = 'primary',
  secondary = 'secondary',
  slate = 'slate',
  success = 'success',
  warning = 'warning',
  danger = 'danger'
}

export enum ButtonSize {
  xs = 'xs',
  sm = 'sm',
  md = 'md',
  lg = 'lg'
}

export enum ButtonVariant {
  solid = 'solid',
  outlined = 'outlined',
  soft = 'soft',
  invisible = 'invisible'
}

export function useButtonClasses({
  color,
  variant,
  isInteractive = true
}: {
  color: string;
  variant: string;
  isInteractive?: boolean;
}) {
  return [
    'whitespace-nowrap text-sm font-bold leading-5',
    'inline-flex items-center justify-center gap-2',
    'disabled:cursor-not-allowed disabled:opacity-50',

    isInteractive && [
      'cursor-pointer',
      'focus:ring-3',
      'active:ring-3',
      'focus:outline-none',
      'active:outline-none'
    ],
    !isInteractive && 'cursor-default',

    color === ButtonColor.primary &&
      variant === ButtonVariant.solid && [
        'bg-primary-800 border-primary-800 text-white',
        isInteractive && [
          'hover:border-primary-900 hover:bg-primary-700',
          'aria-pressed:border-primary-900 aria-pressed:bg-primary-700',
          'focus:ring-primary-300 focus:border-primary-900 focus:bg-primary-800 focus:ring-offset-2',
          'active:border-primary-900 active:bg-primary-800 active:ring-primary-300 active:ring-offset-0',
          'disabled:border-primary-900 disabled:bg-primary-900 disabled:hover:bg-primary-900 disabled:aria-pressed:bg-primary-900 disabled:ring-1 disabled:ring-slate-200'
        ]
      ],

    // Primary Soft
    color === ButtonColor.primary &&
      variant === ButtonVariant.soft && [
        'bg-primary-50 border-primary-50 text-primary-800',
        isInteractive && [
          'hover:border-primary-100 hover:bg-primary-100',
          'aria-pressed:border-primary-100 aria-pressed:bg-primary-100',
          'focus:ring-transparent focus:border-primary-200 focus:bg-primary-50',
          'active:border-primary-300 active:bg-primary-100 active:focus:ring-primary-50',
          'disabled:border-white disabled:bg-white disabled:hover:bg-white disabled:aria-pressed:bg-white disabled:ring-1 disabled:ring-slate-200'
        ]
      ],

    // Primary Outlined
    color === ButtonColor.primary &&
      variant === ButtonVariant.outlined && [
        'bg-white border-primary-300 text-primary-800',
        isInteractive && [
          'hover:border-primary-800/40 hover:bg-primary-50',
          'aria-pressed:border-primary-800/40 aria-pressed:bg-primary-50',
          'focus:bg-white focus:border-primary-800/40 focus:ring-primary-100',
          'active:bg-primary-100 active:ring-primary-100',
          'disabled:opacity-50 disabled:hover:bg-white disabled:hover:border-primary-300 disabled:aria-pressed:bg-white disabled:aria-pressed:border-primary-300 disabled:active:ring-transparent'
        ]
      ],

    // Primary Invisible
    color === ButtonColor.primary &&
      variant === ButtonVariant.invisible && [
        'bg-transparent border-transparent text-primary-800',
        isInteractive && [
          'hover:border-primary-50 hover:bg-primary-50',
          'aria-pressed:border-primary-50 aria-pressed:bg-primary-50',
          'focus:border-primary-300 focus:ring-transparent',
          'active:ring-primary-50 active:border-primary-300 active:bg-primary-100',
          'disabled:hover:border-transparent disabled:hover:bg-transparent disabled:aria-pressed:border-transparent disabled:aria-pressed:bg-transparent disabled:active:ring-transparent'
        ]
      ],

    // Secondary Solid
    color === ButtonColor.secondary &&
      variant === ButtonVariant.solid && [
        'bg-secondary-800 border-secondary-800 text-white',
        isInteractive && [
          'hover:border-secondary-900 hover:bg-secondary-700',
          'aria-pressed:border-secondary-900 aria-pressed:bg-secondary-700',
          'focus:ring-secondary-300 focus:border-secondary-900 focus:bg-secondary-800 focus:ring-offset-2',
          'active:border-secondary-900 active:bg-secondary-800 active:ring-secondary-300 active:ring-offset-0',
          'disabled:border-secondary-900 disabled:bg-secondary-900 disabled:hover:bg-secondary-900 disabled:aria-pressed:bg-secondary-900 disabled:ring-1 disabled:ring-slate-200'
        ]
      ],

    // Secondary Soft
    color === ButtonColor.secondary &&
      variant === ButtonVariant.soft && [
        'bg-secondary-50 border-secondary-50 text-secondary-900',
        isInteractive && [
          'hover:border-secondary-100 hover:bg-secondary-100',
          'aria-pressed:border-secondary-100 aria-pressed:bg-secondary-100',
          'focus:ring-transparent focus:border-secondary-700/20 focus:bg-secondary-100',
          'active:border-secondary-700/30 active:bg-secondary-100 active:focus:ring-secondary-100',
          'disabled:border-transparent disabled:bg-white disabled:hover:bg-white disabled:hover:ring-slate-200 disabled:aria-pressed:bg-white disabled:aria-pressed:ring-slate-200 disabled:ring-1 disabled:ring-slate-200 disabled:active:ring-slate-200'
        ]
      ],

    // Secondary Outlined
    color === ButtonColor.secondary &&
      variant === ButtonVariant.outlined && [
        'bg-white border-secondary-700/50 text-secondary-800',
        isInteractive && [
          'hover:border-secondary-700 hover:bg-secondary-50',
          'aria-pressed:border-secondary-700 aria-pressed:bg-secondary-50',
          'focus:bg-white focus:border-secondary-300 focus:ring-secondary-100',
          'active:bg-secondary-100 active:border-secondary-300 active:ring-secondary-500/20',
          'disabled:opacity-50 disabled:hover:bg-white disabled:hover:border-secondary-700/50 disabled:aria-pressed:bg-white disabled:aria-pressed:border-secondary-700/50 disabled:active:ring-transparent'
        ]
      ],

    // Secondary Invisible
    color === ButtonColor.secondary &&
      variant === ButtonVariant.invisible && [
        'bg-transparent border-transparent text-secondary-800',
        isInteractive && [
          'hover:border-secondary-50 hover:bg-secondary-50',
          'aria-pressed:border-secondary-50 aria-pressed:bg-secondary-50',
          'focus:border-secondary-300 focus:ring-transparent',
          'active:ring-secondary-50 active:border-secondary-300 active:bg-secondary-100',
          'disabled:hover:border-transparent disabled:hover:bg-transparent disabled:aria-pressed:border-transparent disabled:aria-pressed:bg-transparent disabled:active:ring-transparent'
        ]
      ],

    // Slate Solid
    color === ButtonColor.slate &&
      variant === ButtonVariant.solid && [
        'bg-slate-800 border-slate-800 text-white',
        isInteractive && [
          'hover:border-slate-900 hover:bg-slate-700',
          'aria-pressed:border-slate-900 aria-pressed:bg-slate-700',
          'focus:ring-slate-300 focus:border-slate-900 focus:bg-slate-800 focus:ring-offset-2',
          'active:border-slate-900 active:bg-slate-800 active:ring-slate-300 active:ring-offset-0',
          'disabled:border-slate-900 disabled:bg-slate-900 disabled:hover:bg-slate-900 disabled:aria-pressed:bg-slate-900 disabled:ring-1 disabled:ring-slate-200'
        ]
      ],

    // Slate Soft
    color === ButtonColor.slate &&
      variant === ButtonVariant.soft && [
        'bg-slate-100 border-slate-100 text-slate-700',
        isInteractive && [
          'hover:border-slate-200 hover:bg-slate-200',
          'aria-pressed:border-slate-200 aria-pressed:bg-slate-200',
          'focus:ring-transparent focus:border-slate-300 focus:bg-slate-200',
          'active:border-slate-300 active:bg-slate-200 active:focus:ring-slate-100',
          'disabled:border-white disabled:bg-white disabled:hover:bg-white disabled:aria-pressed:bg-white disabled:ring-1 disabled:ring-slate-200'
        ]
      ],

    // Slate Outlined
    color === ButtonColor.slate &&
      variant === ButtonVariant.outlined && [
        'bg-white border-slate-300 text-slate-700',
        isInteractive && [
          'hover:border-slate-300 hover:bg-slate-100',
          'aria-pressed:border-slate-300 aria-pressed:bg-slate-100',
          'focus:bg-white focus:border-slate-300 focus:ring-slate-100',
          'active:bg-slate-100 active:border-slate-300 active:ring-slate-500/20',
          'disabled:opacity-50 disabled:hover:bg-white disabled:hover:border-slate-300 disabled:aria-pressed:bg-white disabled:aria-pressed:border-slate-300 disabled:active:ring-transparent'
        ]
      ],

    // Slate Invisible
    color === ButtonColor.slate &&
      variant === ButtonVariant.invisible && [
        'bg-transparent border-transparent text-slate-700',
        'hover:border-slate-100 hover:bg-slate-200',
        'aria-pressed:border-slate-100 aria-pressed:bg-slate-200',
        'focus:border-slate-300 focus:ring-transparent',
        'active:ring-slate-50 active:border-slate-300 active:bg-slate-200',
        'disabled:hover:border-transparent disabled:hover:bg-transparent disabled:aria-pressed:bg-transparent disabled:active:ring-transparent'
      ],

    // Success Solid
    color === ButtonColor.success &&
      variant === ButtonVariant.solid && [
        'bg-green-700 border-green-700 text-white',
        'hover:border-green-700 hover:bg-green-600',
        'aria-pressed:border-green-700 aria-pressed:bg-green-600',
        'focus:ring-green-500 focus:border-green-700 focus:bg-green-700 focus:ring-offset-2',
        'active:border-green-700 active:bg-green-700 active:ring-green-500 active:ring-offset-0',
        'disabled:border-green-600 disabled:bg-green-600 disabled:hover:bg-green-600 disabled:aria-pressed:bg-green-600 disabled:ring-1 disabled:ring-transparent'
      ],

    // Success Soft
    color === ButtonColor.success &&
      variant === ButtonVariant.soft && [
        'bg-green-100 border-green-100 text-green-800',
        isInteractive && [
          'hover:border-green-200 hover:bg-green-200',
          'aria-pressed:border-green-200 aria-pressed:bg-green-200',
          'focus:ring-transparent focus:border-green-600 focus:bg-green-100',
          'active:border-green-600 active:bg-green-200 active:focus:ring-green-200',
          'disabled:border-green-100 disabled:bg-green-100 disabled:hover:bg-green-100 disabled:aria-pressed:bg-green-100 disabled:ring-1 disabled:ring-transparent'
        ]
      ],

    // Success Outlined
    color === ButtonColor.success &&
      variant === ButtonVariant.outlined && [
        'bg-white border-green-600 text-green-800',
        'hover:border-green-600 hover:bg-green-100',
        'aria-pressed:border-green-600 aria-pressed:bg-green-100',
        'focus:border-green-600 focus:ring-green-200',
        'active:bg-green-100 active:border-green-600 active:ring-green-200',
        'disabled:opacity-50 disabled:border-green-600 disabled:hover:bg-white disabled:hover:border-green-600 disabled:aria-pressed:bg-white disabled:aria-pressed:border-green-600 disabled:active:ring-transparent'
      ],

    // Success Invisible
    color === ButtonColor.success &&
      variant === ButtonVariant.invisible && [
        'bg-transparent border-transparent text-green-800',
        isInteractive && [
          'hover:border-green-100 hover:bg-green-100',
          'aria-pressed:border-green-100 aria-pressed:bg-green-100',
          'focus:border-green-200 focus:ring-transparent',
          'active:ring-green-300/30 active:border-green-200 active:bg-green-200',
          'disabled:hover:border-transparent disabled:hover:bg-transparent disabled:aria-pressed:bg-transparent disabled:active:ring-transparent'
        ]
      ],

    // Warning Solid
    color === ButtonColor.warning &&
      variant === ButtonVariant.solid && [
        'bg-amber-300 border-amber-300 text-amber-900',
        'hover:border-amber-400 hover:bg-amber-200',
        'aria-pressed:border-amber-400 aria-pressed:bg-amber-200',
        'focus:ring-amber-500/50 focus:border-amber-400 focus:bg-amber-300 focus:ring-offset-2',
        'active:border-amber-400 active:bg-amber-300 active:ring-amber-400/30 active:ring-offset-0',
        'disabled:border-amber-300 disabled:bg-amber-300 disabled:hover:bg-amber-300 disabled:aria-pressed:bg-amber-300 disabled:ring-1 disabled:ring-transparent'
      ],

    // Warning Soft
    color === ButtonColor.warning &&
      variant === ButtonVariant.soft && [
        'bg-amber-100 border-amber-100 text-amber-800',
        isInteractive && [
          'hover:border-amber-200 hover:bg-amber-200',
          'aria-pressed:border-amber-200 aria-pressed:bg-amber-200',
          'focus:ring-transparent focus:border-amber-300 focus:bg-amber-100',
          'active:border-amber-300 active:bg-amber-200 active:focus:ring-amber-400/30',
          'disabled:border-amber-100 disabled:bg-amber-100 disabled:hover:bg-amber-100 disabled:aria-pressed:bg-amber-100 disabled:ring-1 disabled:ring-transparent'
        ]
      ],

    // Warning Outlined
    color === ButtonColor.warning &&
      variant === ButtonVariant.outlined && [
        'bg-white border-amber-300 text-amber-900',
        isInteractive && [
          'hover:border-amber-300 hover:bg-amber-100',
          'aria-pressed:border-amber-300 aria-pressed:bg-amber-100',
          'focus:border-amber-300 focus:ring-transparent',
          'active:bg-amber-100 active:border-amber-300 active:ring-amber-400/30',
          'disabled:opacity-50 disabled:border-amber-300 disabled:hover:bg-white disabled:hover:border-amber-300 disabled:aria-pressed:bg-white disabled:aria-pressed:border-amber-300 disabled:active:ring-transparent'
        ]
      ],

    // Warning Invisible
    color === ButtonColor.warning &&
      variant === ButtonVariant.invisible && [
        'bg-transparent border-transparent text-amber-500',
        isInteractive && [
          'hover:border-amber-100 hover:bg-amber-100',
          'aria-pressed:border-amber-100 aria-pressed:bg-amber-100',
          'focus:border-amber-200 focus:ring-transparent',
          'active:ring-amber-300/30 active:border-amber-200 active:bg-amber-200',
          'disabled:hover:border-transparent disabled:hover:bg-transparent disabled:aria-pressed:bg-transparent disabled:active:ring-transparent'
        ]
      ],

    // Danger Solid
    color === ButtonColor.danger &&
      variant === ButtonVariant.solid && [
        'bg-red-600 border-red-600 text-white',
        isInteractive && [
          'hover:border-red-700 hover:bg-red-500',
          'aria-pressed:border-red-700 aria-pressed:bg-red-500',
          'focus:ring-red-600/40 focus:border-red-700 focus:bg-red-500 focus:ring-offset-2',
          'active:border-red-700 active:bg-red-600 active:ring-red-600/10 active:ring-offset-0',
          'disabled:border-red-600 disabled:bg-red-600 disabled:hover:border-red-600 disabled:hover:bg-red-600 disabled:aria-pressed:border-red-600 disabled:aria-pressed:bg-red-600'
        ]
      ],

    // Danger Soft
    color === ButtonColor.danger &&
      variant === ButtonVariant.soft && [
        'bg-red-100 border-red-100 text-red-700',
        isInteractive && [
          'hover:border-red-200 hover:bg-red-200',
          'aria-pressed:border-red-200 aria-pressed:bg-red-200',
          'focus:ring-transparent focus:border-red-300 focus:bg-red-100',
          'active:border-red-300 active:bg-red-200 active:ring-red-600/10',
          'disabled:border-red-100 disabled:bg-red-100 disabled:hover:border-red-100 disabled:hover:bg-red-100 disabled:aria-pressed:border-red-100 disabled:aria-pressed:bg-red-100 disabled:active:ring-transparent'
        ]
      ],

    // Danger Outlined
    color === ButtonColor.danger &&
      variant === ButtonVariant.outlined && [
        'bg-white border-red-300 text-red-700',
        isInteractive && [
          'hover:bg-red-100 hover:border-red-100',
          'aria-pressed:bg-red-100 aria-pressed:border-red-100',
          'focus:border-red-300 focus:bg-white focus:ring-red-600/20',
          'active:bg-red-100 active:border-red-300 active:ring-red-600/10',
          'disabled:border-red-300 disabled:bg-white disabled:hover:bg-white disabled:hover:border-red-300 disabled:aria-pressed:bg-white disabled:aria-pressed:border-red-300 disabled:active:ring-transparent'
        ]
      ],

    // Danger Invisible
    color === ButtonColor.danger &&
      variant === ButtonVariant.invisible && [
        'border-transparent bg-transparent text-red-700',
        isInteractive && [
          'hover:border-red-100 hover:bg-red-100',
          'aria-pressed:border-red-100 aria-pressed:bg-red-100',
          'focus:border-red-300 focus:ring-transparent',
          'active:ring-red-600/10 active:border-red-200 active:bg-red-200',
          'disabled:hover:border-transparent disabled:hover:bg-transparent disabled:aria-pressed:bg-transparent disabled:active:ring-transparent'
        ]
      ]
  ];
}
