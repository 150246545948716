import { debounce } from 'lodash';

import { search } from '@/api/SmsRecipientApi';

export function useSmsRecipientSearch() {
  const searchQuery = ref('');
  const isLoading = ref(false);
  const recipientOptions = ref<App.Sms.Data.SmsRecipientData[]>([]);

  const recipientOptionsWithDirectNumber = computed<App.Sms.Data.SmsRecipientData[]>(() => {
    const strippedQuery = searchQuery.value.replace(/( |-\(|\))/g, '');

    if (searchQuery.value.length && isFinite(Number(strippedQuery))) {
      return [
        {
          name: searchQuery.value,
          phone_number: searchQuery.value,
          recipient_type: 'phone_number',
          recipient_id: null,
          meta: null
        } as App.Sms.Data.SmsRecipientData,
        ...recipientOptions.value
      ];
    }

    return recipientOptions.value;
  });

  const searchRecipients = debounce(async (query: string) => {
    const response = await search(query);

    recipientOptions.value = response;

    isLoading.value = false;
  }, 300);

  function onQueryChange(query: string): void {
    isLoading.value = true;
    searchQuery.value = query;

    searchRecipients(query);
  }

  return {
    searchQuery,
    recipientOptions: recipientOptionsWithDirectNumber,
    isLoading,
    onQueryChange
  };
}
