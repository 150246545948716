<script setup lang="ts">
import { formatDate } from '@/utils/date';

import Badge from '@/components/badge/Badge.vue';
import BadgeBooleanList from '@/components/badge/BadgeBooleanList.vue';
import DefinitionList from '@/components/definitionList/DefinitionList.vue';
import DefinitionListItem from '@/components/definitionList/DefinitionListItem.vue';
import FinancialAidDetailsSection from '@/components/students/financialAid/FinancialAidDetailsSection.vue';

import Calendar from '@/icons/line/calendar.svg';
import Clock from '@/icons/line/clock.svg';
import Check from '@/icons/line/check.svg';
import DollarSign from '@/icons/line/dollar-sign.svg';
import GraduationCap from '@/icons/line/graduation-cap.svg';
import Utensils from '@/icons/line/utensils.svg';
import Times from '@/icons/line/times.svg';
import Play from '@/icons/line/play.svg';
import QuestionCircle from '@/icons/line/question-circle.svg';

const props = defineProps<{
  showEditButton?: boolean;
  student: App.Students.Data.StudentData;
  studentCssProfiles: App.Base.Data.EnumData[];
}>();

const cssProfileLabel = computed(
  () =>
    props.studentCssProfiles?.find((profile) => profile.value === props.student.meta.css_profile)
      ?.label
);

function getStatusIcon(status: string) {
  return {
    none: undefined,
    'created-fsa-id': Clock,
    'in-progress': Clock,
    submitted: Clock,
    approved: Check,
    'not-filing': Times
  }[status];
}

function getCssProfileIcon(cssProfile: string) {
  return {
    unknown: QuestionCircle,
    started: Play,
    completed: Check,
    no: Times
  }[cssProfile];
}
</script>

<template>
  <div class="divide-y divide-slate-200">
    <FinancialAidDetailsSection
      :icon="DollarSign"
      :route="
        route('students.financial-aid.edit', {
          student: student?.id,
          financialAid: student?.financial_aid?.id
        })
      "
      :showEditButton="showEditButton"
      description="A comprehensive overview of the student's federal financial aid application progress."
      title="Financial Aid Application"
    >
      <DefinitionList>
        <DefinitionListItem hideIcon title="Status">
          <Badge
            :iconLeft="getStatusIcon(student.financial_aid?.status.value)"
            :label="student.financial_aid?.status.label ?? '--'"
            variant="soft"
          />
        </DefinitionListItem>
        <DefinitionListItem hideIcon title="Selected for Verification">
          <Badge
            :label="student.financial_aid?.selected_for_verification.label ?? '--'"
            variant="soft"
          />
        </DefinitionListItem>
        <DefinitionListItem hideIcon title="Status Date">
          <Badge
            :iconLeft="Calendar"
            :label="
              student.financial_aid?.date_submitted
                ? formatDate(student.financial_aid?.date_submitted)
                : '--'
            "
            variant="soft"
          />
        </DefinitionListItem>
        <DefinitionListItem hideIcon title="CSS profile">
          <Badge
            :iconLeft="getCssProfileIcon(student.meta.css_profile)"
            :label="cssProfileLabel ?? '--'"
            variant="soft"
          />
        </DefinitionListItem>
        <DefinitionListItem hideIcon title="Other">
          <BadgeBooleanList
            :items="{
              Assisted: !!student.financial_aid?.is_assisted,
              'Alternative financial aid': !!student.financial_aid?.is_alternative
            }"
          />
        </DefinitionListItem>
      </DefinitionList>
    </FinancialAidDetailsSection>
    <FinancialAidDetailsSection
      :eligibility="student.pell_eligible"
      :icon="GraduationCap"
      description="Whether the student meets the criteria for Pell Grants."
      showEligibleBadge
      title="Pell Eligibility"
    />
    <FinancialAidDetailsSection
      :eligibility="student.free_lunch"
      :icon="Utensils"
      :route="
        route('students.edit.enrollment-details', {
          student: student?.id
        })
      "
      anchor="fr-price-lunch"
      description="Whether the student is eligible for free or reduced-price lunch."
      showEligibleBadge
      showEditButton
      title="F/R Price Lunch Eligibility"
    />
  </div>
</template>
