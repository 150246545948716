<script setup lang="ts">
import BadgeBoolean from '@/components/badge/BadgeBoolean.vue';
import IconButton from '@/components/button/IconButton.vue';

import EditAlt from '@/icons/line/edit-alt.svg';

const props = defineProps<{
  description: string;
  eligibility?: boolean | null;
  icon?: Component;
  route?: string;
  showEligibleBadge?: boolean;
  showEditButton?: boolean;
  title: string;
  anchor?: string;
}>();

const rawIcon = toRaw(props.icon);
</script>

<template>
  <section class="divide-y divide-slate-200">
    <component
      :is="$slots.default ? 'header' : 'div'"
      class="flex flex-col gap-y-3 bg-white px-4 py-5"
    >
      <div class="flex w-full justify-between gap-x-2">
        <div class="flex flex-col items-start gap-y-1">
          <div class="flex items-center gap-x-2">
            <component v-if="rawIcon" :is="rawIcon" class="test-slate-500 h-4 w-4" />
            <h3 class="text-base font-semibold leading-6 text-slate-900">
              {{ title }}
            </h3>
          </div>
          <p class="text-sm leading-4 text-slate-500">
            {{ description }}
          </p>
        </div>
        <IconButton
          v-if="showEditButton"
          ariaLabel="Edit financial aid application"
          variant="invisible"
          size="xs"
          :icon="EditAlt"
          :href="route + (anchor ? `#${anchor}` : '')"
        />
      </div>
      <div class="flex">
        <BadgeBoolean
          v-if="showEligibleBadge"
          :boolean="eligibility"
          labelTrue="Eligible"
          labelFalse="Not Eligible"
          size="lg"
        />
      </div>
    </component>
    <div v-if="$slots.default" class="bg-slate-50 px-4 py-5">
      <slot />
    </div>
  </section>
</template>
