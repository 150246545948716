import { NumberVisualization } from '@/components/dashboard/data';
import { MetricConfig } from '@/components/dashboard/metrics';

export interface DashboardConfig {
  showGoal: boolean;
  numberFormat: NumberVisualization;
}

export const metricConfigs: MetricConfig[] = [
  {
    title: '1-on-1s - All Mediums',
    kpis: [
      {
        label: 'Has +1 1-on-1 meetings with student',
        kpi: 'interaction_student_ids_with_one_on_one_interaction_type_derived_table'
      },
      {
        label: 'None',
        kpi: 'interaction_student_ids_without_one_on_one_interaction_type_derived_table'
      }
    ],
    type: 'doughnut',
    size: 'md'
  },
  {
    title: 'Family Engagement',
    kpis: [
      {
        label: 'Has +1 meetings with parent or guardian',
        kpi: 'interaction_student_ids_with_parent_interaction_type_derived_table'
      },
      {
        label: 'None',
        kpi: 'interaction_student_ids_without_parent_interaction_type_derived_table'
      }
    ],
    type: 'doughnut',
    size: 'md'
  },
  {
    title: 'College Applications Submitted',
    kpis: [
      {
        label: '0',
        kpi: 'student_ids_with_zero_submitted_college_applications_derived_table'
      },
      {
        label: '1+',
        kpi: 'college_application_student_ids_with_at_least_one_submitted_application_derived_table'
      },
      {
        label: '3+',
        kpi: 'college_application_student_ids_with_at_least_three_submitted_applications_derived_table'
      },
      {
        label: '1+ In Progress, 0 Submitted',
        kpi: 'student_ids_with_zero_submitted_college_applications_and_at_least_one_in_progress_college_application_derived_table'
      }
    ],
    type: 'bar',
    size: 'lg'
  },
  {
    title: 'College Applications Accepted',
    kpis: [
      {
        label: '0',
        kpi: 'student_ids_with_zero_accepted_college_applications_derived_table'
      },
      {
        label: '1+',
        kpi: 'college_application_student_ids_with_at_least_one_accepted_application_derived_table'
      },
      {
        label: '3+',
        kpi: 'college_application_student_ids_with_at_least_three_accepted_applications_derived_table'
      }
    ],
    type: 'bar',
    size: 'sm'
  },
  {
    title: 'Financial Aid Status',
    kpis: [
      {
        label: 'None',
        kpi: 'student_ids_with_financial_aids_in_none_status_derived_table'
      },
      {
        label: 'Created FSA ID',
        kpi: 'student_ids_with_financial_aids_in_created_fsa_id_status_derived_table'
      },
      {
        label: 'Submitted and Completed FAFSA + Alt Financial Aid',
        kpi: 'student_ids_with_financial_aids_in_submitted_status_derived_table'
      },
      {
        label: 'In Progress, not submitted FAFSA + Alt Financial Aid',
        kpi: 'student_ids_with_financial_aids_in_progress_status_derived_table'
      },
      {
        label: 'Not Filing',
        kpi: 'student_ids_with_financial_aids_in_not_filing_status_derived_table'
      }
    ],
    byline: '*Includes CA Dream Act (CA) and TASFA (TX)',
    type: 'bar',
    size: 'md'
  },
  {
    title: 'Financial Aid Assisted',
    kpis: [
      {
        label: 'Not yet assisted',
        kpi: 'student_ids_with_financial_aid_without_assistance_derived_table'
      },
      {
        label: 'Assisted FAFSA + Alternative Financial Aid',
        kpi: 'student_ids_with_financial_aid_with_assistance_derived_table'
      }
    ],
    byline: '*Includes CA Dream Act (CA) and TASFA (TX)',
    type: 'bar',
    size: 'md'
  },
  {
    title: 'ACT/SATs',
    kpis: [
      {
        label: 'None',
        kpi: 'student_ids_without_registered_sat_act_test_derived_table'
      },
      {
        label: 'Registered',
        kpi: 'student_ids_for_sat_act_registered_with_no_completed_exams_derived_table'
      },
      {
        label: 'Complete',
        kpi: 'test_student_ids_for_sat_act_complete_derived_table'
      }
    ],
    type: 'bar',
    size: 'md'
  }
];
