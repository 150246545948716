<script setup lang="ts">
import type { UploadFormValues } from './useUploadSessionFormWizard';

import { ButtonColor, ButtonSize, ButtonVariant } from '@/hooks/useButtonClasses';

import Button from '@/components/button/Button.vue';
import MappingTable from '@/domains/bulkinator/components/table/MappingTable.vue';
import Alert from '@/components/alert/Alert.vue';
import ExclamationCircleIcon from '@/icons/line/exclamation-circle.svg';
import CheckCircleIcon from '@/icons/line/check-circle.svg';
import QuestionCircleIcon from '@/icons/line/question-circle.svg';

import { singular } from 'pluralize';

const props = defineProps<{
  form: ReturnType<typeof useForm<UploadFormValues>>;
  uploadType: App.Bulkinator.Data.UploadTypeData;
  columns: Array<{ label: string; value: string }>;
  headers: string[];
  showOnlyUnmapped: boolean;
  uploadTypeColumnIds: Array<{ label: string; value: string }>;
}>();
const emit = defineEmits<{ cancel: [void]; submit: [void] }>();

const formWasSubmittedWithUnmappedUploadTypeColumnIds = ref(false);

const unmappedUploadTypeColumnIds = computed(() => {
  return props.uploadTypeColumnIds.filter(
    ({ value }) =>
      !Object.values(props.form.fields.column_mappings).find((column) => column.value === value)
  );
});

function isUploadTypeColumnIdMapped(columnId: string) {
  return Object.values(props.form.fields.column_mappings).find(
    (column) => column.value === columnId
  );
}

function onSubmit() {
  if (unmappedUploadTypeColumnIds.value.length) {
    formWasSubmittedWithUnmappedUploadTypeColumnIds.value = true;
    return;
  }

  formWasSubmittedWithUnmappedUploadTypeColumnIds.value = false;
  emit('submit');
}
</script>

<template>
  <form @submit.prevent="onSubmit" class="flex flex-col gap-y-3">
    <section class="flex flex-col gap-y-5 px-5 py-6">
      <MappingTable
        :headers="headers"
        :form="form"
        :uploadType="uploadType"
        :columns="columns"
        :showOnlyUnmapped="showOnlyUnmapped"
      />
    </section>

    <section v-if="formWasSubmittedWithUnmappedUploadTypeColumnIds">
      <Alert
        title="Missing required matched columns"
        color="warning"
        variant="soft"
        :icon="ExclamationCircleIcon"
      >
        In order to match records in GRACE, we need at least the following columns to be mapped.
        Make sure one column of your document columns match each of these
        {{ singular(uploadType.name).toLocaleLowerCase() }} columns:
        <ul class="mt-1 flex gap-2 text-sm">
          <li
            v-for="column in uploadTypeColumnIds"
            :key="column.value"
            :class="[
              'flex items-center gap-0.5',
              isUploadTypeColumnIdMapped(column.value)
                ? 'font-normal'
                : 'text-sm font-bold text-amber-900'
            ]"
          >
            <CheckCircleIcon
              v-if="isUploadTypeColumnIdMapped(column.value)"
              class="h-4 w-4 opacity-50 hover:opacity-100"
            />
            <QuestionCircleIcon v-else class="h-4 w-4" />
            {{ column.label }}
          </li>
        </ul>
      </Alert>
    </section>

    <section class="flex flex-row justify-end gap-x-2 px-5 py-2.5">
      <Button
        :color="ButtonColor.slate"
        :size="ButtonSize.sm"
        :variant="ButtonVariant.outlined"
        type="button"
        @click="$emit('cancel')"
        >Previous</Button
      >
      <Button
        :color="ButtonColor.primary"
        :disabled="
          !form.fields.file ||
          form.errors.file ||
          (formWasSubmittedWithUnmappedUploadTypeColumnIds &&
            unmappedUploadTypeColumnIds.length > 0)
        "
        :isLoading="form.processing"
        :size="ButtonSize.sm"
        :variant="ButtonVariant.solid"
        type="submit"
        >Continue</Button
      >
    </section>
  </form>
</template>
