<script setup lang="tsx">
import type { CustomCells, HybridlyRow } from '@/components/table/Table';
import type { IndexTestTypeTabNavCounts } from '@/components/testTypes/IndexTestTypeTabNav.vue';

import { ButtonColor, ButtonSize, ButtonVariant } from '@/hooks/useButtonClasses';
import { useAuth } from '@/hooks/useAuth';
import { useHybridlyTable } from '@/hooks/useHybridlyTable';
import { useTableRowClick } from '@/hooks/useTableRowClick';

import BulkDeleteButton from '@/components/table/BulkDeleteButton.vue';
import Button from '@/components/button/Button.vue';
import EmptyState from '@/components/emptyState/EmptyState.vue';
import IndexPageLayout from '@/components/pageLayout/IndexPageLayout.vue';
import IndexTestTypeTabNav from '@/components/testTypes/IndexTestTypeTabNav.vue';
import TableDateTimeCell from '@/components/table/TableDateTimeCell.vue';

import LayerGroup from '@/icons/line/layer-group.svg';
import Plus from '@/icons/line/plus.svg';
import TableTruncatedTextCell from '@/components/table/TableTruncatedTextCell.vue';

type ApCourseRow = HybridlyRow<App.Configuration.Data.ApCourseData>;

const props = defineProps<{
  counts: IndexTestTypeTabNavCounts;
  apCourses: Table<App.Configuration.Data.ApCourseData>;
}>();

const { hasPermission } = useAuth();
const { table, tableSelectionState } = useHybridlyTable<App.Configuration.Data.ApCourseData>(
  props,
  'apCourses'
);
const { handleRowClick } = useTableRowClick();

const customCells = {
  display_name: ({ row, column }) => <TableTruncatedTextCell text={row.value(column.key)} />,
  created_at: ({ row, column }) => <TableDateTimeCell date={row.value(column.key)} />,
  updated_at: ({ row, column }) => <TableDateTimeCell date={row.value(column.key)} />
} as CustomCells<App.Configuration.Data.ApCourseData>;

function handleApCourseRowClick(event: MouseEvent, row: ApCourseRow) {
  const url = route('ap-courses.show', {
    apCourse: row.key
  });
  handleRowClick(url, event);
}
</script>


					<script lang="tsx">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <IndexPageLayout
    title="AP Courses"
    :table="table"
    :customCells="customCells"
    :stickyEndColumns="1"
    :stickyStartColumns="1"
    :tableSelectionState="tableSelectionState"
    clickableRows
    @clickRow="($event, row) => handleApCourseRowClick($event, row as ApCourseRow)"
  >
    <template #rightButtons>
      <Button
        v-if="hasPermission('create-ap-course') && !tableSelectionState.hasSelection"
        :color="ButtonColor.primary"
        :href="route('ap-courses.create')"
        :iconLeft="LayerGroup"
        :size="ButtonSize.sm"
        :variant="ButtonVariant.outlined"
      >
        Bulk add
      </Button>
      <Button
        v-if="hasPermission('create-ap-course') && !tableSelectionState.hasSelection"
        :color="ButtonColor.primary"
        :variant="ButtonVariant.solid"
        :size="ButtonSize.sm"
        :iconLeft="Plus"
        :href="route('ap-courses.create')"
      >
        Add a AP course
      </Button>

      <BulkDeleteButton
        recordType="AP Courses"
        :route="route('ap-courses.bulk-delete')"
        :tableSelectionState="tableSelectionState"
      />
    </template>
    <template #tabs>
      <IndexTestTypeTabNav :counts />
    </template>
    <template #emptyState>
      <EmptyState
        title="No AP courses found"
        :description="
          hasPermission('create-ap-course')
            ? 'Create a new AP course by clicking the button below'
            : ''
        "
        :actionLabel="hasPermission('create-ap-course') ? 'Add a AP course' : ''"
        :actionHref="route('ap-courses.create')"
      />
    </template>
  </IndexPageLayout>
</template>
