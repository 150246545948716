export const getFileExtension = (file?: File): string | null => {
  if (!file) {
    return null;
  }
  const index = file.name.lastIndexOf('.');

  if (index === -1) {
    return null;
  }

  return file.name.substring(index);
};
