<script setup lang="ts">
import Dialog from '@/components/dialog/Dialog.vue';

const props = defineProps<{
  message: App.Sms.Data.AutomatedCampaigns.AutomatedOutboundMessageData;
}>();

const isDialogOpen = ref(false);

const form = useForm({
  fields: {},
  method: 'post',
  url: route('sms.outbound-message.duplicate', { message: props.message })
});

function openDialog() {
  isDialogOpen.value = true;
}

function handleDuplicateMessage() {
  form.submit();
}
</script>

<template>
  <slot name="default" v-bind="{ openDialog }" />

  <Dialog
    title="Duplicate message"
    message="Are you sure you want to duplicate this message?"
    confirmButtonLabel="Duplicate message"
    :isOpen="isDialogOpen"
    :isLoading="form.processing"
    @onCancel="isDialogOpen = false"
    @onClose="isDialogOpen = false"
    @onConfirm="handleDuplicateMessage"
  />
</template>
