<script setup lang="ts">
import { useEditor, EditorContent, JSONContent } from '@tiptap/vue-3';
import StarterKit from '@tiptap/starter-kit';
import IconBold from '@/icons/line/bold.svg';
import IconItalic from '@/icons/line/italic.svg';
import IconUnderline from '@/icons/line/underline.svg';
import IconStrikethrough from '@/icons/line/strikethrough.svg';
import IconListOL from '@/icons/line/list-ol.svg';
import IconListUL from '@/icons/line/list-ul.svg';
import IconLinkH from '@/icons/line/link-h.svg';
import IconHeading1 from '@/icons/line/heading-one.svg';
import IconHeading2 from '@/icons/line/heading-two.svg';
import IconHeading3 from '@/icons/line/heading-three.svg';
import IconBlockquote from '@/icons/line/blockquote.svg';
import Underline from '@tiptap/extension-underline';
import Link from '@tiptap/extension-link';
import CharacterCount from '@tiptap/extension-character-count';
import { useToggle } from '@vueuse/core';
import Dialog from '@/components/dialog/Dialog.vue';
import FormTextInput from '@/components/formTextInput/FormTextInput.vue';
import RichTextControlButton from './RichTextControlButton.vue';

export type RichTextPayload = {
  json: JSONContent;
  html: string | null;
};

export type RichTextInputProps = {
  modelValue: JSONContent | null;
  id?: string;
  hasError?: boolean;
  isDisabled?: boolean;
  characterCountLimit?: number;
};

const props = withDefaults(defineProps<RichTextInputProps>(), {
  characterCountLimit: 1000
});

const emit = defineEmits<{
  'update:modelValue': [value: RichTextPayload];
}>();

const linkUrl = ref<string | null>(null);
// need a unique ID for the child form so use the component instance
const formId = ref<string | undefined>(getCurrentInstance()?.uid.toString());
const [showLinkDialog, toggleShowLinkDialog] = useToggle(false);

const editor = useEditor({
  content: props.modelValue,
  editable: !props.isDisabled,
  onUpdate: onValueChange,
  extensions: [
    StarterKit,
    Underline,
    CharacterCount.configure({
      limit: props.characterCountLimit
    }),
    Link
  ]
});

const characterCount = computed(() => {
  if (editor.value?.storage) {
    return editor.value?.storage.characterCount.characters();
  }
  return 0;
});

function onValueChange() {
  const emptyTipTapJson = {
    type: 'doc',
    content: []
  };

  emit('update:modelValue', {
    json: editor.value?.getJSON() ?? emptyTipTapJson,
    html: editor.value?.getHTML() ?? ''
  });
}

function onRemoveLink() {
  editor.value?.chain().focus().extendMarkRange('link').unsetLink().run();
  toggleShowLinkDialog(false);
}

function onSubmitLink() {
  if (!linkUrl.value) {
    editor.value?.chain().focus().extendMarkRange('link').unsetLink().run();
  } else {
    editor.value
      ?.chain()
      .focus()
      .extendMarkRange('link')
      .setLink({ href: linkUrl.value ?? '' })
      .run();
  }
  toggleShowLinkDialog(false);
}

function openLinkDialog() {
  toggleShowLinkDialog(true);
  const previousUrl = editor.value?.getAttributes('link').href;
  linkUrl.value = previousUrl;
}
</script>

<template>
  <div>
    <div
      class="items-center justify-center rounded-lg border"
      :class="{
        'border-slate-300': !hasError,
        'border-red-500 focus:border-red-500 focus:ring-red-500/40': hasError
      }"
    >
      <div
        v-if="editor"
        class="flex items-center divide-x divide-slate-200 rounded-t-lg border-b border-slate-300 bg-slate-50 py-1.5"
      >
        <div class="flex gap-0.5 px-3">
          <RichTextControlButton
            ariaLabel="Bold"
            :icon="IconBold"
            :checked="editor.isActive('bold')"
            :disabled="isDisabled || !editor.can().chain().focus().toggleBold().run()"
            @click="editor.chain().focus().toggleBold().run()"
          />
          <RichTextControlButton
            ariaLabel="Italic"
            :icon="IconItalic"
            :checked="editor.isActive('italic')"
            :disabled="isDisabled || !editor.can().chain().focus().toggleItalic().run()"
            @click="editor.chain().focus().toggleItalic().run()"
          />
          <RichTextControlButton
            ariaLabel="Underline"
            :icon="IconUnderline"
            :checked="editor.isActive('underline')"
            :disabled="isDisabled || !editor.can().chain().focus().toggleUnderline().run()"
            @click="editor.chain().focus().toggleUnderline().run()"
          />
          <RichTextControlButton
            ariaLabel="Strikethrough"
            :icon="IconStrikethrough"
            :checked="editor.isActive('strike')"
            :disabled="isDisabled || !editor.can().chain().focus().toggleStrike().run()"
            @click="editor.chain().focus().toggleStrike().run()"
          />
          <RichTextControlButton
            ariaLabel="Link"
            :icon="IconLinkH"
            :checked="editor.isActive('link')"
            :disabled="isDisabled || false"
            @click="openLinkDialog"
          />
        </div>
        <div class="flex gap-0.5 px-3">
          <RichTextControlButton
            ariaLabel="Ordered list"
            :icon="IconListOL"
            :disabled="isDisabled || !editor.can().chain().focus().toggleOrderedList().run()"
            :checked="editor.isActive('orderedList')"
            @click="editor.chain().focus().toggleOrderedList().run()"
          />
          <RichTextControlButton
            ariaLabel="Unordered list"
            :icon="IconListUL"
            :checked="editor.isActive('bulletList')"
            :disabled="isDisabled || !editor.can().chain().focus().toggleBulletList().run()"
            @click="editor.chain().focus().toggleBulletList().run()"
          />
        </div>
        <div class="flex gap-0.5 px-3">
          <RichTextControlButton
            ariaLabel="Heading 1"
            :icon="IconHeading1"
            :checked="editor.isActive('heading', { level: 1 })"
            :disabled="
              isDisabled || !editor.can().chain().focus().toggleHeading({ level: 1 }).run()
            "
            @click="editor.chain().focus().toggleHeading({ level: 1 }).run()"
          />
          <RichTextControlButton
            ariaLabel="Heading 2"
            :icon="IconHeading2"
            :checked="editor.isActive('heading', { level: 2 })"
            :disabled="
              isDisabled || !editor.can().chain().focus().toggleHeading({ level: 2 }).run()
            "
            @click="editor.chain().focus().toggleHeading({ level: 2 }).run()"
          />
          <RichTextControlButton
            ariaLabel="Heading 3"
            :icon="IconHeading3"
            :checked="editor.isActive('heading', { level: 3 })"
            :disabled="
              isDisabled || !editor.can().chain().focus().toggleHeading({ level: 3 }).run()
            "
            @click="editor.chain().focus().toggleHeading({ level: 3 }).run()"
          />
        </div>
        <div class="flex gap-0.5 px-3">
          <RichTextControlButton
            ariaLabel="Blockquote"
            :icon="IconBlockquote"
            :checked="editor.isActive('blockquote')"
            :disabled="isDisabled || !editor.can().chain().focus().toggleBlockquote().run()"
            @click="editor.chain().focus().toggleBlockquote().run()"
          />
        </div>
      </div>
      <EditorContent
        :id="id"
        :editor="editor"
        class="rich-content-html cursor-text px-4 pb-3.5 pt-3"
      />
    </div>
    <span class="px-3 text-sm text-slate-500"
      >{{ characterCount }} / {{ characterCountLimit }}</span
    >
  </div>

  <Dialog
    @onClose="toggleShowLinkDialog(false)"
    @onConfirm="onSubmitLink"
    @onCancel="onRemoveLink"
    cancelButtonLabel="Remove URL"
    confirmButtonLabel="Save"
    :isOpen="showLinkDialog"
    title="Edit link"
    :formId="formId"
  >
    <FormTextInput
      v-model="linkUrl"
      name="linkUrl"
      placeholder="URL"
      type="url"
      label="Link URL"
      :autofocus="true"
    />
  </Dialog>
</template>
