export enum NumberVisualization {
  Sum = 'sum',
  Percentage = 'percentage'
}

export type ChartjsDatasetItem = {
  distinct_students: number;
  percent: number;
  total: number;
  goal: App.Reports.Data.GoalResultData | null;
};

export function formatChartDataValue(
  data: ChartjsDatasetItem,
  format: NumberVisualization
): string {
  if (format === NumberVisualization.Sum) {
    return data.distinct_students.toString();
  }
  return `${Math.round(data.percent * 100)}%`;
}

export function formatGoalTarget(data: ChartjsDatasetItem, format: NumberVisualization): string {
  if (!data.goal) {
    return '';
  }

  const { distinct_students, total, percent, goal } = data;

  if (format === NumberVisualization.Sum) {
    const actual = distinct_students;
    const target = Math.round(goal.percent * total);
    return `${actual}/${target}`;
  }

  const actual = Math.round(percent * 100);
  const target = Math.round(goal.percent * 100);
  return `${actual}/${target}%`;
}
