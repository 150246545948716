<script setup lang="ts">
type EnumData = App.Base.Data.EnumData;

import { buildSelectableCustomTest } from '@/utils/buildSelectable';

import CenteredContent from '@/components/content/CenteredContent.vue';
import TestForm from '@/components/students/studentActions/TestForm.vue';

const props = defineProps<{
  returnUrl?: string;
  student: App.Students.Data.StudentData;
  test: App.Students.Data.TestData;
  types: EnumData[];
  scoreMap: Record<string, Record<string, { rules?: string[]; step?: number }>>;
  labelMap: Record<string, string>;
  customTests: App.Configuration.Data.CustomTestData[];
  apCourses: EnumData[];
}>();

const typeOptions = computed(() => [
  ...props.types,
  {
    value: 'custom-test',
    label: 'Custom test',
    description: null
  }
]);

const form = useForm({
  method: 'PUT',
  url: route('students.tests.update', { student: props.student.id, test: props.test.id }),
  fields: {
    type: typeOptions.value.filter((type) => type.value === props.test.type).length
      ? typeOptions.value.filter((test) => test.value === props.test.type)[0]
      : typeOptions.value.filter((test) => test.value === 'custom-test')[0],
    subject: getSubject(),
    test: getTest(),
    date: props.test.date,
    is_complete: props.test.is_complete,
    scores: props.test.scores.reduce(
      (carry: Record<string, number>, score: App.Students.Data.TestScoreData) => ({
        ...carry,
        [score.name]: score.score
      }),
      {}
    ),
    is_assisted: props.test.is_assisted,
    is_waiver_assisted: props.test.is_waiver_assisted,
    is_registered: props.test.is_registered,
    is_waived: props.test.is_waived
  },
  transform: (fields) => ({
    ...fields,
    type: fields.type?.value === 'custom-test' ? fields.test?.value : fields.type?.value,
    test: fields.test?.value
  })
});

function getSubject() {
  if (props.test.type === 'ap-course') {
    const subjectScore = props.test.scores.find((score) => score.name === 'subject')?.score;
    return props.apCourses.find((subject) => subject.value === subjectScore);
  }
  return null;
}

function getTest() {
  return !props.types.find((type) => type.value === props.test.type)
    ? buildSelectableCustomTest(props.customTests.find((test) => test.name === props.test.type))
    : null;
}
</script>


					<script lang="ts">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <CenteredContent>
    <TestForm
      :apCourses
      :customTests
      :form="form"
      :labelMap
      :returnUrl
      :scoreMap
      :student
      :types="typeOptions"
      isEditing
    />
  </CenteredContent>
</template>
