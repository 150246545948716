<script setup lang="ts">
import MetricCard from '@/components/metricCard/MetricCard.vue';
import ShowStudentPageLayout from '@/components/students/ShowStudentPageLayout.vue';
import StudentOverviewInteractionSection from '@/components/students/overview/StudentOverviewInteractionSection.vue';
import StudentOverviewFinancialAidSection from '@/components/students/overview/StudentOverviewFinancialAidSection.vue';
import StudentOverviewCollegeApplicationsSection from '@/components/students/overview/StudentOverviewCollegeApplicationsSection.vue';
import StudentOverviewScholarshipApplicationsSection from '@/components/students/overview/StudentOverviewScholarshipApplicationsSection.vue';

defineProps<{
  interactionCounts: {
    '1_on_1': number;
    group: number;
    parent: number;
    other: number;
  };
  metrics: {
    interactionsTotal: number;
    applicationsAccepted: string;
    financialAidStatus: App.Base.Data.EnumData;
    postSecondaryPlans: App.Students.Data.StudentPostSecondaryPlanData[];
  };
  student: App.Students.Data.StudentData;
  studentCssProfiles: App.Base.Data.EnumData[];
}>();
</script>


					<script lang="ts">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <ShowStudentPageLayout :student="student" :title="student.name">
    <template #metricCards>
      <MetricCard title="Total Interactions">{{ metrics.interactionsTotal }}</MetricCard>
      <MetricCard title="Accepted College Applications">{{
        metrics.applicationsAccepted
      }}</MetricCard>
      <MetricCard title="Financial Aid Status">{{ metrics.financialAidStatus.label }}</MetricCard>
      <MetricCard
        title="Post-Secondary Plan"
        :badgeTruncatorItems="metrics.postSecondaryPlans"
        badgeTruncatorItemKey="post_secondary_plan.label"
        >{{ metrics.postSecondaryPlans[0]?.post_secondary_plan.label || '--' }}</MetricCard
      >
    </template>
    <div class="p-4 pt-0.5">
      <StudentOverviewInteractionSection
        :interactions="student.interactions"
        :interactionCounts="interactionCounts"
        :student="student"
      />
    </div>
    <div class="px-4 pb-20 pt-4 lg:flex lg:gap-6">
      <div class="lg:w-2/5">
        <StudentOverviewFinancialAidSection
          :student="student"
          :studentCssProfiles="studentCssProfiles"
        />
      </div>
      <div class="lg:w-3/5">
        <StudentOverviewCollegeApplicationsSection
          :collegeApplications="student.applications"
          :student="student"
        />
        <StudentOverviewScholarshipApplicationsSection
          :student="student"
          :scholarships="student.scholarships"
        />
      </div>
    </div>
  </ShowStudentPageLayout>
</template>
