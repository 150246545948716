import { MetricApiResult, MetricConfig } from '@/components/dashboard/metrics';
import { runReport } from '@/api/KpiReportApi';
import { debounce } from 'lodash';

interface MetricsFilters {
  schoolId?: number;
  grade?: number;
}

export function useMetrics(filters: MetricsFilters, metricConfigs: MetricConfig[]) {
  const isLoading = ref(false);
  const metrics = ref<MetricApiResult[]>([]);

  const kpis = metricConfigs.flatMap((config) => config.kpis.map((kpi) => kpi.kpi) ?? []);

  const fetchMetrics = debounce(async (filters?: MetricsFilters) => {
    if (!filters?.schoolId || !filters?.grade) {
      return;
    }

    isLoading.value = true;

    const response = await runReport({
      school_id: filters?.schoolId ?? 0,
      grade: filters?.grade ?? 0,
      report_id: 'schools-report',
      columns: kpis.map((kpi) => ({ key: kpi }))
    });

    metrics.value = metricConfigs.map((config) => {
      return config.kpis.map((kpi) => response[kpi.kpi]);
    });

    isLoading.value = false;
  }, 300);

  onMounted(() => {
    isLoading.value = true;

    fetchMetrics(filters);
  });

  function updateFilters(searchFilters?: MetricsFilters) {
    fetchMetrics(searchFilters);
  }

  return {
    metrics,
    isLoading,
    updateFilters
  };
}
