<script setup lang="tsx">
const props = withDefaults(
  defineProps<{
    size?: 'sm' | 'md' | 'lg';
    padded?: boolean;
  }>(),
  {
    size: 'sm',
    padded: true
  }
);

const widthClass = computed(() => {
  return {
    sm: 'max-w-[600px]',
    md: 'max-w-[800px]',
    lg: 'max-w-[960px]'
  }[props.size];
});
</script>

<template>
  <section class="mx-auto w-full" :class="[widthClass, { 'py-10': padded }]">
    <slot />
  </section>
</template>
