<script setup lang="ts">
import { SelectableValue, type Selectable } from '@/components/selectBox/selectBox';
import type { SurveyAnswerChoiceType } from '@/components/surveys/sessoins/SurveyBlock.vue';

import FormMultiInstituteInput from '@/components/formComboboxInput/FormMultiInstituteInput.vue';
import SurveyBlockCheck from '@/components/surveys/sessoins/SurveyBlockCheck.vue';
import FormError from '@/components/formError/FormError.vue';

const props = defineProps<{
  block: App.Surveys.Data.SurveyBlockData;
  choices?: SurveyAnswerChoiceType[];
  errors?: Record<string, string>;
}>();

const emit = defineEmits<{
  'update:modelValue': [selectedOptions: SurveyAnswerChoiceType[]];
}>();

const isInstituteBlock = computed(() => props.block.choice_type?.value === 'institute');

const selectedChoices = ref<string[]>(
  props.choices
    ? props.choices.map(
        (choice) =>
          (choice.survey_choice_id?.toString() as string) ||
          (choice.answer_id?.toString() as string)
      )
    : []
);
const selectedInstituteOptions = ref<Selectable<number>[]>(
  props.choices ? props.choices.map((choice) => choice.answer_option as Selectable<number>) : []
);

function handleSelectionUpdate(checked: boolean, option: App.Surveys.Data.SurveyChoiceData) {
  if (checked) {
    selectedChoices.value = [...selectedChoices.value, option.id.toString()];
  } else {
    selectedChoices.value = selectedChoices.value.filter((item) => item !== option.id.toString());
  }

  emit(
    'update:modelValue',
    selectedChoices.value.map((choice) => {
      if (props.block.dynamic_choice_type) {
        return {
          answer_type: props.block.dynamic_choice_type?.value,
          answer_id: choice
        };
      }

      return {
        survey_choice_id: choice
      };
    })
  );
}

function handleInstituteSelectionUpdate(value: Selectable<number>[]) {
  selectedInstituteOptions.value = value;
}

watch(
  selectedInstituteOptions,
  (newValue) => {
    emit(
      'update:modelValue',
      newValue.map((choice: Selectable<SelectableValue>) => ({
        answer_id: choice.value as string | number,
        answer_type: props.block.choice_type?.value as string
      }))
    );
  },
  { deep: true }
);
</script>

<template>
  <div class="flex w-full flex-col gap-1.5">
    <fieldset class="flex min-w-0 flex-col gap-1">
      <FormMultiInstituteInput
        v-if="isInstituteBlock"
        :modelValue="selectedInstituteOptions"
        @update:modelValue="(value) => handleInstituteSelectionUpdate(value)"
      />
      <template v-for="option in block.choices" :key="option.id">
        <SurveyBlockCheck
          :label="option.choice_text"
          :checked="option.id ? selectedChoices.includes(option.id.toString()) : false"
          @update:checked="handleSelectionUpdate($event, option)"
        />
      </template>
    </fieldset>
    <FormError v-if="errors?.choices">{{ errors.choices }}</FormError>
  </div>
</template>
