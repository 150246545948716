<script setup lang="ts">
import type { FormFieldProps, FormFieldSlots } from '@/components/formField/FormField';
import type { TextInputProps } from '@/components/textInput/TextInputProps';
import FormField from '@/components/formField/FormField.vue';
import TextInput from '@/components/textInput/TextInput.vue';
import { useFormField } from '@/components/formField/useFormField';

type FormTextInputProps = Omit<TextInputProps, 'hasError'> & FormFieldProps;

const props = defineProps<FormTextInputProps>();

defineSlots<FormFieldSlots>();

const emit = defineEmits<{
  'update:modelValue': [value: string];
}>();

function onValueChange(event: string): void {
  emit('update:modelValue', event);
}

const { formFieldProps, slots } = useFormField({ props });
</script>

<template>
  <FormField v-bind="formFieldProps">
    <TextInput
      :append="append"
      :hasError="formFieldProps.hasError"
      :iconLeft="iconLeft"
      :iconRight="iconRight"
      :isDisabled="isDisabled"
      :max="max"
      :min="min"
      :modelValue="modelValue"
      :name="name"
      :placeholder="placeholder"
      :prepend="prepend"
      :step="step"
      :type="type"
      :variant="variant"
      @update:modelValue="onValueChange"
      :autofocus="autofocus"
    />
    <template v-for="slot of slots" #[slot]>
      <slot :name="slot" />
    </template>
  </FormField>
</template>
