<script setup lang="ts">
import TabNav from '@/components/tabNav/TabNav.vue';
import TabNavItem from '@/components/tabNav/TabNavItem.vue';
import TabNavDivider from '@/components/tabNav/TabNavDivider.vue';

const { matches } = useRoute();

defineProps<{
  uploadType: string;
  draftCount: number;
  finishedCount: number;
}>();
</script>

<template>
  <TabNav layout="inline" class="px-3">
    <TabNavItem
      label="Draft"
      :badgeLabel="draftCount"
      :isActive="matches('upload-sessions.draft.index')"
      :href="route('upload-sessions.draft.index', { uploadType })"
      variant="inline"
    />
    <TabNavDivider layout="inline" />
    <TabNavItem
      label="Finished"
      :badgeLabel="finishedCount"
      :isActive="matches('upload-sessions.finished.index')"
      :href="route('upload-sessions.finished.index', { uploadType })"
      variant="inline"
    />
  </TabNav>
</template>
