<script setup lang="ts">
type EnumData = App.Base.Data.EnumData;

import type { Selectable } from '@/components/selectBox/selectBox';

import CenteredContent from '@/components/content/CenteredContent.vue';
import CollegeApplicationForm from '@/components/students/studentActions/CollegeApplicationForm.vue';

const props = defineProps<{
  returnUrl?: string;
  statuses: EnumData[];
  student: App.Students.Data.StudentData;
}>();

const form = useForm({
  method: 'POST',
  url: route('students.college-applications.store', { student: props.student.id }),
  fields: {
    institute_id: null as Selectable<number> | null,
    date_submitted: '',
    status: null as Selectable<string> | null,
    intend_to_enroll: null as Selectable<string> | null,
    has_waiver: { label: 'No', value: false } as Selectable<boolean>,
    waiver: null,
    first_choice: false,
    award_meeting_held: false,
    early_action: false,
    early_decision: false,
    sent_hs_transcript: false,
    applied_to_honors_program: false,
    financial_aid_submitted_to_school: false
  },
  transform: (fields) => ({
    ...fields,
    institute_id: fields.institute_id?.value,
    status: fields.status?.value,
    intend_to_enroll: fields.intend_to_enroll?.value,
    has_waiver: fields.has_waiver?.value
  })
});
</script>


					<script lang="ts">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <CenteredContent>
    <CollegeApplicationForm :form :returnUrl :statuses :student />
  </CenteredContent>
</template>
