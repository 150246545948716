<script setup lang="ts" generic="T extends Record<string, unknown>">
import CheckboxInput, { CheckedValue } from '@/components/checkboxInput/CheckboxInput.vue';

const props = defineProps<{
  checked: CheckedValue;
  isDisabled?: boolean;
}>();

const emit = defineEmits<{
  checked: [checked: boolean];
}>();

function handleChecked(checked: boolean) {
  emit('checked', checked);
}

function toggleChecked() {
  handleChecked(!props.checked);
}
</script>

<template>
  <div class="px-4 py-3.5" @click.stop="toggleChecked">
    <CheckboxInput :checked="checked" :isDisabled="isDisabled" />
  </div>
</template>
