<script setup lang="tsx">
import { TableColumn } from '@/components/table/Table';

import { BadgeColor } from '@/hooks/useBadgeClasses';
import { ButtonColor, ButtonSize, ButtonVariant } from '@/hooks/useButtonClasses';
import { onEvent } from '@/hooks/useWebsockets';
import { useTailwind } from '@/hooks/useTailwind';

import Badge from '@/components/badge/Badge.vue';
import Button from '@/components/button/Button.vue';
import DeleteUploadSession from '@/domains/bulkinator/components/DeleteUploadSession.vue';
import EditableCell from '@/domains/bulkinator/components/table/EditableCell.vue';
import EditButtonCell from '@/domains/bulkinator/components/table/EditButtonCell.vue';
import ExportUploadSession from '@/domains/bulkinator/components/ExportUploadSession.vue';
import IconButton from '@/components/button/IconButton.vue';
import ImportUploadSession from '@/domains/bulkinator/components/ImportUploadSession.vue';
import Loading from '@/components/loading/Loading.vue';
import LockedCell from '@/domains/bulkinator/components/table/LockedCell.vue';
import Menu from '@/components/menu/Menu.vue';
import PaddedCellContent from '@/components/table/PaddedCellContent.vue';
import PageHeader from '@/components/pageHeader/PageHeader.vue';
import Pagination from '@/components/pagination/Pagination.vue';
import RenameUploadSession from '@/domains/bulkinator/components/RenameUploadSession.vue';
import StatusIndicatorCell from '@/domains/bulkinator/components/table/StatusIndicatorCell.vue';
import Table from '@/components/table/Table.vue';
import UploadSessionChildSessions from '@/domains/bulkinator/components/UploadSessionChildSessions.vue';
import UploadSessionDescription from '@/domains/bulkinator/components/UploadSessionDescription.vue';
import UploadSessionHeaderCell from '@/domains/bulkinator/components/table/UploadSessionHeaderCell.vue';
import UploadSessionStatusFilter from '@/domains/bulkinator/components/UploadSessionStatusFilter.vue';

import AndroidPhoneSlash from '@/icons/line/android-phone-slash.svg';
import AngleDownIcon from '@/icons/line/angle-down.svg';
import PlusIcon from '@/icons/line/plus.svg';
import { useAuth } from '@/hooks/useAuth';

type UploadRecordData = App.Bulkinator.Data.UploadRecordData;

const props = defineProps<{
  uploadSession: App.Bulkinator.Data.UploadSessionData;
  availableColumns: App.Bulkinator.Data.UploadColumnData[];
  appliedStatuses: string[] | null;
  uploadRecords?: Paginator<UploadRecordData>;
  uploadType: App.Bulkinator.Data.UploadTypeData;
  sort?: string;
  isMappable: boolean;
}>();

const { theme } = useTailwind();
const { width: windowWidth } = useWindowSize();
const { hasPermission } = useAuth();

const isSmallScreen = computed(() => windowWidth.value <= parseInt(theme?.screens.md ?? '0px'));

onEvent(
  `upload-session.${props.uploadSession.id}`,
  'upload-session.updated',
  ({ session }: { session: App.Bulkinator.Data.UploadSessionData }) => {
    if (session.status.value !== props.uploadSession.status.value) {
      router.reload({
        only: ['uploadRecords', 'uploadSession']
      });
    }
  }
);

watch(
  () => props.uploadSession.status,
  (status) => {
    if (status.value === 'pending') {
      setupPollRequest();
    }
  },
  {
    immediate: true
  }
);

function setupPollRequest() {
  setTimeout(() => {
    if (props.uploadSession.status.value === 'pending') {
      router.reload({
        only: ['uploadRecords', 'uploadSession'],
        preserveScroll: true,
        preserveState: true
      });
    }
  }, 5000);
}

const defaultStatuses = ['match', 'new', 'pending', 'error'];

const addRecordForm = useForm({
  url: route('upload-records.store', { uploadSession: props.uploadSession.id }),
  method: 'POST',
  fields: {}
});

const customCells = computed(() => {
  const customCells = {};

  // Add locked columns overrides
  props.availableColumns.forEach((availableColumn) => {
    const CellComponent = availableColumn.readOnly ? LockedCell : EditableCell;

    customCells[`data.${availableColumn.name}`] = ({ row, column }) => {
      return (
        <CellComponent
          column={availableColumn}
          row={row}
          value={column.value({ row })}
          isLocked={props.uploadSession.is_locked}
        />
      );
    };
  });

  return customCells;
});

function handleSort(sortBy: string | null) {
  router.reload({
    url: route('upload-sessions.show', {
      uploadSession: props.uploadSession.id,
      sort: sortBy ?? undefined
    }),
    only: ['uploadRecords', 'sort']
  });
}

const statusBadgeColor = computed<BadgeColor>(() => {
  return {
    pending: 'slate',
    processing: 'warning',
    draft: 'secondary',
    running: 'warning',
    finished: 'success'
  }[props.uploadSession.status.value];
});

const availableHeaders = computed(() =>
  props.uploadSession.column_headers.filter((header) => {
    const mappedColumnName = props.uploadSession.column_mappings[header];
    return (
      !mappedColumnName ||
      !!props.availableColumns.find((column) => column.name === mappedColumnName)
    );
  })
);

const columns = computed<TableColumn<UploadRecordData>[]>(() => {
  return [
    {
      key: 'status',
      label: '',
      headerCellRender: () => (
        <PaddedCellContent>
          {props.uploadSession.status.value === 'draft' ? (
            <IconButton
              ariaLabel="Add record"
              icon={PlusIcon}
              color={ButtonColor.slate}
              variant={ButtonVariant.soft}
              size={ButtonSize.xs}
              isLoading={addRecordForm.processing}
              // @ts-expect-error Vue won't recognize the event handler even though it works correctly
              onClick={() => handleAddRecord()}
            />
          ) : null}
        </PaddedCellContent>
      ),
      cellRender: ({ row }: { row: UploadRecordData }) => (
        <StatusIndicatorCell status={row.status} uploadType={props.uploadType} />
      )
    },
    {
      key: 'id',
      label: 'Identifier',
      cellRender: ({ row }: { row: UploadRecordData }) => (
        <LockedCell value={row.recordIdentifier} />
      ),
      minWidth: '200px',
      maxWidth: '400px'
    },
    ...availableHeaders.value.map((header) => {
      const mappedColumnName = props.uploadSession.column_mappings[header];
      const mappedColumn = props.availableColumns.find(
        (column) => column.name === mappedColumnName
      );

      return {
        key: header,
        label: mappedColumnName ?? header,
        value: ({ row }) => row.data[mappedColumnName ?? header] ?? null,
        cellRender: customCells.value[`data.${mappedColumnName}`],
        headerCellRender: ({ column }) => (
          <UploadSessionHeaderCell
            uploadSessionId={props.uploadSession.id}
            availableColumns={props.availableColumns}
            columnMappings={props.uploadSession.column_mappings}
            fileHeaders={availableHeaders.value}
            sort={props.sort}
            column={column}
            isMappable={props.isMappable}
            onSort={handleSort}
          />
        ),
        isSortable: false,
        minWidth: getWidthForColumn(mappedColumn)
      };
    }),
    {
      key: 'actions',
      label: '',
      cellRender: ({ row }: { row: UploadRecordData }) => <EditButtonCell row={row} />
    }
  ] as TableColumn<UploadRecordData>[];
});

function getWidthForColumn(column?: App.Bulkinator.Data.UploadColumnData) {
  return (
    {
      boolean: '140px',
      search: '260px'
    }[column?.type.value] ?? '200px'
  );
}

function handleAddRecord() {
  addRecordForm.submit();
}

function handleFilterChange(statuses: string[]) {
  router.reload({
    data: {
      status: statuses.length === defaultStatuses.length ? undefined : statuses.join(','),
      page: 1
    },
    only: ['uploadRecords', 'appliedStatuses']
  });
}
</script>


					<script lang="tsx">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <div class="flex h-full flex-col">
    <section
      v-if="isSmallScreen"
      class="flex h-full flex-col items-center justify-center gap-y-4 px-6"
    >
      <AndroidPhoneSlash class="h-6 w-6 text-primary-600" />
      <div class="text-center">
        <h1 class="text-base font-semibold leading-6 text-slate-900">{{ uploadSession.name }}</h1>
        <p class="text-sm leading-5 text-slate-500">
          Please use a desktop device in order to work with the bulkinator.
        </p>
      </div>
    </section>
    <div v-else class="divide-y-zinc-200 flex h-full flex-col divide-y">
      <div class="px-4 py-5">
        <PageHeader :title="uploadSession.name" :padded="false">
          <template #description>
            <UploadSessionDescription :uploadSession="uploadSession" />
          </template>
          <template #leftButtons>
            <template v-if="!uploadSession.is_locked">
              <RenameUploadSession :uploadSession="uploadSession" />
              <DeleteUploadSession :uploadSession="uploadSession" />
            </template>
          </template>
          <template #rightButtons>
            <Button
              v-if="uploadSession.file_url && hasPermission('download-upload-session-file')"
              :href="uploadSession.file_url"
              target="_blank"
              isExternalLink
              size="sm"
              variant="invisible"
              color="secondary"
            >
              Download original Excel file
            </Button>
            <Badge
              variant="soft"
              size="lg"
              :color="statusBadgeColor"
              :label="`${uploadSession.status.label} Session`"
              :isLoading="['pending', 'processing'].includes(uploadSession.status.value)"
            />
            <Menu>
              <template #trigger>
                <Button
                  :color="ButtonColor.secondary"
                  :iconRight="AngleDownIcon"
                  :variant="ButtonVariant.solid"
                  >Export</Button
                >
              </template>
              <ExportUploadSession :uploadSession="uploadSession" />
            </Menu>
            <ImportUploadSession :uploadSession="uploadSession" :uploadType="uploadType" />
          </template>
        </PageHeader>

        <!-- Can't put this in the PageHeader #description cause it will mess with the alignment of the #rightButtons -->
        <UploadSessionChildSessions :uploadSession="uploadSession" />
      </div>

      <div class="flex items-center gap-3 px-5 py-2">
        <UploadSessionStatusFilter
          :defaultStatuses="appliedStatuses ?? defaultStatuses"
          @change="handleFilterChange"
        />
      </div>

      <div v-if="uploadSession.status.value === 'pending'">
        <Loading
          class="mx-auto w-full max-w-lg px-4 py-20 text-center"
          iconColor="primary"
          title="Processing upload session..."
          description="Please wait while we process your upload session. This can take up to a minute to finish. We'll send you a notification when it has finished processing."
        />
      </div>
      <div v-else-if="!uploadRecords" class="py-20">
        <Loading iconColor="primary" title="Loading records..." />
      </div>
      <template v-else>
        <Table
          class="border-b-0"
          bordered
          showHeaderWhenEmpty
          :columns="columns"
          :rows="uploadRecords?.data as any"
          :currentSort="sort"
          :getRowId="(row) => row.id as number"
          :customCells="customCells"
          :stickyStartColumns="2"
          :stickyEndColumns="1"
          @sort="handleSort"
        >
          <template #emptyState>
            <slot name="emptyState" />
          </template>
        </Table>

        <Pagination :pagination="uploadRecords" :linkOptions="{ only: ['uploadRecords'] }" />
      </template>
    </div>
  </div>
</template>
