<script setup lang="ts">
import VueJsonPretty from 'vue-json-pretty';
import { JSONDataType } from 'vue-json-pretty/types/utils';
import 'vue-json-pretty/lib/styles.css';
import { RouterLink } from 'hybridly/vue';

import PageHeader from '@/components/pageHeader/PageHeader.vue';
import Distiller from '@/components/distiller/Distiller.vue';
import FilterProvider from '@/components/distiller/FilterProvider.vue';
import { AppliedFilters } from '@/components/distiller/types';

import DistillerData = App.Search.Data.DistillerData;

const props = defineProps<{
  distiller: DistillerData;
  filters: AppliedFilters;
  resultingQuery: string;
  students: App.Students.Data.StudentData[];
}>();

const appliedFilters = ref(props.filters || {});

function handleChange() {
  router.reload({
    data: {
      filters: btoa(JSON.stringify(appliedFilters.value))
    }
  });
}
</script>


					<script lang="ts">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <PageHeader title="Filters" />

  <div class="space-y-2 p-2">
    <h1>Resulting Query</h1>
    <pre class="w-full whitespace-pre-wrap border border-gray-100 bg-gray-50 p-2">
      {{ resultingQuery }}
    </pre>
  </div>

  <hr />

  <div class="p-2">
    <div class="flex-1">
      <h1>Applied Filters</h1>
      <VueJsonPretty :data="appliedFilters as JSONDataType" />
    </div>

    <FilterProvider v-model="appliedFilters" @update:modelValue="handleChange">
      <Distiller :distiller />
    </FilterProvider>

    <div class="flex flex-col items-start gap-2">
      <RouterLink
        v-for="student in students"
        :key="student.id"
        :href="route('students.show', { student: student.id })"
        class="block rounded-lg border border-slate-100 p-2 hover:border-slate-200 hover:bg-slate-100"
      >
        <div>ID: {{ student.id }}, Grade: {{ student.grade.label }}, Name: {{ student.name }}</div>
      </RouterLink>
    </div>
  </div>
</template>
