<script setup lang="ts">
import IconButton from '@/components/button/IconButton.vue';
import TimesCircleIcon from '@/icons/line/times-circle.svg';
import RecipientStudentBadges from './RecipientStudentBadges.vue';
import RecipientStudentParentBadges from './RecipientStudentParentBadges.vue';
import RecipientStudentGroupCaseloadBadges from './RecipientStudentGroupCaseloadBadges.vue';
import RecipientPhoneNumberBadges from './RecipientPhoneNumberBadges.vue';
import Tooltip from '@/components/tooltip/Tooltip.vue';

const props = withDefaults(
  defineProps<{
    recipient: App.Sms.Data.SmsRecipientData;
    errors: App.Sms.Data.SmsRecipientValidationData[];
    readonly?: boolean;
  }>(),
  {
    readonly: false
  }
);

defineEmits<{
  (e: 'remove'): void;
}>();

const hasErrors = computed(() => {
  return !!props.errors.length;
});

const recipientBadgeComponent = computed(() => {
  return {
    student: RecipientStudentBadges,
    student_parent: RecipientStudentParentBadges,
    student_group: RecipientStudentGroupCaseloadBadges,
    caseload: RecipientStudentGroupCaseloadBadges,
    phone_number: RecipientPhoneNumberBadges
  }[props.recipient.recipient_type ?? 'unknown'];
});
</script>

<template>
  <Tooltip :isDisabled="!hasErrors" :delayDuration="100" showArrow>
    <div class="flex flex-col gap-2">
      <p v-for="error in errors" :key="error.recipient.recipient_id as number">
        {{ error.error }}
      </p>
    </div>
    <template #trigger>
      <div class="px-3 pb-2 pt-1.5" :class="{ 'bg-red-50': hasErrors }">
        <div class="flex items-center justify-between gap-2">
          <p class="truncate text-sm font-semibold">
            {{ recipient.name }}
          </p>
          <IconButton
            v-if="readonly !== true"
            :icon="TimesCircleIcon"
            ariaLabel="Remove recipient"
            variant="invisible"
            color="danger"
            size="sm"
            @click="$emit('remove')"
          />
        </div>

        <div class="flex items-center gap-2">
          <component :is="recipientBadgeComponent" :recipient="recipient" />
        </div>
      </div>
    </template>
  </Tooltip>
</template>
