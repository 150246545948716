import { get, set, unset } from 'lodash';

import { recordCount } from '@/api/FilterApi';

import { AppliedFilter, AppliedFilterValue, AppliedFilters } from '@/components/distiller/types';

export function useFilters() {
  const appliedFilters = inject<AppliedFilters>('filters', {});
  const filteredRecordsCount = ref<App.Search.Data.SearchRecordCountData | null>(null);
  const isLoadingRecordCount = ref(false);

  const formattedFilteredRecordsCount = computed(() => {
    return new Intl.NumberFormat('en-US').format(filteredRecordsCount.value?.count || 0);
  });

  function getAppliedFilter(path?: string): AppliedFilter {
    if (path) {
      return get(appliedFilters.value, path);
    }

    return appliedFilters.value;
  }

  function hasAppliedFilter(path?: string): boolean {
    return Object.keys(getAppliedFilter(path) || {}).some(
      (key) => !['operator', 'value', 'readonly'].includes(key)
    );
  }

  function replaceFilter(oldPath: string, newPath: string | null): void {
    const clone = { ...appliedFilters.value };

    if (newPath) {
      set(clone, newPath, {
        operator: oldPath ? clone[oldPath]?.operator : null,
        value: null
      });
    }

    if (oldPath) {
      delete clone[oldPath];
    }

    appliedFilters.value = clone;
  }

  function addFilter(
    path: string,
    operator: string | null = null,
    value: AppliedFilterValue | null = null
  ): void {
    const clone = { ...appliedFilters.value };

    set(clone, path, {
      operator,
      value
    });

    appliedFilters.value = clone;
  }

  function updateFilterOperator(path: string, operator: App.Search.Enums.FilterOperator | null) {
    appliedFilters.value = set(appliedFilters.value, `${path}.operator`, operator);
  }

  function updateFilterValue(path: string, value: AppliedFilterValue | AppliedFilterValue[]) {
    appliedFilters.value = set(appliedFilters.value, `${path}.value`, value);
  }

  function removeFilter(path: string) {
    const clone = { ...appliedFilters.value };
    unset(clone, path);
    appliedFilters.value = clone;
  }

  async function getRecordCount(
    distiller: App.Search.Data.DistillerData,
    appliedFiltersQueryStringValue: string | AppliedFilters | undefined
  ) {
    appliedFiltersQueryStringValue =
      typeof appliedFiltersQueryStringValue === 'object'
        ? btoa(JSON.stringify(appliedFiltersQueryStringValue))
        : appliedFiltersQueryStringValue;

    isLoadingRecordCount.value = true;

    filteredRecordsCount.value = await recordCount(
      distiller.name?.value,
      appliedFiltersQueryStringValue
    );

    isLoadingRecordCount.value = false;
  }

  function encodeFilters(filters: AppliedFilters): string | null {
    if (Object.keys(filters).length === 0) {
      return null;
    }
    return window.btoa(JSON.stringify(filters));
  }

  return {
    addFilter,
    appliedFilters,
    encodeFilters,
    filteredRecordsCount,
    formattedFilteredRecordsCount,
    getAppliedFilter,
    getRecordCount,
    isLoadingRecordCount,
    hasAppliedFilter,
    removeFilter,
    replaceFilter,
    updateFilterOperator,
    updateFilterValue
  };
}
