import { debounce } from 'lodash';

import { buildSelectableStudent } from '@/utils/buildSelectable';

import { search } from '@/api/GlobalSearchApi';

import { Selectable } from '@/components/selectBox/selectBox';

export function useGlobalSearch(searchFilters?: Record<string, unknown>) {
  const searchQuery = ref('');
  const isLoading = ref(false);
  const globalSearchOptions = ref<Selectable<number>[]>([]);
  const localSearchFilters = ref(searchFilters);

  const searchGlobal = debounce(async (query: string) => {
    const response = await search({
      filters: {
        ...localSearchFilters.value,
        search: query
      }
    });
    globalSearchOptions.value = response.map((student) =>
      buildSelectableStudent(student)
    ) as Selectable<number>[];

    isLoading.value = false;
  }, 300);

  function updateSearchFilters(searchFilters?: Record<string, unknown>) {
    localSearchFilters.value = searchFilters;
    searchGlobal(searchQuery.value);
  }

  function onQueryChange(query: string): void {
    isLoading.value = true;
    searchQuery.value = query;

    searchGlobal(query);
  }

  return {
    globalSearchOptions,
    searchGlobal,
    isLoading,
    onQueryChange,
    updateSearchFilters
  };
}
