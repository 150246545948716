<script setup lang="ts">
import type { Selectable } from '@/components/selectBox/selectBox';

import CenteredContent from '@/components/content/CenteredContent.vue';
import CustomTestForm from '@/components/customTests/CustomTestForm.vue';

defineProps<{
  returnUrl?: string;
}>();

const form = useForm({
  method: 'POST',
  url: route('custom-tests.store'),
  fields: {
    display_name: '',
    program_id: null as Selectable<number> | null,
    score: '',
    score2: '',
    score3: '',
    score4: '',
    score5: ''
  },
  transform: (fields) => ({
    ...fields,
    program_id: fields.program_id?.value ?? null
  })
});
</script>


					<script lang="ts">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <CenteredContent>
    <CustomTestForm :form="form" :returnUrl="returnUrl" />
  </CenteredContent>
</template>
